import { baseURL } from '../constants';
import { globals } from '../globals';
import { removeUndefinedKeys } from '../utils/vibehutUtils';

export const getChatByCallId = async (chatId: string) => {
  const _authUser = globals.user;
  if (!_authUser) {
    return;
  }
  const token = _authUser.token;

  const endpoint = `${baseURL}/api/v1/chats/${chatId}`;

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const getChatByRoomId = async (roomId: string) => {
  const _authUser = globals.user;
  if (!_authUser) {
    return;
  }
  const token = _authUser.token;

  const endpoint = `${baseURL}/api/v1/chats/room/${roomId}`;

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const saveMessageToRoom = async (chatId: string, roomId: string, isWaveCall: boolean) => {
  const _authUser = globals.user;
  if (!_authUser) {
    return;
  }
  const token = _authUser.token;
  const endpoint = `${baseURL}/api/v1/chats/save-message-to-room/${chatId}`;

  const params = removeUndefinedKeys({ roomId, isWaveCall });

  return fetch(endpoint, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  }).then(res => res.json());
};

export const getPersistedMessagesByRoom = async (roomId: string) => {
  const _authUser = globals.user;
  if (!_authUser) {
    return;
  }
  const token = _authUser.token;

  const endpoint = `${baseURL}/api/v1/chats/get-persisted-messages-room/${roomId}`;

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};
