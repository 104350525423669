import { Persona } from "../../types";
import SpecificChat from "../chat/SpecificChat";
import Button from "../../VibehutUI/Button";
import { useCallback, useContext, useMemo, useState } from "react";
import globals from "../../globals";
import PersonaIntro from "./personaIntro";
import { getShortenedName } from "../../utils/utils";
import { StoreContext } from "../../Context/StoreContext";
import EditPersona from "./EditPersona";
import { showLoginModal } from "../../actions/uiModalActions";
import { Link, useHistory } from "react-router-dom";
import CopyButton from "../../VibehutUI/CopyButton";
import { frontEndURL } from "../../utils/axios";
import Stats from "../../VibehutUI/Icons/Stats";
import { singleClaimedRoomRoutes, singleRoomRoutes } from "../../routes";
import Avatar from "../../VibehutUI/Avatar";
import { getUserData } from "../../videoChat/utils/vibehutUtils";

interface Props {
  persona: Persona;
}

function PersonaCard(props: Props) {
  const { persona } = props;
  const { state, dispatch } = useContext(StoreContext);
  const history = useHistory();
  const {
    auth: { user },
  } = state;
  const [showFullText, setShowFullText] = useState(false);

  const isIntroClicked = useMemo(() => {
    if (
      persona.personaClicks &&
      persona.personaClicks.find(
        (personaClick) => personaClick.user === user?._id,
      )
    ) {
      return true;
    }
    return false;
  }, [persona.personaClicks, user?._id]);

  const showPersonaInfoModal = useCallback(() => {
    if (!user) {
      showLoginModal(dispatch);
      return;
    }
    globals.showModal(persona.name, <PersonaIntro _persona={persona} />);
  }, [dispatch, persona, user]);

  const showEditModal = () => {
    globals.showModal("Edit Persona", <EditPersona _persona={persona} />);
  };

  const navigateToRoom = (e: any) => {
    e.stopPropagation();
    const path = persona?.room?.slug
      ? singleClaimedRoomRoutes.path.replace(":slug", persona?.room?.slug)
      : singleRoomRoutes.path.replace(":roomId", persona?.room?._id);
    history.push(path);
  };

  const isMyPersona = useMemo(
    () => user?._id === persona?.owner._id,
    [persona?.owner._id, user?._id],
  );

  return (
    <Link
      className="w-full max-w-full relative"
      to={`/personas/${persona._id}`}
    >
      <div className="relative flex flex-col justify-center items-center min-w-0 break-words shadow-soft-xl border dark:border-matteGray border-lightGray rounded-xl bg-clip-border p-4">
        <div className="absolute top-2 right-2">
          <CopyButton
            title="Vibehut"
            url={`${frontEndURL}/personas/${persona._id}`}
            bgColor="transparent"
            topPosition="-top-12"
            ratio="h-6 w-6"
          />
        </div>

        <div className="relative">
          <img
            src={persona.avatar}
            alt="Persona avatar"
            className="w-16 h-16 rounded-lg"
          />
          {persona?.companyAffiliation?.imgUrl &&
            persona.companyAffiliation?.isActive && (
              <img
                src={persona.companyAffiliation.imgUrl}
                alt="Company Logo"
                className="w-5 h-5 rounded-lg absolute bottom-1 right-1"
              />
            )}
          {!persona?.companyAffiliation?.imgUrl &&
            !persona.companyAffiliation?.isActive && (
              <span className="w-5 h-5 rounded-lg absolute bottom-1 right-1">
                <Avatar
                  src={getUserData(persona.owner).profile_picture}
                  username={getUserData(persona.owner).username}
                  size="xs"
                />
              </span>
            )}
        </div>
        <div className="flex flex-col dark:text-primaryGray text-darkGray pt-4 justify-center items-center">
          <h5 className="text-lg font-medium text-darkNight dark:text-frescoWhite text-center">
            {persona.name}
          </h5>
          <p className="leading-normal text-xs text-darkGray text-center">
            {showFullText
              ? persona.generatedBio
              : persona.generatedBio.length > 100
                ? getShortenedName(persona.generatedBio, 100)
                : persona.generatedBio}
            {persona.generatedBio.length > 100 && !showFullText && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullText(!showFullText);
                }}
                className="text-primary"
              >
                See More
              </button>
            )}
          </p>
        </div>
        {persona?.room && (
          <div
            className="flex items-center gap-1 pt-2"
            onClick={navigateToRoom}
          >
            <img
              src={
                persona.room?.cover?.urls?.regular ||
                `${process.env.PUBLIC_URL}/images/Frame 61.png`
              }
              alt="Room Banner"
              className="w-8 h-8 rounded-lg"
            />
            <p className="text-darkGray">{persona?.room.name}</p>
          </div>
        )}
        {isMyPersona ? (
          <div className="py-4 flex justify-center gap-4">
            {persona.isEnable ? (
              <SpecificChat
                chatItem={persona}
                type="persona"
                buttonVariant="primary"
              />
            ) : (
              <p className="dark:text-primaryGray text-darkGray text-sm">
                Disabled
              </p>
            )}
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                showEditModal();
              }}
              variant="secondary"
            >
              Edit
            </Button>
            <div className="flex text-darkGray items-center">
              <Stats height={20} width={20} />
              <p>{persona?.personaClickCount || 0}</p>
            </div>
          </div>
        ) : (
          <div className="py-4 flex justify-center gap-4">
            {isIntroClicked ? (
              <Button
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showPersonaInfoModal();
                }}
                variant="vibhut-primary-outline"
              >
                Info
              </Button>
            ) : (
              <Button
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showPersonaInfoModal();
                }}
                variant="primary"
              >
                Info
              </Button>
            )}
            {persona.isEnable ? (
              <SpecificChat chatItem={persona} type="persona" />
            ) : (
              <p className="dark:text-primaryGray text-darkGray text-sm">
                Disabled
              </p>
            )}
            <div className="flex text-darkGray items-center">
              <Stats height={20} width={20} />
              <p>{persona?.personaClickCount || 0}</p>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}

export default PersonaCard;
