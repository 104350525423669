import React from "react";

interface TickProps {
  ratio?: string;
}

const Tick = ({ ratio }: TickProps) => {
  return (
    <svg
      width={ratio}
      height={ratio}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 6.68226L4.53686 9L9.75 3"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Tick.defaultProps = {
  ratio: "12",
};

export default Tick;
