import React from 'react';

function LeaveCall() {
  return (
    <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.57933 8.68772L1.8467 7.01453C1.68522 6.85986 1.55789 6.6731 1.47286 6.46621C1.38784 6.25932 1.34699 6.03687 1.35294 5.81311C1.35888 5.58934 1.41149 5.36919 1.5074 5.16675C1.6033 4.96432 1.74039 4.78406 1.90987 4.63753C3.82261 3.04328 6.08172 1.91921 8.50568 1.35565C10.7427 0.813731 13.0705 0.770789 15.324 1.22987C17.7751 1.70687 20.0783 2.75481 22.0469 4.28879C22.2206 4.42856 22.3632 4.60302 22.4656 4.80107C22.5681 4.99912 22.6281 5.21641 22.6419 5.43911C22.6558 5.66181 22.6231 5.88504 22.5459 6.09459C22.4688 6.30414 22.3489 6.49541 22.1939 6.65624L20.5174 8.39231C20.2489 8.67582 19.8867 8.85224 19.4983 8.88868C19.1099 8.92513 18.7218 8.81911 18.4063 8.5904C17.7811 8.12822 17.1054 7.73837 16.3922 7.42827C16.1103 7.3065 15.8694 7.1064 15.698 6.85168C15.5266 6.59695 15.4319 6.29828 15.4251 5.99105L15.4013 4.5478C13.1599 3.9753 10.8043 4.01875 8.58304 4.67357L8.6068 6.11683C8.61014 6.42412 8.52533 6.72611 8.3624 6.987C8.19948 7.24789 7.96528 7.45676 7.68756 7.58884C6.98501 7.92506 6.32259 8.3396 5.71294 8.82456C5.4019 9.06744 5.01267 9.18771 4.61928 9.16248C4.22589 9.13725 3.85577 8.96828 3.57933 8.68772Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LeaveCall;
