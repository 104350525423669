import { useContext } from 'react';
import { AuthUserContext } from '../../components/AuthUserProvider';

export default function useAuthUser() {
  const context = useContext(AuthUserContext);
  if (!context) {
    throw new Error('useAuthUser must be used within a AuthUserProvider');
  }
  return context;
}
