import React, { useState } from "react";
import Pin from "./Icons/Pin";
import PopOver from "./Popover";

function PinnedPopper({ className }) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className={className}>
      <div className="relative">
        <span
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <Pin />
        </span>
        {showTooltip && (
          <PopOver
            text="Pinned by Vibehut"
            size={36}
            leftPosition={"-left-14"}
          />
        )}
      </div>
    </div>
  );
}

export default PinnedPopper;
