import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getEventById } from "../../services/eventService";
import { useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { useCallback } from "react";
import { getAllApprovalRequestsOfEvent } from "../../services/eventApproval";
import { singleClaimedRoomRoutes, singleRoomRoutes } from "../../routes";

function EventNavigation({ eventIdWithSlug, eventId }) {
  const eventApprovalTab = JSON.parse(localStorage.getItem("eventApprovalTab"));
  const [event, setEvent] = useState(null);
  const isApprovalTabVisible =
    eventApprovalTab?.eventId === eventId &&
    eventApprovalTab.isApprovalTabVisible;
  const [isRequireApproval, setIsRequireApproval] =
    useState(isApprovalTabVisible);
  const [pendingApprovalRequest, setPendingApprovalRequest] = useState(0);
  const {
    state: {
      auth: { user },
    },
  } = useContext(StoreContext);

  const getEvent = useCallback(async () => {
    try {
      const response = await getEventById(user?.token, eventId);
      const _event = response.data?.event;
      setEvent(_event);
      const res = await getAllApprovalRequestsOfEvent(
        user?.token,
        eventId,
        "Pending",
      );
      if (res.data?.length) {
        setPendingApprovalRequest(res.data.length);
      }
      if (event?.isRequireApproval) {
        const isModerator = event?.room?.moderators.some(
          (mode) => mode === user?._id,
        );
        const isOwner = event.owner === user?._id;
        if (isModerator || isOwner) {
          setIsRequireApproval(true);
          storeLastEventDetailsInLocalStorage(true);
        } else {
          setIsRequireApproval(false);
          storeLastEventDetailsInLocalStorage(false);
        }
      } else {
        setIsRequireApproval(false);
        storeLastEventDetailsInLocalStorage(false);
      }
    } catch (error) {
      console.log("error::", error);
    }
  }, [eventId, user?.token]);

  useEffect(() => {
    if (eventId) {
      getEvent();
    }
  }, [eventId, getEvent]);

  const storeLastEventDetailsInLocalStorage = useCallback(
    (isApprovalTabVisible) => {
      localStorage.setItem(
        "eventApprovalTab",
        JSON.stringify({
          eventId,
          isApprovalTabVisible,
        }),
      );
    },
    [],
  );

  const roomPath = useMemo(() => {
    const path = event?.room?.slug
      ? singleClaimedRoomRoutes.path.replace(":slug", event?.room.slug)
      : singleRoomRoutes.path.replace(":roomId", event?.room?._id);
    return path;
  }, [event?.room?._id, event?.room.slug]);

  if (!event) {
    return <></>;
  }
  return (
    <div className="w-full flex gap-4 sm:gap-6 capitalize text-darkGray font-medium  transition-all duration-500 md:pt-0 md:pb-0 pt-5 py-5">
      <div className="hover:text-primary cursor-pointer">
        <NavLink
          exact
          activeClassName="text-primary"
          to={`/${eventIdWithSlug}`}
        >
          general
        </NavLink>
      </div>
      {isRequireApproval && (
        <div className="hover:text-primary cursor-pointer">
          <NavLink
            exact
            activeClassName="text-primary"
            to={`/${eventIdWithSlug}/approval-requests`}
            className=" flex items-center justify-center gap-1"
          >
            <div className="flex md:flex-row flex-col gap-1 items-center justify-center">
              <p> Approval Requests </p>
            </div>
            {pendingApprovalRequest > 0 && (
              <p className="bg-cvRed text-white h-5 w-5 rounded-full flex justify-center items-center text-xs">
                {pendingApprovalRequest}
              </p>
            )}
          </NavLink>
        </div>
      )}{" "}
      <div className="md:w-auto w-60">
        <span>Hosted in </span>
        <span className="hover:text-primary cursor-pointer">
          <NavLink exact activeClassName="text-primary" to={`${roomPath}`}>
            {event.room.name}
          </NavLink>
        </span>
      </div>
    </div>
  );
}

export default EventNavigation;
