import { Persona } from "../../types";
import NewTab from "../../VibehutUI/Icons/NewTab";

interface Props {
  persona: Persona;
  fullWidth?: boolean;
}

function CompanyAffiliationCard({ persona, fullWidth }: Props) {
  if (
    !persona.companyAffiliation?.title ||
    !persona.companyAffiliation?.isActive
  ) {
    return <></>;
  }
  return (
    <div className={`pb-4 ${fullWidth ? "w-full" : "md:w-1/2 w-full"} `}>
      <div className="relative flex items-center min-w-0 break-words shadow-soft-xl border dark:border-matteGray border-lightGray rounded-xl bg-clip-border p-2 gap-2 w-full">
        <div>
          <img
            src={persona.companyAffiliation.imgUrl}
            alt="Persona avatar"
            className="w-12 h-12 rounded-lg"
          />
        </div>
        <div>
          <p className="font-medium text-darkNight dark:text-frescoWhite">
            {persona.companyAffiliation.title}
          </p>
          <p className=" text-darkNight dark:text-frescoWhite  text-sm cursor-pointer">
            <a
              href={persona.companyAffiliation.link}
              target="_blank"
              className="flex items-center gap-1"
              rel="noopener noreferrer"
            >
              {persona.companyAffiliation.link}{" "}
              <span>
                {" "}
                <NewTab ratio="16" />
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyAffiliationCard;
