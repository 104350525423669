import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getMySubscribers } from "../../actions/subscriptions";
import Loader from "../../VibehutUI/Loader";
import UserCard from "../../VibehutUI/UserCard";
import { getUserData } from "../../services/userService";
import Empty from "../../VibehutUI/Empty";
import NoSubsciptions from "../../VibehutUI/Icons/NoSubsciptions";

function MySubscribers() {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth,
    subscription: { mySubscribers, isFetching },
  } = state;

  useEffect(() => {
    getMySubscribers(auth?.user?.token, dispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isFetching ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {mySubscribers && mySubscribers.length > 0 ? (
            <div className=" mb-2">
              <div className="video_page">
                <div className="subscription_list">
                  {mySubscribers.map((subscriber, index) => {
                    return (
                      <div className="border-b border-frescoWhite py-2">
                        <UserCard
                          key={index}
                          user={{
                            ...getUserData(subscriber.user),
                          }}
                          subscription={{
                            room: subscriber.room,
                            createdAt: subscriber.createdAt,
                          }}
                          userState={subscriber.user.userState}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <Empty
              Image={<NoSubsciptions height="150" width="200" />}
              title="No Subscribers"
              description="Your room subscribers will appear here"
              customPadding="pt-2"
              descTextSize="text-sm"
              showButton={false}
            />
          )}
        </>
      )}
    </div>
  );
}

export default MySubscribers;
