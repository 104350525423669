import { useState, useCallback, useRef } from "react";
import { MediaStreamTrackPublishOptions, Room } from "twilio-video";
import { ErrorCallback } from "../../../types";
import { useAppState } from "../../../state";

export default function useScreenShareToggle(
  room: Room | null,
  onError: ErrorCallback,
) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);
  const { setIsGalleryViewActive } = useAppState();
  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: {
          autoGainControl: false,
          channelCount: 2,
          echoCancellation: false,
          noiseSuppression: false,
          sampleRate: 48000,
          sampleSize: 16,
        },
        video: true,
      })
      .then((stream) => {
        const tracks = Array.from(stream.getTracks());
        const audioTrack = tracks.find((track) => track.kind === "audio");
        const videoTrack = tracks.find((track) => track.kind === "video");
        if (videoTrack) {
          room!.localParticipant
            .publishTrack(videoTrack, {
              name: "screen", // Tracks can be named to easily find them later
              priority: "low", // Priority is set to high by the subscriber when the video track is rendered
            } as MediaStreamTrackPublishOptions)
            .then((trackPublication) => {
              stopScreenShareRef.current = () => {
                room!.localParticipant.unpublishTrack(videoTrack);
                // TODO: remove this if the SDK is updated to emit this event
                room!.localParticipant.emit(
                  "trackUnpublished",
                  trackPublication,
                );
                videoTrack.stop();
                setIsSharing(false);
              };

              videoTrack.onended = stopScreenShareRef.current;
              setIsSharing(true);
            })
            .catch(onError);
        }
        if (audioTrack) {
          room!.localParticipant
            .publishTrack(audioTrack)
            .then((trackPublication) => {
              stopScreenShareRef.current = () => {
                room!.localParticipant.unpublishTrack(audioTrack);
                // TODO: remove this if the SDK is updated to emit this event
                room!.localParticipant.emit(
                  "trackUnpublished",
                  trackPublication,
                );
                audioTrack.stop();
              };

              audioTrack.onended = stopScreenShareRef.current;
            })
            .catch(onError);
        }
      })
      .catch((error) => {
        // Don't display an error if the user closes the screen share dialog
        if (
          error.message === "Permission denied by system" ||
          (error.name !== "AbortError" && error.name !== "NotAllowedError")
        ) {
          console.error(error);
          onError(error);
        }
      });
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      if (!isSharing) {
        shareScreen();
        return;
      }

      stopScreenShareRef.current();
      setIsGalleryViewActive(false);
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
