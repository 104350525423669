import axios from "../utils/axios";

export const getCoinPrice = async (
  username: string,
  publicKey: string,
  source?: any,
) => {
  return axios.post(
    "/bitclout/get-creator-coin-price",
    {
      username: username,
      publicKey: publicKey,
    },
    source,
  );
};

export const getAllEventsByUserId = async (
  username: string,
  publicKey: string,
  source?: any,
) => {
  return axios.post(
    "/bitclout/get-creator-coin-price",
    {
      username: username,
      publicKey: publicKey,
    },
    source,
  );
};

export const getUserCallCount = async (
  userId: string,
  token?: string,
  source?: any,
) => {
  return axios.post(
    "/user/get-calls-count",
    {
      from: userId,
    },
    source,
  );
};

export const updateMyConnection = async (
  data: {
    user: string;
    is_connected: boolean;
  },
  token?: string,
  source?: any,
) => {
  return axios.put("/connections/", data, source, token);
};

export const getUserConnection = async (
  userId: string,
  token?: string,
  source?: any,
) => {
  return axios.get(`/connections/single/${userId}`, source, token);
};

export const getUserFollowerCount = async (
  userId: string,
  token?: string,
  source?: any,
) => {
  return axios.get(`/connections/count-followers/${userId}`, source, token);
};

export const getUserFollowingCount = async (
  userId: string,
  token?: string,
  source?: any,
) => {
  return axios.get(`/connections/count-followings/${userId}`, source, token);
};

export const getUserRoomStatus = async (userId: string) => {
  return axios.get(`/connections/count-connections/${userId}`);
};

export const searchVibhutAndDesoUsers = async (keyword: string) => {
  return axios.get(`/user/search-invite-users?s=${keyword}`);
};

export const getRelevantUsers = async (token: string, source?: any) => {
  return axios.get("/user/get-relevant-user", source, token);
};

export const getUserActiveRoomStatus = async (token: string, source?: any) => {
  return axios.get("/user/get-my-room-status", source, token);
};

export const loginAsGuest = async (username: string, source?: any) => {
  return axios.post("/auth/login-as-guest", { username }, source);
};

export const getUserPublicProfile = async (userId: string, source?: any) => {
  return axios.get(`/user/profile/${userId}`, source);
};

export const leaveLastActiveCall = async (token: string, source?: any) => {
  return axios.post("/user/leave-last-active-call", {}, source, token);
};
