import React, { useContext, useEffect, useMemo, useState } from "react";
import Linkify from "react-linkify";
import moment from "moment";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import Add from "../../VibehutUI/Icons/Add";
import Chat from "./Chat";
import KickedUser from "./KickedUser";
import useVibehutChatContext from "../../hooks/useVibehutChatContext/useVibehutChatContext";
import InviteUserModal from "../../VibehutUI/InviteUserModel";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../theme";
import useQuery from "../../../hooks/useQuery";
import { frontEndURL } from "../../constants";
import useGalleryViewParticipants from "../../hooks/useGalleryViewParticipants/useGalleryViewParticipants";
import { updateUnmatchedUserCountsInCall } from "../../services/roomService";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import { useAppState } from "../../state";
import ShareCallModal from "../../VibehutUI/ShareCallModal";
import ShareNodes from "../../VibehutUI/Icons/ShareNodes";
import Avatar from "../../VibehutUI/Avatar";
import Close from "../../VibehutUI/Icons/Close";
import Tick from "../../icons/Tick";
import {
  VibehutDataContext,
  joinRequestFromGuest,
} from "../VibehutDataProvider";
import LiveStreamChat from "../../../components/chat/LiveStreamChat";
import useLiveVideo from "../../hooks/useLiveVideo/useLiveVideo";
import { StoreContext } from "../../../Context/StoreContext";
import * as actionTypes from "../../../constants";

export default function VibehutCallSidebar() {
  const { unreadMessagesCount, setIsChatTabOpen } = useVibehutChatContext();
  const { isLive, liveStreamChatId } = useLiveVideo();

  const { room } = useVideoContext();
  const { isFullScreenViewActive } = useAppState();
  const query = useQuery();
  const v = query.get("v") || "";
  const { roomData, instantRoomId } = useVibehutData(globals.roomId, v);
  //Having a great call with [username 1 + count others] in [room name]. Join here: [url]
  const [isOpenInvite, setIsOpenInvite] = useState(false);
  const [isShareCallOpen, setIsShareCallOpen] = useState(false);
  const [groupActiveTab, setGroupActiveTab] = useState<string>("members");
  const CustomLinkify: any = Linkify;
  const { authUser } = useAuthUser();
  const participants = useGalleryViewParticipants();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { approvalRequestFromGuest } = useContext(VibehutDataContext);
  const { requestAcceptByHost } = useVibehutData(globals.roomId);
  const {
    state: {
      chat: { liveStreamUnreadMessageCount },
    },
    dispatch,
  } = useContext(StoreContext);

  const callId = room!.name;

  const _liveUsers = roomData?.calls[callId];
  const liveUsers = useMemo(() => {
    const foundIdx = _liveUsers?.findIndex(
      (user) => user._id === roomData?.owner?._id,
    );
    if (foundIdx !== -1) {
      const owner = _liveUsers?.find(
        (user) => user._id === roomData?.owner?._id,
      );
      if (owner) {
        _liveUsers.splice(foundIdx, 1);
        _liveUsers.unshift(owner);
      }
    }

    return _liveUsers || [];
  }, [roomData?.owner?._id, _liveUsers]);

  const participantLength = participants.length;
  const liveUsersLength = liveUsers.length;

  const callUrl = instantRoomId
    ? `${frontEndURL}/call?v=${instantRoomId}`
    : roomData.isWaveRoom
      ? `${frontEndURL}/call?roomKey=${roomData._id}`
      : `${frontEndURL}/rooms/${roomData._id}`;

  const updateUnmatchedUserCountsInCallFun = async () => {
    await updateUnmatchedUserCountsInCall(
      authUser.token,
      callId,
      participants,
      liveUsers,
    );
  };
  useEffect(() => {
    if (liveUsersLength !== participantLength + 1) {
      updateUnmatchedUserCountsInCallFun();
    }
  }, [participantLength, liveUsersLength]);

  const componentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ) => (
    <a
      href={decoratedHref}
      key={key}
      target="_blank"
      className="text-primary text-weight-bold break-words underline custom-one-line-clamp text-right"
      rel="noreferrer"
    >
      {decoratedText}
    </a>
  );

  const isVisible = useMemo(() => {
    if (isFullScreenViewActive) return "hidden";
    if (isMobile) return "hidden";
    return "visible";
  }, [isFullScreenViewActive, isMobile]);

  return (
    <div className={`pt-3 pr-3 bg-darkNight ${isVisible}`}>
      <div className="bg-componentColor rounded-xl h-full relative">
        <div className="h-full">
          <div className="wrapper px-2 pt-4">
            <nav className="bg-matteGray rounded-xl py-2">
              <input
                type="radio"
                name="tab"
                id="members"
                checked={groupActiveTab === "members"}
                className="hidden"
              />
              <input
                type="radio"
                name="tab"
                id="chat"
                className="hidden"
                checked={groupActiveTab === "chat"}
              />
              <input
                type="radio"
                name="tab"
                id="live"
                className="hidden"
                checked={groupActiveTab === "live"}
              />
              <label
                htmlFor="members"
                className="members text-center"
                onClick={() => {
                  dispatch({
                    type: actionTypes.UPDATE_ACTIVE_TAB,
                    payload: "members",
                  });
                  setGroupActiveTab("members");
                  setIsChatTabOpen(false);
                }}
              >
                <span className="text-white text-sm">Members</span>
              </label>
              <label
                htmlFor="chat"
                className="chat text-center"
                onClick={() => {
                  dispatch({
                    type: actionTypes.UPDATE_ACTIVE_TAB,
                    payload: "chat",
                  });
                  setGroupActiveTab("chat");
                  setIsChatTabOpen(true);
                }}
              >
                <span className="text-white  text-sm">
                  Chat
                  {unreadMessagesCount === 0 ? (
                    ""
                  ) : groupActiveTab === "chat" ? (
                    ""
                  ) : (
                    <span className="bg-darkGray ml-1 px-2 rounded-full ">
                      {unreadMessagesCount}
                    </span>
                  )}
                </span>
              </label>
              <label
                htmlFor="live"
                className="live text-center"
                onClick={() => {
                  dispatch({
                    type: actionTypes.UPDATE_ACTIVE_TAB,
                    payload: "live",
                  });
                  dispatch({
                    type: actionTypes.RESET_LIVE_STREAM_MESSAGE_COUNT,
                  });
                  setGroupActiveTab("live");
                  setIsChatTabOpen(false);
                }}
              >
                <span className="text-white text-sm">
                  Live{" "}
                  {liveStreamUnreadMessageCount === 0 ? (
                    ""
                  ) : groupActiveTab === "live" ? (
                    ""
                  ) : (
                    <span className="bg-darkGray ml-1 px-2 rounded-full ">
                      {liveStreamUnreadMessageCount}
                    </span>
                  )}
                </span>
              </label>
              <div className="tab rounded-xl bg-darkGray !w-1/3"></div>
            </nav>
          </div>
          <div
            className="mt-3"
            style={{
              height: "calc(100% - 3.9rem)",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: groupActiveTab === "members" ? "block" : "none",
              }}
              className="h-full"
            >
              <div className="flex flex-col justify-between h-full">
                <div className="flex-1 overflow-hidden px-4 ">
                  <div className="w-full overflow-y-auto custom-scrollbar h-full">
                    {liveUsers?.map((user, index) => (
                      <div
                        className={` ${
                          liveUsers.length - 1 === index
                            ? ""
                            : "border-b border-matteGray py-1"
                        }`}
                        key={index + user._id}
                      >
                        <KickedUser user={user} />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="w-full">
                  {roomData?.isRequireApproval && (
                    <div className="px-3 mb-1">
                      <p className="font-bold text-white ">
                        Waiting to join{" "}
                        {approvalRequestFromGuest.length > 0 &&
                          `·${approvalRequestFromGuest.length}`}
                      </p>
                      <div className="h-full">
                        <div className="mt-1 overflow-y-auto h-full custom-scrollbar text-white">
                          {approvalRequestFromGuest.length > 0 ? (
                            <div className="text-black">
                              <div className="flex flex-col gap-1 ">
                                {approvalRequestFromGuest.map(
                                  (
                                    item: joinRequestFromGuest,
                                    index: number,
                                  ) => (
                                    <div key={index} className="py-1">
                                      <div className="flex justify-between items-center">
                                        <div className="flex items-center gap-2">
                                          <Avatar
                                            username={item.username}
                                            src={item.profilePicture}
                                            size="sm"
                                          />
                                          <div>
                                            <p className="max-w-28 text-frescoWhite  break-words">
                                              {item.username}
                                            </p>
                                            <p className="text-frescoWhite text-[11px] italic">
                                              Asked to join{" "}
                                              {moment(item.joinTime).fromNow()}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                          <span>
                                            <button
                                              className="bg-matteGray p-9px rounded-full cursor-pointer hover:bg-cvRed"
                                              onClick={() => {
                                                requestAcceptByHost(
                                                  false,
                                                  item,
                                                );
                                              }}
                                            >
                                              <Close color="white" ratio="11" />
                                            </button>
                                          </span>
                                          <span className="py-[1px] flex items-end">
                                            <button
                                              onClick={() => {
                                                requestAcceptByHost(true, item);
                                              }}
                                              className="bg-primary hover:bg-primaryHover p-[7px] rounded-full cursor-pointer "
                                            >
                                              <Tick ratio="15" />
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          ) : (
                            <p className="py-4 flex justify-center items-start">
                              Users waiting will appear here.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {roomData?.promotion?.isActive && (
                    <div
                      className={`px-3 py-1 space-y-1 flex-grow ${
                        roomData?.isRequireApproval
                          ? "border-t border-matteGray"
                          : ""
                      } `}
                    >
                      <p className="text-sm text-frescoWhite">
                        {roomData.promotion.title}
                      </p>
                      <img
                        src={roomData.promotion.imgUrl}
                        alt=""
                        className="max-w-full object-contain aspect-auto mx-auto overflow-hidden min-h-[60px] max-h-72 rounded-md drop-shadow-md transition-all "
                      />
                      <CustomLinkify componentDecorator={componentDecorator}>
                        {roomData.promotion.link}
                      </CustomLinkify>
                    </div>
                  )}
                  <div className=" border-t border-matteGray md:py-2 lg:py-3 gap-2 flex w-full ">
                    <div
                      className="flex  items-center px-4 gap-3 cursor-pointer"
                      onClick={() => setIsOpenInvite(true)}
                    >
                      <span className="p-[9.5px] bg-matteGray rounded-full">
                        <Add />
                      </span>
                      <div className="text-white text-sm">Invite Someone</div>
                    </div>
                    <div
                      className="flex  items-center px-4 gap-3 cursor-pointer"
                      onClick={() => setIsShareCallOpen(true)}
                    >
                      <span className="p-[9.5px] bg-matteGray rounded-full">
                        <ShareNodes color="#B5B5B5" />
                      </span>
                      <div className="text-white text-sm">Share Call</div>
                    </div>
                  </div>
                </div>
                <ShareCallModal
                  users={liveUsers}
                  roomName={roomData.name}
                  isOpen={isShareCallOpen}
                  setIsOpen={setIsShareCallOpen}
                  url={callUrl}
                />
                <InviteUserModal
                  isOpen={isOpenInvite}
                  setIsOpen={setIsOpenInvite}
                  roomId={roomData._id}
                  callsMap={roomData.calls}
                  callId={callId}
                />
              </div>
            </div>
            <div
              style={{
                display: groupActiveTab === "chat" ? "block" : "none",
              }}
            >
              <Chat roomId={roomData._id} />
            </div>
            {!isMobile && (
              <div
                style={{
                  display: groupActiveTab === "live" ? "block" : "none",
                }}
              >
                <LiveStreamChat
                  roomId={liveStreamChatId}
                  fromCall
                  isLive={isLive}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
