import { useContext, useEffect, useState } from "react";
import { getUserData } from "../../../services/userService";
import { Persona } from "../../../types";
import PersonaCreatorCard from "../PersonaCreatorCard";
import PersonaIntroHeader from "./PersonaIntroHeader";
import PersonaIntroCard from "./PersonaIntroCard";
import { updatePersonaClickCount } from "../../../actions/personaAction";
import { StoreContext } from "../../../Context/StoreContext";
import MatchingSummary from "./MatchingSummary";
import Loader from "../../../VibehutUI/Loader";
import { getPersonaById } from "../../../services/personaService";
import CompanyAffiliationCard from "../CompanyAffiliationCard";

interface Props {
  _persona: Persona;
  loadFromComponent?: boolean;
}

function PersonaIntro(props: Props) {
  const { _persona, loadFromComponent } = props;
  const { state, dispatch } = useContext(StoreContext);
  const [persona, setPersona] = useState(_persona);
  const {
    auth: { user },
  } = state;
  const [showFullText, setShowFullText] = useState(false);
  const [isPersonaLoading, setIsPersonaLoading] = useState(loadFromComponent);

  const truncateText = (text: string, limit: number) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }

    return text;
  };

  useEffect(() => {
    if (loadFromComponent && user?.token && _persona._id) {
      const controller = new AbortController();
      const { signal } = controller;
      getPersonaById(_persona._id, user?.token, { signal })
        .then((res) => {
          setPersona(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsPersonaLoading(false);
        });
    }
  }, [_persona._id, loadFromComponent, user?.token]);

  useEffect(() => {
    if (user && _persona._id && !loadFromComponent) {
      updatePersonaClickCount(_persona._id, user?.token, dispatch);
    }
  }, [dispatch, _persona, user, loadFromComponent]);

  if (isPersonaLoading) {
    return <Loader />;
  }

  return (
    <div className="px-8 pb-8 max-h-[80vh] custom-scrollbar overflow-auto">
      <PersonaIntroHeader persona={persona} />
      <PersonaCreatorCard user={getUserData(persona.owner)} persona={persona} />
      <CompanyAffiliationCard persona={persona} fullWidth />
      <div className="pt-4">
        <p className="dark:text-frescoWhite font-bold">About</p>
        <p className="leading-normal text-sm dark:text-frescoWhite">
          {showFullText
            ? persona.generatedBio
            : persona.generatedBio.length > 100
              ? truncateText(persona.generatedBio, 100)
              : persona.generatedBio}
          {persona.generatedBio.length > 100 && !showFullText && (
            <button
              onClick={(e) => {
                setShowFullText(!showFullText);
                e.stopPropagation();
              }}
              className="text-primary"
            >
              See More
            </button>
          )}
        </p>
      </div>
      <div className="pt-4">
        <PersonaIntroCard />
      </div>
      <div className="pt-4">
        <MatchingSummary
          personaIntro={persona.personaIntro}
          personaId={persona._id as string}
        />
      </div>
    </div>
  );
}

export default PersonaIntro;
