import axios from "../utils/axios";

export function getSystemSetting() {
  return new Promise(function (resolve, reject) {
    axios
      .get("/system-setting", {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getExchangeRate(noCache = false) {
  const newHeaders = {};
  if (noCache) {
    newHeaders["cache-control"] = "no-cache";
  }
  return axios
    .get("https://node.deso.org/api/v0/get-exchange-rate", {
      headers: newHeaders,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("ERROR:", error);
    });
}
