import React from "react";
import Close from "../Icons/Close";
import NoResult from "../Icons/NoResult";
import SendInvite from "./SendInvite";
import UserCardSkeleton from "../Skeletons/UserCardSkeleton";

function InviteSearchBar({
  isLoading,
  value,
  handleChange,
  results = [],
  setValue,
  setInvitedUsers,
  invitedUsers,
  desoInvitedUsers,
  setDesoInvitedUsers,
  rsvpUser,
}) {
  return (
    <>
      <div className={`searchbarDiv`}>
        <div className="relative">
          <input
            type="text"
            className="w-full rounded-xl border border-lightGray select-none outline-none placeholder-notifyBg search-input bg-white"
            placeholder="Search..."
            onChange={handleChange}
            value={value}
          />
          <span
            className="absolute top-3 right-3 bg-notifyBg rounded-full p-[5px] cursor-pointer"
            onClick={() => {
              setValue("");
            }}
          >
            <Close color="#E5E5E5" ratio="8" />
          </span>
        </div>

        {isLoading ? (
          <div
            className="overflow-y-scroll  custom-scrollbar "
            style={{ maxHeight: "40vh" }}
          >
            <UserCardSkeleton />
          </div>
        ) : (
          value.length > 0 && (
            <div
              className={`w-full my-3 flex-1 overflow-y-scroll  custom-scrollbar max-h-[47vh]  md:max-h-[42vh]`}
            >
              {results.length > 0 ? (
                results.map((user, index) => (
                  <SendInvite
                    key={index}
                    user={user}
                    invitedUsers={invitedUsers}
                    setInvitedUsers={setInvitedUsers}
                    desoInvitedUsers={desoInvitedUsers}
                    setDesoInvitedUsers={setDesoInvitedUsers}
                    rsvpUser={rsvpUser}
                  />
                ))
              ) : (
                <div className="relative h-72 w-full ">
                  <div className="absolute top-1/2 transform -translate-y-1/2 z-50 left-1/2 -translate-x-1/2 w-full">
                    <div>
                      <div className="flex justify-center">
                        <NoResult />
                      </div>

                      <p className="text-darkNight text-center dark:text-white text-lg font-bold font-ubuntu pt-2 w-full">
                        Nobody found
                      </p>
                      <p className="text-darkGray text-center dark:text-primaryGray text-base font-normal font-ubuntu pt-2 w-full ">
                        Adjust your search and try again.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
    </>
  );
}

export default InviteSearchBar;
