import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";
import axios from "../utils/axios";
import Loader from "../VibehutUI/Loader";

function StripeReturnCallback(props) {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user: authUser },
  } = state;
  const connectStripeAcc = async () => {
    axios
      .post(
        `/stripe/onboard`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authUser?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data.url;
        } else {
          console.log("RESPONSE not succeed", res);
        }
      })
      .catch((error) => {
        console.log("ERR IN CATCH: ", error);
      });
  };

  React.useEffect(() => {
    connectStripeAcc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [handleRes, setHandleRes] = React.useState(
    "Redirecting to Stripe Onboarding url"
  );

  return (
    <div className="font-ubuntu">
      <div>
        <div>
          <React.Fragment>
            <div className="flex flex-col  items-center">
              <h2 className="text-2xl flex-grow font-bold text-black dark:text-frescoWhite items-center pt-24 pb-12">
                Oops ! You Refresh the page
              </h2>
              <Loader />
              <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite items-center pt-16 pb-12">
                {handleRes}
              </p>
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

export default StripeReturnCallback;
