import React from "react";

const HidEye = () => {
  return (
    <svg
      width="20"
      height="20"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7654 11.7664C11.5365 12.012 11.2605 12.209 10.9538 12.3456C10.6472 12.4823 10.3161 12.5557 9.98044 12.5617C9.64476 12.5676 9.31133 12.5058 9.00004 12.3801C8.68875 12.2544 8.40597 12.0672 8.16857 11.8298C7.93117 11.5924 7.74403 11.3096 7.61829 10.9984C7.49255 10.6871 7.4308 10.3536 7.43673 10.018C7.44265 9.68228 7.51612 9.35123 7.65276 9.04457C7.7894 8.7379 7.98641 8.4619 8.23203 8.23303M14.9487 14.9497C13.5242 16.0355 11.7896 16.6371 9.9987 16.6664C4.16536 16.6664 0.832031 9.99969 0.832031 9.99969C1.86861 8.06794 3.30631 6.3802 5.0487 5.0497L14.9487 14.9497ZM8.2487 3.53303C8.82231 3.39876 9.40959 3.33164 9.9987 3.33303C15.832 3.33303 19.1654 9.99969 19.1654 9.99969C18.6595 10.946 18.0562 11.837 17.3654 12.658L8.2487 3.53303Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.50008 1.5L18.5002 18.5"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HidEye;
