import Button from "../../VibehutUI/Button";
import globals from "../../globals";
import Loader from "../../VibehutUI/Loader";
import { useState } from "react";

interface Props {
  kind: "watch" | "live";
  price: number;
  onUnlock: () => void;
}

function UnlockToWatchModal(props: Props) {
  const kind = props.kind === "watch" ? "video" : "live stream";

  const [isLoading, setIsLoading] = useState(false);

  const handleUnlock = () => {
    setIsLoading(true);
    props.onUnlock();
  };

  return (
    <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
      <div>
        <p>
          To watch this {kind} you are required to pay ${props.price.toFixed(2)}
          .
        </p>
        <div className="pt-3 flex flex-row-reverse">
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={handleUnlock}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader ratio="h-4 w-4" color="white" />
            ) : (
              "Click to Checkout"
            )}
          </Button>

          <div className="mr-2">
            {" "}
            <Button
              size="vibhut-sm"
              variant="vibhut-secondary"
              onClick={() => {
                globals.hideModal();
              }}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnlockToWatchModal;
