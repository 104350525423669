import React from "react";

function SearchResultCard({ image, text }) {
  return (
    <div
      title={text}
      className=" h-24 flex justify-center items-end rounded-xl cursor-pointer p-2 overlay-effect relative bg-no-repeat bg-cover bg-blend-multiply"
      style={{
        backgroundImage: `url(
          ${image ? image : `${process.env.PUBLIC_URL}/vibehut-rooms.png`}
        )`,
      }}
    >
      <div className=" text-frescoWhite z-10">
        {text.split(" ").slice(0, 3).join(" ")}
      </div>
    </div>
  );
}

export default SearchResultCard;
