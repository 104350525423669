import { useContext } from 'react';
import { LiveVideoContext } from '../../components/LiveVideoProvider';

export default function useLiveVideo() {
  const context = useContext(LiveVideoContext);
  if (!context) {
    throw new Error('useLiveVideo must be used within a LiveVideoContext');
  }
  return context;
}
