import React, { useMemo } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";
import { profileRoute } from "../../routes";
import Avatar from "../../VibehutUI/Avatar";
import { blockUserFromRoom } from "../../actions/roomAction";
import NoConnections from "../Icons/NoConnections";

const BlockUserTableList = ({ subscriberList, room }) => {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    room: { blockedUsers },
  } = state;

  const handleBlockUser = async (userId, isBlock = true) => {
    await blockUserFromRoom(
      {
        roomId: room._id,
        ownerId: user._id,
        blocked: isBlock,
        userId: userId,
      },
      user?.token,
      dispatch
    );
  };

  const isUserBlocked = (userId) => {
    return blockedUsers?.some((blockedUser) => blockedUser._id === userId);
  };

  const showBlockCheckMark = useMemo(() => {
    return (
      room?.owner?._id === user?._id ||
      room?.moderators?.some((moderatorId) => moderatorId === user?._id)
    );
  }, [room?.moderators, room?.owner?._id, user?._id]);

  if (blockedUsers.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center">
        <p className="text-lg font-medium text-darkGray mt-4">
          No Blocked Users Yet
        </p>
      </div>
    );
  }
  return (
    <div
      className={`flex flex-col justify-start items-start  overflow-x-auto custom-scrollbar`}
    >
      <table className=" table-auto border dark:border-matteGray">
        <thead className="border-b dark:bg-darkNight dark:border-matteGray border-lightGray dark:hover:bg-navyGray ">
          <tr className="text-white dark:text-frescoWhite">
            <th
              scope="col"
              className="text-sm font-medium text-gray-900  dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
            >
              S.N
            </th>
            <th
              scope="col"
              className="text-sm font-medium text-gray-900  dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
            >
              Name
            </th>

            {showBlockCheckMark && (
              <>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                >
                  Blocked
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {subscriberList.map((data, index) => {
            return (
              <tr
                key={index}
                className=" border-b dark:border-matteGray transition duration-300 ease-in-out hover:bg-gray-100  dark:hover:bg-navyGray"
              >
                <td className="px-6 py-4 text-black dark:text-frescoWhite whitespace-nowrap text-sm font-medium  border-r dark:border-matteGray">
                  {index + 1}
                </td>
                <td class="text-sm text-primary cursor-pointer font-light px-6 py-4 whitespace-nowrap border-r dark:border-matteGray flex space-x-2  items-center">
                  <Avatar
                    src={data.profile_picture}
                    size="sm"
                    username={data.username}
                  />
                  <Link
                    to={profileRoute.path.replace(
                      ":username",
                      data?.vibehutUsername || data.userId
                    )}
                  >
                    {data?.username?.length > 15
                      ? data.username.slice(0, 15) + "..."
                      : data.username}
                  </Link>
                </td>

                {showBlockCheckMark && (
                  <>
                    <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                      <input
                        type="checkbox"
                        name="roomTypes"
                        className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                        checked={isUserBlocked(data._id)}
                        value={data.blocked || false}
                        onChange={(e) =>
                          handleBlockUser(data._id, e.target.checked)
                        }
                      />
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BlockUserTableList;
