import React, { useContext, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { StoreContext } from "../../Context/StoreContext";
import InstantNotificationCard from "./InstantNotificationCard";
import { baseURL } from "../../utils/axios";
import * as io from "socket.io-client";
import * as actionTypes from "../../constants";
import Button from "../../VibehutUI/Button";

function InstantNotification() {
  const { state, dispatch } = useContext(StoreContext);
  const { auth } = state;
  const { notification } = state;
  const socketRef = useRef();

  const removeInstantNotification = (id) => {
    dispatch({
      type: actionTypes.REMOVE_INSTANT_NOTIFICATION,
      payload: {
        id,
      },
    });
  };

  const declineCall = (userId, notificationId, roomId) => {
    removeInstantNotification(notificationId);
    const disconnectSocket = () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = undefined;
      }
    };
    if (auth?.user?.token) {
      socketRef.current = io.connect(`${baseURL}`, {
        auth: {
          token: auth?.user?.token,
        },
      });
      socketRef.current.on("connect", () => {
        socketRef.current.emit("decline_call", { userId, roomId });
      });
    } else {
      disconnectSocket();
    }
  };

  const removeAllInstantNotifications = () => {
    dispatch({
      type: actionTypes.REMOVE_ALL_INSTANT_NOTIFICATION,
    });
  };

  return (
    <ul className="fixed bottom-0 right-4 top-4 flex flex-col list-none justify-start gap-2 z-50 ">
      <AnimatePresence initial={false}>
        {notification.instantNotifications.length > 1 && (
          <div className="flex justify-end">
            <Button
              variant="secondary"
              size="sm"
              onClick={removeAllInstantNotifications}
            >
              Clear All
            </Button>
          </div>
        )}

        {notification.instantNotifications.map((item, index) => (
          <InstantNotificationCard
            key={index}
            item={item}
            declineCall={declineCall}
          />
        ))}
      </AnimatePresence>
    </ul>
  );
}

export default InstantNotification;
