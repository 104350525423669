import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import Button from "../../VibehutUI/Button";
import moment from "moment";
import { getUserData } from "../../services/userService";
import Avatar from "../Avatar";
import Loader from "../Loader";
import { StoreContext } from "../../Context/StoreContext";
import ProfileModal from "../ProfileModal";
import { useHistory } from "react-router-dom";
import { getNotifications } from "../../actions/notificationActions";
import Empty from "../Empty";
import NoNotification from "../Icons/NoNotification";
import { checkCreateRoomPermission, renderPostText } from "../../utils/utils";
import WaveButton from "../WaveButton";
import { frontEndURL, videoAppUrl } from "../../utils/axios";
import {
  checkRoomHasLiveEventAndUserAccess,
  generateInstantCallUrl,
} from "../../services/rooomService";
import { openEventSubscriptionModal } from "../../actions/eventModalActions";
import { NotificationTypes, activeTabEditProfile } from "../../constants";
import {
  openContactModal,
  openGamificationModal,
  openPlanModal,
  openWalletModal,
} from "../../actions/profileModalActions";
import { EditProfileModal } from "../../components/profile/EditProfile";
import CreateRoom from "../../components/rooms/CreateRoom";
import { showLoginModal } from "../../actions/uiModalActions";
import ConfirmationModal from "../ConfirmationModal";
import CreatedLinkModal from "../../components/CreatedLinkModal";
import {
  profileRoute,
  singleClaimedRoomRoutes,
  singleRoomRoutes,
} from "../../routes";
import SpecificChat from "../../components/chat/SpecificChat";
import NotificationSkeleton from "../Skeletons/NotificationSkeleton";
import PersonaWeeklyIntro from "../../components/peronas/PersonaWeeklyIntro";
import useIsPWA from "../../hooks/usePWA";
import globals from "../../globals";

const Notification = ({ notification, openUserModal }) => {
  const { state, dispatch } = useContext(StoreContext);
  const isPWA = useIsPWA();
  const {
    auth: { user },
    room: { myRoomsCount },
    role,
  } = state;
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(activeTabEditProfile.profileTab);
  const [isOpen, setIsOpen] = useState(false);
  const [isInstanceCall, setIsInstanceCall] = useState(undefined);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [roomUUID, setRoomUUID] = useState(undefined);
  const [srcError, setSrcError] = useState(false);
  const [chatItem, setChatItem] = useState(undefined);
  const [notificationImageType, setNotificationImageType] = useState("general");

  const checkAccessAndRedirect = async (roomId) => {
    setIsFetching(true);
    const checkRoomAccess = await checkRoomHasLiveEventAndUserAccess(
      roomId,
      user.token,
    );
    if (checkRoomAccess.data.isUserAllowed) {
      setIsFetching(false);
      if (!isPWA) {
        window.open(`${videoAppUrl}/call?roomKey=${roomId}`);
      } else {
        window.location.href = `${videoAppUrl}/call?roomKey=${roomId}`;
      }
    } else {
      setIsFetching(false);
      openEventSubscriptionModal(
        dispatch,
        checkRoomAccess.data.event,
        `There is a live event going on in this room. Please subscribe to join the event. Otherwise you can wait join the room after the event ends.`,
        "event",
      );
    }
  };
  const startCall = (roomKey) => {
    checkAccessAndRedirect(roomKey);
  };

  const startPassCall = (roomKey, password) => {
    history.push({
      pathname: `/call`,
      search: `?roomKey=${roomKey}&pass=${password}`,
      state: { previousPath: history.location.pathname },
    });
  };

  const handleCreateRoom = async () => {
    if (!user) {
      return showLoginModal(dispatch);
    }
    if (myRoomsCount === undefined) {
      return;
    }
    if (checkCreateRoomPermission(role, myRoomsCount, user)) {
      setIsOpen(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const copyInstantCallLink = async () => {
    try {
      setIsInstanceCall("copy");
      if (!user) {
        setIsInstanceCall(undefined);
        showLoginModal(dispatch);
        return;
      }
      const res = await generateInstantCallUrl(user.token);
      const url = res.data;
      const uuid = url.split(`${frontEndURL}/call?v=`)[1];
      setRoomUUID(uuid);
      setIsInstanceCall(undefined);
    } catch (error) {
      console.log(error);
    }
  };
  const roomRedirectFun = (room) => {
    setChatItem(room);
  };

  const showPersonaInfoModal = useCallback(() => {
    if (!user) {
      showLoginModal(dispatch);
      return;
    }
    globals.showModal(
      "Weekly Persona Matching",
      <PersonaWeeklyIntro />,
      true,
      true,
    );
  }, [dispatch, user]);

  const srcImage = useMemo(() => {
    if (
      notification.type === NotificationTypes.RSVP_REMINDER ||
      notification.type ===
        NotificationTypes.SUBSCRIBED_ROOM_EVENT_STARTED_IN_10
    ) {
      setNotificationImageType("event");
      return notification.eventsMentioned[0]
        ? notification.eventsMentioned[0].cover?.urls?.small
        : notification.usersMentioned[0]
          ? getUserData(notification.usersMentioned[0]).profile_picture
          : notification.roomsMentioned[0]
            ? notification.roomsMentioned[0].cover?.urls?.small
            : "";
    }
    return notification.usersMentioned[0]
      ? getUserData(notification.usersMentioned[0]).profile_picture
      : notification.eventsMentioned[0]
        ? notification.eventsMentioned[0].cover?.urls?.small
        : notification.roomsMentioned[0]
          ? notification.roomsMentioned[0].cover?.urls?.small ||
            `${process.env.PUBLIC_URL}/images/room-fallback.png`
          : "";
  }, [notification]);

  if (!notification?.type) {
    return <></>;
  }

  return (
    <>
      {notification.type === "waved" &&
      notification?.wavesMentioned?.length === 0 ? (
        <></>
      ) : (
        <div key={notification._id} className="flex pt-3.5">
          <div className="flex-none">
            <div className="mr-3">
              {notification.type.includes("ami") ? (
                <div
                  className={`w-10 h-10 bg-frescoWhite text-navyGray
                  rounded-full flex items-center justify-center cursor-pointer mt-1.5`}
                >
                  {srcError ? (
                    <span className="w-[95%] h-[95%] rounded-full flex items-center justify-center bg-primary text-white ">
                      Ami
                    </span>
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/robot.webp`}
                      alt="ami"
                      className="w-6 h-6"
                      onError={(e) => {
                        e.target.onerror = null;
                        setSrcError(true);
                      }}
                    />
                  )}
                </div>
              ) : (
                <Avatar
                  widthClassName="w-10"
                  heightClassName="h-10"
                  src={srcImage}
                  username={
                    notification.usersMentioned[0]
                      ? getUserData(notification.usersMentioned[0]).username
                      : undefined
                  }
                  size="sm"
                  notification={notification.type}
                  NotificationType={notificationImageType}
                />
              )}
            </div>
          </div>
          <div className="text-sm font-ubuntu flex-1 m-1 mr-3 leading-4 text-gray-700 dark:text-gray-300">
            <div>
              {renderPostText(
                notification,
                openUserModal,
                false,
                roomRedirectFun,
              )}
              {notification.type ===
                NotificationTypes.AMI_WEEKLY_PERSONA_MATCHES && (
                <span
                  className="underline text-xs text-primary cursor-pointer"
                  onClick={() => {
                    showPersonaInfoModal();
                  }}
                >
                  See my matches
                </span>
              )}
              {notification.type.includes("ami") &&
                notification.type !==
                  NotificationTypes.AMI_WEEKLY_PERSONA_MATCHES && (
                  <span
                    className="underline text-xs text-primary cursor-pointer"
                    onClick={() => {
                      openGamificationModal(dispatch, true);
                    }}
                  >
                    See all things to do
                  </span>
                )}
            </div>

            <div className="text-gray-500 text-xs">
              {moment(notification.createdAt).startOf().fromNow()}
            </div>
          </div>
          <div className="flex-none  my-1">
            {notification.type === NotificationTypes.INVITE_USER_FOR_ROOM ? (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  const path = notification.roomsMentioned[0]?.slug
                    ? singleClaimedRoomRoutes.path.replace(
                        ":slug",
                        notification.roomsMentioned[0].slug,
                      )
                    : singleRoomRoutes.path.replace(
                        ":roomId",
                        notification.roomsMentioned[0]?._id,
                      );
                  history.push(path);
                }}
              >
                View Invitation
              </Button>
            ) : undefined}
            {notification.type === "user_send_message" ? (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  history.push(`/chat/${notification.usersMentioned[0]._id}`);
                }}
              >
                Reply
              </Button>
            ) : undefined}
            {notification.type === NotificationTypes.AMI_CREATE_ROOM && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={handleCreateRoom}
              >
                Create
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_SEND_INSTANT_CALL && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={copyInstantCallLink}
              >
                {isInstanceCall === "copy" ? (
                  <div className=" ml-2 mt-0.5">
                    <Loader ratio="h-5 w-5" />
                  </div>
                ) : (
                  <>Create</>
                )}
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_ADD_VIBEBUCKS && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  openWalletModal(dispatch, true);
                }}
              >
                Add
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_ADD_SERVICES && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  setActiveTab(activeTabEditProfile.servicesTab);
                  setIsEditProfileOpen(true);
                }}
              >
                Add
              </Button>
            )}
            {(notification.type === NotificationTypes.AMI_ADD_SOCIALS ||
              notification.type === NotificationTypes.AMI_ADD_LOCATION ||
              notification.type === NotificationTypes.AMI_ADD_DESCRIPTION) && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  setActiveTab(activeTabEditProfile.profileTab);
                  setIsEditProfileOpen(true);
                }}
              >
                Add
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_CREATE_PROMOTION && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  setActiveTab(activeTabEditProfile.promotionTab);
                  setIsEditProfileOpen(true);
                }}
              >
                Add
              </Button>
            )}
            {(notification.type === NotificationTypes.AMI_ADD_EMAIL ||
              notification.type === NotificationTypes.AMI_ADD_SMS) && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  openContactModal(dispatch);
                }}
              >
                Add
              </Button>
            )}
            {(notification.type === NotificationTypes.AMI_JOIN_CALL ||
              notification.type === NotificationTypes.AMI_RSVP_EVENT) && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  history.push(`/general-discussion`);
                }}
              >
                {NotificationTypes.AMI_JOIN_CALL ? "Join" : "RSVP"}
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_MAKE_CONNECTIONS && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  history.push(
                    profileRoute.path.replace(":username", "1dolinski"),
                  );
                }}
              >
                Connect
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_SEND_WAVE && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  history.push(`/@1dolinski`);
                }}
              >
                Wave
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_UPGRADE_PLAN && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  openPlanModal(dispatch);
                }}
              >
                Upgrade
              </Button>
            )}
            {notification.type === NotificationTypes.AMI_SEND_CHAT && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  history.push(`/chat`);
                }}
              >
                Chat
              </Button>
            )}
            {notification.type === "waved" ? (
              <WaveButton user={notification.usersMentioned[0]} />
            ) : undefined}

            {notification.type === "waved_back" ? (
              <WaveButton user={notification.usersMentioned[0]} />
            ) : undefined}

            {notification.type === "waved_joined" ? (
              <WaveButton user={notification.usersMentioned[0]} />
            ) : undefined}

            <div className="">
              {notification.type === "invite_user" ? (
                <Button
                  size="vibhut-sm"
                  variant="vibhut-primary"
                  onClick={() => {
                    if (notification.roomPassword) {
                      startPassCall(
                        notification.roomsMentioned[0]._id,
                        notification.roomPassword,
                      );
                    } else {
                      startCall(notification.roomsMentioned[0]._id);
                    }
                  }}
                >
                  {isFetching ? <Loader size="vibhut-sm" /> : "Join Call"}
                </Button>
              ) : undefined}
            </div>
          </div>
        </div>
      )}
      <EditProfileModal
        isOpen={isEditProfileOpen}
        setIsOpen={setIsEditProfileOpen}
        defaultTab={activeTab}
      />
      {user && roomUUID ? (
        <CreatedLinkModal
          isOpen={!!roomUUID}
          setIsOpen={(flag) => !flag && setRoomUUID(undefined)}
          roomCode={roomUUID}
        />
      ) : undefined}
      {user && <CreateRoom isOpen={isOpen} setIsOpen={setIsOpen} />}
      <ConfirmationModal
        title="Upgrade to Premium"
        text={"You have reached your limit of adding Rooms."}
        description="Upgrade to premium to add more Rooms."
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        primaryButtonText="Upgrade"
        primaryAction={() => {
          setOpenConfirmationModal(false);
          openPlanModal(dispatch);
        }}
        secondaryButtonText={"Cancel"}
        secondaryAction={() => {
          setOpenConfirmationModal(false);
        }}
        showLearnMore={true}
      />

      {chatItem && <SpecificChat chatItem={chatItem} type="notification" />}
    </>
  );
};

export default function NotificationModel({ isOpen, setIsOpen }) {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth: { user },
    notification: { data, isFetching, isMore, offset, isMoreFetching },
  } = state;

  const [profileUser, setProfileUser] = useState({});
  const openUserModal = (user) => {
    setIsOpen(false);
    setProfileUser(user);
  };
  const closeUserModal = () => {
    setProfileUser({});
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleLoadMore = () => {
    getNotifications(user?.token, dispatch, isMore, offset, false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed backdrop-blur-[1px] inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="mt-10 md:mt-0 mx-auto dark:bg-navyGray md:mx-0 md:absolute xl:right-80 md:right-20 md:top-10 w-full max-w-md overflow-hidden text-left  transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="bg-white dark:bg-navyGray p-3 border-b border-frescoWhite dark:border-matteGray">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite">
                      Notifications
                    </p>
                  </div>
                </div>

                <div className="overflow-y-auto h-96 py-4 pt-0 px-6 custom-scrollbar">
                  {isFetching ? (
                    <div className="flex justify-center items-center h-full">
                      <NotificationSkeleton />
                    </div>
                  ) : data.length > 0 ? (
                    data?.map((notification) => {
                      if (notification.eventsMentioned?.length > 0) {
                        for (
                          let i = 0;
                          i < notification.eventsMentioned.length;
                          i++
                        ) {
                          const event = notification.eventsMentioned[i];
                          if (!event.is_deleted) {
                            return (
                              <Notification
                                notification={notification}
                                openUserModal={openUserModal}
                              />
                            );
                          } else {
                            return "";
                          }
                        }
                      } else {
                        return (
                          <Notification
                            key={notification._id}
                            notification={notification}
                            openUserModal={openUserModal}
                          />
                        );
                      }
                    })
                  ) : (
                    <Empty
                      Image={<NoNotification />}
                      title="No notifications yet"
                      description="Stay tuned! Notification will appear right here."
                      showButton={false}
                    />
                  )}
                  {data.length !== 0 ? (
                    !isMoreFetching ? (
                      isMore && (
                        <div className="flex justify-center">
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-primary"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </Button>
                        </div>
                      )
                    ) : (
                      <NotificationSkeleton />
                    )
                  ) : undefined}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <ProfileModal
        isOpen={profileUser._id ? true : false}
        setIsOpen={(open) => !open && closeUserModal()}
        user={profileUser}
      />
    </>
  );
}
