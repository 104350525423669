import * as actionTypes from "../constants";

const initialState = {
  waves: [],
  isFetchingWave: false,
  error: null,
  waveUser: null,
  isUserWaveing: false,
};

function waveReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SINGLE_WAVE_SUCCESS:
      return { ...state, waveUser: action.payload.waveUser };

    case actionTypes.GET_USER_WAVES_REQUEST:
      return { ...state, isFetchingWave: true };

    case actionTypes.GET_USER_WAVES_SUCCESS: {
      return {
        ...state,
        waves: action.payload?.waves,
        isFetchingWave: false,
      };
    }

    case actionTypes.GET_USER_WAVES_FAILURE:
      return { ...state, isFetchingWave: false };

    case actionTypes.CREATE_WAVES_REQUEST:
      return { ...state, isFetchingWave: true };

    case actionTypes.CREATE_WAVES_SUCCESS: {
      return {
        ...state,
        waves: [...state.waves, action.payload],
        isFetchingWave: false,
      };
    }

    case actionTypes.CREATE_WAVES_FAILURE:
      return { ...state, isFetchingWavee: false };

    case actionTypes.WAVE_BACK_REQUEST:
      return { ...state, isFetchingWave: true };

    case actionTypes.WAVE_BACK_SUCCESS: {
      const { waves } = state;
      let index = waves.findIndex((wave) => wave._id === action.payload._id);
      waves[index] = action.payload;

      return {
        ...state,
        waves: waves,
        isFetchingWave: false,
      };
    }

    case actionTypes.WAVE_BACK_FAILURE:
      return { ...state, isFetchingWave: false };

    case actionTypes.IS_USER_WAVEING:
      return { ...state, isUserWaveing: !state.isUserWaveing };

    default:
      return state;
  }
}

export default waveReducer;
