import React from "react";

const ContactInfo = () => {
  return (
    <svg
      width="32"
      height="32"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
        fill="#0095F6"
      />
      <path
        d="M22.6667 19.28V21.28C22.6675 21.4657 22.6294 21.6495 22.555 21.8196C22.4807 21.9897 22.3716 22.1424 22.2348 22.2679C22.0979 22.3934 21.9364 22.489 21.7605 22.5485C21.5847 22.608 21.3983 22.6301 21.2134 22.6133C19.1619 22.3904 17.1914 21.6894 15.46 20.5667C13.8493 19.5431 12.4836 18.1775 11.46 16.5667C10.3334 14.8275 9.6322 12.8473 9.41337 10.7867C9.39671 10.6023 9.41862 10.4165 9.4777 10.2411C9.53679 10.0657 9.63175 9.90447 9.75655 9.76775C9.88134 9.63104 10.0332 9.52181 10.2026 9.44702C10.3719 9.37223 10.5549 9.33351 10.74 9.33334H12.74C13.0636 9.33016 13.3772 9.44473 13.6225 9.65569C13.8679 9.86666 14.0281 10.1596 14.0734 10.48C14.1578 11.1201 14.3143 11.7485 14.54 12.3533C14.6297 12.592 14.6491 12.8513 14.596 13.1006C14.5428 13.3499 14.4193 13.5787 14.24 13.76L13.3934 14.6067C14.3424 16.2757 15.7243 17.6576 17.3934 18.6067L18.24 17.76C18.4213 17.5808 18.6501 17.4572 18.8995 17.4041C19.1488 17.3509 19.4081 17.3703 19.6467 17.46C20.2516 17.6857 20.88 17.8423 21.52 17.9267C21.8439 17.9724 22.1396 18.1355 22.3511 18.385C22.5625 18.6345 22.6748 18.9531 22.6667 19.28Z"
        fill="white"
      />
    </svg>
  );
};

export default ContactInfo;
