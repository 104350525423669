import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useContext, useEffect } from "react";
import Close from "../Icons/Close";
import { getUserData } from "../../services/userService";
import { StoreContext } from "../../Context/StoreContext";
import UserCard from "../UserCard";
import { getChatStatus } from "../../services/chatService";
import Loader from "../Loader";

export default function GroupRoomMessageStatus({
  chatId,
  isOpen,
  setIsOpen,
  setChatId,
}) {
  const { state } = useContext(StoreContext);
  const [readBy, setReadBy] = useState([]);
  const [deliveredTo, setDeliveredTo] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const {
    auth: { user },
  } = state;

  const getChatStatusFun = async () => {
    await getChatStatus(user?.token, chatId)
      .then(res => {
        setReadBy(res.data.readBy);
        let d = [];
        res.data.deliveredTo.forEach(function(item) {
          const index = res.data.readBy.findIndex(ur => ur._id === item._id);
          if (index === -1) {
            return d.push(item);
          }
        });
        setDeliveredTo(d);
        setIsFetching(false);
      })
      .catch(err => {
        console.log("err", err);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (isOpen && chatId && user?.token) {
      setIsFetching(true);
      setDeliveredTo([]);
      setReadBy([]);
      //get chat status api
      getChatStatusFun();
    }
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => {
            setChatId(undefined);
            setIsOpen(false);
          }}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-navyGray text-left  shadow-xl transform transition-all  sm:align-middle  w-96 font-ubuntu rounded-2xl">
                <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray rounded-t-2xl">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite">
                      Status
                    </p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="pr-4 py-2 overflow-y-auto custom-scrollbar h-80">
                  {isFetching ? (
                    <Loader />
                  ) : (
                    <>
                      {readBy.length === 0 && deliveredTo.length === 0 ? (
                        <div className="px-4 dark:text-frescoWhite">
                          Currently Message not deliver to any subscriber
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        {readBy.length > 0 && (
                          <div className="px-4 dark:text-frescoWhite">Read</div>
                        )}
                        <div>
                          {readBy &&
                            readBy.length > 0 &&
                            readBy.map((user, index) => (
                              <div
                                className="flex justify-between items-center"
                                key={user._id + index}
                              >
                                <div className="">
                                  <UserCard
                                    user={getUserData(user)}
                                    size="sm"
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div>
                          {deliveredTo.length > 0 && (
                            <div className="px-4 text-darkNight dark:text-frescoWhite">
                              Delivered
                            </div>
                          )}
                          <div>
                            {deliveredTo &&
                              deliveredTo.length > 0 &&
                              deliveredTo.map((user, index) => {
                                return (
                                  <div
                                    className="flex justify-between items-center"
                                    key={user._id + index}
                                  >
                                    <div className="">
                                      <UserCard
                                        user={getUserData(user)}
                                        size="sm"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
