import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Button from '../../VibehutUI/Button';
import { getCoinPrice } from '../../services/userService';
import Axios from 'axios';
import { getUserData } from '../../utils/vibehutUtils';
import useAuthUser from '../../hooks/useAuthUser/useAuthUser';

const RCSlider = Slider as any;

interface FilterCallProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  joinFilterCall: (data: number) => void;
}

export default function FilterCall({ isOpen, setIsOpen, joinFilterCall }: FilterCallProps) {
  const minPoints = 0;
  const [maxPoints, setMaxPoints] = React.useState(1);
  const { authUser } = useAuthUser();

  const userData = getUserData(authUser);
  const source = Axios.CancelToken.source();

  React.useEffect(() => {
    if (userData.provider === 'bitclout') {
      getCoinPrice(userData.username, userData.bitclout_publickey, source)
        .then(res => {
          setMaxPoints(res.data / 2);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (
      userData.provider === 'twitter' ||
      userData.provider === 'metamask' ||
      userData.provider === 'instagram'
    ) {
      setMaxPoints(Math.round(userData.follower_count / 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelButtonRef = useRef(null);
  const [matchPoints, setMatchPoints] = useState(0);
  const { Handle } = Slider;

  const handle = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${getUserData(authUser)?.username && userData.provider === 'bitclout' ? '$' : ''} ${value} `}
        visible={true}
        placement="top"
        key={index}
        zIndex={999}
        overlayInnerStyle={{ width: '50px', backgroundColor: '#1c1c1e' }}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const getFilterText = () => {
    switch (userData.provider) {
      case 'bitclout':
        return 'Match with other DeSo users above the coin price:';
      case 'twitter':
        return 'Match with other X users above the followers:';
      case 'metamask':
        return 'Match with other users above the followers:';
      case 'instagram':
        return 'Match with other Instagram users above the followers:';
      case 'tiktok':
        return 'Match with other Tiktok users above the followers:';
      default:
        return '';
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu">
              <div className="bg-white dark:bg-navyGray p-3 border-b border-frescoWhite dark:border-matteGray">
                <div className="flex justify-between items-center">
                  <p className="font-medium text-lg text-black dark:text-frescoWhite">Filter</p>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="bg-white dark:bg-navyGray p-3 ">
                <p className="text-base text-darkGray dark:text-gray-300 leading-5">{getFilterText()}</p>
                <div className="pt-14 px-6">
                  <RCSlider
                    min={minPoints}
                    max={maxPoints}
                    step={userData.provider === 'bitclout' ? 0.1 : 1}
                    value={matchPoints}
                    handle={handle}
                    handleStyle={{
                      backgroundColor: 'white',
                      boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12)',
                      border: 'none',
                      height: '28px',
                      width: '28px',
                      top: '-4px',
                    }}
                    railStyle={{
                      backgroundColor: 'rgba(120, 120, 128, 0.2)',
                      borderRadius: '2px',
                    }}
                    trackStyle={{ backgroundColor: '#716DFF' }}
                    onChange={setMatchPoints}
                  />

                  <div className="flex justify-between pt-2 text-darkGray dark:text-gray-300 font-medium text-xs">
                    <p>{`${
                      getUserData(authUser)?.username && userData.provider === 'bitclout' ? '$' : ''
                    }${minPoints}`}</p>
                    <p>{`${
                      getUserData(authUser)?.username && userData.provider === 'bitclout' ? '$' : ''
                    }${maxPoints}`}</p>
                  </div>
                </div>
              </div>
              <div className=" px-4 py-3 flex flex-row-reverse ">
                <Button
                  onClick={() => {
                    joinFilterCall(matchPoints);
                    setIsOpen(false);
                  }}
                  size="vibhut-sm"
                  variant="vibhut-primary"
                >
                  Apply
                </Button>
                <div className="mr-3">
                  <Button size="vibhut-sm" variant="vibhut-secondary" onClick={() => setIsOpen(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
