import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChatWindowHeader from './ChatWindowHeader/ChatWindowHeader';
import clsx from 'clsx';
import useVibehutChatContext from '../../hooks/useVibehutChatContext/useVibehutChatContext';
import Chat from '../VibehutCallSidebar/Chat';
import useVibehutData from '../../hooks/useVibehutData/useVibehutData';
import { globals } from '../../globals';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: '#303234',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
      },
    },
    hide: {
      display: 'none',
    },
  })
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
  const classes = useStyles();
  const { isChatWindowOpen } = useVibehutChatContext();
  const { roomData } = useVibehutData(globals.roomId);
  return (
    <aside className={clsx(classes.chatWindowContainer, { [classes.hide]: !isChatWindowOpen })}>
      <ChatWindowHeader />
      <div className="px-4 pb-4">
        <Chat roomId={roomData._id} />
      </div>
    </aside>
  );
}
