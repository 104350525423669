import React, { useMemo } from "react";
import { _renderSocialIcon } from "../../utils/utils";
import Avatar from "../../VibehutUI/Avatar";
import ProfileModal from "../../VibehutUI/ProfileModal";

const ConnectingScreenProfile = ({ user }: { user: any }) => {
  const [isProfileOpen, setIsProfileOpen] = React.useState(false);
  const isWarpCastUser = useMemo(() => {
    if (user?.provider === "metamask") {
      if (user?.farcaster?.username) {
        return true;
      }
    }
    return false;
  }, [user]);
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsProfileOpen(true);
        }}
      >
        <Avatar
          containerStyle={{ width: "64px", marginRight: 10 }}
          src={user.profile_picture}
          username={user.username}
          size="md"
        />
        {_renderSocialIcon(isWarpCastUser ? "warpcast" : user.provider)}
      </div>
      <ProfileModal
        isOpen={isProfileOpen}
        setIsOpen={setIsProfileOpen}
        user={user}
        userState={user.userState}
      />
    </React.Fragment>
  );
};

export default ConnectingScreenProfile;
