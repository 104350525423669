import React from "react";

export const getSystemTheme = () => {
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  }
  return "light";
};

const getInitialTheme = () => {
  const storedTheme = localStorage.getItem("vibehut-theme");
  if (storedTheme) {
    return storedTheme;
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark-theme";
  }
  return "light-theme";
};

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState(getInitialTheme);

  const updateTheme = (existing) => {
    try {
      const root = window.document.documentElement;
      const isDark = existing === "dark-theme";

      root.classList.remove("light", "dark");
      root.classList.add(
        existing === "system" ? getSystemTheme() : isDark ? "dark" : "light",
      );

      localStorage.setItem("vibehut-theme", existing);
    } catch (error) {}
  };

  const isSystemDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");

  React.useEffect(() => {
    updateTheme(theme);
  }, [theme]);

  React.useEffect(() => {
    isSystemDarkTheme.addEventListener("change", (x) => {
      updateTheme("system");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
