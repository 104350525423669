import React from "react";

function PasswordIcon({ isInline = false, marginTop = "mt-0", height="14", width="10" }) {
  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isInline ? "inline" : ""} ${marginTop}`}
    >
      <path
        d="M1.45314 14H8.55639C9.52199 14 10 13.5355 10 12.5335V7.34158C10 6.45804 9.62715 5.98439 8.85277 5.8933V4.05335C8.85277 1.24789 6.9216 0 5 0C3.08794 0 1.14721 1.24789 1.14721 4.05335V5.93885C0.449327 6.07548 0 6.53091 0 7.34158V12.5335C0 13.5355 0.478011 14 1.45314 14ZM2.53345 3.86208C2.53345 2.11322 3.70937 1.24789 5 1.24789C6.29063 1.24789 7.4761 2.11322 7.4761 3.86208V5.88419L2.53345 5.8933V3.86208Z"
        fill="#B5B5B5"
      />
    </svg>
  );
}

export default PasswordIcon;
