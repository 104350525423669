import * as actionTypes from "../constants";
import { NewsPost } from "../types";

interface InitialState {
  newsPosts: NewsPost[];
  isFeedFetching: boolean;
  isMoreFeedFetching: boolean;
  isMoreFeedAvailable: boolean;
  error: null;
  page: number;
  lastUpdated: number | undefined;
}

const initialState: InitialState = {
  newsPosts: [],
  isFeedFetching: false,
  isMoreFeedFetching: false,
  isMoreFeedAvailable: false,
  error: null,
  page: 0,
  lastUpdated: undefined,
};

function newsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_NEWS_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFeedFetching: true,
        };
      }
      return { ...state, isFeedFetching: true };
    case actionTypes.GET_NEWS_POSTS_SUCCESS: {
      if (action.payload.loadMore) {
        return {
          ...state,
          newsPosts: [...state.newsPosts, ...action.payload.newsPosts],
          isMoreFeedFetching: false,
          isFeedFetching: false,
          isMoreFeedAvailable: action.payload.isMoreFeedAvailable,
          page: state.page + 1,
          lastUpdatedAt: action.payload.lastUpdatedAt,
        };
      } else {
        return {
          ...state,
          newsPosts: action.payload.newsPosts,
          isMoreFeedFetching: false,
          isFeedFetching: false,
          page: state.page + 1,
          isMoreFeedAvailable: action.payload.isMoreFeedAvailable,
          lastUpdatedAt: action.payload.lastUpdatedAt,
        };
      }
    }
    case actionTypes.DELETE_NEWS_POST_SUCCESS: {
      const updatedPosts = state.newsPosts.filter(
        (post) => post?._id !== action.payload,
      );
      return {
        ...state,
        newsPosts: updatedPosts,
      };
    }
    case actionTypes.UPDATE_NEWS_DIAMOND_COUNT: {
      const { resourceId } = action.payload;
      const postIndex = state.newsPosts.findIndex(
        (post) => post._id === resourceId,
      );
      const updatePost = (postList: NewsPost[], index: number) => {
        if (index !== -1) {
          const updatedPosts = [...postList];
          let updatedPost = { ...updatedPosts[index] };
          if (updatedPost.post) {
            updatedPost = {
              ...updatedPost,
              ...(updatedPost?.post && {
                post: {
                  ...updatedPost.post,
                  diamondCount: updatedPost.post.diamondCount + 1,
                  postEntryReaderState: {
                    ...updatedPost?.post.postEntryReaderState,
                    DiamondLevelBestowed:
                      updatedPost.post.postEntryReaderState
                        .DiamondLevelBestowed + action.payload.diamondLevel,
                  },
                },
              }),
            };
          } else {
            const diaondSenders = updatedPost?.diamondsGivenBySenders || [];
            updatedPost = {
              ...updatedPost,
              diamondsGivenBySenders: [
                ...diaondSenders,
                {
                  senderId: action.payload.senderId,
                  diamondLevel: action.payload.diamondLevel,
                },
              ],
            };
          }
          updatedPosts[index] = updatedPost;

          return updatedPosts;
        }
        return postList;
      };

      //if post is not found, update the diamond level by resource id

      const updatedFeedPosts = updatePost(state.newsPosts, postIndex);

      return {
        ...state,
        newsPosts: updatedFeedPosts,
      };
    }

    case actionTypes.GET_NEWS_POSTS_FAILURE:
    case actionTypes.DELETE_NEWS_POST_FAILURE:
      return { ...state, isFeedFetching: false, isMoreFeedFetching: false };
    default:
      return state;
  }
}

export default newsReducer;
