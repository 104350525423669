import React from "react";

function Polygon({ bgColor, width = "12", height = "14", isInline = false }) {
  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isInline ? "inline" : ""}
    >
      <path
        d="M5.06424 0.54586C5.64249 0.208551 6.35752 0.208551 6.93576 0.54586L11.0786 2.96253C11.6492 3.29535 12 3.90616 12 4.56669V9.43331C12 10.0938 11.6492 10.7047 11.0786 11.0375L6.93576 13.4541C6.35752 13.7915 5.64249 13.7914 5.06424 13.4541L0.921382 11.0375C0.350835 10.7047 0 10.0938 0 9.43331V4.56669C0 3.90616 0.350835 3.29535 0.921382 2.96253L5.06424 0.54586Z"
        fill={bgColor}
      />
    </svg>
  );
}

export default Polygon;
