import { CreatePromotionPayload, UpdatePromotionPayload } from "../types";
import axios from "../utils/axios";

export async function createPromotion(
  data: CreatePromotionPayload,
  token: string,
) {
  const res = await axios.post(`/promotion`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}

export async function updatePromotion(
  data: UpdatePromotionPayload,
  recordId: string,
  token: string,
) {
  const res = await axios.put(`/promotion/${recordId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}

export async function deletePromotion(recordId: string, token: string) {
  const res = await axios.delete(`/promotion/${recordId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}
export async function getApprovedPromotion(timezone: string) {
  const res = await axios.get(`/promotion?timezone=${timezone}`);
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}
export async function getPromotionBookedDates(token: string) {
  const res = await axios.get(`/promotion/bookedDates`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}
export async function getMyPromotion(token: string, pages: string) {
  const res = await axios.get(`/promotion/my?page=${pages}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}
