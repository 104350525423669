import React, { useState } from "react";
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
} from "../../../constants";
import { LocalVideoTrack } from "twilio-video";
import { makeStyles } from "@material-ui/core/styles";
import VideoTrack from "../../VideoTrack/VideoTrack";
import useDevices from "../../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

const useStyles = makeStyles({
  preview: {
    width: "300px",
    maxHeight: "200px",
    margin: "0.5em auto",
    "& video": {
      maxHeight: "200px",
    },
    borderRadius: "12px",
  },
});

export default function VideoInputList() {
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(
    (track) => track.kind === "video",
  ) as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
  );
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    try {
      setStoredLocalVideoDeviceId(newDeviceId);
      window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
      localVideoTrack?.restart({
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        deviceId: { exact: newDeviceId },
      });
    } catch (error) {}
  }

  return (
    <div>
      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}
      <p className="text-sm text-darkNight pb-1 font-medium">Camera</p>
      <select
        name=""
        id=""
        className="border border-frescoWhite  focus:ring-0 outline-none text-darkNight text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-xl"
        onChange={(e) => replaceTrack(e.target.value as string)}
        value={localVideoInputDeviceId || ""}
      >
        {videoInputDevices.map((device) => (
          <option value={device.deviceId} key={device.deviceId}>
            {device.label}
          </option>
        ))}
      </select>
    </div>
  );
}
