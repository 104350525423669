const LimitedTextarea = ({
  rows,
  cols = 0,
  value,
  limit,
  onChange,
  placeHolder = "Let others know what to expect...",
  disabled = false,
}) => {
  const setFormattedContent = (value) => {
    const text = value.slice(0, limit);
    onChange(text);
  };

  return (
    <div className="relative">
      <textarea
        rows={rows}
        cols={cols}
        onChange={(event) => setFormattedContent(event.target.value)}
        value={value}
        className="border md:text-sm text-base outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full resize-none placeholder-notifyBg"
        placeholder={placeHolder}
        disabled={disabled}
      />
      <div className="absolute bottom-6 right-4">
        <p className="text-lightGray text-sm">
          {value.length}/{limit}
        </p>
      </div>
    </div>
  );
};

export default LimitedTextarea;
