import { useContext, useEffect, useMemo, useState } from "react";
import Loader from "../../VibehutUI/Loader";
import Button from "../../VibehutUI/Button";
import globals from "../../globals";
import { uploadFile, uploadMedia2 } from "../../utils/uploadMedia";
import { StoreContext } from "../../Context/StoreContext";
import {
  getPersonaActivity,
  getPersonaFaqs,
  updatePersona,
} from "../../actions/personaAction";
import { ObjectCleaner } from "../../utils/utils";
import { showToast } from "../../actions/toastActions";
import { Persona } from "../../types";
import { createPersonaTab } from "../../constants";
import AdvanceTab from "./AdvanceTab";
import GeneralTab from "./GeneralTab";
import * as actionTypes from "../../constants";
import ActivityTab from "./ActivityTab";
import FaqTab from "./FaqTab";

interface Props {
  _persona: Persona;
}

function EditPersona(props: Props) {
  const { _persona } = props;
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    persona: { isUpdating, formState, personaPremiumTags },
  } = state;

  const [activeTab, setActiveTab] = useState(createPersonaTab.generalTab);

  const [isImageUploading, setIsImageUploading] = useState(false);

  useEffect(() => {
    if (_persona._id) {
      let tags: any[] = [];
      let premiumTag: any = {
        label: "",
        value: "",
      };
      if (_persona?.tags) {
        tags = _persona?.tags.map((tag: string) => {
          return { value: tag, label: tag, __isNew__: true };
        });
      }

      if (_persona?.premiumTags?.[0]) {
        premiumTag = {
          label: _persona?.premiumTags?.[0],
          value: _persona?.premiumTags?.[0],
        };
      }

      if (premiumTag.value) {
        const isTheRightPremiumTag = personaPremiumTags.find(
          (tag: any) => tag.value === premiumTag.value,
        );
        if (!isTheRightPremiumTag) {
          premiumTag = {
            label: "",
            value: "",
          };
        }
      }

      const faqs = [
        {
          question: "",
          answer: "",
        },
      ];

      dispatch({
        type: actionTypes.UPDATE_FORM_DATA_STATE,
        payload: {
          _id: _persona._id,
          name: _persona.name,
          isEnable: _persona.isEnable,
          highlights: _persona?.highlights || "",
          lowlights: _persona?.lowlights || "",
          about: _persona.about,
          lookingFor: _persona?.lookingFor || "",
          notLookingFor: _persona?.notLookingFor || "",
          generatedBio: _persona.generatedBio,
          includeMe: _persona.includeMe,
          notifyMe: _persona.notifyMe,
          peopleShouldKnow: _persona?.peopleShouldKnow || "",
          recordedVideoLink: _persona?.recordedVideoLink || "",
          avatar: {
            urls: {
              regular: _persona?.avatar || "",
              small: _persona?.avatar || "",
            },
          },
          avatarFile: null,
          tags,
          premiumTag: premiumTag,
          faqs,
          companyAffiliation: {
            isActive: _persona?.companyAffiliation?.isActive || false,
            imgUrl: _persona?.companyAffiliation?.imgUrl || "",
            title: _persona?.companyAffiliation?.title || "",
            link: _persona?.companyAffiliation?.link || "",
          },
          roomSpecific: _persona?.roomSpecific,
          room: _persona?.room,
        },
      });
      getPersonaActivity(dispatch, user?.token, _persona._id);
      getPersonaFaqs(dispatch, user?.token, _persona._id);
    }
  }, [_persona, dispatch, user?.token]);

  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.UPDATE_FORM_DATA_STATE,
        payload: {
          name: "",
          isEnable: true,
          highlights: "",
          lowlights: "",
          about: "",
          lookingFor: "",
          notLookingFor: "",
          generatedBio: "",
          includeMe: true,
          notifyMe: true,
          peopleShouldKnow: "",
          recordedVideoLink: "",
          avatar: {
            urls: {
              regular: "",
              small: "",
            },
          },
          avatarFile: null,
          tags: [],
          premiumTag: "",
          companyAffiliation: {
            isActive: false,
            imgUrl: "",
            title: "",
            link: "",
          },
        },
      });
    };
  }, [dispatch]);

  const validateInputs = () => {
    const isCompanyAffiliationDataNotValid =
      formState.data.companyAffiliation?.isActive &&
      (!formState.data.companyAffiliation.imgUrl ||
        !formState.data.companyAffiliation.title ||
        !formState.data.companyAffiliation.link);
    if (
      !formState.data.name ||
      (!formState.data.avatar.urls.small && !formState.data?.avatarFile) ||
      !formState.data.about ||
      isCompanyAffiliationDataNotValid
    ) {
      dispatch({
        type: actionTypes.UPDATE_FORM_ERROR_STATE,
        payload: {
          nameError: !formState.data.name ? "Please enter name" : "",
          avatarError:
            !formState.data.avatar.urls.small && !formState.data?.avatarFile
              ? "Please select or upload the avatar"
              : "",
          aboutError: !formState.data.about ? "Please enter about" : "",
          companyAffiliationError: isCompanyAffiliationDataNotValid
            ? "Please add all details of company affiliation"
            : "",
        },
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (media: any = undefined) => {
    const _highlights = formState.data?.highlights;
    const _lowlights = formState.data?.lowlights;
    const _lookingFor = formState.data?.lookingFor;
    const _notLookingFor = formState.data?.notLookingFor;
    const _recordedVideoLink = formState.data?.recordedVideoLink;
    const _peopleShouldKnow = formState.data?.peopleShouldKnow;
    let _tags: any[] = [];
    let _avatar = formState.data?.avatar?.urls.small || "";
    let companyAffiliation = null;

    if (formState.data && formState.data.tags) {
      _tags = formState.data.tags
        .filter((tag: any) => tag !== undefined)
        .map((tag: any) => tag.value);
    }

    if (media?.url) {
      _avatar = media?.url;
    }

    dispatch({
      type: actionTypes.UPDATE_PERSONA_REQUEST,
    });

    if (formState.data?.companyAffiliation?.title) {
      let companyAffiliationImgUrl = formState.data?.companyAffiliation?.imgUrl;
      if (formState.data?.companyAffiliation?.imgUrl?.name) {
        companyAffiliationImgUrl = await uploadFile(
          formState.data?.companyAffiliation?.imgUrl,
        );
      }
      companyAffiliation = {
        isActive: formState.data?.companyAffiliation?.isActive,
        imgUrl: companyAffiliationImgUrl,
        title: formState.data?.companyAffiliation?.title,
        link: formState.data?.companyAffiliation?.link,
      };
    }

    const body = {
      name: formState.data.name.trim(),
      avatar: _avatar,
      isEnable: formState.data.isEnable,
      ...(_highlights ? { highlights: _highlights } : {}),
      ...(_lowlights ? { lowlights: _lowlights } : {}),
      about: formState.data.about,
      ...(_lookingFor ? { lookingFor: _lookingFor } : {}),
      ...(_notLookingFor ? { notLookingFor: _notLookingFor } : {}),
      generatedBio: formState.data.generatedBio,
      includeMe: formState.data.includeMe,
      notifyMe: formState.data.notifyMe,
      ...(_recordedVideoLink ? { recordedVideoLink: _recordedVideoLink } : {}),
      ...(_peopleShouldKnow ? { peopleShouldKnow: _peopleShouldKnow } : {}),
      ...(_tags.length > 0 ? { tags: _tags } : {}),
      ...(formState.data?.premiumTag?.value
        ? { premiumTags: [formState.data?.premiumTag?.value] }
        : {}),
      ...(companyAffiliation ? { companyAffiliation: companyAffiliation } : {}),
    };
    const personaId = _persona._id as string;
    updatePersona(ObjectCleaner(body), personaId, user?.token, dispatch)
      .then(() => {
        globals.hideModal();
      })
      .catch((error) => {
        if (error.message) {
          showToast(dispatch, "error", error.message, 2000);
        }
      });
  };

  const handleUploadAndUpdate = async () => {
    if (!validateInputs()) {
      return;
    }
    if (formState.data.avatarFile) {
      await uploadMedia2(
        formState.data.avatarFile,
        setIsImageUploading,
        handleSubmit,
      );
    } else {
      await handleSubmit();
    }
  };

  const isSubmitButtonDisabled = useMemo(
    () =>
      formState.errorMessage.nameError || formState.errorMessage.avatarError,
    [formState.errorMessage],
  );

  return (
    <div className="max-h-[80vh] custom-scrollbar overflow-auto">
      <div className="wrapper dark:border-matteGray border-b border-frescoWhite">
        <nav className="py-2.5">
          <input
            type="radio"
            name="tab"
            id="all"
            checked={activeTab === createPersonaTab.generalTab}
            className="hidden"
          />
          <input
            type="radio"
            name="tab"
            id="dms"
            checked={activeTab === createPersonaTab.advanceTab}
            className="hidden"
          />
          <input
            type="radio"
            name="tab"
            id="rooms"
            checked={activeTab === createPersonaTab.activityTab}
            className="hidden"
          />
          <input
            type="radio"
            name="tab"
            id="more"
            checked={activeTab === createPersonaTab.faqTab}
            className="hidden"
          />
          <label
            htmlFor="general"
            className="general"
            onClick={() => {
              setActiveTab(createPersonaTab.generalTab);
            }}
          >
            <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
              General
            </span>
          </label>
          <label
            htmlFor="advance"
            className="advance"
            onClick={() => {
              setActiveTab(createPersonaTab.advanceTab);
            }}
          >
            <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
              Advanced
            </span>
          </label>
          <label
            htmlFor="activity"
            className="activity"
            onClick={() => {
              setActiveTab(createPersonaTab.activityTab);
            }}
          >
            <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
              Activity
            </span>
          </label>
          <label
            htmlFor="faq"
            className="faq"
            onClick={() => {
              setActiveTab(createPersonaTab.faqTab);
            }}
          >
            <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
              FAQs
            </span>
          </label>
          <div className="conversation-tab border-b-2 border-primary"></div>
        </nav>
      </div>
      <div className="transform transition-all">
        <div
          className={` ${
            activeTab === createPersonaTab.generalTab ? "visible" : "hidden"
          }`}
        >
          {formState.data?.name && <GeneralTab isEditMode />}
        </div>
        <div
          className={` ${
            activeTab === createPersonaTab.advanceTab ? "visible" : "hidden"
          }`}
        >
          <AdvanceTab />
        </div>
        <div
          className={` ${
            activeTab === createPersonaTab.activityTab ? "visible" : "hidden"
          }`}
        >
          <ActivityTab />
        </div>
        <div
          className={` ${
            activeTab === createPersonaTab.faqTab ? "visible" : "hidden"
          }`}
        >
          <FaqTab />
        </div>
      </div>

      {activeTab !== createPersonaTab.activityTab &&
        activeTab !== createPersonaTab.faqTab && (
          <div className=" px-4 py-3 flex flex-row-reverse ">
            {isUpdating || isImageUploading ? (
              <Loader />
            ) : (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                disabled={Boolean(isSubmitButtonDisabled)}
                onClick={handleUploadAndUpdate}
              >
                Save
              </Button>
            )}
            <div className="mr-3">
              <Button
                size="vibhut-sm"
                variant="vibhut-secondary"
                onClick={() => globals.hideModal()}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
    </div>
  );
}

export default EditPersona;
