import React, { useCallback, useContext, useRef } from "react";
import ChatBubble from "../../VibehutUI/ChatBubble";
import * as io from "socket.io-client";
import { StoreContext } from "../../Context/StoreContext";
import LiveMessageInput from "./LiveMessageInput";
import { getUserData } from "../../services/userService";
import { baseURL } from "../../utils/axios";
import moment from "moment";
import * as actionTypes from "../../constants";
import { isMobile } from "../../utils/utils";
import { getChatByRoomId } from "../../services/chatService";

interface ChatProps {
  roomId: string;
  fromCall: boolean;
  isLive?: boolean;
}

const LiveStreamChat = ({ roomId, fromCall, isLive }: ChatProps) => {
  const messagesEnd = React.useRef<null | HTMLDivElement>(null);
  const {
    state: {
      auth: { user },
      chat: { liveStreamMessages, activeTab },
    },
    dispatch,
  } = useContext(StoreContext);
  let name: string;
  const socketRefChat = useRef<any>();

  const getChatData = async () => {
    try {
      const { data: activeCallMessages } = await getChatByRoomId(
        roomId,
        user?.token,
      );
      const chats = [];
      for (let i = 0; i < activeCallMessages.length; i++) {
        const obj = {
          isSend:
            activeCallMessages[i].isAmiBot ||
            activeCallMessages[i].isPersonaChat
              ? false
              : user?._id === activeCallMessages[i]?.userId?._id
                ? true
                : false,
          msg: activeCallMessages[i].msg,
          username: getUserData(activeCallMessages[i].userId).username,

          _id: activeCallMessages[i]._id,
          room: activeCallMessages[i].room,
          date: new Date(activeCallMessages[i].createdAt),
          createdAt: moment(activeCallMessages[i].createdAt).format("hh:mm A"),
          isEncrypted: !activeCallMessages[i]?.isEncrypted ? false : true,
          isBot: activeCallMessages[i]?.isBot,
        };
        chats.push(obj);
      }
      dispatch({
        type: actionTypes.GET_LIVE_STREAM_ROOM_MESSAGES_SUCCESS,
        payload: chats.reverse(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChatMessage = (data: any) => {
    const chatReceived = {
      msg: data.msg,
      isSend:
        !data.isPersonaChat && !data.isAmiBot && data?.userId === user?._id
          ? true
          : false,
      username: data.username,
      _id: data._id,
      date: new Date(data.createdAt),
      createdAt: moment(data.createdAt).format("hh:mm A"),
      isEncrypted: false,
      isBot: data?.isBot,
    };
    dispatch({
      type: actionTypes.ADD_LIVE_STREAM_MESSAGE,
      payload: chatReceived,
    });

    if (!chatReceived.isSend) {
      dispatch({
        type: actionTypes.UPDATE_LIVE_STREAM_MESSAGE_COUNT,
      });
    }
  };

  React.useEffect(() => {
    if (user?.token && roomId) {
      getChatData();
    }
  }, [roomId, user]);

  React.useEffect(() => {
    if (user?.token && roomId && isLive) {
      const userData = getUserData(user);
      socketRefChat.current = io.connect(`${baseURL}/chat`, {
        auth: {
          token: user?.token,
        },
      });
      socketRefChat.current.on("connect", () => {
        socketRefChat.current.emit("join-room-for-chat", {
          roomId: roomId,
          username: userData.username,
          isChatRoom: true,
        });
        if (!fromCall) {
          socketRefChat.current.emit("join_live_stream", {
            username: userData.username,
            roomId: roomId,
          });
        }
      });

      socketRefChat.current.on("chat_room_message", handleChatMessage);
    }

    return () => {
      if (socketRefChat.current) {
        socketRefChat.current.disconnect();
        socketRefChat.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, user, isLive]);

  React.useEffect(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [liveStreamMessages, activeTab]);

  const handleSubmit = (text: string) => {
    const userData = getUserData(user);
    socketRefChat.current.emit("send_room_chat", {
      username: userData.username,
      roomId: roomId,
      msg: text,
      isEncrypted: false,
      chatType: "livestream",
    });
    const currentChat = {
      msg: text,
      isSend: true,
      username: userData.username,
      room: roomId,
      date: new Date(),
      createdAt: moment(new Date()).format("hh:mm A"),
      isEncrypted: false,
    };
    dispatch({
      type: actionTypes.ADD_LIVE_STREAM_MESSAGE,
      payload: currentChat,
    });
  };

  const getMessageLabel = useCallback((chat: any) => {
    if (chat.isBot) {
      return (
        <span className="">
          vibehut
          <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
            Bot
          </span>
        </span>
      );
    }

    if (chat.isSend) {
      return "You";
    }

    return chat.username;
  }, []);

  return (
    <>
      <div
        className={`w-full overflow-auto custom-scrollbar ${
          isMobile() ? "chat-Component-mobile-height " : "chat-Component-height"
        }   px-2`}
      >
        {liveStreamMessages?.length === 0 ? (
          <div className="text-primaryGray text-base text-center pt-56 h-full">
            <p className="h-full">No Message</p>
          </div>
        ) : (
          <div className="h-full">
            {liveStreamMessages.map((msg, index) => {
              let hidden;
              if (msg?.isAmiRequest) {
                return (
                  <p
                    key={msg._id || index}
                    className="text-center text-darkGray text-sm py-4 "
                  >
                    {msg.msg}
                  </p>
                );
              }
              if (name) {
                hidden = name === msg.username ? "hidden" : "";
              } else {
                hidden = "";
              }
              const usernameDirection = msg.isSend
                ? "text-right pr-1"
                : `text-left pl-1`;
              name = !hidden ? msg.username : "";
              return (
                <div
                  key={msg._id || index}
                  className={`flex flex-col mt-2 ${roomId ? "pl-1" : ""} `}
                >
                  <div
                    className={`mb-0.5 text-xs ${
                      msg.isAmiBot ? "" : hidden
                    } font-ubuntu text-primaryGray ${usernameDirection}`}
                  >
                    {getMessageLabel(msg)}
                  </div>

                  <>
                    {msg.msg && (
                      <ChatBubble
                        messageData={{
                          ...msg,
                          _id: msg._id || index,
                        }}
                        index={index}
                        message={`${
                          !msg?.isBot
                            ? msg.msg
                            : `${moment(msg.date).fromNow()} · ${msg.msg}`
                        }`}
                        isSend={msg.isSend}
                        isDanger={msg.isDanger}
                        roomId={roomId}
                      />
                    )}
                  </>
                </div>
              );
            })}
            <div
              style={{ float: "left", clear: "both" }}
              ref={messagesEnd}
            ></div>
          </div>
        )}
      </div>
      {!!user && roomId && isLive ? (
        <div
          style={{
            width: "95%",
            left: "2.5%",
            bottom: 10,
          }}
          className="absolute w-full px-4 md:px-0"
        >
          <LiveMessageInput onSubmit={handleSubmit} fromCall={fromCall} />
        </div>
      ) : (
        <p className="text-darkGray text-center">
          The live stream has not started yet.
        </p>
      )}
    </>
  );
};

export default LiveStreamChat;
