import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Helmet } from "react-helmet";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import { useAppState } from "../../state";
import { useLocation, useParams } from "react-router-dom";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { globals } from "../../globals";
import Button from "../../VibehutUI/Button";
import { Link } from "react-router-dom";
import LocalVideoPreview from "./DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import Settings from "../../VibehutUI/Icons/Settings";
import VideoSettings from "../VideoSettings";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import ConnectingScreenProfile from "./ConnectingScreenProfile";
import TalkingUsersModal from "../../VibehutUI/TalkingUsersModal";
import ConnectingScreen from "./ConnectingScreen";
import ConnectedScreen from "./ConnectedScreen";
import RoomPasswordModal from "../../VibehutUI/RoomPasswordModal";
import FilterCall from "../../VibehutUI/FilterCall";
import { Theme, Tooltip, useMediaQuery } from "@material-ui/core";
import LoginModal from "../../VibehutUI/LoginModal";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import { publicDefaultImageLink, wait } from "../../utils/utils";
import Avatar from "../../VibehutUI/Avatar";
import { getUserData } from "../../utils/vibehutUtils";
import BreakoutRoomEdit from "../BreakoutRoomEdit";
import Edit from "../../VibehutUI/Icons/Edit";
import Tag from "../../VibehutUI/Tag";
import { getRoomLobbyData } from "../../services/roomService";
import { getEventById } from "../../services/eventService";
import { getApprovalRequestsOfUserByEvent } from "../../services/eventApproval";
import GuestModal from "../../VibehutUI/GuestModal";
import { domain } from "../../constants";
import {
  checkDesoHolderToPublicKey,
  checkUserDiamondedToPost,
  getRoomIdByRoomSlug,
} from "../../../services/rooomService";
import { getAmountFromDiamondLevel } from "../../../utils/utils";

interface Props {
  handleJoinRoom: (
    matchPoints: number,
    roomName: string,
    instantRoomId: string,
    password: string,
    callId?: string,
    isNewCall?: boolean,
    subRoomName?: string,
    eventId?: string,
  ) => void;
}

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CallSettingScreen(props: Props) {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, roomId, slug } = useParams<{
    URLRoomName?: string;
    roomId?: string;
    slug?: string;
  }>();
  const [step, setStep] = useState(Steps.roomNameStep);
  const query = useQuery();
  const location = useLocation();
  const [roomName, setRoomName] = useState<string>("");
  const [eventKey, setEventKey] = useState<string>("");

  const [instantRoomId, setInstantRoomId] = useState<string>("");

  const password = useRef("");
  const matchPoints = useRef(0);
  const callUsersIds = useRef<string[]>([]);

  const [mediaError, setMediaError] = useState<Error>();

  const [isVideoSettingsOpen, setIsVideoSettingsOpen] =
    useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isMeetingRoom, setIsMeetingRoom] = useState<boolean>(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const { authUser } = useAuthUser();
  const { isFetching } = useAppState();
  const { roomData, requestForJoinCallAsGuest, isAnyJoinRequestFromGuest } =
    useVibehutData(roomName, instantRoomId, password.current);
  const [isJoining, setIsJoining] = useState(false);
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const { isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const kickedUser = query.get("kickedUser") || null;
  const isJoinAsGuest = query.get("guest") || false;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const [breakoutRoomCallId, setBreakoutRoomCallId] = useState("");
  const [breakoutRoomName, setBreakoutRoomName] = useState("");

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [callIds, setCallIds] = useState<string[]>([]);
  const [isPasswordProtectedEvent, setIsPasswordProtectedEvent] =
    useState(false);

  useEffect(() => {
    setIsButtonsDisabled(disableButtons || false);
  }, [disableButtons]);

  useEffect(() => {
    sendWaitingInLobbyStatus();
  }, []);

  const sendWaitingInLobbyStatus = async () => {
    let isSent = false;
    let waitingTime = 1000;

    while (!isSent) {
      await wait(waitingTime);

      if (globals.isSocketConnected && globals.socket) {
        globals.socket.emit("waiting-in-lobby");
        isSent = true;
        break;
      }

      waitingTime += 1000;
    }
  };
  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (!authUser && roomData._id) {
      if (isJoinAsGuest && roomData?.isGuestAllowed) {
        globals.showModal(
          "Join as Guest",
          <GuestModal onclose={globals.hideModal} />,
        );
        return;
      }
      setIsLoginModalOpen(true);
    }
  }, [authUser, isJoinAsGuest, roomData]);

  useEffect(() => {
    if (kickedUser) {
      globals.showModal(
        "Oops!",
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-lg">You have been kicked out from the room 🥾</p>
          </div>
        </>,
      );
    }
  }, [kickedUser, query]);

  async function _getGeneralRoomKey() {
    return "";
  }

  async function _getRoomKeyFromUrl() {
    if (slug) {
      return getRoomIdByRoomSlug(slug).then(async (res) => {
        if (res?.data?.roomId) {
          globals.roomId = res.data.roomId;
          return res.data.roomId;
        }
      });
    }
    const roomKey =
      query.get("roomKey") || roomId || (await _getGeneralRoomKey());

    globals.roomId = roomKey;

    return roomKey;
  }

  async function _getEventKeyFromUrl() {
    const eventKey = query.get("eventKey") || (await _getGeneralRoomKey());

    globals.eventId = eventKey;

    return eventKey;
  }

  useEffect(() => {
    handleVibehutAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!authUser]);

  const handleVibehutAuth = async () => {
    try {
      const v = query.get("v");
      if (v) {
        setIsMeetingRoom(true);
      }
      // if (!authUser) {
      //   return;
      // }
      const roomKey = await _getRoomKeyFromUrl();
      const isPasswordEvent = query.get("isPasswordProtected");
      const eventId = await _getEventKeyFromUrl();
      setEventKey(eventId);
      setIsPasswordProtectedEvent(Boolean(isPasswordEvent));
      const pass = query.get("pass");
      setRoomName(roomKey);

      setInstantRoomId(v || "");
      password.current = pass || "";
      setStep(Steps.deviceSelectionStep);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const checkForIsBlockedUser = useMemo(() => {
    return (
      (roomData && roomData?.blockedGeneralUsers?.includes(authUser?._id)) ||
      false
    );
  }, [authUser?._id, roomData]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  useEffect(() => {
    if (isAnyJoinRequestFromGuest.isRequestAcceptedByHost === "accepted") {
      handleJoin();
    } else if (
      isAnyJoinRequestFromGuest.isRequestAcceptedByHost === "rejected"
    ) {
      globals.showModal(
        "Unable to join",
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray relative">
            <p className="text-lg">The host declined your join request.</p>
          </div>
          <div className="flex flex-row justify-end pt-4">
            <div>
              <Button
                onClick={() => {
                  globals.hideModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>,
        false,
      );
    }
  }, [isAnyJoinRequestFromGuest]);

  const checkRsvpForEventJoining = async () => {
    try {
      const response = await getEventById(eventKey);

      const event = response.data.event;
      if (event?.maxRsvp) {
        const rsvpdUsers = event?.rsvps?.map((r: any) => r.user._id);
        if (rsvpdUsers.includes(authUser._id)) {
          return true;
        }
        const call: any = Object.values(roomData.callDetails).find(
          (c: any) => c.eventId === eventKey,
        );

        if (call?.users?.length >= event.maxRsvp) {
          return false;
        }
      }

      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserApprovalRequest = useCallback(async () => {
    try {
      const _authUser = globals.user;
      const token = _authUser?.token;
      if (!token) {
        return;
      }
      const res = await getApprovalRequestsOfUserByEvent(eventKey, token);
      return res.data;
    } catch (err) {
      console.log("err:", err);
      throw err;
    }
  }, [eventKey]);

  const checkUserApprovalStatusForEventJoining = async () => {
    try {
      const _authUser = globals?.user;
      const response = await getEventById(eventKey);
      const event = response.data.event;

      if (event?.isRequireApproval) {
        if (event?.owner === _authUser?._id) {
          return true;
        }
        const approvalRequest = await getUserApprovalRequest();
        if (approvalRequest) {
          if (approvalRequest.status !== "Approved") {
            return false;
          }
        }
      }

      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoin = async (
    callId?: string,
    isNewCall?: boolean,
    subRoomName?: string,
  ) => {
    callId && setBreakoutRoomCallId(callId);
    if (!globals.socket) {
      return;
    }
    let joinError;
    if (eventKey) {
      const isApproved = await checkUserApprovalStatusForEventJoining();
      if (!isApproved) {
        globals.showModal(
          `Oops!`,
          <>
            <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
              <p className="text-base">
                Unable to join this event. You have not been approved by the
                host.
              </p>
            </div>
          </>,
        );
        setIsJoining(false);
        return;
      }

      const isJoinEnable = await checkRsvpForEventJoining();
      if (!isJoinEnable) {
        globals.showModal(
          `Oops!`,
          <>
            <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
              <p className="text-base">
                Unable to join this event as it's already at full capacity
              </p>
            </div>
          </>,
        );
        setIsJoining(false);
        return;
      }
    }

    if (
      roomData.isRequireApproval &&
      authUser._id !== roomData.owner?._id &&
      !roomData.moderators.includes(authUser?._id) &&
      (isAnyJoinRequestFromGuest.isRequestAcceptedByHost === "" ||
        isAnyJoinRequestFromGuest.isRequestAcceptedByHost === "rejected")
    ) {
      if (authUser.provider === "guest") {
        requestForJoinCallAsGuest(
          authUser.guest_user_data.username,
          authUser.guest_user_data._id,
          true,
        );
      } else {
        requestForJoinCallAsGuest(
          getUserData(authUser).username,
          authUser._id,
          false,
          getUserData(authUser).profile_picture,
        );
      }
    } else {
      setIsJoiningRoom(true);
      setIsJoining(true);
      const { error }: any = await props.handleJoinRoom(
        matchPoints.current,
        roomName,
        instantRoomId,
        password.current,
        callId,
        isNewCall,
        subRoomName,
        eventKey,
        // isLimitedAttendeesEvent
      );
      setIsJoiningRoom(false);
      joinError = error;
    }

    if (joinError) {
      globals.showModal(
        `Oops!`,
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-base">
              {joinError?.message
                ? joinError.message
                : `Can't Join this room. Please try again later`}
            </p>
          </div>
        </>,
      );
      setIsJoining(false);
    }
  };

  const setIsPasswordVerified = (isVerified: boolean, pass: string) => {
    password.current = pass;
    if (isVerified) {
      handleJoin();
    }
  };

  const joinFilterCall = (matchPoint: number) => {
    matchPoints.current = matchPoint;
    joinCall();
  };
  const checkDesoPostRoomAccessable = async (roomId: string) => {
    try {
      const token = authUser?.token;
      const sender = (await checkUserDiamondedToPost(roomId, token)).data;
      return sender;
    } catch (error) {}
  };

  const checkDesoHodlerRoomAccessable = async (roomId: string) => {
    try {
      const token = authUser?.token;
      const sender = (await checkDesoHolderToPublicKey(roomId, token)).data;
      return sender;
    } catch (error) {}
  };

  const requiredDiamonds = useMemo(() => {
    const diamondLevel = roomData?.desoPostRoom?.diamondLevel;
    return getAmountFromDiamondLevel(diamondLevel);
  }, [roomData?.desoPostRoom?.diamondLevel]);

  const joinCall = async (callId?: string) => {
    if (!authUser) {
      setIsLoginModalOpen(true);
      return;
    }
    if (
      roomData?.desoPostRoom?.postHashHex &&
      roomData?.desoPostRoom?.diamondLevel &&
      authUser?._id !== roomData.owner?._id
    ) {
      if (!authUser?.bitclout_data?.bitclout_publickey) {
        const message = `To access this room you need to login with your deso account`;
        globals.showModal(
          `Oops`,
          <>
            <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
              <p className="text-lg">{message}</p>
            </div>
          </>,
        );
        return;
      }
      const isRoomAccessible = await checkDesoPostRoomAccessable(roomData._id);
      const postHashHex = roomData?.desoPostRoom?.postHashHex;
      if (!isRoomAccessible) {
        globals.showModal(
          `Oops`,
          <>
            <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
              <p className="text-lg">{`To access this room you need to send $${requiredDiamonds} diamond on this post : `}</p>
              <p>
                <a
                  href={`https://diamondapp.com/posts/${postHashHex}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-bold break-words text-lg"
                >
                  {`https://diamondapp.com/posts/${postHashHex}`}
                </a>
              </p>
            </div>
          </>,
        );
        return;
      }
    }

    if (
      (roomData?.isCCRoom || roomData?.isDAORoom) &&
      authUser?._id !== roomData.owner?._id
    ) {
      if (!authUser?.bitclout_data?.bitclout_publickey) {
        const message = `To access this room you need to login with your deso account`;
        globals.showModal(
          `Oops`,
          <>
            <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
              <p className="text-lg">{message}</p>
            </div>
          </>,
        );
        return;
      }
      const isRoomAccessible = await checkDesoHodlerRoomAccessable(
        roomData._id,
      );
      if (!isRoomAccessible) {
        globals.showModal(
          `Oops`,
          <>
            <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
              <p className="text-lg ">
                {`To access this room you need to hold ${
                  roomData.isDAORoom && roomData?.isDAORoom
                    ? "Creator Coin and DAO Token"
                    : roomData.isCCRoom
                      ? "Creator Coin"
                      : "DAO Token"
                } of users mention in room `}
                <span className="inline-block">
                  <Link
                    to={`/rooms/${roomData._id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary font-bold break-words text-lg"
                  >
                    {roomData.name}
                  </Link>
                </span>
              </p>
            </div>
          </>,
        );
        return;
      }
    }

    if (
      roomData.isSubscriberRequireApproval &&
      !roomData.subscriberApprovedUsers?.includes(authUser?._id) &&
      authUser?._id !== roomData.owner?._id
    ) {
      globals.showModal(
        `Oops`,
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-lg">The room host has not approved you yet</p>
          </div>
        </>,
      );
      return;
    }
    if (roomData?.blockedUsers?.includes(authUser?._id)) {
      globals.showModal(
        `Oops`,
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-lg">You are blocked from the room </p>
          </div>
        </>,
      );
      return;
    }
    if (
      (roomData.isPasswordProtected &&
        !roomData.isPasswordVerified &&
        roomData.owner?._id !== authUser._id) ||
      (eventKey &&
        isPasswordProtectedEvent &&
        !roomData.isPasswordVerified &&
        roomData.owner?._id !== authUser._id)
    ) {
      setIsPasswordModalOpen(true);
    } else {
      handleJoin(callId);
    }
  };

  const joinNewCall = (subRoomName: any) => {
    setBreakoutRoomName(subRoomName);
    if (!authUser) {
      setIsLoginModalOpen(true);
      return;
    }

    if (
      roomData.isSubscriberRequireApproval &&
      !roomData.subscriberApprovedUsers?.includes(authUser?._id)
    ) {
      globals.showModal(
        `Oops`,
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-lg">
              Owner has not approved to join in this room
            </p>
          </div>
        </>,
      );
      return;
    }
    if (roomData?.blockedUsers?.includes(authUser?._id)) {
      globals.showModal(
        `Oops`,
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-lg">You are blocked from the room </p>
          </div>
        </>,
      );
      return;
    }
    if (
      roomData.isPasswordProtected &&
      !roomData.isPasswordVerified &&
      roomData.owner?._id !== authUser._id
    ) {
      setIsPasswordModalOpen(true);
    } else {
      handleJoin(undefined, true, subRoomName);
    }
  };

  const handleCancel = () => {
    try {
      const isClosed = window.close();

      if (isClosed === undefined) {
        window.location.search = "";
        window.location.pathname = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBreakoutRoomEdit = (
    breakoutRoomName: string,
    index: number,
    callId: string | undefined = undefined,
  ) => {
    globals.showModal(
      `Edit`,
      <BreakoutRoomEdit
        roomIndex={index}
        roomName={breakoutRoomName}
        callId={callId ? callId : undefined}
      />,
    );
  };
  if (roomData.labels && roomData.labels.length > 0 && roomData.callDetails) {
    Object.keys(roomData.callDetails).forEach((k) => {
      const callDetail = roomData.callDetails[k];
      const labelArray: any = [];
      callDetail.users.map((userData: any) => {
        roomData.labels.map((labelData: any) => {
          if (labelData.userId.toString() === userData._id.toString()) {
            labelArray.push(labelData.labelText);
          }
        });
      });
      roomData.callDetails[k].labelArray = labelArray;
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getLobbyData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [!!authUser, roomData.isRequireApproval]);

  const mods = roomData?.moderators?.map((mod) => mod);

  const notAllowGuestToJoin =
    authUser &&
    authUser?._id !== roomData.owner?._id &&
    !roomData.moderators.includes(authUser?._id) &&
    roomData.owner?._id &&
    roomData.isRequireApproval &&
    !callIds.includes(roomData.owner?._id) &&
    !callIds.some((callId) => mods.includes(callId));

  const getLobbyData = async () => {
    if (notAllowGuestToJoin) {
      const roomRes = await getRoomLobbyData({
        roomId: roomName || undefined,
        v: instantRoomId || undefined,
        password: password.current || undefined,
      });
      setCallIds(roomRes.data.room.callUsersIds);
    }
  };

  useEffect(() => {
    callUsersIds.current = callIds;
  }, [callIds]);

  const _renderBlockNotification = () => {
    return (
      <Tooltip
        open={checkForIsBlockedUser}
        PopperProps={{ disablePortal: true }}
        title="You can't join this room because you are blocked by the host"
      >
        <div></div>
      </Tooltip>
    );
  };

  const getMetaTitle = useCallback(() => {
    if (
      !roomData ||
      !roomData?.name ||
      roomData?.name.includes("Meeting Room")
    ) {
      return "Room";
    }
    if (roomData?.isWaveCall) {
      return "Wave Call";
    }
    return roomData?.name;
  }, [roomData]);

  const _renderMetaTags = () => {
    return (
      <Helmet>
        <title>{`${getMetaTitle()} | Vibehut `}</title>
        <meta
          name="description"
          content={`Have a ${
            roomData.isGroupRoom ? "group" : "1-on-1"
          } video call now about ${roomData.name}. Leave anytime!`}
        />
        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content={
            instantRoomId
              ? `https://vibehut.io/call?v=${instantRoomId}`
              : `https://vibehut.io/call?roomKey=${roomData?._id}`
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${roomData?.name} ${
            roomData?.name.includes("Meeting Room") ? "" : "Room"
          } | Vibehut `}
        />
        <meta
          property="og:description"
          content={`Have a ${
            roomData.isGroupRoom ? "group" : "1-on-1"
          } video call now about ${roomData.name}. Leave anytime!`}
        />
        <meta
          property="og:image"
          content={roomData?.cover?.urls?.regular || publicDefaultImageLink}
        />

        {/* <!-- Twitter Meta Tags -- > */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={domain} />
        <meta
          property="twitter:url"
          content={
            instantRoomId
              ? `https://vibehut.io/call?v=${instantRoomId}`
              : `https://vibehut.io/call?roomKey=${roomData?._id}`
          }
        />
        <meta
          name="twitter:title"
          content={`${roomData?.name} ${
            roomData?.name.includes("Meeting Room") ? "" : "Room"
          } | Vibehut `}
        />
        <meta
          name="twitter:description"
          content={`Have a ${
            roomData.isGroupRoom ? "group" : "1-on-1"
          } video call now about ${roomData.name}. Leave anytime!`}
        />
        <meta
          name="twitter:image"
          content={roomData?.cover?.urls?.regular || publicDefaultImageLink}
        />
      </Helmet>
    );
  };

  return (
    <>
      {_renderMetaTags()}
      <MediaErrorSnackbar error={mediaError} />
      <div className="relative bg-darkNight">
        {isFetching || isConnecting || isJoining ? (
          !isJoiningRoom && isConnecting ? (
            <ConnectedScreen />
          ) : (
            <ConnectingScreen
              uniqueRoomId={instantRoomId}
              isMeetingRoom={isMeetingRoom}
              handleCancel={handleCancel}
              roomData={roomData}
            />
          )
        ) : location.pathname.includes("/instant") ? (
          <ConnectingScreen
            uniqueRoomId={instantRoomId}
            isMeetingRoom={isMeetingRoom}
            handleCancel={handleCancel}
            roomData={roomData}
          />
        ) : (
          <div className="w-8/12  mx-auto flex h-full ">
            <div className="md:w-1/2 w-full  flex  items-center flex-col md:mt-32 mt-12 h-3/4 md:border-r border-navyGray md:pr-20">
              <div>
                <div className="md:w-458px md:h-294px w-260pxl h-40">
                  <LocalVideoPreview />
                </div>
                <div className="flex space-x-2 justify-center pt-4">
                  <ToggleAudioButton
                    disabled={isButtonsDisabled}
                    fromConnectingPage={false}
                  />
                  <ToggleVideoButton
                    disabled={isButtonsDisabled}
                    fromConnectingPage={false}
                  />
                  <div
                    className={`${
                      isMobile ? "w-8 h-8" : "w-10 h-10"
                    } flex items-center justify-center rounded-full    cursor-pointer ${
                      isMobile ? "bg-matteGray" : " bg-gray-700"
                    } `}
                    onClick={() => {
                      setIsVideoSettingsOpen(true);
                    }}
                  >
                    <Settings color="white" />
                  </div>
                </div>
              </div>

              <div className="pt-12 text-center">
                <p className="md:text-[32px] text-3xl font-bold text-white">
                  Ready to join?
                </p>
                <p className="w-96 m-auto pt-2 text-xl text-lightGray">
                  Time to make some magic.
                </p>
                {roomData._id &&
                (!roomData.isBreakoutRoom || roomData.isWaveCall) ? (
                  <>
                    <div className="pt-8 items-center flex justify-center gap-3">
                      <div>
                        {notAllowGuestToJoin ? (
                          <Button size="vibhut-lg" disabled>
                            Waiting for host..
                          </Button>
                        ) : (
                          <Button
                            size="vibhut-lg"
                            onClick={() => joinCall()}
                            disabled={
                              checkForIsBlockedUser ||
                              isButtonsDisabled ||
                              isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                "pending"
                            }
                          >
                            {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                            "pending"
                              ? "Asking to be let in..."
                              : "Join Now"}
                          </Button>
                        )}
                      </div>
                      <div>
                        <Button
                          size="vibhut-lg"
                          variant="dark"
                          onClick={() => setIsFilterOpen(true)}
                        >
                          Filter Matches
                        </Button>
                      </div>
                    </div>
                    {_renderBlockNotification()}
                  </>
                ) : (
                  <>
                    {roomData.isBreakoutRoom ? (
                      <></>
                    ) : (
                      <div className="pt-8 items-center flex justify-center gap-3">
                        <div>
                          <Button size="vibhut-lg" disabled>
                            Join Now
                          </Button>
                        </div>

                        <div>
                          <Button size="vibhut-lg" variant="dark" disabled>
                            Filter Matches
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* mobile section */}
              <div className="block md:hidden">
                {roomData &&
                  roomData.isBreakoutRoom &&
                  !roomData.isWaveCall &&
                  (Object.values(roomData.calls).length * 2 === 0 ? (
                    <p className="text-primaryGray text-center pt-1">
                      There is no one talking at the moment.
                    </p>
                  ) : (
                    <p className="text-primaryGray text-center text-lg">
                      {roomData?.isGroupRoom
                        ? roomData.talkingUsersCount
                        : Object.values(roomData.calls).length * 2}{" "}
                      talking&nbsp;{" "}
                      {` · ${roomData?.maxUsersPerGroup} people / call`}
                    </p>
                  ))}

                {!roomData.isWaveCall && roomData.isBreakoutRoom && (
                  <>
                    <div className="flex flex-col w-96 md:border-b border-navyGray px-4">
                      <div className="flex justify-between flex-wrap w-full mt-4">
                        <p className="text-primaryGray pt-2">
                          Randomly join an open call
                        </p>
                        {notAllowGuestToJoin ? (
                          <Button size="vibhut-sm" disabled>
                            Waiting for host..
                          </Button>
                        ) : (
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-primary-outline"
                            onClick={() => joinCall()}
                            disabled={
                              checkForIsBlockedUser ||
                              isButtonsDisabled ||
                              isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                "pending"
                            }
                          >
                            {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                            "pending" ? (
                              "Asking to be let in..."
                            ) : (
                              <>
                                {" "}
                                Join{" "}
                                {Object.values(roomData.calls).length > 0
                                  ? "Random"
                                  : "Call"}
                              </>
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-between flex-wrap w-full  px-4 ">
                      <div className="w-full backdrop-blur-md">
                        {Object.values(roomData.callDetails).map(
                          (call: any, index) => {
                            return (
                              <div
                                className="flex flex-row items-center justify-between flex-wrap w-full mt-4"
                                key={call.subRoomName + index}
                              >
                                <div className="flex flex-row items-center pr-2">
                                  <div className="w-16">
                                    <div className="relative">
                                      <Avatar
                                        src={call.users[0].profile_picture}
                                        username={call.users[0].username}
                                        size="sm-2"
                                      />
                                      {call.users[1]?.profile_picture ? (
                                        <span className="absolute -top-0.5 left-6 border-2 border-darkNight rounded-full">
                                          <Avatar
                                            src={call.users[1].profile_picture}
                                            username={call.users[1].username}
                                            size="sm-2"
                                          />
                                        </span>
                                      ) : undefined}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="flex items-center gap-1 cursor-pointer">
                                      <p className="text-primaryGray text-start">
                                        {call.subRoomName}
                                      </p>
                                      {((roomData.isGroupRoom &&
                                        authUser?._id ===
                                          roomData.owner?._id) ||
                                        (roomData.moderators !== undefined &&
                                          roomData.moderators.includes(
                                            authUser?._id,
                                          ))) && (
                                        <p
                                          className="text-white"
                                          onClick={() =>
                                            handleBreakoutRoomEdit(
                                              call.subRoomName,
                                              index,
                                              call._id,
                                            )
                                          }
                                        >
                                          <Edit color="#fff" />
                                        </p>
                                      )}
                                    </div>
                                    <p className="text-primaryGray text-start">
                                      {roomData?.maxUsersPerGroup -
                                        call.users.length}{" "}
                                      spots available ({call.users.length}/
                                      {roomData?.maxUsersPerGroup})
                                    </p>
                                  </div>
                                </div>
                                {notAllowGuestToJoin ? (
                                  <Button size="vibhut-sm" disabled>
                                    Waiting for host..
                                  </Button>
                                ) : (
                                  <Button
                                    size="vibhut-sm"
                                    onClick={() => {
                                      if (
                                        Number(call.users.length) >=
                                        Number(roomData?.maxUsersPerGroup)
                                      ) {
                                        return;
                                      }
                                      joinCall(
                                        Object.keys(roomData.calls)[index],
                                      );
                                    }}
                                    disabled={
                                      Number(call.users.length) >=
                                        Number(roomData?.maxUsersPerGroup) ||
                                      isButtonsDisabled ||
                                      isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                        "pending" ||
                                      checkForIsBlockedUser
                                    }
                                  >
                                    {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                      "pending" &&
                                    breakoutRoomCallId === call._id
                                      ? "Asking to be let in..."
                                      : "Join Now"}
                                  </Button>
                                )}
                              </div>
                            );
                          },
                        )}
                        {(roomData.owner?._id === authUser._id ||
                          (roomData.moderators !== undefined &&
                            roomData.moderators.includes(authUser?._id))) &&
                          roomData?.filteredBreakoutRoomNames?.map(
                            (room: any, index) => {
                              return (
                                <div
                                  className="flex flex-row items-center justify-between flex-wrap w-full mt-4"
                                  key={
                                    (Object.values(room)[0] as string) + index
                                  }
                                >
                                  <div className="flex flex-row items-center pr-2">
                                    <div className="flex flex-col">
                                      <div className="flex items-center gap-1 cursor-pointer">
                                        <p className="text-primaryGray text-start">
                                          {Object.values(room)[0]}
                                        </p>
                                        {((roomData.isGroupRoom &&
                                          authUser?._id ===
                                            roomData.owner?._id) ||
                                          (roomData.moderators !== undefined &&
                                            roomData.moderators.includes(
                                              authUser?._id,
                                            ))) && (
                                          <p
                                            className="text-white"
                                            onClick={() =>
                                              handleBreakoutRoomEdit(
                                                Object.values(
                                                  room,
                                                )[0] as string,
                                                index,
                                              )
                                            }
                                          >
                                            <Edit color="#fff" />
                                          </p>
                                        )}
                                      </div>
                                      <p className="text-primaryGray text-start">
                                        {roomData?.maxUsersPerGroup} spots
                                        available (0/
                                        {roomData?.maxUsersPerGroup})
                                      </p>
                                    </div>
                                  </div>
                                  {notAllowGuestToJoin ? (
                                    <Button size="vibhut-sm" disabled>
                                      Waiting for host..
                                    </Button>
                                  ) : (
                                    <Button
                                      size="vibhut-sm"
                                      onClick={() => {
                                        joinNewCall(Object.values(room)[0]);
                                      }}
                                      disabled={
                                        checkForIsBlockedUser ||
                                        isButtonsDisabled ||
                                        isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                          "pending"
                                      }
                                    >
                                      {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                        "pending" &&
                                      breakoutRoomName ===
                                        Object.values(room)[0]
                                        ? "Asking to be let in..."
                                        : "Join Now"}
                                    </Button>
                                  )}
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-1/2 md:flex hidden md:pl-20 mt-32">
              <div>
                <p className="inline-block bg-navyGray capitalize rounded-lg px-2 py-1.5 text-sm text-primaryGray font-medium ">
                  Room
                </p>
                <p className="font-medium text-xl text-white pt-4 custom-third-line-clamp">
                  {isMeetingRoom
                    ? "Meeting room"
                    : roomData?.name?.replace("with $(_user)", "")}
                </p>

                {roomData &&
                  (Object.values(roomData.calls).length * 2 === 0 ? (
                    <p className="text-primaryGray pt-2">
                      There is no one talking at the moment.
                    </p>
                  ) : (
                    !roomData.isWaveCall &&
                    roomData.isBreakoutRoom && (
                      <p className="text-primaryGray pt-2 text-center md:text-left md:text-base text-lg">
                        {roomData?.isGroupRoom
                          ? roomData.talkingUsersCount
                          : Object.values(roomData.calls).length * 2}{" "}
                        talking&nbsp;{" "}
                        {` · ${roomData?.maxUsersPerGroup} people / call`}
                      </p>
                    )
                  ))}

                <>
                  {!roomData.isWaveCall && roomData.isBreakoutRoom && (
                    <div className="flex flex-col w-96 md:border-b border-navyGray pb-4">
                      <div className="flex justify-between flex-wrap w-full mt-6">
                        <p className="text-primaryGray pt-2">
                          Randomly join an open call
                        </p>
                        {notAllowGuestToJoin ? (
                          <Button size="vibhut-sm" disabled>
                            Waiting for host..
                          </Button>
                        ) : (
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-primary-outline"
                            onClick={() => joinCall()}
                            disabled={
                              checkForIsBlockedUser ||
                              isButtonsDisabled ||
                              isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                "pending"
                            }
                          >
                            {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                            "pending" ? (
                              "Asking to be let in..."
                            ) : (
                              <>
                                {" "}
                                Join{" "}
                                {Object.values(roomData.calls).length > 0
                                  ? "Random"
                                  : "Call"}
                              </>
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  {roomData?.isWaveCall || !roomData.isBreakoutRoom ? (
                    <>
                      <p className="text-primaryGray pt-2">
                        {roomData?.isGroupRoom
                          ? roomData.talkingUsersCount
                          : Object.values(roomData.calls).length * 2}
                        &nbsp;talking ·
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => setIsModalOpen(true)}
                        >
                          &nbsp; See All
                        </span>
                      </p>
                      <div className="flex flex-col">
                        <div className="flex flex-wrap w-full mt-6">
                          {roomData?.liveUsers
                            ?.slice(0, 12)
                            ?.map((liveUser, index) => (
                              <div
                                key={index}
                                className="relative mt-5 cursor-pointer"
                              >
                                <ConnectingScreenProfile user={liveUser} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col justify-between flex-wrap w-full   ">
                      <div className="w-full  max-h-96 overflow-y-auto custom-scrollbar backdrop-blur-md">
                        {Object.values(roomData.callDetails)?.map(
                          (call: any, index) => {
                            return (
                              <div
                                className="flex flex-row items-center justify-between flex-wrap w-full mt-4"
                                key={call.subRoomName + index}
                              >
                                <div className="flex flex-row items-center pr-2">
                                  <div className="w-16">
                                    <div className="relative">
                                      <Avatar
                                        src={call.users[0].profile_picture}
                                        username={call.users[0].username}
                                        size="sm-2"
                                      />
                                      {call.users[1]?.profile_picture ? (
                                        <span className="absolute -top-0.5 left-6 border-2 border-darkNight rounded-full">
                                          <Avatar
                                            src={call.users[1].profile_picture}
                                            username={call.users[1].username}
                                            size="sm-2"
                                          />
                                        </span>
                                      ) : undefined}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="flex items-center gap-1 cursor-pointer">
                                      <p className="text-primaryGray text-start">
                                        {call.subRoomName}
                                      </p>
                                      {((roomData.isGroupRoom &&
                                        authUser?._id ===
                                          roomData.owner?._id) ||
                                        (roomData.moderators !== undefined &&
                                          roomData.moderators.includes(
                                            authUser?._id,
                                          ))) && (
                                        <p
                                          className="text-white"
                                          onClick={() =>
                                            handleBreakoutRoomEdit(
                                              call.subRoomName,
                                              index,
                                              call._id,
                                            )
                                          }
                                        >
                                          <Edit color="#fff" />
                                        </p>
                                      )}
                                    </div>

                                    <p className="text-primaryGray text-start">
                                      {roomData?.maxUsersPerGroup -
                                        call.users.length}{" "}
                                      spots available ({call.users.length}/
                                      {roomData?.maxUsersPerGroup})
                                    </p>

                                    {call?.labelArray &&
                                      call.labelArray?.map(
                                        (label: any, index: number) => {
                                          return (
                                            <span
                                              key={index}
                                              className="pl-1 mb-1"
                                            >
                                              <Tag title={label} />
                                            </span>
                                          );
                                        },
                                      )}
                                  </div>
                                </div>
                                {notAllowGuestToJoin ? (
                                  <Button size="vibhut-sm" disabled>
                                    Waiting for host..
                                  </Button>
                                ) : (
                                  <Button
                                    size="vibhut-sm"
                                    onClick={() => {
                                      if (
                                        Number(call.users.length) >=
                                        Number(roomData?.maxUsersPerGroup)
                                      ) {
                                        return;
                                      }
                                      joinCall(
                                        Object.keys(roomData.calls)[index],
                                      );
                                    }}
                                    disabled={
                                      Number(call.users.length) >=
                                        Number(roomData?.maxUsersPerGroup) ||
                                      isButtonsDisabled ||
                                      isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                        "pending" ||
                                      checkForIsBlockedUser
                                    }
                                  >
                                    {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                      "pending" &&
                                    breakoutRoomCallId ===
                                      Object.keys(roomData.calls)[index]
                                      ? "Asking to be let in..."
                                      : "Join Now"}
                                  </Button>
                                )}
                              </div>
                            );
                          },
                        )}
                        {(roomData.owner?._id === authUser._id ||
                          (roomData.moderators !== undefined &&
                            roomData.moderators.includes(authUser?._id))) &&
                          roomData?.filteredBreakoutRoomNames?.map(
                            (room: any, index) => {
                              return (
                                <div
                                  className="flex flex-row items-center justify-between flex-wrap w-full mt-4"
                                  key={
                                    (Object.values(room)[0] as string) + index
                                  }
                                >
                                  <div className="flex flex-row items-center pr-2">
                                    <div className="flex flex-col">
                                      <div className="flex items-center gap-1 cursor-pointer">
                                        <p className="text-primaryGray text-start">
                                          {Object.values(room)[0]}
                                        </p>
                                        {((roomData.isGroupRoom &&
                                          authUser?._id ===
                                            roomData.owner?._id) ||
                                          (roomData.moderators !== undefined &&
                                            roomData.moderators.includes(
                                              authUser?._id,
                                            ))) && (
                                          <p
                                            className="text-white"
                                            onClick={() =>
                                              handleBreakoutRoomEdit(
                                                Object.values(
                                                  room,
                                                )[0] as string,
                                                index,
                                              )
                                            }
                                          >
                                            <Edit color="#fff" />
                                          </p>
                                        )}
                                      </div>
                                      <p className="text-primaryGray text-start">
                                        {roomData?.maxUsersPerGroup} spots
                                        available (0/
                                        {roomData?.maxUsersPerGroup})
                                      </p>
                                    </div>
                                  </div>
                                  {notAllowGuestToJoin ? (
                                    <Button size="vibhut-sm" disabled>
                                      Waiting for host..
                                    </Button>
                                  ) : (
                                    <Button
                                      size="vibhut-sm"
                                      onClick={() => {
                                        joinNewCall(Object.values(room)[0]);
                                      }}
                                      disabled={
                                        checkForIsBlockedUser ||
                                        isButtonsDisabled ||
                                        isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                          "pending"
                                      }
                                    >
                                      {isAnyJoinRequestFromGuest.isRequestAcceptedByHost ===
                                        "pending" &&
                                      breakoutRoomName ===
                                        Object.values(room)[0]
                                        ? "Asking to be let in..."
                                        : "Join Now"}
                                    </Button>
                                  )}
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        )}

        <VideoSettings
          isOpen={isVideoSettingsOpen}
          setIsOpen={() => {
            setIsVideoSettingsOpen(false);
          }}
        />
        <TalkingUsersModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          usersCount={roomData.liveUsersCount}
          talkingUsers={Object.values(roomData.calls)}
          lobby_users={roomData.lobbyUsers}
        />
        <FilterCall
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          joinFilterCall={joinFilterCall}
        />
        <RoomPasswordModal
          roomId={roomData._id}
          isOpen={isPasswordModalOpen}
          setIsOpen={setIsPasswordModalOpen}
          setIsPasswordVerified={setIsPasswordVerified}
          roomPassword={password.current}
          eventId={eventKey}
        />
        {!authUser && (
          <LoginModal
            isOpen={isLoginModalOpen}
            setIsOpen={setIsLoginModalOpen}
            isDismissible={false}
            isUserLoggedIn={false}
            roomUniqueId={isMeetingRoom}
            isGuestAllowed={roomData.isGuestAllowed}
          />
        )}
      </div>
    </>
  );
}
