import * as actionTypes from "../constants";

const initialState = {
  roles: [],
  isFetching: false,
};

function roomReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        roles: action.payload.roles,
      };
    case actionTypes.GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}

export default roomReducer;
