import React, { useEffect } from 'react';
import useVibehutData from '../hooks/useVibehutData/useVibehutData';
import { globals } from '../globals';
import { getPermissionOfUser, userServiceRoleMap } from '../utils/utils';

interface PaidBadgeProps {
  serviceRole?: string;
  isInline?: boolean;
}

const PaidBadge = ({ serviceRole, isInline = false }: PaidBadgeProps) => {
  const { roles } = useVibehutData(globals.roomId);

  const [color, setColor] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    if (serviceRole && roles) {
      const data = getPermissionOfUser(roles, serviceRole);
      if (!data) return;
      const userRole = userServiceRoleMap[data];
      if (!userRole?.badgeColor) return;
      setColor(userRole?.badgeColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, serviceRole]);

  if (!color) return <></>;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isInline ? 'inline' : ''}
    >
      <path
        d="M7.23463 0.317025C7.72469 0.114037 8.27531 0.114037 8.76537 0.317025L12.8915 2.02612C13.3815 2.22911 13.7709 2.61846 13.9739 3.10851L15.683 7.23463C15.886 7.72469 15.886 8.27531 15.683 8.76537L13.9739 12.8915C13.7709 13.3815 13.3815 13.7709 12.8915 13.9739L8.76537 15.683C8.27531 15.886 7.72469 15.886 7.23463 15.683L3.10851 13.9739C2.61846 13.7709 2.22911 13.3815 2.02612 12.8915L0.317025 8.76537C0.114037 8.27531 0.114037 7.72469 0.317025 7.23463L2.02612 3.10851C2.22911 2.61846 2.61846 2.22911 3.10851 2.02612L7.23463 0.317025Z"
        fill={color}
      />
      <path
        d="M5.28711 8.19594L7.28711 10.4817L11.2871 6.48166"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaidBadge;
