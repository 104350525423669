import React from "react";

const StarIconFilled = ({
  width = "30",
  height = "27",
  colour = "#FFCC00",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3124 12.2975L22.6749 17.2175L24.3637 24.5425C24.453 24.9254 24.4275 25.3262 24.2904 25.6947C24.1532 26.0632 23.9104 26.3831 23.5924 26.6144C23.2744 26.8457 22.8953 26.9782 22.5025 26.9951C22.1096 27.0121 21.7205 26.913 21.3837 26.71L14.9949 22.835L8.61993 26.71C8.28314 26.913 7.894 27.0121 7.50115 26.9951C7.1083 26.9782 6.72917 26.8457 6.41117 26.6144C6.09317 26.3831 5.85041 26.0632 5.71324 25.6947C5.57607 25.3262 5.55057 24.9254 5.63993 24.5425L7.32618 17.225L1.68743 12.2975C1.38919 12.0403 1.17353 11.7007 1.0675 11.3214C0.961467 10.9421 0.96978 10.54 1.0914 10.1654C1.21301 9.79079 1.44251 9.46044 1.75113 9.21576C2.05974 8.97109 2.43372 8.82297 2.82618 8.79L10.2587 8.14624L13.1599 1.22624C13.3114 0.86314 13.567 0.552979 13.8944 0.334817C14.2218 0.116654 14.6065 0.000244141 14.9999 0.000244141C15.3934 0.000244141 15.778 0.116654 16.1054 0.334817C16.4329 0.552979 16.6884 0.86314 16.8399 1.22624L19.7499 8.14624L27.1799 8.79C27.5724 8.82297 27.9464 8.97109 28.255 9.21576C28.5636 9.46044 28.7931 9.79079 28.9147 10.1654C29.0363 10.54 29.0446 10.9421 28.9386 11.3214C28.8326 11.7007 28.6169 12.0403 28.3187 12.2975H28.3124Z"
        fill={colour}
      />
    </svg>
  );
};

export default StarIconFilled;
