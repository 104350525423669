import axios from "../utils/axios";

export function getPremiumCreationAccess(name, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/premiumCreator/access`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
