import React from "react";

function Call({ ratio = "22" }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      aria-hidden="true"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9994 15.9738V18.9846C21.0005 19.2642 20.9432 19.5408 20.831 19.7969C20.7188 20.053 20.5542 20.2829 20.3478 20.4719C20.1415 20.6608 19.8978 20.8047 19.6325 20.8942C19.3672 20.9838 19.0861 21.017 18.8072 20.9919C15.7128 20.6563 12.7404 19.601 10.1289 17.9108C7.69921 16.3699 5.63926 14.3141 4.09534 11.8892C2.39586 9.27099 1.33824 6.29008 1.00816 3.18795C0.983033 2.91042 1.01608 2.63071 1.1052 2.36663C1.19432 2.10254 1.33757 1.85987 1.52581 1.65407C1.71405 1.44826 1.94317 1.28383 2.19858 1.17123C2.45398 1.05864 2.73008 1.00036 3.0093 1.0001H6.02608C6.5141 0.995304 6.98722 1.16778 7.35725 1.48537C7.72727 1.80297 7.96896 2.24401 8.03727 2.72629C8.1646 3.68982 8.40074 4.63587 8.74118 5.54642C8.87648 5.90563 8.90576 6.29602 8.82556 6.67133C8.74536 7.04665 8.55903 7.39115 8.28867 7.66402L7.01156 8.93859C8.44308 11.4512 10.5276 13.5315 13.0451 14.9602L14.3222 13.6856C14.5956 13.4158 14.9408 13.2298 15.3169 13.1498C15.6929 13.0698 16.0841 13.099 16.444 13.234C17.3564 13.5738 18.3043 13.8095 19.2698 13.9365C19.7582 14.0053 20.2044 14.2509 20.5233 14.6265C20.8422 15.0021 21.0116 15.4817 20.9994 15.9738Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Call;
