import React, { useState } from 'react';
import CopyIcon from '../Icons/CopyIcon';
import useLiveVideo from '../../hooks/useLiveVideo/useLiveVideo';
import CreatedLinkModal from '../LinkModal/LinkModal';
import { frontEndURL } from '../../constants';
import useVibehutData from '../../hooks/useVibehutData/useVibehutData';
import { globals } from '../../globals';
import CopyButton from '../CopyButton';
import { isMobile } from '../../utils';
import useWatchingCount from '../../hooks/useWatchingCount/useWatchingCount';

function Livetag() {
  const { isLive } = useLiveVideo();
  const roomId = globals.roomId;
  const { callId } = useVibehutData(roomId);
  const [isOpen, setIsOpen] = useState(false);
  const liveWatchingCount = useWatchingCount(callId);
  if (!isLive) {
    return <></>;
  }
  return (
    <div className="flex items-center gap-2">
      <div className="bg-componentColor text-white px-2 py-1.5 inline-flex items-center space-x-1 rounded-lg">
        <span className="text-sm text-green-500 font-bold">Live 🤵‍♂️ {liveWatchingCount}</span>
      </div>
      <div>
        {isMobile ? (
          <span onClick={() => setIsOpen(true)} className="cursor-pointer">
            <CopyIcon />
          </span>
        ) : (
          <CopyButton url={`${frontEndURL}/rooms/${roomId}/calls/${callId}/live`} forMobile topPosition="top-8" />
        )}
      </div>
      <CreatedLinkModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        link={`${frontEndURL}/rooms/${roomId}/calls/${callId}/live`}
      />
    </div>
  );
}

export default Livetag;
