import { useEffect, useContext, useCallback, useState } from "react";
import moment from "moment-timezone";
import { StoreContext } from "../../Context/StoreContext";
import { getCallStreak } from "../../actions/userActions";
import Tick from "../../VibehutUI/Icons/Tick";
import { timeLeftToday } from "../../utils/utils";
import PopOver from "../../VibehutUI/Popover";

function CallStreak({ isReviewPage = false }) {
  const { state, dispatch } = useContext(StoreContext);
  const [streakMode, setStreakMode] = useState("");
  const [streakTimeLeft, setStreakTimeLeft] = useState("");
  const zone = moment.tz.guess();
  const [
    settingStreakModeFromLocalStorage,
    setSettingStreakModeFromLocalStorage,
  ] = useState(true);
  const {
    auth: { user },
    user: { callStreak },
  } = state;

  useEffect(() => {
    try {
      const _streakMode = localStorage.getItem("streakMode");
      if (_streakMode) {
        setStreakMode(_streakMode);
      }
    } catch (error) {
    } finally {
      setSettingStreakModeFromLocalStorage(false);
    }
  }, []);

  useEffect(() => {
    if (user?.token && zone && !settingStreakModeFromLocalStorage) {
      getCallStreak(user?.token, dispatch, zone, streakMode);
    }
  }, [
    zone,
    dispatch,
    user?.token,
    streakMode,
    settingStreakModeFromLocalStorage,
  ]);

  const today = new Date();
  const currentDay = today.getDay();
  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
  const modifiedDaysOfWeek = [
    ...daysOfWeek.slice(currentDay + 1),
    ...daysOfWeek.slice(0, currentDay + 1),
  ];

  const getDayColor = useCallback(
    (call) => {
      if (callStreak?.streakCount >= 7 && call) {
        return "bg-gradient-btn text-transparent bg-clip-text";
      }
      if (call) {
        return "text-primary";
      }
      return "text-primaryGray";
    },
    [callStreak?.streakCount],
  );

  const getTickColor = useCallback(
    (call) => {
      if (callStreak?.streakCount >= 7 && call) {
        return "bg-gradient-btn";
      }
      if (call) {
        return "bg-primary";
      }
      return "bg-primaryGray";
    },
    [callStreak?.streakCount],
  );

  const updateStreakMode = () => {
    if (!streakMode) {
      setStreakMode("elite");
      localStorage.setItem("streakMode", "elite");
    } else {
      setStreakMode("");
      localStorage.removeItem("streakMode");
    }
  };

  const onStreakMouseHover = (index, call) => {
    if (!call && callStreak.streakDays.length - 1 === index) {
      const timeLeft = timeLeftToday();
      if (timeLeft.isMinute) {
        const hoverMsg = `After ${timeLeft.difference} ${
          timeLeft.difference === 1 ? "minute" : "minutes"
        }, your streak will be over.`;
        setStreakTimeLeft(hoverMsg);
        return;
      }
      const hoverMsg = `After ${timeLeft.difference} ${
        timeLeft.difference === 1 ? "hour" : "hours"
      }, your streak will be over.`;
      setStreakTimeLeft(hoverMsg);
    }
  };

  return (
    <div className="flex">
      {callStreak?.streakDays?.length > 0 && (
        <div className="flex flex-col items-center gap-2">
          <div className="flex gap-6 items-center font-bold justify-center">
            {callStreak?.streakCount === 0 && (
              <div
                className="md:flex flex-col hidden relative cursor-pointer"
                onClick={updateStreakMode}
              >
                <p
                  className={`${
                    isReviewPage ? "text-white" : "text-darkGray"
                  }  text-sm leading-4`}
                >
                  Your Call
                </p>
                <p
                  className={`${
                    isReviewPage ? "text-white" : "text-darkGray"
                  }  text-sm leading-4`}
                >
                  Streak
                </p>
                {streakMode === "elite" && (
                  <p className="absolute -top-3 -right-3 text-primary text-xs">
                    Elite
                  </p>
                )}
              </div>
            )}

            {callStreak?.streakCount > 0 && (
              <div className="flex gap-2 items-center">
                <p
                  className={`text-primary text-3xl italic ${
                    callStreak?.streakCount >= 7 &&
                    "bg-gradient-btn text-transparent bg-clip-text"
                  }`}
                >
                  {callStreak?.streakCount}
                </p>
                <div
                  className="flex flex-col relative cursor-pointer"
                  onClick={updateStreakMode}
                >
                  <p
                    className={`${
                      isReviewPage ? "text-white" : "text-darkGray"
                    }  text-sm leading-4`}
                  >
                    Day
                  </p>

                  <p
                    className={`${
                      isReviewPage ? "text-white" : "text-darkGray"
                    }  text-sm leading-4`}
                  >
                    Streak
                  </p>

                  {streakMode === "elite" && (
                    <p className="absolute -top-3 -right-3 text-primary text-xs">
                      Elite
                    </p>
                  )}
                </div>
              </div>
            )}
            <div className="md:flex gap-4 hidden">
              {callStreak.streakDays
                .slice()
                .reverse()
                .map((call, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center gap-1 relative"
                    onClick={() => onStreakMouseHover(index, call)}
                    onMouseOver={() => onStreakMouseHover(index, call)}
                    onMouseLeave={() => setStreakTimeLeft("")}
                  >
                    <p className={` text-xs ${getDayColor(call)}`}>
                      {modifiedDaysOfWeek[index]}
                    </p>
                    <div
                      className={`${
                        !call && callStreak.streakDays.length - 1 === index
                          ? ""
                          : "rounded-[50%]"
                      } p-1 ${getTickColor(call)}  `}
                    >
                      <Tick ratio={14} />
                    </div>
                    {streakTimeLeft &&
                      !call &&
                      callStreak.streakDays.length - 1 === index && (
                        <span className="absolute -right-12 top-14">
                          <PopOver
                            text={streakTimeLeft}
                            size="60"
                            arrowPosition="left"
                          />
                        </span>
                      )}
                  </div>
                ))}
            </div>
          </div>
          {callStreak?.streakCount >= 7 && isReviewPage && (
            <p className="text-darkGray text-center text-sm">
              You’re on a 🔥7 day call streak.
            </p>
          )}
        </div>
      )}
      <div></div>
    </div>
  );
}

export default CallStreak;
