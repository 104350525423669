import { Persona, PersonaActivity, PersonaFaq } from "../types";
import axios from "../utils/axios";
import Axios from "axios";

export function generateImageUsingDalle(prompt: string, token: string) {
  return axios
    .post(
      `/personas/generate-image-using-dalle`,
      { prompt },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getMyPersonas(token: string) {
  return axios
    .get(`/personas/my`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getAllPersonas({ token, query, offset, orderBy }: any) {
  const endPoint = query
    ? `/personas?query=${query}&limit=20&offset=${offset}&orderBy=${orderBy}`
    : `/personas?limit=20&offset=${offset}&orderBy=${orderBy}`;
  return axios
    .get(endPoint, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function createPersona(data: Persona, token: string) {
  return axios
    .post(`/personas`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function updatePersona(data: Persona, personaId: string, token: string) {
  return axios
    .patch(`/personas/${personaId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function deletePersona(personaId: string, token: string) {
  return axios
    .delete(`/personas/${personaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function generateDataForPersona(prompt: string, token: string) {
  return axios
    .post(
      `/personas/generate-data-for-persona`,
      { prompt },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function updatePersonaClickCount(personaId: string, token: string) {
  return axios
    .get(`/personas/update-persona-click/${personaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function createPersonaActivity(data: PersonaActivity, token: string) {
  return axios
    .post(`/personas/activity`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonaActivity(token: string, personaId: string) {
  return axios
    .get(`/personas/activity/${personaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonaMatchingSummary(
  token: string,
  personaId: string,
  options: any = {},
) {
  const { signal } = options;

  const source = Axios.CancelToken.source();

  if (signal) {
    signal.addEventListener("abort", () => {
      source.cancel("Request aborted");
    });
  }

  return axios
    .get(`/personas/matching-summary/${personaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonaByCreator(creatorId: string, options: any = {}) {
  const { signal } = options;

  const source = Axios.CancelToken.source();

  if (signal) {
    signal.addEventListener("abort", () => {
      source.cancel("Request aborted");
    });
  }
  return axios
    .get(`/personas/creator/${creatorId}`, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonaById(
  personaId: string,
  token: string,
  options: any = {},
) {
  const { signal } = options;

  const source = Axios.CancelToken.source();

  if (signal) {
    signal.addEventListener("abort", () => {
      source.cancel("Request aborted");
    });
  }
  return axios
    .get(`/personas/${personaId}`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function generateTagsForPersona(prompt: string, token: string) {
  return axios
    .post(
      `/personas/generate-tags-for-persona`,
      { prompt },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonaFaqs(token: string, personaId: string) {
  return axios
    .get(`/personas/faqs/${personaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function createPersonaFaq(
  data: PersonaFaq,
  token: string,
  personaId: string,
) {
  return axios
    .post(`/personas/faqs/${personaId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonasTags(options: any = {}, token?: string) {
  const { signal } = options;

  const source = Axios.CancelToken.source();

  if (signal) {
    signal.addEventListener("abort", () => {
      source.cancel("Request aborted");
    });
  }
  return axios
    .get(`/personas/tags`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonasPremiumTags(options: any = {}) {
  const { signal } = options;

  const source = Axios.CancelToken.source();

  if (signal) {
    signal.addEventListener("abort", () => {
      source.cancel("Request aborted");
    });
  }
  return axios
    .get(`/personas/premium-tags`, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonasWeeklyMatches(token: string) {
  return axios
    .get(`/personas/weekly-matches`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonasDetailsForDesoPersona(token: string) {
  return axios
    .get(`/personas/deso`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getSuggestedPersonas(
  token: string,
  suggestionType: string,
  regenerate = false,
) {
  return axios
    .get(
      `/personas/suggested-personas?suggestionType=${suggestionType}&regenerate=${regenerate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonasDetailsForDesoPersonaV2(
  token: string,
  data: { name: string; about: string },
) {
  return axios
    .post(`/personas/deso`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getSubscribedRoomPersonas(token: string) {
  return axios
    .get(`/personas/subscribed-room-personas`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getRoomSubscribersPersonas(roomId: string, query: any) {
  const endPoint = query
    ? `/personas/room-subscriber-personas/${roomId}?query=${query}`
    : `/personas/room-subscriber-personas/${roomId}`;
  return axios.get(endPoint).then((res) => {
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  });
}

export function initiatePersonaVideoInsight(
  token: string,
  data: { persona: string; videoLink: string; type: string },
) {
  return axios
    .post(`/personas/persona-video-insight`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getMyPersonaVideoInsights(token: string) {
  return axios
    .get(`/personas/persona-video-insight`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getVideoInsightWithPersona(
  token: string,
  videoInsightId: string,
) {
  return axios
    .get(`/personas/persona-video-insight/${videoInsightId}`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        : {},
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function regeneratePersonaVideoInsight(
  token: string,
  insightId: string,
) {
  return axios
    .patch(
      `/personas/regenerate-persona-video-insight/${insightId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function waitRegeneratePersonaVideoInsight(
  token: string,
  insightId: string,
) {
  return axios
    .patch(
      `/personas/wait-regenerate-persona-video-insight/${insightId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getNextMyVideoInsightWithPersona(
  token: string,
  createdAt: string,
  limit = 3,
) {
  return axios
    .get(
      `/personas/persona-video-insight-next?createdAt=${createdAt}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function generatePersonaArticles(
  token: string,
  data: { persona: string; channel: string; type: string },
) {
  return axios
    .post(`/personas/persona-articles`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getMyPersonaArticles(token: string) {
  return axios
    .get(`/personas/persona-articles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getPersonaArticlesWithPersona(token: string, id: string) {
  return axios
    .get(`/personas/persona-articles/${id}`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        : {},
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}
