import React, { useState, useContext } from "react";
import SendActive from "../Icons/SendActive";
import SendInActive from "../Icons/SendInActive";
import TextareaAutosize from "react-textarea-autosize";
import useVibehutChatContext from "../../hooks/useVibehutChatContext/useVibehutChatContext";
import { VibehutDataContext } from "../../components/VibehutDataProvider";
import AttachmentButton from "../../../VibehutUI/MessageComposer/AttachmentButton";
import AttachmentContainer from "../../../VibehutUI/MessageComposer/AttachmentContainer";
import { uploadAttachment } from "../../../utils/uploadMedia";

function MessageComposer() {
  const [text, setText] = useState("");
  const {
    sendMessage,
    setAttachmentFile,
    isMediaUploading,
    attachmentFile,
    setIsMediaUploading,
  } = useVibehutChatContext();
  const { roomData } = useContext(VibehutDataContext);
  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setText(e.target.value);
  };

  const handleClick = async () => {
    try {
      let attachments: any[] = [];
      if (attachmentFile) {
        setIsMediaUploading(true);
        const response = await uploadAttachment(attachmentFile);
        setAttachmentFile(null);
        if (response.data.secure_url)
          attachments = [
            { mediaUrl: response.data.secure_url, mediaType: "image" },
          ];
      }
      const result = text;
      if (result.trim() === "" && !attachmentFile) {
        return;
      }
      sendMessage(result, roomData._id, attachments);
      setText("");
    } catch (error) {
    } finally {
      setIsMediaUploading(false);
    }
  };

  const handleKeyPress = async (e: {
    key: string;
    shiftKey: any;
    preventDefault: () => void;
  }) => {
    try {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        const result = text;
        let attachments: any[] = [];
        if (attachmentFile) {
          setIsMediaUploading(true);
          const response = await uploadAttachment(attachmentFile);
          setAttachmentFile(null);
          if (response.data.secure_url)
            attachments = [
              { mediaUrl: response.data.secure_url, mediaType: "image" },
            ];
        }
        sendMessage(result, roomData._id, attachments);
        setText("");
      }
    } catch (error) {
    } finally {
      setIsMediaUploading(false);
    }
  };

  const geButtonDisableState = () => {
    if (isMediaUploading) {
      return true;
    }
    if (attachmentFile) {
      return false;
    }

    if ((!text.length || text.trim() === "") && !attachmentFile) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`relative w-full  ${
        !!attachmentFile ? "border rounded-2xl px-2 bg-componentColor" : ""
      } `}
    >
      {!!attachmentFile && (
        <AttachmentContainer
          attachmentFile={attachmentFile}
          setAttachmentFile={setAttachmentFile}
        />
      )}
      <TextareaAutosize
        onChange={handleChange}
        className="py-2 bg-matteGray text-white pr-8 pl-4 custom-scrollbar rounded-xl w-full placeholder-primaryGray outline-none resize-none overflow-auto"
        placeholder="Type a message..."
        maxRows={3}
        value={text}
        onKeyPress={handleKeyPress}
      />
      <div className="absolute right-3 bottom-3">
        <div className="flex items-center gap-1">
          <AttachmentButton
            setAttachmentFile={setAttachmentFile}
            isMediaUploading={isMediaUploading}
            isDisable={false}
            isCallChat
          />
          <button
            type="button"
            disabled={geButtonDisableState()}
            onClick={handleClick}
          >
            {(text.length && text.trim() !== "") ||
            attachmentFile ||
            !isMediaUploading ? (
              <SendActive />
            ) : (
              <SendInActive />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessageComposer;
