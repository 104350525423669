import React from "react";

function Chat({ ratio = "16" }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1812 1H5.8188C3.1574 1 1 3.19788 1 5.90785C1 7.8852 2.148 9.58761 3.8 10.3656V12.1541C3.8 12.7039 4.0016 13 4.3082 13C4.55287 12.9746 4.78141 12.8569 4.9536 12.6677L6.9136 10.8157H10.1812C12.8412 10.8157 15 8.61782 15 5.90785C15 3.19788 12.8426 1 10.1812 1ZM10.1812 9.91239H6.6L6.362 10.136L4.64 11.7598V9.7719L4.136 9.53474C2.7416 8.87764 1.84 7.45317 1.84 5.90785C1.84 3.70091 3.625 1.90634 5.8188 1.90634H10.1812C12.375 1.90634 14.16 3.70091 14.16 5.90785C14.16 8.1148 12.375 9.90937 10.1812 9.90937V9.91239Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default Chat;
