import * as actionTypes from "../constants";

const initialState = {
  exchangeRate: {
    BuyDeSoFeeBasisPoints: 0,
    NanosPerETHExchangeRate: 0,
    NanosSold: 0,
    SatoshisPerBitCloutExchangeRate: 0,
    SatoshisPerDeSoExchangeRate: 0,
    USDCentsPerBitCloutExchangeRate: 0,
    USDCentsPerBitCloutReserveExchangeRate: 0,
    USDCentsPerBitcoinExchangeRate: 0,
    USDCentsPerDeSoBlockchainDotCom: 0,
    USDCentsPerDeSoCoinbase: 0,
    USDCentsPerDeSoExchangeRate: 0,
    USDCentsPerDeSoReserveExchangeRate: 0,
    USDCentsPerETHExchangeRate: 0,
  },
  isFetching: false,
  systemSetting: {},
  diamondExchangeLists: [],
};

function systemSettingReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SYSTEM_SETTINGS_REQUEST:
    case actionTypes.GET_EXCHANGE_RATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.GET_SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        systemSetting: action.payload,
      };
    case actionTypes.GET_SYSTEM_SETTINGS_FAILURE:
    case actionTypes.GET_EXCHANGE_RATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.GET_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        exchangeRate: action.payload,
      };
    case actionTypes.UPDATE_DIAMOND_EXCHANGE_SUCCESS:
      return {
        ...state,
        diamondExchangeLists: action.payload,
      };
    default:
      return state;
  }
}

export default systemSettingReducer;
