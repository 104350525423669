import axios from "../utils/axios";

export function createReferralTransaction(token, referralCode) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/referral-transaction",
        { referralCode },
        {
          headers: token
            ? {
              Authorization: `Bearer ${token}`,
            }
            : {},
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getReferralTransaction(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/referral-transaction", {
        headers: token
          ? {
            Authorization: `Bearer ${token}`,
          }
          : {},
      })
      .then((res) => {
        if (res.status === 200 && res.data.code === 1) {
          return resolve(res.data);
        } else {
          reject(res.data.data);
        }
      })
      .catch((err) => reject(err));
  });
}
