import { Menu, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { logout } from "../../actions/authActions";
import {
  openContactModal,
  openPlanModal,
} from "../../actions/profileModalActions";
import { StoreContext } from "../../Context/StoreContext";
import { frontEndURL, videoAppUrl } from "../../utils/axios";
import {
  _renderSocialIcon,
  _renderSocialProviderText,
  _renderSocialProviderTextIcon,
  checkIsDesoUser,
  getShortenedName,
  getUserRole,
  userServiceRoleMap,
} from "../../utils/utils";
import Avatar from "../Avatar";
import Logout from "../Icons/Logout";
import Settings from "../Icons/Settings";
import UserIcon from "../Icons/UserIcon";
import { generateInstantCallUrl } from "../../services/rooomService";
import Loader from "../Loader";
import CreatedLinkModal from "../../components/CreatedLinkModal";
import CamOn from "../Icons/CamOn";
import CopyIcon from "../Icons/CopyIcon";
import { profileRoute, promotionRoute } from "../../routes";
import PaidBadge from "../Icons/PaidBadge";
import { userRoleTypes } from "../../constants";
import useIsPWA from "../../hooks/usePWA";
import { useDesoProtocol } from "../../hooks/useDesoProtocol";
import PromotionIcon from "../Icons/Promotion";
import BookingIcon from "../Icons/Booking";
import { useDisconnect } from "wagmi";

function UserMenu({
  isInviteUserList,
  user,
  size,
  userState,
  showSocialIcon = false,
  isMemeberCard = false,
  room,
  date,
  showSocialProvider = false,
  subtitle,
}) {
  const history = useHistory();
  const desoProtocol = useDesoProtocol();
  const { state, dispatch } = useContext(StoreContext);
  const { disconnectAsync } = useDisconnect();
  const { auth, role } = state;
  const [isInstanceCall, setIsInstanceCall] = useState(undefined);
  const [roomUUID, setRoomUUID] = useState(undefined);
  const isPWA = useIsPWA();
  const userRole = useMemo(() => {
    const userRole = getUserRole(role, user.serviceRole);
    return userServiceRoleMap[userRole?._id];
  }, [role, user.serviceRole]);

  const goToInstantCallPage = async () => {
    try {
      setIsInstanceCall("call");
      if (!auth?.user) {
        setIsInstanceCall(undefined);
      }
      const res = await generateInstantCallUrl(auth.user.token);
      const url = res.data;
      const uuid = url.split(`${frontEndURL}/call?v=`)[1];

      if (!isPWA) {
        window.open(`${videoAppUrl}/call?v=${uuid}`);
      } else {
        window.location.href = `${videoAppUrl}/call?v=${uuid}`;
      }
      setIsInstanceCall(undefined);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  const copyInstantCallLink = async () => {
    try {
      setIsInstanceCall("copy");
      if (!auth?.user) {
        setIsInstanceCall(undefined);
      }
      const res = await generateInstantCallUrl(auth.user.token);
      const url = res.data;
      const uuid = url.split(`${frontEndURL}/call?v=`)[1];
      setRoomUUID(uuid);
      setIsInstanceCall(undefined);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (history.location.search === "?upgrade-plan=upgrade") {
      openPlanModal(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleBookmarkClick = () => {
    const q = window.location.href;
    const selection = document.getSelection();
    const d = selection ? selection.toString() : "";
    const p = document.title;

    const url = `https://vibehut.io/resources?bookmarklet=true&url=${encodeURIComponent(
      q,
    )}&description=${encodeURIComponent(d)}&label=${encodeURIComponent(p)}`;

    window.open(url, "_blank");
  };
  const isWarpCastUser = useMemo(() => {
    if (user?.provider === "metamask") {
      if (user?.farcaster?.username) {
        return true;
      }
    }
    return false;
  }, [user]);
  return (
    <div>
      <Menu as="button" className="relative inline-block text-left px-4 w-full">
        {({ open }) => (
          <div>
            <Menu.Button
              className={`${
                open
                  ? "bg-frescoWhite"
                  : "hover:bg-frescoWhite dark:text-frescoWhite"
              } px-4 pt-1 pb-0.5 rounded-xl transition-all ease-in-out duration-75 text-darkNight
                ${isInviteUserList ? "" : "dark:hover:text-darkNight"} w-full `}
            >
              <div className="flex items-center space-x-3 cursor-pointer ">
                <div className="relative">
                  <Avatar
                    username={
                      user.provider === "metamask"
                        ? user.username.slice(0, 5)
                        : user.username
                    }
                    src={user.profile_picture}
                    size={size}
                    userState={userState}
                  />
                  {showSocialIcon
                    ? _renderSocialIcon(
                        isWarpCastUser ? "warpcast" : user.provider,
                        "1.5",
                        user?.icon,
                      )
                    : undefined}
                </div>

                <div>
                  <p className=" flex flex-col">
                    <span
                      className={`text-base font-medium text-left ${
                        isMemeberCard ? "text-white" : ""
                      } ${isInviteUserList ? "text-black" : ""} `}
                    >
                      {getShortenedName(user.username, 15)}{" "}
                      <PaidBadge
                        isInline={true}
                        serviceRole={user.serviceRole}
                        customClass="mb-0.5"
                      />
                    </span>
                    <span className="text-sm  font-normal">
                      {room ? <span>{room}</span> : undefined}
                      {date ? <span> · {date}</span> : undefined}
                    </span>
                  </p>
                  {showSocialProvider &&
                    (userRole && userRole.planRole !== userRoleTypes.FREE
                      ? _renderSocialProviderText(
                          isWarpCastUser ? "warpcast" : user.provider,
                        )
                      : _renderSocialProviderTextIcon(
                          isWarpCastUser ? "warpcast" : user.provider,
                          user?.icon,
                        ))}
                  {subtitle ? (
                    <p className="text-xs text-darkGray dark:text-gray-300 font-normal tracking-wide">
                      {subtitle}
                    </p>
                  ) : undefined}
                  <p className="text-xs text-darkGray dark:text-gray-300 font-normal tracking-wide">
                    {userState?.room ? (
                      <React.Fragment>
                        <span>{userState?.room}</span>
                      </React.Fragment>
                    ) : undefined}
                  </p>
                </div>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute md:left-12 left-16 w-180px -top-[310px]  rounded-md  shadow-2xl  focus:outline-none z-50 flex flex-col gap-1">
                <div className="bg-white rounded-t-md ring-1 ring-black ring-opacity-5 shadow-xl  divide-y divide-gray-100">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <NavLink to={promotionRoute.path}>
                        <div
                          className={`group text-darkNight flex items-center justify-between w-full px-2 py-2 text-sm`}
                        >
                          Promotion
                          <span className="pr-1">
                            <PromotionIcon />
                          </span>
                        </div>
                      </NavLink>
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <NavLink
                        to={`${profileRoute.path.replace(
                          ":username",
                          auth.user.vibehutUsername || auth.user._id,
                        )}/book`}
                      >
                        <div
                          className={`group text-darkNight flex items-center justify-between w-full px-2 py-2 text-sm`}
                        >
                          Booking
                          <span className="pr-1">
                            <BookingIcon />
                          </span>
                        </div>
                      </NavLink>
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <NavLink
                        to={profileRoute.path.replace(
                          ":username",
                          auth.user.vibehutUsername || auth.user._id,
                        )}
                      >
                        <div
                          className={`group text-darkNight flex items-center justify-between w-full px-2 py-2 text-sm`}
                        >
                          View Profile
                          <span className="pr-1">
                            <UserIcon />
                          </span>
                        </div>
                      </NavLink>
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <button
                        onClick={() => openContactModal(dispatch)}
                        className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                      >
                        Account Settings
                        <span className="pr-1">
                          <Settings />
                        </span>
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href="javascript:q%3Dlocation.href%3Bif(document.getSelection)%7Bd%3Ddocument.getSelection()%3B%7Delse%7Bd%3D%27%27 %3B%7D%3Bp%3Ddocument.title%3Bvoid(open(%27https://vibehut.io/resources%3Fbookmarklet=true&url%3D%27%2BencodeURIComponent(q)%2B%27%26description%3D%27%2BencodeURIComponent(d)%2B%27%26label%3D%27%2BencodeURIComponent(p),%27EventSo%27,%27toolbar%3Dno,scrollbars%3Dyes,width%3D650,height%3D800%27))%3B"
                        target="_blank"
                        title="Click and drag into bookmark bar"
                        className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                      >
                        Bookmark
                        <CopyIcon />
                      </a>
                    </Menu.Item>
                  </div>
                </div>
                <div className="bg-white ring-1 ring-black ring-opacity-5 shadow-xl   divide-y divide-gray-100">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <button
                        onClick={goToInstantCallPage}
                        className={`group text-darkNight flex rounded-md items-center justify-between w-full pl-2 py-2 text-sm `}
                      >
                        <span className="">New Video Call</span>

                        {isInstanceCall === "call" ? (
                          <Loader ratio="h-4 w-4" />
                        ) : (
                          <span className="pr-3">
                            <CamOn color="#1F2023" />
                          </span>
                        )}
                      </button>
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <button
                        onClick={copyInstantCallLink}
                        className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                      >
                        <span className="">Create Video Link</span>
                        <CopyIcon />
                      </button>
                    </Menu.Item>
                  </div>
                </div>
                <div className="bg-white  ring-1 ring-black ring-opacity-5 shadow-xl rounded-b-md  divide-y divide-gray-100">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <button
                        onClick={async () => {
                          if (checkIsDesoUser(auth?.user)) {
                            const jwt = await desoProtocol.identity.jwt();
                            if (jwt) {
                              await desoProtocol.identity.logout();
                            }
                          }
                          disconnectAsync();
                          await logout(dispatch);
                          history.replace("/login");
                        }}
                        className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                      >
                        <span className="">Log out</span>
                        <Logout />
                      </button>
                    </Menu.Item>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
      {auth?.user && roomUUID ? (
        <CreatedLinkModal
          isOpen={!!roomUUID}
          setIsOpen={(flag) => !flag && setRoomUUID(undefined)}
          roomCode={roomUUID}
        />
      ) : undefined}
    </div>
  );
}

export default UserMenu;
