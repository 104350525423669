import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Avatar from "../Avatar";
import Loader from "../Loader";
import DeSo from "../Icons/DeSo";
import Metamask from "../Icons/Metamask";
import Instagram from "../Icons/Instagram";
import TikTok from "../Icons/Tiktok";
import Twitter from "../Icons/Twitter";
import LinkedIn from "../Icons/LinkedIn";
import Room from "../Icons/Room";
import Arrow from "../Icons/Arrow";
import ProfileStatsSkeleton from "../Skeletons/ProfileStatsSkeleton";
import Button from "../Button";
import Linkify from "react-linkify";
import Copy2 from "../Icons/Copy2";
import PopOver from "../Popover";
import Close from "../Icons/Close";
import {
  getCoinPrice,
  getUserCallCount,
  getUserFollowerCount,
  getUserFollowingCount,
  getUserRoomStatus,
} from "../../services/userService";
import Axios from "axios";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import { frontEndURL } from "../../constants";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import Facebook from "../Icons/Facebook";
import Worldcoin from "../Icons/Worldcoin";
import TwitterV2 from "../Icons/TwitterV2";
import Google from "../Icons/Google";
import ProfilePersonas from "../../../components/peronas/ProfilePersonas";
import Warpcast from "../../../VibehutUI/Icons/Warpcast";

interface ProfileModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  user: any;
  userState: any;
  isLoggedInUser?: boolean;
}

export default function ProfileModal({
  isOpen,
  setIsOpen,
  user,
  userState,
  isLoggedInUser = true,
}: ProfileModalProps) {
  const cancelButtonRef = useRef(null);
  const provider = user?.provider;
  const [coinPrice, setCoinPrice] = useState(user?.coin_price);
  const [handleReadMore, setHandleReadMore] = useState(false);
  const [callsCount, setcallsCount] = useState(0);
  const [isCallCounting, setIsCallCounting] = useState(false);
  const [connectionCount, setConnectionCount] = useState(0);
  const [isConnectCounting, setIsConnectCounting] = useState(false);
  const [userRoomStatus, setUserRoomStatus] = useState<any>(null);
  const [isGettingRoomStatus, setIsGettingRoomStatus] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [followingCount, setFollowingCount] = useState(0);
  const { authUser } = useAuthUser();
  const { roomData } = useVibehutData(globals.roomId);

  const source = Axios.CancelToken.source();

  const CustomLinkify: any = Linkify;

  useEffect(() => {
    if (isOpen && provider === "bitclout") {
      getCoinPrice(user.username, user.bitclout_publickey, source)
        .then((res) => {
          setCoinPrice(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsCallCounting(true);
      setIsConnectCounting(true);

      getUserCallCount(user?._id, "", source)
        .then((res) => {
          setIsCallCounting(false);
          setcallsCount(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getUserFollowerCount(user?._id, authUser.token, source)
        .then((res) => {
          setIsConnectCounting(false);
          setConnectionCount(res.data.connections);
        })
        .catch((err) => {
          console.log(err);
        });
      getUserFollowingCount(user?._id, authUser.token, source)
        .then((res) => {
          setIsConnectCounting(false);
          setFollowingCount(res.data.connections);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (!userState) {
        setIsGettingRoomStatus(true);
        getUserRoomStatus(user?._id).then((res) => {
          if (res.status === 200) {
            setUserRoomStatus(res.data);
            setIsGettingRoomStatus(false);
          } else {
            console.log(res);
          }
        });
      } else {
        setUserRoomStatus(userState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const hangleReadMoreBtn = () => {
    if (user.description.length > 300) {
      setHandleReadMore(true);
    }
  };
  const hangleShowLessBtn = () => {
    if (user.description.length > 300) {
      setHandleReadMore(false);
    }
  };

  const copyMetamaskAddress = async (address: string) => {
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 2000);
  };

  const hangleReadMoreDescriptionBtn = () => {
    if (user.vibehutDescription.length > 300) {
      setHandleReadMore(true);
    }
  };

  const hangleShowLessDescriptionBtn = () => {
    if (user.vibehutDescription.length > 300) {
      setHandleReadMore(false);
    }
  };

  const _renderUserProfile = () => {
    const componentDecorator = (
      decoratedHref: string,
      decoratedText: string,
      key: number,
    ) => (
      <a
        href={decoratedHref}
        key={key}
        target="_blank"
        className="text-primary text-weight-bold break-words"
        rel="noreferrer"
      >
        {decoratedText}
      </a>
    );
    switch (provider) {
      case "bitclout":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <DeSo />
              </span>
            </div>
            <p className="text-lg font-medium pt-3">
              <a
                href={`https://diamondapp.com/u/${user.username}`}
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col ">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace("with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            <p className="text-frescoWhite  text-center px-8 leading-5 text-sm pt-2">
              <CustomLinkify componentDecorator={componentDecorator}>
                <div className="w-full break-all">
                  {user.vibehutDescription ? (
                    user.vibehutDescription.length < 300 ? (
                      <span>{user.vibehutDescription}</span>
                    ) : user.vibehutDescription.length > 300 &&
                      !handleReadMore ? (
                      <span>
                        {user.vibehutDescription.slice(0, 300)}...
                        <button
                          className=" text-primary text-center px-1 leading-5 text-sm"
                          onClick={hangleReadMoreDescriptionBtn}
                        >
                          Read More
                        </button>
                      </span>
                    ) : undefined
                  ) : user.description.length < 300 ? (
                    user.description
                  ) : user.description.length > 300 && !handleReadMore ? (
                    <span>
                      {user.description.slice(0, 300)}...
                      <button
                        className=" text-primary text-center px-1 leading-5 text-sm"
                        onClick={hangleReadMoreBtn}
                      >
                        Read More
                      </button>
                    </span>
                  ) : undefined}

                  {handleReadMore ? (
                    <span className="text-frescoWhite  text-center px-2 leading-5 text-sm">
                      {user.vibehutDescription
                        ? user.vibehutDescription
                        : user.description}
                      <br />
                      <button
                        className=" text-primary text-center px-1 leading-5 text-sm"
                        type="button"
                        onClick={
                          user.vibehutDescription
                            ? hangleShowLessDescriptionBtn
                            : hangleShowLessBtn
                        }
                      >
                        Show less
                      </button>
                    </span>
                  ) : undefined}
                </div>
              </CustomLinkify>
            </p>
            {coinPrice === undefined ||
            coinPrice === null ||
            isCallCounting ||
            isConnectCounting ? (
              <ProfileStatsSkeleton showItem={5} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`$${coinPrice}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Coin Price
                  </p>
                </div>

                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">
                    {provider === "twitter"
                      ? user.follower_count
                      : user.investorCount}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    {provider === "twitter" ? "Followers" : "Investors"}
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}

            <div className="pb-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>

              {/* {isLoggedInUser || !auth.user ? undefined : (
                <WaveButton user={user} setIsOpenLogin={setIsOpenLogin} />
              )} */}
            </div>
          </div>
        );
      case "twitter":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <TwitterV2 />
              </span>
            </div>
            <p className="  text-lg font-medium pt-3">
              <a
                href={`https://twitter.com/${user.username}`}
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}

            <p className="text-frescoWhite  text-center px-2 leading-5 text-sm pt-2">
              <CustomLinkify componentDecorator={componentDecorator}>
                {user.description.length < 300 ? user.description : undefined}
                {user.description.length > 300 && !handleReadMore ? (
                  <span>
                    {user.description.slice(0, 300)}
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      type="button"
                      onClick={hangleReadMoreBtn}
                    >
                      Read More
                    </button>
                  </span>
                ) : undefined}
                {handleReadMore ? (
                  <span className="text-frescoWhite  text-center px-2 leading-5 text-sm">
                    {user.description}
                    <br />
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      type="button"
                      onClick={hangleShowLessBtn}
                    >
                      Show less
                    </button>
                  </span>
                ) : undefined}
              </CustomLinkify>
            </p>
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={4} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">
                    {provider === "twitter"
                      ? user.follower_count
                      : user.investorCount}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    {provider === "twitter" ? "Followers" : "Investors"}
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
              {/* {isLoggedInUser || !auth.user ? undefined : (
                <WaveButton user={user} setIsOpenLogin={setIsOpenLogin} />
              )} */}
            </div>
          </div>
        );
      case "linkedin":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <LinkedIn />
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
              {/* {isLoggedInUser || !auth.user ? undefined : (
                <WaveButton user={user} setIsOpenLogin={setIsOpenLogin} />
              )} */}
            </div>
          </div>
        );
      case "metamask":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                {user?.farcaster?.username ? <Warpcast /> : <Metamask />}
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}
              {user?.provider === "metamask" ? (
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyMetamaskAddress(user?.wallet_address)}
                >
                  {isAddressCopied ? <PopOver text="Copied" /> : undefined}
                  <Copy2 />
                </span>
              ) : undefined}
              {user?.provider === "worldcoin" ? (
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyMetamaskAddress(user.sub)}
                >
                  {isAddressCopied ? <PopOver text="Copied" /> : undefined}
                  <Copy2 />
                </span>
              ) : undefined}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
              {/* {isLoggedInUser || !auth.user ? undefined : (
                <WaveButton user={user} setIsOpenLogin={setIsOpenLogin} />
              )} */}
            </div>
          </div>
        );
      case "instagram":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Instagram />
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3">
              <a
                href={`https://instagram.com/${user.username}`}
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            <p className="text-frescoWhite  text-center px-2 pt-2 leading-5 text-sm">
              <CustomLinkify componentDecorator={componentDecorator}>
                {user.description.length < 300 ? user.description : undefined}
                {user.description.length > 300 && !handleReadMore ? (
                  <span>
                    {user.description.slice(0, 300)}
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      type="button"
                      onClick={hangleReadMoreBtn}
                    >
                      Read More
                    </button>
                  </span>
                ) : undefined}
                {handleReadMore ? (
                  <span className="text-frescoWhite  text-center px-2 leading-5 text-sm">
                    {user.description}
                    <br />
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      type="button"
                      onClick={hangleShowLessBtn}
                    >
                      Show less
                    </button>
                  </span>
                ) : undefined}
              </CustomLinkify>
            </p>
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={4} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">
                    {user.follower_count}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
              {/* {isLoggedInUser || !auth.user ? undefined : (
                <WaveButton user={user} setIsOpenLogin={setIsOpenLogin} />
              )} */}
            </div>
          </div>
        );

      case "facebook":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Facebook />
              </span>
            </div>
            <p className="text-frescoWhite text-lg font-medium pt-3">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
            </div>
          </div>
        );

      case "tiktok":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <TikTok />
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3">
              <a
                href={`https://www.tiktok.com/@${user.username}`}
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                {/* <div className="flex items-center flex-col ">
                <p className="text-lg text-frescoWhite  font-ubuntu font-bold">
                  {user.follower_count}
                </p>
                <p className="text-base text-darkGray font-regular font-ubuntu">
                  Followers
                </p>
              </div> */}
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
              {/* {isLoggedInUser || !auth.user ? undefined : (
                <WaveButton user={user} setIsOpenLogin={setIsOpenLogin} />
              )} */}
            </div>
            {/* <p className="text-frescoWhite  text-center px-2 leading-5 text-sm">
              {user?.description}
            </p> */}
            {/* <div className={`flex text-frescoWhite pt-8`}>
              <div className={`text-center`}>
                <p className="text-xs text-primaryGray">Followers</p>
                <p className="text-base text-frescoWhite ">
                  {user.follower_count}
                </p>
              </div>
            </div> */}
          </div>
        );
      case "worldcoin":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Worldcoin />
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
            </div>
          </div>
        );

      case "google":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Google />
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
            </div>
          </div>
        );

      default:
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <img
                  src={user.icon}
                  className="w-[20px] h-[20px]"
                  alt={provider}
                />
              </span>
            </div>
            <p className="text-frescoWhite  text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}

              {!!user?.walletId ? (
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyMetamaskAddress(user.wallet_address)}
                >
                  {isAddressCopied ? <PopOver text="Copied" /> : undefined}
                  <Copy2 />
                </span>
              ) : undefined}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4  ${
                    !roomData.isWaveCall && "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (roomData.isWaveCall) {
                      return;
                    }
                    window.open(
                      `${frontEndURL}/rooms/${userRoomStatus?.roomId}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${callsCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${connectionCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg text-frescoWhite  font-ubuntu font-bold">{`${followingCount}`}</p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="pr-4">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    window.open(
                      `${frontEndURL}/@${user?.vibehutUsername || user._id}`,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                >
                  View Profile
                </Button>
              </span>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 md:z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`inline-block align-bottom bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-450px font-ubuntu 
                   overflow-y-auto custom-scrollbar`}
              >
                <div className=" bg-navyGray p-3">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black "></p>
                    <span
                      className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                      tabIndex={0}
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="bg-navyGray p-3 max-h-[80vh] overflow-auto custom-scrollbar">
                  {_renderUserProfile()}
                  <ProfilePersonas
                    userId={user?._id}
                    isProfileModalV2
                    setIsOpenProfileModal={setIsOpen}
                  />
                </div>
                {/* <div className="px-8 pb-8">
                  <p className="font-bold font-ubuntu text-frescoWhite">
                    {userLiveEvents &&
                      userLiveEvents.length === 0 &&
                      userUpcomingEvents &&
                      userUpcomingEvents.length === 0 ? (
                      <p>No Rsvp’d events</p>
                    ) : (
                      "RSVP’d Events"
                    )}
                  </p>

                  {!isUserEventsFetching ? (
                    <div class="flex flex-col ">
                      <div className="w-full">
                        <LiveEvents
                          events={userLiveEvents.slice(0, 2)}
                          isShowTitle={false}
                        />
                      </div>
                      <div className="w-full">
                        <UpComingEvents
                          events={userUpcomingEvents.slice(0, 2)}
                          isShowTitle={false}
                        />
                      </div>
                      {userLiveEvents?.length > 2 ||
                        userUpcomingEvents?.length > 2 ? (
                        <div className="pt-2 flex justify-center">
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-secondary"
                            onClick={() => setIsAllEventModelOpen(true)}
                          >
                            Show all
                          </Button>
                        </div>
                      ) : undefined}

                      <AllUserEventModal
                        isOpen={isAllEventModelOpen}
                        setIsOpen={setIsAllEventModelOpen}
                        userLiveEvents={userLiveEvents}
                        userUpcomingEvents={userUpcomingEvents}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div> */}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
