import React from "react";

const StarIcon = ({ fill = "#FFCC00", width = "29", height = "27" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8999 10.1612C28.7788 9.78798 28.5506 9.45858 28.2436 9.21409C27.9367 8.96959 27.5646 8.8208 27.1737 8.78625L19.7499 8.14624L16.8399 1.22624C16.6884 0.86314 16.4329 0.552979 16.1054 0.334817C15.778 0.116654 15.3934 0.000244141 14.9999 0.000244141C14.6065 0.000244141 14.2218 0.116654 13.8944 0.334817C13.567 0.552979 13.3114 0.86314 13.1599 1.22624L10.2587 8.14624L2.82618 8.79C2.43372 8.82297 2.05974 8.97109 1.75113 9.21576C1.44251 9.46044 1.21301 9.79079 1.0914 10.1654C0.96978 10.54 0.961467 10.9421 1.0675 11.3214C1.17353 11.7007 1.38919 12.0403 1.68743 12.2975L7.32618 17.225L5.63618 24.5425C5.54682 24.9254 5.57232 25.3262 5.70949 25.6947C5.84666 26.0632 6.08943 26.3831 6.40742 26.6144C6.72542 26.8457 7.10455 26.9782 7.4974 26.9951C7.89025 27.0121 8.27939 26.913 8.61618 26.71L14.9912 22.835L21.3799 26.71C21.7167 26.913 22.1059 27.0121 22.4987 26.9951C22.8916 26.9782 23.2707 26.8457 23.5887 26.6144C23.9067 26.3831 24.1494 26.0632 24.2866 25.6947C24.4238 25.3262 24.4493 24.9254 24.3599 24.5425L22.6712 17.2175L28.3087 12.2975C28.6069 12.0394 28.8221 11.6989 28.9273 11.3188C29.0325 10.9387 29.023 10.5359 28.8999 10.1612ZM26.9974 10.7862L21.3599 15.7062C21.0856 15.9449 20.8814 16.2539 20.7696 16.5999C20.6577 16.9459 20.6422 17.3159 20.7249 17.67L22.4187 25L16.0349 21.125C15.7235 20.9353 15.3658 20.835 15.0012 20.835C14.6365 20.835 14.2789 20.9353 13.9674 21.125L7.59243 25L9.27493 17.675C9.35762 17.3209 9.34219 16.9509 9.2303 16.6049C9.11841 16.2589 8.9143 15.9499 8.63993 15.7112L2.99993 10.7937C2.99947 10.79 2.99947 10.7862 2.99993 10.7825L10.4299 10.14C10.7927 10.108 11.1398 9.97761 11.4339 9.76283C11.728 9.54806 11.9578 9.25706 12.0987 8.92125L14.9999 2.00999L17.8999 8.92125C18.0408 9.25706 18.2706 9.54806 18.5647 9.76283C18.8588 9.97761 19.2059 10.108 19.5687 10.14L26.9999 10.7825C26.9999 10.7825 26.9999 10.79 26.9999 10.7912L26.9974 10.7862Z"
        fill={fill}
      />
    </svg>
  );
};

export default StarIcon;
