import axios from "../utils/axios";

export async function getPromotedPartners({
  offset = "",
  page,
}: {
  offset: string;
  page: string;
}) {
  const res = await axios.get(`/partners?offset=${offset}&page=${page}`);
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res.data.message);
  }
}
