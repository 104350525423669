import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { ThemeContext } from "../../Context/ThemeContext";

export default function ChatReaction({
  isOpen,
  setIsOpen,
  chat = undefined,
  addReaction,
}) {
  const { state } = useContext(StoreContext);
  const { theme } = React.useContext(ThemeContext);
  const {
    auth: { user },
  } = state;
  const handleReaction = (emoji) => {
    const reaction = {
      emoji: emoji.native,
      unified: emoji.unified,
      owner: user?._id,
    };
    addReaction({ ...reaction, chatId: chat?._id });
    setIsOpen(false);
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-30">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all mb-14 sm:my-8 sm:align-middle  font-ubuntu">
              <div className="">
                <Picker
                  data={data}
                  onEmojiSelect={handleReaction}
                  theme={theme}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
