import React from "react";

function Close({ color = "#7A7A7A", ratio = "12" }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.792895 0.791981C0.402733 1.18129 0.402733 1.81248 0.792895 2.20179L4.59931 5.99987L0.792621 9.79822C0.402459 10.1875 0.40246 10.8187 0.792621 11.208C1.18278 11.5973 1.81536 11.5973 2.20552 11.208L6.01221 7.40968L9.79457 11.1838C10.1847 11.5731 10.8173 11.5731 11.2075 11.1838C11.5976 10.7944 11.5976 10.1633 11.2075 9.77395L7.42511 5.99987L11.2072 2.22606C11.5974 1.83676 11.5974 1.20556 11.2072 0.816257C10.817 0.426949 10.1845 0.42695 9.7943 0.816257L6.01221 4.59006L2.2058 0.791981C1.81563 0.402673 1.18306 0.402673 0.792895 0.791981Z"
        fill={color}
      />
    </svg>
  );
}

export default Close;
