import React from "react";

function User() {
  return (
    <svg
      width="20"
      height="20"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92578 5.87433C5.92578 4.86042 6.77297 4 7.86602 4C8.95908 4 9.80626 4.86042 9.80626 5.87433C9.80626 6.88825 8.95908 7.74867 7.86602 7.74867C6.77297 7.74867 5.92578 6.88825 5.92578 5.87433ZM7.86602 2C5.71138 2 3.92578 3.71335 3.92578 5.87433C3.92578 8.03532 5.71138 9.74867 7.86602 9.74867C10.0207 9.74867 11.8063 8.03532 11.8063 5.87433C11.8063 3.71335 10.0207 2 7.86602 2ZM5.25 10.7363C2.48858 10.7363 0.25 12.9749 0.25 15.7363V17.9978C0.25 19.1024 1.14543 19.9978 2.25 19.9978H13.249C14.3535 19.9978 15.249 19.1024 15.249 17.9978V15.7363C15.249 12.9749 13.0104 10.7363 10.249 10.7363H5.25ZM2.25 15.7363C2.25 14.0795 3.59315 12.7363 5.25 12.7363H10.249C11.9058 12.7363 13.249 14.0795 13.249 15.7363V17.9978L2.25 17.9978V15.7363Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4987 0.750204C17.4988 0.335927 17.1631 4.87124e-05 16.7489 5.29805e-09C16.3348 -4.87021e-05 15.999 0.33575 15.999 0.750028L15.9988 2.24933L14.5 2.24916C14.0858 2.24911 13.75 2.58491 13.75 2.99918C13.75 3.41346 14.0856 3.74934 14.4998 3.74939L15.9986 3.74956L15.9984 5.24884C15.9984 5.66312 16.3341 5.99899 16.7482 5.99904C17.1624 5.99909 17.4982 5.66329 17.4982 5.24901L17.4984 3.74974L18.9972 3.74992C19.4114 3.74997 19.7471 3.41417 19.7472 2.99989C19.7472 2.58561 19.4115 2.24973 18.9974 2.24968L17.4986 2.24951L17.4987 0.750204Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default User;
