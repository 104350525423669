export const LOGIN_WITH_BITCLOUT_REQUEST = "LOGIN_WITH_BITCLOUT_REQUEST";
export const LOGIN_WITH_BITCLOUT_SUCCESS = "LOGIN_WITH_BITCLOUT_SUCCESS";
export const LOGIN_WITH_BITCLOUT_FAILURE = "LOGIN_WITH_BITCLOUT_FAILURE";

export const LOGIN_WITH_TWITTER_REQUEST = "LOGIN_WITH_TWITTER_REQUEST";
export const LOGIN_WITH_TWITTER_SUCCESS = "LOGIN_WITH_TWITTER_SUCCESS";
export const LOGIN_WITH_TWITTER_FAILURE = "LOGIN_WITH_TWITTER_FAILURE";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const TOGGLE_ADD_COMMENT_MODAL = "TOGGLE_ADD_COMMENT_MODAL";
export const UPDATE_COMMENT_COUNT = "UPDATE_COMMENT_COUNT";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const GET_RAW_RECORDINGS_REQUEST = "GET_RAW_RECORDINGS_REQUEST";
export const GET_RAW_RECORDINGS_SUCCESS = "GET_RAW_RECORDINGS_SUCCESS";
export const GET_RAW_RECORDINGS_FAILURE = "GET_RAW_RECORDINGS_FAILURE";

export const GET_FEED_POSTS_REQUEST = "GET_FEED_POSTS_REQUEST";
export const GET_FEED_POSTS_SUCCESS = "GET_FEED_POSTS_SUCCESS";
export const GET_FEED_POSTS_FAILURE = "GET_FEED_POSTS_FAILURE";

export const GET_NEWS_POSTS_REQUEST = "GET_NEWS_POSTS_REQUEST";
export const GET_NEWS_POSTS_SUCCESS = "GET_NEWS_POSTS_SUCCESS";
export const GET_NEWS_POSTS_FAILURE = "GET_NEWS_POSTS_FAILURE";

export const DELETE_NEWS_POST_REQUEST = "DELETE_NEWS_POST_REQUEST";
export const DELETE_NEWS_POST_SUCCESS = "DELETE_NEWS_POST_SUCCESS";
export const DELETE_NEWS_POST_FAILURE = "DELETE_NEWS_POST_FAILURE";

export const GET_LATEST_POST_SUCCESS = "GET_LATEST_POST_SUCCESS";

export const GET_SINGLE_POST_REQUEST = "GET_SINGLE_POST_REQUEST";
export const GET_SINGLE_POST_SUCCESS = "GET_SINGLE_POST_SUCCESS";
export const GET_SINGLE_POST_FAILURE = "GET_SINGLE_POST_FAILURE";

export const UPDATE_SINGLE_FEED_LIKE = "UPDATE_SINGLE_FEED_LIKE";
export const UPDATE_SINGLE_FEED_DIAMOND_COUNT =
  "UPDATE_SINGLE_FEED_DIAMOND_COUNT";
export const UPDATE_NEWS_DIAMOND_COUNT = "UPDATE_NEWS_DIAMOND_COUNT";
export const RESET_SINGLE_POST = "RESET_SINGLE_POST";

export const OPEN_EVENT_MODAL = "OPEN_EVENT_MODAL";
export const CLOSE_EVENT_MODAL = "CLOSE_EVENT_MODAL";

export const LOGIN_WITH_METAMASK_REQUEST = "LOGIN_WITH_METAMASK_REQUEST";
export const LOGIN_WITH_METAMASK_SUCCESS = "LOGIN_WITH_METAMASK_SUCCESS";
export const LOGIN_WITH_METAMASK_FAILURE = "LOGIN_WITH_METAMASK_FAILURE";

export const LOGIN_AS_GUEST_REQUEST = "LOGIN_AS_GUEST_REQUEST";
export const LOGIN_AS_GUEST_SUCCESS = "LOGIN_AS_GUEST_SUCCESS";
export const LOGIN_AS_GUEST_FAILURE = "LOGIN_AS_GUEST_FAILURE";

export const LOGOUT = "LOGOUT";

export const GET_RECENT_CALLS_REQUEST = "GET_RECENT_CALLS_REQUEST";
export const GET_RECENT_CALLS_SUCCESS = "GET_RECENT_CALLS_SUCCESS";
export const GET_RECENT_CALLS_FAILURE = "GET_RECENT_CALLS_FAILURE";

export const GET_CALL_STREAK_REQUEST = "GET_CALL_STREAK_REQUEST";
export const GET_CALL_STREAK_SUCCESS = "GET_CALL_STREAK_SUCCESS";
export const GET_CALL_STREAK_FAILURE = "GET_CALL_STREAK_FAILURE";

export const GET_SIDEBAR_RECENT_CALLS_REQUEST =
  "GET_SIDEBAR_RECENT_CALLS_REQUEST";
export const GET_SIDEBAR_RECENT_CALLS_SUCCESS =
  "GET_SIDEBAR_RECENT_CALLS_SUCCESS";
export const GET_SIDEBAR_RECENT_CALLS_FAILURE =
  "GET_SIDEBAR_RECENT_CALLS_FAILURE";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const GET_PREMIUM_EVENT_CREATOR_ACCESS_REQUEST =
  "GET_PREMIUM_EVENT_CREATOR_ACCESS_REQUEST";
export const GET_PREMIUM_EVENT_CREATOR_ACCESS_SUCCESS =
  "GET_PREMIUM_EVENT_CREATOR_ACCESS_SUCCESS";
export const GET_PREMIUM_EVENT_CREATOR_ACCESS_FAILURE =
  "GET_PREMIUM_EVENT_CREATOR_ACCESS_FAILURE";

export const GET_PREMIUM_CREATOR_ACCESS_REQUEST =
  "GET_PREMIUM_CREATOR_ACCESS_REQUEST";
export const GET_PREMIUM_CREATOR_ACCESS_SUCCESS =
  "GET_PREMIUM_CREATOR_ACCESS_SUCCESS";
export const GET_PREMIUM_CREATOR_ACCESS_FAILURE =
  "GET_PREMIUM_CREATOR_ACCESS_FAILURE";

export const GET_ALL_ROLES_REQUEST = "GET_ALL_ROLES_REQUEST";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const GET_PUBLIC_ROOMS_REQUEST = "GET_PUBLIC_ROOMS_REQUEST";
export const GET_PUBLIC_ROOMS_SUCCESS = "GET_PUBLIC_ROOMS_SUCCESS";
export const GET_PUBLIC_ROOMS_FAILURE = "GET_PUBLIC_ROOMS_FAILURE";

export const GET_SCREEN_ROOM_REQUEST = "GET_SCREEN_ROOM_REQUEST";
export const GET_SCREEN_ROOM_SUCCESS = "GET_SCREEN_ROOM_SUCCESS";
export const GET_SCREEN_ROOM_FAILURE = "GET_SCREEN_ROOM_FAILURE";
export const CLEAR_SCREEN_ROOM_SUCCESS = "CLEAR_SCREEN_ROOM_SUCCESS";

export const GET_MY_ROOM_COUNT_REQUEST = "GET_MY_ROOM_COUNT_REQUEST";
export const GET_MY_ROOM_COUNT_SUCCESS = "GET_MY_ROOM_COUNT_SUCCESS";
export const GET_MY_ROOM_COUNT_FAILURE = "GET_MY_ROOM_COUNT_FAILURE";

export const GET_MY_CALL_COUNT_REQUEST = "GET_MY_CALL_COUNT_REQUEST";
export const GET_MY_CALL_COUNT_SUCCESS = "GET_MY_CALL_COUNT_SUCCESS";
export const GET_MY_CALL_COUNT_FAILURE = "GET_MY_CALL_COUNT_FAILURE";

export const GET_MY_ROOMS_REQUEST = "GET_MY_ROOMS_REQUEST";
export const GET_MY_ROOMS_SUCCESS = "GET_MY_ROOMS_SUCCESS";
export const GET_MY_ROOMS_FAILURE = "GET_MY_ROOMS_FAILURE";

export const GET_SUBSCRIBED_ROOMS_REQUEST = "GET_SUBSCRIBED_ROOMS_REQUEST";
export const GET_SUBSCRIBED_ROOMS_SUCCESS = "GET_SUBSCRIBED_ROOMS_SUCCESS";
export const GET_SUBSCRIBED_ROOMS_FAILURE = "GET_SUBSCRIBED_ROOMS_FAILURE";

export const GET_ROOM_SUBSCRIBER_COUNT_SUCCESS =
  "GET_ROOM_SUBSCRIBER_COUNT_SUCCESS";

export const GET_NFT_ROOMS_REQUEST = "GET_NFT_ROOMS_REQUEST";
export const GET_NFT_ROOMS_SUCCESS = "GET_NFT_ROOMS_SUCCESS";
export const GET_NFT_ROOMS_FAILURE = "GET_NFT_ROOMS_FAILURE";

export const CREATE_ROOM_REQUEST = "CREATE_ROOM_REQUEST";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE";

export const UPDATE_ROOM_REQUEST = "UPDATE_ROOM_REQUEST";
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";
export const UPDATE_ROOM_FAILURE = "UPDATE_ROOM_FAILURE";

export const DELETE_ROOM_REQUEST = "DELETE_ROOM_REQUEST";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
export const DELETE_ROOM_FAILURE = "DELETE_ROOM_FAILURE";

export const GET_GEN_ROOM_REQUEST = "GET_GEN_ROOM_REQUEST";
export const GET_GEN_ROOM_SUCCESS = "GET_GEN_ROOM_SUCCESS";
export const GET_GEN_ROOM_FAILURE = "GET_GEN_ROOM_FAILURE";

export const GET_POPULAR_ROOMS_REQUEST = "GET_POPULAR_ROOMS_REQUEST";
export const GET_POPULAR_ROOMS_SUCCESS = "GET_POPULAR_ROOMS_SUCCESS";
export const GET_POPULAR_ROOMS_FAILURE = "GET_POPULAR_ROOMS_FAILURE";

export const GET_HOME_POPULAR_ROOMS_REQUEST = "GET_HOME_POPULAR_ROOMS_REQUEST";
export const GET_HOME_POPULAR_ROOMS_SUCCESS = "GET_HOME_POPULAR_ROOMS_SUCCESS";
export const GET_HOME_POPULAR_ROOMS_FAILURE = "GET_HOME_POPULAR_ROOMS_FAILURE";

export const GET_TOP_ROOMS_REQUEST = "GET_TOP_ROOMS_REQUEST";
export const MORE_TOP_ROOMS_REQUEST = "MORE_TOP_ROOMS_REQUEST";
export const GET_TOP_ROOMS_SUCCESS = "GET_TOP_ROOMS_SUCCESS";
export const GET_TOP_ROOMS_FAILURE = "GET_TOP_ROOMS_FAILURE";

export const GET_TRENDING_ROOMS_REQUEST = "GET_TRENDING_ROOMS_REQUEST";
export const GET_TRENDING_ROOMS_SUCCESS = "GET_TRENDING_ROOMS_SUCCESS";
export const GET_TRENDING_ROOMS_FAILURE = "GET_TRENDING_ROOMS_FAILURE";

export const GET_NEWLY_ADDED_ROOMS_REQUEST = "GET_NEWLY_ADDED_ROOMS_REQUEST";
export const GET_NEWLY_ADDED_ROOMS_SUCCESS = "GET_NEWLY_ADDED_ROOMS_SUCCESS";
export const GET_NEWLY_ADDED_ROOMS_FAILURE = "GET_NEWLY_ADDED_ROOMS_FAILURE";

export const GET_RECENT_ROOMS_REQUEST = "GET_RECENT_ROOMS_REQUEST";
export const GET_RECENT_ROOMS_SUCCESS = "GET_RECENT_ROOMS_SUCCESS";
export const GET_RECENT_ROOMS_FAILURE = "GET_RECENT_ROOMS_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const IS_PERSONA_THINKING_OR_NOT = "IS_PERSONA_THINKING_OR_NOT";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const UPDATE_MESSAGES = "UPDATE_MESSAGES";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_UNREAD_MESSAGES_STATUS = "UPDATE_UNREAD_MESSAGES_STATUS";
export const UPDATE_CONVERSATION_LAST_MESSAGE =
  "UPDATE_CONVERSATION_LAST_MESSAGE";

export const GET_CONNECTION_REQUEST = "GET_CONNECTION_REQUEST";
export const GET_CONNECTION_SUCCESS = "GET_CONNECTION_SUCCESS";
export const GET_CONNECTION_FAILURE = "GET_CONNECTION_FAILURE";

export const GET_USER_CONNECTION_REQUEST = "GET_USER_CONNECTION_REQUEST";
export const GET_USER_CONNECTION_SUCCESS = "GET_USER_CONNECTION_SUCCESS";
export const GET_USER_CONNECTION_FAILURE = "GET_USER_CONNECTION_FAILURE";

export const GET_MUTUAL_CONNECTION_REQUEST = "GET_MUTUAL_CONNECTION_REQUEST";
export const GET_MUTUAL_CONNECTION_SUCCESS = "GET_MUTUAL_CONNECTION_SUCCESS";
export const GET_MUTUAL_CONNECTION_FAILURE = "GET_MUTUAL_CONNECTION_FAILURE";

export const UPDATE_CONNECTION_REQUEST = "UPDATE_CONNECTION_REQUEST";
export const UPDATE_CONNECTION_SUCCESS = "UPDATE_CONNECTION_SUCCESS";
export const UPDATE_CONNECTION_FAILURE = "UPDATE_CONNECTION_FAILURE";

export const REMOVE_CONNECTION_REQUEST = "REMOVE_CONNECTION_REQUEST";
export const REMOVE_CONNECTION_SUCCESS = "REMOVE_CONNECTION_SUCCESS";
export const REMOVE_CONNECTION_FAILURE = "REMOVE_CONNECTION_FAILURE";

export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const GET_ROOM_EVENTS_REQUEST = "GET_ROOM_EVENTS_REQUEST";
export const GET_ROOM_EVENTS_SUCCESS = "GET_ROOM_EVENTS_SUCCESS";
export const GET_ROOM_EVENTS_FAILURE = "GET_ROOM_EVENTS_FAILURE";
export const RESET_ROOM_EVENTS_SUCCESS = "RESET_ROOM_EVENTS_SUCCESS";

export const GET_NEWLY_ADDED_EVENTS_REQUEST = "GET_NEWLY_ADDED_EVENTS_REQUEST";
export const GET_NEWLY_ADDED_EVENTS_SUCCESS = "GET_NEWLY_ADDED_EVENTS_SUCCESS";
export const GET_NEWLY_ADDED_EVENTS_FAILURE = "GET_NEWLY_ADDED_EVENTS_FAILURE";

export const GET_CALENDER_EVENTS_REQUEST = "GET_CALENDER_EVENTS_REQUEST";
export const GET_CALENDER_EVENTS_SUCCESS = "GET_CALENDER_EVENTS_SUCCESS";
export const GET_CALENDER_EVENTS_FAILURE = "GET_CALENDER_EVENTS_FAILURE";

export const GET_ROOM_ALLEVENTS_REQUEST = "GET_ROOM_ALLEVENTS_REQUEST";
export const GET_ROOM_ALLEVENTS_SUCCESS = "GET_ROOM_ALLEVENTS_SUCCESS";
export const GET_ROOM_ALLEVENTS_FAILURE = "GET_ROOM_ALLEVENTS_FAILURE";

export const GET_LIVE_ROOMS_CALL_REQUEST = "GET_LIVE_ROOMS_CALL_REQUEST";
export const GET_LIVE_ROOMS_CALL_SUCCESS = "GET_LIVE_ROOMS_CALL_SUCCESS";
export const GET_LIVE_ROOMS_CALL_FAILURE = "GET_LIVE_ROOMS_CALL_FAILURE";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const UPDATE_RSVP_REQUEST = "UPDATE_RSVP_REQUEST";
export const UPDATE_RSVP_SUCCESS = "UPDATE_RSVP_SUCCESS";
export const UPDATE_RSVP_FAILURE = "UPDATE_RSVP_FAILURE";

export const UPDATE_HOME_RSVP_REQUEST = "UPDATE_HOME_RSVP_REQUEST";
export const UPDATE_HOME_RSVP_SUCCESS = "UPDATE_HOME_RSVP_SUCCESS";
export const UPDATE_HOME_RSVP_FAILURE = "UPDATE_HOME_RSVP_FAILURE";

export const GET_ALL_EVENTS_REQUEST = "GET_ALL_EVENTS_REQUEST";
export const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS";
export const GET_ALL_EVENTS_FAILURE = "GET_ALL_EVENTS_FAILURE";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const GET_POPULAR_EVENTS_REQUEST = "GET_POPULAR_EVENTS_REQUEST";
export const GET_POPULAR_EVENTS_SUCCESS = "GET_POPULAR_EVENTS_SUCCESS";
export const GET_POPULAR_EVENTS_FAILURE = "GET_POPULAR_EVENTS_FAILURE";

export const GET_LIVE_EVENTS_REQUEST = "GET_LIVE_EVENTS_REQUEST";
export const GET_LIVE_EVENTS_SUCCESS = "GET_LIVE_EVENTS_SUCCESS";
export const GET_LIVE_EVENTS_FAILURE = "GET_LIVE_EVENTS_FAILURE";

export const GET_UPCOMING_EVENTS_REQUEST = "GET_UPCOMING_EVENTS_REQUEST";
export const GET_UPCOMING_EVENTS_SUCCESS = "GET_UPCOMING_EVENTS_SUCCESS";
export const GET_UPCOMING_EVENTS_FAILURE = "GET_UPCOMING_EVENTS_FAILURE";

export const GET_HOMEPAGE_EVENT_REQUEST = "GET_HOMEPAGE_EVENT_REQUEST";
export const GET_HOMEPAGE_EVENT_SUCCESS = "GET_HOMEPAGE_EVENT_SUCCESS";
export const GET_HOMEPAGE_EVENT_FAILURE = "GET_HOMEPAGE_EVENT_FAILURE";

export const GET_COUNT_NOTIFICATIONS_REQUEST =
  "GET_COUNT_NOTIFICATIONS_REQUEST";
export const GET_COUNT_NOTIFICATIONS_SUCCESS =
  "GET_COUNT_NOTIFICATIONS_SUCCESS";
export const GET_COUNT_NOTIFICATIONS_FAILURE =
  "GET_COUNT_NOTIFICATIONS_FAILURE";

export const GET_USER_WAVES_REQUEST = "GET_USER_WAVES_REQUEST";
export const GET_USER_WAVES_SUCCESS = "GET_USER_WAVES_SUCCESS";
export const GET_USER_WAVES_FAILURE = "GET_USER_WAVES_FAILURE";

export const CREATE_WAVES_REQUEST = "CREATE_WAVES_REQUEST";
export const CREATE_WAVES_SUCCESS = "CREATE_WAVES_SUCCESS";
export const CREATE_WAVES_FAILURE = "CREATE_WAVES_FAILURE";

export const WAVE_BACK_REQUEST = "WAVE_BACK_REQUEST";
export const WAVE_BACK_SUCCESS = "WAVE_BACK_SUCCESS";
export const WAVE_BACK_FAILURE = "WAVE_BACK_FAILURE";

export const SET_SINGLE_WAVE_REQUEST = "SET_SINGLE_WAVE_REQUEST";
export const SET_SINGLE_WAVE_SUCCESS = "SET_SINGLE_WAVE_SUCCESS";
export const SET_SINGLE_WAVE_FAILURE = "SET_SINGLE_WAVE_FAILURE";

export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";

export const GET_ON_CALL_USERS_REQUEST = "GET_ON_CALL_USERS_REQUEST";
export const GET_ON_CALL_USERS_SUCCESS = "GET_ON_CALL_USERS_SUCCESS";
export const GET_ON_CALL_USERS_FAILURE = "GET_ON_CALL_USERS_FAILURE";

export const GET_LAST_ACTIVE_USERS_REQUEST = "GET_LAST_ACTIVE_USERS_REQUEST";
export const GET_LAST_ACTIVE_USERS_SUCCESS = "GET_LAST_ACTIVE_USERS_SUCCESS";
export const GET_LAST_ACTIVE_USERS_FAILURE = "GET_LAST_ACTIVE_USERS_FAILURE";

export const GET_USER_CALL_ACTIVITY_SUCCESS = "GET_USER_CALL_ACTIVITY_SUCCESS";
export const GET_USER_CALL_ACTIVITY_REQUEST = "GET_USER_CALL_ACTIVITY_REQUEST";
export const GET_USER_CALL_ACTIVITY_UPDATE = "GET_USER_CALL_ACTIVITY_UPDATE";
export const GET_HOME_POSTS_REQUEST = "GET_HOME_POSTS_REQUEST";
export const GET_HOME_POSTS_SUCCESS = "GET_HOME_POSTS_SUCCESS";
export const GET_HOME_POSTS_FAILURE = "GET_HOME_POSTS_FAILURE";

export const UPDATE_HOME_POSTS_SUCCESS = "UPDATE_HOME_POSTS_SUCCESS";
export const UPDATE_ROOM_POSTS_SUCCESS = "UPDATE_ROOM_POSTS_SUCCESS";
export const UPDATE_USER_POSTS_SUCCESS = "UPDATE_USER_POSTS_SUCCESS";

export const GET_ROOM_POSTS_REQUEST = "GET_ROOM_POSTS_REQUEST";
export const GET_ROOM_POSTS_SUCCESS = "GET_ROOM_POSTS_SUCCESS";
export const GET_ROOM_POSTS_FAILURE = "GET_ROOM_POSTS_FAILURE";

export const GET_USER_POSTS_REQUEST = "GET_USER_POSTS_REQUEST";
export const GET_USER_POSTS_SUCCESS = "GET_USER_POSTS_SUCCESS";
export const GET_USER_POSTS_FAILURE = "GET_USER_POSTS_FAILURE";

export const GET_EVENT_POSTS_REQUEST = "GET_EVENT_POSTS_REQUEST";
export const GET_EVENT_POSTS_SUCCESS = "GET_EVENT_POSTS_SUCCESS";
export const GET_EVENT_POSTS_FAILURE = "GET_EVENT_POSTS_FAILURE";

export const GET_USER_PREMIUM_ACTIVITY_REQUEST =
  "GET_USER_PREMIUM_ACTIVITY_REQUEST";
export const GET_USER_PREMIUM_ACTIVITY_SUCCESS =
  "GET_USER_PREMIUM_ACTIVITY_SUCCESS";
export const GET_USER_PREMIUM_ACTIVITY_FAILED =
  "GET_USER_PREMIUM_ACTIVITY_FAILED";

export const GET_USER_RSVPD_EVENTS_REQUEST = "GET_USER_RSVPD_EVENTS_REQUEST";
export const GET_USER_RSVPD_EVENTS_SUCCESS = "GET_USER_RSVPD_EVENTS_SUCCESS";
export const GET_USER_RSVPD_EVENTS_FAILURE = "GET_USER_RSVPD_EVENTS_FAILURE";

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const GET_MY_SUBSCRIPTIONS_REQUEST = "GET_MY_SUBSCRIPTIONS_REQUEST";
export const GET_MY_SUBSCRIPTIONS_SUCCESS = "GET_MY_SUBSCRIPTIONS_SUCCESS";
export const GET_MY_SUBSCRIPTIONS_FAILURE = "GET_MY_SUBSCRIPTIONS_FAILURE";

export const GET_MY_SUBSCRIBERS_REQUEST = "GET_MY_SUBSCRIBERS_REQUEST";
export const GET_MY_SUBSCRIBERS_SUCCESS = "GET_MY_SUBSCRIBERS_SUCCESS";
export const GET_MY_SUBSCRIBERS_FAILURE = "GET_MY_SUBSCRIBERS_FAILURE";

export const UN_SUBSCRIPTION_REQUEST = "UN_SUBSCRIPTION_REQUEST";
export const UN_SUBSCRIPTION_SUCCESS = "UN_SUBSCRIPTION_SUCCESS";
export const UN_SUBSCRIPTION_FAILURE = "UN_SUBSCRIPTION_FAILURE";

export const GET_LOGGEDIN_USER_DATA_REQUEST = "GET_LOGGEDIN_USER_DATA_REQUEST";
export const GET_LOGGEDIN_USER_DATA_SUCCESS = "GET_LOGGEDIN_USER_DATA_SUCCESS";
export const GET_LOGGEDIN_USER_DATA_FAILURE = "GET_LOGGEDIN_USER_DATA_FAILURE";
export const GET_P_USER_RSVPD_EVENTS_REQUEST =
  "GET_P_USER_RSVPD_EVENTS_REQUEST";
export const GET_P_USER_RSVPD_EVENTS_SUCCESS =
  "GET_P_USER_RSVPD_EVENTS_SUCCESS";
export const GET_P_USER_RSVPD_EVENTS_FAILURE =
  "GET_P_USER_RSVPD_EVENTS_FAILURE";

export const UPDATE_USER_ENS_REQUEST = "UPDATE_USER_ENS_REQUEST";
export const UPDATE_USER_ENS_SUCCESS = "UPDATE_USER_ENS_SUCCESS";
export const UPDATE_USER_ENS_FAILURE = "UPDATE_USER_ENS_FAILURE";

export const GET_AUTH_USER_DATA_REQUEST = "GET_AUTH_USER_DATA_REQUEST";
export const GET_AUTH_USER_DATA_SUCCESS = "GET_AUTH_USER_DATA_SUCCESS";
export const GET_AUTH_USER_DATA_FAILURE = "GET_AUTH_USER_DATA_FAILURE";

export const VIBEBUCKS_WITHDRAW_SUCCESS = "VIBEBUCKS_WITHDRAW_SUCCESS";

export const UPDATE_ISPAYMENT_STATUS = "UPDATE_ISPAYMENT_STATUS";
export const UPDATE_ACCOUNT_CONNECTION_STATUS =
  "UPDATE_ACCOUNT_CONNECTION_STATUS";

export const UPDATE_USER_CALL_ACTIVITY_SUCCESS =
  "UPDATE_USER_CALL_ACTIVITY_SUCCESS";
export const UPDATE_USER_STATE_REQUEST = "UPDATE_USER_STATE_REQUEST";
export const UPDATE_USER_STATE_SUCCESS = "UPDATE_USER_STATE_SUCCESS";
export const UPDATE_USER_STATE_FAILURE = "UPDATE_USER_STATE_FAILURE";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";
export const INVITE_USER_RESET = "INVITE_USER_RESET";

export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAILURE = "BLOCK_USER_FAILURE";
export const BLOCK_USER_RESET = "BLOCK_USER_RESET";

export const UPDATE_RECENT_CALL_LIST = "UPDATE_RECENT_CALL_LIST";
export const UPDATE_STATUS_BY_CALL_ID = "UPDATE_STATUS_BY_CALL_ID";

export const UPDATE_CALL_STATUS_BY_CALL_ID = "UPDATE_CALL_STATUS_BY_CALL_ID";

export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const UPDATE_USER_SUBSCRIPTION_PLAN = "UPDATE_USER_SUBSCRIPTION_PLAN";
export const ADD_INSTANT_NOTIFICATION = "ADD_INSTANT_NOTIFICATION";
export const REMOVE_INSTANT_NOTIFICATION = "REMOVE_INSTANT_NOTIFICATION";
export const REMOVE_ALL_INSTANT_NOTIFICATION =
  "REMOVE_ALL_INSTANT_NOTIFICATION";

export const GET_USER_ROOMS_REQUEST = "GET_USER_ROOMS_REQUEST";
export const GET_USER_ROOMS_SUCCESS = "GET_USER_ROOMS_SUCCESS";
export const GET_USER_ROOMS_FAILURE = "GET_USER_ROOMS_FAILURE";

export const IS_USER_WAVEING = "IS_USER_WAVEING";

export const PUBLISH_VIDEO_REQUEST = "PUBLISH_VIDEO_REQUEST";
export const PUBLISH_VIDEO_SUCCESS = "PUBLISH_VIDEO_SUCCESS";
export const PUBLISH_VIDEO_FAILURE = "PUBLISH_VIDEO_FAILURE";

export const UPDATE_VIDEO_REQUEST = "UPDATE_VIDEO_REQUEST";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const UPDATE_VIDEO_FAILURE = "UPDATE_VIDEO_FAILURE";

export const UPDATE_VIDEO_STATUS_REQUEST = "UPDATE_VIDEO_STATUS_REQUEST";
export const UPDATE_VIDEO_STATUS_SUCCESS = "UPDATE_VIDEO_STATUS_SUCCESS";
export const UPDATE_VIDEO_STATUS_FAILURE = "UPDATE_VIDEO_STATUS_FAILURE";

export const GET_ALL_PUBLISH_VIDEO_REQUEST = "GET_ALL_PUBLISH_VIDEO_REQUEST";
export const GET_ALL_PUBLISH_VIDEO_SUCCESS = "GET_ALL_PUBLISH_VIDEO_SUCCESS";
export const GET_ALL_PUBLISH_VIDEO_FAILURE = "GET_ALL_PUBLISH_VIDEO_FAILURE";

export const PUBLISH_VIDEO_STATUS = "PUBLISH_VIDEO_STATUS";
export const GET_ROOM_MESSAGES_REQUEST = "GET_ROOM_MESSAGES_REQUEST";
export const GET_ROOM_MESSAGES_SUCCESS = "GET_ROOM_MESSAGES_SUCCESS";
export const GET_ROOM_MESSAGES_FAILURE = "GET_ROOM_MESSAGES_FAILURE";

export const GET_MY_PUBLISH_VIDEO_REQUEST = "GET_MY_PUBLISH_VIDEO_REQUEST";
export const GET_MY_PUBLISH_VIDEO_SUCCESS = "GET_MY_PUBLISH_VIDEO_SUCCESS";
export const GET_MY_PUBLISH_VIDEO_FAILURE = "GET_MY_PUBLISH_VIDEO_FAILURE";

export const GET_CHAT_ROOM_REQUEST = "GET_CHAT_ROOM_REQUEST";
export const GET_CHAT_ROOM_SUCCESS = "GET_CHAT_ROOM_SUCCESS";
export const GET_CHAT_ROOM_FAILURE = "GET_CHAT_ROOM_FAILURE";

export const UNLOCK_CHAT_ROOM_REQUEST = "UNLOCK_CHAT_ROOM_REQUEST";
export const UNLOCK_CHAT_ROOM_SUCCESS = "UNLOCK_CHAT_ROOM_SUCCESS";
export const UNLOCK_CHAT_ROOM_FAILURE = "UNLOCK_CHAT_ROOM_FAILURE";

export const GET_CONVERSATIONS_REQUEST = "GET_CONVERSATIONS_REQUEST";
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
export const GET_CONVERSATIONS_FAILURE = "GET_CONVERSATIONS_FAILURE";

export const GET_DM_CONVERSATIONS_REQUEST = "GET_DM_CONVERSATIONS_REQUEST";
export const GET_DM_CONVERSATIONS_SUCCESS = "GET_DM_CONVERSATIONS_SUCCESS";
export const GET_DM_CONVERSATIONS_FAILURE = "GET_DM_CONVERSATIONS_FAILURE";

export const GET_ROOM_CONVERSATIONS_REQUEST = "GET_ROOM_CONVERSATIONS_REQUEST";
export const GET_ROOM_CONVERSATIONS_SUCCESS = "GET_ROOM_CONVERSATIONS_SUCCESS";
export const GET_ROOM_CONVERSATIONS_FAILURE = "GET_ROOM_CONVERSATIONS_FAILURE";

export const GET_MORE_CONVERSATIONS_REQUEST = "GET_MORE_CONVERSATIONS_REQUEST";
export const GET_MORE_CONVERSATIONS_SUCCESS = "GET_MORE_CONVERSATIONS_SUCCESS";
export const GET_MORE_CONVERSATIONS_FAILURE = "GET_MORE_CONVERSATIONS_FAILURE";

export const GET_COUNT_UNREAD_MSG_REQUEST = "GET_COUNT_UNREAD_MSG_REQUEST";
export const GET_COUNT_UNREAD_MSG_SUCCESS = "GET_COUNT_UNREAD_MSG_SUCCESS";
export const GET_COUNT_UNREAD_MSG_FAILURE = "GET_COUNT_UNREAD_MSG_FAILURE";

export const UPDATE_UNREAD_MESSAGE = "UPDATE_UNREAD_MESSAGE";
export const UPDATE_UNREAD_MESSAGE_INCREMENT =
  "UPDATE_UNREAD_MESSAGE_INCREMENT";

export const UPDATE_UNREAD_MESSAGE_INCREMENT_ALL =
  "UPDATE_UNREAD_MESSAGE_INCREMENT_ALL";

export const ADD_NEW_CONVERSATION = "ADD_NEW_CONVERSATION";
export const UPDATE_ROOM_ACTIVE_USER_COUNT = "UPDATE_ROOM_ACTIVE_USER_COUNT";
export const UPDATE_CONVERSATION_ORDER = "UPDATE_CONVERSATION_ORDER";

export const GET_MY_EVENTS_SUCCESS = "GET_MY_EVENTS_SUCCESS";

export const UPDATE_CHAT_USER_STATUS = "UPDATE_CHAT_USER_STATUS";
export const UPDATE_ACTIVE_CHAT_ROOM = "UPDATE_ACTIVE_CHAT_ROOM";

export const SET_CURRENT_PLAYING_VIDEO = "SET_CURRENT_PLAYING_VIDEO";

export const SET_RSVP_UPDATED_EVENT = "SET_RSVP_UPDATED_EVENT";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const ADD_CHAT_REACTION = "ADD_CHAT_REACTION";
export const REMOVE_CHAT_REACTION = "REMOVE_CHAT_REACTION";

export const GET_ROOM_ID_FROM_SLUG_REQUEST = "GET_ROOM_ID_FROM_SLUG_REQUEST";
export const GET_ROOM_ID_FROM_SLUG_SUCCESS = "GET_ROOM_ID_FROM_SLUG_SUCCESS";
export const GET_ROOM_ID_FROM_SLUG_FAILURE = "GET_ROOM_ID_FROM_SLUG_FAILURE";
export const CLEAR_SCREEN_ROOM_SLUG_SUCCESS = "CLEAR_SCREEN_ROOM_SLUG_SUCCESS";

export const TOGGLE_SCROLL = "TOGGLE_SCROLL";
export const TOGGLE_IS_ANIMATED_SCROLL = "TOGGLE_IS_ANIMATED_SCROLL";

export const GET_SEARCH_ROOMS_REQUEST = "GET_SEARCH_ROOMS_REQUEST";
export const GET_SEARCH_ROOMS_SUCCESS = "GET_SEARCH_ROOMS_SUCCESS";
export const GET_SEARCH_ROOMS_FAILURE = "GET_SEARCH_ROOMS_FAILURE";

export const GET_ACCESS_PASSES_REQUEST = "GET_ACCESS_PASSES_REQUEST";
export const GET_ACCESS_PASSES_SUCCESS = "GET_ACCESS_PASSES_SUCCESS";
export const GET_ACCESS_PASSES_FAILURE = "GET_ACCESS_PASSES_FAILURE";

export const GET_SYSTEM_SETTINGS_REQUEST = "GET_SYSTEM_SETTINGS_REQUEST";
export const GET_SYSTEM_SETTINGS_SUCCESS = "GET_SYSTEM_SETTINGS_SUCCESS";
export const GET_SYSTEM_SETTINGS_FAILURE = "GET_SYSTEM_SETTINGS_FAILURE";

export const GET_EXCHANGE_RATE_REQUEST = "GET_EXCHANGE_RATE_REQUEST";
export const GET_EXCHANGE_RATE_SUCCESS = "GET_EXCHANGE_RATE_SUCCESS";
export const GET_EXCHANGE_RATE_FAILURE = "GET_EXCHANGE_RATE_FAILURE";

export const UPDATE_DIAMOND_EXCHANGE_SUCCESS =
  "UPDATE_DIAMOND_EXCHANGE_SUCCESS";

export const CREATE_REFERRAL_REQUEST = "CREATE_REFERRAL_REQUEST";
export const CREATE_REFERRAL_SUCCESS = "CREATE_REFERRAL_SUCCESS";
export const CREATE_REFERRAL_FAILURE = "CREATE_REFERRAL_FAILURE";

export const GET_REFERRAL_REQUEST = "GET_REFERRAL_REQUEST";
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAILURE = "GET_REFERRAL_FAILURE";

export const GET_MY_ROOM_SLUG_COUNT_REQUEST = "GET_MY_ROOM_SLUG_COUNT_REQUEST";
export const GET_MY_ROOM_SLUG_COUNT_SUCCESS = "GET_MY_ROOM_SLUG_COUNT_SUCCESS";
export const GET_MY_ROOM_SLUG_COUNT_FAILURE = "GET_MY_ROOM_SLUG_COUNT_FAILURE";

export const GET_AUTH_USER_REFERRAL_REQUEST = "GET_AUTH_USER_REFERRAL_REQUEST";
export const GET_AUTH_USER_REFERRAL_SUCCESS = "GET_AUTH_USER_REFERRAL_SUCCESS";
export const GET_AUTH_USER_REFERRAL_FAILURE = "GET_AUTH_USER_REFERRAL_FAILURE";

export const UPDATE_AUTH_USER_REFERRAL_REQUEST =
  "UPDATE_AUTH_USER_REFERRAL_REQUEST";
export const UPDATE_AUTH_USER_REFERRAL_SUCCESS =
  "UPDATE_AUTH_USER_REFERRAL_SUCCESS";
export const UPDATE_AUTH_USER_REFERRAL_FAILURE =
  "UPDATE_AUTH_USER_REFERRAL_FAILURE";

export const RESET_MSG_DATA = "RESET_MSG_DATA";
export const SET_RSVP_EVENT_ERROR_MESSAGE = "SET_RSVP_EVENT_ERROR_MESSAGE";

export const CREATE_REQUEST_EVENT_REQUEST = "CREATE_REQUEST_EVENT_REQUEST";
export const CREATE_REQUEST_EVENT_SUCCESS = "CREATE_REQUEST_EVENT_SUCCESS";
export const CREATE_REQUEST_EVENT_FAILURE = "CREATE_REQUEST_EVENT_FAILURE";

export const UPDATE_REQUEST_EVENT_REQUEST = "UPDATE_REQUEST_EVENT_REQUEST";
export const UPDATE_REQUEST_EVENT_SUCCESS = "UPDATE_REQUEST_EVENT_SUCCESS";
export const UPDATE_REQUEST_EVENT_FAILURE = "UPDATE_REQUEST_EVENT_FAILURE";

export const GET_ALL_REQUEST_EVENT_REQUEST = "GET_ALL_REQUEST_EVENT_REQUEST";
export const GET_ALL_REQUEST_EVENT_SUCCESS = "GET_ALL_REQUEST_EVENT_SUCCESS";
export const GET_ALL_REQUEST_EVENT_FAILURE = "GET_ALL_REQUEST_EVENT_FAILURE";

export const CREATE_EVENT_FROM_REQUEST_EVENT_REQUEST =
  "CREATE_EVENT_FROM_REQUEST_EVENT_REQUEST";
export const CREATE_EVENT_FROM_REQUEST_EVENT_SUCCESS =
  "CREATE_EVENT_FROM_REQUEST_EVENT_SUCCESS";
export const CREATE_EVENT_FROM_REQUEST_EVENT_FAILURE =
  "CREATE_EVENT_FROM_REQUEST_EVENT_FAILURE";

export const SET_REQUEST_EVENT_CHAT_MODAL = "SET_REQUEST_EVENT_CHAT_MODAL";
export const LOGIN_WITH_WORLDCOIN_SUCCESS = "LOGIN_WITH_WORLDCOIN_SUCCESS";

export const UPDATE_CALL_COMPLIMENTS = "UPDATE_CALL_COMPLIMENTS";

export const RESET_USER_FEED_STATE = "RESET_USER_FEED_STATE";
export const UPDATE_FEED_LIKE = "UPDATE_FEED_LIKE";
export const CREATE_FEED_SUCCESS = "CREATE_FEED_SUCCESS";

export const UPDATE_FEED_DIAMOND_COUNT = "UPDATE_FEED_DIAMOND_COUNT";

export const GET_FEED_ACTIVITY_POSTS_REQUEST =
  "GET_FEED_ACTIVITY_POSTS_REQUEST";
export const GET_FEED_ACTIVITY_POSTS_SUCCESS =
  "GET_FEED_ACTIVITY_POSTS_SUCCESS";
export const GET_FEED_ACTIVITY_POSTS_FAILURE =
  "GET_FEED_ACTIVITY_POSTS_FAILURE";

export const GET_MY_PERSONAS_REQUEST = "GET_MY_PERSONAS_REQUEST";
export const GET_MY_PERSONAS_SUCCESS = "GET_MY_PERSONAS_SUCCESS";
export const GET_MY_PERSONAS_FAILURE = "GET_MY_PERSONAS_FAILURE";

export const GET_ALL_PERSONAS_REQUEST = "GET_ALL_PERSONAS_REQUEST";
export const GET_ALL_PERSONAS_SUCCESS = "GET_ALL_PERSONAS_SUCCESS";
export const GET_ALL_PERSONAS_FAILURE = "GET_ALL_PERSONAS_FAILURE";

export const CREATE_PERSONA_REQUEST = "CREATE_PERSONA_REQUEST";
export const CREATE_PERSONA_SUCCESS = "CREATE_PERSONA_SUCCESS";
export const CREATE_PERSONA_FAILURE = "CREATE_PERSONA_FAILURE";

export const UPDATE_PERSONA_REQUEST = "UPDATE_PERSONA_REQUEST";
export const UPDATE_PERSONA_SUCCESS = "UPDATE_PERSONA_SUCCESS";
export const UPDATE_PERSONA_FAILURE = "UPDATE_PERSONA_FAILURE";

export const DELETE_PERSONA_REQUEST = "DELETE_PERSONA_REQUEST";
export const DELETE_PERSONA_SUCCESS = "DELETE_PERSONA_SUCCESS";
export const DELETE_PERSONA_FAILURE = "DELETE_PERSONA_FAILURE";

export const GET_ROOM_SUBSCRIBED_PERSONAS_REQUEST =
  "GET_ROOM_SUBSCRIBED_PERSONAS_REQUEST";
export const GET_ROOM_SUBSCRIBED_PERSONAS_SUCCESS =
  "GET_ROOM_SUBSCRIBED_PERSONAS_SUCCESS";
export const GET_ROOM_SUBSCRIBED_PERSONAS_FAILURE =
  "GET_ROOM_SUBSCRIBED_PERSONAS_FAILURE";

export const GET_ROOM_SUBSCRIBER_PERSONAS_REQUEST =
  "GET_ROOM_SUBSCRIBER_PERSONAS_REQUEST";
export const GET_ROOM_SUBSCRIBER_PERSONAS_SUCCESS =
  "GET_ROOM_SUBSCRIBER_PERSONAS_SUCCESS";
export const GET_ROOM_SUBSCRIBER_PERSONAS_FAILURE =
  "GET_ROOM_SUBSCRIBER_PERSONAS_FAILURE";

export const TOGGLE_AI_REPLY = "TOGGLE_AI_REPLY";
export const TOGGLE_AI_REPLY_FALSE = "TOGGLE_AI_REPLY_FALSE";

export const UPDATE_PERSONA_CLICK_COUNT_REQUEST =
  "UPDATE_PERSONA_CLICK_COUNT_REQUEST";
export const UPDATE_PERSONA_CLICK_COUNT_SUCCESS =
  "UPDATE_PERSONA_CLICK_COUNT_SUCCESS";
export const UPDATE_PERSONA_CLICK_COUNT_FAILURE =
  "UPDATE_PERSONA_CLICK_COUNT_FAILURE";

export const UPDATE_FORM_DATA_STATE = "UPDATE_FORM_DATA_STATE";
export const UPDATE_FORM_ERROR_STATE = "UPDATE_FORM_ERROR_STATE";

export const CREATE_PERSONA_ACTIVITY_REQUEST =
  "CREATE_PERSONA_ACTIVITY_REQUEST";
export const CREATE_PERSONA_ACTIVITY_SUCCESS =
  "CREATE_PERSONA_ACTIVITY_SUCCESS";
export const CREATE_PERSONA_ACTIVITY_FAILURE =
  "CREATE_PERSONA_ACTIVITY_FAILURE";

export const GET_PERSONA_ACTIVITY_REQUEST = "GET_PERSONA_ACTIVITY_REQUEST";
export const GET_PERSONA_ACTIVITY_SUCCESS = "GET_PERSONA_ACTIVITY_SUCCESS";
export const GET_PERSONA_ACTIVITY_FAILURE = "GET_PERSONA_ACTIVITY_FAILURE";

export const UPDATE_PERSONA_INTRO = "UPDATE_PERSONA_INTRO";

export const GET_PROFILE_PERSONAS_REQUEST = "GET_PROFILE_PERSONAS_REQUEST";
export const GET_PROFILE_PERSONAS_SUCCESS = "GET_PROFILE_PERSONAS_SUCCESS";
export const GET_PROFILE_PERSONAS_FAILURE = "GET_PROFILE_PERSONAS_FAILURE";

export const UPDATE_PROFILE_PERSONA_CLICK_COUNT_SUCCESS =
  "UPDATE_PROFILE_PERSONA_CLICK_COUNT_SUCCESS";

export const GET_PERSONA_FAQS_REQUEST = "GET_PERSONA_FAQS_REQUEST";
export const GET_PERSONA_FAQS_SUCCESS = "GET_PERSONA_FAQS_SUCCESS";
export const GET_PERSONA_FAQS_FAILURE = "GET_PERSONA_FAQS_FAILURE";

export const CREATE_PERSONA_FAQ_REQUEST = "CREATE_PERSONA_FAQ_REQUEST";
export const CREATE_PERSONA_FAQ_SUCCESS = "CREATE_PERSONA_FAQ_SUCCESS";
export const CREATE_PERSONA_FAQ_FAILURE = "CREATE_PERSONA_FAQ_FAILURE";

export const CREATE_PROMOTION_REQUEST = "CREATE_PROMOTION_REQUEST";
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS";
export const CREATE_PROMOTION_FAILURE = "CREATE_PROMOTION_FAILURE";

export const CREATE_BOOKING_TYPE_REQUEST = "CREATE_BOOKING_TYPE_REQUEST";
export const CREATE_BOOKING_TYPE_SUCCESS = "CREATE_BOOKING_TYPE_SUCCESS";
export const CREATE_BOOKING_TYPE_FAILURE = "CREATE_BOOKING_TYPE_FAILURE";

export const UPDATE_BOOKING_TYPE_REQUEST = "UPDATE_BOOKING_TYPE_REQUEST";
export const UPDATE_BOOKING_TYPE_SUCCESS = "UPDATE_BOOKING_TYPE_SUCCESS";
export const UPDATE_BOOKING_TYPE_FAILURE = "UPDATE_BOOKING_TYPE_FAILURE";

export const GET_BOOKING_TYPES_REQUEST = "GET_BOOKING_TYPES_REQUEST";
export const GET_BOOKING_TYPES_SUCCESS = "GET_BOOKING_TYPES_SUCCESS";
export const GET_BOOKING_TYPES_FAILURE = "GET_BOOKING_TYPES_FAILURE";

export const DELETE_BOOKING_TYPE_REQUEST = "DELETE_BOOKING_TYPE_REQUEST";
export const DELETE_BOOKING_TYPE_SUCCESS = "DELETE_BOOKING_TYPE_SUCCESS";
export const DELETE_BOOKING_TYPE_FAILURE = "DELETE_BOOKING_TYPE_FAILURE";

export const UPDATE_PROMOTION_REQUEST = "UPDATE_PROMOTION_REQUEST";
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS";
export const UPDATE_PROMOTION_FAILURE = "UPDATE_PROMOTION_FAILURE";

export const GET_MY_PROMOTION_REQUEST = "GET_MY_PROMOTION_REQUEST";
export const GET_MY_PROMOTION_SUCCESS = "GET_MY_PROMOTION_SUCCESS";
export const GET_MY_PROMOTION_FAILURE = "GET_MY_PROMOTION_FAILURE";

export const GET_APPROVED_PROMOTION_REQUEST = "GET_APPROVED_PROMOTION_REQUEST";
export const GET_APPROVED_PROMOTION_SUCCESS = "GET_APPROVED_PROMOTION_SUCCESS";
export const GET_APPROVED_PROMOTION_FAILURE = "GET_APPROVED_PROMOTION_FAILURE";

export const GET_PROMOTED_PARTNERS_REQUEST = "GET_PROMOTED_PARTNERS_REQUEST";
export const GET_PROMOTED_PARTNERS_SUCCESS = "GET_PROMOTED_PARTNERS_SUCCESS";
export const GET_MORE_PROMOTED_PARTNERS = "GET_MORE_PROMOTED_PARTNERS";
export const GET_PROMOTED_PARTNERS_FAILURE = "GET_PROMOTED_PARTNERS_FAILURE";

export const GET_PERSONA_PREMIUM_TAGS_REQUEST =
  "GET_PERSONA_PREMIUM_TAGS_REQUEST";
export const GET_PERSONA_PREMIUM_TAGS_SUCCESS =
  "GET_PERSONA_PREMIUM_TAGS_SUCCESS";
export const GET_PERSONA_PREMIUM_TAGS_FAILURE =
  "GET_PERSONA_PREMIUM_TAGS_FAILURE";

export const UPDATE_USER_GAMIFICATION = "UPDATE_USER_GAMIFICATION";

export const GET_LIVE_STREAM_ROOM_MESSAGES_SUCCESS =
  "GET_LIVE_STREAM_ROOM_MESSAGES_SUCCESS";
export const ADD_LIVE_STREAM_MESSAGE = "ADD_LIVE_STREAM_MESSAGE";
export const RESET_LIVE_STREAM_MESSAGE_COUNT =
  "RESET_LIVE_STREAM_MESSAGE_COUNT";
export const UPDATE_LIVE_STREAM_MESSAGE_COUNT =
  "UPDATE_LIVE_STREAM_MESSAGE_COUNT";
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB";

export const GET_MY_PERSONA_VIDEO_INSIGHTS_REQUEST =
  "GET_MY_PERSONA_VIDEO_INSIGHTS_REQUEST";
export const GET_MY_PERSONA_VIDEO_INSIGHTS_SUCCESS =
  "GET_MY_PERSONA_VIDEO_INSIGHTS_SUCCESS";
export const GET_MY_PERSONA_VIDEO_INSIGHTS_FAILURE =
  "GET_MY_PERSONA_VIDEO_INSIGHTS_FAILURE";

export const CREATE_MY_PERSONA_VIDEO_INSIGHT_REQUEST =
  "CREATE_MY_PERSONA_VIDEO_INSIGHT_REQUEST";
export const CREATE_MY_PERSONA_VIDEO_INSIGHT_SUCCESS =
  "CREATE_MY_PERSONA_VIDEO_INSIGHT_SUCCESS";
export const CREATE_MY_PERSONA_VIDEO_INSIGHT_FAILURE =
  "CREATE_MY_PERSONA_VIDEO_INSIGHT_FAILURE";
export const SET_PERSONA_VIDEO_INSIGHT_SUCCESSFULLY_CREATED_FALSE =
  "SET_PERSONA_VIDEO_INSIGHT_SUCCESSFULLY_CREATED_FALSE";

export const ALREADY_ADDED_PERSONA_VIDEO_INSIGHT =
  "ALREADY_ADDED_PERSONA_VIDEO_INSIGHT";

export const UPDATE_PERSONA_VIDEO_INSIGHT_REQUEST =
  "UPDATE_PERSONA_VIDEO_INSIGHT_REQUEST";
export const UPDATE_PERSONA_VIDEO_INSIGHT_SUCCESS =
  "UPDATE_PERSONA_VIDEO_INSIGHT_SUCCESS";
export const UPDATE_PERSONA_VIDEO_INSIGHT_FAILURE =
  "UPDATE_PERSONA_VIDEO_INSIGHT_FAILURE";

export const GET_PERSONA_POSTS_SUCCESS = "GET_PERSONA_POSTS_SUCCESS";
export const GET_PERSONA_POSTS_REQUEST = "GET_PERSONA_POSTS_REQUEST";
export const GET_PERSONA_POSTS_FAILURE = "GET_PERSONA_POSTS_FAILURE";

export const UPDATE_PERSONA_POSTS_SUCCESS = "UPDATE_PERSONA_POSTS_SUCCESS";

export const GET_MY_EXTERNAL_VIDEOS_COUNT_SUCCESS =
  "GET_MY_EXTERNAL_VIDEOS_COUNT_SUCCESS";
export const GET_MY_EXTERNAL_VIDEOS_COUNT_REQUEST =
  "GET_MY_EXTERNAL_VIDEOS_COUNT_REQUEST";
export const GET_MY_EXTERNAL_VIDEOS_COUNT_FAILURE =
  "GET_MY_EXTERNAL_VIDEOS_COUNT_FAILURE";

export const GET_MY_PERSONA_ARTICLES_REQUEST =
  "GET_MY_PERSONA_ARTICLES_REQUEST";
export const GET_MY_PERSONA_ARTICLES_SUCCESS =
  "GET_MY_PERSONA_ARTICLES_SUCCESS";
export const GET_MY_PERSONA_ARTICLES_FAILURE =
  "GET_MY_PERSONA_ARTICLES_FAILURE";

export const CREATE_MY_PERSONA_ARTICLE_REQUEST =
  "CREATE_MY_PERSONA_ARTICLE_REQUEST";
export const CREATE_MY_PERSONA_ARTICLE_SUCCESS =
  "CREATE_MY_PERSONA_ARTICLE_SUCCESS";
export const CREATE_MY_PERSONA_ARTICLE_FAILURE =
  "CREATE_MY_PERSONA_ARTICLE_FAILURE";

export const LOGIN_WITH_CUSTOM_WALLET_REQUEST =
  "LOGIN_WITH_CUSTOM_WALLET_REQUEST";
export const LOGIN_WITH_CUSTOM_WALLET_SUCCESS =
  "LOGIN_WITH_CUSTOM_WALLET_SUCCESS";
export const LOGIN_WITH_CUSTOM_WALLET_FAILURE =
  "LOGIN_WITH_CUSTOM_WALLET_FAILURE";
