import { useContext } from "react";
import { StoreContext } from "../Context/StoreContext";

function PremiumWrapper({ children, permission, fallbackComponent }) {
  const { state } = useContext(StoreContext);

  const {
    auth: { user: authUser },
    role,
  } = state;

  function getPermissionOfUser(role) {
    const roles = role.roles;
    const found = roles.find((element) => element._id === authUser.serviceRole);
    if (found) {
      return found.permissions;
    } else {
      return null;
    }
  }

  function findAllElementsInArray(permission, userPermission) {
    if (!userPermission) return false;
    else {
      return permission.every(function (elem) {
        return userPermission.indexOf(elem) !== -1;
      });
    }
  }

  if (!findAllElementsInArray(permission, getPermissionOfUser(role)))
    return fallbackComponent;

  return children;
}

export default PremiumWrapper;
