import { Dialog, Switch, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { updateProfile } from "../../actions/userActions";
import { openPlanModal } from "../../actions/profileModalActions";
import { StoreContext } from "../../Context/StoreContext";
import ConfirmationModal from "../../VibehutUI/ConfirmationModal";
import Button from "../../VibehutUI/Button";
import Back from "../../VibehutUI/Icons/Back";
import Close from "../../VibehutUI/Icons/Close";
import LimitedTextarea from "../../VibehutUI/LimitedTextarea";
import Loader from "../../VibehutUI/Loader";
import BannerPicker from "../rooms/BannerPicker";
import { uploadMedia } from "../../utils/uploadMedia";
import { useHistory } from "react-router-dom";
import {
  calcDayDifference,
  checkUsernameRegex,
  getUserRole,
} from "../../utils/utils";
import { activeTabEditProfile } from "../../constants";
import LimitedTextarea2 from "../../VibehutUI/LimitedTextArea2";
import { showToast } from "../../actions/toastActions";

const defaultCover = {
  urls: {
    raw: "https://images.unsplash.com/photo-1552300977-cbc8b08d95e7?ixid=MnwyNjkwNTR8MHwxfGFsbHw3fHx8fHx8MXx8MTY2MjczNDQwNA&ixlib=rb-1.2.1",
    full: "https://images.unsplash.com/photo-1552300977-cbc8b08d95e7?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNjkwNTR8MHwxfGFsbHw3fHx8fHx8MXx8MTY2MjczNDQwNA&ixlib=rb-1.2.1&q=80",
    regular:
      "https://images.unsplash.com/photo-1552300977-cbc8b08d95e7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjkwNTR8MHwxfGFsbHw3fHx8fHx8MXx8MTY2MjczNDQwNA&ixlib=rb-1.2.1&q=80&w=1080",
    small:
      "https://images.unsplash.com/photo-1552300977-cbc8b08d95e7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjkwNTR8MHwxfGFsbHw3fHx8fHx8MXx8MTY2MjczNDQwNA&ixlib=rb-1.2.1&q=80&w=400",
    thumb:
      "https://images.unsplash.com/photo-1552300977-cbc8b08d95e7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjkwNTR8MHwxfGFsbHw3fHx8fHx8MXx8MTY2MjczNDQwNA&ixlib=rb-1.2.1&q=80&w=200",
  },
  source: "unsplash",
};

function GeneralSection({
  setIsOpen,
  setCoverOpen,
  cover,
  coverLoading,
  coverFile,
  setCoverFile,
  setCover,
}) {
  const history = useHistory();
  const { state, dispatch } = useContext(StoreContext);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [usernameErrorPrompt, setUsernameErrorPrompt] = useState("");
  const [errorPrompt, setErrorPrompt] = useState("");

  const {
    auth: { user, isFetching },
  } = state;
  const [username, setUsername] = useState(user?.vibehutUsername || "");
  const [selectedLocation, setSelectedLocation] = useState({
    formatted_address: user.location?.formatted_address || "",
    lat: user.location?.geometry?.location?.lat || "",
    lng: user.location?.geometry?.location?.lng || "",
  });

  const [description, setDescription] = useState(
    user?.vibehutDescription || ""
  );

  const [socilaLinks, setSocilaLinks] = useState({
    instagram: user.socialLinks?.instagram || "",
    linkedIn: user.socialLinks?.linkedIn || "",
    twitter:
      user.socialLinks?.twitter || user.socialLinks?.twitter === ""
        ? user.socialLinks?.twitter
        : user.provider === "twitter"
        ? user.username
        : "",
    metamask: user.socialLinks?.metamask || "",
    tiktok: user.socialLinks?.tiktok || "",
    telegram: user.socialLinks?.telegram || "",
    link: user.socialLinks?.link || "",
    facebook: user.socialLinks?.facebook || "",
    thread: user.socialLinks?.thread || "",
    deso: user.socialLinks?.deso || "",
  });

  const { ref: locationRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setSelectedLocation({
        formatted_address: place.formatted_address || "",
        lat: `${place.geometry?.location?.lat() || ""}`,
        lng: `${place.geometry?.location?.lng() || ""}`,
      });
    },
  });

  const handleChange = (e) => {
    setSocilaLinks({ ...socilaLinks, [e.target.name]: e.target.value });
  };

  const handleLocationChange = (e) => {
    setSelectedLocation({ formatted_address: e.target.value });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameErrorPrompt("");
    setErrorPrompt("");
  };

  const handleProfielDescriptionChange = (text) => {
    setDescription(text);
  };

  const handleUpdateUser = async () => {
    if (coverFile) {
      await uploadMedia(
        coverFile,
        cover?.urls?.small,
        setIsImageUploading,
        setCover,
        setCoverFile,
        updateProfileFun
      );
    } else {
      await updateProfileFun();
    }
  };

  const updateProfileFun = async (media = undefined) => {
    if (username?.trim().length > 0 && !checkUsernameRegex(username)) {
      showToast(
        dispatch,
        "error",
        "Usernames can only include letters, numbers, underscores",
        2000
      );

      return;
    }
    if (
      username &&
      username.trim().length > 0 &&
      user?.vibehutUsername !== username &&
      user?.usernameUpdatedAt
    ) {
      const dayDiff = calcDayDifference(user?.usernameUpdatedAt, new Date());
      if (dayDiff < 30) {
        const dayRemain = 30 - dayDiff;
        const errorText = `You can change the username after ${dayRemain} ${
          dayRemain > 1 ? "days" : "day"
        }.`;
        setUsernameErrorPrompt(errorText);
        showToast(dispatch, "error", errorText, 2000);
        return;
      }
    }
    await updateProfile(
      {
        ...(username?.trim().length > 0 && { username: username.trim() }),
        ...(description?.trim().length > 0 && { description: description }),
        cover: media?.url
          ? {
              urls: {
                raw: media.url,
                full: media.url,
                regular: media.url,
                small: media.url,
                thumb: media.url,
              },
              source: "custom",
            }
          : cover || defaultCover,
        socialLinks: socilaLinks,
        location: selectedLocation || {},
        services: user.services || [],
        promotion: {
          title: user.promotion?.title || "",
          link: user.promotion?.link || "",
          imgUrl: user.promotion?.imgUrl || "",
          isActive: user.promotion?.isActive || false,
        },
      },
      user?.token,
      dispatch
    )
      .then(() => {
        setIsOpen(false);
        if (
          user?.vibehutUsername !== username?.trim() &&
          username?.trim().length > 0
        ) {
          history.replace({
            pathname: `/@${username.trim()}`,
            state: { previousPath: history.location.pathname },
          });
        }
      })
      .catch((err) => {
        setErrorPrompt(err);
      });
  };
  const isUsernameValid = () => {
    if (username.length === 0) return true;
    return checkUsernameRegex(username);
  };

  return (
    <div>
      <div className="bg-white dark:bg-navyGray pt-6 px-4 relative  ">
        <div className="border-b border-frescoWhite dark:border-matteGray flex gap-4 items-center pb-6">
          <div className="">
            <img
              alt="room"
              src={
                coverFile
                  ? URL.createObjectURL(coverFile)
                  : cover?.urls?.small ||
                    "https://picsum.photos/id/1005/400/250"
              }
              className="rounded-xl object-cover w-36 h-full"
              loading="lazy"
            />
          </div>
          <div className="">
            <p className="text-base font-medium text-darkNight  dark:text-frescoWhite">
              Cover Photo
            </p>
            <div className="pt-2">
              {coverLoading ? (
                <div className="centerAbsolute mt-1 text-sm py-2 px-3 font-normal rounded-2xl">
                  <Loader />
                </div>
              ) : (
                <Button
                  onClick={() => setCoverOpen(true)}
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                >
                  Change
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 pt-4">
        <div className="font-ubuntu pb-2">
          <p className="text-darkNight dark:text-frescoWhite font-medium">
            Vibehut Username
          </p>
          <p className="text-darkGray text-sm">
            This username will display on your profile
          </p>
        </div>
        <div className="pb-3">
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
            Username
          </p>
          <input
            type="text"
            className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
            value={username}
            name="username"
            onChange={handleUsernameChange}
            placeholder="Username"
          />
          <p
            className={` text-xs ${
              isUsernameValid ? "text-gray-400" : "text-cvRed"
            }`}
          >
            Usernames can only include letters, numbers, underscores
          </p>
          {usernameErrorPrompt && (
            <p className={`text-xs text-cvRed`}>{usernameErrorPrompt}</p>
          )}
        </div>
        <div className="font-ubuntu pb-2">
          <p className="text-darkNight dark:text-frescoWhite font-medium">
            Vibehut Description
          </p>
          <p className="text-darkGray text-sm">
            This description will be displayed on your profile
          </p>
        </div>
        <div className="pb-2">
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
            Description
          </p>
          <LimitedTextarea2
            limit={2000}
            value={description}
            rows={3}
            onChange={(text) => handleProfielDescriptionChange(text)}
            placeHolder="Enter Description"
          />
        </div>
        <div className="pb-2">
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
            Location
          </p>
          <input
            type="text"
            className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
            ref={locationRef}
            placeholder="Enter Location"
            onChange={handleLocationChange}
            value={selectedLocation.formatted_address}
          />
        </div>

        <div className="font-ubuntu pb-3">
          <p className="text-darkNight dark:text-frescoWhite font-medium">
            Social Profiles
          </p>
          <p className="text-darkGray text-sm">
            Display social links on your profile
          </p>
        </div>
        <div>
          <div>
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Instagram
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.instagram}
              name="instagram"
              onChange={handleChange}
              placeholder="Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              X
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.twitter}
              name="twitter"
              onChange={handleChange}
              placeholder="Profile URL"
              disabled={user.provider === "twitter"}
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Metamask
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.metamask}
              name="metamask"
              onChange={handleChange}
              placeholder="Wallet Address / ENS"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              LinkedIn
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.linkedIn}
              name="linkedIn"
              onChange={handleChange}
              placeholder="Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Tiktok
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.tiktok}
              name="tiktok"
              onChange={handleChange}
              placeholder="Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Facebook
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.facebook}
              name="facebook"
              onChange={handleChange}
              placeholder="Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Telegram
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.telegram}
              name="telegram"
              onChange={handleChange}
              placeholder="Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Thread
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.thread}
              name="thread"
              onChange={handleChange}
              placeholder="Instagram Thread Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              DeSo
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.deso}
              name="deso"
              onChange={handleChange}
              placeholder="Profile URL"
            />
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              Any Other Link
            </p>
            <input
              type="text"
              className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
              value={socilaLinks.link}
              name="link"
              onChange={handleChange}
              placeholder="Any other Profile url"
            />
          </div>
        </div>
      </div>

      <div className=" px-4 py-3 flex flex-row  items-center justify-between">
        <p className={`text-xs text-cvRed`}>{errorPrompt}</p>
        <div className="flex space-x-1">
          {isFetching || isImageUploading ? (
            <Loader />
          ) : (
            <Button
              disabled={!isUsernameValid}
              onClick={handleUpdateUser}
              size="vibhut-sm"
              variant="vibhut-primary"
            >
              Save
            </Button>
          )}

          <div className="mr-3">
            <Button
              onClick={() => setIsOpen(false)}
              size="vibhut-sm"
              variant="vibhut-secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PromotionSection({
  setIsOpen,
  setCoverOpen,
  cover,
  coverLoading,
  coverFile,
  setCoverFile,
  setCover,
}) {
  const { state, dispatch } = useContext(StoreContext);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const {
    auth: { user, isFetching },
  } = state;

  const [promotion, setPromotion] = useState({
    title: user.promotion?.title || "",
    link: user.promotion?.link || "",
  });
  const [isPromotionActive, setIsPromotionActive] = useState(
    user?.promotion?.isActive || false
  );

  const handleChange = (e) => {
    setPromotion({ ...promotion, [e.target.name]: e.target.value });
  };

  const handleUpdateUser = async () => {
    if (coverFile) {
      await uploadMedia(
        coverFile,
        cover?.urls?.small,
        setIsImageUploading,
        setCover,
        setCoverFile,
        updateProfileFun
      );
    } else {
      await updateProfileFun();
    }
  };

  const updateProfileFun = async (media = undefined) => {
    await updateProfile(
      {
        ...(user.vibehutUsername && { username: user.vibehutUsername }),
        ...(user.vibehutDescription?.trim().length > 0 && {
          description: user?.vibehutDescription,
        }),
        services: user.services || [],
        cover: user.cover || defaultCover,
        socialLinks: {
          instagram: user.socialLinks?.instagram || "",
          linkedIn: user.socialLinks?.linkedIn || "",
          twitter:
            user.socialLinks?.twitter || user.socialLinks?.twitter === ""
              ? user.socialLinks?.twitter
              : user.provider === "twitter"
              ? user.username
              : "",
          metamask: user.socialLinks?.metamask || "",
          tiktok: user.socialLinks?.tiktok || "",
          telegram: user.socialLinks?.telegram || "",
          link: user.socialLinks?.link || "",
          facebook: user.socialLinks?.facebook || "",
          thread: user.socialLinks?.thread || "",
          deso: user.socialLinks?.deso || "",
        },
        location: user.location || {},
        promotion: {
          title: promotion.title,
          link: promotion.link,
          imgUrl: media?.secure_url ? media.secure_url : cover?.urls?.small,
          isActive: isPromotionActive,
        },
      },
      user?.token,
      dispatch
    ).then(() => {
      setIsOpen(false);
    });
  };

  return (
    <div>
      <div className="bg-white dark:bg-navyGray pt-6 px-4 relative  ">
        <div className="border-b border-frescoWhite dark:border-matteGray flex gap-4 items-center pb-6">
          <div className="">
            <img
              alt="room"
              src={
                coverFile
                  ? URL.createObjectURL(coverFile)
                  : cover?.urls?.small ||
                    "https://picsum.photos/id/1005/400/250"
              }
              className="rounded-xl object-cover w-36 h-full"
              loading="lazy"
            />
          </div>
          <div className="">
            <p className="text-base font-medium text-darkNight  dark:text-frescoWhite">
              Promotional Image
            </p>
            <div className="pt-2">
              {coverLoading ? (
                <div className="centerAbsolute mt-1 text-sm py-2 px-3 font-normal rounded-2xl">
                  <Loader />
                </div>
              ) : (
                <Button
                  onClick={() => setCoverOpen(true)}
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                >
                  Change
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 pt-4 space-y-2">
        <div>
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
            Promotional Title
          </p>
          <input
            type="text"
            className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
            value={promotion.title}
            name="title"
            onChange={handleChange}
            placeholder="Promotional Title"
          />
        </div>
        <div>
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
            Promotional Link
          </p>
          <input
            type="url"
            className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
            value={promotion.link}
            name="link"
            onChange={handleChange}
            placeholder="Promotional Link"
          />
        </div>
        <div className="flex justify-between items-center">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            Is Promotion Active?
          </p>
          <Switch
            checked={isPromotionActive}
            onChange={() => setIsPromotionActive(!isPromotionActive)}
            className={`${
              isPromotionActive ? "bg-primary" : "bg-gray-200"
            } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
          >
            <span
              className={`${
                isPromotionActive ? "translate-x-6" : "translate-x-1"
              } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
            />
          </Switch>
        </div>
      </div>

      <div className=" px-4 py-3 flex flex-row-reverse ">
        {isFetching || isImageUploading ? (
          <Loader />
        ) : (
          <Button
            onClick={handleUpdateUser}
            size="vibhut-sm"
            variant="vibhut-primary"
          >
            Save
          </Button>
        )}

        <div className="mr-3">
          <Button
            onClick={() => setIsOpen(false)}
            size="vibhut-sm"
            variant="vibhut-secondary"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

function ServiceSection({ setIsOpen, setOpenConfirmation }) {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth: { user, isFetching },
    role,
  } = state;

  const [services, setServices] = useState(
    user.services?.length > 0
      ? [
          ...user.services?.map((service) => {
            return {
              name: service.name,
              description: service.description,
              rate: service.rate,
              createdAt: service.createdAt || new Date(),
            };
          }),
        ]
      : [
          {
            name: "",
            description: "",
            rate: 0,
          },
        ]
  );

  function checkAddService() {
    const userRole = getUserRole(role);
    const limit = userRole ? userRole.limits.services : 2;
    if (services.length < limit) {
      return true;
    }
    return false;
  }

  const changeHandler = (id) => (event) => {
    const { name, value } = event.target;
    setServices((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              [name]: value,
            }
          : el
      )
    );
  };

  const handleDescriptionChange = (text, id) => {
    setServices((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              description: text,
            }
          : el
      )
    );
  };

  const handleUpdateUserServices = () => {
    if (
      services?.some(
        (service) => service.name === "" || service.description === ""
      )
    ) {
      return showToast(dispatch, "error", "Please fill all the fields", 2000);
    }
    updateProfile(
      {
        ...(user.vibehutUsername && { username: user.vibehutUsername }),
        ...(user.vibehutDescription?.trim().length > 0 && {
          description: user?.vibehutDescription,
        }),
        services: services,
        cover: user.cover || defaultCover,
        socialLinks: {
          instagram: user.socialLinks?.instagram || "",
          linkedIn: user.socialLinks?.linkedIn || "",
          twitter:
            user.socialLinks?.twitter || user.socialLinks?.twitter === ""
              ? user.socialLinks?.twitter
              : user.provider === "twitter"
              ? user.username
              : "",
          metamask: user.socialLinks?.metamask || "",
          tiktok: user.socialLinks?.tiktok || "",
          telegram: user.socialLinks?.telegram || "",
          link: user.socialLinks?.link || "",
          facebook: user.socialLinks?.facebook || "",
          thread: user.socialLinks?.thread || "",
          deso: user.socialLinks?.deso || "",
        },
        location: user?.location || {},
        promotion: {
          title: user.promotion?.title || "",
          link: user.promotion?.link || "",
          imgUrl: user.promotion?.imgUrl || "",
          isActive: user.promotion?.isActive || false,
        },
      },
      user?.token,
      dispatch
    ).then(() => {
      setIsOpen(false);
    });
  };

  const addServiceInput = () => {
    if (checkAddService()) {
      setServices([
        ...services,
        {
          name: "",
          description: "",
          rate: 0,
          createdAt: new Date(),
        },
      ]);
    } else {
      setOpenConfirmation(true);
    }
  };

  const removeService = (key) => {
    const servs = services.filter((service, index) => {
      return key !== index;
    });
    setServices(servs);
  };

  return (
    <div className="py-4">
      <div className="bg-white dark:bg-navyGray  px-4 relative  ">
        <p className="font-medium text-darkNight font-ubuntu dark:text-frescoWhite">
          Services
        </p>
        <p className="text-sm text-darkGray font-ubuntu">
          Let people know what you’re available to talk about.
        </p>
      </div>
      <div className="px-4  ">
        {services.map((service, index) => (
          <div className="pt-4 pb-6 border-b border-frescoWhite dark:border-matteGray">
            <div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
                  {`Service #${index + 1}*`}
                </p>
                <p
                  className="text-sm font-medium text-cvRed cursor-pointer"
                  onClick={() => removeService(index)}
                >
                  Remove
                </p>
              </div>

              <input
                type="text"
                className="border outline-none text-sm border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg"
                value={service.name}
                placeholder="Ex. Product Design"
                onChange={(e) => changeHandler(index)(e)}
                name="name"
              />
            </div>
            <div className="pt-4">
              <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                Description*
              </p>
              <LimitedTextarea
                limit={500}
                value={service.description}
                rows={5}
                onChange={(text) => handleDescriptionChange(text, index)}
                placeHolder="Explain a little bit more about your service..."
              />
            </div>
            <div className="pt-2">
              <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
                Rate
              </p>
              <input
                type="text"
                className="border text-sm outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-primaryGray bg-lightGray"
                // value={service.rate}
                // name="rate"
                placeholder="Free"
                // onChange={handleChange}
                disabled={true}
              />
              <p className="text-xs text-darkGray font-ubuntu">
                Paid option coming soon.
              </p>
            </div>
          </div>
        ))}

        <div className="pt-6">
          <Button
            onClick={addServiceInput}
            size="vibhut-sm"
            variant="vibhut-primary"
          >
            Add Service...
          </Button>
        </div>
        <div className=" pt-3 flex flex-row-reverse ">
          {isFetching ? (
            <Loader />
          ) : (
            <Button
              onClick={handleUpdateUserServices}
              size="vibhut-sm"
              variant="vibhut-primary"
            >
              Save
            </Button>
          )}

          <div className="mr-3">
            <Button
              onClick={() => setIsOpen(false)}
              size="vibhut-sm"
              variant="vibhut-secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export const EditProfileModal = ({
  isOpen,
  setIsOpen,
  defaultTab = activeTabEditProfile.profileTab,
}) => {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth: { user },
  } = state;
  const cancelButtonRef = useRef(null);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [coverOpen, setCoverOpen] = useState(false);
  const [cover, setCover] = useState(user.cover);
  const [coverFile, setCoverFile] = useState(null);
  const [promotionImg, setPromotionImg] = useState({
    urls: {
      small: user.promotion?.imgUrl || "",
    },
  });
  const [coverUploadFile, setCoverUploadFile] = useState(null);
  const [coverLoading, setCoverLoading] = useState(false);
  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setIsOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-30">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu ">
                <div className="bg-white dark:bg-navyGray p-4 ">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-row justify-start items-center">
                      {coverOpen ? (
                        <span
                          className="bg-frescoWhite rounded-full p-0.5 cursor-pointer text-gray-600 mr-2"
                          onClick={() => {
                            setCoverOpen(false);
                          }}
                        >
                          <Back ratio="26" />
                        </span>
                      ) : undefined}
                      <p className="font-medium text-lg text-black dark:text-frescoWhite">
                        {coverOpen
                          ? activeTab === "promotion"
                            ? "Change Promotion Image"
                            : "Change Cover"
                          : "Edit Profile"}
                      </p>
                    </div>

                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                {coverOpen ? (
                  <BannerPicker
                    setCover={
                      activeTab === activeTabEditProfile.promotionTab
                        ? setPromotionImg
                        : setCover
                    }
                    setCoverLoading={setCoverLoading}
                    setCoverOpen={setCoverOpen}
                    setCoverFile={
                      activeTab === activeTabEditProfile.promotionTab
                        ? setCoverUploadFile
                        : setCoverFile
                    }
                  />
                ) : (
                  <div>
                    <div className="wrapper dark:border-matteGray border-b border-frescoWhite">
                      <nav className="py-2.5">
                        <input
                          type="radio"
                          name="tab"
                          id="profile"
                          checked={
                            activeTab === activeTabEditProfile.profileTab
                          }
                          className="hidden"
                        />
                        <input
                          type="radio"
                          name="tab"
                          id="chat"
                          checked={
                            activeTab === activeTabEditProfile.servicesTab
                          }
                          className="hidden"
                        />
                        <input
                          type="radio"
                          name="tab"
                          id="promotion"
                          checked={
                            activeTab === activeTabEditProfile.promotionTab
                          }
                          className="hidden"
                        />
                        <label
                          htmlFor="profile"
                          className="profile"
                          onClick={() => {
                            setActiveTab(activeTabEditProfile.profileTab);
                          }}
                        >
                          <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
                            General
                          </span>
                        </label>
                        <label
                          htmlFor="chat"
                          className="chat"
                          onClick={() => {
                            setActiveTab(activeTabEditProfile.servicesTab);
                          }}
                        >
                          <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
                            Services
                          </span>
                        </label>
                        <label
                          htmlFor="promotion"
                          className="promotion"
                          onClick={() => {
                            setActiveTab(activeTabEditProfile.promotionTab);
                          }}
                        >
                          <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
                            Promotion
                          </span>
                        </label>
                        <div className="tab border-b-2 border-primary"></div>
                      </nav>
                    </div>

                    <div className="">
                      {activeTab === activeTabEditProfile.profileTab && (
                        <GeneralSection
                          setIsOpen={setIsOpen}
                          setCoverOpen={setCoverOpen}
                          cover={cover}
                          coverLoading={coverLoading}
                          coverFile={coverFile}
                          setCoverFile={setCoverFile}
                          setCover={setCover}
                        />
                      )}
                      {activeTab === activeTabEditProfile.servicesTab && (
                        <ServiceSection
                          setOpenConfirmation={setOpenConfirmation}
                          setIsOpen={setIsOpen}
                        />
                      )}
                      {activeTab === activeTabEditProfile.promotionTab && (
                        <PromotionSection
                          setIsOpen={setIsOpen}
                          setCoverOpen={setCoverOpen}
                          cover={promotionImg}
                          coverLoading={coverLoading}
                          coverFile={coverUploadFile}
                          setCoverFile={setCoverUploadFile}
                          setCover={setPromotionImg}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
            <ConfirmationModal
              title="Upgrade to Premium"
              text={"You have reached your limit of adding services."}
              description="Upgrade to premium to add more services."
              openModal={openConfirmation}
              setOpenModal={setOpenConfirmation}
              primaryButtonText="Upgrade"
              primaryAction={() => {
                setOpenConfirmation(false);
                openPlanModal(dispatch);
              }}
              secondaryButtonText={"Cancel"}
              secondaryAction={() => {
                setOpenConfirmation(false);
              }}
            />
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

function EditProfile() {
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  return (
    <div>
      <Button
        size="vibhut-sm"
        variant="vibhut-secondary"
        onClick={() => setIsEditProfileOpen(true)}
      >
        Edit Profile
      </Button>
      <EditProfileModal
        isOpen={isEditProfileOpen}
        setIsOpen={setIsEditProfileOpen}
      />
    </div>
  );
}

export default EditProfile;
