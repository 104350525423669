import { useContext, useEffect, useState } from "react";
import BannerPicker from "../rooms/BannerPicker";
import Loader from "../../VibehutUI/Loader";
import Button from "../../VibehutUI/Button";
import InputBox from "../../VibehutUI/InputBox/inputBox";
import { StoreContext } from "../../Context/StoreContext";
import * as actionTypes from "../../constants";
import LimitedTextarea from "../../VibehutUI/LimitedTextarea";
import GenerateTextLabel from "./GenerateTextLabel";
import { Switch } from "@headlessui/react";
import { generateDataForPersona } from "../../services/personaService";
import { checkUpdatePersonaDetailsPermission } from "../../utils/utils";

interface Props {
  isEditMode?: boolean;
}

function GeneralTab({ isEditMode = false }: Props) {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    persona: { formState },
    role,
    room: {
      liveRoom: { liveRoom: room },
    },
  } = state;
  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [isGenerating, setIsGenerating] = useState("");
  const [avatar, setAvatar] = useState({
    urls: {
      regular: formState.data?.avatar?.urls?.regular || "",
      small: formState.data?.avatar?.urls?.small || "",
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: actionTypes.UPDATE_FORM_ERROR_STATE,
      payload: { ...formState.errorMessage, nameError: "" },
    });
    dispatch({
      type: actionTypes.UPDATE_FORM_DATA_STATE,
      payload: { ...formState.data, [event.target.name]: event.target.value },
    });
  };

  useEffect(() => {
    if (avatarFile) {
      dispatch({
        type: actionTypes.UPDATE_FORM_ERROR_STATE,
        payload: { ...formState.errorMessage, avatarError: "" },
      });
      dispatch({
        type: actionTypes.UPDATE_FORM_DATA_STATE,
        payload: { ...formState.data, avatarFile: avatarFile },
      });
    } else {
      dispatch({
        type: actionTypes.UPDATE_FORM_DATA_STATE,
        payload: { ...formState.data, avatarFile: null },
      });
    }
  }, [avatarFile, dispatch]);

  useEffect(() => {
    if (avatar) {
      dispatch({
        type: actionTypes.UPDATE_FORM_ERROR_STATE,
        payload: { ...formState.errorMessage, avatarError: "" },
      });
      dispatch({
        type: actionTypes.UPDATE_FORM_DATA_STATE,
        payload: { ...formState.data, avatar: avatar },
      });
    } else {
      dispatch({
        type: actionTypes.UPDATE_FORM_DATA_STATE,
        payload: {
          ...formState.data,
          avatar: {
            urls: {
              regular: "",
              small: "",
            },
          },
        },
      });
    }
  }, [avatar, dispatch]);

  const handleChangeTextAreaValue = (value: string, name: string) => {
    switch (name) {
      case "about":
        dispatch({
          type: actionTypes.UPDATE_FORM_ERROR_STATE,
          payload: { ...formState.errorMessage, aboutError: "" },
        });
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, about: value },
        });
        break;
      case "lookingFor":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, lookingFor: value },
        });
        break;
      case "notLookingFor":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, notLookingFor: value },
        });
        break;
      case "highlights":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, highlights: value },
        });
        break;
      case "lowlights":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, lowlights: value },
        });
        break;
      case "generatedBio":
        dispatch({
          type: actionTypes.UPDATE_FORM_ERROR_STATE,
          payload: { ...formState.errorMessage, generatedBioError: "" },
        });
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, generatedBio: value },
        });
        break;
      default:
        break;
    }
  };

  const setDataForPersona = async (type: string) => {
    try {
      if (isGenerating) {
        return;
      }
      if (
        !formState.data.name ||
        !formState.data.lookingFor ||
        !formState.data.about ||
        !formState.data.notLookingFor
      ) {
        dispatch({
          type: actionTypes.UPDATE_FORM_ERROR_STATE,
          payload: {
            nameError: !formState.data.name ? "Please enter name" : "",
            aboutError: !formState.data.about ? "Please enter about" : "",
            lookingForError: !formState.data.lookingFor
              ? "Please enter lookingFor"
              : "",
            notLookingForError: !formState.data.notLookingFor
              ? "Please enter Not Looking For"
              : "",
          },
        });
      }

      setIsGenerating(type);
      let prompt = `Generate the bio for named ${formState.data.name}.
            About : ${formState.data.about}`;
      if (formState.data?.lookingFor) {
        prompt = prompt + `, Looking For : ${formState.data.lookingFor}`;
      }
      if (formState.data?.notLookingFor) {
        prompt = prompt + `, Not Looking For : ${formState.data.notLookingFor}`;
      }
      if (formState.data?.highlights) {
        prompt = prompt + `, Highlights : ${formState.data.highlights}`;
      }
      if (formState.data?.lowlights) {
        prompt = prompt + `, Lowlights : ${formState.data.lowlights}`;
      }
      const res = await generateDataForPersona(prompt, user?.token);
      handleChangeTextAreaValue(res.data, type);
    } catch (error) {
    } finally {
      setIsGenerating("");
    }
  };

  return (
    <div>
      {avatarPickerOpen ? (
        <BannerPicker
          setCover={setAvatar}
          setCoverLoading={setAvatarLoading}
          setCoverOpen={setAvatarPickerOpen}
          setCoverFile={setAvatarFile}
          generateWithDalle
        />
      ) : (
        <>
          <div className="bg-white dark:bg-navyGray p-4 relative flex space-x-3">
            <div className="w-64">
              {avatar?.urls?.small || !!avatarFile ? (
                <img
                  alt="room"
                  src={
                    avatarFile
                      ? URL.createObjectURL(avatarFile)
                      : avatar?.urls?.small
                  }
                  className="rounded-xl object-cover w-full"
                  loading="lazy"
                />
              ) : (
                <img
                  alt="room"
                  src={`${process.env.PUBLIC_URL}/images/avatar-placeholder.png`}
                  className="rounded-xl object-cover w-full"
                  loading="lazy"
                />
              )}
            </div>
            <div className="">
              <p className="text-base font-medium text-darkNight dark:text-frescoWhite -mt-1">
                Avatar<span className="text-cvRed absolute">*</span>
              </p>
              <p className="text-sm font-normal text-darkGray pb-1">
                This avatar will appear as a profile and thumbnail anytime you
                share a persona.
              </p>

              {avatarLoading ? (
                <div className="centerAbsolute mt-1 text-sm py-2 px-3 font-normal rounded-2xl">
                  <Loader />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    setAvatarPickerOpen(true);
                  }}
                  size="vibhut-sm"
                  variant="vibhut-primary"
                  disabled={Boolean(isGenerating)}
                >
                  Change
                </Button>
              )}
              {formState.errorMessage.avatarError && (
                <p className="text-xs text-cvRed">
                  {formState.errorMessage.avatarError}
                </p>
              )}
            </div>
          </div>
          <div className="px-4 space-y-1.5">
            <InputBox
              type="text"
              primaryLabel="Name"
              required
              value={formState.data.name}
              placeholder="What would you like to call your persona?"
              onChange={(event) => handleChange(event)}
              name="name"
              errorText={formState.errorMessage.nameError}
              customClasses="text-sm"
            />
            <div>
              <div className="flex justify-between">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  About<span className="text-cvRed absolute">*</span>
                </p>
              </div>
              <LimitedTextarea
                limit={500}
                value={formState.data.about}
                placeHolder="Provide details about your persona. How you'd introduce this persona to someone."
                rows={3}
                onChange={(value: string) =>
                  handleChangeTextAreaValue(value, "about")
                }
              />
              {formState.errorMessage.aboutError && (
                <p className="text-xs text-cvRed">
                  {formState.errorMessage.aboutError}
                </p>
              )}
            </div>
            <div>
              <div className="flex justify-between">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Looking For
                </p>
              </div>
              <LimitedTextarea
                limit={500}
                value={formState.data.lookingFor}
                placeHolder="What would you like to talk about more?"
                rows={3}
                onChange={(value: string) =>
                  handleChangeTextAreaValue(value, "lookingFor")
                }
              />
            </div>
            <div>
              <div className="flex justify-between">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Not Looking For
                </p>
              </div>
              <LimitedTextarea
                limit={500}
                value={formState.data.notLookingFor}
                placeHolder="Things your persona would not like to talk about"
                rows={3}
                onChange={(value: string) =>
                  handleChangeTextAreaValue(value, "notLookingFor")
                }
              />
            </div>
            {isEditMode && (
              <div>
                <div className="flex justify-between">
                  <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                    Generated Bio<span className="text-cvRed absolute">*</span>
                  </p>
                  <GenerateTextLabel
                    type="generatedBio"
                    setDataForPersona={setDataForPersona}
                    isGenerating={isGenerating}
                  />
                </div>
                {formState.data.generatedBio &&
                  !checkUpdatePersonaDetailsPermission(role, user) && (
                    <p className="text-darkGray text-xs">
                      Generated Bio, upgrade plan to edit
                    </p>
                  )}
                <LimitedTextarea
                  limit={100}
                  value={formState.data.generatedBio}
                  placeHolder="You can generate an 'Generated Bio' using AI. Modifying the generated description requires the Starter+ plan."
                  rows={3}
                  disabled={!checkUpdatePersonaDetailsPermission(role, user)}
                  onChange={(value: string) =>
                    handleChangeTextAreaValue(value, "generatedBio")
                  }
                />
                {formState.errorMessage.generatedBioError && (
                  <p className="text-xs text-cvRed">
                    {formState.errorMessage.generatedBioError}
                  </p>
                )}
              </div>
            )}
            <div className="flex mt-3 flex-row">
              <div className="w-4/5">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Enable
                </p>
                <p className="text-xs text-gray-400">
                  Users can engage in chat only with enabled personas.
                </p>
              </div>
              <div className="w-1/5 mt-2">
                <Switch
                  checked={formState.data.isEnable}
                  onChange={() =>
                    dispatch({
                      type: actionTypes.UPDATE_FORM_DATA_STATE,
                      payload: {
                        ...formState.data,
                        isEnable: !formState.data.isEnable,
                      },
                    })
                  }
                  className={`${
                    formState.data.isEnable ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      formState.data.isEnable
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>

            <div className="flex mt-3 flex-row">
              <div className="w-4/5">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Include Me
                </p>
                <p className="text-xs text-gray-400">
                  You are included in the chat when any user initiates it
                </p>
              </div>
              <div className="w-1/5 mt-2">
                <Switch
                  checked={formState.data.includeMe}
                  onChange={() =>
                    dispatch({
                      type: actionTypes.UPDATE_FORM_DATA_STATE,
                      payload: {
                        ...formState.data,
                        includeMe: !formState.data.includeMe,
                      },
                    })
                  }
                  className={`${
                    formState.data.includeMe ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      formState.data.includeMe
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
            <div className="flex mt-3 flex-row">
              <div className="w-4/5">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Notify Me
                </p>
                <p className="text-xs text-gray-400">
                  Receive notifications when any user chats with your persona
                </p>
              </div>
              <div className="w-1/5 mt-2">
                <Switch
                  checked={formState.data.notifyMe}
                  onChange={() =>
                    dispatch({
                      type: actionTypes.UPDATE_FORM_DATA_STATE,
                      payload: {
                        ...formState.data,
                        notifyMe: !formState.data.notifyMe,
                      },
                    })
                  }
                  className={`${
                    formState.data.notifyMe ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      formState.data.notifyMe
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
            {(room || (formState.data?.room && isEditMode)) && (
              <div className="flex mt-3 flex-row">
                <div className="w-4/5">
                  <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                    Room Specific Persona
                  </p>
                  <p className="text-xs text-gray-400">
                    This Persona will only to show in the listed rooms. Default
                    it shows in all rooms you subscribe too.
                  </p>
                </div>
                <div className="w-1/5 mt-2">
                  <Switch
                    checked={formState.data.roomSpecific}
                    onChange={() =>
                      dispatch({
                        type: actionTypes.UPDATE_FORM_DATA_STATE,
                        payload: {
                          ...formState.data,
                          roomSpecific: !formState.data.roomSpecific,
                        },
                      })
                    }
                    className={`${
                      formState.data.roomSpecific ? "bg-primary" : "bg-gray-200"
                    } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                  >
                    <span
                      className={`${
                        formState.data.roomSpecific
                          ? "translate-x-6"
                          : "translate-x-1"
                      } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                    />
                  </Switch>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default GeneralTab;
