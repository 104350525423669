import { LinkPreviewResponse, getLinkPreview } from "deso-protocol";
import { useState, useEffect } from "react";
import { extractUrlHostname } from "../../utils/utils";

interface LinkPreviewProps {
  url: string;
  showDescription?: boolean;
  showImage?: boolean;
  isPostLink?: boolean;
}

function LinkPreview({
  url,
  showDescription = false,
  showImage = false,
  isPostLink = false,
}: LinkPreviewProps) {
  const [previewData, setPreviewData] = useState<LinkPreviewResponse | null>(
    null,
  );

  function extractSubUrl(url: string): string {
    const cleanedUrl = url.replace(/^(https?:\/\/)/, "");
    const parts = cleanedUrl.split("/");
    if (parts[parts.length - 1].includes("www.")) {
      return `https://${parts[parts.length - 1]}`;
    }
    return url;
  }

  function getValidUrl(url: string): string {
    let redirectUrl = url;
    if (!url.startsWith("https://")) {
      redirectUrl = "https://" + url;
    }
    return redirectUrl;
  }

  useEffect(() => {
    const fetchLinkPreview = async () => {
      try {
        const response = await getLinkPreview(getValidUrl(url));
        setPreviewData(response);
      } catch (error) {
        console.error("Error fetching link preview:", error);
      }
    };

    fetchLinkPreview();
  }, [url]);

  const handleClick = () => {
    window.open(extractSubUrl(getValidUrl(url)), "_blank");
  };

  if (previewData) {
    return (
      <div
        onClick={handleClick}
        className={`mt-2 flex gap-2 items-center ${
          isPostLink ? "border " : ""
        } border-frescoWhite dark:border-matteGray rounded-lg max-w-lg h-[125px] dark:bg-darkNight bg-[#F4F6F6]`}
        title={extractSubUrl(url)}
      >
        {showImage && previewData.image && (
          <img
            src={previewData.image}
            alt="Link Preview"
            style={{
              minHeight: "124px",
              maxHeight: "124px",
              overflow: "hidden",
              objectFit: "cover",
              borderRadius: "0.5rem 0px 0px 0.5rem",
            }}
            className="w-[30%]"
          />
        )}
        <div
          className={`p-2.5 flex flex-col ${
            showImage && previewData.image ? " w-[70%]" : "w-full"
          }`}
        >
          <p
            className={`text-xs single-line-no-overflow-ellipsis ${
              isPostLink ? "text-darkNight dark:text-frescoWhite" : ""
            }`}
          >
            {extractUrlHostname(url)}
          </p>
          <h3
            className={`single-line-no-overflow-ellipsis text-base ${
              isPostLink ? "text-darkNight dark:text-frescoWhite" : ""
            }`}
          >
            <b>{previewData.title}</b>
          </h3>
          {showDescription && (
            <p className="text-darkGray text-xs break-words custom-third-line-clamp">
              {previewData.description}
            </p>
          )}
        </div>
      </div>
    );
  }

  return <></>;
}

export default LinkPreview;
