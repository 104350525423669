import React, { ChangeEvent, FocusEvent, FC } from "react";

interface InputBoxProps {
  primaryLabel: string;
  secondaryLabel?: string;
  type?: string;
  name: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  errorText?: string;
  required?: boolean;
  customClasses?: string;
  primaryTextColor?: string;
}

const InputBox: FC<InputBoxProps> = ({
  primaryLabel,
  secondaryLabel = "",
  type = "text",
  name,
  value,
  onChange = (event) => {},
  onBlur = () => {},
  placeholder,
  errorText,
  required = false,
  customClasses = "",
  primaryTextColor = "text-darkNight dark:text-frescoWhite",
}) => {
  return (
    <div>
      <label className={`text-sm font-medium ${primaryTextColor}`}>
        {primaryLabel}
        {required ? <span className="text-cvRed absolute">*</span> : null}
      </label>
      <p className="font-normal text-xs text-darkGray">{secondaryLabel}</p>
      <input
        className={`border outline-none ${
          errorText ? "border-red-500" : "border-primaryGray"
        }  py-2 px-4 rounded-xl my-1 w-full ${customClasses}`}
        type={type}
        name={name || ""}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder || ""}
      />
      {errorText ? <p className="text-xs text-cvRed">{errorText}</p> : null}
    </div>
  );
};

export default InputBox;
