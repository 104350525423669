import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { loginWithTwitter } from "../actions/authActions";
import { StoreContext } from "../Context/StoreContext";

export default function LoginSuccessPage() {
  const { dispatch } = useContext(StoreContext);
  const intervalRef = React.useRef(null);
  const history = useHistory();
  const params = useParams();
  const [count, setCount] = React.useState(2);

  const login = React.useCallback(async (token) => {
    await loginWithTwitter(token, dispatch);
    history.push("/");
  }, [dispatch, history]);

  React.useEffect(() => {
    const token = params.token;
    login(token);
    intervalRef.current = window.setInterval(() => {
      if (count <= 0) {
        window.clearInterval(intervalRef.current);
      }
      setCount(count - 1);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-darkNight dark:text-frescoWhite">
      <h1 className=" text-4xl font-bold mb-4">Login Success!</h1>
      <p className="text-lg mb-4">
        Redirecting in <span className="text-red-500">{count}</span> seconds...
      </p>
    </div>
  )
}
