import { Dialog, Transition } from "@headlessui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Fragment, useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";
import Button from "../Button";
import Close from "../Icons/Close";
import Loader from "../Loader";
import { hideUiModal } from "../../actions/uiModalActions";
import { ModalType } from "../../constants";
import CloudSpongeWidget from "../../components/CloudSpongeWidget";
import { inviteUserViaEmailForEvent } from "../../services/eventService";
import { showToast } from "../../actions/toastActions";
import { inviteUserViaEmail } from "../../services/userService";

export default function AddressBookModal() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    globalUiModal: { isOpen, modalType, data },
  } = state;
  const [contactList, setContactList] = useState([]);
  const [currentContacts, setCurrentContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    hideUiModal(dispatch);
  };

  const submitContacts = (contacts) => {
    const _contacts = contacts.map((contact) => contact.__selectedMail__);
    setCurrentContacts(_contacts);
  };

  const removeFromContactList = (email) => {
    let _contactList = [...contactList];
    _contactList = _contactList.filter(function (item) {
      return item !== email;
    });
    setContactList(_contactList);
  };

  useEffect(() => {
    if (currentContacts.length > 0) {
      const totalList = [...contactList, ...currentContacts];
      setContactList([...new Set(totalList)]);
    }
  }, [currentContacts]);

  const resetModal = () => {
    showToast(
      dispatch,
      "success",
      "Invitation has been sent successfully.",
      2000
    );
    setIsLoading(false);
    setContactList([]);
    handleClose();
  };

  const handleInviteSent = async () => {
    try {
      setIsLoading(true);
      switch (data?.modalType) {
        case "event":
          await inviteUserViaEmailForEvent(
            {
              note: "",
              eventId: data._id,
              emails: contactList.join(","),
            },
            user?.token
          );
          resetModal();
          break;

        case "vibehut-invite":
          await inviteUserViaEmail(
            {
              note: "",
              emails: contactList.join(","),
            },
            user?.token
          );
          resetModal();
          break;
        default:
          break;
      }
    } catch (error) {
      showToast(
        dispatch,
        "Error",
        "Facing Error while sending Invitation, Please again later",
        2000
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalTitle = useCallback(() => {
    switch (data?.modalType) {
      case "event":
        return `Invite to ${data?.title}`;
      case "vibehut-invite":
        return "Invite people to Vibehut";
      default:
        return "";
    }
  }, [data?.modalType, data?.title]);

  return (
    <>
      <Transition
        appear
        show={isOpen && modalType === ModalType.ADDRESSBOOK}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu ">
                <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite tracking-wide">
                      {renderModalTitle()}
                    </p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={handleClose}
                    >
                      <Close />
                    </span>
                  </div>
                </div>

                <div className="px-4 py-4 max-h-80 custom-scrollbar overflow-y-auto">
                  <div className="flex flex-col pl-2 pr-2">
                    <div className="pb-3">
                      <CloudSpongeWidget
                        cloudspongeKey={process.env.REACT_APP_CLOUD_SPONGE_KEY}
                        options={{
                          afterSubmitContacts: submitContacts,
                        }}
                      >
                        <div className="flex justify-center gap-4">
                          <Button disabled={isLoading}>
                            <p
                              className="cloudsponge-launch"
                              data-cloudsponge-source="gmail"
                            >
                              {" "}
                              Gmail Contacts
                            </p>
                          </Button>
                          <Button variant="secondary" disabled={isLoading}>
                            <p className="cloudsponge-launch">Address Book</p>
                          </Button>
                        </div>
                      </CloudSpongeWidget>
                    </div>
                    <div className="flex gap-4 flex-col">
                      {contactList.map((email) => {
                        return (
                          <div
                            className="flex items-center justify-between"
                            key={email}
                          >
                            <p
                              className="
                                text-darkNight
                                dark:text-frescoWhite"
                            >
                              {email}
                            </p>
                            <Button
                              variant="danger"
                              onClick={() => removeFromContactList(email)}
                            >
                              Remove
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className=" px-4 py-3 flex flex-row-reverse ">
                  <div className="mr-4">
                    <Button
                      onClick={handleClose}
                      size="vibhut-sm"
                      variant="vibhut-secondary"
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                  {contactList.length > 0 && (
                    <div className="mr-4">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          onClick={handleInviteSent}
                          size="vibhut-sm"
                          variant="vibhut-primary"
                          disabled={isLoading}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
