import axios from "../utils/axios";

export const addMoney = async (authToken, addAmount) => {
  const data = {
    testPayment: "true",
    amount: addAmount,
    currency: "usd",
    url: window.location.href,
  };
  axios
    .post(`/payment/initiate-stripe-transaction`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(res => {
      if (res.status === 200) {
        window.location.href = res.data.data.url;
      } else {
        console.log(res);
      }
    });
};

export const eventTransaction = async (
  authToken,
  addAmount,
  eventId,
  type,
  title,
  promoCode,
) => {
  const data = {
    testPayment: "true",
    amount: addAmount,
    currency: "usd",
    url: window.location.href,
    eventId: eventId,
    type: type,
    title: title,
    kind: "eventSubscription",
    promoCode: promoCode,
  };
  axios
    .post(`/payment/initiate-event-stripe-transaction`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(res => {
      if (res.status === 200) {
        window.location.href = res.data.data.url;
        return true;
      } else {
        console.log(res);
      }
    });
};

export const roomTransaction = async (
  authToken,
  addAmount,
  roomId,
  roomName,
  roomOwner,
) => {
  const data = {
    testPayment: "true",
    amount: addAmount,
    currency: "usd",
    url: window.location.href,
    roomId: roomId,
    roomName: roomName,
    roomOwner,
    kind: "roomSubscription",
  };
  axios
    .post(`/payment/initiate-room-stripe-transaction`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(res => {
        window.location.href = res.data.data.url;
        return true;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const initiateAccessPassPayment = async (
  authToken,
  addAmount,
  roomId,
  type,
  title,
) => {
  const data = {
    testPayment: "true",
    amount: addAmount,
    currency: "usd",
    url: window.location.href,
    roomId: roomId,
    type: type,
    product: title,
    kind: "accessPass",
  };
  axios
    .post(`/payment/initiate-access-pass-stripe-transaction`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(res => {
      if (res.status === 200) {
        window.location.href = res.data.data.url;
        return true;
      } else {
        console.log(res);
      }
    });
};
