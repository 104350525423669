import { baseURL } from '../constants';

export async function getApprovalRequestsOfUserByEvent(eventId: string, token: string): Promise<any> {
  try {
    const response = await fetch(`${baseURL}/api/v1/eventApproval/user/${eventId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in getApprovalRequestsOfUserByEvent:', error);
    throw error;
  }
}
