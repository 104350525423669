import { useCallback, useEffect, useRef } from "react";

const CloudSpongeWidget = ({ cloudspongeKey, options, children }) => {
  const scriptLoaded = useRef(false);

  const addJavascript = (src, id, callback) => {
    if (id && document.getElementById(id)) {
      callback && callback();
      return;
    }

    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.async = 1;
    if (id) {
      scriptTag.id = id;
    }

    if (callback) {
      if (scriptTag.readyState) {
        scriptTag.onreadystatechange = () => {
          if (
            scriptTag.readyState === "loaded" ||
            scriptTag.readyState === "complete"
          ) {
            scriptTag.onreadystatechange = null;
            callback();
          }
        };
      } else {
        scriptTag.onload = () => {
          scriptLoaded.current = true;
          callback();
        };
      }
    }

    scriptTag.src = src;
    document.body.appendChild(scriptTag);
  };

  const initializeCloudSponge = useCallback(() => {
    addJavascript(
      `https://api.cloudsponge.com/widget/${cloudspongeKey}.js`,
      "__cloudsponge_widget_script",
      () => {
        if (window.cloudsponge) {
          window.cloudsponge.init(options);
        }
      }
    );
  },[]);

  useEffect(() => {
    initializeCloudSponge();
  }, []);

  useEffect(() => {
    return () => {
      if (window.cloudsponge && scriptLoaded.current) {
        window.cloudsponge.end();
      }
    };
  }, []);

  return children;
};

export default CloudSpongeWidget;
