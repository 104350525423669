import React, { useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";
import * as actionTypes from "../../constants";
import InputBox from "../../VibehutUI/InputBox/inputBox";

function CompanyAffiliation() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    persona: { formState },
  } = state;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: actionTypes.UPDATE_FORM_ERROR_STATE,
      payload: { ...formState.errorMessage, companyAffiliationError: "" },
    });
    dispatch({
      type: actionTypes.UPDATE_FORM_DATA_STATE,
      payload: {
        ...formState.data,
        companyAffiliation: {
          ...formState.data.companyAffiliation,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleCompanyLogoChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      dispatch({
        type: actionTypes.UPDATE_FORM_ERROR_STATE,
        payload: { ...formState.errorMessage, companyAffiliationError: "" },
      });
      const file = event.target.files[0];
      if (file) {
        dispatch({
          type: actionTypes.UPDATE_FORM_ERROR_STATE,
          payload: { ...formState.errorMessage, avatarError: "" },
        });
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: {
            ...formState.data,
            companyAffiliation: {
              ...formState.data.companyAffiliation,
              imgUrl: file,
            },
          },
        });
      }
    }
  };

  return (
    <div className="border dark:border-matteGray border-frescoWhite rounded py-4">
      {formState.errorMessage.companyAffiliationError && (
        <p className="text-sm text-cvRed px-4 pb-2">
          {formState.errorMessage.companyAffiliationError}
        </p>
      )}
      <div className="bg-white dark:bg-navyGray px-4 relative">
        <div className="flex gap-4 items-center pb-6">
          <div className="">
            <img
              alt="room"
              src={
                formState.data?.companyAffiliation?.imgUrl?.name
                  ? URL.createObjectURL(
                      formState.data.companyAffiliation.imgUrl,
                    )
                  : formState.data?.companyAffiliation?.imgUrl
                    ? formState.data.companyAffiliation.imgUrl
                    : `${process.env.PUBLIC_URL}/ImagePlaceholder.png`
              }
              className="rounded-xl object-cover w-28 h-full"
              loading="lazy"
            />
          </div>
          <div className="">
            <p className="text-base font-medium text-darkNight  dark:text-frescoWhite pb-2">
              Company Logo
            </p>
            <div className="flex">
              <input
                id="files"
                type="file"
                onChange={handleCompanyLogoChange}
                accept="image/png, image/gif, image/jpeg, image/webp"
                className="hidden w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-primary hover:file:bg-violet-100 cursor-pointer"
              />
              <label
                htmlFor="files"
                className=" text-sm bg-frescoWhite py-2 px-3 font-bold text-navyGray rounded-2xl text-center cursor-pointer"
              >
                Change
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 space-y-2">
        <InputBox
          type="text"
          primaryLabel="Name"
          value={formState.data.companyAffiliation.title}
          placeholder="Company Name"
          onChange={(event) => handleChange(event)}
          name="title"
          customClasses="text-sm"
        />
        <InputBox
          type="text"
          primaryLabel="Link"
          value={formState.data.companyAffiliation.link}
          placeholder="Link"
          onChange={(event) => handleChange(event)}
          name="link"
          customClasses="text-sm"
        />
      </div>
    </div>
  );
}

export default CompanyAffiliation;
