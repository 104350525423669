import React from "react";
import RoomIcon from "./Icons/RoomIcon";
import EventIcon from "./Icons/EventIcon";
import WaveIcon from "./Icons/WaveIcon";
import DeSo from "./Icons/DeSo";
import { NotificationTypes } from "../constants";

function Avatar({
  src,
  size = "lg",
  userState=null,
  username,
  containerStyle={},
  notification = null,
  widthClassName = "",
  heightClassName = "",
  isDesoCard = false,
  NotificationType = "General",
}) {
  let color = "";
  if (userState?.status === "online") {
    color = "primaryGreen";
  } else if (userState?.status === "free to talk") {
    color = "primary";
  } else if (userState?.status === "oncall") {
    color = "blue";
  } else if (userState?.status === "offline") {
    color = "darkGray";
  }
  const [srcError, setSrcError] = React.useState(false);
  return (
    <div style={containerStyle} className="relative rounded-full">
      {isDesoCard ? (
        <div
          className={`avatar-${size} rounded-full bg-transparent flex justify-center items-center  ${widthClassName} ${heightClassName}`}
        >
          <DeSo ratio={24} />
        </div>
      ) : src?.length > 0 ? (
        srcError ? (
          <div
            className={`avatar-${size} rounded-full bg-primary flex justify-center items-center  ${widthClassName} ${heightClassName}`}
          >
            <span className="text-white text-lg">
              {username?.charAt(0)?.toUpperCase()}
            </span>
          </div>
        ) : (
          <div
            className={`avatar-${size} ${widthClassName} ${heightClassName}`}
          >
            <img
              alt={username}
              onError={(e) => {
                e.target.onerror = null;
                setSrcError(true);
              }}
              src={src}
              className={`${
                NotificationType === "event"
                  ? "h-10 w-10 rounded"
                  : `avatar-${size} rounded-full`
              }   ${widthClassName} ${heightClassName}`}
              loading="lazy"
            />
          </div>
        )
      ) : (
        <div
          className={`avatar-${size} rounded-full bg-primary flex justify-center items-center  ${widthClassName} ${heightClassName}`}
        >
          <span className="text-white text-lg">
            {username?.charAt(0)?.toUpperCase()}
          </span>
        </div>
      )}

      {notification &&
        (notification === NotificationTypes.ROOM_UPDATE ||
          notification === NotificationTypes.USER_JOINED_ROOM ||
          notification === NotificationTypes.CALL_CONNECTED_IN_ROOM ||
        notification === NotificationTypes.COMPOSITION_CREATED ||
        notification === NotificationTypes.TRANSCRIPT_CREATED ||
          notification === NotificationTypes.INVITE_USER) && (
          <div className="absolute top-5  p-0.5 left-6">
            <RoomIcon />
          </div>
        )}
      {notification &&
        (notification === NotificationTypes.USER_RSVP ||
          notification === NotificationTypes.RSVP_REMINDER ||
          notification ===
            NotificationTypes.SUBSCRIBED_ROOM_EVENT_STARTED_IN_10) && (
          <div className="absolute top-5  p-0.5 left-6">
            <EventIcon />
          </div>
        )}
      {notification &&
        (notification === "waved" ||
          notification === "waved_back" ||
          notification === "waved_joined") && (
          <div className="absolute top-5  p-0.5 left-6">
            <WaveIcon />
          </div>
        )}
      {userState ? (
        <div className="bg-white absolute -bottom-1 p-0.5 right-0 h-2.5 w-2.5  my-1 rounded-full">
          <div
            style={
              color === "blue"
                ? {
                    background:
                      "linear-gradient(180deg, #716DFF 0%, #20DEFB 100%)",
                  }
                : {}
            }
            className={`h-1.5 rounded-full w-1.5 bg-${color} z-2`}
          ></div>
        </div>
      ) : undefined}
    </div>
  );
}

export default Avatar;
