import axios from "../utils/axios";
import Axios from "axios";
import {
  getMetamaskUserProfileImage,
  getMetamaskUsername,
} from "../utils/utils";

export function getRecentCalls(page, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user/get-recent-calls?limit=10&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCallStreak(token, timezone, streakMode) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/user/get-calls-streak?timezone=${timezone}&streakMode=${streakMode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateMetamskEns(ens, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/user/update-ens",
        { ens },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateProfile(profile, token) {
  return new Promise(function (resolve, reject) {
    axios
      .patch("/user", profile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCoinPrice({ username, publicKey, source }) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/bitclout/get-creator-coin-price",
        {
          username: username,
          publicKey: publicKey,
        },
        {
          cancelToken: source?.token,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) return;
        reject(error);
      });
  });
}

export function searchUsers(keyword) {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/search-users?s=${keyword}`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        console.log(res);
        reject(res.data.message);
      }
    });
  });
}

export function getUserByUserName(username) {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/user-by-username?username=${username}`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        console.log(res);
        reject(res.data.message);
      }
    });
  });
}

export function getLoggedInUserDataService(userId) {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/${userId}`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        console.log(res);
        reject(res.data.message);
      }
    });
  });
}

export function searchVibhutAndDesoUsers(keyword) {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/search-invite-users?s=${keyword}`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        reject(res.data.message);
      }
    });
  });
}

export function getRelevantUsers(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/user/get-relevant-user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getOnCallUsers() {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/get-on-call-users`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        console.log(res);
        reject(res.data.message);
      }
    });
  });
}

export function getLastActiveUsers(offset) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user/get-last-active-users?limit=15&offset=${offset}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          console.log(res);
          reject(res.data.message);
        }
      });
  });
}

export function getUserPublicProfile(userId) {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/profile/${userId}`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        console.log(res);
        reject(res.data.message);
      }
    });
  });
}

export function getUserProfileByUsernameOrId(username) {
  return new Promise(function (resolve, reject) {
    axios.get(`/user/profile-name/${username}`).then((res) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        console.log(res);
        reject(res.data.message);
      }
    });
  });
}

export function getUserData(user) {
  switch (user?.provider) {
    case "bitclout":
      return {
        ...user.bitclout_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
        vibehutDescription: user?.vibehutDescription,
        serviceRole: user.serviceRole,
      };
    case "twitter":
      return {
        ...user.twitter_data,
        profile_picture:
          user.twitter_data?.profile_picture.replace("normal", "400x400") || "",
        _id: user._id,
        last_seen: user.last_seen,
        wallet_balance: user.wallet_balance,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    case "instagram":
      return {
        ...user.instagram_data,
        _id: user._id,
        last_seen: user.last_seen,
        wallet_balance: user.wallet_balance,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    case "tiktok":
      return {
        ...user.tiktok_data,
        _id: user._id,
        last_seen: user.last_seen,
        wallet_balance: user.wallet_balance,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    case "facebook":
      return {
        ...user.facebook_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    case "google":
      const profile_picture = user?.google_data?.profile_picture.replace(
        "s96-c",
        "s256-c",
      );
      return {
        ...user.google_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
        profile_picture,
      };
    case "metamask":
      return {
        ...user.metamask_data,
        provider: "metamask",
        username: user.username || getMetamaskUsername(user.metamask_data),
        publickey: null,
        profile_picture: getMetamaskUserProfileImage(user.metamask_data),
        _id: user._id,
        last_seen: user.last_seen,
        wallet_balance: user.wallet_balance,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        fullAddress:
          user.username ||
          (user.metamask_data?.ens
            ? user.metamask_data.ens
            : user.metamask_data?.wallet_address),
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
        farcaster: user?.metamask_data?.farcaster,
      };
    case "linkedin":
      return {
        ...user.linkedin_data,
        _id: user._id,
        last_seen: user.last_seen,
        wallet_balance: user.wallet_balance,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    case "worldcoin":
      return {
        ...user.worldcoin_data,
        username: user.worldcoin_data?.username.slice(0, 15) + "...",
        _id: user._id,
        last_seen: user.last_seen,
        wallet_balance: user.wallet_balance,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    case "guest":
      return {
        ...user.guest_user_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        provider: user.provider,
        vibehutDescription: user?.vibehutDescription,
        vibehutUsername: user?.vibehutUsername,
        serviceRole: user.serviceRole,
      };
    default:
      if (user?.custom_wallet_data) {
        return {
          ...user?.custom_wallet_data,
          _id: user._id,
          last_seen: user.last_seen,
          cover: user.cover,
          socialLinks: user.socialLinks,
          userStatus: user.userState?.status || "offline",
          provider: user.provider,
          vibehutDescription: user?.vibehutDescription,
          vibehutUsername: user?.vibehutUsername,
          serviceRole: user.serviceRole,
          username:
            user?.custom_wallet_data?.wallet_address?.slice(0, 15) + "...",
        };
      }
      return {};
  }
}

export function reportUser(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/report", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function reportBug(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/bug-reports", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateUserState(token, status) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/user/update-state",
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function countUserMissedCalls(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user/get-missed-calls-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateUserCallsSeenStatus(token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/user/update-user-calls-seen-status",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserCallHistoryData(daysDifference, token, userId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/callHistory/get-call-history-by-userId?userId=${userId}&days=${daysDifference}`,
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateAppSettings(body, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/user/update-app-settings`, body, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getLeaderboardUsersCalls(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/leaderboard-users-call", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getLeaderboardRoomSubscribers(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/leaderboard-subscribers", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getLeaderboardChat(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/leaderboard-chat", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomCalls(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/leaderboard-room-calls", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSidebarCalls(jwt) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user/get-sidebar-calls`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRecentRecordedCalls(page, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user/get-recent-recorded-calls?limit=10&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getThanksCalls(page, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user/get-thanks-calls?limit=10&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function reportUserPost(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user-post-report", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserPostLikes(postIdOrPostHashHex, isDesoPost, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/user-posts/${postIdOrPostHashHex}/likes?isDesoPost=${isDesoPost}&limit=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteUserViaEmail(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/invite-user", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
