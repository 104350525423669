import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useContext } from "react";
import Close from "../Icons/Close";
import { searchUsers } from "../../services/userService";
import useDebounce from "../../hooks/useDebounce";
import NoResult from "../Icons/NoResult";
import BlockSearchBar from "./BlockSearchBar";
import { StoreContext } from "../../Context/StoreContext";

export default function BlockUserModal({
  isOpen,
  setIsOpen,
  roomId,
  ownerId,
  sendInviteInstantNotification,
}) {
  const { state } = useContext(StoreContext);
  const { auth } = state;
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchUsers(searchTerm)
        .then((response) => {
          const users = response.data.users;
          const filterUsers = users.filter(
            (user) => user._id !== ownerId && user?._id !== auth?.user?._id
          );
          setSearchResults(filterUsers);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[428px] h-full font-ubuntu">
                <div className="bg-white  p-3 border-b border-frescoWhite ">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black  tracking-wide">
                      All People
                    </p>
                    <span
                      className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="px-6 pt-3 h-96  custom-scrollbar">
                  <div>
                    <BlockSearchBar
                      handleChange={handleChange}
                      results={searchResults}
                      value={searchTerm}
                      roomId={roomId}
                      sendInviteInstantNotification={
                        sendInviteInstantNotification
                      }
                      setValue={setSearchTerm}
                      ownerId={ownerId}
                    />
                  </div>
                  {searchTerm.trim().length === 0 && (
                    <div className="relative h-72 w-full ">
                      <div className="absolute top-1/2 transform -translate-y-1/2 z-50 left-1/2 -translate-x-1/2 w-full">
                        <div>
                          <div className="flex justify-center">
                            <NoResult />
                          </div>

                          <p className="text-black text-center text-md  font-ubuntu pt-2 w-full">
                            Type to search people to block from this room
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
