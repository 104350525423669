import useDevices from '../../hooks/useDevices/useDevices';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

interface Props {
  error?: Error;
  isToolTipDismissed?: boolean;
  setIsToolTipDismissed: React.Dispatch<React.SetStateAction<boolean>>;
}

function MediaErrorToolTip({ error, isToolTipDismissed, setIsToolTipDismissed }: Props) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();
  const isOpen =
    !isToolTipDismissed &&
    !isAcquiringLocalTracks &&
    Boolean(error) &&
    (!hasAudioInputDevices || !hasVideoInputDevices);

  const renderError = () => {
    if (error?.message === 'CameraPermissionsDenied') {
      return 'Enable Camera';
    } else if (error?.message === 'MicrophonePermissionsDenied') {
      return 'Enable Mic';
    } else if (error?.name === 'NotAllowedError') {
      return 'Enable Mic and Camera';
    }
  };
  const renderErrorDescription = () => {
    if (error?.message === 'CameraPermissionsDenied') {
      return 'Please manually camera permission in your browser settings';
    } else if (error?.message === 'MicrophonePermissionsDenied') {
      return 'Please manually enable mic permission in your browser settings';
    } else if (error?.name === 'NotAllowedError') {
      return 'Please manually enable mic and camera permissions in your browser settings';
    }
  };
  return (
    <div className={`absolute z-50 -top-36 ${isOpen ? 'md:block hidden' : 'hidden'}`}>
      <div className="relative -ml-[170px] mb-4">
        <div className="bg-white text-xs py-1 right-0 bottom-full w-60 rounded-lg">
          <div>
            <div className="flex justify-between items-center py-2 px-4">
              <p className="font-medium text-lg text-black flex items-center space-x-2">
                <span>{renderError()}</span>
              </p>
              <span
                className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                onClick={() => setIsToolTipDismissed(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-600"
                  fill="none"
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
            </div>
            <div className="w-full  border-b border-frescoWhite"></div>
            <div className="py-2 px-4">
              <p className="text-sm text-darkGray font-normal text-left">{renderErrorDescription()}</p>
            </div>
          </div>
          <svg
            className="absolute text-white h-6 w-full left-0 top-full"
            x="0px"
            y="0px"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 255 255"
          >
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default MediaErrorToolTip;
