import * as actionTypes from "../constants";
import * as accessPassService from "../services/accessPassService";

export const getAccessPasses = (token, dispatch) => {
  dispatch({ type: actionTypes.GET_ACCESS_PASSES_REQUEST });
  accessPassService
    .getAccessPasses(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ACCESS_PASSES_SUCCESS,
        payload: { data: res.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ACCESS_PASSES_FAILURE,
        payload: { error: err },
      });
    });
};
