import { makeStyles, createStyles } from "@material-ui/core/styles";

import useVibehutChatContext from "../../../hooks/useVibehutChatContext/useVibehutChatContext";
import LeftArrow from "../../../VibehutUI/Icons/LeftArrow";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "56px",
      background: "#303234",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0 0.8em",
    },
    text: {
      marginLeft: "10px",
      color: "#F2F2F2",
      fontWeight: "bold",
      fontSize: "16px",
    },
    closeChatWindow: {
      cursor: "pointer",
      display: "flex",
      background: "transparent",
      border: "0",
      padding: "0.4em",
    },
  }),
);

export default function ChatWindowHeader({ isLive }: { isLive: boolean }) {
  const classes = useStyles();
  const { setIsChatWindowOpen, setIsLiveStreamChatWindowOpen } =
    useVibehutChatContext();

  return (
    <div className={classes.container}>
      <button
        type="button"
        onClick={() => {
          if (isLive) {
            setIsLiveStreamChatWindowOpen(false);
            return;
          }
          setIsChatWindowOpen(false);
        }}
      >
        <LeftArrow backgroundColor="#474747" forgroundColor="#B5B5B5" />
      </button>
      <div className={classes.text}>{isLive ? "Live Chat" : "Chat"}</div>
    </div>
  );
}
