import React, { useCallback, useContext, useMemo, useState } from "react";
import ChatBubble from "../../../VibehutUI/ChatBubble";
import { StoreContext } from "../../../Context/StoreContext";
import {
  countUnreadMessages,
  getChatByRoomId,
} from "../../../actions/chatActions";
import RoomChatBubble from "../../../VibehutUI/RoomChatBubble";
import { updateMessageReadStatus } from "../../../services/chatService";
import Button from "../../../VibehutUI/Button";
import GroupRoomMessageStatus from "../../../VibehutUI/GroupRoomMessageStatus";
import ChatSkeleton from "../../../VibehutUI/Skeletons/ChatSkeleton";
import MComposer from "../../../VibehutUI/MessageComposer/MComposer";
import * as actionTypes from "../../../constants/actionTypes";
import { encryptMsg } from "../../../utils/AES";
import RoomModel from "../../../VibehutUI/RoomModal";
import PasswordIcon from "../../../VibehutUI/Icons/PasswordIcon";
import Polygon from "../../../VibehutUI/Icons/Polygon";
import {
  checkMyRoomSubscription,
  isMobile,
  renderMessageText,
} from "../../../utils/utils";
import Subscriptions from "../../../VibehutUI/Icons/Subscriptions";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import ProfileModal from "../../../VibehutUI/ProfileModal";
import { specificChatType } from "../../../constants";
import { updateRoomChattingUsers } from "../../../services/rooomService";
import Axios from "axios";
import DoubleStar from "../../../VibehutUI/Icons/DoubleStar";

function Chat({
  sendMessage,
  roomId = undefined,
  deleteMessage,
  editMessage,
  addReaction,
  removeUserOrAddReaction,
  addUserToReaction,
  isModal = false,
  chatType = "",
}) {
  const source = Axios.CancelToken.source();
  const messagesEnd = React.useRef(null);
  const messagesStart = React.useRef(null);
  const [profileUser, setProfileUser] = useState({});
  const [isLoadMoreButtonClicked, setisLoadMoreButtonClicked] = useState(false);
  const {
    state: {
      chat: {
        magData,
        isMore,
        isMoreFetching,
        offset,
        isChatLoading,
        canScroll,
        isAnimatedScroll,
      },
      auth: { user },
      room: { chatRoom, isFetchingChatRoom },
      subscription: { mySubscriptions },
    },
    dispatch,
  } = useContext(StoreContext);
  const [chatId, setChatId] = useState(undefined);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [roomPassword, setRoomPassword] = useState("");
  const [attachedMessage, setAttachedMessage] = useState(null);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [currentInputMessageLength, setCurrentInputMessageLength] = useState(0);
  let name;

  React.useEffect(() => {
    if (isLoadMoreButtonClicked) {
      if (messagesStart.current) {
        messagesStart.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      if (messagesEnd.current && canScroll) {
        const scrollOption = isAnimatedScroll ? { behavior: "smooth" } : {};
        setTimeout(() => {
          messagesEnd.current?.scrollIntoView(scrollOption);
        }, 100);
      }
      if (!canScroll) {
        dispatch({
          type: actionTypes.TOGGLE_SCROLL,
          payload: true,
        });
      }
      if (!isAnimatedScroll) {
        dispatch({
          type: actionTypes.TOGGLE_IS_ANIMATED_SCROLL,
          payload: true,
        });
      }
    }
  }, [magData, isLoadMoreButtonClicked]);

  useEffect(() => {
    return () => {
      source.cancel();
      dispatch({
        type: actionTypes.GET_CHAT_ROOM_SUCCESS,
        payload: {
          chatRoom: undefined,
        },
      });
      dispatch({
        type: actionTypes.RESET_MSG_DATA,
      });
    };
  }, []);

  React.useEffect(() => {
    if (chatRoom) handleGetRoomChat();
    setAttachedMessage(null);
    return () => source.cancel();
  }, [isPasswordVerified, chatRoom]);

  const handleGetRoomChatFailure = () => {
    dispatch({
      type: actionTypes.GET_ROOM_MESSAGES_FAILURE,
    });
  };

  const renderDate = (date) => {
    const currentDate = new Date().toLocaleString().split(",")[0];
    var yesterdayDate = new Date(Date.now() - 864e5)
      .toLocaleString()
      .split(",")[0];

    if (currentDate === date) {
      return "Today";
    } else if (yesterdayDate === date) {
      return "Yesterday";
    } else {
      return date;
    }
  };

  const GetMessageStatus = ({ chatId }) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setChatId(chatId);
            setIsOpenStatus(true);
          }}
        >
          Status ·
        </span>
      </>
    );
  };

  const checkForDesoRoom = () => {
    if (chatRoom?.isDesoPostRoomNotAccessible) {
      return true;
    }
    return false;
  };

  const isChatRoomOwner = useMemo(() => {
    return roomId && chatRoom?.owner?._id === user?._id;
  }, [chatRoom?.owner?._id, roomId, user?._id]);

  const handleGetRoomChat = () => {
    if (isChatRoomOwner) {
      getChatByRoomIdFun();
    }
    if (checkForPassword()) {
      handleGetRoomChatFailure();
      setIsOpenPassword(true);
    } else if (checkForNftAccessable()) {
      handleGetRoomChatFailure();
      return;
    } else if (checkForBlocked()) {
      handleGetRoomChatFailure();
      return;
    } else if (checkForSubscribedUser()) {
      handleGetRoomChatFailure();
      return;
    } else if (checkForIsPaidRoomNotSubscribed()) {
      handleGetRoomChatFailure();
      return;
    } else if (checkForDesoRoom()) {
      handleGetRoomChatFailure();
    } else {
      getChatByRoomIdFun();
    }
  };

  const renderChatBottom = (msg, index, msgArray) => {
    const secondMsg = msgArray[index + 1];
    if (secondMsg?.isSend === msg.isSend) {
      if (secondMsg?.createdAt !== msg.createdAt) {
        return (
          <div
            className={`text-darkGray flex items-center pt-0.5 gap-1 ${
              msg.isSend ? "justify-end" : "justify-start"
            }`}
          >
            {!msg.deletedAt && (
              <div className="text-xs">
                {chatRoom?.isChatRoom ? (
                  msg.isSend ? (
                    msg.isRead && msg.isDelivered ? (
                      "Seen ·"
                    ) : msg.isDelivered ? (
                      "Delivered ·"
                    ) : (
                      "Sent ·"
                    )
                  ) : (
                    ""
                  )
                ) : msg.isSend ? (
                  <GetMessageStatus chatId={msg._id} />
                ) : (
                  ""
                )}
              </div>
            )}
            <div className={` text-xs  font-ubuntu  text-darkGray `}>
              {msg.createdAt}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div
          className={`text-darkGray flex items-center pt-0.5 gap-1 ${
            msg.isSend ? "justify-end" : "justify-start"
          } mb-3`}
        >
          {!msg.deletedAt && (
            <div className="text-xs">
              {chatRoom?.isChatRoom ? (
                msg.isSend ? (
                  msg.isRead && msg.isDelivered ? (
                    "Seen ·"
                  ) : msg.isDelivered ? (
                    "Delivered ·"
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : msg.isSend ? (
                <GetMessageStatus chatId={msg._id} />
              ) : (
                ""
              )}
            </div>
          )}
          <div className={` text-xs  font-ubuntu  text-darkGray `}>
            {msg.createdAt}
          </div>
        </div>
      );
    }
  };
  const openUserModal = (user) => {
    setProfileUser(user);
  };
  const closeUserModal = () => {
    setProfileUser({});
  };
  const renderGroupChat = (msgArray) => {
    return (
      <>
        {msgArray.map((msg, index) => {
          if (
            (msg.isAmiRequest && !msg?.deletedAt) ||
            msg?.messageType === "subscribeToRoom"
          ) {
            return (
              <p
                className="text-center text-darkGray text-sm pb-4"
                key={msg._id}
              >
                {renderMessageText(msg, openUserModal)}
              </p>
            );
          }
          let hidden;
          if (name) {
            hidden = name === msg.username ? "hidden" : "";
          } else {
            hidden = "";
          }

          const usernameDirection = msg.isSend
            ? "text-right pr-1"
            : `text-left pl-1`;
          if (!msg.isBot) {
            name = msg.username;
          }
          return (
            <div
              key={msg._id}
              className={`flex flex-col mt-2 ${roomId ? "pl-1" : ""} `}
            >
              <div
                className={`mb-0.5 text-xs  ${
                  msg.isAmiBot || msg.isPersonaChat ? "" : hidden
                } font-ubuntu text-darkGray ${usernameDirection}`}
              >
                {!chatRoom.isChatRoom ? (
                  msg.isSend ? (
                    msg.isBot ? (
                      <span className="">
                        vibehut
                        <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                          Bot
                        </span>
                      </span>
                    ) : (
                      "You"
                    )
                  ) : msg.isAmiBot ? (
                    <span className="">
                      Ami
                      <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                        Bot
                      </span>
                    </span>
                  ) : msg.isPersonaChat ? (
                    <span className="">
                      {chatRoom.name}
                      <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                        Persona
                      </span>
                    </span>
                  ) : (
                    msg.isBot && (
                      <span className="">
                        vibehut
                        <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                          Bot
                        </span>
                      </span>
                    )
                  )
                ) : (
                  msg.isAmiBot && (
                    <span className="">
                      Ami
                      <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                        Bot
                      </span>
                    </span>
                  )
                )}
              </div>
              {roomId && (msg.message || msg?.attachments?.length > 0) ? (
                <>
                  {!msg?.isAmiRequest && (
                    <>
                      <RoomChatBubble
                        message={msg.message}
                        isSend={msg.isSend}
                        chat={msg}
                        setAttachedMessage={setAttachedMessage}
                        deleteMessage={deleteMessage}
                        editMessage={editMessage}
                        addReaction={addReaction}
                        removeUserOrAddReaction={removeUserOrAddReaction}
                        addUserToReaction={addUserToReaction}
                        isAmiBot={msg.isAmiBot}
                        isPersonaChat={msg.isPersonaChat}
                        username={
                          !msg.isAmiBot && !msg.isBot && !msg.isPersonaChat
                            ? `${msg.username} ${
                                msg.isGuest ? "· (Guest)" : ""
                              }`
                            : ""
                        }
                        isGroupChat={!chatRoom?.isChatRoom}
                        roomId={roomId}
                        isModal={isModal}
                      />
                      {renderChatBottom(msg, index, msgArray)}
                    </>
                  )}
                </>
              ) : (
                msg.message && (
                  <ChatBubble
                    message={msg.message}
                    isSend={msg.isSend}
                    isDanger={msg.isDanger}
                  />
                )
              )}
            </div>
          );
        })}
      </>
    );
  };
  const renderChats = () => {
    const grouped = Object.values(
      magData.reduce((acc, item) => {
        acc[item.date] = [...(acc[item.date] || []), item];
        return acc;
      }, {}),
    );

    return grouped.map((msgArray, index) => {
      const date = msgArray[0].date;
      return (
        <div key={date + index}>
          <p className="text-darkGray text-center mt-2">{renderDate(date)}</p>
          <>{renderGroupChat(msgArray)}</>
        </div>
      );
    });
  };

  const getChatByRoomIdFun = async () => {
    try {
      if (user?.token && chatRoom) {
        await updateMessageReadStatus(roomId, user?.token);
        dispatch({
          type: "UPDATE_UNREAD_MESSAGE",
          payload: {
            roomId: chatRoom?._id,
          },
        });
      }

      await getChatByRoomId(
        roomId,
        user?.token,
        dispatch,
        user?._id,
        false,
        0,
        source,
      );
      await countUnreadMessages(user?.token, dispatch);
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkForPassword = useCallback(() => {
    if (!chatRoom?.last_chat) {
      return false;
    }
    const givenDate = new Date(chatRoom?.last_chat.createdAt);
    const currentTime = new Date();
    const timeDiffInMinutes = Math.floor((currentTime - givenDate) / 60000);
    return (
      roomId &&
      chatRoom?.isPasswordProtected &&
      !isPasswordVerified &&
      chatRoom?.owner?._id !== user?._id &&
      timeDiffInMinutes >= 10 &&
      user?._id !== chatRoom?.last_chat?.userId
    );
  }, [
    chatRoom?.isPasswordProtected,
    chatRoom?.last_chat,
    chatRoom?.owner?._id,
    isPasswordVerified,
    roomId,
    user?._id,
  ]);

  const checkForNftAccessable = useCallback(() => {
    return roomId && !chatRoom?.isAccessible && chatRoom?.isNftCollectionRoom;
  }, [chatRoom?.isAccessible, chatRoom?.isNftCollectionRoom, roomId]);

  const checkForBlocked = useCallback(() => {
    return roomId && chatRoom?.blockedUsers?.includes(user?._id);
  }, [chatRoom?.blockedUsers, roomId, user?._id]);

  const checkForSubscribedUser = useCallback(() => {
    return (
      roomId &&
      chatRoom.isSubscriberRequireApproval &&
      !chatRoom.subscriberApprovedUsers?.includes(user?._id)
    );
  }, [
    chatRoom.isSubscriberRequireApproval,
    chatRoom.subscriberApprovedUsers,
    roomId,
    user?._id,
  ]);

  const checkIsPersonaDisbale = useCallback(() => {
    return (
      roomId &&
      chatRoom?.specificChatType === specificChatType.PERSONA &&
      chatRoom?.openAIData?.assistantId &&
      !chatRoom?.openAIData?.isAssistantEnable
    );
  }, [
    chatRoom?.openAIData?.assistantId,
    chatRoom?.openAIData?.isAssistantEnable,
    chatRoom?.specificChatType,
    roomId,
  ]);

  const checkForIsPaidRoomNotSubscribed = useCallback(() => {
    if (roomId && chatRoom?.hasSubscription) {
      if (chatRoom?.moderators) {
        for (let i = 0; i < chatRoom.moderators.length; i++) {
          if (chatRoom.moderators[i].toString() === user?._id.toString())
            return false;
        }
      }
      const mySubscription = checkMyRoomSubscription(
        mySubscriptions,
        chatRoom?._id,
      );
      if (!mySubscription) {
        return true;
      }
      return false;
    }

    return false;
  }, [chatRoom?._id, chatRoom?.hasSubscription, mySubscriptions, roomId]);

  const checkForIsBlockedUser = useMemo(() => {
    return (
      (roomId && chatRoom?.blockedGeneralUsers?.includes(user?._id)) || false
    );
  }, [user]);

  const handleSubmit = async (
    msg,
    usersMentioned = [],
    attachments,
    attachedMessage,
  ) => {
    setisLoadMoreButtonClicked(false);
    const secretKey = roomId + process.env.REACT_APP_AES_SECRET_KEY;
    let isReply = false;
    let replyFor = null;
    if (msg) {
      let isEncrypted = true;
      if (
        msg.trim().substring(0, 4) === "@ami" ||
        chatType === specificChatType.PERSONA ||
        chatType === specificChatType.VIDEOTRANSCRIPT
      ) {
        isEncrypted = false;
      }

      if (attachedMessage) {
        isReply = true;
        replyFor = {
          _id: attachedMessage._id,
          msg: attachedMessage.message,
          ...attachedMessage,
        };
      }

      if (
        usersMentioned.length > 0 &&
        (chatType === specificChatType.EVENT ||
          chatType === specificChatType.EVENTPAGE)
      ) {
        const users = [];
        usersMentioned.forEach((user) => {
          const findUser = chatRoom?.chatUsers.find((u) => u._id === user._id);
          if (!findUser) {
            users.push(user._id);
          }
        });
        if (users.length > 0) {
          await updateRoomChattingUsers(
            { chatUsers: users },
            roomId,
            user?.token,
          );
        }
      }
      let chatMessage = msg;
      if (isEncrypted) {
        chatMessage = encryptMsg(secretKey, msg);
      }
      sendMessage(
        chatMessage,
        usersMentioned,
        isEncrypted,
        attachments,
        isReply,
        replyFor,
        chatType,
      );
    } else {
      sendMessage(
        msg,
        usersMentioned,
        false,
        attachments,
        isReply,
        replyFor,
        chatType,
      );
    }
  };

  const handleLoadMore = async () => {
    setisLoadMoreButtonClicked(true);
    await getChatByRoomId(
      roomId,
      user?.token,
      dispatch,
      user?._id,
      isMore,
      offset,
      source,
    );
  };
  const { getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      dispatch({
        type: actionTypes.TOGGLE_SCROLL,
        payload: true,
      });
      setAttachmentFile(acceptedFiles[0]);
    },
    multiple: false,
    accept: "image/*",
    disableClick: true,
  });

  const _renderChatInputBox = () => {
    if (roomId) {
      return (
        <div
          className={`absolute md:w-95p w-85p md:px-0 pt-2 bottom-0.5 md:left-[2.5%] left-[7.5%] z-20 `}
        >
          {user?.token ? (
            <MComposer
              onSubmit={handleSubmit}
              maxRows={5}
              roomId={roomId}
              attachedMessage={attachedMessage}
              setAttachedMessage={setAttachedMessage}
              attachmentFile={attachmentFile}
              setAttachmentFile={setAttachmentFile}
              chatType={chatType}
              setCurrentInputMessageLength={setCurrentInputMessageLength}
            />
          ) : (
            <span className={`text-primaryGray`}>Login required for chat</span>
          )}
        </div>
      );
    }
    <div
      style={{
        width: "95%",
        left: "2.5%",
        bottom: 10,
      }}
      className={`absolute w-full px-4 md:px-0`}
    >
      {user?.token ? (
        <MComposer onSubmit={handleSubmit} />
      ) : (
        <span className={`text-primaryGray`}>Login required for chat</span>
      )}{" "}
    </div>;
  };

  const _renderChatContentBox = () => {
    if (magData.length === 0) {
      return (
        <div className="text-primaryGray text-base text-center pt-56 h-full">
          <p className="h-full">No messages.</p>
        </div>
      );
    }
    return (
      <>
        {isFetchingChatRoom ? undefined : !isMoreFetching ? (
          isMore && (
            <div className="flex mt-2 justify-center">
              <Button
                size="vibhut-sm"
                variant="vibhut-secondary"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            </div>
          )
        ) : (
          <div className="">
            <ChatSkeleton />
          </div>
        )}
        {isChatLoading ? (
          <ChatSkeleton />
        ) : (
          <div className="h-full md:pb-10 relative">
            <div
              style={{ float: "left", clear: "both" }}
              ref={messagesStart}
            ></div>
            {renderChats()}
            <div
              style={{ float: "left", clear: "both" }}
              ref={messagesEnd}
            ></div>
          </div>
        )}

        <GroupRoomMessageStatus
          isOpen={isOpenStatus}
          setIsOpen={setIsOpenStatus}
          chatId={chatId}
          setChatId={setChatId}
        />
      </>
    );
  };

  const chatMarginTopClassName = useCallback(() => {
    if (isMobile()) {
      if (!!attachmentFile) {
        return "-mt-96";
      }
      if (currentInputMessageLength > 84) {
        return "-mt-24";
      }
      if (currentInputMessageLength > 58) {
        return "-mt-16";
      }
      if (currentInputMessageLength > 28) {
        return "-mt-16";
      }
    }

    return "-mt-10";
  }, [currentInputMessageLength, attachmentFile]);

  return (
    <>
      <div
        className={`w-full overflow-auto custom-scrollbar md:mb-0 md:py-0 md:mt-0  ${
          isModal
            ? "h-[calc(85vh-50px)] md:h-[calc(90vh-50px)]"
            : ` ${
                !!attachmentFile
                  ? `md:h-[34vh] chat-Component-mobile-height`
                  : `md:h-[65vh] chat-Component-mobile-height 3xl:h-[66vh]`
              }  ${chatMarginTopClassName()} py-16 `
        } px-2 transition-all duration-500 `}
      >
        <div
          {...getRootProps({ className: "dropzone" })}
          onClick={(e) => e.stopPropagation()}
          className="outline-none focus:outline-none focus:border-none focus:ring-0"
        >
          {magData?.length === 0 && isChatLoading ? (
            <ChatSkeleton />
          ) : isChatRoomOwner ? (
            _renderChatContentBox()
          ) : checkForIsBlockedUser ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full">
                <Polygon bgColor={"#B5B5B5"} isInline={true} />
                <p>You are blocked to access this room</p>
              </div>
            </div>
          ) : checkForPassword() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full">
                <div className="flex justify-center pb-2">
                  <PasswordIcon />
                </div>
                <p>This chat is password protected.</p>
                <div className="pt-2">
                  <Button onClick={() => setIsOpenPassword(true)}>Chat</Button>
                </div>
              </div>
            </div>
          ) : checkForNftAccessable() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full">
                <Polygon bgColor={"#B5B5B5"} isInline={true} />
                <p>Non accessible NFT room.</p>
              </div>
            </div>
          ) : checkForBlocked() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full">
                <Polygon bgColor={"#B5B5B5"} isInline={true} />
                <p>You are blocked to access this room</p>
              </div>
            </div>
          ) : checkForSubscribedUser() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full">
                <Polygon bgColor={"#B5B5B5"} isInline={true} />
                <p>The room host has not approved you yet</p>
              </div>
            </div>
          ) : checkForIsPaidRoomNotSubscribed() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full flex flex-col items-center">
                <Subscriptions color={"#B5B5B5"} />
                <p>Paid room</p>
              </div>
            </div>
          ) : checkForDesoRoom() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full flex flex-col items-center">
                <Polygon bgColor={"#B5B5B5"} isInline={true} />
                <p>Deso Post room</p>
              </div>
            </div>
          ) : checkIsPersonaDisbale() ? (
            <div className="text-primaryGray text-base text-center pt-56 h-full">
              <div className="h-full flex flex-col items-center">
                <DoubleStar />
                <p>Currently persona is disable</p>
              </div>
            </div>
          ) : (
            _renderChatContentBox()
          )}
        </div>
      </div>
      {isChatRoomOwner
        ? _renderChatInputBox()
        : !checkForPassword() &&
          !checkForNftAccessable() &&
          !checkForBlocked() &&
          !checkForSubscribedUser() &&
          !checkForIsPaidRoomNotSubscribed() &&
          !checkForDesoRoom() &&
          _renderChatInputBox()}
      <RoomModel
        isOpen={isOpenPassword}
        setIsOpen={setIsOpenPassword}
        roomId={roomId}
        setIsPasswordVerified={setIsPasswordVerified}
        roomPassword={roomPassword}
        setRoomPassword={setRoomPassword}
        buttonTitle="Join Chat"
      />
      <ProfileModal
        isOpen={profileUser._id ? true : false}
        setIsOpen={(open) => !open && closeUserModal()}
        user={profileUser}
      />
    </>
  );
}

export default Chat;
