import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { Tooltip } from "@material-ui/core";
import { getUserData } from "../../../../services/userService";
import { Popover, Switch, Transition } from "@headlessui/react";
import { StoreContext } from "../../../../Context/StoreContext";
import {
  isFreePlanUser,
  isMobile,
  isProPlusPlanUser,
} from "../../../../utils/utils";
import ChatReaction from "../../../../components/chat/ChatReaction";
import AddReaction from "../../../icons/AddReaction";
import { Link } from "react-router-dom";
import { removeDuplicatesEmoji } from "../../../../utils/utils";
import UpArrow from "../../../VibehutUI/Icons/UpArrow";

interface ReactionMenuProps {
  sendReactionInCall: any;
  isShowRxn: boolean;
  setIsShowRxn: Dispatch<SetStateAction<boolean>>;
  reactionFromLive?: boolean;
}

interface Emoji {
  emoji: string;
  unified: string;
  isAvailable: boolean;
}

export default function ReactionMenu({
  sendReactionInCall,
  isShowRxn,
  setIsShowRxn,
  reactionFromLive,
}: ReactionMenuProps) {
  const { state } = useContext(StoreContext);
  const [isOpenAddReaction, setIsOpenAddReaction] = useState(false);
  const [isOpenQuickRxn, setIsOpenQuickRxn] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    role,
    auth: { user },
    user: { callStreak },
  } = state;
  const initialReactions = [
    { emoji: "❤️", unified: "2764-fe0f", isAvailable: true },
    {
      emoji: "😂",
      unified: "1f602",
      isAvailable: false,
    },
    {
      emoji: "🙌",
      unified: "1f64c",
      isAvailable: false,
    },
    { emoji: "👍", unified: "1f44d", isAvailable: false },
    {
      emoji: "😮",
      unified: "1f62e",
      isAvailable: false,
    },
  ];

  const [reactions, setReactions] = useState(initialReactions);

  const [frequentlyUsedReactions, setFrequentlyUsedReactions] = useState([]);
  const [hotReactions, setHotReactions] = useState<Emoji[]>([]);

  const [frequentlyUsedReactionsFree] = useState([
    { emoji: "❤️", unified: "2764-fe0f", isAvailable: true },
  ]);
  const toolTipText = useMemo(() => {
    return `Send reaction`;
  }, []);

  useEffect(() => {
    if (user && role) {
      if (isFreePlanUser(role, user)) {
        setReactions([
          {
            emoji: "❤️",
            unified: "2764-fe0f",
            isAvailable: true,
          },
          {
            emoji: "😂",
            unified: "1f602",
            isAvailable: false,
          },
          {
            emoji: "🙌",
            unified: "1f64c",
            isAvailable: false,
          },
          {
            emoji: "👍",
            unified: "1f44d",
            isAvailable: false,
          },
          {
            emoji: "😮",
            unified: "1f62e",
            isAvailable: false,
          },
        ]);
      } else {
        const isProPlusUser = isProPlusPlanUser(role, user);
        if (isProPlusUser) {
          const cacheCallReactions = removeDuplicatesEmoji(
            JSON.parse(localStorage.getItem("callReactions") || "[]") || [],
          );
          setFrequentlyUsedReactions(cacheCallReactions);
          setHotReactions(cacheCallReactions);
        }
        setReactions([
          {
            emoji: "❤️",
            unified: "2764-fe0f",
            isAvailable: true,
          },
          {
            emoji: "😂",
            unified: "1f602",
            isAvailable: true,
          },
          {
            emoji: "🙌",
            unified: "1f64c",
            isAvailable: true,
          },
          {
            emoji: "👍",
            unified: "1f44d",
            isAvailable: true,
          },
          {
            emoji: "😮",
            unified: "1f62e",
            isAvailable: true,
          },
        ]);
      }
    }
  }, [role, user]);

  const sendReaction = (reaction: any) => {
    const isReactionAlreadyInList = reactions.find(
      (r) => r.unified === reaction.unified,
    );
    const isReactionAlreadyInRecentList = frequentlyUsedReactions.find(
      (r: any) => r.unified === reaction.unified,
    );
    const currentReaction = {
      emoji: reaction.emoji,
      unified: reaction.unified,
      isAvailable: true,
    };
    if (!isReactionAlreadyInList && !isReactionAlreadyInRecentList) {
      const cacheCallReactions = removeDuplicatesEmoji(
        JSON.parse(localStorage.getItem("callReactions") || "[]") || [],
      );
      cacheCallReactions.unshift(currentReaction);
      if (cacheCallReactions.length > 6) {
        cacheCallReactions.pop();
      }
      setFrequentlyUsedReactions(cacheCallReactions);
      localStorage.setItem("callReactions", JSON.stringify(cacheCallReactions));
    }
    const isHotReactionAlreadyInList = hotReactions?.some(
      (r) => r.unified === reaction.unified,
    );
    if (!isHotReactionAlreadyInList) {
      const _hotReactions = [currentReaction, ...hotReactions.slice(0, 2)];
      const _filterReactions = removeDuplicatesEmoji(_hotReactions);
      setHotReactions(_filterReactions);
    }
    const username = getUserData(user).username;
    const emoji = reaction.emoji;
    sendReactionInCall(username, user?._id, emoji, callStreak?.streakCount);
  };

  const proPlusUser = useMemo(
    () => isProPlusPlanUser(role, user),
    [user, role],
  );

  const plansTooltipContent = (
    <div className="group">
      <Link
        target="_blank"
        rel="noopener noreferrer"
        to="/plans"
        className="group-hover:underline"
      >
        See Plans
      </Link>{" "}
      for More Reactions
    </div>
  );

  const iconBgColor = useMemo(() => {
    if (reactionFromLive) {
      return "bg-frescoWhite";
    }
    if (isMobile()) {
      return "bg-matteGray";
    }
    return "bg-gray-700";
  }, [reactionFromLive]);

  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Tooltip title={!open ? toolTipText : ""} placement="top">
              <div className="flex gap-2">
                {isShowRxn && isProPlusPlanUser(role, user)
                  ? !!hotReactions?.length && (
                      <div className="flex gap-2 items-center justify-center">
                        {hotReactions.map(
                          (reaction: any, index: number) =>
                            index <= 2 && (
                              <div
                                key={reaction.unified}
                                className={`${
                                  isMobile() ? "w-8 h-8" : "w-10 h-10"
                                } flex items-center justify-center rounded-full ${
                                  isMobile() ? "bg-matteGray" : " bg-gray-700"
                                } cursor-pointer`}
                              >
                                <Tooltip
                                  title={
                                    !reaction.isAvailable
                                      ? plansTooltipContent
                                      : ""
                                  }
                                  placement="top"
                                >
                                  <span
                                    className={`text-xl cursor-pointer ${
                                      !reaction.isAvailable && "opacity-50"
                                    }`}
                                    onClick={() => {
                                      if (reaction.isAvailable) {
                                        sendReaction(reaction);
                                      }
                                    }}
                                  >
                                    {reaction.emoji}
                                  </span>
                                </Tooltip>
                              </div>
                            ),
                        )}
                      </div>
                    )
                  : isShowRxn && (
                      <div
                        className={`${
                          isMobile() ? "w-8 h-8" : "w-10 h-10"
                        } flex items-center justify-center rounded-full ${
                          isMobile() ? "bg-matteGray" : " bg-gray-700"
                        } cursor-pointer`}
                      >
                        <Tooltip
                          title={
                            !frequentlyUsedReactionsFree[0]?.isAvailable
                              ? plansTooltipContent
                              : ""
                          }
                          placement="top"
                        >
                          <span
                            className={`text-xl cursor-pointer ${
                              !frequentlyUsedReactionsFree[0]?.isAvailable &&
                              "opacity-50"
                            }`}
                            onClick={() => {
                              if (frequentlyUsedReactionsFree[0]?.isAvailable) {
                                sendReaction(frequentlyUsedReactionsFree[0]);
                              }
                            }}
                          >
                            {frequentlyUsedReactionsFree[0]?.emoji}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                <Popover.Button
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  <div
                    className={`${
                      isMobile() ? "w-8 h-8" : "w-10 h-10"
                    } flex items-center justify-center rounded-full ${iconBgColor} cursor-pointer`}
                  >
                    <AddReaction fill={reactionFromLive ? "#262A37" : "#fff"} />
                  </div>
                </Popover.Button>
              </div>
            </Tooltip>
            <Transition
              as="div"
              show={visible}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {visible && (
                <div>
                  <Popover.Panel
                    static={visible}
                    className={`absolute z-50 bottom-12 transform -translate-x-1/2 left-5 ${
                      isMobile() ? "bg-matteGray" : " bg-gray-700"
                    } rounded-3xl shadow-xl px-2.5 py-1.5`}
                  >
                    {" "}
                    <div className="flex items-center">
                      {!!frequentlyUsedReactions.length && (
                        <div>
                          <div className="flex items-center gap-1">
                            {frequentlyUsedReactions.map((reaction: any) => (
                              <Tooltip
                                key={reaction.unified}
                                title={
                                  !reaction.isAvailable
                                    ? plansTooltipContent
                                    : ""
                                }
                                placement="top"
                              >
                                <span
                                  className={`text-xl cursor-pointer ${
                                    !reaction.isAvailable && "opacity-50"
                                  }`}
                                  onClick={() => {
                                    if (reaction.isAvailable) {
                                      sendReaction(reaction);
                                    }
                                  }}
                                >
                                  {reaction.emoji}
                                </span>
                              </Tooltip>
                            ))}
                          </div>
                        </div>
                      )}
                      {!!frequentlyUsedReactions.length && (
                        <span className="text-white px-1">|</span>
                      )}
                      <div className="flex items-center gap-1">
                        {reactions.map((reaction) => (
                          <Tooltip
                            key={reaction.unified}
                            title={
                              !reaction.isAvailable ? plansTooltipContent : ""
                            }
                            interactive
                            placement="top"
                          >
                            <span
                              className={`text-xl cursor-pointer ${
                                !reaction.isAvailable && "opacity-50"
                              }`}
                              onClick={() => {
                                if (reaction.isAvailable) {
                                  sendReaction(reaction);
                                }
                              }}
                            >
                              {reaction.emoji}
                            </span>
                          </Tooltip>
                        ))}
                        <Tooltip
                          title={
                            !proPlusUser
                              ? "More Reactions. Available only for Pro+ user"
                              : "More Reactions"
                          }
                          placement="top"
                        >
                          <span
                            className={`text-xl cursor-pointer ${
                              !proPlusUser && "opacity-50"
                            }`}
                            onClick={() => {
                              if (proPlusUser) {
                                setIsOpenAddReaction(true);
                              }
                            }}
                          >
                            <AddReaction />
                          </span>
                        </Tooltip>
                      </div>
                      <span className="text-white mx-1">|</span>
                      <div
                        className={`text-sm text-white cursor-pointer transition-transform duration-300 ${
                          isOpenQuickRxn ? "rotate-180" : "rotate-0"
                        }`}
                        onClick={() => setIsOpenQuickRxn(!isOpenQuickRxn)}
                      >
                        <UpArrow stroke="#ffff" />
                      </div>
                    </div>
                    {isOpenQuickRxn && (
                      <div className="text-white cursor-pointer flex justify-between items-center text-sm px-2.5 py-2.5">
                        <span>Quick Reaction</span>

                        <Switch
                          checked={isShowRxn}
                          onChange={() => setIsShowRxn(!isShowRxn)}
                          className={`${
                            isShowRxn ? "bg-primary" : "bg-gray-200"
                          } relative inline-flex items-center  rounded-full h-[23px] w-[42px] float-right`}
                        >
                          <span
                            className={`${
                              isShowRxn ? "translate-x-6" : "translate-x-1"
                            } inline-block w-[17px] h-[18px] transform bg-white shadow rounded-full duration-200`}
                          />
                        </Switch>
                      </div>
                    )}
                  </Popover.Panel>
                </div>
              )}
            </Transition>
          </>
        )}
      </Popover>
      <ChatReaction
        isOpen={isOpenAddReaction}
        setIsOpen={setIsOpenAddReaction}
        addReaction={sendReaction}
      />
    </>
  );
}
