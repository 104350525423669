import React from 'react';
import MicOff from '../../../VibehutUI/Icons/MicOff';
import MicOn from '../../../VibehutUI/Icons/MicOn';

interface ToggleAudioRemoteButtonProps {
  handleMute: () => void;
  isAudioEnabled: Boolean;
}

export default function ToggleAudioRemoteButton({ handleMute, isAudioEnabled }: ToggleAudioRemoteButtonProps) {
  return (
    <button className={`mr-1`} onClick={handleMute} type="button">
      <div className={`${isAudioEnabled ? 'bg-matteGray' : 'bg-cvRed'} p-9px rounded-full cursor-pointer`}>
        {isAudioEnabled ? <MicOn color="#B5B5B5" ratio={'12'} /> : <MicOff color="#B5B5B5" ratio={'12'} />}
      </div>
    </button>
  );
}
