import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from '../../../types';
import { globals } from '../../../globals';

export default function useHandleRoomDisconnection(
  room: Room | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  useEffect(() => {
    if (room) {
      const onTwilioDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          onError(error);
        }

        onDisconnected();
      };

      const onDisconnected = (error: string = '') => {
        if (error) {
          globals.reconnectBackendSocket();
          return;
        }
        const cancelDisconnect =
          ['transport close'].includes(error?.toLocaleLowerCase()) ||
          !['client namespace disconnect', 'ping timeout', ''].includes(error.toLowerCase());

        // socket is already disconnected, and will eventually reconnect.
        // but do not disconnect twillo as well if the reason of disconnection was, transport close or it was not client namespace disconnect and ping timeout
        if (cancelDisconnect) {
          return;
        }

        console.log('DISCONNECTING!!');

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }

        globals.socket?.disconnect();

        //changed the condition of error, so that even the empty string is compared in the next condition.
        if (error !== undefined && error !== null && error !== 'io client disconnect') {
          room.disconnect();
          window.alert(`Disconnected: ${error}`);

          const isClosed = window.close();

          if (isClosed === undefined) {
            window.location.search = '';
            window.location.pathname = '/';
          }
        }
      };

      const onReconnectAttempt = (reconnectionCount: number) => {
        console.log('RECONNECTION COUNT ', reconnectionCount);
      };

      room.on('disconnected', onTwilioDisconnected);

      globals.socket?.io.on('reconnect_attempt', onReconnectAttempt);

      globals.socket?.on('disconnect', onDisconnected);
      return () => {
        room.off('disconnected', onTwilioDisconnected);
        globals.socket?.off('disconnect', onDisconnected);
        globals.socket?.io.off('reconnect_attempt', onReconnectAttempt);
      };
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}
