import axios from "../utils/axios";

export function getMySubscriptions(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/subscriptions/my-subscriptions`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMySubscribers(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/subscriptions/my-subscribers`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createSubscription(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/subscriptions", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function unSubscription(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/subscriptions/un-subscribe", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
