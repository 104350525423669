import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getRelevantUsers, searchUsers } from "../../services/userService";
import useDebounce from "../../hooks/useDebounce";
import { showToast } from "../../actions/toastActions";
import {
  getRoomSubscribers,
  inviteForHostApprovalRoom,
} from "../../services/rooomService";
import Close from "../../VibehutUI/Icons/Close";
import UserCardSkeleton from "../../VibehutUI/Skeletons/UserCardSkeleton";
import SendInvite from "../../VibehutUI/InviteUserModel/SendInvite";
import Button from "../../VibehutUI/Button";
import Loader from "../../VibehutUI/Loader";
import InviteSearchBar from "../../VibehutUI/InviteUserModel/InviteSearchBar";

export default function InviteModalForHostApprovalRoom({
  isOpen,
  setIsOpen,
  roomId,
  invitedUsers,
  setInvitedUsers,
}) {
  const { state, dispatch } = useContext(StoreContext);

  const { auth } = state;

  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [relevantUsers, setRelevantUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      setSearchResults([]);

      const subscriberIds = subscribers.map((subscriber) => subscriber._id);

      searchUsers(searchTerm)
        .then((searchResponse) => {
          const users = searchResponse.data.users;
          const userIds = [auth?.user?._id, ...subscriberIds];

          const filteredUsers = users.filter(
            (user: { _id: any }) => !userIds.includes(user._id),
          );

          setSearchResults(filteredUsers);
        })
        .catch(console.log)
        .finally(() => {
          setIsSearching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, subscribers]);

  useEffect(() => {
    if (isOpen) {
      getRoomSubscribers(auth.user.token, roomId)
        .then((response) => {
          const subscriberIds = response.data.subsUserInfo.map(
            (user) => user._id,
          );
          const userIds = [];
          const filterUsers = response.data.subsUserInfo.filter(
            (user) =>
              user._id !== auth.user._id &&
              !userIds.includes(user._id) &&
              (user.role === "user" || user.role === "admin"),
          );
          setSubscribers(filterUsers);
          getRelevantUsers(auth.user.token)
            .then((relevantUsersResponse) => {
              const filteredRelevantUsers = relevantUsersResponse.data.filter(
                (user) =>
                  user._id !== auth.user._id &&
                  !subscriberIds.includes(user._id) &&
                  (user.role === "user" || user.role === "admin"),
              );
              setRelevantUsers(filteredRelevantUsers);
            })
            .catch(console.log);
        })
        .catch(console.log);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleInviteSent = async () => {
    try {
      setIsLoading(true);
      await inviteForHostApprovalRoom(
        auth?.user?.token,
        {
          invitedUsers: invitedUsers,
        },
        roomId,
      );
      showToast(
        dispatch,
        "success",
        "Invitations has been sent successfully.",
        2000,
      );
      setInvitedUsers([]);
      setIsOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      showToast(
        dispatch,
        "Error",
        "Facing Error while sending Invitation, Please again later",
        2000,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block align-bottom bg-white dark:bg-navyGray shadow-xl w-428px
                rounded-2xl text-left overflow-hidden transform transition-all mb-14 sm:my-8 sm:align-middle"
              >
                <div className="bg-white dark:bg-navyGray  p-3 border-b border-frescoWhite dark:border-matteGray ">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite w-full">
                      All People
                    </p>
                    <span
                      className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div>
                  <div className="px-6 pt-3 custom-scrollbar">
                    <div>
                      <InviteSearchBar
                        isLoading={isSearching}
                        handleChange={handleChange}
                        results={searchResults}
                        value={searchTerm}
                        setValue={setSearchTerm}
                        invitedUsers={invitedUsers}
                        setInvitedUsers={setInvitedUsers}
                      />
                    </div>

                    {searchTerm.trim().length === 0 && (
                      <div>
                        {relevantUsers.length === 0 ? (
                          <div
                            className="overflow-y-scroll  custom-scrollbar "
                            style={{ maxHeight: "40vh" }}
                          >
                            <UserCardSkeleton />
                          </div>
                        ) : (
                          <div className="overflow-y-scroll  custom-scrollbar my-3 max-h-[47vh]  md:max-h-[42vh]">
                            {relevantUsers.length > 0 &&
                              relevantUsers.map((user, index) => (
                                <SendInvite
                                  key={index}
                                  user={user}
                                  invitedUsers={invitedUsers}
                                  setInvitedUsers={setInvitedUsers}
                                />
                              ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className=" px-4 py-3 flex flex-row items-center justify-end space-x-3 ">
                    <div className="flex space-x-2">
                      <Button
                        size="vibhut-sm"
                        variant="vibhut-secondary"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </Button>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          size="vibhut-sm"
                          variant="vibhut-primary"
                          onClick={handleInviteSent}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
