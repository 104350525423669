import React, { useState } from "react";
import { globals } from "../../globals";
import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import useParticipantsContext from "../../hooks/useParticipantsContext/useParticipantsContext";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import DeleteModel from "../../VibehutUI/DeleteModel";
import Close from "../../VibehutUI/Icons/Close";
import UserFilled from "../../VibehutUI/Icons/UserFilled";
import ProfileModal from "../../VibehutUI/ProfileModal";
import UserCard from "../CardComponents/UserCard";
import ToggleAudioRemoteButton from "../Buttons/ToggleAudioRemoteButton/ToggleAudioRemoteButton";
import Edit from "../../VibehutUI/Icons/Edit";
import EditProfileSocialsModal from "../../VibehutUI/EditProfileSocialsModal";
type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

interface kickedUser {
  user: any;
}

const KickedUser = ({ user }: kickedUser) => {
  const [isOpen, setIsOpen] = useState(false);
  const { roomData, kickedOutUser, muteUser } = useVibehutData(globals.roomId);

  const { authUser } = useAuthUser();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isEditSocialOpen, setIsEditSocialOpen] = useState(false);

  const authUserId = authUser._id;
  const { galleryViewParticipants } = useParticipantsContext();

  let isAudioEnabled = false;
  let participant: any;
  galleryViewParticipants.map((p) => {
    if (p.identity === user._id) {
      participant = p;
    }
  });
  const publication = participant
    ? (Array.from(participant.tracks.values()) as TrackPublication[])
    : undefined;
  isAudioEnabled =
    (publication
      ? publication.find((p) => p.kind === "audio")?.isTrackEnabled
      : false) || false;
  let labelText;
  if (roomData.labels && roomData.labels.length > 0) {
    roomData.labels.map((labelData: any) => {
      if (labelData.userId.toString() === user._id) {
        labelText = labelData.labelText;
      }
    });
  }

  return (
    <div className="flex items-center justify-between">
      <UserCard
        user={user}
        isMemeberCard
        userState={user.userState}
        isRoomOwner={roomData.owner?._id === user._id}
        isYou={authUserId === user._id}
        fromCallProfile
        isModerator={
          roomData.moderators !== undefined &&
          roomData.moderators.includes(user?._id)
        }
        labelText={labelText}
      />

      <div className="space-x-1 flex justify-between items-center">
        {user.provider === "guest" ? undefined : (
          <div
            className="bg-matteGray p-9px rounded-full cursor-pointer "
            onClick={() => setIsProfileOpen(true)}
          >
            <UserFilled />
          </div>
        )}
        {authUser?._id === user._id && (
          <div
            className="bg-matteGray p-9px rounded-full cursor-pointer "
            onClick={() => setIsEditSocialOpen(true)}
          >
            <Edit color="#B5B5B5" ratio="12" />
          </div>
        )}
        {authUser?._id === roomData.owner?._id ||
        (roomData.moderators !== undefined &&
          roomData.moderators.includes(authUser?._id)) ? (
          authUser?._id !== user._id ? (
            <div className="flex">
              <ToggleAudioRemoteButton
                isAudioEnabled={isAudioEnabled}
                handleMute={() => {
                  muteUser(user._id, isAudioEnabled);
                }}
              />
              <div
                className="bg-matteGray p-9px rounded-full cursor-pointer"
                onClick={() => setIsOpen(true)}
              >
                <Close color="#B5B5B5" />
              </div>
            </div>
          ) : null
        ) : null}
      </div>

      {isProfileOpen && (
        <ProfileModal
          isOpen={isProfileOpen}
          setIsOpen={setIsProfileOpen}
          userState={user.userState}
          user={user}
        />
      )}
      {isEditSocialOpen && (
        <EditProfileSocialsModal
          isOpen={isEditSocialOpen}
          setIsOpen={setIsEditSocialOpen}
          user={user}
        />
      )}
      <DeleteModel
        handleDelete={() => {
          kickedOutUser(user._id);
        }}
        title="Remove User"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={`<div class="text-darkGray"> Are you sure you want to kick out user <span class="text-darkNight">"${user.username}"</span></div> `}
        btnvalue="Yes, remove"
        description="They won't be able to join in again for 2 hours."
      />
    </div>
  );
};

export default KickedUser;
