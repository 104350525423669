import axios from "./axios";

export const uploadMedia = async (
  coverFile,
  prevCoverFile,
  setIsImageUploading,
  setCover,
  setCoverFile,
  nextFunction,
) => {
  const formData = new FormData();
  formData.append("image", coverFile);
  formData.append("prevThumbnail", prevCoverFile);

  setIsImageUploading(true);
  await axios
    .patch(`/media`, formData)
    .then((res) => {
      return res.data;
    })
    .then(async (media) => {
      setIsImageUploading(false);
      if (media.data.url) {
        setCover({
          urls: {
            raw: media.data.url,
            full: media.data.url,
            regular: media.data.url,
            small: media.data.url,
            thumb: media.data.url,
          },
        });
        setCoverFile(null);
        await nextFunction(media.data);
      }
    })
    .catch((err) => {
      setIsImageUploading(false);
      console.log("error:", err);
    });
};

export function uploadAttachment(coverFile) {
  return new Promise(function (resolve, reject) {
    const formData = new FormData();
    formData.append("image", coverFile);
    axios
      .post(`/media/protected`, formData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export const uploadMedia2 = async (
  coverFile,
  setIsImageUploading,
  nextFunction,
) => {
  const formData = new FormData();
  formData.append("image", coverFile);
  setIsImageUploading(true);
  await axios
    .patch(`/media`, formData)
    .then((res) => {
      return res.data;
    })
    .then(async (media) => {
      setIsImageUploading(false);
      if (media.data.url) {
        await nextFunction(media.data);
      }
    })
    .catch((err) => {
      setIsImageUploading(false);
      console.log("error:", err);
    });
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  return await axios
    .patch(`/media`, formData)
    .then((res) => {
      return res.data;
    })
    .then(async (media) => {
      return media.data.url;
    })
    .catch((err) => {
      console.log("error:", err);
    });
};
