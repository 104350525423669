import React from 'react';

function CheckMark({ width = 12, height = 10 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.71183 5.20733C1.32394 4.81419 0.690786 4.80995 0.297651 5.19784C-0.0954833 5.58574 -0.0997294 6.21889 0.288167 6.61203L1.71183 5.20733ZM4.04915 9L3.33732 9.70235C3.53295 9.90062 3.80242 10.0083 4.08082 9.9995C4.35922 9.99068 4.62133 9.86614 4.80402 9.65587L4.04915 9ZM11.7549 1.65587C12.1171 1.23897 12.0728 0.607359 11.6559 0.24513C11.239 -0.1171 10.6074 -0.0727774 10.2451 0.344126L11.7549 1.65587ZM0.288167 6.61203L3.33732 9.70235L4.76098 8.29765L1.71183 5.20733L0.288167 6.61203ZM4.80402 9.65587L11.7549 1.65587L10.2451 0.344126L3.29428 8.34413L4.80402 9.65587Z"
        fill="white"
      />
    </svg>
  );
}

export default CheckMark;
