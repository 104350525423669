import axios from "../utils/axios";

export function reportErrorService(error, errorStack) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "/report-error",
      data: {
        error,
        errorStack,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
