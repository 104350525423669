import React from "react";
import { globals } from "../../../globals";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { isMobile } from "../../../utils";
import LeaveCall from "../../../VibehutUI/Icons/LeaveCall";
import { frontEndURL } from "../../../constants";
import useAuthUser from "../../../hooks/useAuthUser/useAuthUser";
import { useHistory } from "react-router-dom";

interface EndCallButtonProps {
  type?: string;
}

export default function EndCallButton({ type = "icon" }: EndCallButtonProps) {
  const { room } = useVideoContext();
  const callId = room ? room.name : "";
  const { authUser } = useAuthUser();
  const history = useHistory();

  const handleEndCall = () => {
    try {
      globals.showEmptyScreen = true;
      globals.socket?.disconnect();
      if (authUser.provider === "guest") {
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("currentCallRoomId");
        window.location.href = `${frontEndURL}`;
        return;
      }
      if (isMobile) {
        history.push(`/review/${callId}`);
        window.location.reload();
      } else {
        window.location.href = `${frontEndURL}/review/${callId}`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        className={`${
          isMobile ? "px-4 py-3" : "md:w-13 w-14 h-10"
        } flex items-center justify-center rounded-full bg-cvRed
        cursor-pointer`}
        onClick={handleEndCall}
      >
        {type === "icon" && <LeaveCall />}
        {type === "button" && (
          <button
            type="button"
            className="bg-cvRed px-4 py-2 text-white rounded-3xl"
          >
            Leave
          </button>
        )}
      </div>
    </div>
  );
}
