import React from "react";
import { LinkedInApi } from "../../utils/axios";
import LinkedIn from "../Icons/LinkedIn";

function LinkedInPage() {
  const handleLinkedInLogin = () => {
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LinkedInApi.clientId}&redirect_uri=${LinkedInApi.redirectUrl}&state=${LinkedInApi.state}&scope=${LinkedInApi.scope}`;

    window.location = url;
  };

  return (
    <button
      onClick={handleLinkedInLogin}
      type="button"
      className="bg-white authBtn border border-gray-400 font-ubuntu"
    >
      <span className="flex pl-6 items-center space-x-2">
        <LinkedIn ratio={24} />
        <span className="text-bitClout">Continue with LinkedIn</span>
      </span>
    </button>
  );
}

export default LinkedInPage;
