import React from 'react';
import { isMobile } from '../../utils';
import CopyIcon from '../Icons/CopyIcon';
import Share from '../Icons/Share';
import PopOver from '../Popover';
import { Typography } from '@material-ui/core';
import { IconContainer } from '../../components/MenuBar/Menu/Menu';

interface CopyButtonProps {
  bgColor?: string;
  title?: string;
  url: string;
  type?: string;
  isHoverable?: boolean;
  showCopyIcon?: boolean;
  showIcon?: boolean;
  forMobile?: boolean;
  topPosition?: string;
  text?: string;
  cssClasses?: string;
  setMenuOpen?: (data: boolean) => void;
  iconPosition?: 'left' | 'right';
}

function CopyButton({
  bgColor,
  title,
  url,
  type = 'icon',
  isHoverable = true,
  showCopyIcon = true,
  showIcon = true,
  forMobile = false,
  topPosition = '-top-11',
  cssClasses = 'justify-between py-2',
  setMenuOpen = undefined,
  iconPosition = 'left',
}: CopyButtonProps) {
  const [copied, setCopied] = React.useState(false);
  const [showCopyText, setShowCopyText] = React.useState(false);

  const toggleCopyText = (isShow: boolean) => {
    if (isMobile) {
      return;
    }
    setShowCopyText(isShow);
  };

  const handleCopyClick = () => {
    if (isMobile && navigator.share) {
      navigator
        .share({
          title: title,
          url: url,
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(url);
      setCopied(true);
      if (setMenuOpen) {
        setMenuOpen(false);
      }

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <div
      onClick={e => {
        if (type === 'icon') {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {type === 'button' ? (
        <button
          onClick={handleCopyClick}
          className={`group flex rounded-md items-center w-full ${cssClasses}  cursor-pointer `}
        >
          {showIcon && iconPosition === 'left' && (
            <IconContainer>
              <span onMouseEnter={() => toggleCopyText(true)} onMouseLeave={() => toggleCopyText(false)}>
                <CopyIcon color={bgColor} />
              </span>
            </IconContainer>
          )}

          {forMobile ? (
            <p className="text-white text-sm  text-center w-full">Copy Link</p>
          ) : (
            <Typography variant="body1">Copy Link</Typography>
          )}
          {showIcon && iconPosition === 'right' && (
            <IconContainer>
              <span onMouseEnter={() => toggleCopyText(true)} onMouseLeave={() => toggleCopyText(false)}>
                <CopyIcon color={bgColor} />
              </span>
            </IconContainer>
          )}
        </button>
      ) : (
        <div
          className={`relative h-full w-full bg-transparent flex justify-center rounded-full items-center bg-frescoWhite`}
          onMouseEnter={() => toggleCopyText(true)}
          onMouseLeave={() => toggleCopyText(false)}
        >
          <span onClick={handleCopyClick}>
            {forMobile && isMobile ? (
              <div className="bg-white rounded-full p-1">
                <Share copied={copied} />
              </div>
            ) : isHoverable ? (
              showCopyIcon ? (
                <CopyIcon color={bgColor} />
              ) : (
                undefined
              )
            ) : (
              <CopyIcon color={bgColor} />
            )}
          </span>
          {isMobile
            ? undefined
            : showCopyText && (
                <PopOver text={copied ? 'Copied!' : 'Copy Link'} topPosition={'top-9'} pointerDirection="top" />
              )}
        </div>
      )}
    </div>
  );
}

export default CopyButton;
