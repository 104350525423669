import { useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { Switch } from "@headlessui/react";
import * as actionTypes from "../../constants";

interface Props {
    isOwner?: boolean
}

function AIReplySwitch({ isOwner = false }: Props) {
    const { state, dispatch } = useContext(StoreContext);
    const {
        persona: { aiReplyEnable, },
    } = state;

    useEffect(() => {
        if (isOwner) {
            dispatch({ type: actionTypes.TOGGLE_AI_REPLY_FALSE })
        }
    }, [dispatch, isOwner])


    return (
        <div className="flex items-center gap-2 ">
            <p className="md:text-sm text-xs font-medium text-navyGray dark:text-frescoWhite">
                AI Reply
            </p>
            <Switch
                checked={aiReplyEnable}
                onChange={() => dispatch({ type: actionTypes.TOGGLE_AI_REPLY })}
                className={`${aiReplyEnable ? "bg-primary" : "bg-gray-200"
                    } relative inline-flex items-center  rounded-full md:h-31px h-5 md:w-51px w-8 float-right`}
            >
                <span
                    className={`${aiReplyEnable
                        ? "md:translate-x-6 translate-x-4"
                        : "translate-x-1"
                        } inline-block md:w-27px md:h-27px w-4 h-4 transform bg-white shadow rounded-full duration-200`}
                />
            </Switch>
        </div>
    )
}

export default AIReplySwitch