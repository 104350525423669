const SYMBOLs = ["", "k", "M", "G", "T", "P", "E"];

export function formatNumber(
  number: number,
  includeDecimalPlaces = true,
  decimalPlaces = 2,
) {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier <= 0) {
    return includeDecimalPlaces
      ? number.toFixed(decimalPlaces)
      : number.toString();
  }

  const suffix = SYMBOLs[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = number / scale;

  return scaled.toFixed(2) + suffix;
}

export function convertDesoToUSD(
  deso: number,
  USDCentsPerDeSoExchangeRate: number,
) {
  if (USDCentsPerDeSoExchangeRate) {
    const dollarPerDeSo = USDCentsPerDeSoExchangeRate / 100;
    const usd = deso * dollarPerDeSo;
    return usd.toFixed(3);
  } else return 0;
}

export function calculateUSDinDeSo(
  usd: number,
  USDCentsPerDeSoExchangeRate: number,
) {
  if (usd === 0) {
    return 0;
  }
  if (USDCentsPerDeSoExchangeRate) {
    const dollarPerDeSo = USDCentsPerDeSoExchangeRate / 100;
    const desoPerDollar = 1 / dollarPerDeSo;
    const deso = usd * desoPerDollar;
    return deso;
  } else return 0;
}

export function calculateDeSoInUSD(
  nanos: number,
  USDCentsPerDeSoExchangeRate: number,
  decimalPlaces = 2,
) {
  if (USDCentsPerDeSoExchangeRate) {
    const dollarPerDeSo = USDCentsPerDeSoExchangeRate / 100;
    const dollarPerNano = dollarPerDeSo / 1000000000;
    let result = dollarPerNano * nanos;
    const decimalPlacesMultiplier = 1 * Math.pow(10, decimalPlaces);
    result =
      Math.round((result + Number.EPSILON) * decimalPlacesMultiplier) /
      decimalPlacesMultiplier;
    return result;
  }
  return 0;
}

export function calculateAndFormatDeSoInUsd(
  nanos: number,
  USDCentsPerDeSoExchangeRate: number,
  includeDecimalPlaces = true,
  decimalPlaces = 2,
) {
  return formatNumber(
    calculateDeSoInUSD(nanos, USDCentsPerDeSoExchangeRate, decimalPlaces),
    includeDecimalPlaces,
    decimalPlaces,
  );
}

export function nanosToDeso(nanos: number, toFixed = 3) {
  const usdAmount = (nanos / 1000000000).toFixed(toFixed);
  return usdAmount;
}

export function USDtoDesoNanos(
  usd: number,
  USDCentsPerDeSoExchangeRate: number,
) {
  const desoNanosAmount =
    calculateUSDinDeSo(usd, USDCentsPerDeSoExchangeRate) * 1000000000;
  const ceilingValue = Math.ceil(desoNanosAmount);
  const floorValue = Math.floor(desoNanosAmount);
  if (desoNanosAmount - floorValue <= 0.5) {
    return floorValue;
  } else {
    return ceilingValue;
  }
}

export function usdToNanos(usd: number, USDCentsPerDeSoExchangeRate: number) {
  const nanos =
    calculateUSDinDeSo(usd, USDCentsPerDeSoExchangeRate) * 1000000000;
  return Math.floor(nanos);
}

export function desoToNanos(deso: number) {
  const nanos = deso * 1000000000;
  return Math.floor(nanos);
}

export function nanosToUsd(
  nanos: number,
  USDCentsPerDeSoExchangeRate: number,
): number {
  const usdAmount = calculateAndFormatDeSoInUsd(
    nanos,
    USDCentsPerDeSoExchangeRate,
  );
  return Number(usdAmount);
}

export function deSoNanosYouWouldGetIfYouSold(
  creatorCoinAmountNano: number,
  coinEntry: any,
): number {
  const deSoLockedNanos = coinEntry.DeSoLockedNanos;
  const currentCreatorCoinSupply = coinEntry.CoinsInCirculationNanos;

  const deSoBeforeFeesNanos =
    deSoLockedNanos *
    (1 -
      Math.pow(
        1 - creatorCoinAmountNano / currentCreatorCoinSupply,
        1 / 0.3333333,
      ));

  return (deSoBeforeFeesNanos * (100 * 100 - 1)) / (100 * 100);
}

function formatUSD(num: number, decimal: number): string {
  const formatUSDMemo: any = {};
  if (formatUSDMemo[num] && formatUSDMemo[num][decimal]) {
    return formatUSDMemo[num][decimal];
  }

  formatUSDMemo[num] = formatUSDMemo[num] || {};

  formatUSDMemo[num][decimal] = Number(num).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  return formatUSDMemo[num][decimal];
}

function abbreviateNumber(
  value: number,
  decimals: number,
  formatUSDEnable = false,
): string {
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixNum = Math.floor((("" + value.toFixed(0)).length - 1) / 3);
  if (suffixNum === 0) {
    decimals = Math.min(2, decimals);
  }
  let shortValue = (value / Math.pow(1000, suffixNum)).toFixed(decimals);
  if (formatUSDEnable) {
    shortValue = formatUSD(Number(shortValue), decimals);
  }
  return shortValue + suffixes[suffixNum];
}

export function calculateDeSoInUSDSimple(
  nanos: number,
  USDCentsPerDeSoExchangeRate: number,
) {
  if (USDCentsPerDeSoExchangeRate) {
    return nanos * (USDCentsPerDeSoExchangeRate / 100 / 1e9);
  }
  return 0;
}

export function creatorCoinNanosToUSDNaive(
  creatorCoinNanos: number,
  coinPriceDeSoNanos: number,
  USDCentsPerDeSoExchangeRate: number,
  abbreviate = false,
): string | number {
  const usdValue = calculateDeSoInUSDSimple(
    (creatorCoinNanos / 1e9) * coinPriceDeSoNanos,
    USDCentsPerDeSoExchangeRate,
  );
  return abbreviate ? abbreviateNumber(usdValue, 2, true) : usdValue;
}
