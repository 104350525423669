import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Fragment, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import { StoreContext } from "../../Context/StoreContext";
import { getNotificationSettings } from "../../services/notificationService";
import {
  sendEmailOtp,
  sendNumberOtp,
  verifyEmailOtp,
  verifyNumberOtp,
} from "../../services/notifSettingService";
import axios from "../../utils/axios";
import Button from "../Button";
import DeleteModel from "../DeleteModel";
import Close from "../Icons/Close";
import Loader from "../Loader";
import LoaderButton from "../LoaderButton";
import { hideUiModal } from "../../actions/uiModalActions";
import { ModalType } from "../../constants";

export default function UserNotificationActionModal() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user: authUser },
    globalUiModal: { isOpen, modalType },
  } = state;
  const [email, setEmail] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [showOtpBox, setShowOtpBox] = React.useState(false);
  const [showPhoneOtpBox, setShowPhoneOtpBox] = React.useState(false);
  const [otpValue, setOtpValue] = React.useState("");
  const [phoneOtpValue, setphoneOtpValue] = React.useState("");
  const [visEmailText, setvisEmailText] = React.useState("hidden");
  const [visNumberText, setvisNumberText] = React.useState("hidden");
  const [isVerifying, setIsVerifying] = React.useState(false);
  const [isVerifyingPhone, setIsVerifyingPhone] = React.useState(false);
  const [notificationSettings, setNotificationSettings] = React.useState({
    email: {
      enabled: true,
      isEmailVerified: false,
      value: "",
    },
    phoneNumber: {
      enabled: true,
      isPhoneNumberVerified: false,
      value: "",
    },
  });
  const [isEmailRemoving, setIsEmailRemoving] = React.useState(false);
  const [isNumberRemoving, setIsNumberRemoving] = React.useState(false);
  const [isShowEmailRemoveBtn, setIsShowEmailRemoveBtn] = React.useState(false);
  const [isShowMobileRemoveBtn, setIsShowMobileRemoveBtn] =
    React.useState(false);

  // hangle wrong otp
  const [wrongEmailOtp, setWrongEmailOtp] = React.useState(false);
  const [wrongNumOtp, setWrongNumOtp] = React.useState(false);

  // timer
  const [otpTimer, setOtpTimer] = React.useState(0);
  const [otpPhnTimer, setOtpPhnTimer] = React.useState(0);
  const [showTimer, setShowTimer] = React.useState({
    emailTimer: false,
    phoneTimer: false,
  });
  const [showRetryBtn, setShowRetryBtn] = React.useState({
    emailRetryBtn: false,
    phoneRetryBtn: false,
  });

  const [isConfirmationEmailOpen, setIsConfirmationEmailOpen] =
    React.useState(false);
  const [isConfirmationPhoneOpen, setIsConfirmationPhoneOpen] =
    React.useState(false);

  const updateNotificationSettings = () => {
    if (
      authUser?.token &&
      isOpen &&
      modalType === ModalType.NOTIFICATIONSUBSCRIPTION
    ) {
      getNotificationSettings(authUser.token)
        .then((res) => {
          const _notificationSettings = res.data.notificationSettings;
          setNotificationSettings(_notificationSettings);
          if (
            _notificationSettings.email.value &&
            _notificationSettings.email.isEmailVerified
          ) {
            setIsShowEmailRemoveBtn(true);
          }
          if (_notificationSettings.email.isEmailVerified) {
            setEmail(_notificationSettings.email.value || "");
          }
          if (
            _notificationSettings.phoneNumber.value &&
            _notificationSettings.phoneNumber.isPhoneNumberVerified
          ) {
            setIsShowMobileRemoveBtn(true);
          }
          if (_notificationSettings.phoneNumber.isPhoneNumberVerified) {
            setNumber(`+${_notificationSettings.phoneNumber.value}` || "");
          }
          if (
            !_notificationSettings.email.isEmailVerified &&
            !_notificationSettings.phoneNumber.isPhoneNumberVerified
          ) {
          }
        })
        .catch((err) => console.log("NOTIFICATION SETTINGS err:", err));
    }
  };

  React.useEffect(() => {
    updateNotificationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChange = (e) => {
    setIsShowEmailRemoveBtn(false);
    setEmail(e.target.value);
    setShowOtpBox(false);
    setOtpValue("");

    setvisEmailText("hidden");
  };

  const handleNumberChange = (e) => {
    setNumber(`+${e}`);
    setShowPhoneOtpBox(false);
    setphoneOtpValue("");
    setvisNumberText("hidden");
  };
  const handleOtpChange = (e) => {
    setOtpValue(e.target.value);
    setWrongEmailOtp(false);
  };
  const handlePhoneOtpChange = (e) => {
    setphoneOtpValue(e.target.value);
    setWrongNumOtp(false);
  };

  const verifyEmail = () => {
    setShowRetryBtn({ ...showRetryBtn, emailRetryBtn: false });
    setOtpTimer(0);
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(email)) {
      sendEmailOtp(authUser?.token, email)
        .then((res) => {
          const _notificationSettings = res.data;
          setShowOtpBox(true);
          setShowTimer({ ...showTimer, emailTimer: true });
          setNotificationSettings(_notificationSettings);
          if (_notificationSettings.email.isEmailVerified) {
            setEmail(_notificationSettings.email.value || "");
          }
          if (_notificationSettings.phoneNumber.isPhoneNumberVerified) {
            setNumber(`+${_notificationSettings.phoneNumber.value}` || "");
          }
        })
        .catch((err) => console.log("SEND EMAIL OTP err: ", err));
    } else if (!regEx.test(email) && email !== "") {
      setvisEmailText("");
    } else {
      setvisEmailText("");
    }
  };

  const verifyPhone = () => {
    setShowRetryBtn({ ...showRetryBtn, phoneRetryBtn: false });
    setOtpPhnTimer(0);
    sendNumberOtp(authUser?.token, number)
      .then((res) => {
        setShowPhoneOtpBox(true);
        setShowTimer({ ...showTimer, phoneTimer: true });
        const _notificationSettings = res.data;
        setNotificationSettings(_notificationSettings);
        if (_notificationSettings.email.isEmailVerified) {
          setEmail(_notificationSettings.email.value || "");
        }
        if (_notificationSettings.phoneNumber.isPhoneNumberVerified) {
          setNumber(`+${_notificationSettings.phoneNumber.value}` || "");
        }
      })
      .catch((err) => console.log("SEND NUMBER OTP err: ", err));
  };

  const verifyOtp = () => {
    setIsVerifying(true);
    verifyEmailOtp(authUser?.token, otpValue)
      .then((res) => {
        if (res.data.isVerified === true) {
          setShowOtpBox(false);
          updateNotificationSettings();
          setShowRetryBtn({
            ...showRetryBtn,
            emailRetryBtn: false,
          });
          setOtpValue("");
        } else {
          setWrongEmailOtp(true);
        }
      })
      .catch((err) => console.log("VERIFY EMAIL OTP err: ", err))
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const verifyPhoneOtp = () => {
    setIsVerifyingPhone(true);
    verifyNumberOtp(authUser?.token, phoneOtpValue)
      .then((res) => {
        if (res.data.isVerified === true) {
          setShowPhoneOtpBox(false);
          updateNotificationSettings();
          setShowRetryBtn({
            ...showRetryBtn,
            phoneRetryBtn: false,
          });
          setphoneOtpValue("");
        } else {
          setWrongNumOtp(true);
        }
      })
      .catch((err) => console.log("VERIFY NUMBER OTP err: ", err))
      .finally(() => {
        setIsVerifyingPhone(false);
      });
  };

  const removeNumber = () => {
    setIsNumberRemoving(true);
    axios
      .post(
        `/notification/save-notification-settings`,
        {
          notificationSettings: {
            ...notificationSettings,
            phoneNumber: {
              ...notificationSettings.phoneNumber,
              value: "",
              isPhoneNumberVerified: false,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser?.token}`,
          },
        }
      )
      .then((res) => {
        setIsNumberRemoving(false);
        if (res.status === 200) {
          setIsShowMobileRemoveBtn(false);
          setNumber("");
          setNotificationSettings({
            ...notificationSettings,
            phoneNumber: {
              enabled: true,
              isPhoneNumberVerified: false,
              value: "",
            },
          });
        } else {
          console.log(res);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const removeEmail = () => {
    setIsEmailRemoving(true);
    axios
      .post(
        `/notification/save-notification-settings`,
        {
          notificationSettings: {
            ...notificationSettings,
            email: {
              ...notificationSettings.email,
              value: "",
              isEmailVerified: false,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser?.token}`,
          },
        }
      )
      .then((res) => {
        setIsEmailRemoving(false);
        if (res.status === 200) {
          setIsShowEmailRemoveBtn(false);
          setEmail("");
          setNotificationSettings({
            ...notificationSettings,
            email: {
              enabled: true,
              isEmailVerified: false,
              value: "",
            },
          });
        } else {
          console.log(res);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const handleClose = () => {
    hideUiModal(dispatch);
  };

  return (
    <>
      <Transition
        appear
        show={isOpen && modalType === ModalType.NOTIFICATIONSUBSCRIPTION}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 h-full font-ubuntu">
                <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite tracking-wide">
                      Setup your notifications now
                    </p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={handleClose}
                    >
                      <Close />
                    </span>
                  </div>
                </div>

                <div>
                  <div className="px-4 pt-2">
                    <div className=" flex flex-col pl-2 pr-2 sm:pr-10">
                      <div className="pb-3">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
                          Email Address
                        </p>
                        <div>
                          <div className="flex items-center">
                            <input
                              type="text"
                              className="border mr-2  outline-none border-primaryGray py-2 px-4 rounded-xl mt-1 w-full sm:w-72 placeholder-notifyBg text-sm"
                              value={email}
                              onChange={handleEmailChange}
                              placeholder="johndoe@gmail.com"
                              disabled={isShowEmailRemoveBtn}
                            />
                            {isShowEmailRemoveBtn && (
                              <div>
                                {isEmailRemoving ? (
                                  <Loader />
                                ) : (
                                  <Button
                                    variant="danger"
                                    onClick={() => {
                                      setIsConfirmationEmailOpen(true);
                                    }}
                                    // size="xs"
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>

                          {email &&
                          !showOtpBox &&
                          (email !== notificationSettings.email.value ||
                            (email === notificationSettings.email.value &&
                              !notificationSettings.email.isEmailVerified)) ? (
                            <div className="pt-3">
                              <Button
                                varient="primary"
                                onClick={() => {
                                  verifyEmail();
                                }}
                              >
                                Send Verification Code
                              </Button>
                            </div>
                          ) : undefined}
                        </div>

                        {showOtpBox ? (
                          <p className="text-xs  text-darkGray dark:text-gray-300 pt-2">
                            An email has been sent!
                            {showTimer.emailTimer && (
                              <span> Retry in {60 - otpTimer}s </span>
                            )}
                          </p>
                        ) : undefined}
                        <p className={` ${visEmailText} text-xs text-cvRed`}>
                          {`${
                            email
                              ? "Please enter valid Email"
                              : "Please enter a Email."
                          }`}
                        </p>
                      </div>

                      <div
                        className={`${showOtpBox ? "visible" : "hidden"} pb-3`}
                      >
                        <div>
                          <input
                            type="text"
                            className="border mr-2  outline-none border-primaryGray py-2 px-4 rounded-xl w-full sm:w-72 placeholder-notifyBg text-sm"
                            value={otpValue}
                            onChange={handleOtpChange}
                            placeholder="Enter 6-digit code..."
                          />
                          {wrongEmailOtp ? (
                            <p
                              className={` text-xs dark:text-frescoWhite text-cvRed mt-2`}
                            >
                              The code is incorrect. Please try again.
                            </p>
                          ) : undefined}
                          <div className="flex pt-3 space-x-2">
                            {showRetryBtn.emailRetryBtn ? (
                              <Button
                                size="vibhut-sm"
                                variant="vibhut-secondary"
                                onClick={() => {
                                  verifyEmail();
                                }}
                              >
                                Re-send Code
                              </Button>
                            ) : undefined}
                            {isVerifying ? (
                              <LoaderButton />
                            ) : (
                              <Button
                                size="vibhut-sm"
                                variant="vibhut-primary"
                                disabled={otpValue.length !== 6}
                                onClick={() => {
                                  verifyOtp();
                                }}
                              >
                                Verify
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="pb-3">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite mb-2">
                          Phone Number (with country code)
                        </p>
                        <div>
                          <div className="flex items-center">
                            <PhoneInput
                              country={"us"}
                              value={number}
                              inputClass="border mr-2 outline-none border-primaryGray  pl-4 rounded-xl  ml-7 w-11/12 sm:w-64 placeholder-notifyBg text-sm"
                              dropdownClass="border mr-2 outline-none border-primaryGray py-2 px-4 rounded-xl"
                              placeholder="(416) 502-9867"
                              onChange={handleNumberChange}
                              disabled={isShowMobileRemoveBtn}
                              enableLongNumbers={true}
                            />

                            {isShowMobileRemoveBtn && (
                              <div>
                                {isNumberRemoving ? (
                                  <Loader />
                                ) : (
                                  <Button
                                    variant="danger"
                                    onClick={() => {
                                      setIsConfirmationPhoneOpen(true);
                                    }}
                                    // size="xs"
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>

                          {number &&
                          !showPhoneOtpBox &&
                          (number !==
                            `+${notificationSettings.phoneNumber.value}` ||
                            (number ===
                              `+${notificationSettings.phoneNumber.value}` &&
                              !notificationSettings.phoneNumber
                                .isPhoneNumberVerified)) ? (
                            <div className="pt-3">
                              <Button
                                varient="primary"
                                onClick={() => {
                                  verifyPhone();
                                }}
                              >
                                Send Verification Code
                              </Button>
                            </div>
                          ) : undefined}
                        </div>

                        {showPhoneOtpBox ? (
                          <p className="text-xs  text-darkGray dark:text-gray-300 pt-2">
                            A SMS has been sent!
                            {showTimer.phoneTimer && (
                              <span> Retry in {60 - otpPhnTimer}s </span>
                            )}
                          </p>
                        ) : undefined}

                        <p className={` ${visNumberText} text-xs text-cvRed`}>
                          {`${
                            number
                              ? "Please enter valid Phone Number"
                              : "Please enter a Phone Number."
                          }`}
                        </p>
                      </div>

                      <div
                        className={`${showPhoneOtpBox ? "visible" : "hidden"} `}
                      >
                        <div>
                          <input
                            type="text"
                            className="border mr-2  outline-none border-primaryGray py-2 px-4 rounded-xl mt-1 w-full sm:w-72 placeholder-notifyBg text-sm"
                            value={phoneOtpValue}
                            onChange={handlePhoneOtpChange}
                            placeholder="Enter 6-digit code..."
                          />
                          {wrongNumOtp ? (
                            <p
                              className={` text-xs dark:text-frescoWhite text-cvRed pt-1`}
                            >
                              The code is incorrect. Please try again.
                            </p>
                          ) : undefined}
                          <div className="pt-3 flex space-x-2">
                            {showRetryBtn.phoneRetryBtn ? (
                              <Button
                                size="vibhut-sm"
                                variant="vibhut-secondary"
                                onClick={() => {
                                  verifyPhone();
                                }}
                              >
                                Re-send Code
                              </Button>
                            ) : undefined}
                            {isVerifyingPhone ? (
                              <LoaderButton />
                            ) : (
                              <Button
                                size="vibhut-sm"
                                variant="vibhut-primary"
                                disabled={phoneOtpValue.length !== 6}
                                onClick={() => {
                                  verifyPhoneOtp();
                                }}
                              >
                                Verify
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>

                      <DeleteModel
                        handleDelete={removeEmail}
                        title={"Confirmation"}
                        isOpen={isConfirmationEmailOpen}
                        setIsOpen={setIsConfirmationEmailOpen}
                        text={`Are you sure you want to remove your email `}
                        btnvalue={"Remove"}
                      />
                      <DeleteModel
                        handleDelete={removeNumber}
                        title={"Confirmation"}
                        isOpen={isConfirmationPhoneOpen}
                        setIsOpen={setIsConfirmationPhoneOpen}
                        text={`Are you sure you want to remove your phone number `}
                        btnvalue={"Remove"}
                      />
                    </div>
                  </div>
                  <div className=" px-4 py-3 flex flex-row-reverse ">
                    <div className="mr-4">
                      <Button
                        onClick={handleClose}
                        size="vibhut-sm"
                        variant="vibhut-primary"
                      >
                        Okay
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
