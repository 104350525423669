import { useContext, useEffect, useMemo } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getGamificationData } from "../../services/gamificationService";
import * as actionTypes from "../../constants";
import Button from "../../VibehutUI/Button";
import { openGamificationModal } from "../../actions/profileModalActions";

function SidebarGamification() {
  const {
    state: {
      auth: { user },
      user: {
        gamification: { data: gamificationData, total, completed },
      },
    },
    dispatch,
  } = useContext(StoreContext);

  useEffect(() => {
    if (user?.token) {
      getGamificationData(user?.token)
        .then((res) => {
          if (res && res.data) {
            dispatch({
              type: actionTypes.UPDATE_USER_GAMIFICATION,
              payload: res.data,
            });
          }
        })
        .catch(() => {});
    }
  }, [user?.token]);

  const sliderWidth = useMemo(() => {
    const width = (100 * completed) / total;
    return width + "%";
  }, [completed, total]);

  if (gamificationData === null) {
    return <></>;
  }

  return (
    <section className="p-3.5 border border-lightGray dark:border-matteGray rounded-2xl flex items-center justify-between">
      <div className="flex flex-col gap-1">
        <div>
          <p className="dark:text-frescoWhite text-darkNight font-medium">
            Explore Vibehut
          </p>
        </div>
        <div>
          <div className="h-2 overflow-hidden rounded-full bg-lightGray">
            <div
              className="h-2 rounded-full bg-gradient-vertical"
              style={{ width: sliderWidth }}
            ></div>
          </div>
          <p className="text-darkGray text-sm">{`${completed}/${total} completed`}</p>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            openGamificationModal(dispatch, true);
          }}
        >
          See All
        </Button>
      </div>
    </section>
  );
}

export default SidebarGamification;
