import { useMediaQuery } from "@material-ui/core";
import React from "react";
import useVibehutChatContext from "../../hooks/useVibehutChatContext/useVibehutChatContext";
import theme from "../../theme";

import ChatBubble from "../../VibehutUI/ChatBubble";
import MessageComposer from "../../VibehutUI/MessageComposer";

interface ChatProps {
  roomId: string;
}

const Chat = ({ roomId }: ChatProps) => {
  const messagesEnd = React.useRef<null | HTMLDivElement>(null);
  const { messages, addReaction, addUserToReaction, removeUserOrAddReaction } =
    useVibehutChatContext();

  let name: string;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [messages]);

  return (
    <React.Fragment>
      <div
        className={`w-full overflow-auto custom-scrollbar ${
          isMobile ? "chat-Component-mobile-height " : "chat-Component-height"
        }   px-2`}
      >
        {messages?.length === 0 ? (
          <div className="text-primaryGray text-base text-center pt-56 h-full">
            <p className="h-full">No Message</p>
          </div>
        ) : (
          <div className="h-full">
            {messages.map((msg, index) => {
              let hidden;
              if (msg?.isAmiRequest) {
                return (
                  <p
                    key={msg._id || index}
                    className={`text-darkGray text-sm py-4 ${
                      msg.msg.includes("set call message:")
                        ? "text-start"
                        : "text-center"
                    }`}
                  >
                    {msg.msg}
                  </p>
                );
              }
              if (name) {
                hidden = name === msg.username ? "hidden" : "";
              } else {
                hidden = "";
              }
              const usernameDirection = msg.isSend
                ? "text-right pr-1"
                : `text-left pl-1`;
              name = !hidden ? msg.username : "";
              return (
                <div
                  key={msg._id || index}
                  className={`flex flex-col mt-2 ${roomId ? "pl-1" : ""} `}
                >
                  <div
                    className={`mb-0.5 text-xs ${
                      msg.isAmiBot ? "" : hidden
                    } font-ubuntu text-primaryGray ${usernameDirection}`}
                  >
                    {msg.isSend ? (
                      "You"
                    ) : msg.isAmiBot ? (
                      <span className="">
                        Ami
                        <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                          Bot
                        </span>
                      </span>
                    ) : msg.isBot ? (
                      <span className="">
                        vibehut
                        <span className="border border-primaryGray px-1 rounded ml-1 text-9px">
                          Bot
                        </span>
                      </span>
                    ) : (
                      `${msg.username} ${msg.isGuest ? "· (Guest)" : ""}`
                    )}
                  </div>

                  <>
                    {(msg.msg || msg?.attachments?.length > 0) &&
                      !msg?.isAmiRequest && (
                        <ChatBubble
                          messageData={{
                            ...msg,
                            _id: msg._id || index,
                          }}
                          index={index}
                          addReaction={addReaction}
                          removeUserOrAddReaction={removeUserOrAddReaction}
                          addUserToReaction={addUserToReaction}
                          message={msg.msg}
                          isSend={msg.isSend}
                          isDanger={msg.isDanger}
                          roomId={roomId}
                        />
                      )}
                  </>
                </div>
              );
            })}
            <div
              style={{ float: "left", clear: "both" }}
              ref={messagesEnd}
            ></div>
          </div>
        )}
      </div>
      <div
        style={{
          width: "95%",
          left: "2.5%",
          bottom: 10,
        }}
        className="absolute w-full px-4 md:px-0"
      >
        <MessageComposer />
      </div>
    </React.Fragment>
  );
};

export default Chat;
