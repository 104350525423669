import { useCallback, useEffect, useState } from "react";
import TalkingUsersModal from "../../VibehutUI/TalkingUsersModal";
import Loader from "../../VibehutUI/Loader";
import ConnectingScreenProfile from "./ConnectingScreenProfile";
import Button from "../../VibehutUI/Button";
import Div100vh from "react-div-100vh";
import { Theme, useMediaQuery } from "@material-ui/core";
import { globals } from "../../globals";
import { frontEndURL } from "../../constants";
import { wait } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { videoAppUrl } from "../../../utils/axios";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import useQuery from "../../hooks/useQuery";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import { getUserData } from "../../utils/vibehutUtils";

interface ConnectingScreenProps {
  isMeetingRoom: boolean;
  uniqueRoomId: string | null;
  handleCancel: () => void;
  roomData: any;
}

export default function ConnectingScreen({
  isMeetingRoom,
  uniqueRoomId = null,
  handleCancel,
  roomData,
}: ConnectingScreenProps) {
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const query = useQuery();
  const [iscopied, setIsCopied] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [joinInstantCallConsent, setJoinInstantCallConsent] = useState(false);

  const roomKey = query.get("roomKey") || roomData._id;
  const { requestForJoinCallAsGuest, isAnyJoinRequestFromGuest } =
    useVibehutData(roomKey);
  const { authUser } = useAuthUser();
  const goToCallPage = (roomId: string) => {
    window.location.href = `${videoAppUrl}/call?roomKey=${roomId}`;
  };

  const openJoinInstantCallModal = () => {
    globals.showModal(
      "⚡️ Instant Join",
      <>
        <InstantJoinCallModal
          handleCancel={handleCancel}
          setJoinInstantCallConsent={setJoinInstantCallConsent}
        />
      </>,
    );
  };

  const handleJoinRoom = useCallback(async () => {
    if (
      !roomData?.isQuickJoin ||
      roomData?.isBreakoutRoom ||
      roomData?.isCCRoom ||
      roomData?.isDAORoom ||
      roomData?.isPasswordProtected
    ) {
      goToCallPage(roomData._id);
      return "";
    }
    const joinInstant = localStorage.getItem("joinInstant");
    if (!joinInstant && !joinInstantCallConsent) {
      openJoinInstantCallModal();
      return "";
    }
    if (
      roomData.isRequireApproval &&
      authUser._id !== roomData.owner?._id &&
      !roomData.moderators.includes(authUser?._id) &&
      (isAnyJoinRequestFromGuest.isRequestAcceptedByHost === "" ||
        isAnyJoinRequestFromGuest.isRequestAcceptedByHost === "rejected")
    ) {
      if (authUser.provider === "guest") {
        requestForJoinCallAsGuest(
          authUser.guest_user_data.username,
          authUser.guest_user_data._id,
          true,
        );
        return "";
      } else {
        requestForJoinCallAsGuest(
          getUserData(authUser).username,
          authUser._id,
          false,
          getUserData(authUser).profile_picture,
        );
        return "";
      }
    }

    if (
      roomData.isSubscriberRequireApproval &&
      !roomData.subscriberApprovedUsers?.includes(authUser?._id) &&
      authUser?._id !== roomData.owner?._id
    ) {
      globals.showModal(
        `Oops`,
        <>
          <div className="bg-white text-gray-600 dark:text-gray-300 dark:bg-navyGray p-4 relative">
            <p className="text-lg">The room host has not approved you yet</p>
          </div>
        </>,
      );
      return "";
    }

    await wait(1000);
    let timeOutId: NodeJS.Timeout;
    return new Promise((resolve) => {
      if (!globals.socket) {
        return resolve({ error: "Something went wrong!" });
      }

      globals.onJoinRoomCallback = ({
        users,
        liveUsersCount,
        room_data,
        error,
        calls,
        callDetails,
        lobbyUsersCount,
        talkingUsersCount,
        screenPresentingUser,
      }: any) => {
        clearTimeout(timeOutId);

        resolve({
          users,
          liveUsersCount,
          room_data,
          error,
          calls,
          callDetails,
          lobbyUsersCount,
          talkingUsersCount,
          screenPresentingUser,
        });
      };

      const joinRoom = (retry?: boolean) => {
        try {
          if (!retry) {
            timeOutId = setTimeout(() => {
              joinRoom(true);
            }, 15000);
          } else {
            console.log("RETRYING TO JOIN THE ROOM");
          }
          if (!globals.socket) {
            return resolve({ error: "Something went wrong!" });
          }

          globals.socket.emit("join", {
            match_price: 0,
            roomKey: roomData._id,
          });
        } catch (error) {
          console.log("error", error);
        }
      };

      joinRoom();
    });
  }, [
    roomData._id,
    roomData?.isQuickJoin,
    roomData?.isRequireApproval,
    roomData?.isBreakoutRoom,
    roomData?.isCCRoom,
    roomData?.isDAORoom,
    roomData?.isPasswordProtected,
    authUser?._id,
    joinInstantCallConsent,
  ]);

  useEffect(() => {
    if (location.pathname.includes("/instant") && roomData._id) {
      handleJoinRoom();
    }
  }, [
    roomData._id,
    roomData?.isQuickJoin,
    roomData?.isRequireApproval,
    roomData?.isSubscriberRequireApproval,
    roomData?.isBreakoutRoom,
    roomData?.isCCRoom,
    roomData?.isDAORoom,
    roomData?.isPasswordProtected,
    location.pathname,
    handleJoinRoom,
    joinInstantCallConsent,
  ]);

  const handleCopyClick = () => {
    let copyUrl = "";
    if (uniqueRoomId !== null && uniqueRoomId.length !== 0) {
      copyUrl = `${frontEndURL}/call?v=${uniqueRoomId}`;
    } else {
      if (roomData?.isWaveCall) {
        copyUrl = `${frontEndURL}/call?roomKey=${roomData?._id}`;
      } else {
        copyUrl = `${frontEndURL}/rooms/${roomData?._id}`;
      }
    }
    if (isMobile && navigator.share) {
      navigator
        .share({
          title: `Vibehut - ${roomData?.name}`,
          // url: window.location.href,
          url: copyUrl,
        })
        .catch(console.error);
    } else {
      const el = document.createElement("input");
      el.value = copyUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      try {
        document.body.removeChild(el);
      } catch (error) {
        console.log(error);
      }
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };
  return (
    <div className="relative bg-darkNight ">
      <div className=" ">
        <div className="w-8/12  mx-auto md:flex hidden">
          <div className="md:w-1/2 w-full  flex justify-center items-center flex-col mt-52 h-3/4 md:border-r border-navyGray">
            <div className="mb-36 w-full relative h-24 flex justify-center items-center">
              <span className="ripple"></span>
              <span className="ripple"></span>
              <span className="ripple"></span>
              <span className="ripple"></span>
            </div>

            <div className=" text-center">
              <p className="md:text-4xl text-3xl font-bold text-white">
                Waiting to join a call...
              </p>
              <p className="w-96 m-auto pt-2 text-xl text-lightGray">
                {roomData.liveUsersCount < 3
                  ? "Wait a minute, we're notifying others and it takes people a few seconds to join in!"
                  : "All people are talking right now."}
              </p>
              <div className="pt-8 webkit-align-center flex justify-center space-x-6">
                <Button
                  size="vibhut-lg"
                  onClick={handleCancel}
                  variant="danger"
                >
                  Leave
                </Button>
                {roomData?._id && (
                  <button
                    onClick={handleCopyClick}
                    type="button"
                    className="bg-componentColor rounded-3xl px-4 text-white"
                  >
                    {!iscopied ? "Copy Link" : "Copied!"}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="w-1/2 md:flex hidden  h-screen px-16 items-center">
            <div>
              {/* <p className="font-medium text-xl text-white pt-3">
                {this.props.roomKey}
              </p> */}
              <p className="inline-block bg-navyGray capitalize rounded-lg px-2 py-1.5 text-sm text-primaryGray font-medium ">
                LOBBY
              </p>
              <p className="font-medium text-xl text-white pt-4 custom-third-line-clamp">
                {isMeetingRoom
                  ? "Meeting room"
                  : roomData?.name?.replace("with $(_user)", "")}
              </p>
              <p className="text-primaryGray text-base">
                {roomData.liveUsersCount} online
              </p>
              <div className="flex flex-col">
                <div className="flex flex-wrap w-full mt-6">
                  {roomData.liveUsers
                    .slice(0, 12)
                    .map((liveUser: any, index: number) => (
                      <div key={index} className="relative mt-5 cursor-pointer">
                        <ConnectingScreenProfile user={liveUser} />
                      </div>
                    ))}
                </div>

                <p className="text-lightGray mt-4">
                  {roomData.talkingUsersCount} talking ·{" "}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setIsModalOpen(true)}
                  >
                    See All
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <TalkingUsersModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          usersCount={roomData.liveUsersCount}
          talkingUsers={Object.values(roomData.calls)}
          lobby_users={roomData.lobbyUsers}
        />
        <Div100vh className="sm:flex md:hidden ">
          <div className="p-4 border-b border-gray-800">
            <div className="text-white bg-componentColor inline-block p-3 rounded-full ">
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </div>
          </div>
          <div className="p-4">
            <div>
              <p className="bg-navyGray capitalize rounded-lg px-2 py-1 text-sm text-primaryGray font-medium w-14">
                {roomData?.type || "LOBBY"}
              </p>
              {/* <p className="font-medium text-xl text-white pt-3">
                {roomData.isWaveCall
                  ? this.state.waveUser
                    ? `Wave Call with ${this.state.waveUser}`
                    : ""
                  : roomData?.name}
              </p> */}

              <p className="text-primaryGray text-base">
                {roomData.liveUsersCount} online
              </p>
              <div className="flex flex-wrap w-full mt-6">
                {roomData.liveUsers
                  .slice(0, 12)
                  .map((liveUser: any, index: number) => (
                    <div key={index} className="relative mt-5 cursor-pointer">
                      <ConnectingScreenProfile user={liveUser} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="absolute bottom-4 w-full px-4">
            <div className="bg-componentColor flex w-full py-4 pr-4 pl-4 items-center justify-between rounded-4xl">
              <div className="flex items-center ">
                <Loader />
                <p className="text-base text-white font-medium">
                  Waiting to join call...
                </p>
              </div>
              <div>
                <Button
                  size="vibhut-lg"
                  onClick={handleCancel}
                  variant="danger"
                >
                  Leave
                </Button>
              </div>
            </div>
          </div>
        </Div100vh>
      </div>
      <TalkingUsersModal
        isModalOpen={isAlertOpen}
        setIsModalOpen={setIsAlertOpen}
        usersCount={roomData.liveUsersCount}
        talkingUsers={Object.values(roomData.calls)}
        lobby_users={roomData.lobbyUsers}
      />
      {/* {this.props.isUserAllowedVideo === "DENY" && (
        <AlertModal
          isSuccessOpen={isAlertOpen}
          setIsAlertClose={setIsAlertOpen}
          connectBasedOnPermission={this.props.connectBasedOnPermission}
        />
      )} */}
    </div>
  );
}

const InstantJoinCallModal = ({
  handleCancel,
  setJoinInstantCallConsent,
}: any) => {
  const [dontAskMeAgain, setDontAskMeAgain] = useState(false);
  return (
    <div className=" justify-center p-4  rounded-2xl  ">
      <div>
        <div>
          <p className="dark:text-frescoWhite">
            I acknowledge that an /instant call link puts me into a call
            immediately without call setup. That means my camera and mic are set
            to the default on.{" "}
          </p>
        </div>
        <div className="pt-2 flex items-center gap-1">
          <input
            type="checkbox"
            name="roomTypes"
            className="appearance-none h-[18px] w-[18px] border-primaryGray transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
            checked={dontAskMeAgain}
            onChange={(event) => {
              if (event.target.checked) {
                localStorage.setItem("joinInstant", JSON.stringify(true));
                setDontAskMeAgain(true);
                return;
              }
              localStorage.removeItem("joinInstant");
              setDontAskMeAgain(false);
            }}
          />
          <p className="text-sm dark:text-white pt-1">Don't ask me again</p>
        </div>
      </div>
      <div className="pt-3 flex flex-row-reverse">
        <div>
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={() => {
              handleCancel();
              globals.hideModal();
            }}
          >
            Leave Call
          </Button>
        </div>
        <div className="mr-2">
          <Button
            size="vibhut-sm"
            variant="vibhut-secondary"
            onClick={() => {
              setJoinInstantCallConsent(true);
              globals.hideModal();
            }}
          >
            Agree, Join the Call!
          </Button>
        </div>
      </div>
    </div>
  );
};
