import React from "react";

function Notification() {
  return (
    <svg
      width="32"
      height="32"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
        fill="#DB0A40"
      />
      <path
        d="M20 13.3333C20 12.2725 19.5786 11.2551 18.8284 10.5049C18.0783 9.75476 17.0609 9.33334 16 9.33334C14.9391 9.33334 13.9217 9.75476 13.1716 10.5049C12.4214 11.2551 12 12.2725 12 13.3333C12 18 10 19.3333 10 19.3333H22C22 19.3333 20 18 20 13.3333Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1533 22C17.0361 22.2021 16.8679 22.3698 16.6655 22.4864C16.4631 22.6029 16.2336 22.6643 16 22.6643C15.7664 22.6643 15.5369 22.6029 15.3345 22.4864C15.1321 22.3698 14.9639 22.2021 14.8467 22"
        stroke="white"
        strokeWidth="1.23568"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Notification;
