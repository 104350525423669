import React from "react";

function Back({
  backgroundColor = "#f2f2f2",
  forgroundColor = "#7a7a7a",
  ratio = "30",
}) {
  return (
    <svg
      width={ratio}
      height={ratio}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
        fill={backgroundColor}
      />
      <path
        d="M22 16C22.5523 16 23 15.5523 23 15C23 14.4477 22.5523 14 22 14V16ZM8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071L14.6569 22.0711C15.0474 22.4616 15.6805 22.4616 16.0711 22.0711C16.4616 21.6805 16.4616 21.0474 16.0711 20.6569L10.4142 15L16.0711 9.34315C16.4616 8.95262 16.4616 8.31946 16.0711 7.92893C15.6805 7.53841 15.0474 7.53841 14.6569 7.92893L8.29289 14.2929ZM22 14H9V16H22V14Z"
        fill={forgroundColor}
      />
    </svg>
  );
}

export default Back;
