import React, { useContext } from "react";
import { StoreContext } from "../Context/StoreContext";
import { Tab } from "@headlessui/react";
import MySubscribers from "../components/subscriptions/MySubscribers";
import MySubscriptions from "../components/subscriptions/MySubscriptions";
import MySubscriptionActivity from "../components/subscriptions/MySubscriptionActivity";

function SubscriptionsPage() {
  const { state } = useContext(StoreContext);

  const {
    subscription: { mySubscribers, mySubscriptions },
  } = state;
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="font-ubuntu">
      <div>
        <div>
          <React.Fragment>
            <div className="flex justify-between items-center">
              <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite hidden md:block">
                Subscriptions
              </p>
            </div>
            <div className="w-full pt-2">
              <Tab.Group>
                <Tab.List className="flex space-x-1 ">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                        "dark:text-frescoWhite ",
                        selected
                          ? " border-b-2 border-primary text-darkNight"
                          : "text-darkGray"
                      )
                    }
                  >
                    My Subscribers ({mySubscribers.length})
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                        " dark:text-frescoWhite font-ubuntu",
                        selected
                          ? " border-b-2 border-primary text-darkNight"
                          : "text-darkGray"
                      )
                    }
                  >
                    My Subscriptions ({mySubscriptions.length})
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                        "dark:text-frescoWhite ",
                        selected
                          ? " border-b-2 border-primary text-darkNight"
                          : "text-darkGray"
                      )
                    }
                  >
                    Activity
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-4">
                  <Tab.Panel>
                    <div className="py-2">
                      <MySubscribers />
                    </div>
                  </Tab.Panel>

                  <Tab.Panel>
                    <div className="py-2">
                      <MySubscriptions />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="py-2">
                      <MySubscriptionActivity />
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </React.Fragment>

          {/* {!isFetching ? (
            isMore && (
              <div className="flex justify-center">
                <Button type="button" onClick={handleLoadMore}>
                  Load More
                </Button>
              </div>
            )
          ) : (
            <ConnectionSkeleton />
          )} */}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsPage;
