import axios from "../utils/axios";

export function loginUsingBitClout(publicKey, jwt) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/auth/login-with-bitclout", { publicKey, jwt })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function loginWithTwitter(token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "/auth/login",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAuthUserData(token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "GET",
      url: "/auth/get-user",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function loginWithMetamask(wallet_address, ens) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "/auth/login-with-metamask",
      data: {
        wallet_address,
        ens,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function loginAsGuest(data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "/auth/login-as-guest",
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function loginWithCustomWallet(data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "/auth/login-with-custom-wallet",
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
