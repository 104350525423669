import { PersonaActivity } from "../../types";
import moment from "moment";

interface Props {
  activityData: PersonaActivity;
}

function ActivityCard({ activityData }: Props) {
  return (
    <div className="border-b dark:border-matteGray border-lightGray py-2">
      <p className="dark:text-white text-darkNight whitespace-pre-wrap">
        {activityData.activity}
      </p>
      <p className="text-sm text-darkGray">
        {moment(activityData.createdAt).fromNow().replace("ago", "")}
      </p>
    </div>
  );
}

export default ActivityCard;
