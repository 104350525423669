import * as actionTypes from "../constants";
import * as userFeedService from "../services/userPostsService";

export async function getLatestFeedPosts({ dispatch, token, lastPostHash }) {
  try {
    const res = await userFeedService.getUsersPosts(
      lastPostHash,
      "newest",
      token,
      0,
    );
    dispatch({
      type: actionTypes.GET_LATEST_POST_SUCCESS,
      payload: res.data.posts[0],
    });
  } catch (err) {
    throw err;
  }
}

export async function getUserFeedPosts({
  dispatch,
  token,
  lastPostHash,
  orderBy,
  page,
  publicKey,
  loadMorePosts,
  postType,
}) {
  dispatch({
    type: actionTypes.GET_FEED_POSTS_REQUEST,
    loadMore: lastPostHash,
  });

  try {
    const res = await userFeedService.getUsersPosts(
      lastPostHash,
      orderBy,
      token,
      page,
      postType,
    );
    const posts = await Promise.all(
      res.data.posts.map(async (post) => {
        if (post.postSource === "deso") {
          const feed = await userFeedService.getSingleDesoPost(
            publicKey,
            post.id,
          );
          post = {
            ...post,
            posterPublicKeyBase58Check: feed.PosterPublicKeyBase58Check,
            postEntryReaderState: feed.PostEntryReaderState,
            postHashHex: feed.PostHashHex,
            isLiked: feed.PostEntryReaderState.LikedByReader,
            commentCount: feed.CommentCount,
            profileEntryResponse: feed.ProfileEntryResponse,
          };
        }
        return post;
      }),
    );

    dispatch({
      type: actionTypes.GET_FEED_POSTS_SUCCESS,
      payload: {
        feedPosts: posts,
        loadMore: loadMorePosts,
        isMoreFeedAvailable: posts.length > 0,
        lastPostHash: posts.length > 0 ? res.data.lastPostHash : "",
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_FEED_POSTS_FAILURE,
    });
    throw err;
  }
}

export async function getUsersPostsActivity(dispatch, token, page) {
  dispatch({
    type: actionTypes.GET_FEED_ACTIVITY_POSTS_REQUEST,
  });
  try {
    const res = await userFeedService.getUsersPostsActivity(token, page);
    dispatch({
      type: actionTypes.GET_FEED_ACTIVITY_POSTS_SUCCESS,
      payload: {
        activityFeedPosts: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_FEED_ACTIVITY_POSTS_FAILURE,
    });
    throw err;
  }
}

export async function createUserFeed({ dispatch, token, body }) {
  try {
    const res = await userFeedService.createUserPost(body, token);
    dispatch({
      type: actionTypes.CREATE_FEED_SUCCESS,
      payload: res.data.post,
    });
  } catch (err) {
    throw err;
  }
}
export async function updateUserComment({
  dispatch,
  token,
  body,
  parentPostId,
  commentCount,
}) {
  try {
    const res = await userFeedService.createUserPost(body, token);
    dispatch({
      type: actionTypes.UPDATE_COMMENT_COUNT,
      payload: {
        postId: parentPostId,
        commentCount: commentCount + 1,
      },
    });
  } catch (err) {
    throw err;
  }
}

export async function createUserFeedMultiple({ data, token }) {
  try {
    await userFeedService.createUserFeedMultiple(data, token);
  } catch (err) {
    throw err;
  }
}
