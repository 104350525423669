import { WorldcoinApi } from "../../utils/axios";
import Worldcoin from "../Icons/Worldcoin";

function WorldCoinLogin() {
  const handleLinkedInLogin = () => {
    const url = `https://id.worldcoin.org/authorize?response_type=token&client_id=${WorldcoinApi.clientId}&redirect_uri=${WorldcoinApi.redirectUrl}&scope=${WorldcoinApi.scope}`;

    window.location = url;
  };

  return (
    <div>
      <button
        className="bg-white authBtn border border-gray-400"
        onClick={handleLinkedInLogin}
      >
        <span className="flex pl-6 items-center space-x-2">
          <Worldcoin />
          <span className="text-bitClout">Continue with Worldcoin</span>
        </span>
      </button>
    </div>
  );
}

export default WorldCoinLogin;
