import { useContext } from "react";
import { Link } from "react-router-dom";
import { closeModal } from "../../../actions/profileModalActions";
import { StoreContext } from "../../../Context/StoreContext";
import RoomCard from "../../../VibehutUI/Card/RoomCard";
import moment from "moment";

export const SubscriptionCard = ({ subscription }) => {
  const { dispatch } = useContext(StoreContext);

  const renderStatusText = () => {
    const isSubscriptionActive =
      new Date(subscription.nextRenewal).getTime() > new Date().getTime();

    if (isSubscriptionActive) {
      return (
        <div className="text-green-500 absolute right-2 bottom-2">
          Active - ${subscription.room.monthlySubscriptionAmount}/mo{" "}
        </div>
      );
    } else {
      return (
        <div className="text-red-600 absolute right-2 bottom-2">
          Expired! - {moment(subscription.nextRenewal).format("MMMM Do YYYY")}
        </div>
      );
    }
  };

  return (
    <Link to={`/rooms/${subscription.room._id}`}>
      <div
        onClick={() => closeModal(dispatch)}
        className="cursor-pointer max-w-md relative mb-5 pt-2"
      >
        <RoomCard room={subscription.room} imgHeight="h-48" />
        {renderStatusText()}
      </div>
    </Link>
  );
};
