import React, { useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";
import axios from "../../utils/axios";
import Button from "../Button";
import DesoUserCard from "../DesoUserCard";

const InviteSearchDesoUser = ({ user, hideDesoCard }) => {
  const { state } = useContext(StoreContext);
  const { auth } = state;
  const [isInvited, setIsInvited] = React.useState(false);
  const invitedesoUser = (e, user) => {
    e.stopPropagation();
    e.preventDefault();
    setIsInvited(true);
    axios
      .post(
        "/bitclout/inviteDesoUser",
        {
          username: user.Username,
          publicKey: user.PublicKeyBase58Check,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.user?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
        } else {
        }
      })
      .catch((err) => console.log("ERRR"));
  };
  return (
    <div className="flex justify-between">
      {hideDesoCard ? null : <DesoUserCard user={user} isSidebarCard={true} />}
      {!auth?.user?.token ? undefined : auth?.user?._id ===
        user._id ? undefined : (
        <div className="mr-2 flex items-center">
          {isInvited ? (
            <Button variant="vibhut-primary-outline" size="vibhut-sm-outline">
              Sent
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                invitedesoUser(e, user);
              }}
            >
              Invite
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default InviteSearchDesoUser;
