import React from "react";
import { getUserData } from "../../services/userService";
import NoResult from "../Icons/NoResult";
import Loader from "../Loader";
import UserCard from "../UserCard";
import InviteSearchDesoUser from "./InviteSearchDesoUser";

function SearchBar({ value, handleChange, results = [], isSeraching = false }) {
  return (
    <>
      <div className="searchbarDiv ">
        <input
          type="text"
          className="searchbar select-none outline-none placeholder-darkGray search-input bg-white"
          placeholder="Search..."
          onChange={handleChange}
          value={value}
          autoComplete="new-value"
        />
        {value.length > 0 && (
          <div className="w-full py-5 relative h-screen  overflow-y-scroll custom-scrollbar">
            {!isSeraching ? (
              <div>
                {results.length > 0 ? (
                  results.map((user, index) => (
                    <div className="mt-1 hover:bg-frescoWhite dark:hover:bg-navyGray rounded-lg transition duration-200  py-1 ">
                      {user.PublicKeyBase58Check ? (
                        <InviteSearchDesoUser user={user} />
                      ) : (
                        <UserCard
                          key={index}
                          user={{
                            ...getUserData(user),
                            room: user.room,
                          }}
                          userState={user.userState}
                          isNormalCard={false}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="relative h-84vh w-full ">
                    <div className="absolute top-1/2 transform -translate-y-1/2 z-50 left-1/2 -translate-x-1/2 w-full">
                      <div>
                        <div className="flex justify-center">
                          <NoResult />
                        </div>

                        <p className="text-darkNight text-center dark:text-white text-lg font-bold font-ubuntu pt-2 w-full">
                          Nobody found
                        </p>
                        <p className="text-darkGray text-center dark:text-primaryGray text-base font-normal font-ubuntu pt-2 w-full ">
                          Adjust your search and try again.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchBar;
