import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MuxVideo from "@mux/mux-video-react";
import { useParams } from "react-router-dom";
import * as io from "socket.io-client";
import { Helmet } from "react-helmet";
import {
  getCallById,
  getLiveStream,
  getLiveStreamViewers,
  initLiveStreamPayment,
  liveStreamCallReaction,
} from "../services/callService";
import {
  checkDesoHolderToPublicKey,
  checkUserDiamondedToPost,
  getSingleRoom,
} from "../services/rooomService";
import Button from "../VibehutUI/Button";
import useWatchingCount from "../hooks/useWatchingCount";
import CopyButton from "../VibehutUI/CopyButton";
import { baseURL, domain, frontEndURL } from "../utils/axios";
import { useHistory } from "react-router-dom";
import Loader from "../VibehutUI/Loader";
import UnlockToWatchModal from "../components/events/UnlockToWatchModal";
import globals from "../globals";
import { StoreContext } from "../Context/StoreContext";
import { showLoginModal } from "../actions/uiModalActions";
import LiveStreamChat from "../components/chat/LiveStreamChat";
import LiveStreamViewers from "../components/call/LiveStreamViewers";
import ReactionMenu from "../videoChat/components/Buttons/ReactionMenu/ReactionMenu";
import { getAmountFromDiamondLevel } from "../utils/utils";
import { singleClaimedRoomRoutes, singleRoomRoutes } from "../routes";

function LiveVideoPage() {
  const history = useHistory();
  const { callId, roomId } = useParams();
  const [isFetching, setIsFetching] = React.useState(true);
  const [isFetchingRoom, setIsFetchingRoom] = React.useState(true);
  const [liveStream, setLiveStream] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [room, setRoom] = React.useState(null);
  const { watchingCount, isLive } = useWatchingCount(callId);
  const [liveEnded, setLiveEnded] = useState(false);
  const { state, dispatch } = useContext(StoreContext);
  const { auth } = state;
  const [chatRoom, setChatRoom] = useState("");
  const [viewers, setViewers] = useState([]);
  const socketRefLivestream = useRef();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getRoom = async (_roomId, token) => {
      try {
        const roomRes = await getSingleRoom(_roomId, token);
        const _room = roomRes.data?.room;
        setRoom(_room);
        await checkRoomRequirements(_room);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetchingRoom(false);
      }
    };

    if (roomId) {
      getRoom(roomId, auth?.user?.token);
    }
  }, [roomId, auth?.user?.token]);

  useEffect(() => {
    if (!room || errorMessage) {
      return;
    }
    getLiveStream(callId, auth?.user?.token)
      .then((res) => {
        const _liveStream = {
          ...res?.data?.liveStream,
          webInput: {
            ...res?.data?.liveStream.webInput,
            url: `http://localhost:3001/call/${callId}/mux/live`,
          },
        };
        if (_liveStream) {
          setLiveStream(_liveStream);
        }

        if (
          _liveStream?.muxAsset?.created_at &&
          new Date(Number(_liveStream.muxAsset.created_at) * 1000).getTime() +
            30000 >=
            new Date().getTime()
        ) {
          setIsReady(false);
          setTimeout(() => {
            setIsReady(true);
          }, 30000);
        } else {
          setIsReady(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [callId, room, auth?.user?.token, errorMessage]);

  useEffect(() => {
    if (!callId || !liveStream) return;
    getCallById(auth?.user?.token, callId)
      .then((res) => {
        const liveChatRoom = res.data.call?.chatRoom;
        if (liveChatRoom) {
          setChatRoom(liveChatRoom);
        }
      })
      .catch((err) => {
        setIsFetchingCallUsers(false);
      });
  }, [callId, liveStream]);

  const getViewers = () => {
    getLiveStreamViewers(callId)
      .then((res) => {
        setViewers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (callId && liveStream) {
      getViewers();

      const interval = setInterval(() => {
        getViewers();
      }, 1000 * 10);

      return () => {
        clearInterval(interval);
      };
    }
  }, [callId, liveStream]);

  useEffect(() => {
    if (liveStream?.isPaidLive && !liveStream.pass) {
      handleBuyPassClick();
    }
  }, [liveStream]);

  useEffect(() => {
    if (auth?.user?.token && callId) {
      socketRefLivestream.current = io.connect(`${baseURL}/livestream`, {
        auth: {
          token: auth.user.token,
        },
      });
      socketRefLivestream.current.on("connect", () => {
        socketRefLivestream.current.emit("join-livestream", {
          callId: callId,
        });
      });
    }

    return () => {
      if (socketRefLivestream.current) {
        socketRefLivestream.current.disconnect();
        socketRefLivestream.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId, auth?.user?.token]);

  const onUnlockLiveStream = async () => {
    if (!auth?.user?.token) return;
    try {
      const res = await initLiveStreamPayment(callId, auth.user.token);
      window.location.href = res.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyPassClick = () => {
    if (!auth?.user?.token) {
      showLoginModal(dispatch);
      return;
    }
    const title = `Unlock to Watch · $${liveStream.livePassCharge.toFixed(2)}`;
    globals.showModal(
      title,
      <>
        <UnlockToWatchModal
          kind="live"
          price={liveStream.livePassCharge}
          onUnlock={onUnlockLiveStream}
        />
      </>,
    );
  };

  const playbackId = liveStream?.muxAsset?.playback_ids?.[0]?.id;

  const renderComponent = useCallback(() => {
    if (!auth?.user?.token) {
      return (
        <div className="border rounded bg-white dark:bg-darkNight dark:border-matteGray border-lightGray font-ubuntu relative">
          <div className="p-4 flex items-center justify-between">
            <p className="text-darkGray text-sm">
              Please log in to access the live chat.
            </p>
            <Button onClick={() => showLoginModal(dispatch)}>Login</Button>
          </div>
        </div>
      );
    }
    if (liveStream.isPaidLive && !liveStream.pass) {
      <div className="border rounded bg-white dark:bg-darkNight dark:border-matteGray border-lightGray font-ubuntu relative">
        <div className="p-4 flex items-center justify-between">
          <p className="text-darkGray text-sm">
            This is a paid live stream. Please buy a pass to watch.
          </p>
        </div>
      </div>;
    }
    if (liveEnded || (!isLive && playbackId)) {
      return (
        <div className="border rounded bg-white dark:bg-darkNight dark:border-matteGray border-lightGray font-ubuntu relative">
          <div className="p-4 flex items-center justify-between">
            <p className="text-darkGray text-sm">
              Live stream ended. Thanks for watching.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="border pb-16 rounded bg-white dark:bg-darkNight dark:border-matteGray border-lightGray font-ubuntu relative">
        <LiveStreamChat roomId={chatRoom} fromCall={false} isLive={isLive} />
      </div>
    );
  }, [
    auth?.user?.token,
    chatRoom,
    dispatch,
    isLive,
    liveEnded,
    liveStream,
    playbackId,
  ]);

  const startCall = () => {
    history.push(`/rooms/${roomId}`);
  };
  const getModalTitle = () => {
    let title = `Viewers (${viewers.length})`;
    const guestUserCount = watchingCount - viewers.length;
    if (guestUserCount > 0) {
      title = `${title} · Guests (${guestUserCount})`;
    }

    return title;
  };
  const triggerViewersModal = () => {
    globals.showModal(getModalTitle(), <LiveStreamViewers viewers={viewers} />);
  };

  const checkDesoPostRoomAccessable = useCallback(
    async (roomId) => {
      try {
        const token = auth?.user?.token;
        const sender = (await checkUserDiamondedToPost(roomId, token)).data;
        return sender;
      } catch (error) {}
    },
    [auth?.user?.token],
  );

  const checkDesoHodlerRoomAccessable = useCallback(
    async (roomId) => {
      try {
        const token = auth?.user?.token;
        const sender = (await checkDesoHolderToPublicKey(roomId, token)).data;
        return sender;
      } catch (error) {}
    },
    [auth?.user?.token],
  );

  const requiredDiamonds = useCallback((room) => {
    const diamondLevel = room?.desoPostRoom?.diamondLevel;
    return getAmountFromDiamondLevel(diamondLevel);
  }, []);
  const roomPath = useMemo(
    () =>
      room?.slug
        ? singleClaimedRoomRoutes.path.replace(":slug", room.slug)
        : singleRoomRoutes.path.replace(":roomId", room?._id),
    [room],
  );
  const checkRoomRequirements = useCallback(
    async (room) => {
      if (room?.isNftCollectionRoom && !auth?.user) {
        setErrorMessage("Please login to access nft gated livestream");
        return;
      }
      if (room?.isNftCollectionRoom && auth?.user && !room.isAccessible) {
        setErrorMessage(
          "You need to own at least one NFT in this collection to watch livestream.",
        );
        return;
      }
      if (
        room?.desoPostRoom?.postHashHex &&
        room?.desoPostRoom?.diamondLevel &&
        auth?.user?._id !== room?.owner?._id
      ) {
        if (!auth?.user?.bitclout_data?.bitclout_publickey) {
          setErrorMessage(
            "To access this livestream you need to login with your deso account",
          );
          return;
        }
        const isRoomAccessible = await checkDesoPostRoomAccessable(room?._id);
        if (!isRoomAccessible) {
          setErrorMessage(
            `To access this livestream you need to send $${requiredDiamonds(
              room,
            )} diamond on post`,
          );
          return;
        }
      }
      if (
        (room?.isCCRoom || room?.isDAORoom) &&
        auth?.user?._id !== room?.owner?._id
      ) {
        if (!auth?.user?.bitclout_data?.bitclout_publickey) {
          setErrorMessage(
            "To access this livestream you need to login with your deso account",
          );
          return;
        }
        const isRoomAccessible = await checkDesoHodlerRoomAccessable(room._id);
        if (!isRoomAccessible) {
          setErrorMessage(
            `To access this livestream you need to hold ${
              room?.isDAORoom && room?.isDAORoom
                ? "Creator Coin and DAO Token"
                : room?.isCCRoom
                  ? "Creator Coin"
                  : "DAO Token"
            } of users mention in room `,
          );
          return;
        }
      }
      return;
    },
    [
      auth?.user,
      checkDesoHodlerRoomAccessable,
      checkDesoPostRoomAccessable,
      requiredDiamonds,
    ],
  );

  if (isFetching || isFetchingRoom) {
    return (
      <div className="flex h-480px justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (!room) {
    return (
      <div className="flex h-480px justify-center items-center">
        <h5 className="text-black dark:text-frescoWhite">Room not Found</h5>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex h-480px justify-center items-center">
        <h5 className="text-black dark:text-frescoWhite">{errorMessage}</h5>
      </div>
    );
  }

  if (!liveStream || !liveStream?.isVibehutLive) {
    return (
      <div className="flex h-480px justify-center items-center">
        <h5 className="text-black dark:text-frescoWhite">
          No live stream available for this call.
        </h5>
      </div>
    );
  }

  const renderAlternateText = () => {
    if (liveEnded || (!isLive && playbackId)) {
      return "Live stream ended. Thanks for watching.";
    }
    if (liveStream.isPaidLive && !liveStream.pass) {
      return "This is a paid live stream. Please buy a pass to watch.";
    }
    return "Live stream will begin shortly";
  };

  const sendReactionInCall = async (username, userId, emoji, streakCount) => {
    try {
      if (!auth?.user?.token) {
        showLoginModal(dispatch);
        return;
      }
      if (callId) {
        const body = {
          username,
          emoji,
          streakCount,
          roomId,
        };
        await liveStreamCallReaction(callId, auth?.user?.token, body);
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  // isReady && playbackId
  return (
    <div className="font-ubuntu pt-6 flex md:flex-row flex-col">
      {/* DN TODO: add helmet */}
      <Helmet>
        <title>{`${room?.name || "Room"} | Vibehut`}</title>
        <meta name="description" content={`Live Stream in ${room?.name}`} />
        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content={`https://vibehut.io${roomPath}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${room?.name} | Vibehut`} />
        <meta
          property="og:description"
          content={`Live Stream in ${room?.name}`}
        />
        <meta
          property="og:image"
          content={
            room?.cover?.urls?.regular ||
            `${process.env.PUBLIC_URL}/images/Frame 61.png`
          }
        />

        {/* <!-- Twitter Meta Tags -- > */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={domain} />
        <meta
          property="twitter:url"
          content={`https://vibehut.io${roomPath}`}
        />
        <meta name="twitter:title" content={`${room?.title}`} />
        <meta
          name="twitter:description"
          content={`Live Stream in ${room?.name}`}
        />
        <meta
          name="twitter:image"
          content={
            room?.cover?.urls?.regular ||
            `${process.env.PUBLIC_URL}/images/Frame 61.png`
          }
        />
      </Helmet>
      <div className="md:w-3/4 w-full pr-2">
        <h3 className="dark:text-frescoWhite text-4xl font-bold">
          {room.name}
        </h3>
        {liveEnded || !isLive || !Boolean(isReady) || !Boolean(playbackId) ? (
          <div className="my-5 rounded-2xl flex flex-col justify-center items-center w-full h-480px bg-black">
            <p className="text-frescoWhite">{renderAlternateText()}</p>
            {liveStream.isPaidLive && !liveStream.pass && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={handleBuyPassClick}
              >
                Buy Pass
              </Button>
            )}
          </div>
        ) : (
          <div className="my-5 rounded-2xl">
            <style>
              {`video {
                display: block;
                width: 100%;
                aspect-ratio: 16 / 9;
                margin: 1rem 0 2rem;
              }`}
            </style>
            <MuxVideo
              playbackId={playbackId}
              metadata={{
                video_id: callId,
                video_title: "Live Video",
                viewer_user_id: auth?.user?._id ? auth.user._id : "",
              }}
              streamType="live"
              autoPlay
              controls
              muted
              onEnded={() => {
                setLiveEnded(true);
              }}
            />
          </div>
        )}
        <div className="flex flex-row items-center justify-end space-x-4">
          <ReactionMenu
            sendReactionInCall={sendReactionInCall}
            reactionFromLive={true}
          />
          <Button size="vibhut-sm" variant="vibhut-primary" onClick={startCall}>
            Join Call
          </Button>
          <div
            className="dark:text-frescoWhite text-2xl cursor-pointer"
            onClick={triggerViewersModal}
          >
            🤵‍♂️ {watchingCount}
          </div>
          <CopyButton
            url={`${frontEndURL}/rooms/${roomId}/calls/${callId}/live`}
            bgColor="transparent"
          />
        </div>
      </div>

      <div
        className="mt-3 md:w-1/4 w-full pb-8 md:gap-0 gap-2"
        style={{
          height: "calc(100% - 3.9rem)",
          overflowY: "auto",
        }}
      >
        {!!chatRoom && <>{renderComponent()}</>}
      </div>
    </div>
  );
}

export default LiveVideoPage;
