import * as actionTypes from "../constants";

function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.LOGIN_WITH_BITCLOUT_REQUEST:
    case actionTypes.LOGIN_WITH_TWITTER_REQUEST:
    case actionTypes.LOGIN_WITH_METAMASK_REQUEST:
    case actionTypes.GET_LOGGEDIN_USER_DATA_REQUEST:
    case actionTypes.UPDATE_USER_ENS_REQUEST:
    case actionTypes.UPDATE_USER_PROFILE_REQUEST:
    case actionTypes.LOGIN_WITH_CUSTOM_WALLET_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOGIN_WITH_BITCLOUT_SUCCESS:
    case actionTypes.LOGIN_WITH_METAMASK_SUCCESS:
    case actionTypes.LOGIN_WITH_TWITTER_SUCCESS:
    case actionTypes.LOGIN_WITH_WORLDCOIN_SUCCESS:
    case actionTypes.LOGIN_WITH_CUSTOM_WALLET_SUCCESS:
    case actionTypes.LOGIN_AS_GUEST_SUCCESS: {
      try {
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        return { ...state, user: action.payload.user, isFetching: false };
      } catch (error) {
        return { ...state, user: action.payload.user, isFetching: false };
      }
    }
    case actionTypes.UPDATE_USER_SUBSCRIPTION_PLAN: {
      const user = {
        ...state.user,
        serviceRole: action.payload.serviceRole,
        wallet_balance: state.user.wallet_balance - action.payload.planPrice,
      };
      try {
        localStorage.setItem("user", JSON.stringify(user));
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      } catch (error) {
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      }
    }
    case actionTypes.GET_LOGGEDIN_USER_DATA_SUCCESS: {
      const user = {
        ...state.user,
        wallet_balance: action.payload.user.wallet_balance,
      };
      try {
        localStorage.setItem("user", JSON.stringify(user));
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      } catch (error) {
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      }
    }
    case actionTypes.UPDATE_USER_ENS_SUCCESS: {
      const user = {
        ...state.user,
        metamask_data: action.payload.user.metamask_data,
      };
      try {
        localStorage.setItem("user", JSON.stringify(user));
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      } catch (error) {
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      }
    }

    case actionTypes.UPDATE_USER_PROFILE_SUCCESS: {
      const user = {
        ...state.user,
        cover: action.payload.user.cover,
        socialLinks: action.payload.user.socialLinks,
        services: action.payload.user.services,
        promotion: action.payload.user.promotion,
        location: action.payload.user.location,
        ...(action.payload.user?.vibehutUsername && {
          vibehutUsername: action.payload.user.vibehutUsername,
        }),
        ...(action.payload.user.referralCode && {
          referralCode: action.payload.user.referralCode,
        }),
        vibehutDescription: action.payload.user?.vibehutDescription,
      };
      try {
        localStorage.setItem("user", JSON.stringify(user));
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      } catch (error) {
        return {
          ...state,
          user: user,
          isFetching: false,
        };
      }
    }
    case actionTypes.GET_AUTH_USER_REFERRAL_REQUEST:
    case actionTypes.UPDATE_AUTH_USER_REFERRAL_REQUEST: {
      return { ...state, isReferralCodeFetching: true };
    }

    case actionTypes.GET_AUTH_USER_REFERRAL_SUCCESS:
    case actionTypes.UPDATE_AUTH_USER_REFERRAL_SUCCESS: {
      return {
        ...state,
        isReferralCodeFetching: false,
        referral: action.payload,
      };
    }

    case actionTypes.GET_AUTH_USER_REFERRAL_FAILURE:
    case actionTypes.UPDATE_AUTH_USER_REFERRAL_FAILURE: {
      return {
        ...state,
        isReferralCodeFetching: false,
      };
    }

    case actionTypes.UPDATE_USER_PROFILE_FAILURE: {
      return { ...state, isFetching: false };
    }

    case actionTypes.LOGIN_WITH_BITCLOUT_FAILURE:
    case actionTypes.LOGIN_WITH_TWITTER_FAILURE:
    case actionTypes.LOGIN_WITH_METAMASK_FAILURE:
    case actionTypes.GET_LOGGEDIN_USER_DATA_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.LOGOUT: {
      localStorage.removeItem("user");
      localStorage.removeItem("myVibehutRooms");
      localStorage.removeItem("myVibehutSubsRooms");
      localStorage.removeItem("myVibehutRecentRooms");
      localStorage.removeItem("ChatRoomStore");
      localStorage.removeItem("ChatConversationStore");
      localStorage.removeItem("ChatStore");
      localStorage.removeItem("ApprovedPromotion");
      return { ...state, user: null, showInCompleteProfileModal: false };
    }

    case actionTypes.GET_AUTH_USER_DATA_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.GET_AUTH_USER_DATA_SUCCESS:
      const updatedUser = {
        ...action.payload.user,
        token: state?.user?.token,
      };
      try {
        localStorage.setItem("user", JSON.stringify(updatedUser));
        return {
          ...state,
          user: updatedUser,
          isFetching: false,
          showInCompleteProfileModal: !updatedUser?.isProfileCompleted,
        };
      } catch (error) {
        return {
          ...state,
          user: updatedUser,
          isFetching: false,
          showInCompleteProfileModal: !updatedUser?.isProfileCompleted,
        };
      }

    case actionTypes.VIBEBUCKS_WITHDRAW_SUCCESS: {
      const updatedUser = {
        ...state.user,
        wallet_balance: action.payload,
      };
      try {
        localStorage.setItem("user", JSON.stringify(updatedUser));
        return {
          ...state,
          user: updatedUser,
        };
      } catch (error) {
        return {
          ...state,
          user: updatedUser,
        };
      }
    }
    case actionTypes.GET_AUTH_USER_DATA_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.UPDATE_USER_STATE:
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.userState) {
        user.userState.status = null;
      }
      try {
        localStorage.setItem("user", JSON.stringify(user));
        return {
          ...state,
          user: user,
        };
      } catch (error) {
        return {
          ...state,
          user: user,
        };
      }

    default:
      return state;
  }
}

export default authReducer;
