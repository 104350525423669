import * as actionTypes from "../constants";

const initialState = {
  mySubscriptions: [],
  isFetching: false,
  mySubscribers: [],
};

function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUBSCRIPTION_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        mySubscriptions: [action.payload, ...state.mySubscriptions],
        isFetching: false,
      };
    }

    case actionTypes.CREATE_SUBSCRIPTION_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.GET_MY_SUBSCRIBERS_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_MY_SUBSCRIBERS_SUCCESS: {
      return {
        ...state,
        mySubscribers: action.payload,
        isFetching: false,
      };
    }

    case actionTypes.GET_MY_SUBSCRIBERS_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.GET_MY_SUBSCRIPTIONS_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_MY_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        mySubscriptions: action.payload,
        isFetching: false,
      };
    }

    case actionTypes.GET_MY_SUBSCRIPTIONS_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.UN_SUBSCRIPTION_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.UN_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        mySubscriptions: [action.payload, ...state.mySubscriptions],
        isFetching: false,
      };
    }

    case actionTypes.UN_SUBSCRIPTION_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

export default subscriptionReducer;
