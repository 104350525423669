import React from "react";

function Home() {
  return (
    <svg
      width="28"
      height="28"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.005 4.40464C14.4439 3.86512 13.5569 3.86512 12.9958 4.40464L4.30687 12.7598C3.90878 13.1426 3.89638 13.7757 4.27919 14.1738C4.66199 14.5719 5.29503 14.5842 5.69313 14.2014L6.14246 13.7694V21.2003C6.14246 21.8941 6.3743 22.5863 6.82554 23.1193C7.28104 23.6574 7.93808 24.0005 8.66634 24.0005H19.3335C20.0618 24.0005 20.7188 23.6574 21.1743 23.1193C21.6255 22.5863 21.8574 21.8941 21.8574 21.2003V13.7684L22.3077 14.2014C22.7058 14.5842 23.3388 14.5719 23.7216 14.1738C24.1045 13.7757 24.0921 13.1426 23.694 12.7598L15.005 4.40464ZM19.8574 11.8452L14.0004 6.21327L8.14246 11.8462V21.2003C8.14246 21.4614 8.23171 21.685 8.35204 21.8271C8.46811 21.9642 8.58628 22.0005 8.66634 22.0005H19.3335C19.4136 22.0005 19.5317 21.9642 19.6478 21.8271C19.7681 21.685 19.8574 21.4614 19.8574 21.2003V11.8452Z"
        fill="currentColor"
      />
      <rect
        x="14"
        y="15"
        width="3"
        height="3"
        rx="0.449374"
        fill="currentColor"
      />
    </svg>
  );
}

export default Home;
