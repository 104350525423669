import React from 'react';

function Add({ color = '#B5B5B5', ratio = 13 }) {
  return (
    <svg width={ratio} height={ratio} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.931287 6.82386C0.930804 7.26479 1.28786 7.62185 1.72879 7.62136L6.03091 7.61665L6.0262 11.9187C6.02571 12.3596 6.38277 12.7167 6.82371 12.7162C7.26464 12.7157 7.62248 12.3579 7.62296 11.917L7.62768 7.6149L11.9019 7.61021C12.3428 7.60973 12.7007 7.25189 12.7011 6.81095C12.7016 6.37002 12.3446 6.01296 11.9036 6.01345L7.62943 6.01813L7.63411 1.74387C7.6346 1.30294 7.27754 0.94588 6.83661 0.946363C6.39567 0.946847 6.03783 1.30469 6.03735 1.74562L6.03266 6.01988L1.73054 6.0246C1.28961 6.02508 0.93177 6.38292 0.931287 6.82386Z"
        fill={color}
      />
    </svg>
  );
}

export default Add;
