import React from "react";
import DeSo from "../Icons/DeSo";
import EventIcon from "../Icons/EventIcon";
import RoomIcon from "../Icons/RoomIcon";
import WaveIcon from "../Icons/WaveIcon";

interface AvatarProps {
  src: string;
  size?: string;
  username: any;
  containerStyle?: any;
  notification?: any;
  widthClassName?: string;
  heightClassName?: string;
  isRoomOwner?: boolean;
  isDesoCard?: boolean;
  showGreenDot?: boolean;
}

function Avatar({
  src,
  size = "lg",
  username,
  containerStyle,
  notification = null,
  widthClassName = "",
  heightClassName = "",
  isRoomOwner = false,
  isDesoCard = false,
  showGreenDot = false,
}: AvatarProps) {
  const [srcError, setSrcError] = React.useState(false);

  const renderImage = () => {
    if (isDesoCard) {
      return (
        <div
          className={`avatar-${size} rounded-full bg-transparent flex justify-center items-center  ${widthClassName} ${heightClassName}`}
        >
          <DeSo ratio={24} />
        </div>
      );
    }

    if (src?.length > 0) {
      if (srcError) {
        return (
          <div
            className={`avatar-${size} rounded-full bg-primary flex justify-center items-center  ${widthClassName} ${heightClassName}`}
          >
            <span className="text-white text-lg">
              {username?.charAt(0)?.toUpperCase()}
            </span>
          </div>
        );
      }

      return (
        <div className={`avatar-${size} ${widthClassName} ${heightClassName}`}>
          <img
            alt={username}
            onError={(e) => {
              setSrcError(true);
            }}
            src={src}
            className={`avatar-${size} rounded-full ${widthClassName} ${heightClassName}`}
          />
        </div>
      );
    }

    return (
      <div
        className={`avatar-${size} rounded-full bg-primary flex justify-center items-center  ${widthClassName} ${heightClassName}`}
      >
        <span className="text-white text-lg">
          {username?.charAt(0)?.toUpperCase()}
        </span>
      </div>
    );
  };

  return (
    <div style={containerStyle} className="relative rounded-full">
      {renderImage()}

      {notification &&
        (notification === "room_update" ||
          notification === "user_joined_room" ||
          notification === "call_connected_in_room" ||
          notification === "composition_created" ||
          notification === "invite_user") && (
          <div className="absolute top-5  p-0.5 left-6">
            <RoomIcon />
          </div>
        )}
      {notification &&
        (notification === "user_rsvp" || notification === "rsvp_reminder") && (
          <div className="absolute top-5  p-0.5 left-6">
            <EventIcon />
          </div>
        )}
      {notification &&
        (notification === "waved" ||
          notification === "waved_back" ||
          notification === "waved_joined") && (
          <div className="absolute top-5  p-0.5 left-6">
            <WaveIcon />
          </div>
        )}
      {showGreenDot && (
        <div className="bg-white absolute -bottom-1 p-0.5 right-0 h-2.5 w-2.5  my-1 rounded-full">
          <div className="h-1.5 rounded-full w-1.5 bg-primaryGreen z-2"></div>
        </div>
      )}
    </div>
  );
}

export default Avatar;
