import React from "react";
import Attachment from "../Icons/Attachment";
import Loader from "../Loader";

function AttachmentButton({
  setAttachmentFile,
  isMediaUploading,
  isDisable,
  isCallChat = false,
}) {
  const handleChange = (e) => {
    const file = e.target.files[0];
    setAttachmentFile(file);
  };
  return !isMediaUploading ? (
    <div>
      <input
        id="files"
        type="file"
        onChange={handleChange}
        accept="image/png, image/gif, image/jpeg, image/webp"
        className="hidden"
        disabled={isDisable}
      />
      <label htmlFor="files" className="cursor-pointer">
        <Attachment />
      </label>
    </div>
  ) : (
    <div
      className={`absolute  ${
        isCallChat ? "right-5 bottom-[3px]" : "right-12 bottom-2.5"
      }`}
    >
      <Loader ratio="h-5 w-5" />
    </div>
  );
}

export default AttachmentButton;
