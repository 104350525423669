function PromotionIcon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill={color}
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M412.626 290.038a8.083 8.083 0 00-1.248 11.364 8.064 8.064 0 006.311 3.027 8.054 8.054 0 005.053-1.777c22.474-18.022 35.364-44.849 35.364-73.599 0-28.75-12.89-55.576-35.362-73.599a8.084 8.084 0 00-10.116 12.612c18.627 14.938 29.31 37.167 29.31 60.986-.001 23.82-10.684 46.048-29.312 60.986z"></path>
      <path d="M483.302 137.925c-18.346-26.18-43.765-46.03-73.508-57.409a8.085 8.085 0 00-5.777 15.101c54.918 21.006 91.815 74.63 91.815 133.435 0 58.805-36.897 112.429-91.815 133.435a8.084 8.084 0 105.778 15.101c29.745-11.378 55.163-31.229 73.508-57.409C502.077 293.39 512 261.879 512 229.053c0-32.827-9.923-64.338-28.698-91.128zM342.232 26.947c-22.289 0-40.421 18.132-40.421 40.421v9.833l-147.915 48.233c-15.216 4.963-26.836 16.496-32.149 30.861H72.758C32.639 156.295 0 188.933 0 229.053c0 40.119 32.639 72.758 72.758 72.758h4.219l33.624 159.706c2.87 13.638 15.069 23.536 29.006 23.536h57.959c16.344 0 29.642-13.298 29.642-29.642v-98.833l74.602 24.327v9.833c0 22.289 18.132 40.421 40.421 40.421 22.289 0 40.421-18.132 40.421-40.421V67.368c.001-22.288-18.132-40.421-40.42-40.421zM83.381 285.642H72.758c-31.203 0-56.589-25.387-56.589-56.589 0-31.203 25.387-56.589 56.589-56.589h45.836a53.128 53.128 0 00-.026 1.648v109.883c0 .55.008 1.101.026 1.648H83.708a7.13 7.13 0 00-.327-.001zm127.658 65.663V455.41c0 7.43-6.044 13.474-13.474 13.474h-57.959c-6.335 0-11.88-4.499-13.185-10.698L93.5 301.811h28.246c5.313 14.365 16.934 25.899 32.149 30.861l57.145 18.634h-.001zm90.772 12.593l-142.902-46.601a34.95 34.95 0 01-24.172-33.305V174.111a34.953 34.953 0 0124.172-33.306l142.902-46.599v269.692zm64.673 26.839c0 13.372-10.88 24.253-24.253 24.253s-24.253-10.88-24.253-24.253v-15.513c.003-.126.003-.25 0-.374V83.258a7.772 7.772 0 000-.374V67.368c0-13.372 10.88-24.253 24.253-24.253s24.253 10.88 24.253 24.253v323.369z"></path>
    </svg>
  );
}

export default PromotionIcon;
