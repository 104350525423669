import { useEffect } from 'react';
import useLocalAudioToggle from '../useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../useLocalVideoToggle/useLocalVideoToggle';

function useKeyboardControls() {
  const [, toggleAudioEnabled, handleAudioEnabled, handleAudioDisabled] = useLocalAudioToggle();
  const [, toggleVideoEnabled] = useLocalVideoToggle();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'd') {
        toggleAudioEnabled();
      } else if (event.ctrlKey && event.key === 'e') {
        toggleVideoEnabled();
      }
      if (event.ctrlKey && event.key === ' ') {
        handleAudioEnabled();
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === ' ') {
        handleAudioDisabled();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [toggleAudioEnabled, toggleVideoEnabled]);
}

export default useKeyboardControls;
