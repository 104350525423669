import React from "react";

const Warning = () => {
  return (
    <svg
      width="20"
      height="20"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57563 3.21665L1.51729 15C1.37177 15.252 1.29477 15.5377 1.29395 15.8288C1.29314 16.1198 1.36854 16.4059 1.51265 16.6588C1.65676 16.9116 1.86457 17.1223 2.11538 17.2699C2.3662 17.4174 2.65129 17.4968 2.94229 17.5H17.059C17.35 17.4968 17.6351 17.4174 17.8859 17.2699C18.1367 17.1223 18.3445 16.9116 18.4886 16.6588C18.6327 16.4059 18.7081 16.1198 18.7073 15.8288C18.7065 15.5377 18.6295 15.252 18.484 15L11.4256 3.21665C11.2771 2.97174 11.0679 2.76925 10.8183 2.62872C10.5687 2.48819 10.2871 2.41437 10.0006 2.41437C9.71418 2.41437 9.43257 2.48819 9.18296 2.62872C8.93336 2.76925 8.72419 2.97174 8.57563 3.21665V3.21665Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.5V10.8333"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.1667H10.0088"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Warning;
