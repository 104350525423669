import { getUserData } from '../../services/userService'
import GeneralRoom from '../../VibehutUI/Icons/GeneralRoom'
import { Persona } from '../../types'
import AIReplySwitch from './AIReplySwitch'
interface Props {
    persona: Persona
}

function PersonaChatHeader({ persona }: Props) {

    return (
        <div className="w-full">
            <div className={`flex items-center justify-between w-full`}>
                <div className="w-full">
                    <div className="flex items-center gap-3 w-full">
                        <div className={`w-12 h-10 relative`}>
                            {persona?.avatar ? (
                                <img
                                    src={persona?.avatar}
                                    alt="room"
                                    className="w-full h-full rounded-lg"
                                    loading="lazy"
                                />
                            ) : (
                                <GeneralRoom width={48} height={40} />
                            )}
                        </div>
                        <div className="flex flex-col">
                            <div className='flex gap-2 items-center'>
                                <p className={` text-matteGray font-medium dark:text-frescoWhite custom-two-line-clamp`}
                                >
                                    {persona.name}
                                </p>
                                <div className='md:hidden block' >
                                    <AIReplySwitch />
                                </div>

                            </div>
                            <p className="text-xs text-darkGray cursor-pointer" >Creator ·  {getUserData(persona.owner).username}</p>
                        </div>
                    </div>
                </div>
                <div className='hidden md:block w-40' >
                    <AIReplySwitch />
                </div>

            </div>
        </div>
    )
}

export default PersonaChatHeader

