import React, { useMemo } from "react";
import { getUserData } from "../../services/userService";
import UserCard from "../UserCard";
import DesoUserCard from "../DesoUserCard";
import { rsvpStatusTypes } from "../../constants";
import moment from "moment";

const SendInvite = ({
  user,
  setInvitedUsers,
  invitedUsers,
  desoInvitedUsers,
  setDesoInvitedUsers,
  rsvpUser,
}) => {
  const alreadyInvitedUser = useMemo(() => {
    return rsvpUser?.find(
      (rsvp) =>
        rsvp.user._id === user._id && rsvp.status === rsvpStatusTypes.INVITED,
    );
  }, [rsvpUser, user._id]);
  const isUserChecked = (userId) => {
    const data =
      (invitedUsers &&
        invitedUsers?.length > 0 &&
        invitedUsers?.some((item) => {
          return item === userId;
        })) ||
      false;
    return data;
  };
  const isDesoUserChecked = (publicKey) => {
    const data =
      (desoInvitedUsers &&
        desoInvitedUsers?.length > 0 &&
        desoInvitedUsers?.some((item) => {
          return item === publicKey;
        })) ||
      false;
    return data;
  };
  const handleUserSelect = (e) => {
    const user = JSON.parse(e.target.value);
    const isDesoUser = user?.PublicKeyBase58Check;
    if (isDesoUser) {
      if (isDesoUserChecked(user.PublicKeyBase58Check)) {
        const _users = desoInvitedUsers?.filter(
          (f) => f !== user.PublicKeyBase58Check,
        );
        setDesoInvitedUsers(_users);
        return;
      }
      setDesoInvitedUsers((prev) => {
        return [...prev, user.PublicKeyBase58Check];
      });
    } else {
      if (isUserChecked(user._id)) {
        const _users = invitedUsers?.filter((f) => f !== user._id);
        setInvitedUsers(_users);
        return;
      }
      setInvitedUsers((prev) => {
        return [...prev, user._id];
      });
    }
  };
  const isCheckInput = (user) => {
    const isDesoUser = user?.PublicKeyBase58Check;
    if (isDesoUser) {
      return isDesoUserChecked(user.PublicKeyBase58Check);
    }
    return isUserChecked(user._id);
  };

  return (
    <div className="flex flex-col py-2">
      <div className="w-full flex items-center justify-between">
        {user?.PublicKeyBase58Check ? (
          <DesoUserCard user={user} />
        ) : (
          <UserCard
            user={{
              ...getUserData(user),
              room: user.room,
            }}
            userState={user.userState}
            isSearchResultCard={true}
            fromCallProfile={true}
          />
        )}
        <div className="px-2">
          <input
            type="checkbox"
            name="roomTypes"
            className="appearance-none h-[18px] w-[18px] border-primaryGray rounded-full transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
            checked={isCheckInput(user) || !!alreadyInvitedUser}
            disabled={!!alreadyInvitedUser}
            value={JSON.stringify(user)}
            onChange={handleUserSelect}
          />
        </div>
      </div>
      {!!alreadyInvitedUser && (
        <p className="text-darkGray text-xs ml-3 italic">
          Already Invited on{" "}
          {moment(alreadyInvitedUser.createdAt).format("MMMM Do YYYY")}
        </p>
      )}
    </div>
  );
};

export default SendInvite;
