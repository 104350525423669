import React, { useMemo } from 'react';

function RecordingStart({ isMobile = false, isDisable = false }) {
  const color = useMemo(() => {
    if (isDisable) {
      return 'rgb(156 163 175 / 1)';
    }
    if (isMobile) {
      return '#fff';
    }
    return '#1F2023';
  }, [isDisable, isMobile]);
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.25" stroke={color} strokeWidth="1.5" />
      <circle cx="8" cy="8" r="4" fill={color} />
    </svg>
  );
}

export default RecordingStart;
