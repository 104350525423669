import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  Fragment,
  useState,
  useEffect,
  SetStateAction,
  useContext,
} from "react";
import Close from "../Icons/Close";
import UserCardSkeleton from "../Skeletons/UserCardSkeleton";
import {
  searchVibhutAndDesoUsers,
  getRelevantUsers,
} from "../../services/userService";
import { getUserData } from "../../utils/vibehutUtils";
import SendInvite from "./SendInvite";
import useDebounce from "../../hooks/useDebounce";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import InviteSearchBar from "./InviteSearchBar";
import Loader from "../Loader";
import DesoUserCard from "../../components/CardComponents/DesoUserCard";
import UserCard from "../../components/CardComponents/UserCard";
import Button from "../../../VibehutUI/Button";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import LimitedTextarea from "../../../VibehutUI/LimitedTextarea";
import { showToast } from "../../../actions/toastActions";
import { StoreContext } from "../../../Context/StoreContext";
import { VibehutDataContext } from "../../components/VibehutDataProvider";
interface InviteUserModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  roomId: string;
  callsMap: any;
  callId: string;
}

export default function InviteUserModal({
  isOpen,
  setIsOpen,
  roomId,
  callsMap,
  callId,
}: InviteUserModalProps) {
  // const { invitedUsers: alreadyInvitedUserList } =
  //   useContext(VibehutDataContext);
  const { invitedUsers: alreadyInvitedUserList } =
    useContext(VibehutDataContext);
  const { dispatch } = useContext(StoreContext);
  const { authUser } = useAuthUser();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [relevantUsers, setRelevantUsers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [invitationMsg, setInvitationMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<any[]>([]);
  const [desoInvitedUsers, setDesoInvitedUsers] = useState<any[]>([]);
  const [invitedUserList, setInvitedUserList] = useState<any[]>([]);
  const [nonInvitedUsersList, setNonInvitedUserList] = useState<any[]>([]);
  const { inviteUsersFun } = useVibehutData(globals.roomId);

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };

  const handleInviteSent = async () => {
    try {
      setIsLoading(true);
      await inviteUsersFun(
        desoInvitedUsers,
        invitedUsers,
        roomId,
        invitationMsg,
        authUser.token,
      );
      showToast(
        dispatch,
        "success",
        "Invitations has been sent successfully.",
        2000,
      );
      setIsOpen(false);
    } catch (error) {
      showToast(
        dispatch,
        "Error",
        "Facing Error while sending Invitation, Please again later",
        2000,
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearchLoading(true);
      searchVibhutAndDesoUsers(searchTerm)
        .then((response: { data: { users: any } }) => {
          const users = response.data.users;
          const callUserIds: any[] = [];
          callsMap[callId].map((user: { _id: any }) =>
            callUserIds.push(user._id),
          );
          const filterUsers = users.filter(
            (user: any) => !callUserIds.includes(user._id),
          );
          setSearchResults(filterUsers);
          setIsSearchLoading(false);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setIsLoading(true);
    getRelevantUsers(authUser?.token)
      .then((response: { data: any[] }) => {
        const callUserIds: any[] = [];
        callsMap[callId].map((user: { _id: any }) =>
          callUserIds.push(user._id),
        );
        const filterUsers = response.data.filter(
          (user: { _id: any; role: string }) =>
            !callUserIds.includes(user._id) && user.role === "user",
        );
        setRelevantUsers(filterUsers);
        setIsLoading(false);
      })
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, callId]);
  useEffect(() => {
    // Filter relevant users based on whether they are already invited
    const InvitedUsersList = relevantUsers.filter((user) => {
      return alreadyInvitedUserList.includes(user._id);
    });
    setInvitedUserList(InvitedUsersList);

    // Filter relevant users based on whether they are not invited
    const NonInvitedUsersList = relevantUsers.filter((user) => {
      return !alreadyInvitedUserList.includes(user._id);
    });
    setNonInvitedUserList(NonInvitedUsersList);
  }, [relevantUsers, alreadyInvitedUserList]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block align-bottom bg-white shadow-xl w-428px
                rounded-2xl text-left overflow-hidden transform transition-all font-ubuntu mb-14 sm:my-8 sm:align-middle"
              >
                <div className="bg-white p-3 border-b border-frescoWhite  ">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black  w-full">
                      All People
                    </p>
                    <span
                      className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div>
                  <div className="px-6 pt-3 custom-scrollbar">
                    <div>
                      <InviteSearchBar
                        handleChange={handleChange}
                        results={searchResults}
                        value={searchTerm}
                        setValue={setSearchTerm}
                        invitedUsers={invitedUsers}
                        setInvitedUsers={setInvitedUsers}
                        desoInvitedUsers={desoInvitedUsers}
                        setDesoInvitedUsers={setDesoInvitedUsers}
                        isLoading={isSearchLoading}
                      />
                    </div>

                    {searchTerm.trim().length === 0 && (
                      <div>
                        <Tab.Group>
                          <Tab.List className="flex  gap-3 w-full">
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <Button
                                  variant={selected ? "primary" : "secondary"}
                                  onClick=""
                                >
                                  Non - Invited ({nonInvitedUsersList.length})
                                </Button>
                              )}
                            </Tab>
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <Button
                                  variant={selected ? "primary" : "secondary"}
                                  onClick=""
                                >
                                  Invited ({alreadyInvitedUserList.length})
                                </Button>
                              )}
                            </Tab>
                          </Tab.List>

                          <Tab.Panels>
                            <Tab.Panel>
                              {nonInvitedUsersList.length === 0 ? (
                                <div
                                  className="overflow-y-scroll custom-scrollbar"
                                  style={{ maxHeight: "40vh" }}
                                >
                                  <UserCardSkeleton />
                                </div>
                              ) : (
                                <div className="overflow-y-scroll custom-scrollbar my-3 max-h-[47vh] md:max-h-[42vh]">
                                  {nonInvitedUsersList.map((user, index) => (
                                    <SendInvite
                                      key={index}
                                      user={user}
                                      invitedUsers={invitedUsers}
                                      setInvitedUsers={setInvitedUsers}
                                      desoInvitedUsers={desoInvitedUsers}
                                      setDesoInvitedUsers={setDesoInvitedUsers}
                                    />
                                  ))}
                                </div>
                              )}
                            </Tab.Panel>
                            <Tab.Panel>
                              {alreadyInvitedUserList.length === 0 ? (
                                <div className="h-[100px] flex justify-center items-center">
                                  No invited users
                                </div>
                              ) : (
                                <div>
                                  {invitedUserList.map((user, index) => (
                                    <div
                                      key={index}
                                      className="overflow-y-scroll custom-scrollbar my-3 max-h-[47vh] md:max-h-[42vh]"
                                    >
                                      <div>
                                        <div className="py-2 flex items-center justify-between">
                                          {user?.PublicKeyBase58Check ? (
                                            <DesoUserCard user={user} />
                                          ) : (
                                            <UserCard
                                              user={{
                                                ...getUserData(user),
                                                room: user.room,
                                              }}
                                              userState={user.userState}
                                              isSearchResultCard
                                              fromCallProfile
                                              isInviteUserList
                                              showSocialLinks={false}
                                              showConnectionStatus={false}
                                            />
                                          )}
                                          <div className="px-2">
                                            <input
                                              type="checkbox"
                                              name="roomTypes"
                                              className="appearance-none h-[18px] w-[18px] border-primaryGray rounded-full transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                                              checked
                                              disabled
                                              value={JSON.stringify(user)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* <SendInvite
                                        key={index}
                                        user={user}
                                        invitedUsers={invitedUsers}
                                        setInvitedUsers={setInvitedUsers}
                                        desoInvitedUsers={desoInvitedUsers}
                                        setDesoInvitedUsers={
                                          setDesoInvitedUsers
                                        }
                                      /> */}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </div>
                    )}
                    <div className=" space-y-1">
                      <p className="text-sm font-medium text-darkNight">
                        Invitation Note
                      </p>
                      <LimitedTextarea
                        limit={2000}
                        value={invitationMsg}
                        rows={5}
                        onChange={(text: string) => setInvitationMsg(text)}
                        placeHolder="Leave a  Invitation message"
                      />
                    </div>
                  </div>
                  <div className=" px-4 py-3 flex flex-row items-center justify-end space-x-3 ">
                    <div className="flex space-x-2">
                      <Button
                        size="vibhut-sm"
                        variant="vibhut-secondary"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </Button>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          size="vibhut-sm"
                          variant="vibhut-primary"
                          onClick={handleInviteSent}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
