import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import Button from "../../VibehutUI/Button";
import Close from "../../VibehutUI/Icons/Close";
import useLiveVideo from "../../hooks/useLiveVideo/useLiveVideo";
import Loader from "../../VibehutUI/Loader";
import { globals } from "../../globals";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import CopyButton from "../../VibehutUI/CopyButton";
import { isMobile } from "../../utils";
import PopOver from "../../VibehutUI/Popover";
import Copy2 from "../../VibehutUI/Icons/Copy2";
import { isValidRtmpUrl } from "../../utils/utils";
import HidEye from "../../../VibehutUI/Icons/HidEye";
import VisEye from "../../../VibehutUI/Icons/VisEye";
import {
  handleCurrencyInputChange,
  saveToLocalStorage,
} from "../../../utils/utils";
import CurrencyInput from "react-currency-input-field";
import { set } from "react-ga";
import SelectBox from "../../../VibehutUI/SelectBox";
interface VideoSettingProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

export default function LiveSettings({ isOpen, setIsOpen }: VideoSettingProps) {
  const {
    isLive,
    enableError,
    enableLiveVideo,
    disableLiveVideo,
    isLoading,
    isVibehutLiveOn,
  } = useLiveVideo();
  const roomId = globals.roomId;
  const { callId } = useVibehutData(roomId);
  const [copied, setCopied] = useState(false);
  const [isSimulcast, setIsSimulcast] = useState(false);
  const [isVibehutLive, setIsVibehutLive] = useState(true);
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const [hidePoweredByVibehut, setHidePoweredByVibehut] = useState(false);
  const [rtmpURL, setRtmpURL] = useState("");
  const [streamKey, setStreamKey] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [rtmpOptions, setRtmpOptions] = useState([]);
  const [selectedRtmpOption, setSelectedRtmpOption] = useState(null);

  const [isPaidLive, setPaidLive] = useState(false);
  const [livePassCharge, setLivePassCharge] = useState("");

  const handleHidePoweredByVibehut = () => {
    setHidePoweredByVibehut((prev) => !prev);
  };

  const [errors, setErrors] = useState({
    rtmpURL: "",
    streamKey: "",
    default: "",
  });

  const resetErrors = () => {
    setErrors({
      rtmpURL: "",
      streamKey: "",
      default: "",
    });
  };

  useEffect(() => {
    const storedRtmpStream = localStorage.getItem("streamRtmps");

    if (storedRtmpStream) {
      const _storedRtmpStream = JSON.parse(storedRtmpStream);
      setRtmpOptions(_storedRtmpStream);
      setSelectedRtmpOption(_storedRtmpStream[_storedRtmpStream.length - 1]);
    }
  }, [isLive]);

  useEffect(() => {
    if (selectedRtmpOption) {
      setRtmpURL(selectedRtmpOption.label);
      setStreamKey(selectedRtmpOption.value);
    }
  }, [selectedRtmpOption]);

  const handleGoLive = async () => {
    if (isSimulcast) {
      let skip = true;
      if (!rtmpURL) {
        setErrors((prev) => ({ ...prev, rtmpURL: "RTMP Url is required" }));
        skip = false;
      } else if (!isValidRtmpUrl(rtmpURL)) {
        setErrors((prev) => ({
          ...prev,
          rtmpURL: "Please enter a valid RTMP url",
        }));
        skip = false;
      }

      if (!streamKey) {
        setErrors((prev) => ({ ...prev, streamKey: "Stream Key is required" }));
        skip = false;
      }

      if (!skip) {
        return;
      }
    }

    try {
      const defaultParams = {
        isRtmpEnabled: isSimulcast,
        isMobileLayout,
        isPaidLive: isPaidLive,
        livePassCharge: Number(livePassCharge),
        isVibehutLive: isSimulcast ? isVibehutLive : true,
      };
      await enableLiveVideo(
        isSimulcast
          ? {
              ...defaultParams,
              rtmpUrl: rtmpURL,
              streamKey: streamKey,
            }
          : {
              ...defaultParams,
              isRtmpEnabled: false,
            },
      );
      const streamRtmp: any = {
        label: rtmpURL,
        value: streamKey,
      };
      const storedRtmpStream = localStorage.getItem("streamRtmps");

      if (storedRtmpStream) {
        const _storedRtmpStream = JSON.parse(storedRtmpStream);
        const index = _storedRtmpStream.findIndex(
          (item: any) => item.label === streamRtmp.label,
        );

        if (index !== -1) {
          _storedRtmpStream[index] = streamRtmp;
        } else {
          _storedRtmpStream.push(streamRtmp);
        }

        setRtmpOptions(_storedRtmpStream);
        setSelectedRtmpOption({
          value: _storedRtmpStream[0].value,
          label: _storedRtmpStream[0].label,
        });
        saveToLocalStorage("streamRtmps", _storedRtmpStream);
      } else {
        saveToLocalStorage("streamRtmps", [streamRtmp]);
        setSelectedRtmpOption({
          value: streamRtmp.value,
          label: streamRtmp.label,
        });
      }
    } catch (p_error) {
      console.log("ERROR ::: ", p_error);
    }
  };

  const handleEndLive = () => {
    disableLiveVideo();
  };

  const liveLink = `${window.location.origin}/rooms/${roomId}/calls/${callId}/live`;

  const handleCopyClick = () => {
    if (isMobile && navigator.share) {
      navigator
        .share({
          title: "Copy Live link",
          url: liveLink,
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(liveLink);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  const handleSelectChange = (option) => {
    setSelectedRtmpOption(option);
  };

  const renderButton = () => {
    const text = (
      <div className="text-red-500">
        {enableError || errors.rtmpURL || errors.streamKey || ""}
      </div>
    );
    if (isLoading) {
      return (
        <div className="flex flex-col items-end">
          {text}
          <Button size="vibhut-sm" variant="vibhut-primary">
            <Loader color="white" ratio="h-6 w-6" />
          </Button>
        </div>
      );
    }

    if (isLive) {
      return (
        <div className="flex flex-col items-end">
          {text}
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={handleEndLive}
          >
            End Live
          </Button>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-end">
        {text}
        <Button
          size="vibhut-sm"
          variant="vibhut-primary"
          onClick={handleGoLive}
        >
          Go Live
        </Button>
      </div>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={setIsOpen}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[428px] font-ubuntu">
                <div className="bg-white p-4 border-b border-frescoWhite">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black">
                      Live Settings
                    </p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={setIsOpen}
                    >
                      <Close />
                    </span>
                  </div>
                </div>

                <div>
                  {isLive ? (
                    <div className="px-4 pt-2">
                      <div className="space-y-3">
                        {isVibehutLiveOn && (
                          <>
                            <p>
                              You're now streaming to the masses. Copy the link
                              below and share to let others watch and listen in!
                            </p>
                            <div className="mt-2 relative">
                              <input
                                type="text"
                                className="border-0 focus:outline-none focus:outline-0 focus:ring-0  bg-frescoWhite  py-[15px] pl-4 pr-12 md:px-4  rounded-lg my-1 w-full text-darkGray"
                                value={liveLink}
                                readOnly
                              />
                              {copied ? (
                                <span className="absolute right-8 top-2">
                                  <PopOver text="Copied" size={20} />
                                </span>
                              ) : undefined}
                              <span
                                className="absolute right-3 top-5 cursor-pointer bg-frescoWhite"
                                onClick={handleCopyClick}
                              >
                                <Copy2 />
                              </span>
                            </div>
                          </>
                        )}
                        <p>
                          When you're finished, come back here to end the live
                          stream.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="px-4 pt-2">
                      <div className="space-y-3">
                        <p>
                          Go live now and share your call with the masses
                          through live streaming!
                        </p>
                        <div>
                          <div className="flex flex-row justify-between mr-4 my-2">
                            RTMP (Stream to YouTube, Twitch etc..){" "}
                            <span className="relative inline-block border-1px-solid w-8 h-4 select-none transition duration-200 ease-in">
                              <label
                                className={`toggle-label pl-[2px] pr-[2px] border-solid border-[1px] border-slate-100 block h-8 w-12 rounded-full  cursor-pointer ${
                                  isSimulcast ? "bg-primary" : "bg-gray-300"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  name="isSimulcast"
                                  className="toggle-checkbox absolute opacity-0 w-0 h-0"
                                  checked={isSimulcast}
                                  onChange={() => {
                                    resetErrors();
                                    setIsSimulcast(!isSimulcast);
                                  }}
                                />
                                <span
                                  className={`mt-1 block h-5 w-5 rounded-full bg-white place-self-center align-middle transform transition-transform ${
                                    isSimulcast
                                      ? "translate-x-[20px]"
                                      : "translate-x-[0px]"
                                  }`}
                                />
                              </label>
                            </span>
                          </div>
                          {isSimulcast && (
                            <React.Fragment>
                              <div className="flex flex-row justify-between mr-4 my-2 pt-2">
                                Vibehut Live
                                <span className="relative inline-block border-1px-solid w-8 h-4 select-none transition duration-200 ease-in">
                                  <label
                                    className={`toggle-label pl-[2px] pr-[2px] border-solid border-[1px] border-slate-100 block h-8 w-12 rounded-full  cursor-pointer ${
                                      isVibehutLive
                                        ? "bg-primary"
                                        : "bg-gray-300"
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      name="isVibehutLive"
                                      className="toggle-checkbox absolute opacity-0 w-0 h-0"
                                      checked={isVibehutLive}
                                      onChange={() => {
                                        resetErrors();
                                        setIsVibehutLive(!isVibehutLive);
                                      }}
                                    />
                                    <span
                                      className={`mt-1 block h-5 w-5 rounded-full bg-white place-self-center align-middle transform transition-transform ${
                                        isVibehutLive
                                          ? "translate-x-[20px]"
                                          : "translate-x-[0px]"
                                      }`}
                                    />
                                  </label>
                                </span>
                              </div>
                              {rtmpOptions.length > 0 && selectedRtmpOption && (
                                <div className="pt-2">
                                  <SelectBox
                                    onChange={handleSelectChange}
                                    selected={selectedRtmpOption}
                                    options={rtmpOptions}
                                    width="w-full"
                                  />
                                </div>
                              )}
                              <input
                                className={`w-full py-2 text-[15px] px-4 border ${
                                  errors.rtmpURL
                                    ? "border-red-600"
                                    : "border-gray-300"
                                } rounded-md focus:outline-none focus:ring focus:ring-blue-200 mt-2`}
                                type="text"
                                placeholder="RTMP URL"
                                name="rtmpURL"
                                value={rtmpURL}
                                onChange={(e) => {
                                  setErrors((prev) => ({
                                    ...prev,
                                    rtmpURL: "",
                                  }));
                                  setRtmpURL(e.target.value);
                                }}
                              />
                              <div className="flex gap-2 mt-2 relative">
                                <input
                                  className={`w-full py-2 text-md text-[16px] px-4 border ${
                                    errors.streamKey
                                      ? "border-red-600"
                                      : "border-gray-300"
                                  } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                                  placeholder="Stream key"
                                  name="streamKey"
                                  type={showInput ? "text" : "password"}
                                  value={streamKey}
                                  onChange={(e) => {
                                    setErrors((prev) => ({
                                      ...prev,
                                      streamKey: "",
                                    }));
                                    setStreamKey(e.target.value);
                                  }}
                                />
                                <button
                                  onClick={() => setShowInput(!showInput)}
                                  className="absolute right-3 flex items-center bg-transparent h-full"
                                >
                                  {showInput ? <HidEye /> : <VisEye />}
                                </button>
                              </div>
                            </React.Fragment>
                          )}

                          <div className="flex flex-row justify-between mr-4 my-2 mt-6">
                            Show Mobile Layout ?{" "}
                            <span className="relative inline-block border-1px-solid w-8 h-4 select-none transition duration-200 ease-in">
                              <label
                                className={`toggle-label pl-[2px] pr-[2px] border-solid border-[1px] border-slate-100 block h-8 w-12 rounded-full  cursor-pointer ${
                                  isMobileLayout ? "bg-primary" : "bg-gray-300"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  name="isMobileLayout"
                                  className="toggle-checkbox absolute opacity-0 w-0 h-0"
                                  checked={isMobileLayout}
                                  onChange={() => {
                                    resetErrors();
                                    setIsMobileLayout(!isMobileLayout);
                                  }}
                                />
                                <span
                                  className={`mt-1 block h-5 w-5 rounded-full bg-white place-self-center align-middle transform transition-transform ${
                                    isMobileLayout
                                      ? "translate-x-[20px]"
                                      : "translate-x-[0px]"
                                  }`}
                                />
                              </label>
                            </span>
                          </div>

                          <div className="flex flex-row justify-between mr-4 my-2 mt-6">
                            Pay for Live
                            <span className="relative inline-block border-1px-solid w-8 h-4 select-none transition duration-200 ease-in">
                              <label
                                className={`toggle-label pl-[2px] pr-[2px] border-solid border-[1px] border-slate-100 block h-8 w-12 rounded-full  cursor-pointer ${
                                  isPaidLive ? "bg-primary" : "bg-gray-300"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  name="isPaidLive"
                                  className="toggle-checkbox absolute opacity-0 w-0 h-0"
                                  checked={isPaidLive}
                                  onChange={() => {
                                    resetErrors();
                                    setPaidLive(!isPaidLive);
                                  }}
                                />
                                <span
                                  className={`mt-1 block h-5 w-5 rounded-full bg-white place-self-center align-middle transform transition-transform ${
                                    isPaidLive
                                      ? "translate-x-[20px]"
                                      : "translate-x-[0px]"
                                  }`}
                                />
                              </label>
                            </span>
                          </div>
                          {isPaidLive && (
                            <div className="flex flex-row justify-end mr-4 my-2 mt-6">
                              <CurrencyInput
                                prefix="$"
                                name="currencyInput"
                                id="currencyInput"
                                data-number-to-fixed="2"
                                data-number-stepfactor="100"
                                value={livePassCharge}
                                placeholder="$0.00"
                                className="text-sm border outline-none border-primaryGray py-2 px-2 rounded-xl my-1 w-40"
                                onChange={(e) => {
                                  handleCurrencyInputChange(
                                    e,
                                    setLivePassCharge,
                                  );
                                }}
                                onBlur={() =>
                                  setLivePassCharge(
                                    Number(livePassCharge).toFixed(2),
                                  )
                                }
                                allowDecimals
                                decimalsLimit={2}
                                decimalScale={2}
                                disableAbbreviations
                              />
                            </div>
                          )}
                          {/*
                          <div className="flex flex-row justify-between mr-4 my-2 mt-6">
                            Hide "Powered by Vibehut" ?{' '}
                            <span className="relative inline-block border-1px-solid w-8 h-4 select-none transition duration-200 ease-in">
                              <label
                                className={`toggle-label pl-[2px] pr-[2px] border-solid border-[1px] border-slate-100 block h-8 w-12 rounded-full  cursor-pointer ${hidePoweredByVibehut ? 'bg-primary' : 'bg-gray-300'
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  name="isMobileLayout"
                                  className="toggle-checkbox absolute opacity-0 w-0 h-0"
                                  checked={hidePoweredByVibehut}
                                  onChange={() => {
                                    handleHidePoweredByVibehut();
                                  }}
                                />
                                <span
                                  className={`mt-1 block h-5 w-5 rounded-full bg-white place-self-center align-middle transform transition-transform ${hidePoweredByVibehut ? 'translate-x-[20px]' : 'translate-x-[0px]'
                                    }`}
                                />
                              </label>
                            </span>
                          </div> */}
                        </div>
                        {isLive && (
                          <div>
                            <p className="text-blue-600">
                              We are Live streaming at following URL:
                            </p>
                            <div style={{ display: "flex" }}>
                              <div>
                                <a
                                  target="_blank"
                                  href={liveLink}
                                  rel="noreferrer"
                                >
                                  Live Link
                                </a>
                              </div>
                              <div style={{ maxWidth: 50, cursor: "pointer" }}>
                                <CopyButton
                                  topPosition="-top-12"
                                  url={liveLink}
                                  text="Copy"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="pr-4 py-3 flex flex-row-reverse mt-3 ">
                    {renderButton()}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
