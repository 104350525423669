import axios from "../utils/axios";

export function getUserWaves(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/waves`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserWavesById(userId, token, source) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/waves/users-wave`,
        { userId },
        {
          cancelToken: source?.token,
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function waveToUser(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/waves", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function waveBack(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/waves`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
