import React, { ReactNode } from 'react';

interface ButtonProps {
  size?: string;
  children: string | ReactNode;
  onClick?: () => void;
  variant?: string;
  disabled?: boolean;
  onMouseEnter?: any;
  onMouseLeave?: any;
  border?: string;
  disabledClasses?: string;
  customClasses?: string;
}

function Button({
  size = 'sm',
  children,
  onClick,
  variant = 'primary',
  disabled = false,
  onMouseEnter = null,
  onMouseLeave = null,
  border = '',
  disabledClasses = 'bg-purple-200 opacity-80 hover:bg-purple-200',
  customClasses = '',
}: ButtonProps) {
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className={`cusBtn cusBtn-${variant} ${border} cusBtn-${size} ${
          disabled ? disabledClasses : ''
        } ${customClasses} `}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </button>
    </>
  );
}

export default Button;
