// Description: Reducer for UI state
// create initial state to display or hide UI
import * as actionTypes from "../constants/actionTypes";

const initialState = {
  show: false,
  message: null,
  type: null,
  autoHideDuration: 3000,
};

function toastReducer(state = initialState, action) {
  function toggleChange(stateName) {
    switch (stateName) {
      case actionTypes.SHOW_TOAST:
        return {
          message: action.payload.message,
          type: action.payload.type,
          autoHideDuration: action.payload.autoHideDuration,
          show: true,
        };
      case actionTypes.HIDE_TOAST:
        return {
          message: null,
          type: null,
          autoHideDuration: 3000,
          show: false,
        };
      default:
        return {
          message: null,
          type: null,
          show: false,
          autoHideDuration: 3000,
        };
    }
  }

  switch (action.type) {
    case actionTypes.SHOW_TOAST:
      return toggleChange(actionTypes.SHOW_TOAST);
    case actionTypes.HIDE_TOAST:
      return toggleChange(actionTypes.HIDE_TOAST);
    default:
      return state;
  }
}
export default toastReducer;
