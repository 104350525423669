import { useEffect } from "react";

function useOnClickOutside(handler, ...inputRef) {
  useEffect(() => {
    const listener = (event) => {
      for (const ref of inputRef) {
        if (!(ref.current && !ref.current.contains(event.target))) {
          return;
        }
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
    };
  }, [inputRef, handler]);
}

export default useOnClickOutside;
