import React, { useEffect, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { interval } from 'd3-timer';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MicOff from '../../VibehutUI/Icons/MicOff';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: 30,
      background: 'linear-gradient(180deg, #716DFF 0%, #20DEFB 100%)',
      transitionDuration: '0.3s',
    },
  })
);

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
export function initializeAnalyser(stream: MediaStream) {
  const audioContext = new AudioContext(); // Create a new audioContext for each audio indicator
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);

  // Here we provide a way for the audioContext to be closed.
  // Closing the audioContext allows the unused audioSource to be garbage collected.
  stream.addEventListener('cleanup', () => {
    if (audioContext.state !== 'closed') {
      audioContext.close();
    }
  });

  return analyser;
}

const isIOS = /iPhone|iPad/.test(navigator.userAgent);

function AudioLevelIndicator({ audioTrack, color = 'white' }: { audioTrack?: AudioTrack; color?: string }) {
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);
  const [itemHeight, setItemHeight] = useState(4);
  const [centerItemHeight, setCenterItemHeight] = useState(4);
  const [itemBorder, setItemBorder] = useState<string | number>('50%');
  const classes = useStyles();

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari. We only clone the mediaStreamTrack on iOS.
      let newMediaStream = new MediaStream([isIOS ? mediaStreamTrack.clone() : mediaStreamTrack]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => {
        if (isIOS) {
          // If we are on iOS, then we want to stop the MediaStreamTrack that we have previously cloned.
          // If we are not on iOS, then we do not stop the MediaStreamTrack since it is the original and still in use.
          newMediaStream.getTracks().forEach(track => track.stop());
        }
        newMediaStream.dispatchEvent(new Event('cleanup')); // Stop the audioContext
      };
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        // We only clone the mediaStreamTrack on iOS.
        newMediaStream = new MediaStream([isIOS ? mediaStreamTrack.clone() : mediaStreamTrack]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);
  const itemStyle = {
    minHeight: 3,
    backgroundColor: 'white',
    width: 3,
    borderRadius: itemBorder,
    height: itemHeight,
  };
  const centerItemStyle = {
    minHeight: 3,
    backgroundColor: 'white',
    width: 3,
    borderRadius: itemBorder,
    height: centerItemHeight,
  };
  useEffect(() => {
    if (isTrackEnabled && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }
        const volume = Math.min(14, Math.max(5, Math.log10(values / length / 3) * 7));
        if (volume <= 6.2) {
          setItemHeight(3);
          setCenterItemHeight(3);
          setItemBorder('50%');
        } else {
          setItemBorder(15);
          setItemHeight(volume - 1);
          setCenterItemHeight(1 + volume);
        }
      }, 100);

      return () => {
        setItemBorder('50%');
        setItemHeight(3);
        setCenterItemHeight(3);
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  return isTrackEnabled ? (
    <div className={`${classes.container}  flex justify-center items-center gap-1 px-3 h-7`}>
      <div style={itemStyle} />
      <div style={centerItemStyle}></div>
      <div style={itemStyle} />
    </div>
  ) : (
    <MicOff />
  );
}

export default React.memo(AudioLevelIndicator);
