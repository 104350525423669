import React, { useMemo } from "react";

import Button from "@material-ui/core/Button";
import MicIcon from "../../../icons/MicIcon";
import MicOffIcon from "../../../icons/MicOffIcon";

import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import MicOff from "../../../VibehutUI/Icons/MicOff";
import MicOn from "../../../VibehutUI/Icons/MicOn";
import { Theme, Tooltip, useMediaQuery } from "@material-ui/core";

interface ToggleAudioButtonProps {
  disabled?: boolean;
  className?: string;
  fromConnectingPage?: boolean;
}

export default function ToggleAudioButton({
  disabled,
  className,
  fromConnectingPage = true,
}: ToggleAudioButtonProps) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === "audio");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const toolTipText = useMemo(() => {
    return `Turn ${!isAudioEnabled ? "on" : "off"} Microphone ( ctrl + D )`;
  }, [isAudioEnabled]);

  if (fromConnectingPage) {
    return (
      <Tooltip title={toolTipText} placement="top">
        <Button
          type="button"
          className={className}
          onClick={toggleAudioEnabled}
          disabled={!hasAudioTrack || disabled}
          startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
          data-cy-audio-toggle
        >
          {!hasAudioTrack ? "No Audio" : isAudioEnabled ? "Mute" : "Unmute"}
        </Button>
      </Tooltip>
    );
  }

  if (disabled) {
    return (
      <Tooltip title={toolTipText} placement="top">
        <button
          className={` ${
            isMobile ? "w-8 h-8" : "w-10 h-10"
          } flex items-center justify-center rounded-full ${
            isMobile ? "bg-matteGray" : " bg-gray-700"
          }   cursor-wait`}
          disabled
          type="button"
        >
          <MicOn />
        </button>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip title={toolTipText} placement="top">
        <button
          className={` ${
            isMobile ? "w-8 h-8" : "w-10 h-10"
          } flex items-center justify-center rounded-full ${
            isAudioEnabled
              ? isMobile
                ? "bg-matteGray"
                : " bg-gray-700"
              : "bg-cvRed"
          }   cursor-pointer`}
          onClick={toggleAudioEnabled}
          type="button"
        >
          {isAudioEnabled ? <MicOn /> : <MicOff />}
        </button>
      </Tooltip>
    </>
  );
}
