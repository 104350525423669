import React from "react";

function ProfileStatsSkeleton({ showItem = 4 }) {
  return (
    <div className="flex space-x-5 py-4">
      {new Array(showItem).fill(0).map((_, index) => (
        <div
          className="flex items-center flex-col  gap-1.5 justify-center"
          key={index}
        >
          <div className="rounded-xl  animate-pulse bg-frescoWhite dark:bg-darkGray w-12 h-5"></div>
          <div className="rounded-xl  animate-pulse bg-frescoWhite dark:bg-darkGray w-16 h-5"></div>
        </div>
      ))}
    </div>
  );
}

export default ProfileStatsSkeleton;
