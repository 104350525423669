import { getUserData } from "../../services/userService";
import UserCard from "../../VibehutUI/UserCard";

function LiveStreamViewers({ viewers }: any) {
  return (
    <div className=" overflow-y-scroll custom-scrollbar">
      {(viewers?.length || 0) > 0 &&
        viewers.map((viewer: any) => {
          const userData = getUserData(viewer.user);
          return (
            <div
              key={viewer._id}
              className="pt-1 flex items-center justify-between"
            >
              <UserCard user={userData} />
            </div>
          );
        })}
    </div>
  );
}

export default LiveStreamViewers;
