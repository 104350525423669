import * as actionTypes from "../constants";
import * as rolesService from "../services/rolesService";

export function getAllRoles(dispatch) {
  dispatch({
    type: actionTypes.GET_ALL_ROLES_REQUEST,
  });
  return rolesService
    .getAllRoles()
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ALL_ROLES_SUCCESS,
        payload: {
          roles: res.data.roles,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ALL_ROLES_FAILURE,
      });
      throw err;
    });
}
