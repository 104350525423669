import { SetStateAction } from "react";
import UserCardSkeleton from "../../../VibehutUI/Skeletons/UserCardSkeleton";
import Close from "../Icons/Close";
import NoResult from "../Icons/NoResult";
import SendInvite from "./SendInvite";

interface InviteSearchBarProps {
  value: string;
  handleChange: any;
  results: any[];
  setValue: (data: string) => void;
  invitedUsers: any[];
  setInvitedUsers: React.Dispatch<SetStateAction<any[]>>;
  desoInvitedUsers: any[];
  setDesoInvitedUsers: React.Dispatch<SetStateAction<any[]>>;
  isLoading: boolean;
}

export default function InviteSearchBar({
  value,
  handleChange,
  results = [],
  setValue,
  isLoading,
  invitedUsers,
  desoInvitedUsers,
  setInvitedUsers,
  setDesoInvitedUsers,
}: InviteSearchBarProps) {
  return (
    <div
      className={`searchbarDiv flex flex-col  overflow-y-hidden relative ${
        value.length > 0 && `h-full`
      }  grow `}
    >
      <div className="relative px-1 ">
        <input
          type="text"
          className="border outline-none border-primaryGray py-2 px-3 rounded-xl my-1 w-full placeholder-notifyBg"
          placeholder="Search..."
          onChange={handleChange}
          value={value}
        />
        <span
          className="absolute top-4 right-3 bg-notifyBg rounded-full p-[5px] cursor-pointer"
          onClick={() => {
            setValue("");
          }}
        >
          <Close color="#E5E5E5" ratio="8" />
        </span>
      </div>

      {isLoading ? (
        <div
          className="overflow-y-scroll  custom-scrollbar "
          style={{ maxHeight: "40vh" }}
        >
          <UserCardSkeleton />
        </div>
      ) : (
        value.length > 0 && (
          <div
            className={`w-full my-2 ${
              results.length > 0 ? "pb-0" : "pb-8"
            } max-h-[35vh]  md:max-h-[32vh] flex-1 overflow-y-auto custom-scrollbar`}
          >
            {results.length > 0 ? (
              results.map((user, index) => (
                <SendInvite
                  key={index}
                  user={user}
                  invitedUsers={invitedUsers}
                  setInvitedUsers={setInvitedUsers}
                  desoInvitedUsers={desoInvitedUsers}
                  setDesoInvitedUsers={setDesoInvitedUsers}
                />
              ))
            ) : (
              <div className="relative h-[182px] w-full overflow-hidden ">
                <div className="absolute top-1/2 transform -translate-y-1/2 z-50 left-1/2 -translate-x-1/2 w-full">
                  <div>
                    <div className="flex justify-center bg-cover">
                      <NoResult />
                    </div>

                    <p className="text-darkNight text-center dark:text-white text-lg font-bold font-ubuntu  w-full">
                      Nobody found
                    </p>
                    <p className="text-darkGray text-center dark:text-primaryGray text-base font-normal font-ubuntu w-full ">
                      Adjust your search and try again.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}
