import * as actionTypes from "../constants";

export const showToast = (dispatch, type, message, autoHideDuration) => {
  dispatch({
    type: actionTypes.SHOW_TOAST,
    payload: {
      show: true,
      message: message,
      autoHideDuration: autoHideDuration,
      type: type,
    },
  });
};

export const hideToast = (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_TOAST,
    payload: actionTypes.HIDE_TOAST,
  });
};
