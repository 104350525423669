import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import Loader from "../../VibehutUI/Loader";
import { Link, useParams } from "react-router-dom";
import { getRoomVideos } from "../../services/publishStore";
import Button from "../../VibehutUI/Button";

import VideoCard from "../PublishVideo/VideoCard";

const VIDEO_FILTER_OBJECT = {
  RECENTLY_UPLOADED: "RECENTLY_UPLOADED",
  POPULAR: "POPULAR",
};

function RoomVideos() {
  let { roomId } = useParams();
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
    room: { roomId: roomCustomId },
  } = state;
  if (!roomId) {
    roomId = roomCustomId;
  }
  const [filter, setFilter] = useState(VIDEO_FILTER_OBJECT.RECENTLY_UPLOADED);
  const [videos, setVideos] = useState([]);
  const [isVideosFetching, setIsVideosFetching] = useState(false);
  const [currentVideosLength, setCurrentVideosLength] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  useEffect(() => {
    if (roomId) {
      getVideos(offset);
      setCurrentVideosLength(0);
    }
  }, [roomId, filter]);

  const getMoreVideos = (offset) => {
    setIsMoreLoading(true);
    getRoomVideos(
      user?.token,
      {
        videoTypes: filter,
        room: roomId,
      },
      offset
    )
      .then((res) => {
        setIsMoreLoading(false);
        setCurrentVideosLength(res.data?.videoes.length);
        if (res.data?.videoes.length === 6) {
          setOffset((offset) => offset + 6);
        }
        setVideos([...videos, ...res.data?.videoes]);
      })
      .catch((err) => {
        setIsMoreLoading(false);
        console.log("error:", err);
      });
  };

  const getVideos = () => {
    setIsVideosFetching(true);
    getRoomVideos(
      user?.token,
      {
        videoTypes: filter,
        room: roomId,
      },
      0
    )
      .then((res) => {
        setIsVideosFetching(false);
        setCurrentVideosLength(res.data?.videoes.length);
        if (res.data?.videoes.length === 6) {
          setOffset(6);
        }
        setVideos(res.data?.videoes);
      })
      .catch((err) => {
        setIsVideosFetching(false);
        console.log("error:", err);
      });
  };

  return (
    <>
      <div className="flex justify-between items-center pt-6 ">
        <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite">
          Watch
        </p>
        <div className="flex gap-2 md:gap-4">
          <div
            className={`${
              filter === VIDEO_FILTER_OBJECT.RECENTLY_UPLOADED
                ? "text-white bg-primary "
                : "text-matteGray bg-frescoWhite  "
            } cursor-pointer py-2 px-2 md:px-3 text-sm md:text-base rounded-lg font-medium`}
            onClick={() => {
              setFilter(VIDEO_FILTER_OBJECT.RECENTLY_UPLOADED);
            }}
          >
            Recently Uploaded
          </div>
          <div
            className={`${
              filter === VIDEO_FILTER_OBJECT.POPULAR
                ? "text-white bg-primary "
                : "text-matteGray bg-frescoWhite "
            } cursor-pointer py-2 px-2 md:px-3 text-sm md:text-base rounded-lg font-medium`}
            onClick={async () => {
              setFilter(VIDEO_FILTER_OBJECT.POPULAR);
            }}
          >
            Popular
          </div>
        </div>
      </div>
      {!isVideosFetching ? (
        videos?.length > 0 ? (
          <div className="grid md:grid-cols-2 lg:grid-cols-2 3xl:grid-cols-3 grid-cols-1 gap-x-10 gap-y-10 py-5">
            {videos.map((video) => (
              <div key={video.compositionId}>
                <VideoCard video={video} />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-darkGray text-center pt-6">
            <p>There is no video currently available in this room.</p>
            <p>
              Explore{" "}
              <Link to="/watch" className="text-primary">
                Watch
              </Link>{" "}
            </p>
          </div>
        )
      ) : (
        <Loader />
      )}
      {currentVideosLength === 6 && (
        <div className="flex justify-center pb-6">
          {!isMoreLoading ? (
            <Button
              size="vibhut-sm"
              variant="vibhut-primary"
              onClick={() => getMoreVideos(offset)}
            >
              Load More
            </Button>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </>
  );
}

export default RoomVideos;
