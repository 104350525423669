import * as actionTypes from "../constants";
import { getUserData } from "../services/userService";
import {
  saveConversationToLocalStorage,
  saveToLocalStorage,
} from "../utils/utils";

const initialState = {
  roomId: null,
  slug: undefined,
  isFetchingRoomId: false,
  isSearching: false,
  isMoreSearching: false,
  isSearchMore: false,
  searchedRooms: [],
  rooms: [],
  myRooms: [],
  subscriberCount: undefined,
  myRoomsCount: undefined,
  myRoomsSlugCount: undefined,
  subscibedRooms: [],
  nftCollectionRooms: [],
  isFetching: false,
  isFetchingMyRooms: false,
  isMyRoomMore: false,
  isMyRoomFetching: false,
  isFetchingPopRoom: false,
  isFetchingTopRooms: false,
  isFetchingMoreTopRooms: false,
  isTopRoomMore: false,
  isFetchingTrendingRooms: false,
  isSubscribedRoomMore: false,
  isSubscribedRoomFetching: false,
  error: null,
  offset: 0,
  isMore: false,
  generalRoom: null,
  popularRooms: [],
  topRooms: [],
  trendingRooms: [],
  recentRooms: [],
  invitedUsers: [],
  blockedUsers: [],
  userRooms: [],
  isUserRoomMore: false,
  isUserRoomFetching: false,
  oneTimeloaderUserRooms: true,
  isMoreFetching: false,
  oneTimeloaderAllRooms: true,
  oneTimeloaderMyRooms: true,
  oneTimeloaderSubscribedRooms: true,
  totalSubscribedRooms: 0,
  liveRoom: [],
  chatRoom: undefined,
  roomChats: [],
  isFetchingChatRoom: false,
  conversations: [],
  isFetchingConversations: false,
  conversationOffset: 0,
  isMoreConv: false,
  isMoreConvFetching: false,
  dmConversations: [],
  isFetchingDmConversations: false,
  dmConversationOffset: 0,
  isMoreDmConv: false,
  isMoreDmConvFetching: false,
  roomConversations: [],
  isFetchingRoomConversations: false,
  roomConversationOffset: 0,
  isMoreRoomConv: false,
  isMoreRoomConvFetching: false,
  isFetchingMoreConversations: false,
  moreConversations: [],
  moreConversationOffset: 0,
  isMoreConvMore: false,
  isMoreConvMoreFetching: false,
  activeCalls: [],
  homePopularRooms: [],
  isFetchingHomePopRoom: false,
  isMoreHomePopularRoomFetching: false,
  isMoreHomePopRoom: false,
  homePopRoomOffset: 0,
  isFetchingScreenRoom: true,
};

function roomReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MY_ROOMS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMyRoomFetching: true,
        };
      }
      return {
        ...state,
      };
    case actionTypes.GET_MY_ROOM_COUNT_REQUEST:
      return {
        ...state,
      };
    case actionTypes.GET_MY_ROOM_COUNT_SUCCESS:
      return {
        ...state,
        myRoomsCount: action.payload.myRoomCount,
      };
    case actionTypes.GET_MY_ROOM_COUNT_FAILURE:
      return {
        ...state,
      };
    case actionTypes.GET_LIVE_ROOMS_CALL_REQUEST:
      return {
        ...state,
      };
    case actionTypes.GET_LIVE_ROOMS_CALL_SUCCESS:
      return {
        ...state,
        activeCalls: action.payload.calls,
      };
    case actionTypes.GET_LIVE_ROOMS_CALL_FAILURE:
      return {
        ...state,
      };
    case actionTypes.GET_SUBSCRIBED_ROOMS_REQUEST:
      return {
        ...state,
        isSubscribedRoomFetching: true,
      };
    case actionTypes.GET_SCREEN_ROOM_REQUEST:
      return {
        ...state,
        isFetchingScreenRoom: true,
      };
    case actionTypes.GET_PUBLIC_ROOMS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
          oneTimeloaderAllRooms: true,
        };
      }
      return { ...state, oneTimeloaderAllRooms: true };
    case actionTypes.GET_NFT_ROOMS_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_PUBLIC_ROOMS_SUCCESS: {
      const ofst =
        action.payload.rooms.length >= 30
          ? action.payload.offset + 30
          : action.payload.offset;

      if (action.payload.loadMore) {
        const rooms = [...state.rooms, ...action.payload.rooms];
        //remove duplicate room from rooms
        const uniqueRooms = [
          ...new Map(rooms.map((item) => [item["_id"], item])).values(),
        ];

        return {
          ...state,
          rooms: uniqueRooms,
          oneTimeloaderAllRooms: false,
          offset: ofst,
          isMoreFetching: false,
          isMore: action.payload.rooms.length >= 30 ? true : false,
        };
      } else {
        return {
          ...state,
          rooms: action.payload.rooms,
          oneTimeloaderAllRooms: false,
          offset: ofst,
          isMore: action.payload.rooms.length >= 30 ? true : false,
        };
      }
    }

    case actionTypes.GET_SCREEN_ROOM_SUCCESS: {
      let totalUsersCount =
        action.payload.liveRoom?.notificationsubscriptions?.length || 0;
      if (
        action.payload.liveRoom &&
        action.payload.liveRoom?.isSubscriberRequireApproval === true
      ) {
        totalUsersCount =
          action.payload.liveRoom.subscriberApprovedUsers?.length || 0;
      }
      return {
        ...state,
        liveRoom: action.payload,
        totalSubscribedRooms: totalUsersCount,
        isFetchingScreenRoom: false,
      };
    }
    case actionTypes.GET_SCREEN_ROOM_FAILURE:
      return { ...state, isFetchingScreenRoom: false };
    case actionTypes.CLEAR_SCREEN_ROOM_SUCCESS: {
      return {
        ...state,
        liveRoom: [],
        totalSubscribedRooms: 0,
      };
    }
    case actionTypes.GET_MY_ROOMS_SUCCESS: {
      if (action.payload.loadMore) {
        const mynewRooms = [...state.myRooms, ...action.payload.rooms];

        return {
          ...state,
          myRooms: mynewRooms,
          oneTimeloaderMyRooms: false,
          isMyRoomFetching: false,
          isMyRoomMore: action.payload.rooms.length >= 18 ? true : false,
        };
      } else {
        return {
          ...state,
          myRooms: action.payload.rooms,
          oneTimeloaderMyRooms: false,
          isMyRoomMore: action.payload.rooms.length >= 18 ? true : false,
        };
      }
    }
    case actionTypes.GET_USER_ROOMS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isUserRoomFetching: true,
        };
      }
      return {
        ...state,
      };
    case actionTypes.GET_USER_ROOMS_SUCCESS: {
      if (action.payload.loadMore) {
        const newUsersRooms = [...state.userRooms, ...action.payload.rooms];
        return {
          ...state,
          userRooms: newUsersRooms,
          oneTimeloaderUserRooms: false,
          isUserRoomFetching: false,
          isUserRoomMore: action.payload.rooms.length >= 18 ? true : false,
        };
      } else {
        return {
          ...state,
          userRooms: action.payload.rooms,
          oneTimeloaderUserRooms: false,
          isUserRoomMore: action.payload.rooms.length >= 18 ? true : false,
        };
      }
    }
    case actionTypes.GET_USER_ROOMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isMyRoomFetching: false,
        oneTimeloaderUserRooms: false,
      };
    case actionTypes.GET_SUBSCRIBED_ROOMS_SUCCESS: {
      if (action.payload.loadMore) {
        const newSubscribedRooms = [
          ...state.subscibedRooms,
          ...action.payload.rooms,
        ];

        return {
          ...state,
          subscibedRooms: newSubscribedRooms,
          oneTimeloaderSubscribedRooms: false,
          isSubscribedRoomFetching: false,
          isSubscribedRoomMore:
            action.payload.rooms.length >= 18 ? true : false,
        };
      } else {
        return {
          ...state,
          subscibedRooms: action.payload.rooms,
          oneTimeloaderSubscribedRooms: false,
          isSubscribedRoomFetching: false,
          isSubscribedRoomMore:
            action.payload.rooms.length >= 18 ? true : false,
        };
      }
    }
    case actionTypes.GET_ROOM_SUBSCRIBER_COUNT_SUCCESS:
      return {
        ...state,
        subscriberCount: action.payload,
      };
    case actionTypes.GET_NFT_ROOMS_SUCCESS: {
      return {
        ...state,
        nftCollectionRooms: action.payload.rooms,
        isFetching: false,
      };
    }

    case actionTypes.GET_MY_ROOMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isMyRoomFetching: false,
        oneTimeloaderMyRooms: false,
      };

    case actionTypes.GET_SUBSCRIBED_ROOMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubscribedRoomFetching: false,
        oneTimeloaderSubscribedRooms: false,
      };

    case actionTypes.GET_PUBLIC_ROOMS_FAILURE:
    case actionTypes.GET_NFT_ROOMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isMoreFetching: false,
        isFetchingMyRooms: false,
        oneTimeloaderMyRooms: false,
        oneTimeloaderAllRooms: false,
      };

    case actionTypes.CREATE_ROOM_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.CREATE_ROOM_SUCCESS: {
      const gnRoom = state.myRooms.find(
        (room) => room._id === state.generalRoom._id,
      );
      if (gnRoom) {
        return {
          ...state,
          myRooms: [
            gnRoom,
            action.payload,
            ...state.myRooms.filter(
              (room) => room._id !== state.generalRoom._id,
            ),
          ],
          isFetching: false,
        };
      }
      return {
        ...state,
        myRooms: [action.payload, ...state.myRooms],
        isFetching: false,
      };
    }

    case actionTypes.CREATE_ROOM_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.UPDATE_ROOM_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.UPDATE_ROOM_SUCCESS: {
      const { myRooms } = state;
      const index = myRooms.findIndex(
        (room) => room._id === action.payload?._id,
      );
      myRooms[index] = action.payload;
      return {
        ...state,
        myRooms: myRooms,
        isFetching: false,
      };
    }

    case actionTypes.UPDATE_ROOM_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.GET_GEN_ROOM_REQUEST:
      return { ...state };

    case actionTypes.GET_GEN_ROOM_SUCCESS: {
      return {
        ...state,
        generalRoom: action.payload?.room,
      };
    }

    case actionTypes.GET_GEN_ROOM_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.GET_POPULAR_ROOMS_REQUEST:
      return { ...state, isFetchingPopRoom: true, oneTimeloaderAllRooms: true };

    case actionTypes.GET_POPULAR_ROOMS_SUCCESS: {
      return {
        ...state,
        rooms: action.payload?.rooms,
        popularRooms: action.payload?.rooms,
        oneTimeloaderAllRooms: false,
        isFetchingPopRoom: false,
      };
    }

    case actionTypes.GET_POPULAR_ROOMS_FAILURE:
      return {
        ...state,
        isFetchingPopRoom: false,
        oneTimeloaderAllRooms: false,
      };

    case actionTypes.GET_HOME_POPULAR_ROOMS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreHomePopularRoomFetching: true,
        };
      }
      return {
        ...state,
        isFetchingHomePopRoom: true,
        oneTimeloaderAllRooms: true,
      };

    case actionTypes.GET_HOME_POPULAR_ROOMS_SUCCESS: {
      const ofst =
        action.payload.rooms.length >= 10
          ? action.payload.offset + 10
          : action.payload.offset;
      if (action.payload.loadMore) {
        const rooms = [...state.homePopularRooms, ...action.payload.rooms];

        return {
          ...state,
          homePopularRooms: rooms,
          oneTimeloaderAllRooms: false,
          homePopRoomOffset: ofst,
          isMoreHomePopularRoomFetching: false,
          isMoreHomePopRoom: action.payload.rooms.length >= 10 ? true : false,
        };
      } else {
        return {
          ...state,
          homePopularRooms: action.payload?.rooms,
          oneTimeloaderAllRooms: false,
          homePopRoomOffset: ofst,
          isFetchingHomePopRoom: false,
          isMoreHomePopRoom: action.payload.rooms.length >= 10 ? true : false,
        };
      }
    }

    case actionTypes.GET_HOME_POPULAR_ROOMS_FAILURE:
      return {
        ...state,
        isFetchingHomePopRoom: false,
        oneTimeloaderAllRooms: false,
      };
    case actionTypes.GET_TOP_ROOMS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isFetchingMoreTopRooms: true,
        };
      }
      if (action?.intervalCall) {
        return {
          ...state,
        };
      }
      return { ...state, isFetchingTopRooms: true };

    case actionTypes.GET_TOP_ROOMS_SUCCESS: {
      const topRooms = action.payload?.rooms;
      if (action.payload.loadMore) {
        return {
          ...state,
          topRooms: [...state.topRooms, ...topRooms],
          isFetchingTopRooms: false,
          isTopRoomMore: topRooms.length >= 18 ? true : false,
          isFetchingMoreTopRooms: false,
        };
      }
      return {
        ...state,
        topRooms: topRooms,
        isFetchingTopRooms: false,
        isMoreFetchingRoom: false,
        isTopRoomMore: topRooms.length >= 18 ? true : false,
      };
    }

    case actionTypes.GET_TOP_ROOMS_FAILURE:
      return {
        ...state,
        isFetchingTopRooms: false,
      };

    case actionTypes.GET_TRENDING_ROOMS_REQUEST:
      return { ...state, isFetchingTrendingRooms: true };

    case actionTypes.GET_TRENDING_ROOMS_SUCCESS: {
      return {
        ...state,
        trendingRooms: action.payload?.rooms,
        isFetchingTrendingRooms: false,
      };
    }

    case actionTypes.GET_TRENDING_ROOMS_FAILURE:
      return {
        ...state,
        isFetchingTrendingRooms: false,
      };

    case actionTypes.GET_NEWLY_ADDED_ROOMS_REQUEST:
      return { ...state, oneTimeloaderAllRooms: true };

    case actionTypes.GET_NEWLY_ADDED_ROOMS_SUCCESS: {
      return {
        ...state,
        rooms: action.payload?.rooms,
        oneTimeloaderAllRooms: false,
      };
    }

    case actionTypes.GET_NEWLY_ADDED_ROOMS_FAILURE:
      return { ...state, oneTimeloaderAllRooms: false };

    case actionTypes.GET_RECENT_ROOMS_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_RECENT_ROOMS_SUCCESS: {
      return {
        ...state,
        recentRooms: action.payload?.rooms,
        isFetching: false,
      };
    }

    case actionTypes.GET_RECENT_ROOMS_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.DELETE_ROOM_REQUEST:
      return { ...state, isFetching: false };

    case actionTypes.DELETE_ROOM_SUCCESS: {
      return {
        ...state,
        myRooms: state.myRooms.filter(
          (room) => room._id !== action.payload._id,
        ),
        isFetching: false,
      };
    }

    case actionTypes.DELETE_ROOM_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.INVITE_USER_REQUEST:
      return { ...state };

    case actionTypes.INVITE_USER_SUCCESS: {
      const users = [...state.invitedUsers, action.payload];

      return {
        ...state,
        invitedUsers: users,
      };
    }

    case actionTypes.INVITE_USER_FAILURE:
      return { ...state };

    case actionTypes.INVITE_USER_RESET: {
      const users = [];

      return {
        ...state,
        invitedUsers: users,
      };
    }

    case actionTypes.BLOCK_USER_REQUEST:
    case actionTypes.BLOCK_USER_RESET:
      return { ...state };

    case actionTypes.BLOCK_USER_SUCCESS: {
      const users = action.payload?.map((user) => getUserData(user)) || [];
      return {
        ...state,
        blockedUsers: users,
      };
    }

    case actionTypes.BLOCK_USER_FAILURE: {
      const users = [];
      return {
        ...state,
        blockedUsers: users,
      };
    }

    case actionTypes.GET_CHAT_ROOM_REQUEST:
      return { ...state, isFetchingChatRoom: true };

    case actionTypes.GET_CHAT_ROOM_SUCCESS: {
      return {
        ...state,
        chatRoom: action.payload.chatRoom,
        isFetchingChatRoom: false,
      };
    }

    case actionTypes.GET_CHAT_ROOM_FAILURE:
      return { ...state, isFetchingChatRoom: false };

    case actionTypes.UPDATE_ROOM_ACTIVE_USER_COUNT: {
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          totalActiveUsersInChat: action.payload.count,
        },
      };
    }

    case actionTypes.UPDATE_ACTIVE_CHAT_ROOM: {
      const { chatRoom } = state;
      chatRoom?.chatUsers.map((u) => {
        if (
          action.payload.authUserId !== action.payload.profile._id &&
          chatRoom.isChatRoom &&
          u._id === action.payload.profile._id
        ) {
          u.userState = action.payload.profile.userState;
          return u;
        }
        return u;
      });

      return {
        ...state,
        chatRoom: chatRoom,
      };
    }

    case actionTypes.GET_CONVERSATIONS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreConvFetching: true,
        };
      }
      return { ...state, isFetchingConversations: true };

    case actionTypes.GET_CONVERSATIONS_SUCCESS: {
      const ofst =
        action.payload.conversations.length >= 10
          ? action.payload.offset + 10
          : action.payload.offset;
      let conversations = action.payload.conversations;

      conversations = conversations.filter(
        (c) => !(c.isChatRoom && c.chatUsers.length === 1),
      );

      const unreadConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount > 0,
      );
      let readConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount === 0,
      );

      readConversations = readConversations.sort(function (a, b) {
        return (
          new Date(b.last_chat?.createdAt) - new Date(a.last_chat?.createdAt)
        );
      });

      if (action.payload.loadMore) {
        return {
          ...state,
          conversations: [...state.conversations, ...conversations],
          isFetchingConversations: false,
          conversationOffset: ofst,
          isMoreConv: action.payload.conversations.length >= 10 ? true : false,
          isMoreConvFetching: false,
        };
      }

      return {
        ...state,
        conversations: [...unreadConversations, ...readConversations],
        isFetchingConversations: false,
        conversationOffset: ofst,
        isMoreConv: action.payload.conversations.length >= 10 ? true : false,
        isMoreConvFetching: false,
      };
    }

    case actionTypes.GET_CONVERSATIONS_FAILURE:
      return { ...state, isFetchingConversations: false };

    case actionTypes.GET_DM_CONVERSATIONS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreDmConvFetching: true,
        };
      }
      return { ...state, isFetchingDmConversations: true };

    case actionTypes.GET_DM_CONVERSATIONS_SUCCESS: {
      const ofst =
        action.payload.conversations.length >= 10
          ? action.payload.offset + 10
          : action.payload.offset;
      let conversations = action.payload.conversations;

      conversations = conversations.filter((c) => c.chatUsers.length > 1);

      const unreadConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount > 0,
      );
      let readConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount === 0,
      );

      readConversations = readConversations.sort(function (a, b) {
        return (
          new Date(b.last_chat?.createdAt) - new Date(a.last_chat?.createdAt)
        );
      });

      if (action.payload.loadMore) {
        return {
          ...state,
          dmConversations: [...state.dmConversations, ...conversations],
          isFetchingDmConversations: false,
          dmConversationOffset: ofst,
          isMoreDmConv:
            action.payload.conversations.length >= 10 ? true : false,
          isMoreDmConvFetching: false,
        };
      }

      return {
        ...state,
        dmConversations: [...unreadConversations, ...readConversations],
        isFetchingDmConversations: false,
        dmConversationOffset: ofst,
        isMoreDmConv: action.payload.conversations.length >= 10 ? true : false,
        isMoreDmConvFetching: false,
      };
    }

    case actionTypes.GET_DM_CONVERSATIONS_FAILURE:
      return { ...state, isFetchingRoomConversations: false };

    case actionTypes.GET_ROOM_CONVERSATIONS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreRoomConvFetching: true,
        };
      }
      return { ...state, isFetchingRoomConversations: true };

    case actionTypes.GET_ROOM_CONVERSATIONS_SUCCESS: {
      const ofst =
        action.payload.conversations.length >= 10
          ? action.payload.offset + 10
          : action.payload.offset;
      const conversations = action.payload.conversations;

      const unreadConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount > 0,
      );
      let readConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount === 0,
      );

      readConversations = readConversations.sort(function (a, b) {
        return (
          new Date(b.last_chat?.createdAt) - new Date(a.last_chat?.createdAt)
        );
      });

      if (action.payload.loadMore) {
        return {
          ...state,
          roomConversations: [...state.roomConversations, ...conversations],
          isFetchingRoomConversations: false,
          roomConversationOffset: ofst,
          isMoreRoomConv:
            action.payload.conversations.length >= 10 ? true : false,
          isMoreRoomConvFetching: false,
        };
      }

      return {
        ...state,
        roomConversations: [...unreadConversations, ...readConversations],
        isFetchingRoomConversations: false,
        roomConversationOffset: ofst,
        isMoreRoomConv:
          action.payload.conversations.length >= 10 ? true : false,
        isMoreRoomConvFetching: false,
      };
    }

    case actionTypes.GET_ROOM_CONVERSATIONS_FAILURE:
      return { ...state, isFetchingRoomConversations: false };

    case actionTypes.GET_MORE_CONVERSATIONS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreConvMoreFetching: true,
        };
      }
      return { ...state, isFetchingMoreConversations: true };

    case actionTypes.GET_MORE_CONVERSATIONS_SUCCESS: {
      const ofst =
        action.payload.conversations.length >= 10
          ? action.payload.offset + 10
          : action.payload.offset;
      const conversations = action.payload.conversations;

      const unreadConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount > 0,
      );
      let readConversations = conversations.filter(
        (conv) => conv.unreadMessagesCount === 0,
      );

      readConversations = readConversations.sort(function (a, b) {
        return (
          new Date(b.last_chat?.createdAt) - new Date(a.last_chat?.createdAt)
        );
      });

      if (action.payload.loadMore) {
        return {
          ...state,
          moreConversations: [...state.moreConversations, ...conversations],
          isFetchingMoreConversations: false,
          moreConversationOffset: ofst,
          isMoreConvMore:
            action.payload.conversations.length >= 10 ? true : false,
          isMoreConvMoreFetching: false,
        };
      }

      return {
        ...state,
        moreConversations: [...unreadConversations, ...readConversations],
        isFetchingMoreConversations: false,
        moreConversationOffset: ofst,
        isMoreConvMore:
          action.payload.conversations.length >= 10 ? true : false,
        isMoreConvMoreFetching: false,
      };
    }

    case actionTypes.GET_MORE_CONVERSATIONS_FAILURE:
      return { ...state, isFetchingMoreConversations: false };

    case actionTypes.ADD_NEW_CONVERSATION: {
      let { conversations } = state;
      const singleConversation = conversations.find(
        (conv) => conv._id === action.payload.conversation._id,
      );
      if (singleConversation) {
        conversations = [singleConversation, ...conversations];
        conversations = [...new Set(conversations)];
      } else {
        conversations = [action.payload.conversation, ...conversations];
      }
      saveConversationToLocalStorage(conversations);
      return {
        ...state,
        conversations: conversations,
        isFetchingConversations: false,
      };
    }

    case actionTypes.UPDATE_UNREAD_MESSAGE: {
      const {
        conversations,
        moreConversations,
        dmConversations,
        roomConversations,
      } = state;
      const index = conversations.findIndex(
        (room) => room._id === action.payload?.roomId,
      );
      if (conversations[index]?.unreadMessagesCount) {
        conversations[index].unreadMessagesCount = 0;
      }
      const moreIndex = moreConversations.findIndex(
        (room) => room._id === action.payload?.roomId,
      );
      if (moreConversations[moreIndex]?.unreadMessagesCount) {
        moreConversations[moreIndex].unreadMessagesCount = 0;
      }
      const dmIndex = dmConversations.findIndex(
        (room) => room._id === action.payload?.roomId,
      );
      if (dmConversations[dmIndex]?.unreadMessagesCount) {
        dmConversations[dmIndex].unreadMessagesCount = 0;
      }
      const roomIndex = roomConversations.findIndex(
        (room) => room._id === action.payload?.roomId,
      );
      if (roomConversations[roomIndex]?.unreadMessagesCount) {
        roomConversations[roomIndex].unreadMessagesCount = 0;
      }
      saveConversationToLocalStorage(conversations);
      return {
        ...state,
        conversations: conversations,
        moreConversations: moreConversations,
        dmConversations: dmConversations,
        roomConversations: roomConversations,
      };
    }

    case actionTypes.UPDATE_UNREAD_MESSAGE_INCREMENT: {
      let _conversations = [...state.conversations];
      const index = _conversations.findIndex(
        (room) => room._id === action.payload?.roomId,
      );

      //unread message count increment
      if (index !== -1) {
        const currentUnreadCount =
          _conversations[index]?.unreadMessagesCount || 0;
        const newUnreadCount =
          currentUnreadCount + (action.payload?.count || 1);

        _conversations[index] = {
          ..._conversations[index],
          unreadMessagesCount: newUnreadCount,
          last_chat: action.payload?.last_chat,
        };
      }

      //change order
      const singleConversation = _conversations.find(
        (conv) => conv._id === action.payload?.roomId,
      );
      if (singleConversation) {
        _conversations = [singleConversation, ..._conversations];
        _conversations = [...new Set(_conversations)];
      }
      saveConversationToLocalStorage(_conversations);
      return {
        ...state,
        conversations: _conversations,
      };
    }

    case actionTypes.UPDATE_CONVERSATION_ORDER: {
      //change order
      let { conversations } = state;
      if (conversations[0]?._id !== action.payload?.roomId) {
        const singleConversation = conversations.find(
          (conv) => conv._id === action.payload?.roomId,
        );
        if (singleConversation) {
          conversations = [singleConversation, ...conversations];
          conversations = [...new Set(conversations)];
        }
      }
      saveConversationToLocalStorage(conversations);
      return {
        ...state,
        conversations: conversations,
      };
    }

    case actionTypes.UPDATE_CONVERSATION_LAST_MESSAGE: {
      const { conversations, dmConversations, roomConversations } = state;

      const singleConversationIndex = conversations.findIndex(
        (conv) => conv._id === action.payload?.roomId,
      );

      if (singleConversationIndex !== -1) {
        conversations[singleConversationIndex].last_chat = {
          _id: action.payload.chat.uniqueId,
          createdAt: action.payload.chat.createdAt,
          msg: action.payload.chat.message,
          usersMentioned: action.payload.chat.usersMentioned,
          isEncrypted: action.payload.chat.isEncrypted,
          room: action.payload.chat.room,
          attachments: action.payload.chat.attachments,
        };
      }

      const dmConversationIndex = dmConversations.findIndex(
        (conv) => conv._id === action.payload?.roomId,
      );

      if (dmConversationIndex !== -1) {
        dmConversations[dmConversationIndex].last_chat = {
          _id: action.payload.chat.uniqueId,
          createdAt: action.payload.chat.date,
          msg: action.payload.chat.message,
          usersMentioned: action.payload.chat.usersMentioned,
          isEncrypted: action.payload.chat.isEncrypted,
          room: action.payload.chat.room,
        };
      }

      const roomConversationIndex = roomConversations.findIndex(
        (conv) => conv._id === action.payload?.roomId,
      );

      if (roomConversationIndex !== -1) {
        roomConversations[roomConversationIndex].last_chat = {
          _id: action.payload.chat.uniqueId,
          createdAt: action.payload.chat.date,
          msg: action.payload.chat.message,
          usersMentioned: action.payload.chat.usersMentioned,
          isEncrypted: action.payload.chat.isEncrypted,
          room: action.payload.chat.room,
        };
      }
      saveConversationToLocalStorage(conversations);
      return {
        ...state,
        conversations: conversations,
        dmConversations: dmConversations,
        roomConversations: roomConversations,
      };
    }

    case actionTypes.UPDATE_CHAT_USER_STATUS: {
      let { conversations } = state;
      const conv = conversations.find(
        (c) =>
          action.payload.authUserId !== action.payload.profile._id &&
          c.isChatRoom &&
          c.chatUsers.find((u) => u._id === action.payload.profile._id),
      );
      if (conv) {
        conversations = conversations.map((cn) => {
          if (cn._id === conv._id) {
            cn.chatUsers.map((u) => {
              if (u._id === action.payload.profile._id) {
                u.userState = action.payload.profile.userState;
                return u;
              }
              return u;
            });
            return cn;
          }
          return cn;
        });
      }
      saveConversationToLocalStorage(conversations);
      return {
        ...state,
        conversations: conversations,
      };
    }

    case actionTypes.GET_ROOM_ID_FROM_SLUG_REQUEST: {
      return {
        ...state,
        slug: undefined,
        error: false,
        isFetchingRoomId: true,
      };
    }

    case actionTypes.CLEAR_SCREEN_ROOM_SLUG_SUCCESS: {
      return {
        ...state,
        slug: undefined,
        error: false,
        roomId: null,
        isFetchingRoomId: false,
      };
    }

    case actionTypes.GET_ROOM_ID_FROM_SLUG_SUCCESS: {
      return {
        ...state,
        slug: action.payload.slug,
        roomId: action.payload.roomId,
        error: false,
        isFetchingRoomId: false,
      };
    }

    case actionTypes.GET_ROOM_ID_FROM_SLUG_FAILURE: {
      return {
        ...state,
        slug: undefined,
        error: true,
        isFetchingRoomId: false,
      };
    }

    case actionTypes.GET_SEARCH_ROOMS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreSearching: true,
        };
      }
      return {
        ...state,
        isSearching: true,
      };

    case actionTypes.GET_SEARCH_ROOMS_SUCCESS:
      if (action.payload.loadMore) {
        const newSearchedRooms = [
          ...state.searchedRooms,
          ...action.payload.rooms,
        ];
        return {
          ...state,
          searchedRooms: newSearchedRooms,
          isSearching: false,
          isMoreSearching: false,
          isSearchMore: action.payload.rooms.length >= 10 ? true : false,
        };
      } else {
        return {
          ...state,
          isSearching: false,
          searchedRooms: action.payload.rooms,
          isSearchMore: action.payload.rooms.length >= 10 ? true : false,
        };
      }

    case actionTypes.GET_SEARCH_ROOMS_FAILURE:
      return {
        ...state,
        isSearching: false,
        isMoreSearching: false,
        isSearchMore: false,
      };

    case actionTypes.GET_MY_ROOM_SLUG_COUNT_REQUEST:
      return {
        ...state,
      };
    case actionTypes.GET_MY_ROOM_SLUG_COUNT_SUCCESS:
      return {
        ...state,
        myRoomsSlugCount: action.payload,
      };
    case actionTypes.GET_MY_ROOM_SLUG_COUNT_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default roomReducer;
