import * as actionTypes from "../constants";
import * as systemSettingService from "../services/systemSettingService";

export function getSystemSetting(dispatch) {
  dispatch({
    type: actionTypes.GET_SYSTEM_SETTINGS_REQUEST,
  });
  return systemSettingService
    .getSystemSetting()
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SYSTEM_SETTINGS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_SYSTEM_SETTINGS_FAILURE,
      });
      throw err;
    });
}

export function getExchangeRate(dispatch) {
  dispatch({
    type: actionTypes.GET_EXCHANGE_RATE_REQUEST,
  });
  return systemSettingService
    .getExchangeRate()
    .then((res) => {
      dispatch({
        type: actionTypes.GET_EXCHANGE_RATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_EXCHANGE_RATE_FAILURE,
      });
      throw err;
    });
}
