import { useEffect, useContext, useRef } from "react";
import Axios from "axios";
import {
  getChatRoom,
  getChatRoomById,
  getConversations,
} from "../actions/roomAction";
import { StoreContext } from "../Context/StoreContext";
import { getUserData } from "../services/userService";
import * as io from "socket.io-client";
import { baseURL } from "../utils/axios";
import { v4 as uuid } from "uuid";
import moment from "moment";
import * as actionTypes from "../constants";
import { useHistory } from "react-router-dom";
import { isEmptyObject, updateChatMessageToLocalStorage } from "../utils/utils";

const useChat = (
  userId,
  roomId,
  checkSubscription = true,
  isChatModal = false,
) => {
  const source = Axios.CancelToken.source();

  const { state, dispatch } = useContext(StoreContext);
  const socketRefChat = useRef();
  const {
    auth: { user },
    room: { chatRoom },
    persona: { aiReplyEnable },
  } = state;
  const history = useHistory();
  const getData = async () => {
    if (userId) {
      await getChatRoom(user?.token, userId, dispatch, source);
      return;
    }
    if (roomId) {
      await getChatRoomById(
        user?.token,
        roomId,
        dispatch,
        handleRedirect,
        checkSubscription,
        source,
      );
    }
  };

  const handleRedirect = () => {
    history.push(`/${roomId}`);
  };

  const getConversationsFun = async () => {
    await getConversations(user?.token, dispatch, false, 0);
  };

  const chatUserUpdate = (data) => {
    dispatch({
      type: actionTypes.UPDATE_CHAT_USER_STATUS,
      payload: {
        profile: data.profile_data,
        authUserId: user?._id,
      },
    });
    dispatch({
      type: actionTypes.UPDATE_ACTIVE_CHAT_ROOM,
      payload: {
        profile: data.profile_data,
        authUserId: user?._id,
      },
    });
  };

  useEffect(() => {
    if (user?.token && !isChatModal) {
      getConversationsFun();
    }
  }, []);

  useEffect(() => {
    const storedChatMessages =
      JSON.parse(localStorage.getItem("ChatRoomStore")) || {};
    if (!isEmptyObject(storedChatMessages[roomId])) {
      dispatch({
        type: actionTypes.GET_CHAT_ROOM_SUCCESS,
        payload: {
          chatRoom: storedChatMessages[roomId],
        },
      });
    }
    getData();
    return () => {
      source.cancel();
    };
  }, [userId, roomId]);

  useEffect(() => {
    const storedChatMessages =
      JSON.parse(localStorage.getItem("ChatStore")) || {};
    const cachedRoomMsg = storedChatMessages[roomId || userId] || [];
    if (cachedRoomMsg && cachedRoomMsg.length > 0) {
      dispatch({
        type: actionTypes.GET_ROOM_MESSAGES_SUCCESS,
        payload: cachedRoomMsg,
        loadMore: false,
        offset: 0,
      });
    }
    return () => {
      source.cancel();
    };
  }, [userId, roomId]);

  useEffect(() => {
    if (user?.token && chatRoom?._id) {
      const userData = getUserData(user);
      socketRefChat.current = io.connect(`${baseURL}/chat`, {
        auth: {
          token: user?.token,
        },
      });
      socketRefChat.current.on("connect", () => {
        socketRefChat.current.emit("join-room-for-chat", {
          roomId: chatRoom?._id,
          username: userData.username,
          isChatRoom: true,
        });
      });

      socketRefChat.current.on("chat_room_message", handleChatMessage);
      socketRefChat.current.on("ami_chat_request", handleAmiChatRequest);
      socketRefChat.current.on("ami_chat_response", onMessageDeleted);
      socketRefChat.current.on("update_read_status", handleReadStatus);
      socketRefChat.current.on(
        "update_all_unread_msg_status",
        handleAllUnreadMessage,
      );
      socketRefChat.current.on("count_active_users", setActiveUsers);
      socketRefChat.current.on("chat_user_update", chatUserUpdate);
      socketRefChat.current.on("room_message_updated", onMessageEdited);
      socketRefChat.current.on("room_message_deleted", onMessageDeleted);
      socketRefChat.current.on("chat_reaction_added", onMessageReacted);
      socketRefChat.current.on("chat_reaction_updated", onReactionUpdated);
      socketRefChat.current.on("chat_reaction_removed", onReactionRemoved);
    }

    return () => {
      if (socketRefChat.current) {
        socketRefChat.current.disconnect();
        socketRefChat.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoom?._id, user]);

  const setActiveUsers = (data) => {
    if (data?.roomId) {
      dispatch({
        type: actionTypes.UPDATE_ROOM_ACTIVE_USER_COUNT,
        payload: data,
      });
    }
  };

  const handleAllUnreadMessage = (data) => {
    dispatch({
      type: actionTypes.UPDATE_UNREAD_MESSAGES_STATUS,
      payload: data,
    });
  };

  const handleReadStatus = (data) => {
    if (data.uniqueId) {
      dispatch({
        type: actionTypes.UPDATE_MESSAGE,
        payload: {
          data: {
            _id: data._id,
            isRead: !!data.isRead?.length,
            isDelivered: !!data.isDelivered?.length,
          },
          uniqueId: data.uniqueId,
        },
      });
    }
  };

  const handleAmiChatRequest = (data) => {
    dispatch({
      type: actionTypes.ADD_MESSAGE,
      payload: {
        message: data.msg,
        isAmiRequest: true,
        _id: data._id,
      },
    });
    dispatch({
      type: actionTypes.IS_PERSONA_THINKING_OR_NOT,
      payload: {
        isPersonaThinking: true,
      },
    });
  };

  const handleChatMessage = (data) => {
    const chatReceived = {
      message: data.msg,
      isSend:
        !data.isPersonaChat && !data.isAmiBot && data?.userId === user?._id
          ? true
          : false,
      isDanger: data.isDanger,
      isBot: data.isBot,
      isAmiBot: data.isAmiBot,
      isPersonaChat: data.isPersonaChat,
      isReply: data.isReply,
      replyFor: data.replyFor,
      username: data.username,
      _id: data._id,
      room: chatRoom?._id,
      date: new Date(data.createdAt).toLocaleString().split(",")[0],
      createdAt: moment(data.createdAt).format("hh:mm A"),
      reactions: [],
      usersMentioned: data.usersMentioned,
      isEncrypted: data.isEncrypted ? data.isEncrypted : true,
      persistedAt: data?.persistedAt ? data.persistedAt : null,
      attachments: data?.attachments ? data?.attachments : [],
    };
    dispatch({
      type: actionTypes.ADD_MESSAGE,
      payload: chatReceived,
    });
    updateChatMessageToLocalStorage(chatRoom?._id, chatReceived);
    dispatch({
      type: actionTypes.UPDATE_CONVERSATION_ORDER,
      payload: {
        roomId: chatRoom?._id,
      },
    });
    dispatch({
      type: actionTypes.UPDATE_CONVERSATION_LAST_MESSAGE,
      payload: {
        roomId: chatRoom?._id,
        chat: {
          uniqueId: data._id,
          message: data.msg,
          usersMentioned: data.usersMentioned,
          date: moment(data.createdAt).format("hh:mm A"),
          isEncrypted: data.isEncrypted ? data.isEncrypted : true,
          room: chatRoom?._id,
          createdAt: data.createdAt,
          attachments: data?.attachments ? data?.attachments : [],
        },
      },
    });
    dispatch({
      type: actionTypes.IS_PERSONA_THINKING_OR_NOT,
      payload: {
        isPersonaThinking: false,
      },
    });
  };

  const editMessage = (chat) => {
    socketRefChat.current.emit("edit_room_chat", {
      msg: chat.message,
      userId: user?._id,
      chatId: chat.chatId,
    });
  };

  const deleteMessage = (chatId) => {
    socketRefChat.current.emit("delete_room_chat", {
      userId: user?._id,
      chatId: chatId,
    });
  };

  const onMessageEdited = (chat) => {
    dispatch({
      type: actionTypes.EDIT_MESSAGE,
      payload: {
        message: chat.msg,
        chatId: chat._id,
        isEncrypted: chat?.isEncrypted ? chat.isEncrypted : true,
      },
    });
  };

  const onMessageDeleted = (chat) => {
    dispatch({
      type: actionTypes.TOGGLE_SCROLL,
      payload: false,
    });
    dispatch({
      type: actionTypes.DELETE_MESSAGE,
      payload: {
        chatId: chat._id,
      },
    });
  };

  const onMessageReacted = (body) => {
    dispatch({
      type: actionTypes.TOGGLE_SCROLL,
      payload: false,
    });
    dispatch({
      type: actionTypes.ADD_CHAT_REACTION,
      payload: {
        reaction: body,
        chatId: body.chat._id,
      },
    });
  };

  const onReactionUpdated = (body) => {
    dispatch({
      type: actionTypes.REMOVE_CHAT_REACTION,
      payload: {
        reactionId: body._id,
        chatId: body.chat._id,
        userId: body.owner,
      },
    });
  };

  const onReactionRemoved = (body) => {
    dispatch({
      type: actionTypes.TOGGLE_SCROLL,
      payload: false,
    });
    dispatch({
      type: actionTypes.ADD_CHAT_REACTION,
      payload: {
        reaction: body,
        chatId: body.chat._id,
      },
    });
  };

  const sendMessage = (
    msg,
    usersMentioned = [],
    isEncrypted,
    attachments = [],
    isReply = false,
    replyFor = {},
    chatType = "",
  ) => {
    const userData = getUserData(user);
    const uniqueId = uuid();
    socketRefChat.current.emit("send_room_chat", {
      username: userData.username,
      roomId: chatRoom?._id,
      msg,
      isReply,
      replyFor,
      userId: userId,
      roomUsers: chatRoom?.chatUsers.map((u) => u._id),
      uniqueId: uniqueId,
      room: {
        name: chatRoom?.name,
        cover: chatRoom.cover.urls?.small,
        moderators: chatRoom?.moderators,
        owner: chatRoom?.owner._id,
        specificChatId: chatRoom?.specificChatId,
        openAIData: { ...chatRoom?.openAIData, isAIReplyEnable: aiReplyEnable },
      },
      usersMentioned: usersMentioned,
      isEncrypted: isEncrypted,
      attachments,
      chatType,
    });

    const currentChat = {
      message: msg,
      isSend: true,
      isDanger: false,
      isBot: false,
      username: userData.username,
      uniqueId: uniqueId,
      room: chatRoom?._id,
      date: new Date().toLocaleString().split(",")[0],
      createdAt: moment(new Date()).format("hh:mm A"),
      reactions: [],
      usersMentioned: usersMentioned,
      isEncrypted: isEncrypted,
      isReply,
      replyFor,
      attachments,
    };
    dispatch({
      type: actionTypes.ADD_MESSAGE,
      payload: currentChat,
    });

    dispatch({
      type: actionTypes.UPDATE_CONVERSATION_LAST_MESSAGE,
      payload: {
        roomId: chatRoom?._id,
        chat: {
          uniqueId: uniqueId,
          message: msg,
          usersMentioned: usersMentioned,
          date: new Date().toLocaleString().split(",")[0],
          isEncrypted: isEncrypted,
          room: chatRoom?._id,
          createdAt: new Date(),
          isReply,
          replyFor,
          attachments,
        },
      },
    });
    updateChatMessageToLocalStorage(chatRoom?._id, currentChat);
  };

  const addReaction = (body) => {
    socketRefChat.current.emit("add_reaction_chat", body);
  };

  const removeUserOrAddReaction = (body) => {
    socketRefChat.current.emit("remove_user_or_add_reaction_chat", body);
  };

  const addUserToReaction = (body) => {
    socketRefChat.current.emit("add_user_to_reaction_chat", body);
  };

  return {
    sendMessage,
    handleChatMessage,
    editMessage,
    deleteMessage,
    addReaction,
    removeUserOrAddReaction,
    addUserToReaction,
  };
};

export default useChat;
