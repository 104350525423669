import React from "react";

function Plans() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#1F2023" />
      <g clipPath="url(#clip0_52_10415)">
        <path
          d="M16.6667 9.33333L10 17.3333H16L15.3333 22.6667L22 14.6667H16L16.6667 9.33333Z"
          fill="#1F2023"
          stroke="#1F2023"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_52_10415)">
          <path
            d="M16 22.6667C19.6819 22.6667 22.6667 19.6819 22.6667 16C22.6667 12.3181 19.6819 9.33333 16 9.33333C12.3181 9.33333 9.33334 12.3181 9.33334 16C9.33334 19.6819 12.3181 22.6667 16 22.6667Z"
            fill="#34C759"
            stroke="#34C759"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 12.3333V13.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 18.5V19.6667"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6667 13.6667H15.1667C14.8572 13.6667 14.5605 13.7896 14.3417 14.0084C14.1229 14.2272 14 14.5239 14 14.8333C14 15.1427 14.1229 15.4395 14.3417 15.6583C14.5605 15.8771 14.8572 16 15.1667 16H16.8333C17.1428 16 17.4395 16.1229 17.6583 16.3417C17.8771 16.5605 18 16.8572 18 17.1667C18 17.4761 17.8771 17.7728 17.6583 17.9916C17.4395 18.2104 17.1428 18.3333 16.8333 18.3333H14"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_52_10415">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
        <clipPath id="clip1_52_10415">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Plans;
