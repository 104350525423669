import * as actionTypes from "../constants";
import * as rooomService from "../services/rooomService";
import {
  isEmptyObject,
  saveChatRoomToLocalStorage,
  saveConversationToLocalStorage,
} from "../utils/utils";

export function getActiveRooms(dispatch, token) {
  dispatch({
    type: actionTypes.GET_LIVE_ROOMS_CALL_REQUEST,
  });
  return rooomService
    .getActiveRooms(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_LIVE_ROOMS_CALL_SUCCESS,
        payload: {
          calls: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_LIVE_ROOMS_CALL_FAILURE,
      });
      throw err;
    });
}
export function getPublicRooms(dispatch, token) {
  dispatch({
    type: actionTypes.GET_PUBLIC_ROOMS_REQUEST,
  });
  return rooomService
    .getPublicRooms(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PUBLIC_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PUBLIC_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function searchRooms(dispatch, token, data, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_SEARCH_ROOMS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .searchRooms(token, data, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SEARCH_ROOMS_SUCCESS,
        payload: {
          rooms: res.data,
          loadMore: loadMore,
        },
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_SEARCH_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getAllRooms(dispatch, token, roomTypes, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_PUBLIC_ROOMS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getAllRooms(token, roomTypes, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PUBLIC_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PUBLIC_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getMyRoomCount(token, dispatch) {
  dispatch({
    type: actionTypes.GET_MY_ROOM_COUNT_REQUEST,
  });
  return rooomService
    .getMyRoomCount(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_ROOM_COUNT_SUCCESS,
        payload: {
          myRoomCount: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_ROOM_COUNT_FAILURE,
      });
      throw err;
    });
}

export function getMyRoomSlugCount(token, dispatch) {
  dispatch({
    type: actionTypes.GET_MY_ROOM_SLUG_COUNT_REQUEST,
  });
  return rooomService
    .getMyRoomSlugCount(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_ROOM_SLUG_COUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_ROOM_SLUG_COUNT_FAILURE,
      });
      throw err;
    });
}

export function getMyRooms(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_MY_ROOMS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getMyRooms(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getSubscribedRooms(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_SUBSCRIBED_ROOMS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getSubscribedRooms(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SUBSCRIBED_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_SUBSCRIBED_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getScreenRoom(token, dispatch, roomId) {
  return rooomService
    .getSingleRoom(roomId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SCREEN_ROOM_SUCCESS,
        payload: {
          liveRoom: res.data.room,
        },
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_SCREEN_ROOM_FAILURE,
      });
      throw err;
    });
}

export function getNFTCollectionRooms(token, fetchType, dispatch) {
  dispatch({
    type: actionTypes.GET_NFT_ROOMS_REQUEST,
  });
  return rooomService
    .getNFTCollectionRooms(token, fetchType)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_NFT_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_NFT_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function createRoom(data, token, dispatch) {
  dispatch({
    type: actionTypes.CREATE_ROOM_REQUEST,
  });
  return rooomService
    .createRoom(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_ROOM_SUCCESS,
        payload: res.data.room,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_ROOM_FAILURE,
      });
      throw err;
    });
}

export function updateRoom(data, roomId, token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ROOM_REQUEST,
  });
  return rooomService
    .updateRoom(data, roomId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_ROOM_SUCCESS,
        payload: res.data.room,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_ROOM_FAILURE,
      });
      throw err;
    });
}

export function deleteRoom(roomId, token, dispatch) {
  dispatch({
    type: actionTypes.DELETE_ROOM_REQUEST,
  });
  return rooomService
    .deleteRoom(roomId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.DELETE_ROOM_SUCCESS,
        payload: res.data.room,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getGenaralRoom(token, dispatch) {
  dispatch({
    type: actionTypes.GET_GEN_ROOM_REQUEST,
  });
  return rooomService
    .getGenaralRoom(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_GEN_ROOM_SUCCESS,
        payload: {
          room: res.data.room,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_GEN_ROOM_FAILURE,
      });
      throw err;
    });
}

export function getPopularRooms(token, dispatch) {
  dispatch({
    type: actionTypes.GET_POPULAR_ROOMS_REQUEST,
  });
  return rooomService
    .getPopularRooms(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_POPULAR_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_POPULAR_ROOMS_FAILURE,
      });
      throw err;
    });
}
export function getTopRooms(
  token,
  dispatch,
  offset = 0,
  loadMore = false,
  isIntervalCall = false,
) {
  dispatch({
    type: actionTypes.GET_TOP_ROOMS_REQUEST,
    loadMore: loadMore,
    intervalCall: isIntervalCall,
  });

  return rooomService
    .getTopRooms(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_TOP_ROOMS_SUCCESS,
        payload: {
          rooms: res.data,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_TOP_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getTrendingRooms(dispatch) {
  dispatch({
    type: actionTypes.GET_TRENDING_ROOMS_REQUEST,
  });
  return rooomService
    .getTrendingRooms()
    .then((res) => {
      dispatch({
        type: actionTypes.GET_TRENDING_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_TRENDING_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getNewlyAddedRooms(token, dispatch) {
  dispatch({
    type: actionTypes.GET_NEWLY_ADDED_ROOMS_REQUEST,
  });
  return rooomService
    .getNewlyAddedRooms(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_NEWLY_ADDED_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_NEWLY_ADDED_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function createRoomSearch(token, roomId) {
  return rooomService
    .createRoomSearch(token, roomId)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
}

export function getMyRecentRooms(token, dispatch) {
  dispatch({
    type: actionTypes.GET_RECENT_ROOMS_REQUEST,
  });
  return rooomService
    .getMyRecentRooms(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_RECENT_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_RECENT_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function resetInvite(dispatch) {
  dispatch({
    type: actionTypes.INVITE_USER_RESET,
  });
}

export function resetBlockList(dispatch) {
  dispatch({
    type: actionTypes.BLOCK_USER_RESET,
  });
}

export function inviteUser(data, token, dispatch, isdesoUser) {
  dispatch({
    type: actionTypes.INVITE_USER_REQUEST,
  });
  const api = isdesoUser
    ? rooomService.inviteDesoUser
    : rooomService.inviteUser;
  return api(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.INVITE_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.INVITE_USER_FAILURE,
      });
      throw err;
    });
}

export function blockUserFromRoom(data, token, dispatch) {
  dispatch({
    type: actionTypes.BLOCK_USER_REQUEST,
  });
  return rooomService
    .blockUserFromRoom(token, data)
    .then((res) => {
      dispatch({
        type: actionTypes.BLOCK_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.BLOCK_USER_FAILURE,
      });
      throw err;
    });
}

export function getUserRoomsByUserId(
  token,
  userId,
  dispatch,
  roomTypes,
  loadMore,
  offset,
) {
  dispatch({
    type: actionTypes.GET_USER_ROOMS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getUserRoomsByUserId(token, userId, roomTypes, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_USER_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_ROOMS_FAILURE,
      });
      throw err;
    });
}

export function getChatRoom(token, userId, dispatch, source) {
  dispatch({
    type: actionTypes.GET_CHAT_ROOM_REQUEST,
  });
  const storedChatMessages =
    JSON.parse(localStorage.getItem("ChatRoomStore")) || {};
  const cachedRoomMsg = storedChatMessages[userId] || {};

  if (cachedRoomMsg && !isEmptyObject(cachedRoomMsg)) {
    dispatch({
      type: actionTypes.GET_CHAT_ROOM_SUCCESS,
      payload: {
        chatRoom: cachedRoomMsg,
      },
    });
  }
  return rooomService
    .getChatRoom(token, userId, source)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_CHAT_ROOM_SUCCESS,
        payload: {
          chatRoom: res.data.room,
        },
      });
      saveChatRoomToLocalStorage(userId, res.data.room);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_CHAT_ROOM_FAILURE,
      });
      throw err;
    });
}

export function getChatRoomById(
  token,
  roomId,
  dispatch,
  handleRedirect,
  checkSubscription = true,
  source,
) {
  dispatch({
    type: actionTypes.GET_CHAT_ROOM_REQUEST,
  });
  const storedChatMessages =
    JSON.parse(localStorage.getItem("ChatRoomStore")) || {};
  const cachedRoomMsg = storedChatMessages[roomId] || {};

  if (cachedRoomMsg && !isEmptyObject(cachedRoomMsg)) {
    dispatch({
      type: actionTypes.GET_CHAT_ROOM_SUCCESS,
      payload: {
        chatRoom: cachedRoomMsg,
      },
    });
  }
  return rooomService
    .getChatRoomById(token, roomId, checkSubscription, source)
    .then((res) => {
      if (res.data.room) {
        dispatch({
          type: actionTypes.GET_CHAT_ROOM_SUCCESS,
          payload: {
            chatRoom: res.data.room,
          },
        });
        saveChatRoomToLocalStorage(roomId, res.data.room);
      } else {
        handleRedirect();
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_CHAT_ROOM_FAILURE,
      });
      throw err;
    });
}

export function getConversations(token, dispatch, loadMore, offset) {
  const storedConversationMessages =
    JSON.parse(localStorage.getItem("ChatConversationStore")) || [];
  if (storedConversationMessages?.length && !loadMore) {
    dispatch({
      type: actionTypes.GET_CONVERSATIONS_SUCCESS,
      payload: {
        conversations: storedConversationMessages,
        offset: offset,
        loadMore: loadMore,
      },
    });
  }
  dispatch({
    type: actionTypes.GET_CONVERSATIONS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getConversations(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_CONVERSATIONS_SUCCESS,
        payload: {
          conversations: res.data.conversations,
          offset: offset,
          loadMore: loadMore,
        },
      });
      if (!loadMore) {
        saveConversationToLocalStorage(res.data.conversations);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_CONVERSATIONS_FAILURE,
      });
      throw err;
    });
}

export function getDmConversations(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_DM_CONVERSATIONS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getConversations(token, offset, "dm")
    .then((res) => {
      dispatch({
        type: actionTypes.GET_DM_CONVERSATIONS_SUCCESS,
        payload: {
          conversations: res.data.conversations,
          offset: offset,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_DM_CONVERSATIONS_FAILURE,
      });
      throw err;
    });
}

export function getRoomConversations(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_ROOM_CONVERSATIONS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getConversations(token, offset, "room")
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ROOM_CONVERSATIONS_SUCCESS,
        payload: {
          conversations: res.data.conversations,
          offset: offset,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ROOM_CONVERSATIONS_FAILURE,
      });
      throw err;
    });
}

export function getMoreConversations(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_MORE_CONVERSATIONS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getConversations(token, offset, "more")
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MORE_CONVERSATIONS_SUCCESS,
        payload: {
          conversations: res.data.conversations,
          offset: offset,
          loadMore: loadMore,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MORE_CONVERSATIONS_FAILURE,
      });
      throw err;
    });
}

export function getRoomIdByRoomSlug(slug, dispatch) {
  dispatch({
    type: actionTypes.GET_ROOM_ID_FROM_SLUG_REQUEST,
  });
  return rooomService
    .getRoomIdByRoomSlug(slug)
    .then((res) => {
      if (res?.data?.roomId) {
        dispatch({
          type: actionTypes.GET_ROOM_ID_FROM_SLUG_SUCCESS,
          payload: {
            roomId: res.data.roomId,
            slug: res.data.slug,
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ROOM_ID_FROM_SLUG_FAILURE,
      });
      throw err;
    });
}

export async function getRoomSubscriberCount({ dispatch, token, roomId }) {
  try {
    const res = await rooomService.getRoomSubscriberCount(token, roomId);
    dispatch({
      type: actionTypes.GET_ROOM_SUBSCRIBER_COUNT_SUCCESS,
      payload: res.data.count,
    });
  } catch (err) {
    throw err;
  }
}

export function getHomePopularRooms(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_HOME_POPULAR_ROOMS_REQUEST,
    loadMore: loadMore,
  });
  return rooomService
    .getHomePopularRooms(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_HOME_POPULAR_ROOMS_SUCCESS,
        payload: {
          rooms: res.data.rooms,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_HOME_POPULAR_ROOMS_FAILURE,
      });
      throw err;
    });
}
