import * as actionTypes from "../constants";
import * as postService from "../services/postService";

export function getPosts(dispatch, token, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_POSTS_REQUEST,
    loadMore: loadMore,
  });
  return postService
    .getPosts(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_HOME_POSTS_SUCCESS,
        payload: {
          posts: res.data.posts,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_POSTS_FAILURE,
      });
      throw err;
    });
}

export function getUserPosts(dispatch, token, isUserRoomPosts, offset, userId) {
  dispatch({
    type: actionTypes.GET_USER_POSTS_REQUEST,
    loadMore: isUserRoomPosts,
  });
  return postService
    .getUserPosts(token, offset, userId)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_USER_POSTS_SUCCESS,
        payload: {
          posts: res.data.posts,
          loadMore: isUserRoomPosts,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_POSTS_FAILURE,
      });
      throw err;
    });
}

export function getRoomPosts(dispatch, token, isMoreRoomPosts, offset, roomId) {
  dispatch({
    type: actionTypes.GET_ROOM_POSTS_REQUEST,
    loadMore: isMoreRoomPosts,
  });
  return postService
    .getRoomPosts(token, offset, roomId)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ROOM_POSTS_SUCCESS,
        payload: {
          posts: res.data.posts,
          loadMore: isMoreRoomPosts,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_POSTS_FAILURE,
      });
      throw err;
    });
}

export function getUserPremiumActivity(
  dispatch,
  token,
  isMoreUserPremiumActivity,
  offset,
) {
  dispatch({
    type: actionTypes.GET_USER_PREMIUM_ACTIVITY_REQUEST,
    loadMore: isMoreUserPremiumActivity,
  });
  return postService
    .getPremiumActivityPosts(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_USER_PREMIUM_ACTIVITY_SUCCESS,
        payload: {
          posts: res.data.activities,
          loadMore: isMoreUserPremiumActivity,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_PREMIUM_ACTIVITY_FAILED,
      });
      throw err;
    });
}

export function getPersonaPosts(dispatch, token, isMorePersonaPosts, offset) {
  dispatch({
    type: actionTypes.GET_PERSONA_POSTS_REQUEST,
    loadMore: isMorePersonaPosts,
  });
  return postService
    .getPersonaPosts(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PERSONA_POSTS_SUCCESS,
        payload: {
          posts: res.data.posts,
          loadMore: isMorePersonaPosts,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PERSONA_POSTS_FAILURE,
      });
      throw err;
    });
}

export function getEventPosts(dispatch, token, loadMore, offset, eventId) {
  dispatch({
    type: actionTypes.GET_EVENT_POSTS_REQUEST,
    loadMore,
  });
  return postService
    .getEventPosts(token, offset, eventId)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_EVENT_POSTS_SUCCESS,
        payload: {
          posts: res.data.posts,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_EVENT_POSTS_FAILURE,
      });
      throw err;
    });
}
