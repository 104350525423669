import * as actionTypes from "../constants";
import * as waveService from "../services/waveService";

export function getUserWaves(token, dispatch) {
  dispatch({
    type: actionTypes.GET_USER_WAVES_REQUEST,
  });
  return waveService
    .getUserWaves(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_USER_WAVES_SUCCESS,
        payload: {
          waves: res.data.waves,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_WAVES_FAILURE,
      });
      throw err;
    });
}

export function waveToUser(data, token, dispatch) {
  dispatch({
    type: actionTypes.CREATE_WAVES_REQUEST,
  });
  return waveService
    .waveToUser(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_WAVES_SUCCESS,
        payload: res.data.wave,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function waveBack(data, token, dispatch) {
  dispatch({
    type: actionTypes.WAVE_BACK_REQUEST,
  });
  return waveService
    .waveBack(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.WAVE_BACK_SUCCESS,
        payload: res.data.updateWave,
      });
    })
    .catch((err) => {
      throw err;
    });
}
