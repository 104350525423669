import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";

const AuthGuard = ({ children }) => {
  const { state } = useContext(StoreContext);
  const { auth } = state;

  if (!auth.user) {
    return <Redirect to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
