import React, { useState, useRef, useEffect, Fragment } from "react";
import AboutDialog from "../../AboutDialog/AboutDialog";
import BackgroundIcon from "../../../icons/BackgroundIcon";
import CollaborationViewIcon from "@material-ui/icons/AccountBox";
import PeopleIcon from "@material-ui/icons/People";
import ChatBubbleRoundedIcon from "@material-ui/icons/ChatBubbleRounded";
import GridViewIcon from "@material-ui/icons/Apps";
import StartRecordingIcon from "../../../icons/StartRecordingIcon";
import StopRecordingIcon from "../../../icons/StopRecordingIcon";
import SettingsIcon from "../../../icons/SettingsIcon";
import { Theme, useMediaQuery, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { isSupported } from "@twilio/video-processors";
import { Menu, Transition } from "@headlessui/react";
import { useAppState } from "../../../state";
import useIsRecording from "../../../hooks/useIsRecording/useIsRecording";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import FlipCameraIcon from "../../../icons/FlipCameraIcon";
import useFlipCameraToggle from "../../../hooks/useFlipCameraToggle/useFlipCameraToggle";
import useVibehutData from "../../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../../globals";
import useAuthUser from "../../../hooks/useAuthUser/useAuthUser";
import MemberListDialog from "../../MemberListDialog";
import useVibehutChatContext from "../../../hooks/useVibehutChatContext/useVibehutChatContext";
import ChatWindow from "../../ChatWindow/ChatWindow";
import More from "../../../VibehutUI/Icons/More";
import VideoSettings from "../../VideoSettings";
import CopyButton from "../../../VibehutUI/CopyButton";
import { frontEndURL } from "../../../constants";
import useQuery from "../../../hooks/useQuery";
import ToolTip from "../../../VibehutUI/ToolTip";
import useScreenShareParticipant from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import Expand from "../../../icons/Expand";
import Minimize from "../../../icons/Minimize";
import useVibehutAuth from "../../../state/useVibehutAuth/useVibehutAuth";
import MediaErrorToolTip from "../../MediaErrorToolTip/MediaErrorToolTip";
import CastIcon from "../../../icons/CastIcon";
import LiveSettings from "../../LiveSettings";
export const IconContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "1.5em",
  marginRight: "0.3em",
});

interface MenuOptionProps {
  buttonClassName?: string;
}

export default function MenuOption({ buttonClassName }: MenuOptionProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { authUser } = useAuthUser();

  const [isLiveSettingsOpen, setIsLiveSettingsOpen] = useState(false);

  const query = useQuery();
  const v = query.get("v") || "";

  const { roomData, instantRoomId } = useVibehutData(globals.roomId, v);

  const roomOwnerId = roomData.owner?._id;

  const authUserId = authUser?._id;
  const isGroupRoom = roomData.isGroupRoom;

  const isRoomAdmin =
    roomOwnerId === authUserId ||
    (roomData.moderators !== undefined &&
      roomData.moderators.includes(authUserId));

  const canStartRecording =
    roomOwnerId === authUserId ||
    !isGroupRoom ||
    (roomData.moderators !== undefined &&
      roomData.moderators.includes(authUserId));

  const [aboutOpen, setAboutOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [memberListOpen, setMemberListOpen] = useState(false);

  const {
    isFetching,
    roomType,
    setIsGalleryViewActive,
    isGalleryViewActive,
    setIsFullScreenViewActive,
    isFullScreenViewActive,
  } = useAppState();
  const { setIsChatWindowOpen } = useVibehutChatContext();
  const isRecording = useIsRecording();
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();

  const anchorRef = useRef(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } =
    useFlipCameraToggle();
  const [isToolTipOpen, setIsToolTipOpen] = useState(true);
  const { updateRecordingRules } = useVibehutAuth();
  const screenShareParticipant = useScreenShareParticipant();
  const [mediaError, setMediaError] = useState<Error>();
  const [isToolTipDismissed, setIsToolTipDismissed] = useState(false);
  const { getAudioAndVideoTracks } = useVideoContext();

  useEffect(() => {
    const isOldUser: string | null = window.localStorage.getItem("isOldUser");

    if (Number(isOldUser) === 1) {
      setIsToolTipOpen(false);
    }
  }, []);

  useEffect(() => {
    if (!mediaError && !isToolTipDismissed) {
      getAudioAndVideoTracks().catch((error) => {
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  function handleToolTip() {
    const isOldUser = window.localStorage.getItem("isOldUser");
    if (Number(isOldUser) !== 1) {
      localStorage.setItem("isOldUser", String(1));
    }
    setIsToolTipOpen(false);
  }

  return (
    <>
      <MediaErrorToolTip
        error={mediaError}
        setIsToolTipDismissed={setIsToolTipDismissed}
        isToolTipDismissed={isToolTipDismissed}
      />
      <ToolTip isOpen={isToolTipOpen} handleToolTip={handleToolTip} />
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="ml-1"
      >
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="" ref={anchorRef}>
                  <div
                    className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-700 cursor-pointer"
                    ref={anchorRef}
                    onClick={handleToolTip}
                  >
                    <More />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute bottom-12 -left-20  w-60 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  <div className="px-1  ">
                    <Menu.Item>
                      <button
                        onClick={() => {
                          setSettingsOpen(true);
                        }}
                        className="group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm"
                      >
                        <Typography variant="body1">
                          Audio and Video Settings
                        </Typography>
                        <IconContainer>
                          <SettingsIcon />
                        </IconContainer>
                      </button>
                    </Menu.Item>
                  </div>
                  {isSupported && (
                    <div className="px-1  ">
                      <Menu.Item>
                        <button
                          onClick={() => {
                            setIsBackgroundSelectionOpen(true);
                            setIsChatWindowOpen(false);
                          }}
                          className="group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm"
                        >
                          <Typography variant="body1">Backgrounds</Typography>
                          <IconContainer>
                            <BackgroundIcon />
                          </IconContainer>
                        </button>
                      </Menu.Item>
                    </div>
                  )}
                  <div className="px-1  ">
                    <Menu.Item>
                      <button
                        className="group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm"
                        onClick={() => {
                          setIsLiveSettingsOpen(true);
                        }}
                        disabled={!Boolean(isRoomAdmin)}
                      >
                        <Typography variant="body1">
                          <span
                            className={`${
                              !Boolean(isRoomAdmin) ? "text-gray-300" : ""
                            }`}
                          >
                            Live
                          </span>
                        </Typography>
                        <IconContainer>
                          <CastIcon
                            color={`${
                              !Boolean(isRoomAdmin)
                                ? "rgb(209 213 219 / 1)"
                                : "#707578"
                            }`}
                          />
                        </IconContainer>
                      </button>
                    </Menu.Item>
                  </div>
                  {flipCameraSupported && (
                    <div className="px-1  ">
                      <Menu.Item>
                        <button
                          disabled={flipCameraDisabled}
                          onClick={toggleFacingMode}
                          className="group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm"
                        >
                          <Typography variant="body1">Flip Camera</Typography>
                          <IconContainer>
                            <FlipCameraIcon />
                          </IconContainer>
                        </button>
                      </Menu.Item>
                    </div>
                  )}

                  {roomType !== "peer-to-peer" && roomType !== "go" && (
                    <div className="px-1  ">
                      <Menu.Item>
                        <button
                          className="group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm"
                          onClick={() => {
                            if (isRecording) {
                              updateRecordingRules(room!.sid, [
                                { type: "exclude", all: true },
                              ]);
                            } else {
                              updateRecordingRules(room!.sid, [
                                { type: "include", all: true },
                              ]);
                            }
                          }}
                          disabled={!Boolean(isRoomAdmin)}
                        >
                          <Typography variant="body1">
                            <span
                              className={`${
                                !Boolean(isRoomAdmin) ? "text-gray-300" : ""
                              }`}
                            >
                              {isRecording ? "Stop" : "Start"} Recording
                            </span>
                          </Typography>
                          <IconContainer>
                            {isRecording ? (
                              <StopRecordingIcon
                                color={`${
                                  !Boolean(isRoomAdmin)
                                    ? "rgb(209 213 219 / 1)"
                                    : "#707578"
                                }`}
                              />
                            ) : (
                              <StartRecordingIcon
                                color={`${
                                  !Boolean(isRoomAdmin)
                                    ? "rgb(209 213 219 / 1)"
                                    : "#707578"
                                }`}
                              />
                            )}
                          </IconContainer>
                        </button>
                      </Menu.Item>
                    </div>
                  )}

                  <div className="px-1  ">
                    <Menu.Item>
                      <button
                        onClick={() => {
                          setIsGalleryViewActive((isGallery) => !isGallery);
                        }}
                        className="group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm"
                      >
                        <Typography variant="body1">
                          {isGalleryViewActive
                            ? "Speaker View"
                            : "Gallery View"}
                        </Typography>
                        <IconContainer>
                          {isGalleryViewActive ? (
                            <CollaborationViewIcon
                              style={{ fill: "#707578", width: "0.9em" }}
                            />
                          ) : (
                            <GridViewIcon
                              style={{ fill: "#707578", width: "0.9em" }}
                            />
                          )}
                        </IconContainer>
                      </button>
                    </Menu.Item>
                  </div>
                  <div className="px-1  ">
                    <Menu.Item>
                      <button
                        onClick={() => {
                          setIsFullScreenViewActive(
                            (isFullScreen) => !isFullScreen,
                          );
                        }}
                        className="group flex rounded-md items-center justify-between w-full px-2 py-2  text-sm"
                      >
                        <Typography variant="body1">
                          {" "}
                          {isFullScreenViewActive
                            ? "Exit Full Screen"
                            : "Full Screen"}{" "}
                        </Typography>
                        <IconContainer>
                          <div style={{ color: "#707578" }}>
                            {isFullScreenViewActive ? <Minimize /> : <Expand />}
                          </div>
                        </IconContainer>
                      </button>
                    </Menu.Item>
                  </div>
                  <Menu.Item>
                    <div className=" border-gray-600 cursor-pointer text-sm px-3">
                      <CopyButton
                        url={
                          instantRoomId
                            ? `${frontEndURL}/call?v=${instantRoomId}`
                            : roomData.isWaveRoom
                              ? `${frontEndURL}/call?roomKey=${roomData._id}`
                              : `${frontEndURL}/rooms/${roomData._id}`
                        }
                        type="button"
                        text="text-base"
                        iconPosition="right"
                      />
                    </div>
                  </Menu.Item>
                  {isMobile && (
                    <Menu.Item>
                      <button
                        onClick={() => {
                          setMemberListOpen(true);
                          setIsChatWindowOpen(false);
                        }}
                        className="group flex rounded-md items-center justify-between w-full px-2 py-2  text-sm"
                      >
                        <IconContainer>
                          <PeopleIcon
                            style={{ fill: "#707578", width: "0.9em" }}
                          />
                        </IconContainer>
                        <Typography variant="body1">Members</Typography>
                      </button>
                    </Menu.Item>
                  )}
                  {isMobile && (
                    <Menu.Item>
                      <button
                        onClick={() => {
                          setIsChatWindowOpen(true);
                          setMemberListOpen(false);
                        }}
                        className="group flex rounded-md items-center justify-between w-full px-2 py-2  text-sm"
                      >
                        <IconContainer>
                          <ChatBubbleRoundedIcon
                            style={{ fill: "#707578", width: "0.7em" }}
                          />
                        </IconContainer>
                        <Typography variant="body1">Chat</Typography>
                      </button>
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
        }}
      />
      <VideoSettings
        isOpen={settingsOpen}
        setIsOpen={() => {
          setSettingsOpen(false);
        }}
      />
      <LiveSettings
        isOpen={isLiveSettingsOpen}
        setIsOpen={() => {
          setIsLiveSettingsOpen(false);
        }}
      />
      <MemberListDialog isOpen={memberListOpen} setIsOpen={setMemberListOpen} />
      <ChatWindow />
    </>
  );
}
