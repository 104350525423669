import React from "react";

const InlineSubscription = ({
  color = "#34C759",
  isInline = false,
  size = "16",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isInline ? "inline" : ""}
    >
      <path
        d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.33334V5.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5V11.6667"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66667 5.66666H7.16667C6.85725 5.66666 6.5605 5.78957 6.34171 6.00837C6.12292 6.22716 6 6.5239 6 6.83332C6 7.14274 6.12292 7.43949 6.34171 7.65828C6.5605 7.87707 6.85725 7.99999 7.16667 7.99999H8.83333C9.14275 7.99999 9.4395 8.12291 9.65829 8.3417C9.87708 8.56049 10 8.85724 10 9.16666C10 9.47608 9.87708 9.77282 9.65829 9.99161C9.4395 10.2104 9.14275 10.3333 8.83333 10.3333H6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InlineSubscription;
