export const PLAN = "PLAN";
export const WALLET = "WALLET";
export const NOTIFICATION = "NOTIFICATION";
export const CONTACT = "CONTACT";
export const APPSETTING = "APPSETTING";
export const GAMIFICATION = "GAMIFICATION";
export const PUBLICAPI = "PUBLICAPI";
export const REFERRAL = "REFERRAL";
export const SETTINGS = "SETTINGS";

export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_PLAN_MODAL = "OPEN_PLAN_MODAL";
export const OPEN_NOTIFICATION_MODAL = "OPEN_NOTIFICATION_MODAL";
export const OPEN_CONTACT_MODAL = "OPEN_CONTACT_MODAL";
export const OPEN_WALLET_MODAL = "OPEN_WALLET_MODAL";
export const OPEN_APP_SETTING_MODAL = "OPEN_APP_SETTING_MODAL";
export const OPEN_GAMIFICATION_MODAL = "OPEN_GAMIFICATION_MODAL";

export const activeTabEditProfile = {
  profileTab: "profile",
  servicesTab: "services",
  promotionTab: "promotion",
};

export const rsvpStatusTypes = {
  CONFIRMED: "confirmed",
  MAYBE: "maybe",
  DECLINED: "declined",
  REMOVED: "removed",
  INVITED: "invited",
};

export const eventModalTab = {
  CREATE: "create",
  INVITETAB: "inviteTab",
};

export const roomModalTab = {
  CREATE: "create",
  EDIT: "edit",
  SETTINGS: "Settings",
  PROMOTION: "promotion",
};

export const CLOSE_EDIT_MODAL = "CLOSE_EDIT_MODAL";
export const OPEN_EDIT_MODAL = "OPEN_EDIT_MODAL";
export const OPEN_EDIT_SERVICE_MODAL = "OPEN_EDIT_SERVICE_MODAL";
export const OPEN_EDIT_PROMOTION_MODAL = "OPEN_EDIT_PROMOTION_MODAL";

export const NotificationTypes = {
  USER_RSVP: "user_rsvp",
  WAVED: "waved",
  WAVED_BACK: "waved_back",
  ROOM_UPDATE: "room_update",
  USER_JOINED_ROOM: "user_joined_room",
  CALL_CONNECTED_IN_ROOM: "call_connected_in_room",
  RSVP_REMINDER: "rsvp_reminder",
  WAVED_JOINED: "waved_joined",
  STRIPE_USER_CONNECTED: "stripe_acc_connected",
  SUSBSCRIBED_ROOM_EVENT_CREATED: "subscribed_room_event_created",
  USER_SUSBSCRIBE_ROOM: "user_subscribe_room",
  INVITE_USER: "invite_user",
  SUBSCRIBED_ROOM_EVENT_STARTED_IN_10: "subscribed_room_event_started_in_10",
  SUSBSCRIBED_ROOM_JOINED: "subscribed_room_joined",
  USER_CONNECTED: "user_connected",
  COMPOSITION_CREATED: "composition_created",
  TRANSCRIPT_CREATED: "transcript_created",
  USER_SETTING_NOTIF: "user_setting_notif",
  ROOM_SUBSCRIBE: "room_subscribe",
  SUBSCRIBED_USER_FREE_TO_TALK: "subscribed_user_free_to_talk",
  USER_SERVICE_UPDATE: "user_service_update",
  USER_PUBLISHED_VIDEO: "user_published_video",
  USER_LIKE_VIDEO: "user_like_video",
  USER_SEND_MESSAGE: "user_send_message",
  CHAT_ACTIVE_ROOM: "chat_active_room",
  USER_CALLING: "user_calling",
  USER_CALL_MISSED: "user_call_missed",
  USER_CALL_DECLINE: "user_call_decline",
  USER_CALLING_INVITATION: "user_invitation",
  USER_MENTIONED: "user_mentioned",
  /**
   * These are the activities of the user that we want to track
   * and send notifications for.
   */
  AMI_CREATE_ROOM: "ami_create_room",
  AMI_JOIN_CALL: "ami_join_call",
  AMI_RSVP_EVENT: "ami_rsvp_event",
  AMI_ADD_SOCIALS: "ami_add_socials",
  AMI_ADD_SERVICES: "ami_add_services",
  AMI_ADD_EMAIL: "ami_add_email",
  AMI_ADD_SMS: "ami_add_sms",
  AMI_MAKE_CONNECTIONS: "ami_make_connections",
  AMI_ADD_VIBEBUCKS: "ami_add_vibebucks",
  AMI_SEND_INSTANT_CALL: "ami_send_instant_call",
  AMI_SEND_WAVE: "ami_send_wave",
  AMI_UPGRADE_PLAN: "ami_upgrade_plan",
  AMI_SEND_CHAT: "ami_send_chat",
  AMI_CREATE_PROMOTION: "ami_create_promotion",
  AMI_ADD_LOCATION: "ami_add_location",
  AMI_ADD_DESCRIPTION: "ami_add_description",
  AMI_WEEKLY_PERSONA_MATCHES: "ami_weekly_persona_matches",
  ACCEPT_INVITATION_HOST_ROOM: "accept_invitation_host_room",
  INVITE_USER_FOR_ROOM: "invite_user_for_room",
};

export const userRoleTypes = {
  STARTER: "starter",
  PRO: "pro",
  BUSINESS: "business",
  FREE: "free",
};

export const ModalType = {
  LOGIN: "login",
  NOTIFICATIONSUBSCRIPTION: "notificationSubscription",
  ADDRESSBOOK: "addressbook",
};

export const VIBEHUT_PUBLICKEY =
  "BC1YLjPVbFFLiqAtwXRNSk7fBKXxw58hZVc4i15v5ifaqjVX2MPs4b4";

export const specificChatType = {
  WATCH: "watch",
  PROMPT: "prompt",
  RESOURCE: "resource",
  EVENT: "event",
  EVENTPAGE: "eventPage",
  NOTIFICATION: "notification",
  REQUESTEVENT: "requestEvent",
  FEEDPAGE: "feedPage",
  PARTNER: "partner",
  PERSONA: "persona",
  VIDEOTRANSCRIPT: "videoTranscript",
};

export const planType = [
  { label: "Free", value: "Free" },
  { label: "Starter", value: "Starter" },
  { label: "Pro", value: "Pro" },
  { label: "Business ", value: "Business" },
];

export const nftOptions = [
  { label: "OpenSea", value: "opensea" },
  { label: "Deso", value: "deso" },
];

export const themeOptions = [
  {
    label: "Light",
    value: "light-theme",
  },
  {
    label: "Dark",
    value: "dark-theme",
  },
  {
    label: "System",
    value: "system",
  },
];

export const createPersonaTab = {
  generalTab: "general",
  advanceTab: "advance",
  activityTab: "activity",
  faqTab: "faq",
};

export const subscriptionTypeOptions = [
  { label: "Monthly Subscription", value: "monthly" },
  { label: "One Time Subscription", value: "oneTime" },
];

export const streakModeOptions = [
  { label: "Regular", value: "regular" },
  { label: "Elite", value: "elite" },
];

export const LINE_CHAR = 45;
