import * as actionTypes from "../constants";
import { Persona, PersonaActivity, PersonaFaq } from "../types";

interface InitialState {
  myPersonas: Persona[];
  allPersonas: Persona[];
  offset: number;
  isMorePersonas: boolean;
  isMorePersonasFetching: boolean;
  profilePersonas: Persona[];
  subscribedRoomPersonas: Persona[];
  error: any;
  isFetching: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  aiReplyEnable: boolean;
  formState: {
    data: {
      _id: string;
      name: string;
      isEnable: boolean;
      about: string;
      lookingFor: string;
      notLookingFor: string;
      highlights: string;
      lowlights: string;
      generatedBio: string;
      avatar: {
        urls: {
          regular: string;
          small: string;
        };
      };
      avatarFile: any;
      includeMe: boolean;
      notifyMe: boolean;
      recordedVideoLink: string;
      peopleShouldKnow: string;
      tags: any[];
      premiumTag: string;
      faqs: [
        {
          question: string;
          answer: string;
        },
      ];
      companyAffiliation: {
        isActive: boolean;
        imgUrl: string;
        title: string;
        link: string;
      };
      roomSpecific: boolean;
      room: string;
    };
    errorMessage: {
      nameError: string;
      avatarError: string;
      aboutError: string;
      generatedBioError: string;
      tagsError: string;
      faqsError: string;
      premiumTagsError: string;
      companyAffiliationError: string;
    };
  };
  personaActivity: PersonaActivity[];
  personaFaqs: PersonaFaq[];
  isCreatingActivity: boolean;
  isFetchingActivity: boolean;
  isFetchingMyPersonas: boolean;
  isFetchingProfilePersonas: boolean;
  isCreatingFaq: boolean;
  isFetchingFaqs: boolean;
  personaPremiumTags: any[];
  isFetchingPersonaPremiumTags: boolean;
  isFetchingSubscribedRoomPersonas: boolean;
  isFetchingRoomSubscriberPersonas: boolean;
  roomScriberPersonas: Persona[];
  personaVideoInsights: any[];
  isFetchingPersonaVideoInsights: boolean;
  isCreatingPersonaVideoInsight: boolean;
  isPersonaVideoInsightSuccessfullyCreated: boolean;
  alreadyAddedPersonaVideoInsight: any;
  isUpdatingPersonaVideoInsight: boolean;
  isPersonaVideoInsightSuccessfullyUpdated: boolean;
  personaArticles: any;
  isFetchingPersonaArticles: boolean;
  isCreatingPersonaArticle: boolean;
}

const initialState: InitialState = {
  myPersonas: [],
  allPersonas: [],
  offset: 0,
  isMorePersonas: false,
  isMorePersonasFetching: false,
  subscribedRoomPersonas: [],
  profilePersonas: [],
  error: null,
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  isDeleting: false,
  aiReplyEnable: true,
  formState: {
    data: {
      _id: "",
      name: "",
      isEnable: true,
      about: "",
      lookingFor: "",
      notLookingFor: "",
      highlights: "",
      lowlights: "",
      generatedBio: "",
      avatar: {
        urls: {
          regular: "",
          small: "",
        },
      },
      avatarFile: null,
      includeMe: true,
      notifyMe: true,
      recordedVideoLink: "",
      peopleShouldKnow: "",
      tags: [],
      premiumTag: "",
      faqs: [
        {
          question: "",
          answer: "",
        },
      ],
      companyAffiliation: {
        isActive: false,
        imgUrl: "",
        title: "",
        link: "",
      },
      roomSpecific: false,
      room: "",
    },
    errorMessage: {
      nameError: "",
      avatarError: "",
      aboutError: "",
      generatedBioError: "",
      tagsError: "",
      faqsError: "",
      premiumTagsError: "",
      companyAffiliationError: "",
    },
  },
  personaActivity: [],
  personaFaqs: [],
  isCreatingActivity: false,
  isFetchingActivity: false,
  isFetchingMyPersonas: false,
  isFetchingProfilePersonas: false,
  isFetchingSubscribedRoomPersonas: false,
  isCreatingFaq: false,
  isFetchingFaqs: false,
  personaPremiumTags: [],
  isFetchingPersonaPremiumTags: false,
  isFetchingRoomSubscriberPersonas: false,
  roomScriberPersonas: [],
  personaVideoInsights: [],
  isFetchingPersonaVideoInsights: false,
  isCreatingPersonaVideoInsight: false,
  isPersonaVideoInsightSuccessfullyCreated: false,
  alreadyAddedPersonaVideoInsight: null,
  isUpdatingPersonaVideoInsight: false,
  isPersonaVideoInsightSuccessfullyUpdated: true,
  personaArticles: [],
  isFetchingPersonaArticles: false,
  isCreatingPersonaArticle: false,
};

function personaReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_MY_PERSONAS_REQUEST:
      return {
        ...state,
        isFetchingMyPersonas: true,
      };
    case actionTypes.GET_MY_PERSONAS_SUCCESS: {
      return {
        ...state,
        myPersonas: action.payload.personas as Persona[],
        isFetchingMyPersonas: false,
      };
    }
    case actionTypes.GET_MY_PERSONAS_FAILURE:
      return {
        ...state,
        isFetchingMyPersonas: false,
      };
    case actionTypes.GET_ALL_PERSONAS_REQUEST:
      if (action.isMorePersonas) {
        return {
          ...state,
          isMorePersonasFetching: true,
        };
      }
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_ALL_PERSONAS_SUCCESS: {
      const _offset =
        action.payload.personas.length >= 20
          ? action.payload.offset + 20
          : action.payload.offset;
      if (action.payload.isMorePersonas) {
        const personas = [...state.allPersonas, ...action.payload.personas];
        return {
          ...state,
          allPersonas: personas,
          offset: _offset,
          isMorePersonasFetching: false,
          isMorePersonas: action.payload.personas.length >= 20 ? true : false,
        };
      }
      return {
        ...state,
        allPersonas: action.payload.personas as Persona[],
        isFetching: false,
        offset: _offset,
        isMorePersonas: action.payload.personas.length >= 20 ? true : false,
      };
    }
    case actionTypes.GET_ALL_PERSONAS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.CREATE_PERSONA_REQUEST:
      return { ...state, isCreating: true };

    case actionTypes.CREATE_PERSONA_SUCCESS: {
      const persona = {
        ...action.payload,
        owner: {
          _id: action.payload.owner,
        },
      };
      return {
        ...state,
        myPersonas: [persona, ...state.myPersonas],
        isCreating: false,
      };
    }

    case actionTypes.CREATE_PERSONA_FAILURE:
      return { ...state, isCreating: false };

    case actionTypes.UPDATE_PERSONA_REQUEST:
      return { ...state, isUpdating: true };

    case actionTypes.UPDATE_PERSONA_SUCCESS: {
      const updatedPersona = {
        ...action.payload,
        owner: {
          _id: action.payload.owner,
        },
      };

      const { myPersonas, allPersonas } = state;
      const updatedMyPersonas = myPersonas.map((persona) => {
        if (persona._id === action.payload?._id) {
          return updatedPersona;
        }
        return persona;
      });
      const updatedAllPersonas = allPersonas.map((persona) => {
        if (persona._id === action.payload?._id) {
          return updatedPersona;
        }
        return persona;
      });
      return {
        ...state,
        myPersonas: updatedMyPersonas,
        allPersonas: updatedAllPersonas,
        isUpdating: false,
      };
    }

    case actionTypes.UPDATE_PERSONA_FAILURE:
      return { ...state, isUpdating: false };

    case actionTypes.DELETE_PERSONA_REQUEST:
      return { ...state, isDeleting: false };

    case actionTypes.DELETE_PERSONA_SUCCESS: {
      return {
        ...state,
        myPersonas: state.myPersonas.filter(
          (room) => room._id !== action.payload._id,
        ),
        isDeleting: false,
      };
    }

    case actionTypes.DELETE_PERSONA_FAILURE:
      return { ...state, isDeleting: false };

    case actionTypes.TOGGLE_AI_REPLY:
      return { ...state, aiReplyEnable: !state.aiReplyEnable };

    case actionTypes.TOGGLE_AI_REPLY_FALSE:
      return { ...state, aiReplyEnable: false };

    case actionTypes.UPDATE_FORM_DATA_STATE:
      return {
        ...state,
        formState: {
          data: action.payload,
          errorMessage: state.formState.errorMessage,
        },
      };

    case actionTypes.UPDATE_FORM_ERROR_STATE:
      return {
        ...state,
        formState: {
          errorMessage: action.payload,
          data: state.formState.data,
        },
      };

    case actionTypes.CREATE_PERSONA_ACTIVITY_REQUEST:
      return { ...state, isCreatingActivity: true };

    case actionTypes.CREATE_PERSONA_ACTIVITY_SUCCESS: {
      return {
        ...state,
        personaActivity: [
          action.payload.personaActivity,
          ...state.personaActivity,
        ],
        isCreatingActivity: false,
      };
    }

    case actionTypes.CREATE_PERSONA_ACTIVITY_FAILURE:
      return { ...state, isCreatingActivity: false };

    case actionTypes.GET_PERSONA_ACTIVITY_REQUEST:
      return { ...state, isFetchingActivity: true };

    case actionTypes.GET_PERSONA_ACTIVITY_SUCCESS: {
      return {
        ...state,
        personaActivity: [...action.payload.personaActivity],
        isFetchingActivity: false,
      };
    }

    case actionTypes.GET_PERSONA_ACTIVITY_FAILURE:
      return { ...state, isFetchingActivity: false };

    case actionTypes.UPDATE_PERSONA_INTRO: {
      const { allPersonas } = state;
      const updatedPersonas = allPersonas.map((persona) => {
        if (persona._id === action.payload?.persona) {
          return { ...persona, personaIntro: action.payload };
        }
        return persona;
      });
      return {
        ...state,
        allPersonas: updatedPersonas,
      };
    }

    case actionTypes.UPDATE_PERSONA_CLICK_COUNT_SUCCESS: {
      const { allPersonas } = state;
      const { persona: personaId, user } = action.payload || {};

      const updatedPersonas = allPersonas.map((persona) => {
        if (persona._id === personaId) {
          const personaClicks = persona.personaClicks || [];
          const existingClick = personaClicks.find(
            (click) => click.user === user,
          );

          if (existingClick) {
            return {
              ...persona,
              personaClicks: personaClicks.map((click) =>
                click.user === user ? { ...click, count: click.count } : click,
              ),
            };
          } else {
            return {
              ...persona,
              personaClicks: [...personaClicks, action.payload],
            };
          }
        }
        return persona;
      });

      return {
        ...state,
        allPersonas: updatedPersonas,
      };
    }

    case actionTypes.GET_PROFILE_PERSONAS_REQUEST:
      return {
        ...state,
        isFetchingProfilePersonas: true,
      };
    case actionTypes.GET_PROFILE_PERSONAS_SUCCESS: {
      return {
        ...state,
        profilePersonas: action.payload.personas as Persona[],
        isFetchingProfilePersonas: false,
      };
    }
    case actionTypes.GET_PROFILE_PERSONAS_FAILURE:
      return {
        ...state,
        isFetchingProfilePersonas: false,
      };

    case actionTypes.UPDATE_PROFILE_PERSONA_CLICK_COUNT_SUCCESS: {
      const { profilePersonas } = state;
      const { persona: personaId, user } = action.payload || {};

      const updatedPersonas = profilePersonas.map((persona) => {
        if (persona._id === personaId) {
          const personaClicks = persona.personaClicks || [];
          const existingClick = personaClicks.find(
            (click) => click.user === user,
          );

          if (existingClick) {
            return {
              ...persona,
              personaClicks: personaClicks.map((click) =>
                click.user === user ? { ...click, count: click.count } : click,
              ),
            };
          } else {
            return {
              ...persona,
              personaClicks: [...personaClicks, action.payload],
            };
          }
        }
        return persona;
      });

      return {
        ...state,
        profilePersonas: updatedPersonas,
      };
    }
    case actionTypes.GET_PERSONA_FAQS_REQUEST:
      return { ...state, isFetchingFaqs: true };

    case actionTypes.GET_PERSONA_FAQS_SUCCESS: {
      return {
        ...state,
        personaFaqs: [...action.payload.personaFaqs],
        isFetchingFaqs: false,
      };
    }

    case actionTypes.GET_PERSONA_FAQS_FAILURE:
      return { ...state, isFetchingFaqs: false };

    case actionTypes.CREATE_PERSONA_FAQ_REQUEST:
      return { ...state, isCreatingFaq: true };

    case actionTypes.CREATE_PERSONA_FAQ_SUCCESS: {
      return {
        ...state,
        personaFaqs: [...action.payload.personaFaqs, ...state.personaFaqs],
        isCreatingFaq: false,
      };
    }

    case actionTypes.CREATE_PERSONA_FAQ_FAILURE:
      return { ...state, isCreatingFaq: false };

    case actionTypes.GET_PERSONA_PREMIUM_TAGS_REQUEST:
      return { ...state, isFetchingPersonaPremiumTags: true };

    case actionTypes.GET_PERSONA_PREMIUM_TAGS_SUCCESS: {
      const premiumTagOptions = action.payload.personaPremiumTags.map(
        (option: any) => {
          return {
            label: option.tag,
            value: option.tag,
          };
        },
      );
      return {
        ...state,
        personaPremiumTags: [...premiumTagOptions],
        isFetchingPersonaPremiumTags: false,
      };
    }

    case actionTypes.GET_PERSONA_PREMIUM_TAGS_FAILURE:
      return { ...state, isFetchingPersonaPremiumTags: false };
    case actionTypes.GET_ROOM_SUBSCRIBED_PERSONAS_REQUEST:
      return {
        ...state,
        isFetchingSubscribedRoomPersonas: true,
      };
    case actionTypes.GET_ROOM_SUBSCRIBED_PERSONAS_SUCCESS: {
      return {
        ...state,
        subscribedRoomPersonas: action.payload.personas as Persona[],
        isFetchingSubscribedRoomPersonas: false,
      };
    }
    case actionTypes.GET_ROOM_SUBSCRIBED_PERSONAS_FAILURE:
      return {
        ...state,
        isFetchingSubscribedRoomPersonas: false,
      };
    case actionTypes.GET_ROOM_SUBSCRIBER_PERSONAS_REQUEST:
      return {
        ...state,
        isFetchingRoomSubscriberPersonas: true,
      };
    case actionTypes.GET_ROOM_SUBSCRIBER_PERSONAS_SUCCESS: {
      return {
        ...state,
        roomScriberPersonas: action.payload.personas as Persona[],
        isFetchingRoomSubscriberPersonas: false,
      };
    }
    case actionTypes.GET_ROOM_SUBSCRIBER_PERSONAS_FAILURE:
      return {
        ...state,
        isFetchingRoomSubscriberPersonas: false,
      };
    case actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_REQUEST:
      return {
        ...state,
        isFetchingPersonaVideoInsights: true,
      };
    case actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_SUCCESS: {
      return {
        ...state,
        personaVideoInsights: action.payload.personaVideoInsights,
        isFetchingPersonaVideoInsights: false,
      };
    }
    case actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_FAILURE:
      return {
        ...state,
        isFetchingPersonaVideoInsights: false,
      };
    case actionTypes.CREATE_MY_PERSONA_VIDEO_INSIGHT_REQUEST:
      return {
        ...state,
        isCreatingPersonaVideoInsight: true,
      };
    case actionTypes.CREATE_MY_PERSONA_VIDEO_INSIGHT_SUCCESS: {
      return {
        ...state,
        personaVideoInsights: [
          action.payload.personaVideoInsight,
          ...state.personaVideoInsights,
        ],
        isCreatingPersonaVideoInsight: false,
        isPersonaVideoInsightSuccessfullyCreated: true,
      };
    }
    case actionTypes.CREATE_MY_PERSONA_VIDEO_INSIGHT_FAILURE:
      return {
        ...state,
        isCreatingPersonaVideoInsight: false,
      };
    case actionTypes.SET_PERSONA_VIDEO_INSIGHT_SUCCESSFULLY_CREATED_FALSE:
      return {
        ...state,
        isPersonaVideoInsightSuccessfullyCreated: false,
      };
    case actionTypes.ALREADY_ADDED_PERSONA_VIDEO_INSIGHT:
      return {
        ...state,
        isCreatingPersonaVideoInsight: false,
        alreadyAddedPersonaVideoInsight: action.payload.personaVideoInsight,
        isPersonaVideoInsightSuccessfullyCreated: true,
      };

    case actionTypes.UPDATE_PERSONA_VIDEO_INSIGHT_REQUEST:
      return {
        ...state,
        isUpdatingPersonaVideoInsight: true,
      };
    case actionTypes.UPDATE_PERSONA_VIDEO_INSIGHT_SUCCESS: {
      const { personaVideoInsights } = state;
      const index = personaVideoInsights.findIndex(
        (insight) => insight._id === action.payload.personaVideoInsight._id,
      );
      personaVideoInsights[index] = {
        ...action.payload.personaVideoInsight,
      };
      return {
        ...state,
        personaVideoInsights: personaVideoInsights,
        isUpdatingPersonaVideoInsight: false,
        isPersonaVideoInsightSuccessfullyUpdated: true,
      };
    }
    case actionTypes.UPDATE_PERSONA_VIDEO_INSIGHT_FAILURE:
      return {
        ...state,
        isUpdatingPersonaVideoInsight: false,
      };
    case actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_REQUEST:
      return {
        ...state,
        isFetchingPersonaVideoInsights: true,
      };
    case actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_SUCCESS: {
      return {
        ...state,
        personaVideoInsights: action.payload.personaVideoInsights,
        isFetchingPersonaVideoInsights: false,
      };
    }
    case actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_FAILURE:
      return {
        ...state,
        isFetchingPersonaVideoInsights: false,
      };
    case actionTypes.GET_MY_PERSONA_ARTICLES_REQUEST:
      return {
        ...state,
        isFetchingPersonaArticles: true,
      };
    case actionTypes.GET_MY_PERSONA_ARTICLES_SUCCESS: {
      return {
        ...state,
        personaArticles: action.payload.personaArticles,
        isFetchingPersonaArticles: false,
      };
    }
    case actionTypes.GET_MY_PERSONA_ARTICLES_FAILURE:
      return {
        ...state,
        isFetchingPersonaArticles: false,
      };
    case actionTypes.CREATE_MY_PERSONA_ARTICLE_REQUEST:
      return {
        ...state,
        isCreatingPersonaArticle: true,
      };
    case actionTypes.CREATE_MY_PERSONA_ARTICLE_SUCCESS: {
      const updatedArticles = state.personaArticles.map((article: any) => {
        if (article._id === action.payload.personaArticle._id) {
          return action.payload.personaArticle;
        }
        return article;
      });

      const isNewArticleExists = updatedArticles.some(
        (article: any) => article._id === action.payload.personaArticle._id,
      );

      if (!isNewArticleExists) {
        updatedArticles.unshift(action.payload.personaArticle);
      }
      return {
        ...state,
        personaArticles: updatedArticles,
        isCreatingPersonaArticle: false,
      };
    }
    case actionTypes.CREATE_MY_PERSONA_ARTICLE_FAILURE:
      return {
        ...state,
        isCreatingPersonaArticle: false,
      };
    default:
      return state;
  }
}

export default personaReducer;
