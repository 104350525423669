import React, { useContext, useState } from "react";
import LoadingSkelaton from "../../VibehutUI/LoadingSkelaton";
import axios from "../../utils/axios";
import Masonry from "react-responsive-masonry";
import Loader from "../../VibehutUI/Loader";
import { getRandomPicture } from "../../services/rooomService";
import { StoreContext } from "../../Context/StoreContext";
import useDebounce from "../../hooks/useDebounce";
import Button from "../../VibehutUI/Button";
import { generateImageUsingDalle } from "../../services/personaService";
import DoubleStar from "../../VibehutUI/Icons/DoubleStar";
function BannerPicker({
  setCover,
  setCoverLoading,
  setCoverOpen,
  setCoverFile,
  showUploadBtn = true,
  generateWithDalle = false
}) {
  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("")
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const { state } = useContext(StoreContext);
  const [isGenerating, setIsGenerating] = useState(false)
  const debouncedSearchTerm = useDebounce(name, 500);
  const {
    auth: {
      user: { token },
    },
  } = state;

  const getRandomCover = () => {
    setCoverOpen(false);
    setCoverLoading(true);
    setCoverFile(null);
    getRandomPicture(token)
      .then((res) => {
        setCover(res.data);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      })
      .finally(() => {
        setCoverLoading(false);
      });
  };
  
  React.useEffect(() => {
    axios.get(`/rooms/get-popular-picture`).then((res) => {
      if (res.status === 200) {
        setResults(res.data.data);
       
      }
    })
    .catch((error) => {
        console.log("ERROR: ", error);
    })
    .finally(() => {
       setIsLoading(false);
    });
  }, []);

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoading(true);

      handleSearch();
    } else {
      setResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleSearch = () => {
    axios
      .get(`/rooms/search-picture?keyword=${name}&page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          setResults(res.data.data.results);
        } 
      }) 
      .catch((error) => {
          console.log("ERROR: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

const createImageUsingDalle = async () => {
  setIsGenerating(true);

  try {
    const res = await generateImageUsingDalle(prompt, token);

    const _results = res.data.map((image) => ({
      alt_description: '',
      urls: {
        regular: image.url,
        small: image.url,
      },
    }));

    setResults(_results);
  } catch (error) {
    console.error("ERROR: ", error);
  } finally {
    setIsGenerating(false);
  }
};

  const fetchImages = async () => {
    setHasMore(true);
    setPage(page + 1);
    await axios
      .post(`/rooms/search-picture?keyword=${name}&page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          setResults(results.concat(res.data.data.results));
          setHasMore(false);
        }
      }).catch((error) => {
         console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });;
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setCoverFile(file);
    setCoverOpen(false);
  };

  return (
    <div>
      <div className="bg-white dark:bg-navyGray p-4 relative">
        <div>
          <input
            className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full focus:ring-1 ring-primary"
            type="text"
            placeholder="Search..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
         
          
        </div>
       {
        generateWithDalle && 
          <div className="flex items-center gap-2">
            <input
              className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full focus:ring-1 ring-primary"
              type="text"
              placeholder="Generate Avatar using Dalle AI"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <button
                  className="px-1 py-0.5 bg-gradient-btn-border text-white rounded-3xl "
                  onClick={createImageUsingDalle}
                  disabled={isGenerating}
              >
                  <div className="bg-gradient-btn flex align-center items-center  rounded-3xl justify-center px-1.5 sm:px-4 py-1.5">
                      <>
                          <DoubleStar />
                          <p className="text-sm sm:text-base ml-0.5">
                             {isGenerating ? 'Generating' : 'Generate'}
                          </p>
                      </>

                  </div>
            </button>
          </div>
       }  
        <>
          {isLoading || isGenerating ? (
            <LoadingSkelaton />
          ) : (
            <div className="h-96 overflow-auto custom-scrollbar mt-4">
              {results.length > 0 && (
                <>
                  <Masonry columnsCount={2} gutter="10px">
                    {results.map((result, i) => (
                      <div className="p-2" key={i}>
                        <img
                          key={i}
                          alt={result.alt_description}
                          src={result.urls && result.urls.regular}
                          className="w-full block rounded-xl cursor-pointer hover:scale-105 transform transition-all"
                          onClick={() => {
                            setCoverOpen(false);
                            setCoverFile(null);
                            setCover({ urls: result.urls });
                          }}
                          loading="lazy"
                        />
                        <p className="mt-1 text-navyGray dark:text-frescoWhite">
                          {result.alt_description}
                        </p>
                      </div>
                    ))}
                  </Masonry>
               {
               !generateWithDalle && <>
                    {hasMore ? (
                          <Loader />
                        ) : (
                        <div className="flex justify-center">
                          <Button
                            onClick={fetchImages}
                            size="vibhut-sm"
                            variant="vibhut-primary"
                          >
                            Load More
                          </Button>
                        </div>
                    )}
                  </>
               }   
                </>
              )}
            </div>
          )}
        </>
      </div>
      <div className=" px-4 py-3 flex">
        <button
          type="button"
          className=" text-sm bg-frescoWhite w-full py-2 px-3 font-bold text-navyGray rounded-2xl"
          onClick={getRandomCover}
        >
          Randomize Cover
        </button>
      </div>
      {showUploadBtn && (
        <div className=" px-4 pb-4 flex">
          <input
            id="files"
            type="file"
            onChange={handleChange}
            accept="image/png, image/gif, image/jpeg, image/webp"
            className="hidden w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-primary hover:file:bg-violet-100 cursor-pointer"
          />
          <label
            htmlFor="files"
            className=" text-sm bg-frescoWhite w-full py-2 px-3 font-bold text-navyGray rounded-2xl text-center cursor-pointer"
          >
            Upload Cover (Pro beta)
          </label>
        </div>
      )}{" "}
      <div className=" px-4 py-3 flex">
        <button
          type="button"
          className=" text-sm bg-frescoWhite w-full py-2 px-3 font-bold text-navyGray rounded-2xl"
          onClick={() => setCoverOpen(false)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default BannerPicker;
