import * as actionTypes from "../constants";

const initialState = {
  isFetching: false,
  premiumAccess: [],
};

function premiumCreatorReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PREMIUM_CREATOR_ACCESS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.GET_PREMIUM_CREATOR_ACCESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        premiumAccess: action.payload,
      };
    case actionTypes.GET_PREMIUM_CREATOR_ACCESS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}

export default premiumCreatorReducer;
