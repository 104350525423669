import { useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { getSubscriberApprovalSubmission } from "../../services/rooomService";
import { StoreContext } from "../../Context/StoreContext";

interface Props {
  userId: string;
  roomId: string;
  approvalQuestions: string[];
}

function SubscriberApprovalSubmissionsModal({
  userId,
  roomId,
  approvalQuestions,
}: Props) {
  const [answers, setAnswers] = useState(["", "", ""]);
  const [isLoadingApproval, setIsLoadingApproval] = useState(true);
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;
  useEffect(() => {
    const getSubmission = async () => {
      try {
        const response = await getSubscriberApprovalSubmission(
          user?.token,
          roomId,
          userId,
        );
        if (response.data?.answers) {
          setAnswers(response.data?.answers);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingApproval(false);
      }
    };
    if (userId && roomId) {
      getSubmission();
    }
  }, [userId, roomId]);

  if (isLoadingApproval) {
    return <Loader />;
  }

  return (
    <div className="px-2 flex flex-col gap-2">
      {approvalQuestions.map((question: any, index: number) => (
        <div key={question + index}>
          <p className="font-medium text-navyGray dark:text-frescoWhite">
            {question}
          </p>
          <p className="text-sm font-medium text-darkGray">{answers[index]}</p>
        </div>
      ))}
    </div>
  );
}

export default SubscriberApprovalSubmissionsModal;
