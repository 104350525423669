import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from '../../VibehutUI/Button';
import Close from '../../VibehutUI/Icons/Close';
import AudioInputList from '../DeviceSelectionDialog/AudioInputList/AudioInputList';
import AudioOutputList from '../DeviceSelectionDialog/AudioOutputList/AudioOutputList';
import VideoInputList from '../DeviceSelectionDialog/VideoInputList/VideoInputList';

interface VideoSettingProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

export default function VideoSettings({ isOpen, setIsOpen }: VideoSettingProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={setIsOpen}>
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[428px] font-ubuntu">
                <div className="bg-white p-4 border-b border-frescoWhite">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black">Video Settings</p>
                    <span className="bg-frescoWhite p-9px rounded-full cursor-pointer" onClick={setIsOpen}>
                      <Close />
                    </span>
                  </div>
                </div>

                <div>
                  <div className="px-4 pt-2">
                    <div className="space-y-3">
                      <VideoInputList />
                      <AudioInputList />
                      <AudioOutputList />
                    </div>
                  </div>
                  <div className="pr-4 py-3 flex flex-row-reverse mt-3 ">
                    <Button size="vibhut-sm" variant="vibhut-primary" onClick={setIsOpen}>
                      Done
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
