import * as actionTypes from "../constants";

const initialState = {
  showCommentModal: false,
  parentPost: null,
};

function commentModalReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_ADD_COMMENT_MODAL:
      return {
        ...state,
        showCommentModal: action.payload.showCommentModal,
        parentPost: action.payload.post,
      };
    default:
      return state;
  }
}

export default commentModalReducer;
