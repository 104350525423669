import React, { useEffect, useRef, useState, useContext } from "react";
import {
  getPopularRooms,
  searchRooms,
  getTrendingRooms,
  createRoomSearch,
} from "../actions/roomAction";
import { Link } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";
import Loader from "../VibehutUI/Loader";
import useDebounce from "../hooks/useDebounce";
import CancelButton from "../VibehutUI/CancelButton";
import SearchResultCard from "../VibehutUI/Card/SearchResultCard";
import Chip from "../VibehutUI/Chip";
import Button from "../VibehutUI/Button";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { useWindowSize } from "../hooks/useWindowSize";
import RoomChipSkeleton from "../VibehutUI/Skeletons/RoomChipSkeleton";
import { singleClaimedRoomRoutes, singleRoomRoutes } from "../routes";

const MainSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [resultBoxOpen, setResultBoxOpen] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const { state, dispatch } = useContext(StoreContext);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const inputRef = useRef(null);
  const dropDownRef = useRef(null);

  const {
    auth: { user },
    room: {
      popularRooms,
      isFetchingPopRoom,
      trendingRooms,
      isFetchingTrendingRooms,
      searchedRooms,
      isSearching,
      isSearchMore,
      isMoreSearching,
    },
  } = state;
  const { width } = useWindowSize();
  const getSearchHistory = () => {
    const prevValue = window.localStorage.getItem("searchTerm");
    if (prevValue?.length > 0) {
      setSearchHistory(prevValue.split(","));
    }
  };
  const setLocalSearchHistory = () => {
    try {
      const prevValue = window.localStorage.getItem("searchTerm") || "";
      let arr = prevValue?.length > 0 ? prevValue?.split(",") : [];
      if (arr.includes(searchTerm) && arr.length > 0) {
        arr = arr.filter((word) => word !== searchTerm);
        arr.unshift(searchTerm);
      } else {
        arr.unshift(searchTerm);
      }
      window.localStorage.setItem("searchTerm", arr.join(","));
      getSearchHistory();
    } catch (error) {}
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchCount = (roomId) => {
    createRoomSearch(user?.token, roomId);
  };

  useEffect(() => {
    if (debouncedSearchTerm?.trim()) {
      searchRooms(dispatch, user?.token, debouncedSearchTerm?.trim(), false, 0);
      setLocalSearchHistory();
    }
  }, [debouncedSearchTerm]);

  const handleLoadMore = () => {
    searchRooms(
      dispatch,
      user?.token,
      debouncedSearchTerm?.trim(),
      isSearchMore,
      searchedRooms.length,
    );
    setLocalSearchHistory();
  };

  useEffect(() => {
    if (!popularRooms.length > 0) {
      getPopularRooms(user?.token, dispatch);
    }
    if (!trendingRooms.length > 0) {
      getTrendingRooms(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnClickOutside(
    () => {
      setResultBoxOpen(false);
    },
    inputRef,
    dropDownRef,
  );

  return (
    <div className={`relative w-full md:py-0 py-5`}>
      <input
        className="searchbar select-none outline-none placeholder-darkGray search-input bg-white"
        type="text"
        ref={inputRef}
        value={searchTerm}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setLocalSearchHistory();
          }
        }}
        onClick={() => {
          setResultBoxOpen(true);
          getSearchHistory();
        }}
        onChange={handleSearch}
        placeholder="Search..."
      />
      {resultBoxOpen && (
        <div
          ref={dropDownRef}
          className="absolute rounded-2xl dark:bg-navyGray bg-white z-30 mt-2 max-h-[80vh] overflow-auto custom-scrollbar border-lightGray dark:border-none border"
          style={{
            width: `${inputRef.current.offsetWidth}px`,
          }}
        >
          <div className="mx-4 py-2">
            <>
              {!searchTerm?.trim() && (
                <div>
                  <div className="flex space-x-2 my-1 items-center dark:text-frescoWhite justify-between">
                    {searchHistory.length > 0 && (
                      <>
                        <p className="dark:text-frescoWhite">Recent searches</p>
                        <span
                          onClick={() => {
                            window.localStorage.removeItem("searchTerm");
                            setSearchHistory([]);
                            getSearchHistory();
                          }}
                          className="cursor-pointer pt-1"
                        >
                          <CancelButton ratio="15" />
                        </span>
                      </>
                    )}
                  </div>

                  <div className="flex flex-direction-column flex-wrap ">
                    {searchHistory.map((result) => {
                      if (!result) return null;
                      return (
                        <Chip
                          content={result}
                          onClick={() => {
                            setSearchTerm(result);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {searchTerm?.trim() && (
                <div>
                  <div className="pb-1 dark:text-frescoWhite">
                    Showing Result for "{searchTerm}"
                  </div>
                  {isSearching ? (
                    <Loader />
                  ) : searchedRooms.length <= 0 ? (
                    <div className=" w-full text-center text-sm text-darkGray">
                      Room not found
                    </div>
                  ) : (
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 grid-cols-1 gap-4 pt-2 pb-5">
                      {searchedRooms.map((room) => {
                        const path = room?.slug
                          ? singleClaimedRoomRoutes.path.replace(
                              ":slug",
                              room.slug,
                            )
                          : singleRoomRoutes.path.replace(":roomId", room._id);
                        return (
                          <Link
                            key={room._id}
                            to={path}
                            onClick={() => handleSearchCount(room._id)}
                          >
                            <div
                              className="cursor-pointer max-w-md"
                              key={room._id}
                            >
                              <SearchResultCard
                                image={room?.cover?.urls?.regular}
                                text={room?.name}
                                orientation="landscape"
                              />
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                  {isSearching ? undefined : !isMoreSearching ? (
                    isSearchMore && (
                      <div className="flex justify-center pb-2">
                        <Button
                          size="vibhut-sm"
                          variant="vibhut-primary"
                          onClick={handleLoadMore}
                        >
                          Load More
                        </Button>
                      </div>
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              )}
              {!searchTerm && (
                <>
                  <div>
                    {!isFetchingTrendingRooms ? (
                      trendingRooms.length > 0 && (
                        <>
                          <p className="text-darkNight dark:text-frescoWhite pt-2 ">
                            Trending
                          </p>
                          <div className="grid md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 grid-cols-1 gap-4 pt-2 pb-3">
                            {trendingRooms
                              .slice(0, width > 1600 ? 8 : 6)
                              .map((room) => {
                                return (
                                  <Link key={room._id} to={`/${room._id}`}>
                                    <div
                                      className="cursor-pointer max-w-md"
                                      key={room._id}
                                    >
                                      <SearchResultCard
                                        image={room?.cover?.urls?.regular}
                                        text={room?.name}
                                        orientation="landscape"
                                      />
                                    </div>
                                  </Link>
                                );
                              })}
                          </div>
                        </>
                      )
                    ) : (
                      <RoomChipSkeleton totalCard={width > 1600 ? 4 : 3} />
                    )}
                  </div>
                  <div>
                    {!isFetchingPopRoom ? (
                      popularRooms.length > 0 && (
                        <>
                          <p className="text-darkNight dark:text-frescoWhite">
                            Popular
                          </p>
                          <div className="grid md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 grid-cols-1 gap-4 pt-2 pb-5">
                            {popularRooms
                              .slice(0, width > 1600 ? 8 : 6)
                              .map((room) => {
                                return (
                                  <Link key={room._id} to={`/${room._id}`}>
                                    <div
                                      className="cursor-pointer max-w-md"
                                      key={room._id}
                                    >
                                      <SearchResultCard
                                        image={room?.cover?.urls?.regular}
                                        text={room?.name}
                                        orientation="landscape"
                                      />
                                    </div>
                                  </Link>
                                );
                              })}
                          </div>
                        </>
                      )
                    ) : (
                      <RoomChipSkeleton totalCard={width > 1600 ? 4 : 3} />
                    )}
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainSearch;
