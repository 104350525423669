import React from 'react';

function CamOff() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M7.10675 3.33334H9.33341C9.68704 3.33334 10.0262 3.47382 10.2762 3.72387C10.5263 3.97392 10.6667 4.31305 10.6667 4.66668V6.89334L11.3334 7.56001L15.3334 4.66668V11.3333M10.6667 10.6667V11.3333C10.6667 11.687 10.5263 12.0261 10.2762 12.2762C10.0262 12.5262 9.68704 12.6667 9.33341 12.6667H2.00008C1.64646 12.6667 1.30732 12.5262 1.05727 12.2762C0.807224 12.0261 0.666748 11.687 0.666748 11.3333V4.66668C0.666748 4.31305 0.807224 3.97392 1.05727 3.72387C1.30732 3.47382 1.64646 3.33334 2.00008 3.33334H3.33341L10.6667 10.6667Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1 1L15 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CamOff;
