import * as actionTypes from "../constants";

const initialState = {
  posts: [],
  premiumActivityPosts: [],
  premiumOffset: 0,
  isPremiumFetching: false,
  isPremiumMoreFetching: false,
  isFetching: false,
  error: null,
  offset: 0,
  isMore: false,
  isMoreFetching: false,
  homePosts: [],
  isActivityFetching: false,
  roomOffset: 0,
  isMoreRoomPosts: false,
  roomPosts: [],
  userPosts: [],
  isMoreUserPosts: false,
  userOffset: 0,
  personaPosts: [],
  isMorePersonaPosts: false,
  personaOffset: 0,
  eventPosts: [],
  isMoreEventPosts: false,
  eventOffset: 0,
};

function postReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return { ...state, isFetching: true };

    case actionTypes.GET_POSTS_SUCCESS: {
      const ofst =
        action.payload.posts.length >= 30
          ? action.payload.offset + 30
          : action.payload.offset;

      if (action.payload.loadMore) {
        return {
          ...state,
          posts: [...state.posts, ...action.payload.posts],
          isMoreFetching: false,
          offset: ofst,
          isMore: action.payload.posts.length >= 30 ? true : false,
        };
      } else {
        return {
          ...state,
          posts: action.payload.posts,
          isFetching: false,
          offset: ofst,
          isMore: action.payload.posts.length >= 30 ? true : false,
        };
      }
    }

    case actionTypes.GET_POSTS_FAILURE:
      return { ...state, isFetching: false, isMoreFetching: false };

    case actionTypes.GET_HOME_POSTS_REQUEST:
      return {
        ...state,
        isActivityFetching: true,
      };

    case actionTypes.GET_HOME_POSTS_SUCCESS:
      const ofstHome =
        action.payload.posts.length >= 10
          ? action.payload.offset + 10
          : action.payload.offset;
      if (action.payload.loadMore) {
        return {
          ...state,
          homePosts: [...state.homePosts, ...action.payload.posts],
          isMoreFetching: false,
          offset: ofstHome,
          isMore: action.payload.posts.length >= 10 ? true : false,
        };
      } else {
        return {
          ...state,
          homePosts: action.payload.posts,
          isActivityFetching: false,
          offset: ofstHome,
          isMore: action.payload.posts.length >= 10 ? true : false,
        };
      }
    case actionTypes.GET_ROOM_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return {
        ...state,
        isActivityFetching: true,
      };

    case actionTypes.GET_ROOM_POSTS_SUCCESS:
      let ofst;
      if (action.payload.offset) {
        ofst =
          action.payload.posts.length >= 5
            ? action.payload.offset + 5
            : action.payload.offset;
      } else {
        ofst = action.payload.posts.length >= 5 ? 5 : 0;
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          roomPosts: [...state.roomPosts, ...action.payload.posts],
          isMoreFetching: false,
          roomOffset: ofst,
          isMoreRoomPosts: action.payload.posts.length >= 5 ? true : false,
        };
      } else {
        return {
          ...state,
          roomPosts: action.payload.posts,
          isActivityFetching: false,
          roomOffset: ofst,
          isMoreRoomPosts: action.payload.posts.length >= 5 ? true : false,
        };
      }

    case actionTypes.GET_USER_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return {
        ...state,
        isActivityFetching: true,
      };

    case actionTypes.GET_USER_POSTS_SUCCESS:
      let ofstUserPost;
      if (action.payload.offset) {
        ofstUserPost =
          action.payload.posts.length >= 5
            ? action.payload.offset + 5
            : action.payload.offset;
      } else {
        ofstUserPost = action.payload.posts.length >= 5 ? 5 : 0;
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          userPosts: [...state.userPosts, ...action.payload.posts],
          isMoreFetching: false,
          userOffset: ofstUserPost,
          isMoreUserPosts: action.payload.posts.length >= 5 ? true : false,
        };
      } else {
        return {
          ...state,
          userPosts: action.payload.posts,
          isActivityFetching: false,
          userOffset: ofstUserPost,
          isMoreUserPosts: action.payload.posts.length >= 5 ? true : false,
        };
      }

    case actionTypes.UPDATE_HOME_POSTS_SUCCESS:
      return {
        ...state,
        homePosts: [action.payload, ...state.homePosts],
        isActivityFetching: false,
      };

    case actionTypes.GET_PERSONA_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return {
        ...state,
        isActivityFetching: true,
      };

    case actionTypes.GET_PERSONA_POSTS_SUCCESS:
      let ofstPersonaPost;
      if (action.payload.offset) {
        ofstPersonaPost =
          action.payload.posts.length >= 5
            ? action.payload.offset + 5
            : action.payload.offset;
      } else {
        ofstPersonaPost = action.payload.posts.length >= 5 ? 5 : 0;
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          personaPosts: [...state.personaPosts, ...action.payload.posts],
          isMoreFetching: false,
          personaOffset: ofstPersonaPost,
          isMorePersonaPosts: action.payload.posts.length >= 5 ? true : false,
        };
      } else {
        return {
          ...state,
          personaPosts: action.payload.posts,
          isActivityFetching: false,
          personaOffset: ofstPersonaPost,
          isMorePersonaPosts: action.payload.posts.length >= 5 ? true : false,
        };
      }

    case actionTypes.UPDATE_PERSONA_POSTS_SUCCESS:
      return {
        ...state,
        personaPosts: [action.payload, ...state.personaPosts],
        isActivityFetching: false,
      };

    case actionTypes.UPDATE_HOME_POSTS_SUCCESS:
      return {
        ...state,
        homePosts: [action.payload, ...state.homePosts],
        isActivityFetching: false,
      };
    case actionTypes.UPDATE_ROOM_POSTS_SUCCESS:
      const post = state.roomPosts.find((p) => p._id === action.payload._id);
      if (post) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        roomPosts: [action.payload, ...state.roomPosts],
        isActivityFetching: false,
      };
    case actionTypes.UPDATE_USER_POSTS_SUCCESS:
      return {
        ...state,
        userPosts: [action.payload, ...state.userPosts],
        isActivityFetching: false,
      };
    case actionTypes.GET_USER_PREMIUM_ACTIVITY_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isPremiumMoreFetching: true,
        };
      }
      return {
        ...state,
        isPremiumFetching: true,
      };

    case actionTypes.GET_USER_PREMIUM_ACTIVITY_SUCCESS: {
      let ofstUserPost;
      if (action.payload.offset) {
        ofstUserPost =
          action.payload.posts.length >= 5
            ? action.payload.offset + 5
            : action.payload.offset;
      } else {
        ofstUserPost = action.payload.posts.length >= 5 ? 5 : 0;
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          premiumActivityPosts: [
            ...state.premiumActivityPosts,
            ...action.payload.posts,
          ],
          isPremiumFetching: false,
          premiumOffset: ofstUserPost,
          isPremiumMoreFetching: false,
          isMore: action.payload.posts.length >= 5 ? true : false,
        };
      } else {
        return {
          ...state,
          premiumActivityPosts: action.payload.posts,
          isPremiumFetching: false,
          premiumOffset: ofstUserPost,
          isMore: action.payload.posts.length >= 5 ? true : false,
        };
      }
    }
    case actionTypes.GET_USER_PREMIUM_ACTIVITY_FAILED:
      return {
        ...state,
        isPremiumFetching: false,
        isPremiumMoreFetching: false,
      };

    case actionTypes.GET_EVENT_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return {
        ...state,
        isActivityFetching: true,
      };

    case actionTypes.GET_EVENT_POSTS_SUCCESS:
      let ofstEventPost;
      if (action.payload.offset) {
        ofstEventPost =
          action.payload.posts.length >= 5
            ? action.payload.offset + 5
            : action.payload.offset;
      } else {
        ofstEventPost = action.payload.posts.length >= 5 ? 5 : 0;
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          eventPosts: [...state.eventPosts, ...action.payload.posts],
          isMoreFetching: false,
          eventOffset: ofstEventPost,
          isMoreEventPosts: action.payload.posts.length >= 5 ? true : false,
        };
      } else {
        return {
          ...state,
          eventPosts: action.payload.posts,
          isActivityFetching: false,
          eventOffset: ofstEventPost,
          isMoreEventPosts: action.payload.posts.length >= 5 ? true : false,
        };
      }

    default:
      return state;
  }
}

export default postReducer;
