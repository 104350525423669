import React from "react";
import Close from "../Icons/Close";
import { renderMessageText } from "../../utils/utils";

function AttachmentContainer({
  attachmentFile,
  setAttachmentFile,
  gif,
  attachedMessage,
  setAttachedMessage,
  setGif,
}) {
  const renderContentAsPerType = () => {
    if (attachmentFile) {
      return (
        <img
          alt="room"
          src={URL.createObjectURL(attachmentFile)}
          className="rounded-xl object-contain h-60"
          loading="lazy"
        />
      );
    }
    if (gif) {
      return (
        <img
          alt="room"
          src={gif}
          className="rounded-xl object-contain h-60"
          loading="lazy"
        />
      );
    }
    if (attachedMessage?.attachments?.length > 0) {
      return (
        <div className="w-full h-full flex flex-col">
          <span className="text-xs font-bold cursor-pointer text-black dark:text-frescoWhite">
            {attachedMessage?.username}
          </span>
          <img
            alt="media"
            src={attachedMessage?.attachments[0].mediaUrl}
            className="rounded-xl object-contain h-60"
            loading="lazy"
          />
        </div>
      );
    }
    if (attachedMessage?.message) {
      return (
        <div
          className=" bg-frescoWhite dark:bg-navyGray p-[6px] border-l-[2px] break-words border-l-matteGray dark:border-l-white"
          style={{ borderRadius: "4px" }}
        >
          <div className="w-full h-full flex flex-col">
            <span className="text-xs font-bold cursor-pointer text-black dark:text-frescoWhite">
              {attachedMessage?.username}
            </span>
            <span className=" text-black dark:text-frescoWhite text-sm">
              {renderMessageText(attachedMessage, undefined)}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };
  const handleRemoveAttachment = () => {
    setGif?.(null);
    setAttachedMessage?.(null);
    setAttachmentFile(null);
  };
  return (
    <div className="pt-4 pb-2 px-1 z-50">
      <span
        onClick={handleRemoveAttachment}
        className="cursor-pointer block pb-1"
      >
        <Close />
      </span>
      {renderContentAsPerType()}
    </div>
  );
}

export default AttachmentContainer;
