import React from "react";
import Button from "./Button";

function LoaderButton() {
  return (
    <Button>
      <svg
        className="mx-1 animate-spin"
        width="16"
        height="16"
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 15.2C8 15.6418 7.64092 16.0042 7.2013 15.9601C5.38122 15.7775 3.66938 14.975 2.36055 13.6742C0.861694 12.1845 0.0131326 10.1623 0.00015095 8.04914C-0.0128307 5.93597 0.810822 3.90349 2.29126 2.39551C3.7717 0.887535 5.78865 0.0265666 7.90171 0.000603799C10.0148 -0.025359 12.0523 0.785792 13.5693 2.25694C15.0864 3.72809 15.9597 5.73971 15.9986 7.85257C16.0376 9.96543 15.239 12.0079 13.7772 13.5339C12.5007 14.8665 10.8091 15.7108 8.99404 15.938C8.55563 15.9929 8.18776 15.6395 8.1769 15.1978C8.16605 14.7561 8.51683 14.3944 8.95373 14.3286C10.3456 14.1188 11.6378 13.4543 12.6217 12.4271C13.7912 11.2063 14.4301 9.57235 14.3989 7.88206C14.3678 6.19177 13.6691 4.58247 12.4555 3.40555C11.2418 2.22863 9.61182 1.57971 7.92137 1.60048C6.23092 1.62125 4.61736 2.31003 3.43301 3.51641C2.24866 4.72279 1.58974 6.34877 1.60012 8.03932C1.61051 9.72986 2.28936 11.3476 3.48844 12.5394C4.49731 13.5421 5.80549 14.1746 7.20205 14.3501C7.64043 14.4052 8 14.7582 8 15.2Z"
          fill="url(#loadergradient)"
        />
      </svg>
    </Button>
  );
}

export default LoaderButton;
