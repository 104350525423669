import React from "react";

function Room() {
  return (
    <svg
      width="28"
      height="28"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 5H9C7.89543 5 7 5.89543 7 7V21C7 22.1046 7.89543 23 9 23H19C20.1046 23 21 22.1046 21 21V7C21 5.89543 20.1046 5 19 5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="2.3662"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="16.5" cy="17.5" r="1.5" fill="currentColor" />
      <rect x="10" y="8" width="8" height="5" rx="1" fill="currentColor" />
    </svg>
  );
}

export default Room;
