import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MenuBar from "./components/MenuBar/MenuBar";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "./components/RecordingNotifications/RecordingNotifications";
import Room from "./components/Room/Room";
import useHeight from "./hooks/useHeight/useHeight";
import useRoomState from "./hooks/useRoomState/useRoomState";
import { VibehutDataProvider } from "./components/VibehutDataProvider";
import { VibehutChatProvider } from "./components/VibehutChatProvider";
import { globals } from "./globals";
import VibehutModal from "./VibehutUI/VibehutModal";
import MobileMenuBar from "./components/MenuBar/MobileMenuBar";
import { useMediaQuery } from "@material-ui/core";
import theme from "./theme";
import CallSettingScreen from "./components/PreJoinScreens/CallSettingScreen";
import { wait } from "./utils/utils";
import { LiveVideoProvider } from "./components/LiveVideoProvider";
import useKeyboardControls from "./hooks/useKeyboardControls/useKeyboardControls";
import Toast from "../VibehutUI/Toast";
import FlyingEmojisOverlay from "./components/FlyingEmojisOverlay/FlyingEmojisOverlay";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto",
});

const Main = styled("main")(({ theme }: { theme: Theme }) => ({
  overflow: "hidden",
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: "#1F2023",
  // [theme.breakpoints.down('sm')]: {
  //   paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  // },
}));

const defaultModalConfig = {
  showModal: false,
  title: "",
  isClosable: true,
  content: (
    <div>
      <span></span>
    </div>
  ),
};

export default function App() {
  const roomState = useRoomState();

  const [modalConfig, setModalConfig] = useState(defaultModalConfig);
  const onModalClose = () => {
    setModalConfig({
      ...modalConfig,
      showModal: false,
    });
  };

  globals.showModal = (title, content, isClosable = true) => {
    setModalConfig({
      title,
      isClosable,
      content,
      showModal: true,
    });
  };
  globals.hideModal = () => onModalClose();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useKeyboardControls();

  const handleJoinRoom = async (
    matchPoints: number,
    roomName: string,
    instantRoomId: string,
    password: string,
    callId?: string,
    isNewCall?: boolean,
    subRoomName?: string,
  ) => {
    await wait(1000);
    let timeOutId: NodeJS.Timeout;
    return new Promise((resolve) => {
      if (!globals.socket) {
        return resolve({ error: "Something went wrong!" });
      }

      globals.onJoinRoomCallback = ({
        users,
        liveUsersCount,
        room_data,
        error,
        calls,
        callDetails,
        lobbyUsersCount,
        talkingUsersCount,
        screenPresentingUser,
      }: any) => {
        clearTimeout(timeOutId);

        resolve({
          users,
          liveUsersCount,
          room_data,
          error,
          calls,
          callDetails,
          lobbyUsersCount,
          talkingUsersCount,
          screenPresentingUser,
        });
      };

      const joinRoom = (retry?: boolean) => {
        try {
          if (!retry) {
            timeOutId = setTimeout(() => {
              joinRoom(true);
            }, 15000);
          } else {
            console.log("RETRYING TO JOIN THE ROOM");
          }
          if (!globals.socket) {
            return resolve({ error: "Something went wrong!" });
          }

          globals.socket.emit("join", {
            match_price: matchPoints,
            roomKey: roomName,
            v: instantRoomId,
            password: password,
            callId: callId ? callId : undefined,
            isNewCall: isNewCall ? isNewCall : undefined,
            subRoomName: subRoomName ? subRoomName : undefined,
            // commented following as temporary fix for diverged calls issue
            // eventId: eventId ? eventId : undefined,
          });
        } catch (error) {
          console.log("error", error);
        }
      };

      joinRoom();
    });
  };

  if (globals.showEmptyScreen) {
    return <></>;
  }
  return (
    <Container style={{ height }}>
      <VibehutChatProvider>
        <VibehutDataProvider>
          <LiveVideoProvider>
            {roomState === "disconnected" ? (
              <CallSettingScreen handleJoinRoom={handleJoinRoom} />
            ) : (
              <Main>
                <ReconnectingNotification />
                <RecordingNotifications />
                <Room />
                <FlyingEmojisOverlay />
                {isMobile ? <MobileMenuBar /> : <MenuBar />}
              </Main>
            )}
            <VibehutModal
              isOpen={modalConfig.showModal}
              onClose={onModalClose}
              content={modalConfig.content}
              title={modalConfig.title}
              isClosable={modalConfig.isClosable}
            />
            <Toast autoHideDuration={6000} />
          </LiveVideoProvider>
        </VibehutDataProvider>
      </VibehutChatProvider>
    </Container>
  );
}
