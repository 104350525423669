import * as actionTypes from "../constants/actionTypes";

const initialState = {
  isOpen: false,
  modalType: null,
  data: null,
};

function uiModalReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        modalType: action.payload.modalType,
        isOpen: true,
        data: action.payload.data,
      };
    case actionTypes.HIDE_MODAL:
      return {
        modalType: null,
        isOpen: false,
        data: null,
      };
    default:
      return state;
  }
}
export default uiModalReducer;
