import axios from "../utils/axios";

export function createEventSubscription(token, data) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/eventSubscription`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function checkPremiumActivityAccess(name, creatorId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/premiumCreator/find/${name}/${creatorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function checkEventSubscription(token, eventId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/eventSubscription/check/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
