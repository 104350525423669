import { globals } from '../../globals';
import useVibehutData from '../../hooks/useVibehutData/useVibehutData';
import Clock from '../Icons/Clock';

function Timer() {
  const { timerValue } = useVibehutData(globals.roomId);
  return (
    <div className="bg-componentColor text-white px-2 py-1.5 inline-flex items-center space-x-1 rounded-lg">
      <Clock />
      <span className="text-sm text-primaryYellow font-normal">{timerValue}</span>
    </div>
  );
}

export default Timer;
