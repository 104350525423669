import React from "react";
import { baseURL } from "../utils/axios";

export default function TwitterCallback(props) {
  const query = new URLSearchParams(props.location.search);
  window.location.href = `${baseURL}/twitter/callback?oauth_token=${query.get(
    "oauth_token"
  )}&oauth_verifier=${query.get("oauth_verifier")}`;
  return <div style={{ background: "white" }}>Redirecting...</div>;
}
