import axios from "axios";
import { getEnv } from "./utils";

const domains = {
  local: "localhost:8080",
  staging: "vibehut-staging.herokuapp.com",
  prod: "vibehut.io",
  testing: "vibehut-test.looklike.io",
};

const protocols = {
  local: "http://",
  staging: "https://",
  prod: "https://",
  testing: "https://",
};

const environment = getEnv();

export const localVideoUrl = "http://localhost:3001";
export const domain = domains[environment];
export const baseURL = `${protocols[environment]}${domain}`;
// export const baseURL = `https://vibehut.io`;
export const frontEndURL = `${protocols[environment]}${window.location.host}`;

export const videoAppUrl = frontEndURL;

export const LinkedInApi = {
  clientId: "86jgfok90ostcq",
  redirectUrl: `${frontEndURL}/linkedin`,
  oauthUrl:
    "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
  scope: "r_emailaddress r_liteprofile", //r_basicprofile
  state: "123456",
};

export const WorldcoinApi = {
  clientId: "app_cad3e1a589428163267676cb4deaa92b",
  redirectUrl: `${frontEndURL}/auth/worldcoin/callback`,
  oauthUrl: "https://id.worldcoin.org/authorize",
  scope: "openid+profile+email",
};

export default axios.create({
  baseURL: `${baseURL}/api/v1`,
  validateStatus: status => status < 500,
});
