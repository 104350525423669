import { useContext } from 'react'
import * as actionTypes from "../../constants";
import LimitedTextarea from '../../VibehutUI/LimitedTextarea';
import { StoreContext } from '../../Context/StoreContext';
import FaqCard from './FaqCard';
import { PersonaFaq } from '../../types';
import Button from '../../VibehutUI/Button';
import Loader from '../../VibehutUI/Loader';
import { createPersonaFaq } from '../../actions/personaAction';
import { showToast } from '../../actions/toastActions';

function FaqTab() {
    const { state, dispatch } = useContext(StoreContext);
    const {
        auth: { user },
        persona: { formState, personaFaqs, isCreatingFaq },
    } = state;

    const removeFaq = (key: number) => {
        const faqs = formState.data.faqs.filter((_: any, index: number) => {
            return key !== index;
        });
        dispatch({ type: actionTypes.UPDATE_FORM_DATA_STATE, payload: { ...formState.data, faqs } })
    };

    const addFaqInput = () => {
        const faqs = [...formState.data.faqs, {
            question: "",
            answer: ""
        }];
        dispatch({ type: actionTypes.UPDATE_FORM_DATA_STATE, payload: { ...formState.data, faqs } })
    };

    const handleChangeTextAreaValue = (value: string, name: string, index: number) => {
        switch (name) {
            case 'question':
                let _faqs = [...formState.data.faqs];
                _faqs =
                    _faqs.map((el, inputIndex) =>
                        inputIndex === index
                            ? {
                                ...el,
                                question: value,
                            }
                            : el
                    )
                dispatch({ type: actionTypes.UPDATE_FORM_ERROR_STATE, payload: { ...formState.errorMessage, faqsError: '' } })
                dispatch({ type: actionTypes.UPDATE_FORM_DATA_STATE, payload: { ...formState.data, faqs: _faqs } })
                break;
            case 'answer':
                let faqs = [...formState.data.faqs];
                faqs =
                    faqs.map((el, inputIndex) =>
                        inputIndex === index
                            ? {
                                ...el,
                                answer: value,
                            }
                            : el
                    )
                dispatch({ type: actionTypes.UPDATE_FORM_ERROR_STATE, payload: { ...formState.errorMessage, faqsError: '' } })
                dispatch({ type: actionTypes.UPDATE_FORM_DATA_STATE, payload: { ...formState.data, faqs: faqs } })
                break;
            default:
                break;
        }
    }

    const validateInputs = () => {
        const faqsValidate = formState.data?.faqs.some(
            (faq: any) => (faq.question && faq.answer === "")
        )

        if (faqsValidate) {
            dispatch({
                type: actionTypes.UPDATE_FORM_ERROR_STATE, payload: {
                    faqsError: 'All answers are required',
                }
            })
            return false;
        }
        return true;
    }

    const createFaq = () => {
        if (!validateInputs()) {
            return;
        }
        createPersonaFaq(formState.data.faqs, user?.token, dispatch, formState.data._id)
            .then(() => {
                dispatch({
                    type: actionTypes.UPDATE_FORM_DATA_STATE, payload: {
                        ...formState.data, faqs: [{
                            question: "",
                            answer: ""
                        }]
                    }
                })
            })
            .catch((error) => {
                if (error.message) {
                    showToast(dispatch, "error", error.message, 2000);
                }
            });
    }
    return (
        <div className="px-4 space-y-1.5">
            <div>
                {formState.data?.faqs && formState.data.faqs.map((faq: any, index: number) => (
                    <div className={`py-2 ${index !== formState.data.faqs.length - 1 ? "border-b" : ""} border-frescoWhite dark:border-matteGray`}>
                        <div>
                            {
                                index !== 0 && (
                                    <div className="flex justify-end items-center">
                                        <p
                                            className="text-sm font-medium text-cvRed cursor-pointer"
                                            onClick={() => removeFaq(index)}
                                        >
                                            Remove
                                        </p>
                                    </div>
                                )}
                            <div>
                                <div className='flex justify-between'>
                                    <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                                        Question<span className="text-cvRed absolute">*</span>
                                    </p>
                                </div>
                                <LimitedTextarea
                                    limit={500}
                                    value={faq.question}
                                    placeHolder="Provide details about your persona. How you'd introduce this persona to someone."
                                    rows={2}
                                    onChange={(value: string) => handleChangeTextAreaValue(value, 'question', index)}
                                />
                            </div>
                            <div>
                                <div className='flex justify-between'>
                                    <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                                        Answer<span className="text-cvRed absolute">*</span>
                                    </p>
                                </div>
                                <LimitedTextarea
                                    limit={500}
                                    value={faq.answer}
                                    placeHolder="Provide details about your persona. How you'd introduce this persona to someone."
                                    rows={2}
                                    onChange={(value: string) => handleChangeTextAreaValue(value, 'answer', index)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                {formState.errorMessage.faqsError && <p className="text-xs text-cvRed">{formState.errorMessage.faqsError}</p>}
                <div className='flex justify-between items-center'>
                    <p className="text-xs text-gray-600 dark:text-frescoWhite underline cursor-pointer" onClick={addFaqInput}>Add More</p>
                    <div className="">
                        {!isCreatingFaq ? (
                            <Button
                                disabledClasses={"opacity-40"}
                                onClick={createFaq}
                            >
                                Save
                            </Button>
                        ) : (
                            <div className="flex justify-start">
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>

            </div>
            <div className='max-h-60 overflow-auto custom-scrollbar pt-2'>
                {personaFaqs.map((faq: PersonaFaq) => <FaqCard faq={faq} key={faq._id} />)}
            </div>
        </div>
    )
}

export default FaqTab