import React from 'react';

function CamOn({ color = 'white' }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.3334 4.66666L10.6667 7.99999L15.3334 11.3333V4.66666Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33342 3.33334H2.00008C1.2637 3.33334 0.666748 3.9303 0.666748 4.66668V11.3333C0.666748 12.0697 1.2637 12.6667 2.00008 12.6667H9.33342C10.0698 12.6667 10.6667 12.0697 10.6667 11.3333V4.66668C10.6667 3.9303 10.0698 3.33334 9.33342 3.33334Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CamOn;
