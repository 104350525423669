import axios from "../utils/axios";

export function getAllRoles() {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/roles/get-roles`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
