import React, { useCallback, useMemo, useRef } from "react";

import Button from "@material-ui/core/Button";
import VideoOffIcon from "../../../icons/VideoOffIcon";
import VideoOnIcon from "../../../icons/VideoOnIcon";

import useDevices from "../../../hooks/useDevices/useDevices";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import CamOff from "../../../VibehutUI/Icons/CamOff";
import CamOn from "../../../VibehutUI/Icons/CamOn";
import { Theme, Tooltip, useMediaQuery } from "@material-ui/core";

interface ToggleVideoButtonProps {
  disabled?: boolean;
  className?: string;
  fromConnectingPage?: boolean;
}

export default function ToggleVideoButton({
  disabled,
  className,
  fromConnectingPage = true,
}: ToggleVideoButtonProps) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const toolTipText = useMemo(() => {
    return `Turn ${!isVideoEnabled ? "on" : "off"} Camera ( ctrl + E )`;
  }, [isVideoEnabled]);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  if (fromConnectingPage) {
    return (
      <Tooltip title={toolTipText} placement="top">
        <Button
          type="button"
          className={className}
          onClick={toggleVideo}
          disabled={!hasVideoInputDevices || disabled}
          startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        >
          {!hasVideoInputDevices
            ? "No Video"
            : isVideoEnabled
              ? "Stop Video"
              : "Start Video"}
        </Button>
      </Tooltip>
    );
  }

  if (disabled) {
    return (
      <Tooltip title={toolTipText} placement="top">
        <button
          type="button"
          className={` ${
            isMobile ? "w-8 h-8" : "w-10 h-10"
          } flex items-center justify-center rounded-full ${
            isMobile ? "bg-matteGray" : " bg-gray-700"
          }   cursor-wait`}
          disabled
        >
          <CamOn />
        </button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={toolTipText} placement="top">
      <button
        className={` ${
          isMobile ? "w-8 h-8" : "w-10 h-10"
        } flex items-center justify-center rounded-full ${
          isVideoEnabled
            ? isMobile
              ? "bg-matteGray"
              : " bg-gray-700"
            : "bg-cvRed"
        }   cursor-pointer`}
        type="button"
        onClick={toggleVideo}
      >
        {isVideoEnabled ? <CamOn /> : <CamOff />}
      </button>
    </Tooltip>
  );
}
