import { RefObject, useEffect } from 'react';

function useFullScreen<T extends HTMLElement = HTMLElement>(ref: RefObject<T>) {
  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking outside ref's element
      if (ref.current && !ref.current.contains(event.target)) {
        return;
      }
      toggleFullScreen();
    };

    document.addEventListener('dblclick', listener);

    return () => {
      document.removeEventListener('dblclick', listener);
    };
  }, [ref]);
}

export default useFullScreen;
