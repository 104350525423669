import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import Close from "../Icons/Close";
import TalkingUsersModal from "../TalkingUsersModal";
import ChatIcon from "../Icons/Chat";
import CameraFlip from "../Icons/CameraFlip";
import Settings from "../Icons/Settings";
import CopyButton from "../CopyButton";
import StopRecording from "../Icons/StopRecording";
import RecordingStart from "../Icons/RecordingStart";
import InviteUserModal from "../InviteUserModel";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import ToggleVideoButton from "../../components/Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleAudioButton from "../../components/Buttons/ToggleAudioButton/ToggleAudioButton";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { frontEndURL } from "../../constants";
import useQuery from "../../hooks/useQuery/useQuery";
import useVibehutChatContext from "../../hooks/useVibehutChatContext/useVibehutChatContext";
import VideoSettings from "../../components/VideoSettings";
import useFlipCameraToggle from "../../hooks/useFlipCameraToggle/useFlipCameraToggle";
import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import { useAppState } from "../../state";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import { getRoomName } from "../../utils/utils";
import LiveSettings from "../../components/LiveSettings";
import DoubleStar from "../Icons/DoubleStar";
import BoostCall from "../Boostcall";
import LiveStreamChatWindow from "../../components/ChatWindow/LiveStreamChatWindow";
import { StoreContext } from "../../../Context/StoreContext";
import * as actionTypes from "../../../constants";
import LiveChat from "../Icons/LiveChat";

interface MobileMoreOptionProps {
  isMoreOpen: boolean;
  handleMoreOption: (data: boolean) => void;
}

export default function MobileMoreOption({
  handleMoreOption,
  isMoreOpen,
}: MobileMoreOptionProps) {
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();
  const { toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  const callId = room!.name;
  const query = useQuery();
  const {
    unreadMessagesCount,
    setIsChatWindowOpen,
    setIsLiveStreamChatWindowOpen,
  } = useVibehutChatContext();
  const { updateRecordingRules, setIsGalleryViewActive, isGalleryViewActive } =
    useAppState();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { roomData, gotoNextCall } = useVibehutData(globals.roomId);
  const [instantRoomId, setInstantRoomId] = useState<string>("");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const isRecording = useIsRecording();
  const [isLiveSettingsOpen, setIsLiveSettingsOpen] = useState(false);
  const { authUser } = useAuthUser();
  const {
    state: {
      chat: { liveStreamUnreadMessageCount },
    },
    dispatch,
  } = useContext(StoreContext);
  const authUserId = authUser?._id;
  const roomOwnerId = roomData.owner?._id;
  const _liveUsers = roomData?.calls[callId];

  useEffect(() => {
    const v = query.get("v");
    setInstantRoomId(v || "");
  }, []);

  const isRoomAdmin =
    roomOwnerId === authUserId ||
    (roomData.moderators !== undefined &&
      roomData.moderators.includes(authUserId));

  useEffect(() => {
    if (globals.boostedCallData) {
      const boostCallInitiatedUser = _liveUsers.find(
        (user) => user._id === globals.boostedCallData.userId,
      );
      if (boostCallInitiatedUser) {
        globals.boostedCallData = {};
      }
    }
  }, [globals.boostedCallData]);

  const showBoostCallModal = () => {
    handleMoreOption(false);
    globals.showModal("Set Call Message", <BoostCall />);
  };

  return (
    <>
      <Transition appear show={isMoreOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed bottom-0 left-0 right-0 z-50"
          onClose={() => handleMoreOption(false)}
        >
          <div className="text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-100 translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-full"
              leaveTo="opacity-0 translate-y-full"
            >
              <div className="inline-block w-full overflow-hidden text-left  transition-all transform bg-darkNight shadow-xl rounded-t-2xl ">
                <div className="py-3 px-4  border-b border-componentColor">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                      <div>
                        <img
                          src={`${
                            roomData?.cover?.urls?.small ||
                            `${process.env.PUBLIC_URL}/images/room-fallback.png`
                          } `}
                          alt="profile_picture"
                          className="w-10 h-10 rounded-xl"
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-white font-medium text-base custom-two-line-clamp">
                          {getRoomName(roomData, callId, authUser)}
                        </p>
                        <p className="text-sm text-white flex items-center space-x-1 cursor-pointer">
                          {roomData.talkingUsersCount} Talking,{" "}
                          {roomData.lobbyUsersCount} In Lobby
                          <span
                            className="text-primary cursor-pointer"
                            onClick={() => {
                              handleMoreOption(false);
                              setIsModalOpen(true);
                            }}
                          >
                            &nbsp; See All
                          </span>
                          {/* <span className="pt-1">
                            <Arrow />
                          </span> */}
                        </p>
                      </div>
                    </div>
                    <span
                      className="bg-matteGray p-9px rounded-full cursor-pointer"
                      onClick={() => handleMoreOption(false)}
                    >
                      <Close color="#C4C4C4" />
                    </span>
                  </div>
                </div>
                <div className="px-4 my-4 ">
                  <div className="flex items-center justify-between py-2">
                    <div
                      className={`w-8 h-8 flex items-center justify-center rounded-full bg-matteGray
          cursor-pointer relative`}
                      onClick={() => {
                        handleMoreOption(false);
                        setIsChatWindowOpen(true);
                      }}
                    >
                      <ChatIcon />
                      {unreadMessagesCount === 0 ? null : (
                        <span className="w-5 h-5 rounded-full -top-1 -right-1 bg-cvRed absolute text-white flex justify-center items-center text-xs">
                          {unreadMessagesCount}
                        </span>
                      )}
                    </div>
                    <div
                      className={`w-8 h-8 flex items-center justify-center rounded-full bg-matteGray
          cursor-pointer relative`}
                      onClick={() => {
                        dispatch({
                          type: actionTypes.RESET_LIVE_STREAM_MESSAGE_COUNT,
                        });
                        handleMoreOption(false);
                        setIsLiveStreamChatWindowOpen(true);
                      }}
                    >
                      <LiveChat />
                      {liveStreamUnreadMessageCount === 0 ? null : (
                        <span className="w-5 h-5 rounded-full -top-1 -right-1 bg-cvRed absolute text-white flex justify-center items-center text-xs">
                          {liveStreamUnreadMessageCount}
                        </span>
                      )}
                    </div>
                    <ToggleAudioButton
                      disabled={isReconnecting}
                      fromConnectingPage={false}
                    />
                    <ToggleVideoButton
                      disabled={isReconnecting}
                      fromConnectingPage={false}
                    />

                    {flipCameraSupported && (
                      <div
                        className="w-8 h-8 flex items-center justify-center rounded-full bg-matteGray cursor-pointer"
                        onClick={toggleFacingMode}
                      >
                        <CameraFlip />
                      </div>
                    )}

                    <div
                      className="w-8 h-8 flex items-center justify-center rounded-full bg-matteGray cursor-pointer text-white"
                      onClick={() => {
                        handleMoreOption(false);
                        setSettingsOpen(true);
                      }}
                    >
                      <Settings color="white" />
                    </div>
                  </div>

                  {/* VIDEO_SETTINGS: */}
                  <div className=" my-2 ">
                    <div className="bg-componentColor text-white text-center rounded-xl">
                      <div className=" border-b border-gray-600 cursor-pointer flex items-center justify-center w-full">
                        <CopyButton
                          url={
                            instantRoomId
                              ? `${frontEndURL}/call?v=${instantRoomId}`
                              : `${frontEndURL}/rooms/${roomData?._id}`
                          }
                          title="copy"
                          type="button"
                          text="text-base"
                          cssClasses="justify-center text-white py-2.5"
                          showIcon={false}
                          forMobile
                          setMenuOpen={handleMoreOption}
                        />
                      </div>
                      {isRecording ? (
                        <div
                          className={`py-2.5  border-b border-gray-600 flex items-center justify-center w-full ${
                            Boolean(isRoomAdmin) ? "cursor-pointer" : ""
                          }`}
                          onClick={() => {
                            if (Boolean(isRoomAdmin)) {
                              handleMoreOption(false);
                              updateRecordingRules(room!.sid, [
                                { type: "exclude", all: true },
                              ]);
                            }
                          }}
                        >
                          <span
                            className={`${
                              !Boolean(isRoomAdmin)
                                ? "text-gray-400"
                                : "text-cvRed"
                            }`}
                          >
                            Stop Recording
                          </span>
                          <span className="pt-1 ml-2">
                            <StopRecording
                              isDisable={!Boolean(isRoomAdmin) ? true : false}
                            />
                          </span>
                        </div>
                      ) : (
                        <div
                          className={`py-2.5  border-b border-gray-600 flex items-center justify-center w-full ${
                            Boolean(isRoomAdmin) ? "cursor-pointer" : ""
                          }`}
                          onClick={() => {
                            if (Boolean(isRoomAdmin)) {
                              handleMoreOption(false);
                              updateRecordingRules(room!.sid, [
                                { type: "include", all: true },
                              ]);
                            }
                          }}
                        >
                          <span
                            className={`${
                              !Boolean(isRoomAdmin) ? "text-gray-400" : ""
                            }`}
                          >
                            Start Recording!!!
                          </span>

                          <span className="ml-2">
                            <RecordingStart
                              isMobile
                              isDisable={!Boolean(isRoomAdmin) ? true : false}
                            />
                          </span>
                        </div>
                      )}

                      <div
                        className={`py-2.5  border-b border-gray-600 flex items-center justify-center w-full ${
                          Boolean(isRoomAdmin) ? "cursor-pointer" : ""
                        }`}
                        onClick={() => {
                          if (Boolean(isRoomAdmin)) {
                            handleMoreOption(false);
                            setIsLiveSettingsOpen(true);
                          }
                        }}
                      >
                        <span
                          className={`${
                            !Boolean(isRoomAdmin) ? "text-gray-400" : ""
                          }`}
                        >
                          Live
                        </span>
                      </div>

                      <div
                        className="py-2.5 border-b border-gray-600   cursor-pointer flex items-center justify-center w-full"
                        onClick={() => {
                          handleMoreOption(false);
                          setIsBackgroundSelectionOpen(true);
                        }}
                      >
                        <span className="">Background</span>
                      </div>
                      <div
                        className="py-2.5  cursor-pointer flex items-center justify-center w-full"
                        onClick={() => {
                          handleMoreOption(false);
                          setIsGalleryViewActive((isGallery) => !isGallery);
                        }}
                      >
                        <span>
                          {isGalleryViewActive
                            ? "Speaker View"
                            : "Gallery View"}
                        </span>
                      </div>
                    </div>

                    {/* VIDEO_SETTINGS: */}
                    <div className="bg-componentColor text-white text-center rounded-xl mt-4">
                      {!roomData.isWaveCall && !roomData.isGroupRoom && (
                        <div className="flex space-x-2 justify-center">
                          <div
                            className="py-2.5  border-b border-gray-600 cursor-pointer w-full"
                            onClick={gotoNextCall}
                          >
                            Next Call
                          </div>
                        </div>
                      )}
                      {roomData.isGroupRoom && (
                        <div
                          className="py-2.5 cursor-pointer"
                          onClick={() => {
                            handleMoreOption(false);
                            setIsOpen(true);
                          }}
                        >
                          Invite Friends
                        </div>
                      )}
                    </div>
                    {!roomData.isWaveCall &&
                      !roomData.isInstantRoom &&
                      !roomData.is_private && (
                        <div
                          className="bg-gradient-btn flex align-center items-center  rounded-xl justify-center px-1.5 sm:px-4 py-2 mt-2"
                          onClick={showBoostCallModal}
                        >
                          <DoubleStar />
                          <p className=" text-white  ml-0.5">
                            {roomData.isBoostedCallRoom
                              ? "Call Message Set"
                              : "Set Call Message"}{" "}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      {/* <StopRecordingModal
        isOpen={isStopRecordingModel}
        setIsOpen={setIsStopRecordingModel}
        setIsRecording={setIsRecording}
        setIsRecordingOppositeUser={setIsRecordingOppositeUser}
      /> */}
      <ChatWindow />
      <LiveStreamChatWindow />
      <VideoSettings
        isOpen={settingsOpen}
        setIsOpen={() => {
          setSettingsOpen(false);
        }}
      />
      <LiveSettings
        isOpen={isLiveSettingsOpen}
        setIsOpen={() => {
          setIsLiveSettingsOpen(false);
        }}
      />
      <TalkingUsersModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        usersCount={roomData.liveUsersCount}
        talkingUsers={Object.values(roomData.calls)}
        lobby_users={roomData.lobbyUsers}
      />
      <InviteUserModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        roomId={roomData._id}
        callsMap={roomData.calls}
        callId={callId}
      />
    </>
  );
}
