// Description: Reducer for UI state
// create initial state to display or hide UI
import * as profileTypes from "../constants/commonTypes";
const initialState = {
  openModal: false,
  setNotificationModal: false,
  setContactModal: true,
  setWalletModal: false,
  setPlanModal: false,
  setAppSettingsModal: false,
  setGamificationModal: false,
  isTopUpActive: true,
  currentModal: profileTypes.CONTACT,
};
function modalReducer(state = initialState, action) {
  function toggleChange(stateName) {
    switch (stateName) {
      case profileTypes.NOTIFICATION:
        return {
          openModal: true,
          setNotificationModal: true,
          setContactModal: false,
          setWalletModal: false,
          setPlanModal: false,
          setAppSettingsModal: false,
          setGamificationModal: false,
          currentModal: profileTypes.NOTIFICATION,
        };

      case profileTypes.CONTACT:
        return {
          openModal: true,
          setNotificationModal: false,
          setContactModal: true,
          setWalletModal: false,
          setPlanModal: false,
          setAppSettingsModal: false,
          setGamificationModal: false,
          currentModal: profileTypes.CONTACT,
        };
      case profileTypes.WALLET:
        return {
          openModal: true,
          setNotificationModal: false,
          setContactModal: false,
          setWalletModal: true,
          setPlanModal: false,
          setAppSettingsModal: false,
          setGamificationModal: false,
          currentModal: profileTypes.WALLET,
        };

      case profileTypes.PLAN:
        return {
          openModal: true,
          setNotificationModal: false,
          setContactModal: false,
          setWalletModal: false,
          setPlanModal: true,
          setAppSettingsModal: false,
          setGamificationModal: false,
          currentModal: profileTypes.PLAN,
        };

      case profileTypes.APPSETTING:
        return {
          openModal: true,
          setNotificationModal: false,
          setContactModal: false,
          setWalletModal: false,
          setPlanModal: false,
          setAppSettingsModal: true,
          setGamificationModal: false,
          currentModal: profileTypes.APPSETTING,
        };
      case profileTypes.GAMIFICATION:
        return {
          openModal: true,
          setNotificationModal: false,
          setContactModal: false,
          setWalletModal: false,
          setPlanModal: false,
          setAppSettingsModal: false,
          setGamificationModal: true,
          currentModal: profileTypes.GAMIFICATION,
        };
      case profileTypes.CLOSE_MODAL:
        return {
          openModal: false,
        };
      default:
        return {
          openModal: false,
          setNotificationModal: false,
          setContactModal: true,
          setWalletModal: false,
          setAppSettingsModal: false,
          setGamificationModal: false,
          setPlanModal: false,
          setAddVibebucksOpen: false,
          currentModal: profileTypes.CONTACT,
        };
    }
  }

  switch (action.type) {
    case profileTypes.OPEN_PLAN_MODAL:
    case profileTypes.OPEN_NOTIFICATION_MODAL:
    case profileTypes.OPEN_CONTACT_MODAL:
    case profileTypes.OPEN_APP_SETTING_MODAL:
    case profileTypes.OPEN_GAMIFICATION_MODAL:
    case profileTypes.CLOSE_MODAL: {
      const data = toggleChange(action.payload);
      return {
        ...data,
        isTopUpActive: false,
      };
    }
    case profileTypes.OPEN_WALLET_MODAL: {
      const data = toggleChange(action.payload.model);
      return {
        ...data,
        isTopUpActive: action.payload.isTopUpActive,
      };
    }
    default:
      return state;
  }
}
export default modalReducer;
