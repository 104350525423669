import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import moment from "moment";
import Polygon from "../Icons/Polygon";
import Avatar from "../Avatar";
import UnlistedIcon from "../Icons/UnlistedIcon";
import PasswordIcon from "../Icons/PasswordIcon";
import Person from "../Icons/Person";
import CopyButton from "../CopyButton";
import PinnedPopper from "../PinnedPopper";
import Subscriptions from "../Icons/Subscriptions";
import SubscriberApproved from "../Icons/SubscriberApproved";
import { calcPlanIconColor, checkMyRoomSubscription } from "../../utils/utils";
import SubscriberApproval from "../Icons/SubscriberApproval";
import PaidBadgeRaw from "../Icons/PaidBadgeRaw";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    m: "%dm",
    mm: "%dm",
    h: "%dh",
    hh: "%dh",
    d: "%dd",
    dd: "%dd",
    M: "%d month",
    MM: "%d months",
    y: "%dy",
    yy: "%dy",
  },
});

function RoomCard({
  room,
  showRoomInfo = true,
  recentRooms = false,
  imgHeight = "h-40",
  showSubscriberCount = false,
}) {
  const [showCopyIcon, setShowCopyIcon] = React.useState(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const { state } = useContext(StoreContext);
  const {
    subscription: { mySubscriptions },
    auth: { user },
    room: { generalRoom },
  } = state;

  // const duration = room.last_connected_call_time
  //   ? moment
  //       .duration(
  //         new Date().getTime() -
  //           new Date(room.last_connected_call_time).getTime(),
  //         "milliseconds"
  //       )
  //       .toISOString()
  //   : moment
  //       .duration(
  //         new Date().getTime() - new Date(room.createdAt).getTime(),
  //         "milliseconds"
  //       )
  //       .toISOString();

  useEffect(() => {
    const mySubscription = checkMyRoomSubscription(mySubscriptions, room?._id);
    if (mySubscription) {
      setIsUserSubscribed(true);
    }
  }, [mySubscriptions, room?._id]);

  const _renderSubscriberApprovalRequired = (subscriberApprovedUsers) => {
    let isSubscriberApproved = false;
    subscriberApprovedUsers.forEach((subscriberApprovedUser) => {
      if (user && subscriberApprovedUser.toString() === user._id.toString()) {
        isSubscriberApproved = true;
      }
    });

    return (
      <div className="flex items-center space-x-1 border-[1px] border-primaryGray w-20 h-6 p-1 text-darkGray dark:border-matteGray rounded justify-center">
        {isSubscriberApproved && <SubscriberApproved ratio="18" />}
        {!isSubscriberApproved && <SubscriberApproval ratio="18" />}
        <span className="dark:text-gray-200 text-xs text-darkGray">
          Approved
        </span>
      </div>
    );
  };

  const _renderPlanAccessible = (planName) => {
    const iconFillColor = calcPlanIconColor(planName);
    return (
      <div className="flex items-center space-x-1 border-[1px] border-primaryGray min-w-16 max-w-20 h-6 p-1 text-darkGray dark:border-matteGray rounded justify-center">
        <PaidBadgeRaw color={iconFillColor} ratio="14" />
        <span className="dark:text-gray-200 text-xs text-darkGray">
          {planName}
        </span>
      </div>
    );
  };

  const roomSubscribersCount =
    (room?.subscriptions && room?.subscriptions?.length
      ? room?.subscriptions[0]?.count
      : room?.subscriptions?.count || 0) + 1;

  const imgSrc =
    room?._id === generalRoom?._id
      ? `${process.env.PUBLIC_URL}/images/user.png`
      : room.nft_logo_image
        ? room.nft_logo_image
        : (room.cover && room.cover.urls?.small) || `${process.env.PUBLIC_URL}/images/Frame 61.png`;

  return (
    <div
      onMouseEnter={() => setShowCopyIcon(true)}
      onMouseLeave={() => setShowCopyIcon(false)}
      className="cardWidth flex flex-col space-y-1"
    >
      <div className="relative">
        <img
          alt="room"
          src={imgSrc}
          className={`rounded-xl ${imgHeight}  w-full object-cover ${room?._id === generalRoom?._id ? "bg-primary" : ""
            }`}
          loading="lazy"
        />
        {room?.isPinned && (
          <PinnedPopper className="absolute top-3 left-3 z-10" />
        )}
        {!recentRooms && (
          <span className={`absolute top-3 right-3 z-15`}>
            <CopyButton
              title={`Vibehut - ${room?.name}`}
              url={`${window.location.href}/${room._id}`}
              isHoverable={true}
              showCopyIcon={showCopyIcon}
              forMobile={true}
              leftPosition="-left-9"
              ratio="w-6 h-6"
            />
          </span>
        )}
      </div>
      <p className="custom-line-clamp font-medium text-navyGray dark:text-frescoWhite ">
        {room.name}
      </p>
      {showRoomInfo && (
        <div className="flex flex-wrap flex-row gap-x-2 gap-y-2 py-1">
          {room?.isNftCollectionRoom && (
            <div className="flex items-center space-x-1 border-[1px] border-primaryGray w-12 h-6 p-1 text-darkGray dark:border-matteGray rounded justify-center">
              <Polygon bgColor={room?.isAccessible ? "#FFCC00" : "#B5B5B5"} />
              <span className="dark:text-gray-200 text-xs text-darkGray">
                NFT
              </span>
            </div>
          )}
          {room?.is_private && (
            <div className="flex items-center space-x-1 border-[1px] border-primaryGray w-[74px] h-6 p-1 text-darkGray dark:border-matteGray rounded justify-center">
              <UnlistedIcon />
              <span className="dark:text-gray-200 text-xs text-darkGray">
                Unlisted
              </span>
            </div>
          )}
          {room?.isPasswordProtected && (
            <div className="flex items-center space-x-1 border-[1px] border-primaryGray w-[74px] h-6 p-1  text-darkGray dark:border-matteGray rounded justify-center">
              <PasswordIcon />
              <span className="dark:text-gray-200 text-xs  text-darkGray">
                Password
              </span>
            </div>
          )}
          {room?.isGroupRoom ? undefined : (
            <div className="flex items-center space-x-1 border-[1px] border-primaryGray w-[50px] h-6 p-1 text-darkGray dark:border-matteGray rounded justify-center">
              <Person />
              <span className="dark:text-gray-200 text-xs text-darkGray">
                1:1
              </span>
            </div>
          )}
          {room?.isSubscriberRequireApproval &&
            _renderSubscriberApprovalRequired(
              room?.subscriberApprovedUsers || []
            )}

          {room?.planAccessible && _renderPlanAccessible(room.planAccessible)}
          {room?.hasSubscription && (
            <div
              className={`flex items-center space-x-1 border-[1px] w-[57px] h-6 p-1 ${isUserSubscribed
                ? "text-primaryGreen border-primaryGreen dark:border-primaryGreen"
                : " text-darkGray border-primaryGray dark:border-matteGray "
                } rounded justify-center `}
            >
              <Subscriptions color={isUserSubscribed ? "#34C759" : "#7A7A7A"} />
              <span
                className={` ${isUserSubscribed
                  ? " text-xs text-primaryGreen"
                  : "dark:text-gray-200 text-xs text-darkGray"
                  } `}
              >
                Paid
              </span>
            </div>
          )}
        </div>
      )}

      <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <div className="-space-x-4 flex overflow-hidden">
            {room.users?.map((user) => (
              <div
                className=" border-4 rounded-full dark:border-darkNight border-white"
                key={user._id}
              >
                <div className="w-7 h-7 rounded-full">
                  <Avatar
                    src={user.profile_picture}
                    username={user.username}
                    size="xs"
                  />
                </div>
              </div>
            ))}
          </div>
          {showSubscriberCount && (
            <div className="text-darkGray">
              <p className="text-sm">
                {roomSubscribersCount}{" "}
                {roomSubscribersCount === 1 ? "Subscriber" : "Subscribers"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.room === nextProps.room;
}
export default memo(RoomCard, areEqual);
