import React, { useContext, useState } from "react";
import RemoveConnection from "../../components/connections/RemoveConnection";
import { StoreContext } from "../../Context/StoreContext";
import {
  getConnection,
  updateMyConnection,
} from "../../services/connectionService";
import Button from "../Button";
import CheckMark from "../Icons/CheckMark";
import Loader from "../Loader";
import { showLoginModal } from "../../actions/uiModalActions";
import { isMobile } from "../../utils/utils";

function ConnectionButton({ userId }) {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;

  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveConnModal, setIsRemoveConnModal] = useState(false);

  React.useEffect(() => {
    if (user?.token) {
      getConnection(user?.token, userId)
        .then((res) => {
          setConnection(res.data.connection);
          console.log(res.data);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  const handleConnection = () => {
    if (!user) {
      return showLoginModal(dispatch);
    }

    if (connection) {
      setIsRemoveConnModal(true);
    } else {
      setIsLoading(true);
      updateMyConnection(
        { user: userId, is_connected: true },
        user?.token,
        dispatch
      ).then((res) => {
        console.log(res.data);
        setIsLoading(false);
        setConnection(res.data.connection);
      });
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : connection ? (
        <Button onClick={handleConnection} type="button">
          <div className="flex flex-row items-center justify-between space-x-1">
            <div>Following</div>
            {!isMobile() && <CheckMark />}
          </div>
        </Button>
      ) : (
        <Button
          onClick={handleConnection}
          size="vibhut-sm-outline"
          variant="vibhut-primary-outline"
        >
          Follow
        </Button>
      )}

      {connection && (
        <RemoveConnection
          isOpen={isRemoveConnModal}
          setIsOpen={setIsRemoveConnModal}
          connection={connection}
          setUserConnection={setConnection}
        />
      )}
    </>
  );
}

export default ConnectionButton;
