import axios from "../utils/axios";
import Axios from "axios";

export function getChatByRoomId(roomId, token, offset, source) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/chats/room/${roomId}?offset=${offset}&limit=${20}`, {
        cancelToken: source?.token,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) return;
        reject(error);
      });
  });
}

export function updateMessageReadStatus(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        `/chats/update-read-status/${roomId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateAllChatReadStatus(token) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        `/chats/update-all-chat-read-status`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateMessageDeliveredStatus(token) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        `/chats/update-delivered-status`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function countUnreadMessages(token, moreChat = false) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/chats/count-unread-chat?countMoreChat=${moreChat}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getChatStatus(token, chatId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/chats/get-chat-status/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAmiFullMsgResponse(token, chatId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/chats/get-bot-full-response/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getChatReactions(token, chatId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/chats/get-chat-reactions/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function countChatByRoom(token, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/chats/count-room-chat/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
