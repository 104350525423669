import React from "react";
import Close from "../Icons/Close";
import NoResult from "../Icons/NoResult";
import SendBlock from "./SendBlock";

function BlockSearchBar({
  value,
  handleChange,
  results = [],
  roomId,
  setValue,
  ownerId
}) {
  return (
    <>
      <div
        className={`searchbarDiv flex flex-col  overflow-y-hidden relative ${value.length > 0 && `h-full`
          }  grow `}
      >
        <div className="relative">
          <input
            type="text"
            className="w-full rounded-xl border border-lightGray select-none outline-none placeholder-notifyBg search-input bg-white"
            placeholder="Search..."
            onChange={handleChange}
            value={value}
          />
          <span
            className="absolute top-3 right-3 bg-notifyBg rounded-full p-[5px] cursor-pointer"
            onClick={() => {
              setValue("");
            }}
          >
            <Close color="#E5E5E5" ratio="8" />
          </span>
        </div>

        {value.length > 0 && (
          <div className="w-full my-3 flex-1 overflow-y-auto   custom-scrollbar">
            {results.length > 0 ? (
              results.map((user, index) => (
                <SendBlock
                  key={index}
                  user={user}
                  roomId={roomId}
                  ownerId={ownerId}
                />
              ))
            ) : (
              <div className="relative h-72 w-full ">
                <div className="absolute top-1/2 transform -translate-y-1/2 z-50 left-1/2 -translate-x-1/2 w-full">
                  <div>
                    <div className="flex justify-center">
                      <NoResult />
                    </div>

                    <p className="text-darkNight text-center dark:text-white text-lg font-bold font-ubuntu pt-2 w-full">
                      Nobody found
                    </p>
                    <p className="text-darkGray text-center dark:text-primaryGray text-base font-normal font-ubuntu pt-2 w-full ">
                      Adjust your search and try again.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default BlockSearchBar;
