import React from 'react';
import useAuthUser from '../../hooks/useAuthUser/useAuthUser';

interface Props {
  chat: any;
  removeUserOrAddReaction: (reaction: any) => void;
  addUserToReaction: (reaction: any) => void;
}

function Reactions({ chat, removeUserOrAddReaction, addUserToReaction }: Props) {
  const { authUser } = useAuthUser();
  const handleReactionClick = (reaction: any) => {
    if (reaction.owners.find((owner: any) => owner._id === authUser?._id)) {
      removeUserOrAddReaction({ ...reaction, owner: authUser?._id });
    } else {
      addUserToReaction({ ...reaction, owner: authUser?._id });
    }
  };
  const checkIsAuthUserReacted = (reaction: any) => {
    return reaction?.owners.find((owner: any) => owner._id === authUser?._id)
      ? chat.isSend
        ? 'border-2 border-white'
        : 'border-2 border-primary'
      : '';
  };
  return (
    <div className="flex gap-1 relative flex-wrap">
      {chat.reactions &&
        chat.reactions.map((reaction: any) => (
          <div className="flex flex-col" key={reaction._id}>
            <span>
              <span
                onClick={() => handleReactionClick(reaction)}
                className={`cursor-pointer bg-bitClout rounded-3xl flex gap-1 px-2 items-center py-0.5 ${checkIsAuthUserReacted(
                  reaction
                )}`}
              >
                {reaction?.owners?.length > 1 && (
                  <span className={`${chat.isSend ? 'text-white' : 'text-white'}`}>{reaction?.owners?.length}</span>
                )}{' '}
                {reaction.emoji}
              </span>
            </span>
          </div>
        ))}
    </div>
  );
}

export default Reactions;
