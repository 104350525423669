import React, { useState } from "react";
import GIF from "../Icons/GIF";
import GifsModal from "./GifsModal";

function GifButton({ setGif, isDisable }) {
  const [isGifModalOpen, setIsGifModalOpen] = useState(false);
  return (
    <>
      <div className="pt-1">
        <button
          className="cursor-pointer"
          onClick={() => setIsGifModalOpen(true)}
          disabled={isDisable}
        >
          <GIF />
        </button>
      </div>
      <GifsModal
        isOpen={isGifModalOpen}
        setIsOpen={setIsGifModalOpen}
        setGif={setGif}
      />
    </>
  );
}

export default GifButton;
