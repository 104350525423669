import React from "react";

function SvgDefinations() {
  return (
    <svg
      fill="transparent"
      style={{ position: "absolute", bottom: 0, zIndex: -1 }}
    >
      <defs>
        <radialGradient
          id="loadergradient"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8 8) rotate(90) scale(8)"
        >
          <stop stopColor="#DBDBDB" stopOpacity="0" />
          <stop offset="1" stopColor="#DBDBDB" />
        </radialGradient>
        <linearGradient
          id="desoSvgGradient1"
          x1="6.442"
          y1="5.59824"
          x2="11.1277"
          y2="17.1716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0057FF" />
          <stop offset="1" stopColor="#00A3FF" />
        </linearGradient>
        <linearGradient
          id="desoSvgGradient2"
          x1="8.72631"
          y1="5.67955"
          x2="17.7578"
          y2="3.23968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0283FF" />
          <stop offset="1" stopColor="#01C2FF" />
        </linearGradient>
        <linearGradient
          id="desoSvgGradient3"
          x1="5.61253"
          y1="16.5182"
          x2="17.9382"
          y2="-0.208463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0157FF" />
          <stop offset="1" stopColor="#00FFFF" />
        </linearGradient>
        <clipPath id="copy-clipPath">
          <rect
            width="12.8"
            height="12.8"
            fill="currentColor"
            transform="translate(5.6001 5.60001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefinations;
