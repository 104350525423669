import * as actionTypes from "../constants";
import * as referralTransactionService from "../services/referralTransactionService";

export function createReferralTransaction(dispatch, referralCode, token) {
  dispatch({
    type: actionTypes.CREATE_REFERRAL_REQUEST,
  });
  return referralTransactionService
    .createReferralTransaction(token, referralCode)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_REFERRAL_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_REFERRAL_FAILURE,
      });
      throw err;
    });
}

export function getReferralTransaction(dispatch, token) {
  dispatch({
    type: actionTypes.GET_REFERRAL_REQUEST,
  });
  return referralTransactionService
    .getReferralTransaction(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_REFERRAL_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_REFERRAL_FAILURE,
      });
    });
}
