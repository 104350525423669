import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { globals } from "../../globals";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { isMobile } from "../../utils";
import useLiveVideo from "../../hooks/useLiveVideo/useLiveVideo";
import Avatar from "../../VibehutUI/Avatar";
import useFullScreen from "../../hooks/useFullScreen/useFullScreen";
import { getUserPublicProfile } from "../../services/userService";
import { getUserData } from "../../utils/vibehutUtils";
import ParticipantComponent from "../Participant/Participant";
import Timer from "../../VibehutUI/Timer";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  identity: {
    color: "white",
    padding: "0.1em 0.3em 0.1em 0",
    display: "inline-flex",
    marginLeft: "0.4em",
    gap: "0.4em",
    marginRight: "0.4em",
    alignItems: "center",
  },
  infoContainer: {
    position: "absolute",
    zIndex: 2,
    height: "100%",
    width: "100%",
  },
  reconnectingContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(40, 42, 43, 0.75)",
    zIndex: 1,
  },
  fullWidth: {
    gridArea: "1 / 1 / 2 / 3",
    [theme.breakpoints.down("sm")]: {
      gridArea: "1 / 1 / 3 / 3",
    },
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    "& svg": {
      transform: "scale(2)",
    },
  },
  recordingIndicator: {
    display: "flex",
    alignItems: "center",
    background: "rgba(128, 128, 128, 0.7)", // Gray background color
    color: "white",
    padding: "0.1em 0.3em 0.1em 0",
    fontSize: "1.2rem",
    height: "28px",
    borderRadius: "14px",
    [theme.breakpoints.down("sm")]: {
      bottom: "auto",
      right: 0,
      top: 0,
    },
  },
  circle: {
    height: "12px",
    width: "12px",
    background: "green",
    borderRadius: "100%",
    margin: "0 0.5em",
    animation: `1.25s $pulsate ease-out infinite`,
  },
  circleGreen: {
    height: "12px",
    width: "12px",
    background: "green",
    borderRadius: "100%",
    margin: "0 0.5em",
    animation: `1.25s $pulsateGreen ease-out infinite`,
  },
  timeDisplay: {
    fontSize: "1rem",
    color: "#FFCC00",
    background: "#303234",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
  },
  "@keyframes pulsate": {
    "0%": {
      background: `#A90000`,
    },
    "50%": {
      background: "#f00",
    },
    "100%": {
      background: "#A90000",
    },
  },
  "@keyframes pulsateGreen": {
    "0%": {
      background: "#0a0",
    },
    "50%": {
      background: "#0f0",
    },
    "100%": {
      background: "#0a0",
    },
  },
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: MainParticipantInfoProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { isLive } = useLiveVideo();
  const { roomData } = useVibehutData(globals.roomId);
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;
  const [user, setUser] = useState<any>(null);
  const [callDuration, setCallDuration] = useState(0);

  const fullScreenRef = useRef<HTMLDivElement>(null);
  useFullScreen<HTMLDivElement>(fullScreenRef);
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video",
  );
  const screenSharePublication = publications.find((p) =>
    p.trackName.includes("screen"),
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  useEffect(() => {
    const getUser = async () => {
      const vibehutUserRes = await getUserPublicProfile(participant.identity);
      const vibehutUser = vibehutUserRes.data;
      const _user = getUserData(vibehutUser);
      console.log(_user);
      setUser(_user);
    };

    getUser();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCallDuration((prevDuration) => prevDuration + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const username =
    user?.username ||
    roomData.liveUsers.find(
      (p_user: any) => p_user._id === participant.identity,
    )?.username ||
    participant.identity;

  const profile_picture =
    user?.profile_picture ||
    roomData.liveUsers.find((p_user) => p_user._id === participant.identity)
      ?.profile_picture ||
    participant.identity;

  return (
    <div
      ref={fullScreenRef}
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing,
        "w-screen": isMobile,
      })}
    >
      <div className={classes.infoContainer}>
        <div className="gap-1 flex items-center">
          <div className={classes.identity}>
            <Timer />
            {/* <AudioLevelIndicator audioTrack={audioTrack} /> */}
            {/* <Typography variant="body1" color="inherit">
              <span className="flex gap-2 items-center">
                <span className={classes.timeDisplay}>
                  {formatTime(callDuration)}
                </span>
                {screenSharePublication && " Screen"}
              </span>
            </Typography> */}
          </div>
          {/* <NetworkQualityLevel participant={participant} /> */}
          <div className=" flex gap-2">
            {isRecording && (
              <Tooltip
                title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
                placement="top"
              >
                <div className={classes.recordingIndicator}>
                  <div className={classes.circle}></div>
                  <Typography
                    variant="body1"
                    color="inherit"
                    data-cy-recording-indicator
                  >
                    <span className="pr-1">Recording</span>
                  </Typography>
                </div>
              </Tooltip>
            )}
            {isLive && (
              <Tooltip
                title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
                placement="top"
              >
                <div className={classes.recordingIndicator}>
                  <div className={classes.circleGreen}></div>
                  <Typography variant="body1" color="inherit">
                    <span className="pr-1">Live</span>
                  </Typography>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className={classes.avatarContainer}>
          <Avatar src={profile_picture} username={username} />
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: "white" }}>
            Reconnecting...
          </Typography>
        </div>
      )}
      {isRemoteParticipantScreenSharing && (
        <div
          style={{
            width: 175,
            maxWidth: 230,
            height: 90,
            position: "absolute",
            right: isMobile ? -5 : 5,
            bottom: 15,
          }}
        >
          <ParticipantComponent
            participant={participant}
            isLocalParticipant={participant === room!.localParticipant}
          />
        </div>
      )}
      {children}
    </div>
  );
}
