import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';
import Loader from '../Loader';
import Button from '../Button';
import ReactHtmlParser from 'react-html-parser';

interface DeleteModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  isFetching?: boolean;
  handleDelete: () => void;
  title: string;
  text: string;
  btnvalue?: string;
  description?: string;
}

function DeleteModel({
  isOpen,
  setIsOpen,
  isFetching = false,
  handleDelete,
  title,
  text,
  btnvalue = 'Delete',
  description = "You can't undo this action.",
}: DeleteModalProps) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white 
              }  rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu`}
            >
              <div className={`bg-white p-3 border-b border-frescoWhite `}>
                <div className="flex justify-between items-center">
                  <p className={`font-medium text-lg  text-black dark:text-frescoWhite  `}>{title}</p>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className={`bg-white text-gray-600    p-4 relative`}>
                {ReactHtmlParser(text)}
                <div className="pt-4 text-darkGray">{description}</div>
              </div>

              <div className=" px-4 py-3 flex flex-row-reverse ">
                {isFetching ? (
                  <Loader />
                ) : (
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-danger"
                    onClick={() => {
                      handleDelete();
                      setIsOpen(false);
                    }}
                  >
                    {btnvalue}
                  </Button>
                )}
                <div className="mr-3">
                  <Button size="vibhut-sm" variant="vibhut-secondary" onClick={() => setIsOpen(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DeleteModel;
