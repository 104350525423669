import React from "react";

const Person = () => {
  return (
    <svg
      width="16"
      height="16"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.06445 4.45541C5.06445 2.74053 6.45402 1.3501 8.1678 1.3501C9.88159 1.3501 11.2712 2.74053 11.2712 4.45541C11.2712 6.17029 9.88159 7.56073 8.1678 7.56073C6.45402 7.56073 5.06445 6.17029 5.06445 4.45541Z"
        fill="#B5B5B5"
      />
      <path
        d="M8.06251 8.38623C11.1346 8.38623 13.625 10.1997 13.625 12.4362C13.625 14.6727 11.1346 13.8537 8.06251 13.8537C4.99041 13.8537 2.5 14.6727 2.5 12.4362C2.5 10.1997 4.99041 8.38623 8.06251 8.38623Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};

export default Person;
