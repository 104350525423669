import axios from "../utils/axios";

export function updateUserReferralCode(referralCode, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/referral",
        { referralCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createDefaultUserReferralCode(token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/referral/default-referral",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserReferral(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/referral", {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function countUserReferralTransaction(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/referral-transaction/get-my-referral-count/", {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getReferralUser(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/referral-transaction/get-referral-user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
