import React from 'react';

interface PopoverProps {
  text: string;
  size?: number;
  leftPosition?: string;
  topPosition?: string;
  pointerDirection?: 'bottom' | 'top';
}
function PopOver({
  text,
  size = 24,
  leftPosition = '-left-9',
  topPosition = '-top-11',
  pointerDirection = 'bottom',
}: PopoverProps) {
  return (
    <>
      <div className={`absolute ${leftPosition} ${topPosition}  z-40`}>
        <div className="relative">
          {pointerDirection === 'top' && (
            <svg
              className="absolute rotate-180 text-frescoWhite h-5 w-full -top-5"
              x="0px"
              y="0px"
              viewBox="0 0 255 255"
            >
              <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
            </svg>
          )}
          <div
            className={`font-medium  py-1.5 text-sm bottom-full bg-frescoWhite rounded-lg  text-darkNight w-${size}`}
          >
            <div>
              <p className="font-medium text-md text-center">{text}</p>
            </div>
          </div>
          {pointerDirection === 'bottom' && (
            <svg className="absolute text-frescoWhite h-5 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255">
              <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
            </svg>
          )}
        </div>
      </div>
    </>
  );
}

export default PopOver;
