import { useContext, useState } from 'react'
import { StoreContext } from '../../Context/StoreContext';
import LimitedTextarea from '../../VibehutUI/LimitedTextarea';
import Button from '../../VibehutUI/Button';
import Loader from '../../VibehutUI/Loader';
import { createPersonaActivity } from '../../actions/personaAction';
import { showToast } from '../../actions/toastActions';
import ActivityCard from './ActivityCard';

function ActivityTab() {
    const { state, dispatch } = useContext(StoreContext);
    const [activity, setActivity] = useState('');
    const {
        auth: {
            user,
        },
        persona: { isCreatingActivity, formState, personaActivity },
    } = state;

    const handleChangeTextAreaValue = (value: string, name: string) => {
        setActivity(value)
    }

    const createActivity = () => {
        const body = {
            persona: formState.data._id,
            activity
        }
        createPersonaActivity(body, user?.token, dispatch)
            .then(() => {
                setActivity('')
            })
            .catch((error) => {
                if (error.message) {
                    showToast(dispatch, "error", error.message, 2000);
                }
            });
    }

    return (
        <div className="p-4 space-y-1.5">
            <div>
                <div className='flex justify-between'>
                    <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                        Activity
                    </p>
                </div>
                <LimitedTextarea
                    limit={500}
                    value={activity}
                    placeHolder="Any new thought ?"
                    rows={3}
                    onChange={handleChangeTextAreaValue}
                />
            </div>
            <div className="">
                {!isCreatingActivity ? (
                    <Button
                        disabled={!activity}
                        disabledClasses={"opacity-40"}
                        onClick={createActivity}
                    >
                        Post
                    </Button>
                ) : (
                    <div className="flex justify-start">
                        <Loader />
                    </div>
                )}
            </div>
            <div className='max-h-60 overflow-auto custom-scrollbar pt-2'>
                {personaActivity.map((_personaActivity: any) => <ActivityCard activityData={_personaActivity} key={_personaActivity._id} />)}
            </div>
        </div>
    )
}

export default ActivityTab