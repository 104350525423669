import React from "react";

function Stars() {
  return (
    <svg
      width="28"
      height="28"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.17938 2.21769C6.46127 1.4559 7.53873 1.4559 7.82062 2.21769L8.75101 4.73203C8.83963 4.97153 9.02847 5.16037 9.26797 5.24899L11.7823 6.17938C12.5441 6.46127 12.5441 7.53873 11.7823 7.82062L9.26797 8.75101C9.02847 8.83963 8.83963 9.02847 8.75101 9.26797L7.82062 11.7823C7.53873 12.5441 6.46127 12.5441 6.17938 11.7823L5.24899 9.26797C5.16037 9.02847 4.97153 8.83963 4.73203 8.75101L2.21769 7.82062C1.4559 7.53873 1.4559 6.46127 2.21769 6.17938L4.73203 5.24899C4.97153 5.16037 5.16037 4.97153 5.24899 4.73203L6.17938 2.21769Z"
        fill="#FFCC00"
      />
      <path
        d="M16.8277 11.1681C17.2304 10.0799 18.7696 10.0799 19.1723 11.1681L20.5014 14.76C20.628 15.1022 20.8978 15.372 21.24 15.4986L24.8319 16.8277C25.9201 17.2304 25.9201 18.7696 24.8319 19.1723L21.24 20.5014C20.8978 20.628 20.628 20.8978 20.5014 21.24L19.1723 24.8319C18.7696 25.9201 17.2304 25.9201 16.8277 24.8319L15.4986 21.24C15.372 20.8978 15.1022 20.628 14.76 20.5014L11.1681 19.1723C10.0799 18.7696 10.0799 17.2304 11.1681 16.8277L14.76 15.4986C15.1022 15.372 15.372 15.1022 15.4986 14.76L16.8277 11.1681Z"
        fill="#716DFF"
      />
    </svg>
  );
}

export default Stars;
