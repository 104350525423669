import React from "react";

function UserIcon() {
  return (
    <svg
      width="16"
      height="16"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25171 3.35575C6.25171 2.33037 7.08146 1.50098 8.10273 1.50098C9.12401 1.50098 9.95376 2.33037 9.95376 3.35575C9.95376 4.38114 9.12401 5.21053 8.10273 5.21053C7.08146 5.21053 6.25171 4.38114 6.25171 3.35575ZM8.10273 0.000976562C6.25099 0.000976562 4.75171 1.50398 4.75171 3.35575C4.75171 5.20752 6.25099 6.71053 8.10273 6.71053C9.95447 6.71053 11.4538 5.20752 11.4538 3.35575C11.4538 1.50398 9.95447 0.000976562 8.10273 0.000976562ZM6.25 7.918C3.62665 7.918 1.5 10.0446 1.5 12.668V14.2485C1.5 15.215 2.2835 15.9985 3.25 15.9985H12.7493C13.7158 15.9985 14.4993 15.215 14.4993 14.2485V12.668C14.4993 10.0447 12.3726 7.918 9.74927 7.918H6.25ZM3 12.668C3 10.8731 4.45507 9.418 6.25 9.418H9.74927C11.5442 9.418 12.9993 10.8731 12.9993 12.668V14.2485C12.9993 14.3866 12.8873 14.4985 12.7493 14.4985H3.25C3.11193 14.4985 3 14.3866 3 14.2485V12.668Z"
        fill="#1F2023"
      />
    </svg>
  );
}

export default UserIcon;
