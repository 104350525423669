import React from 'react';

function LinkIcon({ ratio = 20, isGreyScaleActive = false }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isGreyScaleActive && `custom-grayscale`}`}
    >
      <path
        d="M6.6665 8.66666C6.95282 9.04941 7.31808 9.36611 7.73754 9.59529C8.157 9.82445 8.62084 9.96074 9.0976 9.99488C9.57437 10.029 10.0529 9.96024 10.5008 9.79318C10.9485 9.62613 11.3553 9.3647 11.6931 9.02666L13.6931 7.02666C14.3004 6.39799 14.6364 5.55598 14.6288 4.68199C14.6211 3.808 14.2706 2.97196 13.6525 2.35393C13.0345 1.7359 12.1985 1.38534 11.3245 1.37775C10.4505 1.37015 9.60852 1.70614 8.97984 2.31333L7.83318 3.45333"
        stroke="#B5B5B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33297 7.33333C9.04667 6.95058 8.68141 6.63388 8.26194 6.4047C7.84248 6.17553 7.37863 6.03925 6.90187 6.0051C6.42512 5.97097 5.94658 6.03975 5.49874 6.2068C5.05091 6.37387 4.64423 6.63528 4.30631 6.97333L2.30631 8.97333C1.69912 9.602 1.36313 10.444 1.37073 11.318C1.37832 12.192 1.72888 13.028 2.34692 13.646C2.96494 14.2641 3.80098 14.6146 4.67497 14.6223C5.54896 14.6299 6.39097 14.2939 7.01964 13.6866L8.15964 12.5466"
        stroke="#B5B5B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LinkIcon;
