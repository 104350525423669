import React, { useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { removeConnection } from "../../actions/connectionActions";
import { getUserData } from "../../services/userService";
import DeleteModel from "../../VibehutUI/DeleteModel";

export default function RemoveConnection({
  isOpen,
  setIsOpen,
  connection,
  setUserConnection,
}) {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth,
    connection: { isFetching },
  } = state;

  const handleDelete = () => {
    removeConnection(
      { user: connection.user._id, is_connected: false },
      auth?.user?.token,
      dispatch
    ).then(() => {
      if (setUserConnection) setUserConnection(false);
      setIsOpen(false);
    });
  };

  return (
    <DeleteModel
      handleDelete={handleDelete}
      title={getUserData(connection.user).username}
      isFetching={isFetching}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      text={` Are you sure to unfollow ${getUserData(connection.user).username} ?`}
    />
  );
}
