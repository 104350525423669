import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useMemo } from "react";
import Close from "../Icons/Close";
import { IUser } from "../../components/VibehutDataProvider";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  RedditIcon,
  RedditShareButton,
  XIcon,
} from "react-share";
import DeSo from "../Icons/DeSo";
import { StoreContext } from "../../../Context/StoreContext";
import { checkIsDesoUser } from "../../../utils/utils";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
interface ShareCallModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  url: string;
  users: IUser[];
  roomName: string;
}
const openLinkInNewWindow = (
  url: string,
  state: Record<string, string | number>,
) => {
  const queryString = Object.keys(state)
    .map((key) => `${key}=${encodeURIComponent(state[key])}`)
    .join("&");

  const windowRatio = 0.5;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const windowWidth = screenWidth * windowRatio;
  const windowHeight = screenHeight * windowRatio;

  const left = Math.max(0, (screenWidth - windowWidth) / 2 + window.screenX);

  const top = Math.max(0, (screenHeight - windowHeight) / 2 + window.screenY);

  const newWindow = window.open(
    `${url}?${queryString}`,
    "_blank",
    `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`,
  );

  if (newWindow) {
    // Focus the new window if it was successfully opened
    newWindow.focus();
  }
};

export default function ShareCallModal({
  isOpen,
  setIsOpen,
  url,
  users,
  roomName,
}: ShareCallModalProps) {
  const { authUser } = useAuthUser();

  const callUsers = useMemo(() => {
    if (authUser) {
      return users?.filter((user) => user?._id !== authUser?._id);
    }
    return users;
  }, [users, authUser]);

  const title = `${
    !callUsers || callUsers?.length === 0
      ? "Starting a call"
      : callUsers.length === 1
        ? `Having a great call with @${callUsers[0]?.username || ""}`
        : callUsers.length === 2
          ? `Having a great call with @${callUsers[0]?.username || ""} and @${
              callUsers[1]?.username || ""
            }`
          : `Having a great call with @${callUsers[0]?.username || ""} and ${
              callUsers.length - 1
            } others`
  } in ${roomName}. Join here:`;

  const handleDesoShare = () => {
    openLinkInNewWindow("/feed", { title, url });
  };
  const { state } = useContext(StoreContext);
  const { auth } = state;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4  pb-0 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[428px] h-full font-ubuntu">
              <div className="p-3 border-b border-frescoWhite dark:border-matteGray">
                <div className="flex justify-between items-center">
                  <p className="font-medium text-lg text-darkNight dark:text-frescoWhite  tracking-wide">
                    Share Call
                  </p>
                  <span
                    className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <Close />
                  </span>
                </div>
              </div>
              <div className="pt-3 h-96 custom-scrollbar">
                <div className="overflow-y-auto custom-scrollbar h-96 px-4 flex flex-col gap-1  divide-y dark:divide-matteGray">
                  <div className="flex justify-between items-center py-2">
                    <div className="flex space-x-3">
                      <XIcon size={28} round />
                      <p className="text-darkNight dark:text-frescoWhite flex items-center">
                        X
                      </p>
                    </div>
                    <div>
                      <TwitterShareButton
                        url={url}
                        title={title}
                        resetButtonStyle={false}
                        className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                      >
                        Share
                      </TwitterShareButton>
                    </div>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex space-x-3">
                      <FacebookIcon size={28} round />
                      <p className="text-darkNight dark:text-frescoWhite flex items-center">
                        Facebook
                      </p>
                    </div>
                    <div>
                      <FacebookShareButton
                        url={url}
                        title={title}
                        resetButtonStyle={false}
                        className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                      >
                        Share
                      </FacebookShareButton>
                    </div>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex space-x-3">
                      <TelegramIcon size={28} round />
                      <p className="text-darkNight dark:text-frescoWhite flex items-center">
                        Telegram
                      </p>
                    </div>
                    <div>
                      <TelegramShareButton
                        url={url}
                        title={title}
                        resetButtonStyle={false}
                        className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                      >
                        Share
                      </TelegramShareButton>
                    </div>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex space-x-3">
                      <WhatsappIcon size={28} round />
                      <p className="text-darkNight dark:text-frescoWhite flex items-center">
                        Whatsapp
                      </p>
                    </div>
                    <div>
                      <WhatsappShareButton
                        url={url}
                        title={title}
                        resetButtonStyle={false}
                        className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                      >
                        Share
                      </WhatsappShareButton>
                    </div>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex space-x-3">
                      <LinkedinIcon size={28} round />
                      <p className="text-darkNight dark:text-frescoWhite flex items-center">
                        Linkedin
                      </p>
                    </div>
                    <div>
                      <LinkedinShareButton
                        url={url}
                        title={title}
                        resetButtonStyle={false}
                        className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                      >
                        Share
                      </LinkedinShareButton>
                    </div>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex space-x-3">
                      <RedditIcon size={28} round />
                      <p className="text-darkNight dark:text-frescoWhite flex items-center">
                        Reddit
                      </p>
                    </div>
                    <div>
                      <RedditShareButton
                        url={url}
                        title={title}
                        resetButtonStyle={false}
                        className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                      >
                        Share
                      </RedditShareButton>
                    </div>
                  </div>

                  {checkIsDesoUser(auth.user) && (
                    <div className="flex justify-between items-center py-2">
                      <div className="flex space-x-3">
                        <span className="bg-white rounded-full p-1">
                          <DeSo ratio={20} />
                        </span>
                        <p className="text-darkNight dark:text-frescoWhite flex items-center">
                          Deso
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={handleDesoShare}
                          className="bg-secondary text-navyGray px-3 py-1 rounded-full"
                        >
                          Share
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
