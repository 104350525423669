import React from "react";

function Pin() {
  return (
    <svg
      width="28"
      height="28"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#FFCC00" />
      <path
        d="M9 15.7857C9 16.0603 9.08585 16.2801 9.25756 16.4453C9.42927 16.6105 9.65893 16.6931 9.94655 16.6931H13.4301V19.6982C13.4301 19.9427 13.4516 20.1744 13.4945 20.3932C13.5375 20.612 13.5911 20.8039 13.6555 20.9691C13.7199 21.1343 13.7832 21.264 13.8455 21.3584C13.9077 21.4528 13.9603 21.5 14.0032 21.5C14.0419 21.5 14.0923 21.4528 14.1545 21.3584C14.2168 21.264 14.2801 21.1343 14.3445 20.9691C14.4089 20.8039 14.4625 20.612 14.5055 20.3932C14.5484 20.1744 14.5699 19.9427 14.5699 19.6982V16.6931H18.0534C18.3454 16.6931 18.5761 16.6105 18.7457 16.4453C18.9152 16.2801 19 16.0603 19 15.7857C19 15.331 18.8841 14.8816 18.6523 14.4376C18.4205 13.9936 18.0878 13.5871 17.6542 13.2181C17.2207 12.8492 16.7034 12.5553 16.1024 12.3365L15.8899 9.38288C16.2033 9.2027 16.4941 9.02038 16.7624 8.83591C17.0307 8.65144 17.2657 8.47125 17.4675 8.29536C17.6693 8.11947 17.8259 7.9586 17.9375 7.81274C18.105 7.59824 18.1887 7.38803 18.1887 7.18211C18.1887 6.98477 18.1189 6.82175 17.9794 6.69304C17.8399 6.56435 17.6585 6.5 17.4353 6.5H10.5711C10.3436 6.5 10.1612 6.56435 10.0238 6.69304C9.88645 6.82175 9.81776 6.98477 9.81776 7.18211C9.81776 7.38803 9.89933 7.59824 10.0625 7.81274C10.1741 7.9586 10.3308 8.11947 10.5325 8.29536C10.7343 8.47125 10.9693 8.65251 11.2376 8.83912C11.5059 9.02573 11.7967 9.20699 12.1101 9.38288L11.8976 12.3365C11.2966 12.5553 10.7793 12.8492 10.3458 13.2181C9.91221 13.5871 9.57952 13.9936 9.34771 14.4376C9.1159 14.8816 9 15.331 9 15.7857Z"
        fill="white"
      />
    </svg>
  );
}

export default Pin;
