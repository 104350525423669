import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import Loader from "../../VibehutUI/Loader";
import { getUserData } from "../../services/userService";
import { getUserPremiumActivity } from "../../actions/postActions";
import Avatar from "../../VibehutUI/Avatar";
import { renderPostText } from "../../utils/utils";
import moment from "moment";
import Button from "../../VibehutUI/Button";

function MySubscriptionActivity() {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth: { user },
    post: {
      premiumActivityPosts,
      isMore,
      premiumOffset,
      isPremiumFetching,
      isPremiumMoreFetching,
    },
  } = state;

  useEffect(() => {
    if (user.token) {
      getUserPremiumActivity(dispatch, user?.token, isMore, premiumOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    getUserPremiumActivity(dispatch, user?.token, isMore, premiumOffset);
  };

  const getPostImageSection = (post) => {
    if (post?.roomsMentioned && post.roomsMentioned[0]?.cover?.urls?.small) {
      return (
        <div className="h-10 w-10">
          <img
            src={post.roomsMentioned[0].cover.urls.small}
            alt="event"
            className="w-full h-full rounded"
          />
        </div>
      );
    }
    return (
      <Avatar
        src={getUserData(post.owner).profile_picture}
        size="sm"
        username={getUserData(post.owner).username}
      />
    );
  };

  const Post = ({ post, startTime }) => {
    return (
      <div className="relative mt-1" key={post._id}>
        <div className="flex items-center py-1 z-40">
          <div className="">
            <div className="pl-2">{getPostImageSection(post)}</div>
          </div>
          <div className="pl-5">
            <p className="text-sm font-normal dark:text-frescoWhite">
              <div className="text-gray-700 dark:text-gray-300">
                {renderPostText(post, false, false)}
              </div>
            </p>
            <p className="text-xs font-normal text-darkGray">
              {moment(startTime).fromNow()}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isPremiumFetching ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className="h-[45vh] custom-scrollbar overflow-auto">
          {premiumActivityPosts.length !== 0 ? (
            premiumActivityPosts.map((post, index) => {
              const startTime = post.createdAt;
              return <Post post={post} startTime={startTime} />;
            })
          ) : (
            <p className="text-sm font-normal text-center pt-12  text-darkGray">
              No Activity yet
            </p>
          )}
          {!isPremiumMoreFetching ? (
            isMore && (
              <div className="flex justify-center">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-primary"
                  onClick={handleLoadMore}
                >
                  Load More
                </Button>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      )}
    </div>
  );
}

export default MySubscriptionActivity;
