import React from 'react';

function Metamask({ ratio = 20, isGreyScaleActive = false }) {
  return (
    <img
      width={ratio}
      height={ratio}
      alt="metamaskIcon"
      style={{ height: ratio, width: ratio }}
      src={`${process.env.PUBLIC_URL}/metamask-fox.png`}
      className={`${isGreyScaleActive && `custom-grayscale`}`}
    ></img>
  );
}

export default Metamask;
