import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Button from "../Button";

import { StoreContext } from "../../Context/StoreContext";

import moment from "moment";

import { addMoney, eventTransaction } from "../../services/paymentService";
import globals from "../../globals";
import { closeEventSubscriptionModal } from "../../actions/eventModalActions";
import { showToast } from "../../actions/toastActions";

export default function EventSubscriptionModal() {
  const { state, dispatch } = useContext(StoreContext);
  const { auth, eventSubscriptionModal } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  const { user } = auth;

  const handleSubscription = async () => {
    setIsLoading(true);
    const purchaseAmount = eventSubscriptionModal?.selectedEvent.price;
    const subriberslist =
      eventSubscriptionModal?.selectedEvent.eventSubscriptions;
    const maxSubscribers =
      eventSubscriptionModal?.selectedEvent.room.totalLiveCalls;
    if (subriberslist.length < maxSubscribers - 1) {
      const apicall = eventTransaction(
        auth.user.token,
        purchaseAmount,
        eventSubscriptionModal?.selectedEvent._id,
        eventSubscriptionModal?.selectedEvent.type,
        eventSubscriptionModal?.selectedEvent.title,
        promoCode,
      );
      if (apicall === true) {
        setIsLoading(false);
        closeEventSubscriptionModal(dispatch);
      }
    } else {
      showToast(
        dispatch,
        "error",
        "Sorry, The event guest limit has been reached. Please try another event.",
        6000,
      );
      setIsLoading(false);
      closeEventSubscriptionModal(dispatch);
    }
  };

  return (
    <>
      <Transition
        appear
        show={eventSubscriptionModal.showEventSubscriptionModal}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => closeEventSubscriptionModal(dispatch)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-navyGray shadow-xl rounded-2xl">
                <div className="flex flex-row justify-between px-6 py-3">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-black dark:text-frescoWhite"
                  >
                    Paid Event
                  </Dialog.Title>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => closeEventSubscriptionModal(dispatch)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
                <hr className="" />
                <div className="px-6 py-3">
                  <div className=" font-medium">
                    <p className="text-sm text-black dark:text-frescoWhite break-words">
                      {eventSubscriptionModal?.message}
                    </p>
                  </div>

                  <div className="pt-2">
                    <input
                      type="text"
                      className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                      value={promoCode || ""}
                      name="promo"
                      onChange={event => {
                        setPromoCode(event.target.value);
                      }}
                      placeholder="Enter Promo Code (Optional)"
                    />
                  </div>

                  <div className=" flex flex-row-reverse pt-3">
                    <Button
                      size="vibhut-sm"
                      disabled={isLoading}
                      variant="vibhut-primary"
                      onClick={() => handleSubscription()}
                    >
                      {isLoading ? "Loading..." : "Checkout Now"}
                    </Button>

                    <div className="mr-3">
                      <Button
                        size="vibhut-sm"
                        variant="vibhut-secondary"
                        onClick={() => closeEventSubscriptionModal(dispatch)}
                      >
                        Go Back
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
