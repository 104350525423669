import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import PinIcon from "./PinIcon/PinIcon";
import ScreenShareIcon from "../../icons/ScreenShareIcon";
import Typography from "@material-ui/core/Typography";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { useAppState } from "../../state";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import { getUserData } from "../../utils/vibehutUtils";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import Avatar from "../../VibehutUI/Avatar";
import useFullScreen from "../../hooks/useFullScreen/useFullScreen";
import { getUserPublicProfile } from "../../services/userService";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      isolation: "isolate",
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: 0,
      overflow: "hidden",
      marginBottom: "0.5em",
      borderRadius: "4px",
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      paddingTop: `calc(${(9 / 16) * 100}% - ${
        theme.participantBorderWidth
      }px)`,
      background: "black",
      [theme.breakpoints.down("sm")]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: "8px",
        marginBottom: "0",
        fontSize: "12px",
        paddingTop: `${theme.sidebarMobileHeight - 2}px`,
      },
    },
    innerContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    infoContainer: {
      position: "absolute",
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
      background: "transparent",
      top: 0,
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "black",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        "& svg": {
          transform: "scale(0.7)",
        },
      },
    },
    reconnectingContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(40, 42, 43, 0.75)",
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: "rgba(0, 0, 0, 0.5)",
      padding: "0.18em 0.3em",
      marginRight: "0.3em",
      display: "flex",
      "& path": {
        fill: "white",
      },
    },
    identity: {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "0.18em 0.3em 0.18em 0",
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    infoRowBottom: {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    typography: {
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    hideParticipant: {
      display: "none",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    galleryView: {
      border: `${theme.participantBorderWidth}px solid ${theme.galleryViewBackgroundColor}`,
      borderRadius: "12px",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        width: "100%",
        height: "100%",
        padding: "0",
        fontSize: "12px",
        margin: "0",
      },
    },
    dominantSpeaker: {
      background:
        "linear-gradient(to right, #18181b, #18181b), linear-gradient(to bottom right, #60a5fa, #4ade80)",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border: `4px solid transparent`,
    },
  }),
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantInfoProps) {
  const [user, setUser] = useState<any>(null);
  const { authUser } = useAuthUser();
  const { roomData } = useVibehutData(globals.roomId);
  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoContext();
  const publications = usePublications(participant);
  const fullScreenRef = useRef<HTMLDivElement>(null);
  useFullScreen<HTMLDivElement>(fullScreenRef);
  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find(
    (p) => p.kind === "video" && p.trackName !== "screen",
  );
  const localParticipant = room!.localParticipant;
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find((p) =>
    p.trackName.includes("screen"),
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );

  const isScreenShareParticipantIsLocal =
    localParticipant === screenShareParticipant;

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const { isGalleryViewActive } = useAppState();

  const classes = useStyles();

  useEffect(() => {
    const getUser = async () => {
      const vibehutUserRes = await getUserPublicProfile(participant.identity);
      const vibehutUser = vibehutUserRes.data;
      const _user = getUserData(vibehutUser);
      setUser(_user);
    };

    getUser();
  }, []);

  const username =
    user?.username ||
    roomData.liveUsers.find((p_user) => p_user._id === participant.identity)
      ?.username ||
    participant.identity;
  const profile_picture =
    user?.profile_picture ||
    roomData.liveUsers.find((p_user) => p_user._id === participant.identity)
      ?.profile_picture ||
    participant.identity;

  const authUserData = getUserData(authUser);
  return (
    <div
      ref={fullScreenRef}
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
        [classes.dominantSpeaker]: isDominantSpeaker,
        [classes.galleryView]: isGalleryViewActive,
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <div className="flex justify-between ">
          <NetworkQualityLevel participant={participant} />
          <span className="p-2">
            <AudioLevelIndicator audioTrack={audioTrack} />
          </span>
        </div>

        <div className={classes.infoRowBottom}>
          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            <Typography
              variant="body1"
              className={`${classes.typography} pl-2`}
              component="span"
            >
              {isLocalParticipant ? authUserData.username : username}
              {isLocalParticipant && " (You)"}
            </Typography>
          </span>
        </div>
        <div>{isSelected && <PinIcon />}</div>
      </div>
      <div className={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={classes.avatarContainer}>
            <Avatar
              src={
                isLocalParticipant
                  ? authUserData.profile_picture
                  : profile_picture
              }
              username={isLocalParticipant ? authUserData.username : username}
            />
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
