import axios from "../utils/axios";

export function createEventApproval(data, token) {
  return axios
    .post("/eventApproval", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

export function updateEventApprovalStatus(data, token, eventApprovalId) {
  return axios
    .patch(`/eventApproval/${eventApprovalId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

export function getAllApprovalRequestsOfEvent(token, eventId, status) {
  const endPoint = status
    ? `/eventApproval/${eventId}/${status}`
    : `/eventApproval/${eventId}`;
  return axios
    .get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

export function getApprovalRequestsOfUserByEvent(token, eventId) {
  return axios
    .get(`/eventApproval/user/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteApprovalRequest(token, id) {
  return axios
    .delete(`/eventApproval/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}
