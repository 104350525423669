import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";

function ConfirmationModal({
  openModal,
  setOpenModal,
  primaryAction,
  secondaryAction,
  title,
  text,
  primaryButtonText='',
  secondaryButtonText='',
  description,
  showLearnMore = false,
  radioBoxData = [],
}) {
  const cancelButtonRef = useRef(null);
  const [radioIndex, setRadioIndex] = React.useState(
    radioBoxData?.length ? 1 : null
  );
  return (
    <Transition.Root
      show={openModal}
      as={Fragment}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpenModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white dark:bg-navyGray
                rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu`}
            >
              <div
                className={`bg-white  dark:bg-navyGray dark:border-matteGray
                 p-3 border-b border-frescoWhite `}
              >
                <div className="flex justify-between items-center">
                  <p
                    className={`font-medium text-lg  text-black dark:text-frescoWhite bg-white  dark:bg-navyGray`}
                  >
                    {title}
                  </p>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div
                className={`bg-white text-gray-600 dark:bg-navyGray dark:text-gray-300 p-4 relative`}
              >
                {ReactHtmlParser(text)}
                {radioBoxData?.length > 0 && (
                  <div className="flex flex-col pt-3 space-y-1">
                    {radioBoxData?.map((item, index) => {
                      return (
                        <label>
                          <input
                            type="radio"
                            name="roomTypes"
                            className="appearance-none h-3 w-3 border-primaryGray rounded-full transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                            onClick={() => {
                              setRadioIndex(index + 1);
                            }}
                            defaultChecked={index === 0}
                          />
                          <p className="text-sm">{item}</p>
                        </label>
                      );
                    })}
                  </div>
                )}
                <div className="pt-4 text-darkGray">{description}</div>
                {showLearnMore && (
                  <div
                    className="underline text-sm text-darkGray cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://vibehut.notion.site/About-Vibehut-Plans-bd5b63080e3f4e9695a6407332f5df14",
                        "_blank"
                      );
                    }}
                  >
                    Learn More
                  </div>
                )}
              </div>

              <div className=" px-4 py-3 flex flex-row-reverse ">
              {primaryButtonText && 
                <Button
                  size="vibhut-sm"
                  variant="vibhut-primary"
                  onClick={() => {
                    primaryAction(radioBoxData?.length ? radioIndex : null);
                  }}
                >
                  {primaryButtonText}
                </Button>
              }  
              {secondaryButtonText && 
                <div className="mr-3">
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      secondaryAction();
                    }}
                  >
                    {secondaryButtonText}
                  </Button>
                </div>
              }
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ConfirmationModal;
