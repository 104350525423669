import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";
import ConfirmationModal from "../../VibehutUI/ConfirmationModal";
import { openPlanModal } from "../../actions/profileModalActions";
import PremiumWrapper from "../PremiumWrapper";
import axios from "../../utils/axios";
import { profileRoute } from "../../routes";
import Avatar from "../../VibehutUI/Avatar";
import UserCard from "../../VibehutUI/UserCard";
import { getUserData } from "../../services/userService";
import Button from "../../VibehutUI/Button";
import Loader from "../../VibehutUI/Loader";
import { showToast } from "../../actions/toastActions";
import globals from "../../globals";
import SubscriberApprovalSubmissionsModal from "../../VibehutUI/Notification/SubscriberApprovalSubmissionsModal";

const approvalOptions = ["Approved", "Declined"];

const SubscriberTable = ({ subscriberList, room }) => {
  const { state, dispatch } = useContext(StoreContext);
  const [roomState, setRoomState] = useState(room);
  const [modalValues, setModalValues] = useState({
    title: "",
    text: "",
    description: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [subscriberArrayList, setSubscriberList] = useState(subscriberList);
  const [isApprovingAll, setIsApprovingAll] = useState(false);
  const [isDecliningAll, setIsDecliningAll] = useState(false);
  const {
    auth: { user },
  } = state;

  const updateSelectedlabel = (e, index) => {
    setSubscriberList((prevState) => {
      const newState = prevState.map((obj, postion) => {
        if (postion === index) {
          obj.labels.map((labelData, labelIndex) => {
            if (labelData._id === e.target.value) {
              labelData.isSelected = true;
            } else {
              labelData.isSelected = false;
            }
          });
        }

        return obj;
      });
      return newState;
    });
    if (e.target.value === "") {
      return;
    }
    axios
      .post(
        `/rooms/set-room-label`,
        {
          roomId: roomState._id,
          userId: subscriberArrayList[index].userId,
          labelId: e.target.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      .then((res) => {})
      .catch(function (error) {
        //handle error
        console.log(error);
      });
  };

  const updateRole = (e, index) => {
    setSubscriberList((prevState) => {
      const newState = prevState.map((obj, postion) => {
        if (postion === index) {
          if (e.target.checked) {
            obj["role"] = "Moderator";
          } else {
            obj["role"] = "Member";
          }
        }

        return obj;
      });
      return newState;
    });

    axios
      .post(
        `/rooms/set-room-moderators`,
        {
          roomId: roomState._id,
          ownerId: subscriberArrayList[index].userId,
          role: e.target.checked ? "Moderator" : "Member",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          setRoomState({
            ...roomState,
            moderators: res.data.data.moderators,
          });
        }
      })
      .catch(function (error) {
        //handle error
        console.log(error);
      });
  };

  const updateSubscriberApproved = (e, index) => {
    if (!e.target.value) {
      return;
    }
    setSubscriberList((prevState) => {
      const newState = prevState.map((obj, postion) => {
        if (postion === index) {
          if (e.target.value === "Approved") {
            obj["subscriberApproved"] = true;
          } else {
            obj["subscriberApproved"] = false;
          }
          obj["roomSubscribeStatus"] = e.target.value;
        }

        return obj;
      });
      return newState;
    });

    axios
      .post(
        `/rooms/set-subscriber-approval`,
        {
          roomId: roomState._id,
          ownerId: subscriberArrayList[index].userId,
          subscriberApproved: e.target.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      .then((res) => {})
      .catch(function (error) {
        console.log(error);
      });
  };
  const updateAllSubscriberApproved = (status) => {
    if (!status) {
      return;
    }
    const isAnyPendingUser = subscriberArrayList.find(
      (user) => user?.roomSubscribeStatus === "Pending",
    );
    if (!isAnyPendingUser) {
      showToast(dispatch, "info", "No pending requests available", 2000);
      return;
    }
    if (status === "Approved") {
      setIsApprovingAll(true);
    }
    if (status === "Declined") {
      setIsDecliningAll(true);
    }

    axios
      .post(
        `/rooms/set-all-subscriber-approval`,
        {
          roomId: roomState._id,
          subscriberApproved: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      .then(() => {
        setSubscriberList((prevState) => {
          const newState = prevState.map((obj) => {
            if (
              status === "Approved" &&
              obj["roomSubscribeStatus"] === "Pending"
            ) {
              obj["subscriberApproved"] = true;
              obj["roomSubscribeStatus"] = status;
            } else if (
              status === "Declined" &&
              obj["roomSubscribeStatus"] === "Pending"
            ) {
              obj["subscriberApproved"] = false;
              obj["roomSubscribeStatus"] = status;
            }

            return obj;
          });
          return newState;
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setIsApprovingAll(false);
        setIsDecliningAll(false);
      });
  };

  const updateBlockUsers = (e, index) => {
    setSubscriberList((prevState) => {
      const newState = prevState.map((obj, postion) => {
        if (postion === index) {
          if (e.target.checked) {
            obj["blocked"] = true;
          } else {
            obj["blocked"] = false;
          }
        }

        return obj;
      });
      return newState;
    });

    axios
      .post(
        `/rooms/update-block-users`,
        {
          roomId: roomState._id,
          ownerId: subscriberArrayList[index].userId,
          blocked: e.target.checked,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      .then((res) => {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const showApprovalListLabel = useMemo(() => {
    return (
      roomState?.isSubscriberRequireApproval &&
      (roomState?.owner?._id === user?._id ||
        roomState?.moderators?.some(
          (moderatorId) => moderatorId === user?._id,
        ) ||
        roomState?.subscriberApprovedUsers?.some(
          (subscriber) => subscriber.toString() === user?._id.toString(),
        ))
    );
  }, [
    roomState?.isSubscriberRequireApproval,
    roomState?.owner?._id,
    roomState?.moderators,
    roomState?.subscriberApprovedUsers,
    user?._id,
  ]);

  const showApprovalListDropdown = useMemo(() => {
    return (
      roomState?.isSubscriberRequireApproval &&
      (roomState?.owner?._id === user?._id ||
        roomState?.moderators?.some((moderatorId) => moderatorId === user?._id))
    );
  }, [
    roomState?.isSubscriberRequireApproval,
    roomState?.owner?._id,
    roomState?.moderators,
    user?._id,
  ]);

  const showSubmissions = (userId, roomId) => {
    globals.showModal(
      "Submission",
      <SubscriberApprovalSubmissionsModal
        userId={userId}
        roomId={roomId}
        approvalQuestions={room.approvalQuestions}
      />,
    );
  };

  const approvalColumn = useCallback(
    (selectedApprovalStatus, data, index) => {
      if (selectedApprovalStatus === "Invited") {
        return selectedApprovalStatus;
      }
      if (roomState?.owner?._id !== data?.userId) {
        return (
          <div className="flex items-center gap-2">
            <select
              name=""
              id=""
              className="w-fit py-2  border border-frescoWhite rounded-2xl focus:ring-0 outline-none text-darkNight text-sm"
              onChange={(e) => {
                updateSubscriberApproved(e, index);
              }}
              disabled={isApprovingAll || isDecliningAll}
            >
              <option value="">Select</option>
              {approvalOptions.map((option) => (
                <option
                  value={option}
                  key={option}
                  selected={selectedApprovalStatus === option}
                >
                  {option}
                </option>
              ))}
            </select>
            {room?.isAnswersRequireForApproval && (
              <Button onClick={() => showSubmissions(data?.userId, room._id)}>
                View Submission
              </Button>
            )}
          </div>
        );
      }
      return "Approved";
    },
    [roomState?.owner?._id],
  );

  if (!roomState) {
    return <></>;
  }

  return (
    <>
      <div className="flex items-center justify-between w-full">
        {room?.owner ? (
          <div className="flex pt-3 pb-6">
            <UserCard user={getUserData(room?.owner)} subtitle="Creator" />
          </div>
        ) : undefined}
        {showApprovalListDropdown && (
          <div className="flex gap-2">
            {isApprovingAll ? (
              <Loader />
            ) : (
              <Button
                disabled={isApprovingAll}
                onClick={() => updateAllSubscriberApproved("Approved")}
              >
                Approve All
              </Button>
            )}
            {isDecliningAll ? (
              <Loader />
            ) : (
              <Button
                onClick={() => updateAllSubscriberApproved("Declined")}
                variant="danger"
              >
                Decline All
              </Button>
            )}
          </div>
        )}
      </div>
      <div
        className={`flex flex-col justify-start items-start  overflow-x-auto custom-scrollbar`}
      >
        <table className=" table-auto border dark:border-matteGray">
          <thead className="border-b dark:bg-darkNight dark:border-matteGray border-lightGray dark:hover:bg-navyGray ">
            <tr className="text-white dark:text-frescoWhite">
              <th
                scope="col"
                className="text-sm font-medium text-gray-900  dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
              >
                S.N
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900  dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
              >
                Name
              </th>

              {roomState?.owner?._id === user?._id && user?._id && (
                <>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                  >
                    Email
                  </th>

                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                  >
                    Moderator
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                  >
                    Blocked
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                  >
                    Labels
                  </th>
                </>
              )}
              {showApprovalListLabel && (
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                >
                  Approved
                </th>
              )}
              {roomState?.owner?._id === user?._id && user?._id && (
                <>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                  >
                    Last Seen
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900   dark:text-frescoWhite px-6 py-4 text-left border-r dark:border-matteGray"
                  >
                    Subscribe Date
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {subscriberArrayList.map((data, index) => {
              const selectedApprovalStatus = data.roomSubscribeStatus;
              return (
                <tr
                  key={index}
                  className=" border-b dark:border-matteGray transition duration-300 ease-in-out hover:bg-gray-100  dark:hover:bg-navyGray"
                >
                  <td className="px-6 py-4 text-black dark:text-frescoWhite whitespace-nowrap text-sm font-medium  border-r dark:border-matteGray">
                    {index + 1}
                  </td>
                  <td class="text-sm text-primary cursor-pointer font-light px-6 py-4 whitespace-nowrap border-r dark:border-matteGray flex space-x-2  items-center">
                    <Avatar
                      src={data.profile_picture}
                      size="sm"
                      username={data.username}
                    />
                    <Link
                      to={profileRoute.path.replace(
                        ":username",
                        data?.vibehutUsername || data.userId,
                      )}
                    >
                      {data?.username?.length > 15
                        ? data.username.slice(0, 15) + "..."
                        : data.username}
                    </Link>
                  </td>

                  {roomState?.owner?._id === user?._id && user?._id && (
                    <>
                      <PremiumWrapper
                        permission={["roomEmail:read"]}
                        fallbackComponent={
                          <td
                            onClick={() => {
                              setModalValues({
                                title: "Upgrade to Premium",
                                text: "Viewing room email addresses is a premium feature.",
                                description:
                                  "Upgrade to premium to access this feature.",
                              });
                              setOpenModal(true);
                            }}
                            class="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap border-r dark:border-matteGray cursor-pointer"
                          >
                            &#8226; &#8226; &#8226; &#8226;
                          </td>
                        }
                      >
                        <td class="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap border-r dark:border-matteGray">
                          {data?.email?.length > 0 ? data.email : "-"}
                        </td>
                      </PremiumWrapper>

                      <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                        <PremiumWrapper
                          permission={["roomRoles:write"]}
                          fallbackComponent={
                            <input
                              type="checkbox"
                              name="moderator"
                              className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                              checked={data.role === "Moderator" ? true : false}
                              value={data.role === "Moderator" ? true : false}
                              onChange={(e) => {
                                if (data.role === "Moderator") {
                                  updateRole(e, index);
                                } else if (roomState?.moderators.length < 2) {
                                  updateRole(e, index);
                                } else {
                                  setModalValues({
                                    title: "Upgrade to Premium",
                                    text: "Upgrade to premium for more than 2 moderators and additional benefits.",
                                    description:
                                      "Upgrade to premium to access this feature.",
                                  });
                                  setOpenModal(true);
                                }
                              }}
                            />
                          }
                        >
                          <input
                            type="checkbox"
                            name="moderator"
                            className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                            checked={data.role === "Moderator" ? true : false}
                            value={data.role === "Moderator" ? true : false}
                            onChange={(e) => updateRole(e, index)}
                          />
                        </PremiumWrapper>
                      </td>
                      <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                        <input
                          type="checkbox"
                          name="block"
                          className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                          checked={data.blocked || false}
                          value={data.blocked || false}
                          onChange={(e) => updateBlockUsers(e, index)}
                        />
                      </td>
                      <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                        {data.labels && data.labels.length > 0 && (
                          <select
                            name=""
                            id=""
                            className="w-fit py-2  border border-frescoWhite rounded-2xl focus:ring-0 outline-none text-darkNight text-sm"
                            onChange={(e) => {
                              updateSelectedlabel(e, index);
                            }}
                          >
                            <option value="">Select Option</option>
                            {data.labels.map((labelData) => (
                              <option
                                value={labelData._id}
                                key={labelData._id}
                                selected={labelData.isSelected || false}
                              >
                                {labelData.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    </>
                  )}
                  {showApprovalListDropdown ? (
                    <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                      {approvalColumn(selectedApprovalStatus, data, index)}
                    </td>
                  ) : (
                    <>
                      {showApprovalListLabel && (
                        <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                          {roomState?.owner?._id !== data?.userId
                            ? selectedApprovalStatus || "Pending"
                            : "Approved"}
                        </td>
                      )}{" "}
                    </>
                  )}
                  {roomState?.owner?._id === user?._id && user?._id && (
                    <>
                      <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                        {data.lastSeen ? moment(data.lastSeen).fromNow() : "-"}
                      </td>
                      <td className="text-sm text-black dark:text-frescoWhite font-light px-6 py-4 whitespace-nowrap  border-r dark:border-matteGray">
                        {data.subscribeDate
                          ? moment(data.subscribeDate).format("MMMM Do YYYY")
                          : "-"}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ConfirmationModal
          title={modalValues.title}
          text={modalValues.text}
          description={modalValues.description}
          openModal={openModal}
          setOpenModal={setOpenModal}
          primaryButtonText="Upgrade"
          primaryAction={() => {
            setOpenModal(false);
            openPlanModal(dispatch);
          }}
          secondaryButtonText={"Cancel"}
          secondaryAction={() => {
            setOpenModal(false);
          }}
        />
      </div>
    </>
  );
};

export default SubscriberTable;
