import * as profileTypes from "../constants";

export const openPlanModal = (dispatch) => {
  dispatch({
    type: profileTypes.OPEN_PLAN_MODAL,
    payload: profileTypes.PLAN,
  });
};

export const closeModal = (dispatch) => {
  dispatch({
    type: profileTypes.CLOSE_MODAL,
    payload: profileTypes.CLOSE_MODAL,
  });
};

export const openNotificationModal = (dispatch) => {
  dispatch({
    type: profileTypes.OPEN_NOTIFICATION_MODAL,
    payload: profileTypes.NOTIFICATION,
  });
};

export const openContactModal = (dispatch) => {
  dispatch({
    type: profileTypes.OPEN_CONTACT_MODAL,
    payload: profileTypes.CONTACT,
  });
};

export const openWalletModal = (dispatch, isTopUpActive = false) => {
  dispatch({
    type: profileTypes.OPEN_WALLET_MODAL,
    payload: {
      model: profileTypes.WALLET,
      isTopUpActive, // isTopActive is used to set the active tab in the add vibebucks section of wallet model
    },
  });
};

export const openAppSettingModal = (dispatch) => {
  dispatch({
    type: profileTypes.OPEN_APP_SETTING_MODAL,
    payload: profileTypes.APPSETTING,
  });
};

export const openGamificationModal = (dispatch) => {
  dispatch({
    type: profileTypes.OPEN_GAMIFICATION_MODAL,
    payload: profileTypes.GAMIFICATION,
  });
};
