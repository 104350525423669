import * as actionTypes from "../constants";
import * as publishStoreService from "../services/publishStore";

export function publishVideo(data, token, dispatch) {
  dispatch({
    type: actionTypes.PUBLISH_VIDEO_REQUEST,
    payload: data.callId,
  });
  return publishStoreService
    .publishVideo(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.PUBLISH_VIDEO_STATUS,
        payload: {
          isPublished: true,
          video: res.data.video,
        },
      });
      dispatch({
        type: actionTypes.UPDATE_VIDEO_STATUS_SUCCESS,
      });
      return res.data.video;
    })
    .catch((err) => {
      throw err;
    });
}

export function updatePublishedVideo(videoId, data, token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_VIDEO_REQUEST,
  });
  return publishStoreService
    .updatePublishedVideo(videoId, data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_VIDEO_SUCCESS,
        payload: {
          video: res.data.video,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getPublishVideoes(token, dispatch, videoTypes, page = 0) {
  dispatch({
    type: actionTypes.GET_ALL_PUBLISH_VIDEO_REQUEST,
  });
  return publishStoreService
    .getPublishVideoes(token, videoTypes, page)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ALL_PUBLISH_VIDEO_SUCCESS,
        payload: { count: res.data.count, page, data: res.data.videoes },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getPendingApprovalVideos(token, dispatch) {
  dispatch({
    type: actionTypes.GET_ALL_PUBLISH_VIDEO_REQUEST,
  });
  return publishStoreService
    .getPendingApprovalVideos(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ALL_PUBLISH_VIDEO_SUCCESS,
        payload: { count: res.data.count, page: 1, data: res.data.videoes },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getMyPublishVideoes(token, dispatch) {
  dispatch({
    type: actionTypes.GET_MY_PUBLISH_VIDEO_REQUEST,
  });
  return publishStoreService
    .getMyPublishVideoes(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_PUBLISH_VIDEO_SUCCESS,
        payload: res.data.videoes,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function updateVideoVisibility(data, token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_VIDEO_STATUS_REQUEST,
    payload: data.callId,
  });
  return publishStoreService
    .updateVideoVisibility(
      { videoId: data.videoId, visibility: data.visibility },
      token,
    )
    .then((res) => {
      dispatch({
        type: actionTypes.PUBLISH_VIDEO_STATUS,
        payload: {
          isPublished: true,
          video: res.data.video,
        },
      });
      dispatch({
        type: actionTypes.UPDATE_VIDEO_STATUS_SUCCESS,
      });
      return res.data.video;
    })
    .catch((err) => {
      throw err;
    });
}

export function getMyExternalVideosCount(token, dispatch) {
  dispatch({
    type: actionTypes.GET_MY_EXTERNAL_VIDEOS_COUNT_REQUEST,
  });
  return publishStoreService
    .getMyExternalVideosCount(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_EXTERNAL_VIDEOS_COUNT_SUCCESS,
        payload: {
          myVideosCount: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_EXTERNAL_VIDEOS_COUNT_FAILURE,
      });
      throw err;
    });
}
