import React, { useEffect, useRef, useState } from "react";
import SendActive from "../Icons/SendActive";
import SendInActive from "../Icons/SendInActive";
import TextareaAutosize from "react-textarea-autosize";
import { getUserData } from "../../services/userService";

function MessageComposer({ onSubmit, maxRows = 3, roomId = null, value = "" }) {
  const [text, setText] = useState(value);
  const textareaRef = useRef(null);
  const handleChange = (e) => {
    setText(e.target.value);
  };
  const [userMentions, setUserMentions] = useState([]);
  const getText = () => {
    let result = text;
    if (userMentions.length > 0) {
      const regex = /@(\w+)/g;
      result = text.replace(regex, (match, name) => {
        const obj = userMentions.find(
          (o) => `@${getUserData(o).username}` === `@${name}`
        );
        return obj ? `$(_user:${obj._id})` : match;
      });
    }
    return result;
  };

  const handleClick = () => {
    const result = getText();

    if (result.trim() === "") {
      return;
    }
    const filteredUserMentions = removeUser(result);
    onSubmit(result, filteredUserMentions);
    setText("");
    setUserMentions([]);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.selectionStart = value.length;
      textareaRef.current.selectionEnd = value.length;
    }
  }, [value]);

  const removeUser = (st) => {
    const array = userMentions;
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (!st.includes(obj._id)) {
        array.splice(i, 1);
        i--;
      }
    }
    return array;
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const result = getText();
      if (result.trim() === "") {
        return;
      }

      const filteredUserMentions = removeUser(result);
      onSubmit(result, filteredUserMentions);
      setText("");
      setUserMentions([]);
    }
  };

  return (
    <div className="relative  w-full">
      <TextareaAutosize
        onChange={handleChange}
        className={`py-1.5 ${
          roomId
            ? "border dark:border-0 text-matteGray dark:bg-matteGray dark:text-white border-lightGray"
            : "bg-matteGray text-white"
        }   pr-8 pl-4 custom-scrollbar rounded-xl w-full placeholder-primaryGray outline-none resize-none overflow-auto`}
        placeholder="Type a message..."
        maxRows={maxRows}
        value={text}
        onKeyPress={handleKeyPress}
        ref={textareaRef}
      />
      <button
        type="button"
        disabled={!text.length || text.trim() === ""}
        className="absolute right-3 bottom-3"
        onClick={handleClick}
      >
        {text.length && text.trim() !== "" ? (
          <SendActive />
        ) : (
          <SendInActive roomId={roomId} />
        )}
      </button>
    </div>
  );
}

export default MessageComposer;
