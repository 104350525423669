import React from "react";

const VisEye = () => {
  return (
    <svg
      width="20"
      height="20"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 12.0001C17.5 15.5001 13.9101 16.6748 11.8 16.7093C4.92729 16.7093 1 8.85467 1 8.85467C2.22128 6.57871 3.91516 4.59023 5.96802 3.02265M6 3.00007C8.5 1.00012 11.106 0.99846 11.8 1.00009C18.6728 1.00009 22.6001 8.85467 22.6001 8.85467C22.0041 9.96964 21.2933 11.0193 20.4794 11.9867"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="9"
        r="3.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.66667"
      />
    </svg>
  );
};

export default VisEye;
