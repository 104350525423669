import * as actionTypes from "../constants";

const initialState = {
  isFetching: false,
  isCompleted: false,
};

function referralReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_REFERRAL_REQUEST:
    case actionTypes.GET_REFERRAL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.CREATE_REFERRAL_SUCCESS:
    case actionTypes.GET_REFERRAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isCompleted: true,
      };
    case actionTypes.CREATE_REFERRAL_FAILURE:
    case actionTypes.GET_REFERRAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        isCompleted: false,
      };

    default:
      return state;
  }
}

export default referralReducer;
