import axios from "../utils/axios";

export function getCompositionViewUrl(sid, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/compositions/${sid}/view`, {
        Authorization: `Bearer ${token}`,
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteUsersToRoomCall(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/invite-users", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCompositionDownloadUrl(sid, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/compositions/${sid}/download`, {
        Authorization: `Bearer ${token}`,
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createHorizontalComposition(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/calls/compositions/desktop-horizontal`,
        {
          callId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createVerticalComposition(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/calls/compositions/mobile-vertical`,
        {
          callId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createAudioComposition(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/calls/compositions/audio`,
        {
          callId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createAudioTranscript(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/calls/audio-transcript`,
        {
          callId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCallById(token, callId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/get-single-call/${callId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRawCallRecordings(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/${callId}/recordings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function downloadRawCallRecording(callId, recordingSid, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/${callId}/recordings/${recordingSid}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCallUsers(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/${callId}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getLiveStream(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/calls/${callId}/mux/live-stream`,
        token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {},
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getShareImageUrl(params) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/media/share-image-url`, params, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyCallCount(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/get-my-call-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function initLiveStreamPayment(callId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/calls/${callId}/mux/live-stream/payment`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getLiveStreamViewers(callId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/calls/${callId}/mux/viewer`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateLiveStreamViewer(callId, token, status) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/calls/${callId}/mux/viewer`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function liveStreamCallReaction(callId, token, data) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/calls/${callId}/mux/live-stream/reaction`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
