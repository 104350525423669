import * as actionTypes from "../constants";
import { Partner } from "../types";

interface InitialState {
  partners: Partner[];
  offset: string | null | undefined;
  isFetching: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isDeleting: boolean;
}

const initialState: InitialState = {
  partners: [],
  offset: "",
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  isDeleting: false,
};

function partnerReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_PROMOTED_PARTNERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_PROMOTED_PARTNERS_SUCCESS: {
      return {
        ...state,
        partners: action.payload.data as Partner[],
        isFetching: false,
        offset: action.payload.offset,
      };
    }
    case actionTypes.GET_MORE_PROMOTED_PARTNERS:{
      return {
        ...state,
        partners: [...state.partners, ...action.payload.data],
        offset: action.payload.offset,
      };
    }
    case actionTypes.GET_PROMOTED_PARTNERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}

export default partnerReducer;
