import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <p className="text-sm text-darkNight pb-1 font-medium">Microphone</p>
      <>
        {audioInputDevices.length > 1 ? (
          <select
            name=""
            id=""
            className="border border-frescoWhite  focus:ring-0 outline-none text-darkNight text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-xl"
            onChange={e => replaceTrack(e.target.value as string)}
            value={localAudioInputDeviceId || ''}
          >
            {audioInputDevices.map(device => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        ) : (
          <p className="text-darkNight">{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</p>
        )}
      </>
    </div>
  );
}
