import React from "react";

const MyAccount = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
        fill="#34C759"
      />
      <path
        d="M18.5 12V11.2222C18.5 10.8981 18.3683 10.5872 18.1339 10.358C17.8995 10.1288 17.5815 10 17.25 10H14.75C14.4185 10 14.1005 10.1288 13.8661 10.358C13.6317 10.5872 13.5 10.8981 13.5 11.2222V12"
        fill="#34C759"
      />
      <g clipPath="url(#clip0_4886_67948)">
        <path
          d="M22.0001 10.6667H10.0001C9.2637 10.6667 8.66675 11.2636 8.66675 12V20C8.66675 20.7364 9.2637 21.3333 10.0001 21.3333H22.0001C22.7365 21.3333 23.3334 20.7364 23.3334 20V12C23.3334 11.2636 22.7365 10.6667 22.0001 10.6667Z"
          fill="white"
        />
        <path
          d="M8.66675 14.6667H23.3334"
          stroke="#34C759"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4886_67948">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MyAccount;
