import React from 'react';

function MicOn({ color = '#ffffff', ratio = '16' }) {
  return (
    <svg width={ratio} height={ratio} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8 1C7.46957 1 6.96086 1.20319 6.58579 1.56487C6.21071 1.92654 6 2.41708 6 2.92857V8.07143C6 8.58292 6.21071 9.07346 6.58579 9.43513C6.96086 9.79681 7.46957 10 8 10C8.53043 10 9.03914 9.79681 9.41421 9.43513C9.78929 9.07346 10 8.58292 10 8.07143V2.92857C10 2.41708 9.78929 1.92654 9.41421 1.56487C9.03914 1.20319 8.53043 1 8 1V1Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6667 6.66666V7.99999C12.6667 9.23767 12.175 10.4247 11.2999 11.2998C10.4247 12.175 9.23772 12.6667 8.00004 12.6667C6.76236 12.6667 5.57538 12.175 4.70021 11.2998C3.82504 10.4247 3.33337 9.23767 3.33337 7.99999V6.66666"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12.6667V15.3333"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33337 15.3333H10.6667"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicOn;
