import authReducer from "./authReducer";
import chatReducer from "./chatReducer";
import userReducer from "./userReducer";
import modalReducer from "./modalReducer";
import roomReducer from "./roomReducer";
import roleReducer from "./roleReducer";
import connectionReducer from "./connectionReducer";
import eventReducer from "./eventReducer";
import eventModalReducer from "./eventModalReducer";
import eventSubscriptionReducer from "./eventSubscriptionReducer";
import notificationReducer from "./notificationReducer";
import waveReducer from "./waveReducer";
import postReducer from "./postReducer";
import toastReducer from "./toastReducer";
import subscriptionReducer from "./subscriptionReducer";
import accessPassReducer from "./accessPassReducer";
import systemSettingReducer from "./systemSettingReducer";
import referralReducer from "./referralReducer";
import publishStoreReducer from "./publishStoreReducer";
import requestEventReducer from "./requestEventReducer";
import feedReducer from "./feedReducer";
import commentModalReducer from "./commentModalReducer";
import uiModalReducer from "./uiModalReducer";
import premiumCreatorReducer from "./premiumCreatorReducer";
import personaReducer from "./personaReducer";
import promotionReducer from "./promotionReducer";
import bookingTypeReducer from "./bookingTypeReducer";
import partnerReducer from "./partnerReducer";
import newsReducer from "./newsReducer";
import callReducer from "./callReducer";

const combineReducers = (slices) => (state, action) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state,
  );

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  chat: chatReducer,
  room: roomReducer,
  globalUiModal: uiModalReducer,
  globalToast: toastReducer,
  eventCreation: eventSubscriptionReducer,
  role: roleReducer,
  eventSubscriptionModal: eventModalReducer,
  accountSettingsModal: modalReducer,
  connection: connectionReducer,
  event: eventReducer,
  notification: notificationReducer,
  wave: waveReducer,
  post: postReducer,
  subscription: subscriptionReducer,
  publishStore: publishStoreReducer,
  accessPass: accessPassReducer,
  setting: systemSettingReducer,
  referral: referralReducer,
  requestEvent: requestEventReducer,
  feed: feedReducer,
  commentModal: commentModalReducer,
  premiumCreator: premiumCreatorReducer,
  persona: personaReducer,
  promotion: promotionReducer,
  partner: partnerReducer,
  news: newsReducer,
  calls: callReducer,
  bookingType: bookingTypeReducer,
});

export default rootReducer;
