import React from "react";

function UnlistedIcon() {
  return (
    <svg
      width="16"
      height="16"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2401 11.2404C10.3077 11.9512 9.17229 12.3449 8.00004 12.3641C4.18183 12.3641 2 8.0004 2 8.0004C2.67849 6.73597 3.61954 5.63126 4.76002 4.76038L11.2401 11.2404ZM6.85458 3.76764C7.23004 3.67976 7.61444 3.63583 8.00004 3.63673C11.8183 3.63673 14.0001 8.0004 14.0001 8.0004C13.669 8.61983 13.2741 9.20299 12.8219 9.74041L6.85458 3.76764ZM9.15642 9.15677C9.00661 9.31754 8.82595 9.4465 8.62522 9.53593C8.4245 9.62537 8.20781 9.67346 7.98809 9.67734C7.76838 9.68122 7.55013 9.6408 7.34637 9.5585C7.14261 9.4762 6.95752 9.3537 6.80213 9.19831C6.64675 9.04292 6.52425 8.85783 6.44195 8.65407C6.35965 8.45032 6.31923 8.23207 6.3231 8.01235C6.32698 7.79263 6.37507 7.57595 6.46451 7.37522C6.55395 7.17449 6.6829 6.99384 6.84367 6.84403"
        fill="#B5B5B5"
      />
      <path
        d="M11.2401 11.2404C10.3077 11.9512 9.17229 12.3449 8.00004 12.3641C4.18183 12.3641 2 8.0004 2 8.0004C2.67849 6.73597 3.61954 5.63126 4.76002 4.76038L11.2401 11.2404ZM6.85458 3.76764C7.23004 3.67976 7.61444 3.63583 8.00004 3.63673C11.8183 3.63673 14.0001 8.0004 14.0001 8.0004C13.669 8.61983 13.2741 9.20299 12.8219 9.74041L6.85458 3.76764ZM9.15642 9.15677C9.00661 9.31754 8.82595 9.4465 8.62522 9.53593C8.4245 9.62537 8.20781 9.67346 7.98809 9.67734C7.76838 9.68122 7.55013 9.6408 7.34637 9.5585C7.14261 9.4762 6.95752 9.3537 6.80213 9.19831C6.64675 9.04292 6.52425 8.85783 6.44195 8.65407C6.35965 8.45032 6.31923 8.23207 6.3231 8.01235C6.32698 7.79263 6.37507 7.57595 6.46451 7.37522C6.55395 7.17449 6.6829 6.99384 6.84367 6.84403L9.15642 9.15677Z"
        stroke="#B5B5B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2L14.0001 14.0001"
        stroke="#B5B5B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UnlistedIcon;
