import React from "react";
import { isMobile } from "../../utils/utils";
import Avatar from "../Avatar";

function DesoUserCard({
  user,
  userState,
  size = "xs",
  subtitle = "",
  isSidebarCard = false,
}) {
  return (
    <React.Fragment>
      <div className="px-2">
        <div
          className={`flex items-center space-x-3 cursor-pointer  px-2 py-1.5 rounded-xl`}
          onClick={() => {
            window.open(`https://diamondapp.com/u/${user.Username}`, "_blank");
          }}
        >
          <div className="relative">
            <Avatar
              username={user.Username}
              size={size}
              userState={userState}
              isDesoCard={true}
            />
          </div>

          <div className="md:w-[106px]">
            <p className=" flex flex-col overflow-hidden whitespace-nowrap">
              <span
                className={`${
                  user &&
                  user.Username &&
                  isSidebarCard &&
                  user.Username.length > 8 &&
                  `animatedText`
                } font-medium  text-matteGray dark:text-frescoWhite text-base `}
              >
                {isMobile()
                  ? user && user.Username
                    ? isSidebarCard && user.Username.length > 12
                      ? user.Username.slice(0, 12) + "..."
                      : user.Username
                    : ""
                  : user?.Username}
              </span>
            </p>
            {subtitle ? (
              <p className="text-xs   font-normal tracking-wide">{subtitle}</p>
            ) : undefined}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DesoUserCard;
