import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";
import axios from "../utils/axios";
import Loader from "../VibehutUI/Loader";

function VibebucksCallback(props) {
  const data = new URLSearchParams(props.location.search);
  const status = data.get("status");
  const { state, dispatch } = useContext(StoreContext);
  const { auth } = state;
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [handleRes, setHandleRes] = React.useState("");
  const updateAccountInfo = async () => {
    await axios
      .get("/stripe/checkAccConn", {
        headers: {
          Authorization: `Bearer ${auth.user.token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_ACCOUNT_CONNECTION_STATUS",
          payload: status,
        });
        history.push("/");
      });
  };

  React.useEffect(() => {
    updateAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="font-ubuntu">
      <div>
        <div>
          <React.Fragment>
            <div className="flex flex-col  items-center">
              <h2 className="text-2xl flex-grow font-bold text-black dark:text-frescoWhite items-center pt-24 pb-12">
                We are Verifying your Account Please Wait
              </h2>
              <Loader />
              <p className="text-2xl flex-grow font-bold text-black dark:text-frescoWhite items-center pt-24 pb-12">
                {handleRes}
              </p>
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

export default VibebucksCallback;
