import React from 'react';

function MicOff({ color = '#ffffff', ratio = '16' }) {
  return (
    <svg width={ratio} height={ratio} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M1.5 1L15 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10 6.36322V2.93046C10.0005 2.45221 9.81669 1.99084 9.48425 1.63591C9.15181 1.28099 8.69447 1.05783 8.201 1.00977C7.70753 0.961704 7.21314 1.09216 6.81381 1.37581C6.41448 1.65946 6.1387 2.07607 6.04 2.54476M6 6.14466V8.07318C6.00035 8.45436 6.11783 8.82689 6.33762 9.14372C6.55741 9.46055 6.86964 9.70747 7.23487 9.85329C7.60011 9.99912 8.00197 10.0373 8.3897 9.96304C8.77743 9.88876 9.13363 9.70536 9.41333 9.436L6 6.14466Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6667 6.66666V7.99999C12.6665 8.27495 12.6419 8.54935 12.5934 8.81999M11.3334 11.3C10.6826 11.9643 9.84813 12.419 8.93712 12.6057C8.0261 12.7925 7.08006 12.7028 6.22037 12.3482C5.36068 11.9936 4.62655 11.3902 4.11216 10.6154C3.59776 9.84068 3.32657 8.92992 3.33338 7.99999V6.66666L11.3334 11.3Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12.6667V15.3333"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33337 15.3333H10.6667"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicOff;
