import * as actionTypes from "../constants";

function accessPassReducer(state, action) {
  switch (action.type) {
    case actionTypes.GET_ACCESS_PASSES_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_ACCESS_PASSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
      };
    }

    case actionTypes.GET_ACCESS_PASSES_FAILURE:
      return { ...state, isFetching: false, error: action.payload.error };

    default:
      return state;
  }
}

export default accessPassReducer;
