import { Dialog, Transition } from "@headlessui/react";
import Button from "../Button";
import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import axios from "../../utils/axios";
import * as actionTypes from "../../constants/actionTypes";
import Close from "../../VibehutUI/Icons/Close";
import { StoreContext } from "../../Context/StoreContext";

function RoomModel({
  isOpen,
  roomId,
  setIsOpen,
  setIsPasswordVerified,
  setRoomPassword,
  roomPassword,
  buttonTitle = "Join Call",
}) {
  const inputRef = useRef();
  const [password, setpassword] = useState("");
  const [vispass, setvispass] = useState("hidden");
  const { dispatch, state } = useContext(StoreContext);
  const {
    room: { chatRoom },
  } = state;

  useEffect(() => {
    if (isOpen) {
      if (roomPassword) {
        setpassword(roomPassword);
        setvispass("visible");
      }
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 50);
    }
  }, [isOpen]);

  function handleChange(e) {
    setpassword(e.target.value);
    setvispass("hidden");
  }

  const startFuncCall = (e) => {
    if (e.key === "Enter") {
      goToCallPage();
    }
  };

  const goToCallPage = () => {
    axios
      .post(`/rooms/verify-password/${roomId}`, {
        password: password,
      })
      .then(function (response) {
        if (response.data.data) {
          setIsOpen(false);
          setRoomPassword(password);
          setIsPasswordVerified(true);
          dispatch({
            type: actionTypes.UNLOCK_CHAT_ROOM_SUCCESS,
            payload: {
              chatRoomId: chatRoom?._id,
            },
          });
        } else {
          setvispass("");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md  text-left overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-darkNight dark:text-frescoWhite">
                      Password Required
                    </p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={() => setIsOpen(false)}
                      tabindex="0"
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="bg-white dark:bg-navyGray p-4">
                  <div className="flex font-ubuntu">
                    <div className={`w-full flex items-center relative`}>
                      <div className="my-auto w-full flex flex-col">
                        <div className="flex flex-col space-y-2">
                          <input
                            ref={inputRef}
                            type="password"
                            className={`${
                              vispass ? "border-secondary" : "border-red-500"
                            } text-sm placeholder-gray-400   rounded-xl   `}
                            placeholder="Enter Password..."
                            onChange={handleChange}
                            onKeyDown={startFuncCall}
                            value={password}
                          ></input>
                          <p
                            className={`${
                              vispass ? "" : "hidden"
                            } text-xs text-darkGray dark:text-frescoWhite`}
                          >
                            The creator has put a password on this room.
                          </p>
                          <p
                            className={` ${vispass} pl-0.5 text-xs text-cvRed`}
                          >
                            {`${
                              password
                                ? "The password is incorrect. Please try again."
                                : "Please enter a password."
                            }`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" px-4 py-3 flex flex-row justify-end space-x-3 dark:bg-navyGray">
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => goToCallPage()}
                    size="vibhut-sm"
                    variant="vibhut-primary"
                  >
                    {buttonTitle}
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default RoomModel;
