import React, { useContext, useMemo, useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import globals from "../../globals";
import {
  cancelPlanSubscription,
  createPlanSubscription,
} from "../../services/planSubscriptionService";
import Button from "../../VibehutUI/Button";
import Tick from "../../VibehutUI/Icons/Tick";
import PaidBadgeRaw from "../../VibehutUI/Icons/PaidBadgeRaw";
import Loader from "../../VibehutUI/Loader";
import { calcPlanIconColor, wait } from "../../utils/utils";
import { showLoginModal } from "../../actions/uiModalActions";

function PlanCard({ plan, planType, toggleWalletVibeBuck }) {
  const { state, dispatch } = useContext(StoreContext);
  const [isFetchingStripeSession, setIsFetchingStripeSession] = useState(false);

  const {
    auth: { user: authUser },
  } = state;

  const isCurrentPlan = authUser?.serviceRole?.toString() === plan.role;

  const iconFillColor = useMemo(() => {
    return calcPlanIconColor(plan?.name);
  }, [plan]);

  const isFreePlan = useMemo(() => {
    return plan.name === "Free";
  }, [plan]);

  const stripePlanPrice = useMemo(() => {
    return plan.stripePrices?.data?.find(
      (p) => p.recurring.interval === planType
    );
  }, [plan, planType]);

  const stripePlanPriceAmount = stripePlanPrice?.unit_amount
    ? stripePlanPrice?.unit_amount / 100
    : 0;

  function purchaseAbility() {
    if (!authUser) {
      return showLoginModal(dispatch);
    }
    showConfirmationModal({
      header: "Confirm Plan",
      subtitle: "Are you sure you want to upgrade your plan?",
      message:
        "You will be charged $" +
        stripePlanPriceAmount +
        " for " +
        planType +
        "ly subscription",
      primaryButton: "Confirm",
      secondaryButton: "Cancel",
    });
  }

  const showConfirmationModal = ({
    header,
    subtitle,
    message,
    primaryButton,
    secondaryButton,
  }) => {
    globals.showModal(
      `${header}`,
      <>
        <div className=" justify-center p-4 rounded-2xl  ">
          <div>
            <div className="flex flex-row items-center gap-4">
              <div>
                <p className="dark:text-frescoWhite">{subtitle}</p>
                <p className="text-sm break-words text-darkGray">{message}</p>
              </div>
            </div>
          </div>
          <div className="pt-3 flex flex-row-reverse">
            {primaryButton && (
              <Button
                size="vibhut-sm"
                variant="vibhut-primary"
                onClick={() => {
                  globals.hideModal();
                  updateUserPlan();
                }}
              >
                {isFetchingStripeSession ? <Loader /> : primaryButton}
              </Button>
            )}
            {secondaryButton && (
              <div className="mr-2">
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    globals.hideModal();
                  }}
                >
                  {secondaryButton}
                </Button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const updateUserPlan = async () => {
    setIsFetchingStripeSession(true);
    try {
      if (authUser?.token && isFreePlan) {
        await cancelPlanSubscription(authUser?.token);
        await wait(2000);
        window.location.reload();
      } else if (authUser?.token && !isFreePlan) {
        const res = await createPlanSubscription(
          {
            plan: plan.name,
            planType: planType,
            priceId: stripePlanPrice?.id,
          },
          authUser?.token
        );

        const stripeUrl = res.data.stripeSession.url;
        window.location.href = stripeUrl;
      }
    } catch (error) {
      showConfirmationModal({
        header: "Error",
        subtitle: "Something went wrong. Please try again later.",
        message: "If the problem persists, please contact support.",
      });
      console.log("error", error);
    } finally {
      setIsFetchingStripeSession(false);
    }
  };

  return (
    <div
      className={`w-64 p-4 pb-9 shadow-lg rounded-2xl relative border-2 text-gray-800 bg-white dark:bg-frescoWhite hover:scale-[102%] transition-all duration-500`}
    >
      <div className="flex justify-between items-center">
        <p className={`mb-3 text-xl font-medium `}>{plan.name}</p>
        <PaidBadgeRaw color={iconFillColor} customClass="-mt-1" />
      </div>
      <p className={`text-3xl font-bold mb-2 `}>
        ${stripePlanPriceAmount}
        <span className={`text-sm text-gray-500 `}>/ {planType}</span>
      </p>
      <p className={`mb-1 text-sm `}>{plan.shortDescription}</p>
      <div className=" my-2 ">
        <hr />
      </div>
      <ul className="w-full mb-6 overflow-auto  sm:h-[350px] sm:overflow-y-scroll custom-scrollbar">
        {plan.benifits?.map((benifit) => (
          <li
            className={`mb-2.5 flex space-x-3  items-center justify-start text-sm`}
          >
            <div>
              <span
                className={`w-5 h-5
              text-navyGray
              rounded-full flex items-center justify-center`}
                style={{
                  backgroundColor: iconFillColor,
                }}
              >
                <Tick />
              </span>
            </div>
            <span>{benifit}</span>
          </li>
        ))}
      </ul>
      <button
        type="button"
        style={{
          width: "calc(100% - 1rem)",
        }}
        className={`absolute bottom-3 left-0 right-0 mx-auto px-4 py-3 
        rounded-full w-full transition ease-in duration-200 text-center
         shadow-md focus:outline-none ${
           isCurrentPlan
             ? "opacity-20 cursor-not-allowed text-frescoWhite bg-primary"
             : "border-2 border-primary text-primary"
         }`}
        onClick={() => {
          if (!isCurrentPlan) {
            purchaseAbility();
          }
        }}
      >
        {isFetchingStripeSession ? (
          <Loader />
        ) : authUser?.serviceRole?.toString() === plan.role ? (
          "Current Plan"
        ) : (
          "Upgrade"
        )}
      </button>
    </div>
  );
}

export default PlanCard;
