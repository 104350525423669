import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Close from "../VibehutUI/Icons/Close";

export default function VibehutModal({
  content,
  title,
  isOpen,
  onClose,
  canClose = true,
  isChatModal = false,
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-40 inset-0 overflow-y-auto `}
        onClose={canClose ? onClose : () => {}}
      >
        <div
          className={`flex  justify-center  pt-4 px-4 ${
            isChatModal ? "pb-4" : "pb-20"
          }  text-center sm:block sm:p-0 z-30 min-h-screen md:items-end items-start`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom ${
                isChatModal
                  ? "bg-white dark:bg-darkNight shadow-2xl md:w-2/5 w-428px border dark:border-matteGray h-[85vh] md:h-[96vh] mb-0"
                  : "bg-white dark:bg-navyGray shadow-xl w-428px mb-14"
              }  rounded-2xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle font-ubuntu`}
            >
              <div
                className={`${
                  isChatModal
                    ? "bg-white dark:bg-darkNight"
                    : "bg-white dark:bg-navyGray"
                } p-4 border-b border-frescoWhite dark:border-matteGray`}
              >
                <div className="flex justify-between items-center ">
                  <div className="flex flex-row justify-start items-center w-full ">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite w-full">
                      {title}
                    </p>
                  </div>
                  {canClose && (
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={onClose}
                    >
                      <Close />
                    </span>
                  )}
                </div>
              </div>
              <div className="px-3 py-2">{content}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
