import moment from "moment";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ResponsiveCalendar } from "@nivo/calendar";
import { StoreContext } from "../../Context/StoreContext";
import { getUserData } from "../../services/userService";
import { getRoomCallHistoryData } from "../../services/rooomService";
import { ThemeContext } from "../../Context/ThemeContext";
import { openPlanModal } from "../../actions/profileModalActions";
import PremiumWrapper from "../PremiumWrapper";
import PurchaseIllustration from "../../VibehutUI/PurchaseIllustration";
import axios from "../../utils/axios";
import Loader from "../../VibehutUI/Loader";
import SelectBox from "../../VibehutUI/SelectBox";
import UserCard from "../../VibehutUI/UserCard";
import useQuery from "../../hooks/useQuery";
import { getAverageTime, getCallDuration, options } from "../../utils/utils";
import { singleClaimedRoomRoutes, singleRoomRoutes } from "../../routes";
import Button from "../../VibehutUI/Button";

function RoomAnalytics() {
  let { roomId } = useParams();
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth,
    room: { roomId: roomCustomId },
  } = state;
  if (!roomId) {
    roomId = roomCustomId;
  }
  const history = useHistory();
  const query = useQuery();
  const dateRange = query.get("range") || null;
  let selectedDate;
  if (dateRange) {
    selectedDate = options.filter((option) => {
      return option.label === dateRange;
    });
    selectedDate = selectedDate[0];
  } else {
    selectedDate = options[2];
  }
  const [room, setRoom] = useState(undefined);
  const [isRoomFetching, setIsRoomFetching] = useState(false);
  const [analytics, setAnalytics] = useState([]);
  const [selectedOption, setSelectedOption] = useState(selectedDate);
  const [roomCallHistory, setRoomCallHistory] = useState([]);
  const [totalCallsCount, settotalCallsCount] = useState(0);
  const { theme } = React.useContext(ThemeContext);

  const [yearOptions, setYearOptions] = useState(new Date());
  const toDate = new Date();
  toDate.setMonth(11);
  toDate.setDate(31);

  useEffect(() => {
    getRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!auth?.user?.token) {
      return;
    }
    let date = selectedOption.value;
    if (date === null) {
      date = new Date("22 jan 2022");
    }
    setYearOptions(date);
    if (roomId) {
      getRoomCallHistoryData(auth?.user?.token, roomId, date)
        .then((res) => {
          setRoomCallHistory(res.data.history || []);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, selectedOption]);

  const SubscribePlan = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col mt-8 items-center justify-center">
          <div style={{ height: "50%", width: "50%" }}>
            <PurchaseIllustration />
          </div>
          <h1 className="text-xl font-bold mt-8 mb-8 text-gray-600">
            You need to subscribe to a plan to access this feature
          </h1>
          <Button
            onClick={() => {
              openPlanModal(dispatch);
            }}
          >
            Subscribe
          </Button>
        </div>
      </div>
    );
  };

  const handleChange = (value) => {
    setSelectedOption(value);
    const path = room?.slug
      ? singleClaimedRoomRoutes.path.replace(":slug", room.slug)
      : singleRoomRoutes.path.replace(":roomId", room._id);
    history.replace({
      pathname: `${path}/analytics`,
      search: `?range=${value.label}`,
      state: { previousPath: history.location.pathname },
    });
  };

  // const handleYearChange = (value) => {
  //   setSelectedYear(value);
  //   const d = new Date(value.value).getFullYear();
  //   setCalenderToDate(new Date(d, 11, 31));
  // };

  const getRoom = async () => {
    if (roomId && auth?.user?.token) {
      setIsRoomFetching(true);
      await axios
        .post(
          `/rooms/room-analytics`,
          {
            roomId,
            date: selectedOption.value,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.user?.token}`,
            },
          },
        )
        .then((res) => {
          setRoom(res.data.data.room);
          setAnalytics(res.data.data.roomAnalytics);
          settotalCallsCount(res.data.data.totalCallCount);
          setIsRoomFetching(false);
        });
    }
  };

  return isRoomFetching ? (
    <div className="py-10">
      <Loader />
    </div>
  ) : //Add Premium Wrapper here
  room ? (
    <PremiumWrapper
      permission={["analytics:read"]}
      fallbackComponent={<SubscribePlan />}
    >
      <div className="mb-6">
        <div className="border-b dark:border-matteGray md:py-6  ">
          <div className="flex gap-4  md:pt-0 pt-4   flex-col">
            <div className="flex md:flex-row flex-col justify-between items-center">
              <p className="font-bold text-xl text-darkNight dark:text-frescoWhite">
                {room?.name} Analytics
              </p>
              <div className="pt-2 md:pt-0">
                <SelectBox
                  onChange={handleChange}
                  selected={selectedOption}
                  options={options}
                />
              </div>
            </div>
            {room?.owner ? (
              <div className="flex pt-3 pb-6">
                <UserCard user={getUserData(room?.owner)} subtitle="Creator" />
              </div>
            ) : undefined}
          </div>
        </div>
        <div className="flex gap-4 md:flex-row flex-col  py-10 font-ubuntu text-darkNight">
          <div className="md:w-1/3 w-full bg-blue-100 p-4 flex items-center justify-between rounded-md ">
            <p className="text-2xl font-normal">Users</p>
            <p className="text-4xl">{analytics.length}</p>
          </div>
          <div className="md:w-1/3 w-full bg-blue-100 p-4 flex items-center justify-between rounded-md">
            <p className="text-2xl font-normal">Calls</p>
            <p className="text-4xl"> {totalCallsCount}</p>
          </div>
        </div>
        {roomCallHistory.length > 0 ? (
          <div
            className={`${
              yearOptions.getFullYear() === 2024 ? "h-[200px]" : "h-[400px]"
            }`}
          >
            <ResponsiveCalendar
              data={roomCallHistory}
              from={moment(yearOptions).format("YYYY-MM-DDTHH:mmZ")}
              to={moment(toDate).format("YYYY-MM-DDTHH:mmZ")}
              emptyColor="#eeeeee"
              align="left"
              colors={["#9C99FF", "#6A66FF", "#5B57FF"]}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              yearSpacing={40}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              theme={{
                textColor: theme === "dark" ? "#FFFFFF" : "#333333",
              }}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "row",
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: "right-to-left",
                },
              ]}
            />
          </div>
        ) : undefined}
        {analytics.length !== 0 && (
          <div className="overflow-x-auto">
            <div className=" flex items-center justify-center">
              <div className="w-full">
                <div className=" shadow-md rounded text-darkNight dark:text-frescoWhite">
                  <table className="min-w-max w-full table-auto">
                    <thead>
                      <tr className="bg-primary text-white uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">User</th>
                        <th className="py-3 px-6 text-center">Total calls</th>
                        <th className="py-3 px-6 text-center">
                          Total call time
                        </th>
                        <th className="py-3 px-6 text-center">Avarage time</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {analytics.map((user, index) => (
                        <tr
                          className="border-b bg-frescoWhite dark:bg-darkNight dark:border-matteGray "
                          key={index}
                        >
                          <td className="py-3  text-left">
                            <div className="flex items-center">
                              <span>
                                <UserCard user={getUserData(user)} />
                              </span>
                            </div>
                          </td>
                          <td className="py-3 px-6 text-center">
                            <span>{user.callCount}</span>
                          </td>
                          <td className="py-3 px-6 text-center">
                            <span>
                              {getCallDuration(user.callDuration)} hrs
                            </span>
                          </td>
                          <td className="py-3 px-6 text-center">
                            <span>
                              {getAverageTime(
                                user.callDuration,
                                user.callCount,
                              )}{" "}
                              m
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PremiumWrapper>
  ) : (
    <div className="py-10 text-primary">Not Authorized for Room Analytics</div>
  );
}

export default RoomAnalytics;
