/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, memo, useMemo, useRef } from "react";
import DeSo from "../../VibehutUI/Icons/DeSo";
import Metamask from "../../VibehutUI/Icons/Metamask";
import Instagram from "../../VibehutUI/Icons/Instagram";
import LinkedIn from "../../VibehutUI/Icons/LinkedIn";
import TikTok from "../../VibehutUI/Icons/Tiktok";
import Avatar from "../../VibehutUI/Avatar";
import Tag from "../../VibehutUI/Tag";
import LinkIcon from "../../VibehutUI/Icons/LinkIcon";
import PaidBadge from "../../icons/PaidBadge";
import Facebook from "../../VibehutUI/Icons/Facebook";
import SocialLinkIcons from "../../VibehutUI/SocialLinkIcon";
import Add from "../../VibehutUI/Icons/Add";
import {
  getUserConnection,
  updateMyConnection,
} from "../../services/userService";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import Loader from "../../VibehutUI/Loader";
import Worldcoin from "../../VibehutUI/Icons/Worldcoin";
import TwitterV2 from "../../VibehutUI/Icons/TwitterV2";
import Google from "../../VibehutUI/Icons/Google";
import { getShortenedName } from "../../utils/utils";
import Warpcast from "../../../VibehutUI/Icons/Warpcast";

interface userCardProps {
  user: any;
  userState: any;
  isChatWindowOpen?: boolean;
  showSocialIcon?: boolean;
  showSocialProvider?: boolean;
  isMemeberCard?: boolean;
  isNormalCard?: boolean;
  fromCallProfile?: boolean;
  isGroupRoom?: boolean;
  totalUsersInCall?: number;
  isMissedCall?: boolean;
  isInviteUserList?: boolean;
  isRoomOwner?: boolean;
  isYou?: boolean;
  isSearchResultCard?: boolean;
  subtitle?: string;
  size?: string;
  isModerator?: boolean;
  labelText?: any;
  showSocialLinks?: boolean;
  showConnectionStatus?: boolean;
}

interface socialIcon {
  provider: string;
  bottomPosition?: string;
  icon?: string;
}

function UserCard({
  user,
  size = "sm",
  isMemeberCard = false,
  isMissedCall = false,
  isInviteUserList = false,
  isRoomOwner = false,
  isYou = false,
  isSearchResultCard = false,
  showSocialIcon = false,
  showSocialProvider = false,
  subtitle = "",
  isModerator = false,
  labelText = undefined,
  showSocialLinks = true,
  showConnectionStatus = true,
}: userCardProps) {
  const { authUser } = useAuthUser();
  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef<boolean>(true);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (
      authUser.token &&
      authUser._id !== user._id &&
      showConnectionStatus &&
      isMounted.current
    ) {
      setIsLoading(true);
      getUserConnection(user._id, authUser.token)
        .then((res) => {
          setConnection(res.data.connection);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id, isMounted?.current]);

  const handleConnection = () => {
    setIsLoading(true);
    updateMyConnection(
      { user: user._id, is_connected: true },
      authUser.token,
    ).then((res) => {
      setIsLoading(false);
      setConnection(res.data.connection);
    });
  };

  const isWarpCastUser = useMemo(() => {
    if (user?.provider === "metamask") {
      if (user?.farcaster?.username) {
        return true;
      }
    }
    return false;
  }, [user]);

  const _renderSocialProviderTextIcon = () => {
    switch (user.provider) {
      case "bitclout":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Deso</span>
            <span className="pl-0.5">
              <DeSo ratio={16} />
            </span>
          </p>
        );
      case "twitter":
        return (
          <p className="flex text-sm items-center  font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Twitter</span>
            <span className="pl-0.5">
              <TwitterV2 ratio={16} />
            </span>
          </p>
        );
      case "metamask":
        if (user?.farcaster?.username) {
          return (
            <p className="flex text-sm items-center font-normal tracking-wide">
              <span className="font-ubuntu text-darkGray">Warpcast</span>
              <span className="pl-0.5">
                <Warpcast ratio={16} />
              </span>
            </p>
          );
        }
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Metamask</span>
            <span className="pl-0.5">
              <Metamask ratio={16} />
            </span>
          </p>
        );
      case "instagram":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Instagram</span>
            <span className="pl-0.5">
              <Instagram ratio={16} />
            </span>
          </p>
        );
      case "tiktok":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">TikTok</span>
            <span className="pl-0.5">
              <TikTok ratio="16" />
            </span>
          </p>
        );
      case "linkedin":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">LinkedIn</span>
            <span className="pl-0.5">
              <LinkedIn ratio={20} />
            </span>
          </p>
        );
      case "link":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Link</span>
            <span className="pl-0.5">
              <LinkIcon ratio={20} />
            </span>
          </p>
        );
      case "facebook":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Facebook</span>
            <span className="pl-0.5">
              <Facebook ratio={20} />
            </span>
          </p>
        );
      case "worldcoin":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Worldcoin</span>
            <span className="pl-0.5">
              <Worldcoin ratio={20} />
            </span>
          </p>
        );
      case "google":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Google</span>
            <span className="pl-0.5">
              <Google ratio={20} />
            </span>
          </p>
        );
      default:
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">{user?.provider}</span>
            {user?.icon && (
              <span className="pl-0.5">
                <img src={user.icon} className="w-5 h-5" alt={user.provider} />
              </span>
            )}
          </p>
        );
    }
  };

  const _renderSocialIcon = ({
    provider,
    bottomPosition = "-bottom-1",
    icon,
  }: socialIcon) => {
    switch (provider) {
      case "bitclout":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <DeSo />
          </span>
        );
      case "linkedin":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <LinkedIn />
          </span>
        );
      case "twitter":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <TwitterV2 />
          </span>
        );
      case "metamask":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <Metamask />
          </span>
        );
      case "warpcast":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <Warpcast />
          </span>
        );
      case "instagram":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <Instagram />
          </span>
        );
      case "tiktok":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <TikTok />
          </span>
        );
      case "worldcoin":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <Worldcoin />
          </span>
        );
      case "google":
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            <Google />
          </span>
        );
      default:
        return (
          <span
            className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
          >
            {icon && (
              <img src={icon} className="w-[20px] h-[20px]" alt={provider} />
            )}
          </span>
        );
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${isSearchResultCard || isMemeberCard ? "" : "px-2"} ${
          isMemeberCard ? "flex justify-between items-center w-full pr-2" : ""
        } `}
      >
        <div
          className={`flex items-center space-x-3 cursor-pointer ${
            isMemeberCard ? "" : " px-2"
          } py-1.5 rounded-xl  ${
            !isMemeberCard &&
            ` hover:bg-frescoWhite  ${
              isInviteUserList ? "" : "dark:hover:bg-navyGray"
            } `
          }`}
        >
          <div className="relative">
            <Avatar
              username={
                user.provider === "metamask"
                  ? user.username.slice(0, 5)
                  : user.username
              }
              src={user.profile_picture}
              size={size}
              isRoomOwner={isRoomOwner}
              showGreenDot={authUser._id === user._id ? false : !!connection}
            />

            {showConnectionStatus &&
              (authUser.provider === "guest" ? undefined : isLoading ? (
                <span className={`absolute -bottom-2 md:-right-[0px] `}>
                  <Loader ratio="h-3 w-3" />
                </span>
              ) : (
                !connection &&
                authUser._id !== user._id &&
                user.provider !== "guest" && (
                  <span
                    className="absolute -bottom-2 md:right-[10px] right-0 bg-cvRed dark:bg-darkNight p-1 rounded-full"
                    onClick={handleConnection}
                  >
                    <Add color="#F2F2F2" ratio={10} />
                  </span>
                )
              ))}
            {showSocialIcon
              ? _renderSocialIcon({
                  provider: isWarpCastUser ? "warpcast" : user.provider,
                  bottomPosition: "",
                  icon: user?.icon,
                })
              : undefined}
          </div>

          <div>
            <p className=" flex items-center space-x-1 ">
              <span
                className={` font-medium truncate flex  justify-center items-start ${
                  isMemeberCard
                    ? "text-white text-sm  "
                    : isMissedCall
                      ? "text-cvRed"
                      : "text-matteGray text-base"
                }  ${
                  isInviteUserList
                    ? ""
                    : `${isMissedCall ? "dark:text-cvRed" : "dark:text-white"}`
                }`}
              >
                {user && user.username
                  ? `${getShortenedName(user.username, 16)} ${
                      user.provider === "guest" ? "· (Guest)" : ""
                    }`
                  : ""}
              </span>
              <PaidBadge isInline serviceRole={user.serviceRole} />
            </p>
            {showSocialProvider ? _renderSocialProviderTextIcon() : undefined}
            {subtitle ? (
              <p className="text-xs text-darkGray  font-normal tracking-wide">
                {subtitle}
              </p>
            ) : undefined}

            <div
              className={`flex ${
                (isRoomOwner || isYou || isModerator) && "space-x-1"
              } `}
            >
              {isMemeberCard && (
                <span className="text-xs text-notifyBg flex font-normal tracking-wide items-center ">
                  {isRoomOwner && isYou
                    ? "Host · You"
                    : isRoomOwner
                      ? "Host"
                      : isYou && isModerator
                        ? "Mod · You"
                        : isYou
                          ? "You"
                          : isModerator
                            ? "Mod"
                            : ""}
                  {labelText && (
                    <span className="pl-1 mb-1 ">
                      <Tag title={labelText} />
                    </span>
                  )}
                </span>
              )}
            </div>
            {showSocialLinks && (
              <div
                className={`flex ${
                  (isRoomOwner || isYou || isModerator) && "space-x-1"
                } `}
              >
                {user?.socialLinks && <SocialLinkIcons profile={user} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.user._id === nextProps.user._id &&
    (prevProps.user.socialLinks?.twitter ===
      nextProps.user.socialLinks?.twitter ||
      prevProps.user.socialLinks?.facebook ===
        nextProps.user.socialLinks?.facebook ||
      prevProps.user.socialLinks?.instagram ===
        nextProps.user.socialLinks?.instagram ||
      prevProps.user.socialLinks?.linkedIn ===
        nextProps.user.socialLinks?.linkedIn ||
      prevProps.user.socialLinks?.tiktok ===
        nextProps.user.socialLinks?.tiktok ||
      prevProps.user.socialLinks?.metamask ===
        nextProps.user.socialLinks?.metamask ||
      prevProps.user.socialLinks?.facebook ===
        nextProps.user.socialLinks?.facebook ||
      prevProps.user.socialLinks?.link === nextProps.user.socialLinks?.link ||
      prevProps.user.socialLinks?.telegram ===
        nextProps.user.socialLinks?.telegram) &&
    prevProps.user.serviceRole === nextProps.user.serviceRole
  );
}
export default memo(UserCard, areEqual);
