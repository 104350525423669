import React from "react";

function Chip({ content, disabled, onClick }) {
  return (
    <div
      className="bg-gray-200 text-gray-700 text-sm font-medium px-2 py-1 rounded-full mr-2 mb-2 cursor-pointer"
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      {content}
    </div>
  );
}

export default Chip;
