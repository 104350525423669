import React from "react";
import { useHistory } from "react-router-dom";
import axios from "../utils/axios";
import { useContext } from "react";
import { StoreContext } from "../Context/StoreContext";

function WorldcoinCallback(props) {
  const { dispatch } = useContext(StoreContext);
  const history = useHistory();
  const worldcoinLogin = async () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const token = url.hash.split("=")[1];
    await axios.get(`/auth/worldcoin/callback?token=${token}`).then(res => {
      dispatch({
        type: "LOGIN_WITH_WORLDCOIN_SUCCESS",
        payload: {
          user: res.data.data,
        },
      });
      history.push("/");
    });
  };

  React.useEffect(() => {
    worldcoinLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className="text-center mt-10">Redirecting...</div>;
}

export default WorldcoinCallback;
