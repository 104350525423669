import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getPlans } from "../../services/plansService";
import PlanCard from "./PlanCard";
import PlanSkeleton from "../../VibehutUI/Skeletons/PlanSkeleton";

const PlanProfile = ({ toggleWalletVibeBuck }) => {
  const { state } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [planType, setPlanType] = useState("month");

  const {
    auth: { user },
  } = state;

  useEffect(() => {
    if (user?.token) {
      fetchPlans(user.token);
    }
  }, [user]);

  const fetchPlans = (token) => {
    setIsLoading(true);
    getPlans(token)
      .then((res) => {
        const plans = res?.data?.plans;
        if (plans) {
          setPlans(plans);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("plans err: ", err);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <PlanSkeleton />
      </div>
    );
  }

  return (
    <div className="overflow-auto">
      <div
        className={`w-fit mx-auto  flex cursor-pointer items-center justify-center rounded-xl border-2 text-gray-800 bg-white dark:bg-frescoWhite`}
      >
        <div
          className={`rounded-xl h-full py-2 px-4 text-sm font-medium ${
            planType === "month" && "bg-zinc-400"
          }`}
          onClick={() => setPlanType("month")}
        >
          Monthly
        </div>
        <div
          className={`rounded-xl h-full py-2 px-5 text-sm font-medium ${
            planType === "year" && "bg-zinc-400"
          }`}
          onClick={() => setPlanType("year")}
        >
          Yearly
        </div>
      </div>

      <div className="w-fit sm:w-full mx-auto flex-1 sm:flex justify-center item-center flex-col sm:flex-row h-[500px] sm:h-full overflow-y-auto sm:overflow-y-hidden custom-scrollbar mt-2 sm:mt-1 p-2 space-x-0 sm:space-x-3  space-y-3 sm:space-y-0">
        {plans.map((p) => (
          <PlanCard
            plan={p}
            planType={planType}
            toggleWalletVibeBuck={toggleWalletVibeBuck}
          />
        ))}
      </div>
    </div>
  );
};

export default PlanProfile;
