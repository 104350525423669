import React, { useContext } from "react";
import Chat from "../VideoChatComponent/VideoChatSidebar/Chat";
import useChat from "../../hooks/useChat";
import { StoreContext } from "../../Context/StoreContext";

function SpecificChatModal({ room, chatType }) {
  const {
    sendMessage,
    deleteMessage,
    editMessage,
    addReaction,
    removeUserOrAddReaction,
    addUserToReaction,
  } = useChat(null, room._id, false, true);
  const { state } = useContext(StoreContext);
  const {
    room: { chatRoom, isFetchingChatRoom },
  } = state;
  return (
    <div>
      {chatRoom ? (
        !isFetchingChatRoom ? (
          <Chat
            sendMessage={sendMessage}
            roomId={chatRoom?._id}
            deleteMessage={deleteMessage}
            editMessage={editMessage}
            addReaction={addReaction}
            removeUserOrAddReaction={removeUserOrAddReaction}
            addUserToReaction={addUserToReaction}
            isModal={true}
            chatType={chatType}
          />
        ) : (
          <></>
        )
      ) : (
        <div className="dark:text-frescoWhite flex justify-center items-center h-full"></div>
      )}
    </div>
  );
}

export default SpecificChatModal;
