function Warpcast({ ratio = 20 }) {
  return (
    <img
      width={ratio}
      height={ratio}
      alt="WarpcastIcon"
      style={{ height: ratio, width: ratio }}
      src={`${process.env.PUBLIC_URL}/warpcast.jpg`}
    ></img>
  );
}

export default Warpcast;
