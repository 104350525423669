import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";

import globals from "../globals";
import axios, { videoAppUrl } from "../utils/axios";
import { getAccessPasses } from "../actions/actionPassActions";

export const useStripeCallBack = () => {
  const { search } = useLocation();
  const [isUpdatingPaymentInfo, setIsUpdatingPaymentInfo] = useState(false);
  const [error, setError] = useState(null);

  const { state, dispatch } = useContext(StoreContext);
  const { auth } = state;

  const updatePaymentInfo = useCallback(
    async (status, paymentId) => {
      if (!auth?.user?.token) {
        return;
      }

      setIsUpdatingPaymentInfo(true);
      setError(null);

      await axios
        .post(
          "/payment/update-payment-info",
          {
            paymentId,
            status,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.user.token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "UPDATE_ISPAYMENT_STATUS",
            payload: {
              status: status,
              message: "Your VibeBucks purchase was successful.",
            },
          });
          window.history.replaceState(
            {},
            document.title,
            window.location.href.split("?")[0]
          );
          setTimeout(() => {
            globals.onStripePaymentSuccess();
          }, 500);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsUpdatingPaymentInfo(false);
        });
    },
    [auth?.user?.token, dispatch]
  );

  const updateEventPaymentInfo = useCallback(
    async (status, paymentId, eventId, type) => {
      const testPayment = "true";
      if (!auth?.user?.token) {
        return;
      }

      setIsUpdatingPaymentInfo(true);
      setError(null);

      await axios
        .post(
          "/payment/update-event-payment-info",
          {
            testPayment,
            paymentId,
            status,
            eventId,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.user.token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "UPDATE_ISPAYMENT_STATUS",
            payload: {
              status: status,
              message: "Purchase successful. Have a great event!",
            },
          });

          window.history.replaceState(
            {},
            document.title,
            window.location.href.split("?")[0]
          );
          setTimeout(() => {
            globals.onStripePaymentSuccess();
          }, 500);
          if (type === "rsvp") {
            window.location.reload();
          } else if (type === "event") {
            window.location.href = `${videoAppUrl}/call?roomKey=${
              res?.data?.data?.room._id ? res?.data?.data?.room._id : ""
            }`;
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsUpdatingPaymentInfo(false);
        });
    },
    [auth?.user?.token, dispatch]
  );

  const updateAccessPassPaymentInfo = useCallback(
    async (status, paymentId, roomId, type) => {
      const testPayment = "true";
      if (!auth?.user?.token) {
        return;
      }

      setIsUpdatingPaymentInfo(true);
      setError(null);

      await axios
        .post(
          "/payment/update-access-pass-payment-info",
          {
            testPayment,
            paymentId,
            status,
            roomId,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.user.token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "UPDATE_ISPAYMENT_STATUS",
            payload: {
              status: status,
              message: "Purchase successful. Have a great event!",
            },
          });

          window.history.replaceState(
            {},
            document.title,
            window.location.href.split("?")[0]
          );

          getAccessPasses(dispatch, auth.user.token);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsUpdatingPaymentInfo(false);
        });
    },
    [auth?.user?.token, dispatch]
  );

  useEffect(() => {
    const data = new URLSearchParams(search);
    const status = data.get("paymentStatus");
    const paymentId = data.get("paymentId");
    const type = data.get("type");
    const eventId = data.get("eventId");
    const kind = data.get("kind");
    const roomId = data.get("roomId");

    if (status && paymentId && eventId && kind === "eventSubscription") {
      updateEventPaymentInfo(status, paymentId, eventId, type);
    } else if (status && paymentId && roomId && kind === "accessPass") {
      updateAccessPassPaymentInfo(status, paymentId, roomId, type);
    } else if (status && paymentId) {
      updatePaymentInfo(status, paymentId, type);
    }
  }, [search, updatePaymentInfo]);

  return { isUpdatingPaymentInfo, error };
};

export const useStripeSuccessCallback = (callback) => {
  useEffect(() => {
    globals.onStripePaymentSuccess = callback;

    return () => {
      globals.onStripePaymentSuccess = globals.defaultOnStripePaymentSuccess;
    };
  }, [callback]);

  return null;
};
