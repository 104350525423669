import moment from "moment";
import { PersonaFaq } from "../../types";

interface Props {
  faq: PersonaFaq;
}

function FaqCard({ faq }: Props) {
  return (
    <div className="border-b dark:border-matteGray border-lightGray py-2">
      <p className="dark:text-white text-darkNight font-medium text-sm whitespace-pre-wrap">
        {faq.question}
      </p>
      <p className=" text-darkGray font-medium text-sm whitespace-pre-wrap">
        {faq.answer}
      </p>
      <p className="text-sm text-darkGray">
        {moment(faq.createdAt).fromNow().replace("ago", "")}
      </p>
    </div>
  );
}

export default FaqCard;
