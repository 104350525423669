import * as actionTypes from "../constants";
import * as callService from "../services/callService";

export async function getRawCallRecordings({
  dispatch,
  callId,
  token,
}: {
  dispatch: any;
  callId: string;
  token: string;
}) {
  dispatch({
    type: actionTypes.GET_RAW_RECORDINGS_REQUEST,
  });
  try {
    const res = await callService.getRawCallRecordings(callId, token);
    dispatch({
      type: actionTypes.GET_RAW_RECORDINGS_SUCCESS,
      payload: res.data.recordings,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_RAW_RECORDINGS_FAILURE,
    });
    throw err;
  }
}

export function getMyCallCount(dispatch: any, token: string) {
  dispatch({
    type: actionTypes.GET_MY_CALL_COUNT_REQUEST,
  });
  return callService
    .getMyCallCount(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_CALL_COUNT_SUCCESS,
        payload: {
          myCallCount: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_CALL_COUNT_FAILURE,
      });
      throw err;
    });
}
