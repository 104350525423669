import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import { AuthUserContextProvider } from './components/AuthUserProvider';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <AuthUserContextProvider>
            <App />
          </AuthUserContextProvider>
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

export const VideoReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <AppStateProvider>
      <VideoApp />
    </AppStateProvider>
  </MuiThemeProvider>
);

