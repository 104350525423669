/* eslint-disable import/no-anonymous-default-export */
export default {
  control: {
    // backgroundColor: "#fff",
    fontSize: 16,
    borderRadius: 16,
    // position: "absolute",
    // width: "100%",
  },

  "&multiLine": {
    control: {
      maxHeight: 90,
      overflow: "auto",
    },
    highlighter: {
      padding: "2px 8px",
      // border: "1px solid transparent",
    },
    input: {
      // padding: 9,
      // border: "1px solid silver",
      borderRadius: 16,
      margin: "0px",
      paddingRight: "80px",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      borderRadius: 16,
      bottom: 16,
      position: "absolute",
      overflow: "auto",
      height: 250,
      zIndex: 9999,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "hsl(0, 0%, 85%)",
      },
      color: "#000",
    },
  },
};
