import axios, { Axios } from 'axios';
import { baseURL } from '../constants';
const API_ROOT = `${baseURL}/api/v1`;

class ApiService {
  private client: Axios;
  constructor() {
    this.client = axios.create({
      baseURL: API_ROOT,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public async get(path: string, source?: any, accessToken?: string) {
    const response = await this.client.get(path, {
      cancelToken: source?.token,
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
    });
    return response.data;
  }

  public async put(path: string, payload: Object, source?: any, accessToken?: string) {
    const response = await this.client.put(path, JSON.stringify(payload), {
      cancelToken: source?.token,
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
    });
    return response.data;
  }

  public async post(path: string, payload: Object, source?: any, accessToken?: string) {
    const response = await this.client.post(path, JSON.stringify(payload), {
      cancelToken: source?.token,
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
    });
    return response.data;
  }

  public async patch(path: string, payload: Object, accessToken?: string): Promise<Object> {
    const response = await this.client.patch(path, payload, {
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
    });
    return response.data;
  }
  public async delete(path: string, accessToken?: string): Promise<number> {
    const response = await this.client.delete(path, {
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
    });
    return response.data;
  }
}
export default new ApiService();
