import * as actionTypes from "../constants";
import * as promotionService from "../services/promotionService";
import { CreatePromotionPayload, UpdatePromotionPayload } from "../types";
import { saveApprovedPromotionToLocalStrorage } from "../utils/utils";

export function getApprovedPromotion(dispatch: any, timezone: string) {
  dispatch({
    type: actionTypes.GET_APPROVED_PROMOTION_REQUEST,
  });
  return promotionService
    .getApprovedPromotion(timezone)
    .then((res) => {
      saveApprovedPromotionToLocalStrorage(res.data);
      dispatch({
        type: actionTypes.GET_APPROVED_PROMOTION_SUCCESS,
        payload: {
          promotion: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_APPROVED_PROMOTION_FAILURE,
      });
      throw err;
    });
}

export function getMyPromotions(dispatch: any, token: string, offset: string) {
  dispatch({
    type: actionTypes.GET_MY_PROMOTION_REQUEST,
  });
  return promotionService
    .getMyPromotion(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_PROMOTION_SUCCESS,
        payload: {
          promotions: res.data.promotions,
          offset: res.data?.offset || "",
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_PROMOTION_FAILURE,
      });
      throw err;
    });
}

export function createPromotion(
  data: CreatePromotionPayload,
  token: string,
  dispatch: any,
) {
  dispatch({
    type: actionTypes.CREATE_PROMOTION_REQUEST,
  });
  return promotionService
    .createPromotion(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_PROMOTION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_PROMOTION_FAILURE,
      });
      throw err;
    });
}

export function updatePromotion(
  data: UpdatePromotionPayload,
  recordId: string,
  token: string,
  dispatch: any,
) {
  dispatch({
    type: actionTypes.UPDATE_PROMOTION_REQUEST,
  });
  return promotionService
    .updatePromotion(data, recordId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_PROMOTION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_PROMOTION_FAILURE,
      });
      throw err;
    });
}
