import React from "react";
import Linkify from "react-linkify";
import { componentDecorator } from "../../utils/utils";

function ChatBubble({ message, isSend, isDanger }) {
  let direction = isSend
    ? "float-right bg-primary text-left"
    : `float-left ${isDanger ? "bg-cvRed" : "bg-matteGray"}   text-left`;

  return (
    <div className="flow-root">
      <span
        style={{ maxWidth: "20rem", minWidth: "2.5rem" }}
        className={`break-words text-wrap  whitespace-pre-wrap p-2 text-white rounded-2xl text-sm ${direction}`}
      >
        <Linkify componentDecorator={componentDecorator}>{message}</Linkify>
      </span>
    </div>
  );
}

export default ChatBubble;
