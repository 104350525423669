import React from "react";

function Stats({ height = 14, width = 16 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill='currentColor'
      id="stats"
    >
      <path d="M176 96h64v320h-64zM80 320h64v96H80zM272 256h64v160h-64zM368 192h64v224h-64z"></path>
    </svg>
  );
}

export default Stats;
