import { useContext, useMemo, useState } from "react";
import Loader from "../../VibehutUI/Loader";
import Button from "../../VibehutUI/Button";
import globals from "../../globals";
import { uploadFile, uploadMedia2 } from "../../utils/uploadMedia";
import { StoreContext } from "../../Context/StoreContext";
import { createPersona } from "../../actions/personaAction";
import { ObjectCleaner } from "../../utils/utils";
import { showToast } from "../../actions/toastActions";
import { createPersonaTab } from "../../constants";
import GeneralTab from "./GeneralTab";
import * as actionTypes from "../../constants";
import AdvanceTab from "./AdvanceTab";

function CreatePersona() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    persona: { isCreating, formState },
    room: {
      liveRoom: { liveRoom: room },
    },
  } = state;
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [activeTab, setActiveTab] = useState(createPersonaTab.generalTab);

  const validateInputs = () => {
    const isCompanyAffiliationDataNotValid =
      formState.data?.companyAffiliation?.isActive &&
      (!formState.data.companyAffiliation.imgUrl ||
        !formState.data.companyAffiliation.title ||
        !formState.data.companyAffiliation.link);
    if (
      !formState.data.name ||
      (!formState.data.avatar.urls.small && !formState.data?.avatarFile) ||
      !formState.data.about ||
      isCompanyAffiliationDataNotValid
    ) {
      dispatch({
        type: actionTypes.UPDATE_FORM_ERROR_STATE,
        payload: {
          nameError: !formState.data.name ? "Please enter name" : "",
          avatarError:
            !formState.data.avatar.urls.small && !formState.data?.avatarFile
              ? "Please select or upload the avatar"
              : "",
          aboutError: !formState.data.about ? "Please enter about" : "",
          companyAffiliationError: isCompanyAffiliationDataNotValid
            ? "Please add all details of company affiliation"
            : "",
        },
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (media: any = undefined) => {
    const _highlights = formState.data?.highlights;
    const _lowlights = formState.data?.lowlights;
    const _lookingFor = formState.data?.lookingFor;
    const _notLookingFor = formState.data?.notLookingFor;
    const _recordedVideoLink = formState.data?.recordedVideoLink;
    const _peopleShouldKnow = formState.data?.peopleShouldKnow;
    const _tags = formState.data?.tags
      ? formState.data?.tags.map((tag: any) => tag?.value)
      : [];
    let _avatar = formState.data?.avatar?.urls.small || "";
    let companyAffiliation = null;
    if (media?.url) {
      _avatar = media?.url;
    }
    dispatch({
      type: actionTypes.CREATE_PERSONA_REQUEST,
    });
    if (formState.data?.companyAffiliation?.imgUrl?.name) {
      const companyAffiliationImgUrl = await uploadFile(
        formState.data?.companyAffiliation?.imgUrl,
      );
      companyAffiliation = {
        isActive: formState.data?.companyAffiliation?.isActive,
        imgUrl: companyAffiliationImgUrl,
        title: formState.data?.companyAffiliation?.title,
        link: formState.data?.companyAffiliation?.link,
      };
    }
    const body = {
      name: formState.data.name.trim(),
      avatar: _avatar,
      isEnable: formState.data.isEnable,
      ...(_highlights ? { highlights: _highlights } : {}),
      ...(_lowlights ? { lowlights: _lowlights } : {}),
      about: formState.data.about,
      ...(_lookingFor ? { lookingFor: _lookingFor } : {}),
      ...(_notLookingFor ? { notLookingFor: _notLookingFor } : {}),
      includeMe: formState.data.includeMe,
      notifyMe: formState.data.notifyMe,
      ...(_recordedVideoLink ? { recordedVideoLink: _recordedVideoLink } : {}),
      ...(_peopleShouldKnow ? { peopleShouldKnow: _peopleShouldKnow } : {}),
      ...(_tags.length > 0 ? { tags: _tags } : {}),
      ...(formState.data?.premiumTag?.value
        ? { premiumTags: [formState.data?.premiumTag?.value] }
        : {}),
      ...(companyAffiliation ? { companyAffiliation: companyAffiliation } : {}),
      roomSpecific: formState.data?.roomSpecific,
      ...(room?._id ? { room: room._id } : {}),
    };
    createPersona(ObjectCleaner(body), user?.token, dispatch)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: {
            name: "",
            isEnable: true,
            highlights: "",
            lowlights: "",
            about: "",
            lookingFor: "",
            notLookingFor: "",
            generatedBio: "",
            includeMe: true,
            notifyMe: true,
            peopleShouldKnow: "",
            recordedVideoLink: "",
            avatar: {
              urls: {
                regular: "",
                small: "",
              },
            },
            avatarFile: null,
            tags: [],
            premiumTag: "",
            companyAffiliation: {
              isActive: false,
              imgUrl: "",
              title: "",
              link: "",
            },
            roomSpecific: false,
            room: "",
          },
        });
        globals.hideModal();
      })
      .catch((error) => {
        if (error.message) {
          showToast(dispatch, "error", error.message, 2000);
        }
      });
  };

  const handleUploadAndCreate = async () => {
    if (!validateInputs()) {
      return;
    }

    if (formState.data.avatarFile) {
      await uploadMedia2(
        formState.data.avatarFile,
        setIsImageUploading,
        handleSubmit,
      );
    } else {
      await handleSubmit();
    }
  };

  const isSubmitButtonDisabled = useMemo(
    () =>
      formState.errorMessage.nameError || formState.errorMessage.avatarError,
    [formState.errorMessage],
  );
  return (
    <div className="max-h-[80vh] custom-scrollbar overflow-auto">
      <div className="wrapper dark:border-matteGray border-b border-frescoWhite">
        <nav className="py-2.5">
          <input
            type="radio"
            name="tab"
            id="all"
            checked={activeTab === createPersonaTab.generalTab}
            className="hidden"
          />
          <input
            type="radio"
            name="tab"
            id="mutual"
            checked={activeTab === createPersonaTab.advanceTab}
            className="hidden"
          />
          <label
            htmlFor="profile"
            className="profile"
            onClick={() => {
              setActiveTab(createPersonaTab.generalTab);
            }}
          >
            <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
              General
            </span>
          </label>
          <label
            htmlFor="chat"
            className="chat"
            onClick={() => {
              setActiveTab(createPersonaTab.advanceTab);
            }}
          >
            <span className="dark:!text-white text-xs font-ubuntu uppercase !text-black font-medium text-center block w-full">
              Advanced
            </span>
          </label>
          <div className="connection-tab border-b-2 border-primary"></div>
        </nav>
      </div>
      <div className="transform transition-all">
        <div
          className={` ${
            activeTab === createPersonaTab.generalTab ? "visible" : "hidden"
          }`}
        >
          <GeneralTab />
        </div>
        <div
          className={` ${
            activeTab === createPersonaTab.advanceTab ? "visible" : "hidden"
          }`}
        >
          <AdvanceTab />
        </div>
      </div>

      <div className=" px-4 py-3 flex flex-row-reverse ">
        {isCreating || isImageUploading ? (
          <Loader />
        ) : (
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            disabled={Boolean(isSubmitButtonDisabled)}
            onClick={handleUploadAndCreate}
          >
            Create
          </Button>
        )}
        <div className="mr-3">
          <Button
            size="vibhut-sm"
            variant="vibhut-secondary"
            onClick={() => globals.hideModal()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreatePersona;
