import React from 'react';

function ScreenShareOn() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 2H2.66659C1.93021 2 1.33325 2.59695 1.33325 3.33333V10C1.33325 10.7364 1.93021 11.3333 2.66659 11.3333H13.3333C14.0696 11.3333 14.6666 10.7364 14.6666 10V3.33333C14.6666 2.59695 14.0696 2 13.3333 2Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.33325 14H10.6666" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11.3333V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ScreenShareOn;
