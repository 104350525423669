import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getPersonasWeeklyMatches } from "../../services/personaService";
import PersonaCard from "./PersonaCard";
import PersonaWeeklyIntroCard from "./PersonaWeeklyIntroCard";
import ConnectionSkeleton from "../../VibehutUI/Skeletons/ConnectionSkeleton";

function PersonaWeeklyIntro() {
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;
  const [matchingPersonasLoading, setMatchingPersonasLoading] = useState(true);
  const [matchingPersonas, setMatchingPersonas] = useState([
    {
      personas: [],
      summary: "",
      related: [],
      asked: [],
    },
  ]);

  useEffect(() => {
    if (user?.token) {
      getPersonasWeeklyMatches(user?.token)
        .then((res) => {
          setMatchingPersonas(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setMatchingPersonasLoading(false);
        });
    }
  }, [user?.token]);

  if (matchingPersonasLoading) {
    return <ConnectionSkeleton gridConfig="xl:grid-cols-2 grid-cols-1" />;
  }

  if (matchingPersonas.length === 0) {
    return <></>;
  }

  return (
    <div className="overflow-auto  px-8 theCalcDiv custom-scrollbar">
      <PersonaWeeklyIntroCard />
      <div className="pt-4">
        <p className="dark:text-frescoWhite font-bold">
          Details About This Weeks Match
        </p>
        <p className="dark:text-frescoWhite text-sm font-medium pt-2">
          {matchingPersonas.summary}
        </p>
        <div className="pt-2">
          <p className="font-bold text-sm dark:text-frescoWhite">
            List of Interests and Their Relevance to Your Personas
          </p>
          <ul>
            {matchingPersonas.related.map((relate, index) => (
              <li
                key={relate + index}
                className="text-sm list-disc dark:text-frescoWhite"
              >
                {relate}
              </li>
            ))}
          </ul>
        </div>
        <div className="pt-2">
          <p className="font-bold text-sm dark:text-frescoWhite">
            List of Questions to Ask the Person
          </p>
          <ul>
            {matchingPersonas.asked.map((ask, index) => (
              <li
                key={ask + index}
                className="text-sm list-disc dark:text-frescoWhite"
              >
                {ask}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className="dark:text-frescoWhite font-bold pt-2">Matching Personas</p>
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-8 py-5">
        {matchingPersonas.personas.map((persona: any) => (
          <div className="cursor-pointer max-w-md" key={persona._id}>
            <PersonaCard persona={persona} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PersonaWeeklyIntro;
