import axios from "../utils/axios";

export function sendEmailOtp(token, email) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/send-otp`, {
        params: {
          type: "email",
          value: email,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function verifyEmailOtp(token, otpValue) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/verify-otp`, {
        params: {
          type: "email",
          otpValue: otpValue,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function sendNumberOtp(token, number) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/send-otp`, {
        params: {
          type: "phoneNumber",
          value: number.replace("+", ""),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function verifyNumberOtp(token, otpValue) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/verify-otp`, {
        params: {
          type: "phoneNumber",
          otpValue: otpValue,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
