import { useContext, useState, useEffect } from "react";
import NavigationBar from "./NavigationBar";
import UpGradeCard from "../VibehutUI/Card/UpGradeCard";
import { Link } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";
import { getUserData } from "../services/userService";
import MainLogo from "../VibehutUI/Icons/MainLogo";
import UserMenu from "../VibehutUI/UserMenu";
import QuickWallet from "./QuickWallet";
import SidebarGamification from "../components/home/SidebarGamification";
import DiamondIcon from "../videoChat/icons/Diamond";
function LeftSidebar({
  isOpen = false,
  setIsOpen,
  missedCallCount = 0,
  isNewVideoAvailable = false,
}) {
  const {
    state: {
      auth: { user },
      user: {
        gamification: { total, completed },
      },
    },
  } = useContext(StoreContext);

  const [isInfoOpen, setIsInfoOpen] = useState(false);

  useEffect(() => {
    const isLeftBarInfo = parseInt(
      window.localStorage.getItem("isLeftBarInfo"),
    );

    setIsInfoOpen(true);
    if (isLeftBarInfo === 1) {
      setIsInfoOpen(false);
    }
  }, []);

  return (
    <div className="h-full border-r relative  font-ubuntu bg-white dark:bg-darkNight border-lightGray dark:border-matteGray">
      <div className="px-4 pt-6 ">
        <div className="flex items-center space-x-2">
          {isOpen && (
            <div className="bg-white dark:bg-navyGray">
              <div className="flex justify-between items-center">
                <p className="font-medium text-lg text-black dark:text-frescoWhite"></p>
                <span
                  className="bg-frescoWhite rounded-full p-1 cursor-pointer text-darkGray"
                  tabIndex={0}
                  onClick={() => setIsOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    aria-hidden="true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </div>
            </div>
          )}
          <Link to="/" className="flex space-x-1 pl-1">
            <span className="pt-px">
              <MainLogo />
            </span>

            <p className="text-xl font-extrabold text-matteGray dark:text-white font-prozaLibre tracking-wide">
              vibehut
            </p>
          </Link>
        </div>
        <div className="pt-4">
          <NavigationBar
            setIsOpen={setIsOpen}
            missedCallCount={missedCallCount}
            isNewVideoAvailable={isNewVideoAvailable}
          />
        </div>
        {/* <div className="w-full border-t dark:border-matteGray border-frescoWhite mt-4 mb-4"></div> */}
        {/* {user && <QuickWallet />}
        {isInfoOpen && (
          <div className="hidden md:block mt-4">
            <UpGradeCard setIsOpen={setIsInfoOpen} />
          </div>
        )} */}
        {completed !== total && (
          <div className="pt-3">
            <SidebarGamification />
          </div>
        )}
      </div>
      <div className="bottom-2 left-0 absolute  w-full">
        {user ? (
          <div>
            <section className="px-4 pb-1">
              <Link
                to="/plans"
                className="py-3 px-3 bg-gray-100 flex gap-6 items-center rounded-xl"
              >
                <span>
                  <DiamondIcon />
                </span>
                <span>Pricing</span>
              </Link>
            </section>

            <UserMenu user={getUserData(user)} showSocialProvider size="sm-3" />
          </div>
        ) : undefined}
      </div>
    </div>
  );
}

export default LeftSidebar;
