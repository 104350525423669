import { Persona } from "../../../types";
import SpecificChat from "../../chat/SpecificChat";
import Stats from "../../../VibehutUI/Icons/Stats";
import { useContext, useMemo } from "react";
import { StoreContext } from "../../../Context/StoreContext";
import Avatar from "../../../VibehutUI/Avatar";
import { getUserData } from "../../../videoChat/utils/vibehutUtils";

interface Props {
  persona: Persona;
}

function PersonaIntroHeader(props: Props) {
  const { persona } = props;
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;
  const isMyPersona = useMemo(() => user?._id === persona?.owner?._id, []);
  return (
    <div className="w-full max-w-full relative">
      <div className="relative flex flex-col justify-center items-center min-w-0 break-words rounded-xl p-4">
        <div className="relative">
          <img
            src={persona.avatar}
            alt="Persona avatar"
            className="w-16 h-16 rounded-lg border-gradient-br-blue-green-gray-900 gradient-border-2"
          />
          {persona?.companyAffiliation?.imgUrl &&
            persona.companyAffiliation?.isActive && (
              <img
                src={persona.companyAffiliation.imgUrl}
                alt="Company Logo"
                className="w-5 h-5 rounded-lg absolute bottom-1 right-1"
              />
            )}
          {!persona?.companyAffiliation?.imgUrl &&
            !persona.companyAffiliation?.isActive && (
              <span className="w-5 h-5 rounded-lg absolute bottom-1 right-1">
                <Avatar
                  src={getUserData(persona.owner).profile_picture}
                  username={getUserData(persona.owner).username}
                  size="xs"
                />
              </span>
            )}
        </div>
        <div className="pt-4">
          <h5 className="text-lg font-medium text-darkNight dark:text-frescoWhite">
            {persona.name}
          </h5>
        </div>
        <div className="py-4 flex justify-center gap-4">
          {!isMyPersona && (
            <>
              {persona.isEnable ? (
                <SpecificChat chatItem={persona} type="persona" />
              ) : (
                <p className="dark:text-primaryGray text-darkGray text-sm">
                  Disabled
                </p>
              )}
            </>
          )}
          <div className="flex text-darkGray items-center">
            <Stats height={20} width={20} />
            <p>{persona?.personaClickCount || 0}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonaIntroHeader;
