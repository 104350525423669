import React, { useCallback, useContext, useEffect, useState } from "react";
import { setDesoUserForBackend } from "../../services/BitcloutIdentity";
import {
  loginWithBitclout,
  loginWithCustomWallet,
  loginWithMetamask,
} from "../../actions/authActions";
import { baseURL } from "../../utils/axios";
import { StoreContext } from "../../Context/StoreContext";
import { useHistory } from "react-router-dom";
import DeSo from "../Icons/DeSo";
import { useEthers } from "@usedapp/core";
import Metamask from "../Icons/Metamask";
import Facebook from "../Icons/Facebook";
import WalletConnect from "../Icons/WalletConnect";
import { ethers } from "ethers";
import LinkedInLogin from "./LinkedInLogin";
import WorldCoinLogin from "./WorldCoinLogin";
import TwitterV2 from "../Icons/TwitterV2";
import Google from "../Icons/Google";
import { detectInAppBrowser } from "../../utils/utils";
import { useDesoProtocol } from "../../hooks/useDesoProtocol";
import Button from "../Button";
import useIsPWA from "../../hooks/usePWA";
import { SiweMessage } from "siwe";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import useQuery from "../../hooks/useQuery";

function LoginComponent({
  setIsOpen,
  setIsSuccessOpen,
  isModel,
  guestCallUrl = undefined,
  joinCallAsGuest = undefined,
}) {
  const desoProtocol = useDesoProtocol();
  const { activateBrowserWallet, account } = useEthers();
  const { dispatch, state } = useContext(StoreContext);
  const { auth } = state;
  const [waitingOnMetamask, setWaitingOnMetamask] = useState(false);
  const history = useHistory();
  const [isInappBrowser, setIsInappBrowser] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [address, setAddress] = useState("");
  const isPWA = useIsPWA();
  const {
    address: connectedAddress,
    isConnected,
    isDisconnected,
    isReconnecting,
    chain,
    connector,
    status,
    chainId,
  } = useAccount();
  const { open } = useWeb3Modal();

  const handleCustomWalletLogin = useCallback(async (data) => {
    loginWithCustomWallet(data, dispatch)
      .then(() => {
        if (isModel) {
          setIsOpen(false);
          setIsSuccessOpen(true);
        } else {
          history.replace("/");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      return;
    }
    if (isConnected && !isReconnecting) {
      handleCustomWalletLogin({
        provider: connector.name,
        wallet_address: connectedAddress,
        icon: connector.icon,
        walletId: connector.id,
        chain: {
          chainId: chain.id,
          name: chain.name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleCustomWalletLogin,
    isConnected,
    isDisconnected,
    auth?.logoutInProgress,
  ]);
  const query = useQuery();

  const handleCustomLogin = () => {
    open();
  };

  const initialize = useCallback(() => {
    const _isInappBrowser = detectInAppBrowser();
    setIsInappBrowser(_isInappBrowser);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (query.get("invitation_code")) {
      const invitationCode = query.get("invitation_code");
      localStorage.setItem("vibehut_invitation_code", invitationCode);
    }
  }, [query]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleBitcloutLogin = async () => {
    try {
      const identityUser = await desoProtocol.identity.login();
      if (identityUser) {
        let body;
        if (identityUser?.users) {
          body = setDesoUserForBackend(identityUser);
        } else {
          body = { ...identityUser, publicKey: identityUser?.publicKeyAdded };
        }
        loginWithBitclout(body, dispatch).then(() => {
          if (isModel) {
            setIsOpen(false);
            setIsSuccessOpen(true);
          } else {
            history.replace("/");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTwitterLogin = () => {
    window.location.href = `${baseURL}/twitter/authenticate`;
  };

  const handleFacebookLogin = () => {
    window.location.href = `${baseURL}/api/v1/auth/facebook-oauth`;
  };

  const handleGoogleLogin = () => {
    window.location.href = `${baseURL}/api/v1/auth/google-oauth`;
  };

  // const handleInstagramLogin = (e) => {
  //   e.preventDefault();
  //   window.location.href = `${baseURL}/api/v1/auth/instagram-oauth`;
  // };

  // const handleTiktokLogin = () => {
  //   window.location.href = `${baseURL}/api/v1/auth/tiktok-oauth`;
  // };

  async function createSiweMessage(address, statement) {
    const domain = window.location.host;
    const origin = window.location.origin;
    const res = await fetch(
      `${baseURL}/api/v1/auth/login-with-metamask/nonce`,
      {
        credentials: "include",
      },
    );

    const nonce = JSON.parse(await res.text()).data;
    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: "1",
      nonce,
    });
    return message.prepareMessage();
  }

  const getMetamaskAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    return signer.getAddress();
  };

  const handleMetamaskLoginV2 = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();

    const message = await createSiweMessage(
      await signer.getAddress(),
      "Sign in with Ethereum to the app.",
    );
    const signature = await signer.signMessage(message);

    const res = await fetch(
      `${baseURL}/api/v1/auth/login-with-metamask/verify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, signature }),
        credentials: "include",
      },
    );

    const isVerified = JSON.parse(await res.text()).data;

    if (isVerified) {
      const myAddress = await getMetamaskAddress();
      if (myAddress) {
        setAddress(myAddress);
        setWaitingOnMetamask(true);
      }
    }
  };

  const handleMetamaskLogin = async () => {
    if (!account) {
      try {
        await activateBrowserWallet(onActivateBrowserWalletError, true);
      } catch (err) {
        console.log("metamask error: ", err);
      }
    }
    setWaitingOnMetamask(true);
  };

  const onActivateBrowserWalletError = (error) => {
    alert(error.message);
  };

  const getEns = (wallet_address) =>
    new Promise(function (resolve, reject) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      provider.send("eth_requestAccounts", []);

      const ens = provider.lookupAddress(wallet_address);
      resolve(ens);
      reject();
    });

  React.useEffect(() => {
    if (waitingOnMetamask && address) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      if (provider._network?.ensAddress) {
        getEns(address)
          .then((ens) => {
            loginWithMetamask(address, ens, dispatch);
          })
          .then(() => {
            if (isModel) {
              setIsOpen(false);
              setIsSuccessOpen(true);
            } else {
              history.replace("/");
            }
          })
          .catch((err) => {
            console.log("err", err?.message);
          });
      } else {
        loginWithMetamask(address, "", dispatch)
          .then(() => {
            if (isModel) {
              setIsOpen(false);
              setIsSuccessOpen(true);
            } else {
              history.replace("/");
            }
          })
          .catch((err) => {
            console.log("err", err?.message);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, waitingOnMetamask]);

  if (!initialized) {
    return <></>;
  }

  return (
    <div className={`flex font-ubuntu ${isModel ? "dark:bg-navyGray" : ""}`}>
      <div className={`w-full flex justify-center items-center relative`}>
        <div className="my-auto flex gap-8 flex-col py-4">
          <div className="flex justify-center">
            <div className="rounded-xl">
              <img
                width={35}
                src={`${process.env.PUBLIC_URL}/images/Hut.png`}
                alt="Vibehut"
                loading="lazy"
              />
            </div>
          </div>

          <div className="text-center">
            <p className="text-navyGray dark:text-frescoWhite  font-medium text-2xl">
              {isInappBrowser ? "Browser Not Supported" : "Welcome to Vibehut"}
            </p>
            <p className="text-darkGray  pt-2">
              {isInappBrowser
                ? "Try another one.."
                : "Hey, it's great to connect!"}
            </p>
          </div>
          {isInappBrowser ? (
            <div className="flex flex-col space-y-4 space-x-4 items-center">
              <p className="text-navyGray dark:text-frescoWhite text-center">
                Login is not supported in this browser, please try another one
                (Chrome, Safari, etc..)
              </p>
              <Button
                onClick={() => {
                  if (isInappBrowser.includes("ios")) {
                    window.location =
                      "googlechrome-x-callback://x-callback-url/open/?url=" +
                      encodeURIComponent(window.location.href) +
                      "&x-source=Safari&x-success=" +
                      encodeURIComponent(window.location.href);
                  } else {
                    window.open(
                      "intent://vibehut.io/login#Intent;scheme=https;package=com.android.chrome;end",
                      "_blank",
                    );
                  }
                }}
              >
                Open in Chrome
              </Button>
            </div>
          ) : (
            <div className="flex flex-col space-y-4">
              <div className="flex justify-center">
                <button
                  onClick={handleBitcloutLogin}
                  type="button"
                  className="bg-white authBtn border border-gray-400"
                >
                  <span className="flex pl-6 items-center space-x-2">
                    <DeSo />
                    <span className="text-bitClout">Continue with DeSo</span>
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleTwitterLogin}
                  type="button"
                  className="bg-white authBtn border border-gray-400"
                >
                  <span className="flex pl-6 items-center space-x-2 text-black ">
                    <TwitterV2 />
                    <span className="text-bitClout">Continue with X</span>
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleGoogleLogin}
                  type="button"
                  className="bg-white authBtn border border-gray-400"
                >
                  <span className="flex pl-6 items-center space-x-2">
                    <Google />
                    <span className="text-bitClout">Continue with Google</span>
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleMetamaskLoginV2}
                  type="button"
                  className="bg-white authBtn border border-gray-400"
                >
                  <span className="flex pl-6 items-center space-x-2">
                    <Metamask />
                    <span className="text-bitClout">
                      Continue with Metamask
                    </span>
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <LinkedInLogin />
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleFacebookLogin}
                  type="button"
                  className="bg-white authBtn border border-gray-400"
                >
                  <span className="flex pl-6 items-center space-x-2">
                    <Facebook />
                    <span className="text-bitClout">
                      Continue with Facebook
                    </span>
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <WorldCoinLogin />
              </div>
              <div className="flex justify-center">
                <button
                  onClick={handleCustomLogin}
                  type="button"
                  className="bg-white authBtn border border-gray-400"
                >
                  <span className="flex pl-5 items-center space-x-2">
                    <WalletConnect />
                    <span className="text-bitClout">
                      Connect with Your Wallet
                    </span>
                  </span>
                </button>
              </div>
              {joinCallAsGuest && guestCallUrl && (
                <>
                  <p className="text-center text-navyGray dark:text-frescoWhite">
                    or
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        if (!isPWA) {
                          window.open(guestCallUrl);
                        } else {
                          window.location.href = guestCallUrl;
                        }
                      }}
                      type="button"
                      className="bg-white authBtn border border-gray-400"
                    >
                      <span className="flex justify-center space-x-2">
                        <span className="text-bitClout text-center">
                          Join as Guest
                        </span>
                      </span>
                    </button>
                  </div>
                </>
              )}

              <div className="flex justify-center">
                <p className="text-darkGray  pt-2 w-60 text-center text-sm ">
                  By signing up, you agree to our &nbsp;
                  <a href="/terms-condition" className="text-primary">
                    Terms of Service
                  </a>
                  &nbsp;and&nbsp;
                  <a className="text-primary" href="/privacy-policy">
                    Privacy Policy.
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;
