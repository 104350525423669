import { useContext, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { StoreContext } from "../../Context/StoreContext";
import * as actionTypes from "../../constants";
import LimitedTextarea from "../../VibehutUI/LimitedTextarea";
import InputBox from "../../VibehutUI/InputBox/inputBox";
import {
  checkPersonaCompanyAffiliationWritePermission,
  checkUpdatePersonaDetailsPermission,
} from "../../utils/utils";
import Loader from "../../VibehutUI/Loader";
import DoubleStar from "../../VibehutUI/Icons/DoubleStar";
import { generateTagsForPersona } from "../../services/personaService";
import SelectBox from "../../VibehutUI/SelectBox";
import CompanyAffiliation from "./CompanyAffiliation";
import { Switch } from "@headlessui/react";

function AdvanceTab() {
  const { state, dispatch } = useContext(StoreContext);
  const [isGenerating, setIsGenerating] = useState("");
  const {
    persona: { formState, personaPremiumTags },
    role,
    auth: { user },
  } = state;

  const isPersonaCompanyAffiliationWritePermissionIncluded = useMemo(() => {
    return checkPersonaCompanyAffiliationWritePermission(role, user);
  }, [role, user]);

  const handleChangeTextAreaValue = (value: string, name: string) => {
    switch (name) {
      case "highlights":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, highlights: value },
        });
        break;
      case "lowlights":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, lowlights: value },
        });
        break;
      case "peopleShouldKnow":
        dispatch({
          type: actionTypes.UPDATE_FORM_DATA_STATE,
          payload: { ...formState.data, peopleShouldKnow: value },
        });
        break;
      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: actionTypes.UPDATE_FORM_DATA_STATE,
      payload: { ...formState.data, [event.target.name]: event.target.value },
    });
  };

  const handleTagsChange = (values: any) => {
    if (values.length > 3) {
      dispatch({
        type: actionTypes.UPDATE_FORM_ERROR_STATE,
        payload: {
          ...formState.errorMessage,
          tagsError: "Maximum of 3 tags allowed.",
        },
      });
      return;
    }
    dispatch({
      type: actionTypes.UPDATE_FORM_DATA_STATE,
      payload: { ...formState.data, tags: values },
    });
    dispatch({
      type: actionTypes.UPDATE_FORM_ERROR_STATE,
      payload: { ...formState.errorMessage, tagsError: "" },
    });
  };

  const handlePremiumTagsChange = (option: any) => {
    dispatch({
      type: actionTypes.UPDATE_FORM_DATA_STATE,
      payload: { ...formState.data, premiumTag: option },
    });
    dispatch({
      type: actionTypes.UPDATE_FORM_ERROR_STATE,
      payload: { ...formState.errorMessage, premiumTagsError: "" },
    });
  };

  const generateTags = async (tagsType: string) => {
    try {
      if (isGenerating) {
        return;
      }
      setIsGenerating(tagsType);
      let prompt = `Generate a minimum of ${
        tagsType === "premium" ? "1" : "3"
      } and a maximum of ${
        tagsType === "premium" ? "1" : "3"
      } tags without the '#' sign, each in one word for named ${
        formState.data.name
      }.
            About : ${formState.data.about}`;

      if (formState.data?.lookingFor) {
        prompt = prompt + `, Looking For : ${formState.data.lookingFor}`;
      }
      if (formState.data?.notLookingFor) {
        prompt = prompt + `, Not Looking For : ${formState.data.notLookingFor}`;
      }
      if (formState.data?.highlights) {
        prompt = prompt + `, Highlights : ${formState.data.highlights}`;
      }
      if (formState.data?.lowlights) {
        prompt = prompt + `, Lowlights : ${formState.data.lowlights}`;
      }
      const res = await generateTagsForPersona(prompt, user?.token);
      const tags = res.data.tags.map((tag: string) => {
        return { value: tag, label: tag, __isNew__: true };
      });
      if (tagsType === "premiumTags") {
        // handlePremiumTagsChange(tags.slice(0, 1));
      } else {
        handleTagsChange(tags.slice(0, 3));
      }
    } catch (error) {
    } finally {
      setIsGenerating("");
    }
  };

  return (
    <div className="p-4 space-y-1.5">
      <div>
        <div className="flex justify-between">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            Highlights
          </p>
        </div>
        <LimitedTextarea
          limit={500}
          value={formState.data.highlights}
          placeHolder="What have been some major accoplishments?"
          rows={3}
          onChange={(value: string) =>
            handleChangeTextAreaValue(value, "highlights")
          }
        />
      </div>
      <div>
        <div className="flex justify-between">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            Lowlights
          </p>
        </div>
        <LimitedTextarea
          limit={500}
          value={formState.data.lowlights}
          placeHolder="Have there been any difficult moments?"
          rows={3}
          onChange={(value: string) =>
            handleChangeTextAreaValue(value, "lowlights")
          }
        />
      </div>
      <div>
        <div className="flex justify-between">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            What People Should Know
          </p>
        </div>
        <LimitedTextarea
          limit={500}
          value={formState.data.peopleShouldKnow}
          placeHolder=""
          rows={3}
          onChange={(value: string) =>
            handleChangeTextAreaValue(value, "peopleShouldKnow")
          }
        />
      </div>
      {checkUpdatePersonaDetailsPermission(role, user) ? (
        <>
          <div>
            <div className="">
              <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                Premium Tags
              </p>
              <SelectBox
                onChange={(value: any) => {
                  handlePremiumTagsChange(value);
                }}
                selected={formState.data.premiumTag}
                options={personaPremiumTags}
                width="w-full"
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div>
        <div className="flex justify-between">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            Tags
          </p>
          <p
            className="text-sm font-medium text-primary flex items-center gap-1 cursor-pointer"
            onClick={() => generateTags("tags")}
          >
            {" "}
            {isGenerating === "tags" ? (
              <Loader ratio="h-4 w-4" rightMargin={false} />
            ) : (
              <DoubleStar />
            )}{" "}
            <span>
              {" "}
              {isGenerating === "tags" ? "Generating" : "Generate"} using AI
            </span>
          </p>
        </div>
        {formState.data?.tags?.length > 0 &&
          !checkUpdatePersonaDetailsPermission(role, user) && (
            <p className="text-darkGray text-xs">
              Generated Tags, upgrade plan to edit
            </p>
          )}
        <CreatableSelect
          isClearable
          isMulti
          name="tags"
          value={formState.data.tags}
          onChange={handleTagsChange}
          className="basic-multi-select my-1.5 text-sm rounded-xl"
          classNamePrefix="select"
          isDisabled={!checkUpdatePersonaDetailsPermission(role, user)}
        />
        {formState.errorMessage.tagsError && (
          <p className="text-xs text-cvRed">
            {formState.errorMessage.tagsError}
          </p>
        )}
        {!checkUpdatePersonaDetailsPermission(role, user) && (
          <p className="text-sm font-medium text-darkGray ">
            Upgrade your plan to access premium tags.
          </p>
        )}
      </div>
      <InputBox
        type="text"
        primaryLabel="Recorded video link"
        value={formState.data.recordedVideoLink}
        placeholder="Recorded video for persona"
        onChange={(event) => handleChange(event)}
        name="recordedVideoLink"
        customClasses="text-sm"
      />
      <div className="flex mt-3 flex-row">
        <div className="w-4/5">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            Include Company Affiliation (Pro)
          </p>
          <p className="text-xs text-gray-400">Specify company affiliation.</p>
        </div>
        <div className="w-1/5 mt-2">
          <Switch
            checked={formState.data.companyAffiliation?.isActive}
            disabled={!isPersonaCompanyAffiliationWritePermissionIncluded}
            onChange={() =>
              dispatch({
                type: actionTypes.UPDATE_FORM_DATA_STATE,
                payload: {
                  ...formState.data,
                  companyAffiliation: {
                    ...formState.data.companyAffiliation,
                    isActive: !formState.data.companyAffiliation?.isActive,
                  },
                },
              })
            }
            className={`${
              formState.data.companyAffiliation?.isActive
                ? "bg-primary"
                : "bg-gray-200"
            } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
          >
            <span
              className={`${
                formState.data.companyAffiliation?.isActive
                  ? "translate-x-6"
                  : "translate-x-1"
              } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
            />
          </Switch>
        </div>
      </div>
      {formState.data.companyAffiliation?.isActive &&
        isPersonaCompanyAffiliationWritePermissionIncluded && (
          <CompanyAffiliation />
        )}
    </div>
  );
}

export default AdvanceTab;
