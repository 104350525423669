import DoubleStar from "../../VibehutUI/Icons/DoubleStar";

function PersonaWeeklyIntroCard() {
  return (
    <div className="bg-gradient-btn text-white rounded-lg flex items-center px-4 py-3 justify-center flex-col">
      <span>
        <DoubleStar />
      </span>
      <p className="font-bold">Weekly Vibehut Match</p>
      <p className="text-xs">
        We’ve come up with some ideas of Personas you’d like to talk with. We’ve
        taken into consideration your current personas.
      </p>
    </div>
  );
}

export default PersonaWeeklyIntroCard;
