import React, { useContext } from "react";
import { isMobile } from "../../utils/utils";
import CopyIcon from "../Icons/CopyIcon";
import Share from "../Icons/Share";
import PopOver from "../Popover";
import { showToast } from "../../actions/toastActions";
import { StoreContext } from "../../Context/StoreContext";

function CopyButton({
  bgColor,
  title,
  url,
  type = "icon",
  isHoverable = true,
  showCopyIcon = true,
  showIcon = true,
  strokeCurrent = "",
  forMobile = false,
  topPosition = "-top-11",
  leftPosition = "-left-8",
  text = "text-sm",
  cssClasses = "justify-between py-2",
  ratio = "h-9 w-9",
  showSuccessMessage = false,
  buttonTitle = "Copy Link",
  copyText = "",
  disabled = false,
  iconPosition = "left",
}) {
  const [copied, setCopied] = React.useState(false);
  const [showCopyText, setShowCopyText] = React.useState(false);
  const { dispatch } = useContext(StoreContext);

  const toggleCopyText = (isShow) => {
    if (isMobile()) {
      return;
    }
    setShowCopyText(isShow);
  };

  const handleCopyClick = () => {
    if (isMobile() && navigator.share) {
      navigator
        .share({
          title: title,
          url: url,
        })
        .catch(console.error);
    } else {
      const el = document.createElement("input");
      el.value = url || copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setCopied(true);
      if (showSuccessMessage) {
        showToast(dispatch, "success", "Copied", 2000);
      }
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  const IconButton = () => (
    <div className="relative">
      <button
        className={` ${ratio} flex justify-center rounded-full items-center bg-frescoWhite`}
        onMouseEnter={() => toggleCopyText(true)}
        onMouseLeave={() => toggleCopyText(false)}
        disabled={disabled}
        onClick={handleCopyClick}
      >
        {forMobile && isMobile() && navigator.share ? (
          <div className="bg-white rounded-full p-1">
            <Share copied={copied} />
          </div>
        ) : (
          <CopyIcon copied={copied} color={bgColor} stroke={strokeCurrent} />
        )}
      </button>
      {isMobile() && navigator.share
        ? undefined
        : showCopyText && (
            <PopOver
              text={copied ? "Copied!" : buttonTitle || "Copy Link"}
              topPosition={topPosition}
              leftPosition={leftPosition}
            />
          )}
    </div>
  );

  return (
    <div
      onClick={(e) => {
        if (type === "icon") {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {type === "button" ? (
        <div className="relative">
          <button
            onClick={handleCopyClick}
            disabled={disabled}
            onMouseEnter={() => toggleCopyText(true)}
            onMouseLeave={() => toggleCopyText(false)}
            className={`group flex rounded-md items-center  pl-2 pr-1 ${cssClasses}  ${text}cursor-pointer  ${
              disabled ? "cusBtn-vibhut-disabled" : ""
            }`}
          >
            {showIcon && iconPosition === "left" && (
              <div>
                <span>
                  <CopyIcon
                    copied={copied}
                    color={bgColor}
                    stroke={strokeCurrent}
                  />
                </span>
              </div>
            )}
            {buttonTitle}{" "}
            {isMobile() && navigator.share
              ? undefined
              : showCopyText && (
                  <PopOver
                    text={copied ? "Copied!" : buttonTitle || "Copy Link"}
                    topPosition={topPosition}
                    leftPosition={leftPosition}
                  />
                )}
            {showIcon && iconPosition === "right" && (
              <div>
                <span>
                  <CopyIcon
                    copied={copied}
                    color={bgColor}
                    stroke={strokeCurrent}
                  />
                </span>
              </div>
            )}
          </button>
        </div>
      ) : isHoverable ? (
        showCopyIcon ? (
          <IconButton />
        ) : undefined
      ) : (
        <IconButton />
      )}
    </div>
  );
}

export default CopyButton;
