import DoubleStar from "../../../VibehutUI/Icons/DoubleStar";

function PersonaIntroCard() {
  return (
    <div className="bg-primary text-white rounded-lg flex items-center px-4 py-3 justify-center flex-col">
      <span>
        <DoubleStar />
      </span>
      <p className="font-bold">Vibehut Intro</p>
      <p className="text-xs">
        We’ve looked into how you both could get along and came up with a few
        ideas.
      </p>
    </div>
  );
}

export default PersonaIntroCard;
