import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import Button from "../Button";
import * as actionTypes from "../../constants";
import axios, { videoAppUrl } from "../../utils/axios";
import { showLoginModal } from "../../actions/uiModalActions";
import Axios from "axios";
import { checkRoomHasLiveEventAndUserAccess } from "../../services/rooomService";
import { openEventSubscriptionModal } from "../../actions/eventModalActions";
import useIsPWA from "../../hooks/usePWA";
import { isMobile } from "../../utils/utils";
import { getUserWavesById } from "../../services/waveService";

function WaveButton({
  user,
  size = "vibhut-sm",
  notficationId = undefined,
  removeInstantNotification = undefined,
  showOnlyCall = false,
}) {
  const [wave, setWave] = useState(null);
  const [isWaveLoading, setIsWaveLoading] = useState(false);
  const { state, dispatch } = useContext(StoreContext);
  const isPWA = useIsPWA();
  const {
    wave: { isUserWaveing },
  } = state;
  const { auth } = state;

  const [isFetching, setIsFetching] = useState(false);
  const checkAccessAndRedirect = async (roomId) => {
    setIsFetching(true);
    const checkRoomAccess = await checkRoomHasLiveEventAndUserAccess(
      roomId,
      auth?.user?.token,
    );
    if (checkRoomAccess.data.isUserAllowed) {
      setIsFetching(false);
      if (!isPWA) {
        window.open(`${videoAppUrl}/call?roomKey=${wave.room}`);
      } else {
        window.location.href = `${videoAppUrl}/call?roomKey=${wave.room}`;
      }
    } else {
      setIsFetching(false);
      openEventSubscriptionModal(
        dispatch,
        checkRoomAccess.data.event,
        `There is a live event going on in this room. Please subscribe to join the event. Otherwise you can wait join the room after the event ends.`,
        "event",
      );
    }
  };
  const source = Axios.CancelToken.source();

  useEffect(() => {
    if (auth?.user?.token) {
      setIsWaveLoading(true);
      getWave();
    }
    return () => source.cancel();
  }, [auth?.user?.token, isUserWaveing]);

  const getWave = async () => {
    if (user?._id) {
      await getUserWavesById(user._id, auth.user.token, source)
        .then((res) => {
          setWave(res.data.wave);
          setIsWaveLoading(false);
        })
        .catch((error) => {
          if (Axios.isCancel(error)) return;
        });
    }
  };

  const checkWaveStatus = (acceptedStatusUsers) => {
    if (auth?.user?.token) {
      const myWaveStatus = acceptedStatusUsers.find(
        (id) => id === auth.user._id,
      );
      const otherWaveStatus = acceptedStatusUsers.find((id) => id === user._id);

      if (myWaveStatus && otherWaveStatus) {
        return "start_call";
      } else if (myWaveStatus && !otherWaveStatus) {
        return "waved";
      } else if (!myWaveStatus && otherWaveStatus) {
        return "wave_back";
      } else {
        return "wave";
      }
    } else {
      return "wave";
    }
  };

  const handleWave = async (event) => {
    if (!auth?.user) {
      return showLoginModal(dispatch);
    }
    setIsWaveLoading(true);
    event.stopPropagation();
    event.preventDefault();
    if (auth.user) {
      if (auth.user.token !== undefined) {
        axios
          .post(
            "/waves",
            { userId: user._id },
            {
              headers: {
                Authorization: `Bearer ${auth.user.token}`,
              },
            },
          )
          .then((res) => {
            setWave(res.data.data.wave);
            setIsWaveLoading(false);
          });
      }
    } else {
      showLoginModal(dispatch);
    }
  };

  const handleWaveBack = async (event) => {
    setIsWaveLoading(true);
    event.stopPropagation();
    event.preventDefault();
    if (auth.user) {
      if (auth.user?.token !== undefined && wave?._id) {
        axios
          .put(
            "/waves",
            { userId: user._id },
            {
              headers: {
                Authorization: `Bearer ${auth.user.token}`,
              },
            },
          )
          .then((res) => {
            setWave(res.data.data.updateWave);
            setIsWaveLoading(false);
            if (notficationId) {
              removeInstantNotification?.(notficationId);
            }
          });
      }
    } else {
      showLoginModal(dispatch);
    }
  };
  const startCall = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (notficationId) {
      await removeInstantNotification(notficationId);
    }
    const user = wave.waveUsers.find((u) => u._id !== auth.user._id);
    dispatch({
      type: actionTypes.SET_SINGLE_WAVE_SUCCESS,
      payload: {
        waveUser: user?.bitclout_data?.username,
      },
    });
    checkAccessAndRedirect(wave.room);
  };

  return (
    <>
      {isWaveLoading ? (
        <div
          className={`animate-pulse bg-frescoWhite dark:bg-darkGray ${
            size === "vibhut-xs" ? "px-5 py-1" : "px-10 py-1.5"
          }  rounded-3xl inline-flex `}
        >
          &nbsp;
        </div>
      ) : (
        <>
          {wave === null ? (
            <Button
              size={size}
              variant="vibhut-primary"
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await handleWave(e);
                dispatch({
                  type: actionTypes.IS_USER_WAVEING,
                });
              }}
            >
              Wave 👋
            </Button>
          ) : undefined}
          {wave?.acceptedStatusUsers &&
          wave.acceptedStatusUsers.length > 0 &&
          checkWaveStatus(wave.acceptedStatusUsers) === "waved" ? (
            <Button size={size} variant="vibhut-primary">
              Waved!
            </Button>
          ) : undefined}

          {wave?.acceptedStatusUsers &&
          wave.acceptedStatusUsers.length > 0 &&
          checkWaveStatus(wave.acceptedStatusUsers) === "wave_back" ? (
            <Button
              size={size}
              variant="vibhut-primary"
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await handleWaveBack(e);
                dispatch({
                  type: actionTypes.IS_USER_WAVEING,
                });
              }}
            >
              {isMobile() ? "👋" : "Wave"} Back
            </Button>
          ) : undefined}

          {wave?.acceptedStatusUsers &&
          wave.acceptedStatusUsers.length > 0 &&
          checkWaveStatus(wave.acceptedStatusUsers) === "start_call" ? (
            <Button size={size} variant="vibhut-primary" onClick={startCall}>
              {showOnlyCall ? "Call" : isFetching ? "Joining..." : "Join Call"}
            </Button>
          ) : undefined}
        </>
      )}
    </>
  );
}

export default WaveButton;
