import { baseURL } from '../constants';
import { globals } from '../globals';
import axios from '../utils/axios';
import { removeUndefinedKeys } from '../utils/vibehutUtils';

export const getRoomLobbyData = async ({ roomId, password, v }: any) => {
  const _authUser = globals.user;
  // if (!_authUser) {
  //   return;
  // }
  const token = _authUser.token;

  const endpoint = `${baseURL}/api/v1/rooms/lobby-data`;
  const params = removeUndefinedKeys({ roomId, password, v });

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  }).then(res => res.json());
};

export const verifyPassword = async (roomId: string, password: string) => {
  const endpoint = `${baseURL}/api/v1/rooms/verify-password/${roomId}`;
  const params = removeUndefinedKeys({ password });

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then(res => res.json());
};

export const verifyEventPassword = async (eventId: string, password: string) => {
  const endpoint = `${baseURL}/api/v1/events/verify-password/${eventId}`;
  const params = removeUndefinedKeys({ password });

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then(res => res.json());
};

export const inviteDesoUser = async (data: any, token?: string, source?: any) => {
  return axios.post('/rooms/invite-deso-user', data, source, token);
};

export const inviteUser = async (data: any, token?: string, source?: any) => {
  return axios.post('/rooms/invite-user', data, source, token);
};

export const getCallUsers = async (calId: string, token?: string, source?: any) => {
  return axios.get(`/calls/${calId}/user`, source, token);
};

export const userJoinedRoom = async (token?: string, source?: any) => {
  return axios.get('/rooms/user-joined', source, token);
};

export const updateBreakoutRoomNames = async (roomId: string, breakoutRoomNames: any[]) => {
  const _authUser = globals.user;
  if (!_authUser) {
    return;
  }
  const token = _authUser.token;
  const endpoint = `${baseURL}/api/v1/rooms/update-breakout-room-names/${roomId}`;
  const params = removeUndefinedKeys({ breakoutRoomNames });

  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  }).then(res => res.json());
};

export const updateCallSocketReconnect = async (token?: string) => {
  return axios.post('/calls/socket/re-connect', {}, undefined, token);
};

export const updateUnmatchedUserCountsInCall = async (
  token: string,
  callId: string,
  twilioParticipants: any[],
  socketConnections: any[]
) => {
  return axios.post(
    `/calls/${callId}/unmatched-counts`,
    {
      twilioParticipants,
      socketConnections,
    },
    undefined,
    token
  );
};
