import { createContext, useContext, useEffect, useState } from "react";
import { StoreContext } from "./StoreContext";
import { checkIsDesoUser } from "../utils/utils";
import globals from "../globals";
import { logout } from "../actions/authActions";
import Button from "../VibehutUI/Button";
import DesoProtocol, { identity } from "deso-protocol";

export let desoIdentity: typeof identity;

export interface DesoProtocolContextType {
  desoProtocol: typeof DesoProtocol;
}

interface Props {
  children: any;
}
export const DesoProtocolContext = createContext<DesoProtocolContextType>({
  desoProtocol: {} as any,
});

export const DesoProtocolProvider = ({ children }: Props) => {
  const { state, dispatch } = useContext(StoreContext);
  const [desoProtocol, setDesoProtocol] = useState<typeof DesoProtocol | null>(
    null,
  );
  const { auth } = state;

  useEffect(() => {
    const loadDeso = async () => {
      const deso = await import("deso-protocol");
      deso.configure({
        spendingLimitOptions: {
          GlobalDESOLimit: 5 * 1e9,
          TransactionCountLimitMap: {
            SUBMIT_POST: "UNLIMITED",
            BASIC_TRANSFER: "UNLIMITED",
            LIKE: "UNLIMITED",
            CREATE_POST_ASSOCIATION: "UNLIMITED",
          },
        },
        MinFeeRateNanosPerKB: 12500,
        appName: "Vibehut",
      });
      setDesoProtocol(deso);
      desoIdentity = deso.identity;
    };
    loadDeso();
  }, []);

  const checkTransactionPermission = async () => {
    if (desoProtocol && checkIsDesoUser(auth?.user)) {
      const response = await desoProtocol.identity.hasPermissions({
        TransactionCountLimitMap: {
          NEW_MESSAGE: "UNLIMITED",
        },
      });

      if (response) {
        globals.showModal(
          "Session Expired",
          <>
            <div className=" justify-center p-4  rounded-2xl  ">
              <div>
                <div className="flex flex-col items-center gap-4">
                  <div>
                    <p className="dark:text-frescoWhite">
                      Please logout and login again.
                    </p>
                  </div>
                  <Button
                    onClick={async () => {
                      const jwt = await desoProtocol.identity.jwt();
                      if (jwt) {
                        await desoProtocol.identity.logout();
                      }
                      logout(dispatch);
                      window.location.replace("/login");
                    }}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          </>,
        );
      }
    }
  };

  useEffect(() => {
    if (desoProtocol && checkIsDesoUser(auth?.user)) {
      try {
        checkTransactionPermission();
        desoProtocol.identity.hasPermissions({
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED",
          },
        });
        desoProtocol.identity.setActiveUser(
          auth.user.bitclout_data.bitclout_publickey,
        );
      } catch (error) {
        logout(dispatch);
        globals.showModal(
          "Session Expired",
          <>
            <div className=" justify-center p-4  rounded-2xl  ">
              <div>
                <div className="flex flex-col items-center gap-4">
                  <div>
                    <p className="dark:text-frescoWhite">Please login again.</p>
                  </div>
                  <Button
                    onClick={() => {
                      logout(dispatch);
                      window.location.replace("/login");
                    }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </>,
        );
      }
    }
  }, [desoProtocol, auth?.user?.bitclout_data?.bitclout_publickey]);

  if (!desoProtocol) {
    return null;
  }

  return (
    <DesoProtocolContext.Provider value={{ desoProtocol }}>
      {children}
    </DesoProtocolContext.Provider>
  );
};
