import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../Context/StoreContext";
import { hideToast } from "../actions/toastActions";

const Toast = ({ autoHideDuration }) => {
  const { state, dispatch } = useContext(StoreContext);
  const { globalToast } = state;
  const { show, message, type } = globalToast;

  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    setIsVisible(show);
  }, [globalToast]);

  let bgColor;
  switch (type) {
    case "success":
      bgColor = "bg-primary";
      break;
    case "warning":
      bgColor = "bg-yellow-500";
      break;
    case "error":
      bgColor = "bg-red-500";
      break;
    default:
      bgColor = "bg-gray-500";
      break;
  }

  useEffect(() => {
    let hideTimer;

    if (autoHideDuration && isVisible) {
      hideTimer = setTimeout(() => {
        setIsVisible(false);
        hideToast(dispatch);
      }, autoHideDuration);
    }

    return () => {
      clearTimeout(hideTimer);
    };
  }, [autoHideDuration, isVisible]);

  const handleClose = () => {
    setIsVisible(false);
    hideToast(dispatch);
  };

  return (
    <div
      className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 bottom-2rem p-4 rounded-md shadow-md text-white ${bgColor} ${
        isVisible ? "opacity-100 z-50" : "opacity-0 z-0 pointer-events-none"
      } transition-all duration-500`}
    >
      <div className="flex items-center justify-between">
        <p className="text-center">{message}</p>
        <button
          className="ml-2 text-white hover:text-gray-200"
          onClick={handleClose}
        >
          <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M10 8.586l3.536-3.536 1.414 1.414L11.414 10l3.536 3.536-1.414 1.414L10 11.414l-3.536 3.536-1.414-1.414L8.586 10 5.05 6.464l1.414-1.414L10 8.586z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Toast;
