import moment from "moment";
import * as actionTypes from "../constants";
import * as chatService from "../services/chatService";
import { getUserData } from "../services/userService";
import { saveChatMessagesToLocalStorage } from "../utils/utils";

export function getChatByRoomId(
  roomId,
  token,
  dispatch,
  authId,
  loadMore,
  offset,
  source,
) {
  if (!token) {
    return;
  }

  dispatch({
    type: actionTypes.GET_ROOM_MESSAGES_REQUEST,
    loadMore: loadMore,
  });
  const storedChatMessages =
    JSON.parse(localStorage.getItem("ChatStore")) || {};
  const cachedRoomMsg = storedChatMessages[roomId] || [];

  if (cachedRoomMsg && cachedRoomMsg.length > 0) {
    dispatch({
      type: actionTypes.GET_ROOM_MESSAGES_SUCCESS,
      payload: cachedRoomMsg,
      loadMore: loadMore,
      offset: offset,
    });
  }
  return chatService
    .getChatByRoomId(roomId, token, offset, source)
    .then((res) => {
      const resData = res.data;
      const chats = [];
      for (let i = 0; i < resData.length; i++) {
        const obj = {
          isBot: resData[i].isBot ? true : false,
          isDanger: resData[i].isDanger ? true : false,
          isSend:
            resData[i].isAmiBot || resData[i].isPersonaChat
              ? false
              : authId === resData[i]?.userId?._id
                ? true
                : false,
          isAmiBot: resData[i].isAmiBot ? true : false,
          isPersonaChat: resData[i].isPersonaChat ? true : false,
          message: resData[i].msg,
          user: getUserData(resData[i].userId),
          username: getUserData(resData[i].userId).username,
          isRead: resData[i].isRead ? true : false,
          isReply: resData[i].isReply ? true : false,
          replyFor: resData[i].replyFor ? resData[i].replyFor : undefined,
          isDelivered: resData[i].isDelivered ? true : false,
          _id: resData[i]._id,
          room: resData[i].room,
          date: new Date(resData[i].createdAt).toLocaleString().split(",")[0],
          createdAt: moment(resData[i].createdAt).format("hh:mm A"),
          isEdited: resData[i]?.isEdited ? true : false,
          reactions: resData[i]?.reactions,
          usersMentioned: resData[i]?.usersMentioned
            ? resData[i]?.usersMentioned
            : [],
          isEncrypted: !resData[i]?.isEncrypted ? false : true,
          persistedAt: resData[i]?.persistedAt,
          attachments: resData[i]?.attachments ? resData[i]?.attachments : [],
          deletedAt: resData[i]?.deletedAt ? resData[i]?.deletedAt : undefined,
          messageType: resData[i]?.messageType
            ? resData[i]?.messageType
            : undefined,
          replyFor: {
            ...resData[i]?.replyFor,
            username: resData[i]?.replyFor?.userId
              ? getUserData(resData[i]?.replyFor?.userId)?.username
              : "",
          },
        };
        chats.push(obj);
      }
      dispatch({
        type: actionTypes.GET_ROOM_MESSAGES_SUCCESS,
        payload: chats.reverse(),
        loadMore: loadMore,
        offset: offset,
      });
      saveChatMessagesToLocalStorage(roomId, chats);
    })
    .catch((err) => {
      throw err;
    });
}

export function countUnreadMessages(token, dispatch) {
  dispatch({
    type: actionTypes.GET_COUNT_UNREAD_MSG_REQUEST,
  });
  return chatService
    .countUnreadMessages(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_COUNT_UNREAD_MSG_SUCCESS,
        payload: {
          unreadMessagesCount: res.data.length,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_COUNT_UNREAD_MSG_FAILURE,
      });
      throw err;
    });
}

export function unlockChatRoom(dispatch, chatRoomId) {
  dispatch({
    type: actionTypes.UNLOCK_CHAT_ROOM_FAILURE,
  });

  dispatch({
    type: actionTypes.UNLOCK_CHAT_ROOM_SUCCESS,
    payload: {
      chatRoomId: chatRoomId,
    },
  });
}
