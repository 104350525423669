import React from "react";

function ReplyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 96 96"
    >
      <switch>
        <g>
          <path
            fill="currentColor"
            d="M56 36H17.656l18.628-18.627a4 4 0 0 0-5.657-5.657L5.172 37.171a4 4 0 0 0 0 5.657l25.455 25.456a4 4 0 0 0 5.657-5.657L17.658 44H56c15.464 0 28 12.536 28 28v4a4 4 0 0 0 8 0v-4c0-19.883-16.118-36-36-36z"
          ></path>
        </g>
      </switch>
    </svg>
  );
}

export default ReplyIcon;
