import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ChatWindowHeader from "./ChatWindowHeader/ChatWindowHeader";
import clsx from "clsx";
import useVibehutChatContext from "../../hooks/useVibehutChatContext/useVibehutChatContext";
import LiveStreamChat from "../../../components/chat/LiveStreamChat";
import useLiveVideo from "../../hooks/useLiveVideo/useLiveVideo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: "#303234",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
      },
    },
    hide: {
      display: "none",
    },
  }),
);

export default function LiveStreamChatWindow() {
  const classes = useStyles();
  const { isLiveStreamChatWindowOpen } = useVibehutChatContext();
  const { isLive, liveStreamChatId } = useLiveVideo();
  return (
    <aside
      className={clsx(classes.chatWindowContainer, {
        [classes.hide]: !isLiveStreamChatWindowOpen,
      })}
    >
      <ChatWindowHeader isLive />
      <div className="px-4 pb-4">
        <LiveStreamChat roomId={liveStreamChatId} fromCall isLive={isLive} />
      </div>
    </aside>
  );
}
