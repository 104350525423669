import React from "react";
import Play from "../../VibehutUI/Icons/Play";
import UserCard from "../../VibehutUI/UserCard";
import { getUserData } from "../../services/userService";
import moment from "moment";
import { Link } from "react-router-dom";

function VideoCard({ video }) {
  return (
    <Link to={`/watch/${video._id}`}>
      <div className=" rounded-lg border dark:border-matteGray border-lightGray">
        <div className="py-4 pl-3 pr-2 flex items-center justify-between">
          <p className="text-lg font-bold font-ubuntu text-darkNight dark:text-frescoWhite custom-two-line-clamp">
            {video.title}
          </p>
          <div className="flex items-center justify-end gap-2  w-1/5">
            <p className="font-ubuntu text-sm text-darkGray  font-normal">
              {moment(video.createdAt).fromNow()} &nbsp;
            </p>
          </div>
        </div>
        <div className="relative">
          <img
            src={video?.thumbnail ? video?.thumbnail : `${process.env.PUBLIC_URL}/banner.png`}
            alt="thumbnail"
            className="max-h-60 w-full object-cover aspect-video"
          />
          <span className="bg-lightGray w-12 h-12 flex items-center justify-center rounded-full cursor-pointer  centerAbsolute">
            <span className="pl-1">
              <Play />
            </span>
          </span>
        </div>
        <div className="px-2 py-2 flex items-center justify-between">
          <div>
            {video?.owner?.length > 0 && (
              <UserCard
                user={getUserData(video.owner[0])}
                size="sm-2"
                room={
                  video.room[0].isWaveRoom ? "Wave Call" : video.room[0]?.name
                }
                roomId={
                  video.room[0].isWaveRoom || video.room[0].isInstantRoom
                    ? undefined
                    : video.room[0]?._id
                }
                showSocialProvider={false}
              />
            )}
          </div>
          <div className="pr-2 w-1/3 flex flex-col items-end ">
            <p
              className={`font-ubuntu text-sm font-normal cursor-pointer text-darkGray`}
            >
              {video.views[0]?.totalCount || 0}{" "}
              {video.views[0]?.totalCount === 1 ? "view" : "views"}
            </p>
            <p
              className={`font-ubuntu text-sm font-normal cursor-pointer text-darkGray`}
            >
              {video.likes.length || 0}{" "}
              {video.likes?.length === 1 ? "like" : "likes"}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default VideoCard;
