import { Dialog, Transition } from "@headlessui/react";
import React, { useState, Fragment } from "react";
import globals from "../../globals";
import { ChevronDownIcon } from "@heroicons/react/solid";

function RoomDropdownMobile() {
  let [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div
        className="border border-grayLighter rounded-3xl px-3 py-1 flex items-center justify-between cursor-pointer space-x-1"
        onClick={() => setIsOpen(true)}
      >
        <span className="text-black dark:text-frescoWhite">Filter</span>{" "}
        <span className="pt-1">
          <ChevronDownIcon
            className={`h-5 w-5 text-black dark:text-frescoWhite  transition ease-in-out duration-150`}
            aria-hidden="true"
          />
        </span>
      </div>
      <FilterModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}

const ROOM_TYPES = [
  {
    type: "PUBLIC",
    desc: "Anyone can access these",
    label: "Public Rooms",
  },
  {
    type: "NFT_ACCESSIBLE",
    desc: "You own the required NFT in your metamask wallet.",
    label: "NFT Accessible Rooms",
  },
  {
    type: "NFT_NOT_ACCESSIBLE",
    desc: "You don't own the required NFT in your metamask wallet.",
    label: "NFT Not Accessible Rooms",
  },
];

function FilterModal({ isOpen, setIsOpen }) {
  const [roomTypes, setRoomTypes] = React.useState([]);
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setRoomTypes([...roomTypes, event.target.value]);
    } else {
      const index = roomTypes.indexOf(event.target.value);
      roomTypes.splice(index, 1);
      setRoomTypes([...roomTypes]);
    }
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed -bottom-1 left-0 right-0 z-40"
          onClose={() => setIsOpen(false)}
        >
          <div className="pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-100 translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-full"
              leaveTo="opacity-0 translate-y-full"
            >
              <div className="inline-block w-full p-6  overflow-hidden text-left transition-all transform bg-white dark:bg-navyGray shadow-xl rounded-2xl">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-black dark:text-frescoWhite"
                  >
                    Filters
                  </Dialog.Title>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
                <hr className="mt-3" />
                <div className="mt-2">
                  <div className="overflow-hidden">
                    <div className="relative flex-col space-y-4 flex bg-white dark:bg-navyGray py-2">
                      {ROOM_TYPES.map((type, index) => (
                        <div key={index}>
                          <label>
                            <input
                              type="checkbox"
                              name="roomTypes"
                              value={type.type}
                              className="opacity-0 absolute h-8 w-8"
                              onChange={handleChange}
                              checked={roomTypes.includes(type.type)}
                            />
                            <div className="flex items-center">
                              <div className="bg-white border-2 rounded-md border-primary w-6 h-6 flex flex-shrink-0 justify-center items-center mr-4 focus-within:border-primary">
                                <svg
                                  className="fill-current hidden w-3 h-3 text-primary pointer-events-none"
                                  version="1.1"
                                  aria-hidden="true"
                                  focusable="false"
                                  viewBox="0 0 17 12"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <g
                                      transform="translate(-9 -11)"
                                      fill="#716DFF"
                                      fillRule="nonzero"
                                    >
                                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="flex flex-col">
                                <span className="font-medium text-gray-800 dark:text-gray-200">
                                  {type.label}
                                </span>
                                <span className=" text-gray-400">
                                  {type.desc}
                                </span>
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="py-4 flex space-x-4">
                      <button
                        onClick={() => setRoomTypes([])}
                        className="text-navyGray bg-frescoWhite w-full rounded-3xl px-6"
                      >
                        Clear
                      </button>
                      <button
                        onClick={() => {
                          globals.onApplyRoomFilter(roomTypes);
                          setIsOpen(false);
                        }}
                        className="text-base font-medium rounded-3xl py-3 bg-primary text-white px-6 w-full"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default RoomDropdownMobile;
