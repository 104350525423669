import React, { createContext, useEffect, useState, useRef } from "react";
import { globals } from "../../globals";
import * as io from "socket.io-client";
import { baseURL } from "../../constants";
import UserInAnotherRoomModal from "../../VibehutUI/UserInAnotherRoomModal";
import {
  getUserActiveRoomStatus,
  leaveLastActiveCall,
} from "../../services/userService";
import { updateCallSocketReconnect } from "../../services/roomService";
import { playCallJoinSound, wait } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { getRoomIdByRoomSlug } from "../../../services/rooomService";

type AuthUserContextType = {
  authUser: any;
};

export const AuthUserContext = createContext<AuthUserContextType>(null!);

interface Props {
  children: any;
}

export const AuthUserContextProvider = ({ children }: Props) => {
  const [authUser, setAuthUser] = useState();
  const [connectSocket, setConnectSocket] = useState(false);
  const [isBlockerModalOpen, setIsBlockerModalOpen] = useState(false);
  const [showContinueLoading, setShowContinueLoading] = useState(false);
  const isMounted = useRef(false);
  const { roomId, slug } = useParams<{
    URLRoomName?: string;
    roomId?: string;
    slug?: string;
  }>();

  globals.reconnectBackendSocket = React.useCallback(() => {
    const token = (authUser as any)?.token;
    if (!token) {
      return;
    }

    if (globals.socket && globals.socket.connected) {
      globals.socket.disconnect();
    }

    if (globals.socket) {
      globals.socket.removeAllListeners();
    }

    connectBackendSocket(token);
  }, [authUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (connectSocket && globals.socket && !globals.socket.connected) {
        globals.reconnectBackendSocket();
        const token = (authUser as any)?.token;

        updateCallSocketReconnect(token).catch(console.error);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [connectSocket]);

  async function _getRoomKeyFromUrl() {
    if (slug) {
      const response = await getRoomIdByRoomSlug(slug);
      if (response.data?.roomId) {
        return response.data.roomId;
      }
      return "";
    }
  }

  useEffect(() => {
    isMounted.current = true;
    globals.guestUserDetails = async (user: any) => {
      if (isMounted.current) {
        setAuthUser(user);
        const token = user.token;
        globals.user = user;
        let roomKey = new URLSearchParams(window.location.search).get(
          "roomKey",
        );
        if (!roomKey && roomId) {
          roomKey = roomId as string;
        }
        if (!roomKey && slug) {
          roomKey = await _getRoomKeyFromUrl();
        }
        try {
          window.localStorage.setItem("currentCallRoomId", roomKey || "");
          window.localStorage.setItem("user", JSON.stringify(user));
          connectBackendSocket(token);
        } catch (error) {}
      }
    };

    const authUserStr = window.localStorage.getItem("user");
    if (!authUserStr) {
      return;
    }

    const _authUser = JSON.parse(authUserStr);

    const token = _authUser.token;

    setAuthUser(_authUser);
    globals.user = _authUser;

    checkUserStatus(token);

    const intervalId = setInterval(() => {
      if ((globals.socket as any)?.emit) {
        (globals.socket as any)?.emit("ping-pong");
      }
    }, 30000);

    return () => {
      clearInterval(intervalId);
      (globals.socket as any)?.disconnect();
      isMounted.current = false;
    };
  }, [roomId, slug]);

  const checkUserStatus = async (token: string) => {
    const userStatus = (await getUserActiveRoomStatus(token)).data;

    if (userStatus.isActiveInAnotherRoom) {
      setIsBlockerModalOpen(true);
    } else {
      setConnectSocket(true);
    }
  };

  const token = (authUser as any)?.token;

  const connectBackendSocket = (p_token: string) => {
    globals.socket = io.connect(baseURL, {
      auth: {
        token: p_token,
      },
    });

    window.onbeforeunload = () => {
      globals.socket?.disconnect();
    };

    globals.socket.on("hello-server", (p_data) => {
      globals.helloServer(p_data);
    });

    globals.socket.on("chat start", (p_data: any) => {
      globals.onChatStart(p_data);
    });

    globals.socket.on("update_call", (p_data: any) => {
      globals.onUpdateCall(p_data);
    });

    globals.socket.on("chat end", () => {
      globals.onChatEnd();
    });

    globals.socket.on("chat_message", (p_data: any) => {
      globals.onChatMessage(p_data);
    });

    globals.socket.on("ami_chat_request", (p_data: any) => {
      const data = {
        ...p_data,
        isAmiRequest: true,
      };
      globals.onChatMessage(data);
    });

    globals.socket.on("ami_chat_response", (p_data: any) => {
      globals.onDeletedMessage(p_data);
    });

    globals.socket.on("user_join_call", () => {
      playCallJoinSound();
    });

    globals.socket.on("user_boosted_call", (userId) => {
      globals.boostedCallData = {
        userId,
        isBoosted: true,
      };
    });

    globals.socket.on("update_chat_id", (p_data: any) => {
      globals.onChatIdUpdate(p_data);
    });

    globals.socket.on("recordingConsent", (p_data: any) => {
      globals.onRecordingConcent(p_data);
    });

    globals.socket.on("isRecording", (p_data: any) => {
      globals.onIsRecording(p_data);
    });

    globals.socket.on("userKickedOut", () => {
      globals.onUserKickOut();
    });

    globals.socket.on(
      "guestUserJoinCallRequest",
      ({ guestUsername, guestUserId, profilePicture }) => {
        globals.onGuestUserJoinCallRequest(
          guestUsername,
          guestUserId,
          profilePicture,
        );
      },
    );

    globals.socket.on("isRequestAcceptByHost", ({ isAllowed }) => {
      globals.onIsRequestAcceptByHost(isAllowed);
    });

    globals.socket.on("join_room_callback", (p_data: any) => {
      globals.onJoinRoomCallback(p_data);
    });

    globals.socket.on("initiateAnotherCall", (p_data: any) => {
      globals.onInitiateCall(p_data);
    });

    globals.socket.on("call_chat_reaction_added", (p_data: any) => {
      globals.onChatReactionAdded(p_data);
    });
    globals.socket.on("call_chat_reaction_updated", (p_data: any) => {
      globals.onChatReactionUpdate(p_data);
    });
    globals.socket.on("call_chat_reaction_same", (p_data: any) => {
      globals.onChatReactionAdded(p_data);
    });

    globals.socket.on("userMuteOut", (p_data: any) => {
      globals.onMuteUser(p_data);
    });

    globals.socket.on("room_promotion", (p_data: any) => {
      globals.onRoomPromotion(p_data);
    });

    globals.socket.on("save_message_to_room", (p_data: any) => {
      globals.onSaveMessageToRoom(p_data);
    });

    globals.socket.on("callLiveToggle", (p_data) => {
      globals.onCallLiveToggle(p_data);
    });

    globals.socket.on("closeWindowNow", () => {
      globals.closeWindowNow();
    });

    globals.socket.on("send-reaction-in-call", (p_data: any) => {
      globals.onSendReactionInCall(p_data);
    });

    globals.isSocketConnected = true;

    return globals.socket;
  };

  useEffect(() => {
    if (!token || !connectSocket) {
      return;
    }
    const socket = connectBackendSocket(token);
    return () => {
      (socket as any)?.disconnect();
      isMounted.current = false;
    };
  }, [token, connectSocket]);

  const onContinue = async () => {
    try {
      setShowContinueLoading(true);
      // call a new api for making the other user leave
      await leaveLastActiveCall(token);

      // close the model in 2 seconds.
      await wait(2000);
      setConnectSocket(true);
      setIsBlockerModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setShowContinueLoading(false);
    }
  };

  // if (!authUser) {
  //   return <span>Loading...</span>;
  // }

  return (
    <AuthUserContext.Provider
      value={{
        authUser,
      }}
    >
      {children}
      <UserInAnotherRoomModal
        isOpen={isBlockerModalOpen}
        onContinue={onContinue}
        showContinueLoading={showContinueLoading}
        onLeave={() => window.close()}
      />
    </AuthUserContext.Provider>
  );
};
