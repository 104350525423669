import React, { useState } from "react";
import Linkify from "react-linkify";
import ReactHtmlParser from "react-html-parser";
import { getDecryptMessage } from "../../utils/utils";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import PopOver from "../Popover";
import AddReaction from "../../icons/AddReaction";
import Reactions from "../../components/ChatWindow/Reactions";
import ChatReaction from "../../components/ChatWindow/ChatReaction";
import SaveMessage from "../../components/ChatWindow/SaveMessage";
import { isMobile } from "../../../utils/utils";
import Rocket from "../../../VibehutUI/Icons/Rocket";
import AllReactions from "../../../components/chat/AllReactions";
import { globals } from "../../globals";
import { Lightbox } from "react-modal-image";

interface chatBubble {
  message: string;
  isSend: boolean;
  messageData: any;
  index: number;
  addReaction: (reaction: any) => void;
  addUserToReaction: (reaction: any) => void;
  removeUserOrAddReaction: (reaction: any) => void;
  isDanger: boolean;
  roomId: string;
}

function ChatBubble({
  messageData,
  index,
  message,
  isSend,
  isDanger,
  addReaction,
  addUserToReaction,
  removeUserOrAddReaction,
  roomId,
}: chatBubble) {
  const { authUser } = useAuthUser();
  const [isShowOptionIcon, setIsShowOptionIcon] = useState(false);
  const [isOpenAddReaction, setIsOpenAddReaction] = useState(false);
  const [showReactionsPopover, setShowReactionsPopover] = useState(false);
  const [showImgBox, setShowImgBox] = useState(false);

  const direction = isSend
    ? "float-right bg-primary text-left"
    : `float-left ${isDanger ? "bg-cvRed" : "bg-matteGray"}   text-left`;

  const saveMessageIcondirection = isSend
    ? "float-right bg-primary text-left mr-2"
    : `float-left ml-2 ${isDanger ? "bg-cvRed" : "bg-matteGray"}   text-left`;

  const CustomLinkify: any = Linkify;

  const componentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ) => (
    <a
      href={decoratedHref}
      key={key}
      target="_blank"
      className="text-white text-weight-bold break-all underline"
      rel="noreferrer"
    >
      {decoratedText}
    </a>
  );

  const handleInstantReaction = (react: any) => {
    const reaction = {
      emoji: react.emoji,
      unified: react.unified,
      owner: authUser?._id,
    };

    addReaction({ ...reaction, chatId: messageData._id });
  };

  let pressTimer: any;
  function handleTouchMove() {
    clearTimeout(pressTimer);
  }

  function handleTouchEnd() {
    clearTimeout(pressTimer);
  }

  const handleShowLightBox = () => {
    setShowImgBox(true);
  };

  const initialReactions = [
    { emoji: "❤️", unified: "2764-fe0f", name: "Heart" },
    { emoji: "👍", unified: "1f44d", name: "Thumbs Up" },
    { emoji: "🙌", unified: "1f64c", name: "Raising Hands" },
    { emoji: "😂", unified: "1f602", name: "Face with Tears of Joy" },
    { emoji: "👀", unified: "1f440", name: "Eyes" },
    { emoji: "👎", unified: "1f44e", name: "Thumbs Down" },
    { emoji: "🤖", unified: "1f916", name: "Robot" },
  ];

  const _renderSpaces = (spaceLen: number) => {
    let spaces = "";
    for (let i = 0; i < spaceLen; i++) {
      spaces += " ";
    }
    return spaces;
  };

  const spaceLen = message ? message.length - message.trimLeft().length : 0;

  const getMessage = () => {
    if (message && messageData.isAmiBot) {
      return ReactHtmlParser(message);
    } else {
      if (message && messageData) {
        if (!messageData?.isEncrypted) {
          return message.trimLeft().replace(/^@ami\b/g, "");
        }
        const decryptMessage = getDecryptMessage(
          roomId,
          messageData?.isEncrypted,
          message,
        );
        const msg = decryptMessage!.trimLeft().replace(/^@ami\b/g, "");
        return msg ? msg : "";
      }
      return "";
    }
  };

  const toggleReactionsPopover = (isShow: boolean) => {
    if (isMobile()) {
      return;
    }
    setShowReactionsPopover(isShow);
  };

  const allReactions = () => {
    globals.showModal("All Reactions", <AllReactions chat={messageData} />);
  };

  return (
    <div className="flow-root relative">
      <div
        onMouseEnter={() => setIsShowOptionIcon(true)}
        onMouseLeave={() => setIsShowOptionIcon(false)}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <span
          style={{ maxWidth: "20rem", minWidth: "2.5rem" }}
          className={`break-words whitespace-pre-wrap p-2 text-white rounded-2xl text-sm ${direction}`}
        >
          {message.trim().substring(0, 4) === "@ami" && (
            <span className="font-bold">
              {_renderSpaces(spaceLen)}
              @ami
            </span>
          )}
          <CustomLinkify componentDecorator={componentDecorator}>
            {getMessage()}
          </CustomLinkify>
          {messageData?.attachments?.length > 0 && !messageData?.deletedAt && (
            <div>
              <img
                alt="room"
                src={messageData?.attachments[0].mediaUrl}
                className="rounded-xl object-contain cursor-pointer"
                loading="lazy"
                onClick={handleShowLightBox}
              />
              {showImgBox && (
                <Lightbox
                  medium={messageData?.attachments[0].mediaUrl}
                  large={messageData?.attachments[0].mediaUrl}
                  onClose={() => setShowImgBox(false)}
                />
              )}
            </div>
          )}
          <div className={`${messageData?.attachments?.length ? "pt-2" : ""}`}>
            <Reactions
              chat={messageData}
              removeUserOrAddReaction={removeUserOrAddReaction}
              addUserToReaction={addUserToReaction}
            />
          </div>

          {isShowOptionIcon && (
            <div
              className={`bg-white dark:bg-navyGray flex absolute ${
                isSend ? "right-1" : "left-1"
              } z-20 -top-8 rounded p-1 items-center gap-0.5 shadow`}
            >
              {initialReactions.map((reaction) => (
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => handleInstantReaction(reaction)}
                  key={reaction.unified}
                >
                  {reaction.emoji}
                </div>
              ))}
              <div
                className="cursor-pointer"
                onClick={() => setIsOpenAddReaction(true)}
              >
                <AddReaction />
              </div>
              {messageData.reactions && messageData.reactions.length > 0 && (
                <div className="relative pl-1">
                  {showReactionsPopover && (
                    <div className="z-30">
                      <PopOver
                        text="Reactions"
                        size={24}
                        leftPosition="-left-8"
                      />
                    </div>
                  )}
                  <div
                    className="cursor-pointer dark:text-frescoWhite"
                    onClick={() => allReactions()}
                    onMouseEnter={() => toggleReactionsPopover(true)}
                    onMouseLeave={() => toggleReactionsPopover(false)}
                  >
                    <Rocket stroke="#000000" />
                  </div>
                </div>
              )}
            </div>
          )}
        </span>
      </div>
      {!isDanger && (
        <span className={`${saveMessageIcondirection} rounded-full mt-1.5`}>
          <SaveMessage chat={messageData} />
        </span>
      )}
      <ChatReaction
        isOpen={isOpenAddReaction}
        setIsOpen={setIsOpenAddReaction}
        chat={messageData}
        addReaction={addReaction}
      />
    </div>
  );
}

export default ChatBubble;
