import React from "react";

function Bell({ ratio = 24 }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 9.33334C21 7.47682 20.2625 5.69634 18.9497 4.38359C17.637 3.07083 15.8565 2.33334 14 2.33334C12.1435 2.33334 10.363 3.07083 9.05025 4.38359C7.7375 5.69634 7 7.47682 7 9.33334C7 17.5 3.5 19.8333 3.5 19.8333H24.5C24.5 19.8333 21 17.5 21 9.33334Z"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0184 24.5C15.8132 24.8536 15.5188 25.1471 15.1646 25.3511C14.8104 25.5551 14.4088 25.6625 14 25.6625C13.5912 25.6625 13.1896 25.5551 12.8354 25.3511C12.4812 25.1471 12.1868 24.8536 11.9817 24.5"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Bell;
