import { useState, useEffect } from "react";
import axios from "../utils/axios";

const useWatchingCount = (callId) => {
  const [watchingCount, setWatchingCount] = useState(0);
  const [isLive, setIsLive] = useState(false);
  const [livePassCharge, setLivePassCharge] = useState();

  useEffect(() => {
    if (callId) {
      const updateWatchingCount = async (_callId) => {
        try {
          const res = await axios.get(`/calls/${_callId}/mux/counts`);
          const isLive = res.data?.data?.isLive || false;
          const viewers = res.data?.data?.viewers;
          const livePassCharge = res.data?.data?.livePassCharge;
          setWatchingCount(viewers);
          setIsLive(isLive);
          setLivePassCharge(livePassCharge);
        } catch (error) {
          setWatchingCount(0);
        }
      };

      updateWatchingCount(callId);
      const interval = setInterval(() => {
        updateWatchingCount(callId);
      }, 1000 * 10);

      return () => {
        clearInterval(interval);
      };
    }
  }, [callId]);

  return { watchingCount, isLive, livePassCharge };
};

export default useWatchingCount;
