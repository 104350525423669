import axios from "../utils/axios";

export function getUsersPosts(lastPostHash, orderBy, token, page, postType) {
  return new Promise(function (resolve, reject) {
    let query = `lastPostHash=${lastPostHash}&orderBy=${orderBy}&page=${page}`;
    if (postType) {
      query = `lastPostHash=${lastPostHash}&orderBy=${orderBy}&page=${page}&postType=${postType}`;
    }
    axios
      .get(`/user-posts?${query}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSinglePost(PostHash, token, publicKey) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user-posts/${PostHash}?publicKey=${publicKey}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createUserPost(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/user-posts`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSingleDesoPost(
  publicKey,
  postHex = "",
  CommentLimit = 20,
  CommentOffset = 0,
) {
  return axios
    .post("https://node.deso.org/api/v0/get-single-post", {
      AddGlobalFeedBool: false,
      CommentLimit: CommentLimit,
      CommentOffset: CommentOffset,
      FetchParents: true,
      LoadAuthorThread: true,
      PostHashHex: postHex,
      ReaderPublicKeyBase58Check: publicKey,
      ThreadLeafLimit: 1,
      ThreadLevelLimit: 2,
    })
    .then((response) => {
      return response.data?.PostFound;
    })
    .catch((error) => {
      console.log("ERROR:", error);
    });
}

export function getUsersPostsActivity(token, page) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/user-posts/my-feed-activity?page=${page}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createUserFeedMultiple(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/feed-views/create`, data, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
