import { useCallback, useContext, useMemo, useState } from "react";
import { Persona } from "../../types";
import globals from "../../globals";
import Button from "../../VibehutUI/Button";
import SpecificChat from "../chat/SpecificChat";
import { StoreContext } from "../../Context/StoreContext";
import PersonaIntro from "./personaIntro";
import { getShortenedName } from "../../utils/utils";
import { showLoginModal } from "../../actions/uiModalActions";
import CopyButton from "../../VibehutUI/CopyButton";
import { frontEndURL } from "../../utils/axios";
import Stats from "../../VibehutUI/Icons/Stats";
import { useHistory } from "react-router-dom";
import EditPersona from "./EditPersona";
import Avatar from "../../VibehutUI/Avatar";
import { getUserData } from "../../videoChat/utils/vibehutUtils";

interface Props {
  persona: Persona;
  isProfileModalV2?: boolean;
  setIsOpenProfileModal: any;
}

function PersonaMiniCard(props: Props) {
  const { persona, isProfileModalV2 } = props;
  const history = useHistory();
  const [showFullText, setShowFullText] = useState(false);
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;

  const showPersonaInfoModal = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      showLoginModal(dispatch);
      return;
    }
    globals.showModal(persona.name, <PersonaIntro _persona={persona} />);
    props?.setIsOpenProfileModal?.(false);
  };

  const isIntroClicked = useCallback(() => {
    if (
      persona.personaClicks &&
      persona.personaClicks.find(
        (personaClick) => personaClick.user === user?._id,
      )
    ) {
      return true;
    }
    return false;
  }, [persona.personaClicks, user?._id]);

  const showEditModal = () => {
    props?.setIsOpenProfileModal?.(false);
    globals.showModal("Edit Persona", <EditPersona _persona={persona} />);
  };

  const isMyPersona = useMemo(
    () => user?._id === persona?.owner._id,
    [persona?.owner._id, user?._id],
  );

  return (
    <div
      className="w-full max-w-full relative cursor-pointer"
      onClick={() => {
        if (isProfileModalV2) {
          // open on new tab
          window.open(`/personas/${persona._id}`, "_blank");
          return;
        }
        props?.setIsOpenProfileModal?.(false);
        history.push(`/personas/${persona._id}`);
      }}
    >
      <div className="relative flex flex-col gap-4 min-w-0 break-words shadow-soft-xl border dark:border-matteGray border-lightGray rounded-xl bg-clip-border p-4">
        <div className="absolute top-2 right-2">
          <CopyButton
            copyText="Vibehut"
            title="Vibehut"
            url={`${frontEndURL}/personas/${persona._id}`}
            bgColor="transparent"
            topPosition="-top-12"
            ratio="h-6 w-6"
          />
        </div>
        <div className="flex flex-row gap-4 relative">
          <img
            src={persona.avatar}
            alt="Persona avatar"
            className="w-16 h-16 rounded-lg"
          />
          {persona?.companyAffiliation?.imgUrl &&
            persona.companyAffiliation?.isActive && (
              <img
                src={persona.companyAffiliation.imgUrl}
                alt="Company Logo"
                className="w-5 h-5 rounded-lg absolute top-10 left-10"
              />
            )}
          {!persona?.companyAffiliation?.imgUrl &&
            !persona.companyAffiliation?.isActive && (
              <span className="w-5 h-5 rounded-lg absolute  top-10 left-10">
                <Avatar
                  src={getUserData(persona.owner).profile_picture}
                  username={getUserData(persona.owner).username}
                  size="xs"
                />
              </span>
            )}
          <div className="flex-auto px-1 ">
            <h5
              className={`text-lg font-medium ${
                isProfileModalV2
                  ? "text-frescoWhite"
                  : "text-darkNight dark:text-frescoWhite"
              } `}
            >
              {persona.name}
            </h5>
            <p className="leading-normal text-xs text-darkGray">
              {showFullText
                ? persona.generatedBio
                : persona.generatedBio.length > 100
                  ? getShortenedName(persona.generatedBio, 100)
                  : persona.generatedBio}
              {persona.generatedBio.length > 100 && !showFullText && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFullText(!showFullText);
                  }}
                  className="text-primary"
                >
                  See More
                </button>
              )}
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-2 items-center">
          <div className="flex text-darkGray items-center">
            <Stats height={20} width={20} />
            <p>{persona?.personaClickCount || 0}</p>
          </div>
          {!isMyPersona ? (
            <>
              {isIntroClicked() ? (
                <Button
                  onClick={showPersonaInfoModal}
                  variant="vibhut-primary-outline"
                >
                  Info
                </Button>
              ) : (
                <Button onClick={showPersonaInfoModal} variant="primary">
                  Info
                </Button>
              )}
              {persona.isEnable ? (
                <SpecificChat
                  chatItem={persona}
                  type="persona"
                  setIsOpenProfileModal={props.setIsOpenProfileModal}
                />
              ) : (
                <p className="dark:text-primaryGray text-darkGray text-sm">
                  Disabled
                </p>
              )}
            </>
          ) : (
            <div className="py-4 flex justify-center gap-4">
              {persona.isEnable ? (
                <SpecificChat
                  chatItem={persona}
                  type="persona"
                  setIsOpenProfileModal={props.setIsOpenProfileModal}
                  buttonVariant="primary"
                />
              ) : (
                <p className="dark:text-primaryGray text-darkGray text-sm">
                  Disabled
                </p>
              )}
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  showEditModal();
                }}
                variant="secondary"
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonaMiniCard;
