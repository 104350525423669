import axios from "../utils/axios";

export function cancelPlanSubscription(token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        "/planSubscription/stripe/cancel",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function createPlanSubscription(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/planSubscription/stripe", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
