import * as actionTypes from "../constants";
import * as eventSubscriptionService from "../services/eventSubscriptionService";

export function getPremiumCreationAccess(dispatch, name, creatorId, token) {
  dispatch({
    type: actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_REQUEST,
  });
  return eventSubscriptionService
    .checkPremiumActivityAccess(name, creatorId, token)
    .then((res) => {
      const premiumCreator = res.data.premiumCreator;
      if (premiumCreator) {
        dispatch({
          type: actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_SUCCESS,
          payload: true,
        });
      } else {
        dispatch({
          type: actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_FAILURE,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_FAILURE,
      });
      throw err;
    });
}
