import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import globals from "../../globals";
import { useStripeCallBack } from "../../hooks/useStripeCallback";
import Button from "../Button";

function PaymentStatus({ isOpen, setIsOpen, status, message }) {
  const cancelButtonRef = useRef(null);

  globals.defaultOnStripePaymentSuccess = () => {
    setIsOpen(true);
  };
  globals.onStripePaymentSuccess = globals.defaultOnStripePaymentSuccess;
  useStripeCallBack();

  return (
    <>
      <Transition appear show={isOpen} in as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block align-bottom bg-white 
                rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu"
              >
                <div className={`bg-white p-3 border-b border-frescoWhite `}>
                  <div className="flex justify-between items-center">
                    <p
                      className={`font-medium text-lg text-darkNight 
                     `}
                    >
                      {status === "success"
                        ? "Payment Successful"
                        : "Payment Failed"}
                    </p>
                    <span
                      className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-gray-600"
                        fill="none"
                        aria-hidden="true"
                        focusable="false"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className=" p-4 relative">
                  <div className="">
                    <p className="text-sm  text-gray-500">
                      {status === "success" ? message : "Please try again"}
                    </p>
                    <p className="text-sm text-gray-500 pt-2">
                      - Vibehut Team🏠
                    </p>
                  </div>

                  <div className="mt-4 flex flex-row justify-end">
                    <Button
                      onClick={() => setIsOpen(false)}
                      size="vibhut-sm"
                      variant="vibhut-primary"
                    >
                      Okay
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default PaymentStatus;
