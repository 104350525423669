import { useContext } from "react";
import { StoreContext } from "../Context/StoreContext";

function GamificationPage() {
  const { state } = useContext(StoreContext);

  const {
    user: {
      gamification: { data: gamificationData },
    },
  } = state;

  if (gamificationData === null) {
    return <></>;
  }

  return (
    <div>
      <div className=" flex flex-col pl-2 pr-2 sm:pr-10">
        <div className="pb-3">
          <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite hidden md:block">
            Things to Do
          </p>
          <div className="h-[58vh] custom-scrollbar overflow-y-auto">
            <div className="flex justify-between pt-2 items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Join a 1:1 call
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.joinOneToOne}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Join a group call
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.joinGroupCall}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                RSVP for an event
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.rsvpEvent}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add your profile cover photo
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.coverPhotoAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add a service
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.serviceAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add a social link
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.socialLinkAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add in e-mail
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.emailAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add in SMS
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.smsAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add Location
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.locationAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add Description
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.descriptionAdded}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Follow a User
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.connection}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Send an instant call link
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.sendInstantCallLink}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Wave to someone
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.waveSomeone}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Talk to someone more than 3 times
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.talkSomeoneMoreThan3Times}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Resource creation through bookmark
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.resourceCreated}
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-darkNight dark:text-frescoWhite">
                Add your personas
              </div>
              <div className="form-check form-check-inline pr-4">
                <input
                  className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                  type="checkbox"
                  checked={gamificationData.personas}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GamificationPage;
