import axios from "../utils/axios";

export function getPublishVideoes(token, data, page) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/publish/publish-video?page=${page}`,
        { videoTypes: data },
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getPendingApprovalVideos(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/publish/pending-approval-videos`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyPublishVideoes(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/publish/my-video", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSinglePublishVideo(token, videoId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/publish/video/${videoId}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function publishVideo(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/publish/video", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updatePublishedVideo(videoId, data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(`/publish/video/${videoId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateVideoVisibility(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/publish/update-video", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateLastSeenForWatchPage(token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/publish/update-watch-last-seen",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function checkNewVideoAvailable(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/publish/check-new-video`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomVideos(token, data, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/publish/room-publish-video?limit=6&offset=${offset}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getProfileVideos(token, data, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/publish/user-publish-video?limit=6&offset=${offset}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function initWatchPayment(videoId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/publish/${videoId}/watch/payment`,
        { videoId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyExternalVideosCount(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/publish/get-my-videos-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateVideoApprovalStatusByAdmin(token, body) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/publish/update-status-by-admin`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
