import React from "react";

function Button({
  size = "sm",
  children,
  onClick,
  variant = "primary",
  disabled = false,
  onMouseEnter = null,
  onMouseLeave = null,
  border = "",
  disabledClasses = "",
  customClasses = "",
}) {
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className={`cusBtn cusBtn-${variant} ${border} cusBtn-${size} ${
          disabled && `${disabledClasses}`
        } ${customClasses} `}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </button>
    </>
  );
}

export default Button;
