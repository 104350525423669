
function Tag({ title, icon }) {
  return (
    <div className="mt-[1px] flex items-center space-x-1 border-[1px] border-primaryGray  h-6 py-1 px-1.5 text-darkGray dark:border-matteGray rounded justify-center">
      {icon}
      <span className="dark:text-gray-200 text-xs text-darkGray">{title}</span>
    </div>
  );
}

export default Tag;
