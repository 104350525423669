import { useState } from "react";
import {
  addPushNotificationSubscription,
  askUserPermission,
  isPushNotificationSupported,
} from "../utils/swHelper";
import { baseURL } from "../utils/axios";

const pushNotificationSupported = isPushNotificationSupported();

export default function usePushNotifications(token) {
  const [userConsent, setUserConsent] = useState(Notification.permission);
  const [error, setError] = useState(null);
  //to manage errors
  const [loading, setLoading] = useState(true);
  const onClickAskUserPermission = () => {
    setLoading(true);
    setError(false);
    askUserPermission().then(async (consent) => {
      setUserConsent(consent);
      if (consent !== "granted") {
        setError({
          name: "Consent denied",
          message: "You denied the consent to receive notifications",
          code: 0,
        });
      } else {
        await addPushNotificationSubscription(token);
      }
      setLoading(false);
    });
  };

  const onClickSendNotification = async () => {
    fetch(`${baseURL}/api/v1/push`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  };

  return {
    userConsent,
    pushNotificationSupported,
    onClickAskUserPermission,
    onClickSendNotification,
  };
}
