import { lazy } from "react";
import AuthGuard from "../components/AuthGuard";
import TwitterCallback from "../pages/TwitterCallback";
import StripeCallback from "../pages/StripeCallback";
import VibebucksCallback from "../pages/VibebucksCallback";
import StripeReturnCallback from "../pages/StripeReturnCallback";
import RoomAnalytics from "../components/rooms/RoomAnalytics";
import RoomSubscibers from "../components/rooms/RoomSubscibers";
import RoomVideosNew from "../components/rooms/RoomVideosNew";
import LiveVideoPage from "../pages/LiveVideoPage";
import WorldcoinCallback from "../pages/WorldcoinCallback";
import LoginSuccessPage from "../pages/LoginSuccessPage";
import ReviewPage from "../pages/ReviewPage";
import { VideoReactApp } from "../videoChat";
const PlanPage = lazy(() => import("../pages/PlanPage"));
const EventApprovalRequestsPage = lazy(
  () => import("../components/events/EventApprovalRequests"),
);
const ChatPage = lazy(() => import("../pages/ChatPage"));
const LeaderBoardPage = lazy(() => import("../pages/LeaderBoardPage"));
const RoomsPage = lazy(() => import("../pages/RoomsPage"));
const FeedPagePosts = lazy(() => import("../pages/FeedPage"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const SingleRoomPage = lazy(() => import("../pages/SingleRoomPage"));
const ResourcesPage = lazy(() => import("../pages/ResourcesPage"));
const CallsPage = lazy(() => import("../pages/CallsPage"));
const RawRecordingsPage = lazy(() => import("../pages/RawRecordingsPage"));
const EventsPage = lazy(() => import("../pages/EventsPage"));
const ConnectionsPage = lazy(() => import("../pages/ConnectionsPage"));
const PublishedVideoPage = lazy(() => import("../pages/PublishedVideoPage"));
const SubscriptionsPage = lazy(() => import("../pages/SubscriptionsPage"));
const SinglePostPage = lazy(() => import("../pages/SinglePostPage"));
const EventPage = lazy(() => import("../pages/EventPage"));
const PersonasPage = lazy(() => import("../pages/PersonasPage"));
const PersonaWarpcastPage = lazy(() => import("../pages/PersonaWarpcastPage"));
const PersonaWarpcastArticlePage = lazy(
  () => import("../pages/PersonaWarpcastArticlePage"),
);
const PersonaPage = lazy(() => import("../pages/PersonaPage"));
const PromotionPage = lazy(() => import("../pages/PromotionPage"));
const BookingsPage = lazy(() => import("../pages/BookingsPage"));
const BookingSlotPage = lazy(() => import("../pages/BookingSlotPage"));
const NewsPage = lazy(() => import("../pages/NewsPage"));
const RoomPersonasPage = lazy(() => import("../pages/RoomPersonasPage"));
const RoomTestimonialsPage = lazy(
  () => import("../pages/RoomTestimonialsPage"),
);
const PartnerListPage = lazy(() => import("../pages/PartnerListPage"));
const FramePage = lazy(() => import("../pages/FramePage"));
const FramePage2 = lazy(() => import("../pages/FramePage2"));
const PersonaVideoInsightsPage = lazy(
  () => import("../pages/PersonaVideoInsightsPage"),
);
const PersonaVideoInsightPage = lazy(
  () => import("../pages/PersonaVideoInsightPage"),
);

const SinglePublishedVideoPage = lazy(
  () => import("../pages/SinglePublishedVideoPage"),
);

const HomePageWrapper = lazy(
  () => import("../components/wrapper/HomePageWrapper"),
);

const ProfilePage = lazy(() => import("../pages/ProfilePage"));

const ProfilePageWrapper = lazy(
  () => import("../components/wrapper/ProfilePageWrapper"),
);

const authRoutes = {
  id: "Auth",
  path: "/login",
  name: "Login",
  component: LoginPage,
  children: [
    {
      path: "/login",
      name: "Login",
      component: LoginPage,
    },
  ],
};

const homeRoutes = {
  id: "Home",
  path: "/",
  name: "Home",
  component: HomePageWrapper,
};

const newsRoutes = {
  id: "News",
  path: "/",
  name: "News",
  component: NewsPage,
};

export const newsAppRoutes = {
  id: "News",
  path: "/news",
  name: "News",
  component: NewsPage,
};

const feedRoutes = {
  id: "Feed",
  path: "/feed",
  name: "Feed",
  component: FeedPagePosts,
  children: [
    {
      path: "/feed",
      name: "Feed",
      component: FeedPagePosts,
    },
    {
      path: "/feed/:postId",
      name: "Feed",
      component: SinglePostPage,
    },
  ],
};

const ReviewCallRoutes = {
  id: "Review",
  path: "/review/:callId",
  name: "Review",
  component: ReviewPage,
};

const SubscriptionsRoutes = {
  id: "Subscriptions",
  path: "/subscriptions",
  component: SubscriptionsPage,
};

const connectionsRoutes = {
  id: "Connections",
  path: "/connections",
  name: "Connections",
  component: ConnectionsPage,
};

const leaderBoardRoutes = {
  id: "LeaderBoard",
  path: "/leaderboard/users-calls",
  name: "LeaderBoard",
  component: LeaderBoardPage,
  children: [
    {
      path: "/leaderboard/users-calls",
      name: "LeaderBoard",
      component: LeaderBoardPage,
    },
    {
      path: "/leaderboard/subscribers",
      name: "LeaderBoard",
      component: LeaderBoardPage,
    },
    {
      path: "/leaderboard/chat",
      name: "LeaderBoard",
      component: LeaderBoardPage,
    },
    {
      path: "/leaderboard/room-calls",
      name: "LeaderBoard",
      component: LeaderBoardPage,
    },
  ],
};

const videoCallRoutes = {
  id: "VideoCall",
  path: "/call",
  name: "Video Call",
  component: VideoReactApp,
};

export const instantJoinClaimedRoomRoutes = {
  id: "InstantJoinClaimedRoom",
  path: "/:slug/instant",
  name: "InstantJoinClaimedRoom",
  component: VideoReactApp,
};

export const instantJoinRoomRoutes = {
  id: "InstantJoinRoom",
  path: "/rooms/:roomId/instant",
  name: "InstantJoinRoom",
  component: VideoReactApp,
};

const roomsRoutes = {
  id: "Rooms",
  path: "/rooms",
  name: "Rooms",
  component: RoomsPage,
};

const eventsRoutes = {
  id: "Events",
  path: "/events",
  name: "Events",
  guard: AuthGuard,
  component: EventsPage,
};

const eventRoutesWithRoomSlug = {
  id: "Event",
  path: "/:slug/events/:eventId",
  name: "Event",
  component: EventPage,
  children: [
    {
      path: "/:slug/events/:eventId",
      name: "EventPageSlug",
      component: EventPage,
    },
    {
      path: "/:slug/events/:eventId/approval-requests",
      name: "EventApprovalRequestsWithSlug",
      component: EventApprovalRequestsPage,
    },
  ],
};

const eventRoutesWithRoomId = {
  id: "Event",
  path: "/rooms/:roomId/events/:eventId",
  name: "Event",
  component: EventPage,
  children: [
    {
      path: "/rooms/:roomId/events/:eventId",
      name: "Event",
      component: EventPage,
    },
    {
      path: "/rooms/:roomId/events/:eventId/approval-requests",
      name: "EventApprovalRequests",
      component: EventApprovalRequestsPage,
    },
  ],
};

export const callsRoutes = {
  id: "Call",
  path: "/calls",
  name: "Calls",
  guard: AuthGuard,
  component: CallsPage,
  children: [
    {
      path: "/calls",
      name: "Thanks Modal",
      component: CallsPage,
    },
    {
      path: "/calls/:id",
      name: "Thanks Modal",
      component: CallsPage,
    },
    {
      path: "/calls/:callId/recordings",
      name: "Raw Recordings",
      component: RawRecordingsPage,
    },
  ],
};

export const profileRoute = {
  id: "profile",
  path: "/@:username",
  name: "Profile",
  wrapper: ProfilePageWrapper,
  children: [
    {
      path: "/@:username",
      name: "Profile",
      component: ProfilePage,
    },
    {
      path: "/@:username/book",
      name: "Book",
      component: BookingsPage,
    },
    {
      path: "/@:username/book/:slug",
      name: "BookSlot",
      component: BookingSlotPage,
    },
  ],
};

export const promotionRoute = {
  id: "promotion",
  path: "/promotion",
  name: "Promotion",
  component: PromotionPage,
};

export const singleClaimedRoomRoutes = {
  id: "SingleClaimedRoom",
  path: "/:slug",
  name: "SingleRoom",
  component: SingleRoomPage,
  children: [
    {
      path: "/:slug",
      name: "SingleRoom",
      component: SingleRoomPage,
    },
    {
      path: "/:slug/analytics",
      name: "RoomAnalytics",
      component: RoomAnalytics,
    },
    {
      path: "/:slug/subscribers",
      name: "RoomSubscibers",
      component: RoomSubscibers,
    },
    {
      path: "/:slug/watch",
      name: "RoomVideos",
      component: RoomVideosNew,
    },
    {
      path: "/:slug/apps",
      name: "Apps",
      component: SingleRoomPage,
    },
    {
      path: "/:slug/apps/:appSlug",
      name: "Apps",
      component: SingleRoomPage,
    },
    {
      path: "/:slug/apps/:appSlug/response/:promptId",
      name: "Apps",
      component: SingleRoomPage,
    },
    {
      path: "/:slug/personas",
      name: "Personas",
      component: RoomPersonasPage,
    },
    {
      path: "/:slug/testimonials",
      name: "Testimonials",
      component: RoomTestimonialsPage,
    },
  ],
};

export const singleRoomRoutes = {
  id: "SingleRoom",
  path: "/rooms/:roomId",
  name: "SingleRoom",
  component: SingleRoomPage,
  children: [
    {
      path: "/rooms/:roomId",
      name: "SingleRoom",
      component: SingleRoomPage,
    },
    {
      path: "/rooms/:roomId/analytics",
      name: "RoomAnalytics",
      component: RoomAnalytics,
    },
    {
      path: "/rooms/:roomId/subscribers",
      name: "RoomSubscibers",
      component: RoomSubscibers,
    },
    {
      path: "/rooms/:roomId/watch",
      name: "RoomVideos",
      component: RoomVideosNew,
    },
    {
      path: "/rooms/:roomId/apps",
      name: "Apps",
      component: SingleRoomPage,
    },
    {
      path: "/rooms/:roomId/apps/:appSlug",
      name: "Apps",
      component: SingleRoomPage,
    },
    {
      path: "/rooms/:roomId/apps/:appSlug/response/:promptId",
      name: "Apps",
      component: SingleRoomPage,
    },
    {
      path: "/rooms/:roomId/personas",
      name: "Personas",
      component: RoomPersonasPage,
    },
    {
      path: "/rooms/:roomId/testimonials",
      name: "Testimonials",
      component: RoomTestimonialsPage,
    },
  ],
};

const publishedVideoesRoutes = {
  id: "PublishedVideo",
  path: "/watch",
  name: "PublishedVideo",
  guard: AuthGuard,
  component: PublishedVideoPage,
  children: [
    {
      path: "/watch",
      name: "PublishedVideo",
      component: PublishedVideoPage,
    },
    {
      path: "/watch/:videoId",
      name: "SinglePublishedVideo",
      component: SinglePublishedVideoPage,
    },
  ],
};

const chatRoutes = {
  id: "ChatPage",
  path: "/chat",
  name: "ChatPage",
  guard: AuthGuard,
  component: ChatPage,
  children: [
    {
      path: "/chat",
      name: "ChatPage",
      component: ChatPage,
      guard: AuthGuard,
    },
    {
      path: "/chat/:userId",
      name: "ChatPageWithUser",
      component: ChatPage,
      guard: AuthGuard,
    },
  ],
};

const planRoute = {
  id: "Plans",
  path: "/plans",
  name: "plans",
  component: PlanPage,
};

const twitterCallBackRoute = {
  id: "twitter",
  path: "/twitter/callback",
  name: "twitter",
  component: TwitterCallback,
};

const worldcoinCallBackRoute = {
  id: "worldcoin",
  path: "/auth/worldcoin/callback",
  name: "worldcoin",
  component: WorldcoinCallback,
};

const twitterLoginRoute = {
  id: "twitter",
  path: "/twitter/:token",
  name: "twitter",
  component: LoginSuccessPage,
};

const stripeCallbackRoute = {
  id: "stripe-callback",
  path: "/stripe",
  name: "stripe-callback",
  guard: AuthGuard,
  component: StripeCallback,
};

const VibebucksCallbackRoute = {
  id: "vibebucks-callback",
  path: "/vibebuckcallback",
  name: "vibebucks-callback",
  guard: AuthGuard,
  component: VibebucksCallback,
};

const StripeReturnCallbackRoute = {
  id: "stripe-return-callback",
  path: "/stripe/refresh",
  name: "stripe-return-callback",
  guard: AuthGuard,
  component: StripeReturnCallback,
};

const tokenLoginRoute = {
  id: "tokenLogin",
  path: "/login/token/:token",
  name: "tokenLogin",
  component: LoginSuccessPage,
};

export const resourceRoutes = {
  id: "Resources",
  path: "/resources",
  name: "resources",
  guard: AuthGuard,
  component: ResourcesPage,
  children: [
    {
      path: "/resources",
      name: "resources",
      component: ResourcesPage,
      guard: AuthGuard,
    },
    {
      path: "/resources/:resourceId",
      name: "EditResourcePage",
      component: ResourcesPage,
      guard: AuthGuard,
    },
  ],
};

export const personasRoutes = {
  id: "Personas",
  path: "/personas",
  name: "personas",
  component: PersonasPage,
  children: [
    {
      path: "/personas",
      name: "personas",
      component: PersonasPage,
    },
    {
      path: "/personas/warpcast",
      name: "PersonaWarpcastPage",
      component: PersonaWarpcastPage,
    },
    {
      path: "/personas/warpcast/articles/:id/:slug",
      name: "PersonaWarpcastArticlePage",
      component: PersonaWarpcastArticlePage,
    },
    {
      path: "/personas/:personaId",
      name: "PersonaPage",
      component: PersonaPage,
    },
  ],
};

const liveVideoRoutes = {
  id: "LiveVideo",
  path: "/rooms/:roomId/calls/:callId/live",
  name: "LiveVideo",
  component: LiveVideoPage,
};

const partnerListRoutes = {
  id: "PartnerList",
  path: "/partners",
  name: "PartnerList",
  component: PartnerListPage,
};

const framePageRoutes = {
  id: "FramePage",
  path: "/frame",
  name: "FramePage",
  component: FramePage,
};

const frame2PageRoutes = {
  id: "FramePage",
  path: "/frame2",
  name: "FramePage",
  component: FramePage2,
};

export const personaVideoInsightRoutes = {
  id: "PersonaVideoInsights",
  path: "/persona-video-insights",
  name: "PersonaVideoInsights",
  component: PersonaVideoInsightsPage,
  guard: AuthGuard,
  children: [
    {
      path: "/persona-video-insights",
      name: "PersonaVideoInsights",
      component: PersonaVideoInsightsPage,
    },
    {
      path: "/persona-video-insights/:personaVideoInsightId",
      name: "PersonaVideoInsight",
      component: PersonaVideoInsightPage,
    },
  ],
};

export const authLayoutRoutes = [
  ReviewCallRoutes,
  authRoutes,
  videoCallRoutes,
  instantJoinClaimedRoomRoutes,
  instantJoinRoomRoutes,
  twitterCallBackRoute,
  twitterLoginRoute,
  tokenLoginRoute,
  stripeCallbackRoute,
  worldcoinCallBackRoute,
  framePageRoutes,
  frame2PageRoutes,
];

// Note : Please keep the order of routes as it is for the personalized routes to work properly
export const mainLayoutRoutes = [
  planRoute,
  homeRoutes,
  personasRoutes,
  personaVideoInsightRoutes,
  roomsRoutes,
  promotionRoute,
  partnerListRoutes,
  newsAppRoutes,
  connectionsRoutes,
  SubscriptionsRoutes,
  resourceRoutes,
  leaderBoardRoutes,
  eventsRoutes,
  feedRoutes,
  callsRoutes,
  VibebucksCallbackRoute,
  StripeReturnCallbackRoute,
  profileRoute,
  chatRoutes,
  connectionsRoutes,
  publishedVideoesRoutes,
  callsRoutes,
  eventRoutesWithRoomSlug,
  eventRoutesWithRoomId,
  roomsRoutes,
  singleClaimedRoomRoutes,
  singleRoomRoutes,
  liveVideoRoutes,
];

export const subDomainRoutes = [newsRoutes];
