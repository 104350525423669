import React from "react";

export function UserCardSingleSkeleton() {
  return (
    <div className=" pt-6">
      <div className="flex items-center gap-4">
        <div className=" overflow-hidden  rounded-full w-7 animate-pulse bg-frescoWhite dark:bg-darkGray h-7 "></div>

        <div className="flex flex-col gap-2">
          <p className="rounded-xl w-32 animate-pulse bg-frescoWhite dark:bg-darkGray h-3"></p>
          <p className="rounded-xl w-36 animate-pulse bg-frescoWhite dark:bg-darkGray h-2"></p>
        </div>
      </div>
    </div>
  );
}

function UserCardSkeleton() {
  return (
    <div className="px-2">
      {new Array(12).fill(0).map((_, index) => (
        <UserCardSingleSkeleton key={index} />
      ))}
    </div>
  );
}

export default UserCardSkeleton;
