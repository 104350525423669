import {
  getMetamaskUserProfileImage,
  getMetamaskUsername,
} from "../../utils/utils";

export function getUserData(user: any) {
  switch (user?.provider) {
    case "bitclout":
      return {
        ...user.bitclout_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
      };
    case "twitter":
      return {
        ...user.twitter_data,
        profile_picture: user.twitter_data.profile_picture.replace(
          "normal",
          "400x400",
        ),
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
      };
    case "instagram":
      return {
        ...user.instagram_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
      };
    case "tiktok":
      return {
        ...user.tiktok_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
      };
    case "facebook":
      return {
        ...user.facebook_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
      };
    case "metamask":
      return {
        ...user.metamask_data,
        provider: "metamask",
        username: user.username || getMetamaskUsername(user.metamask_data),
        publickey: null,
        profile_picture: getMetamaskUserProfileImage(user.metamask_data),
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        fullAddress:
          user.username ||
          (user.metamask_data?.ens
            ? user.metamask_data.ens
            : user.metamask_data?.wallet_address),
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
        farcaster: user?.metamask_data?.farcaster,
      };
    case "linkedin":
      return {
        ...user.linkedin_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
      };
    case "guest":
      return {
        ...user.guest_user_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        provider: user.provider,
        vibehutUsername: user?.vibehutUsername,
      };
    case "worldcoin":
      return {
        ...user.worldcoin_data,
        username: user.worldcoin_data?.username.slice(0, 15) + "...",
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
      };
    case "google":
      const profile_picture = user?.google_data?.profile_picture.replace(
        "s96-c",
        "s256-c",
      );
      return {
        ...user.google_data,
        _id: user._id,
        last_seen: user.last_seen,
        cover: user.cover,
        socialLinks: user.socialLinks,
        userStatus: user.userState?.status || "offline",
        services: user.services,
        vibehutUsername: user?.vibehutUsername,
        profile_picture,
      };
    default:
      if (user?.custom_wallet_data) {
        return {
          ...user?.custom_wallet_data,
          _id: user._id,
          last_seen: user.last_seen,
          cover: user.cover,
          socialLinks: user.socialLinks,
          userStatus: user.userState?.status || "offline",
          provider: user.provider,
          vibehutDescription: user?.vibehutDescription,
          vibehutUsername: user?.vibehutUsername,
          serviceRole: user.serviceRole,
          username:
            user?.custom_wallet_data?.wallet_address?.slice(0, 15) + "...",
        };
      }
      return {};
  }
}

export const removeUndefinedKeys = (obj: any) => {
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined ? delete obj[key] : {},
  );
  return obj;
};

export const getEnv = () => {
  switch (window.location.host) {
    case "vibehut-633f7.web.app":
      return "staging";

    case "vibehut-staging.herokuapp.com":
      return "staging";

    case "vibehut.io":
      return "prod";

    case "vibehut-test.looklike.io":
      return "testing";

    default:
      return "local";
  }
};
