import React, { useState, useContext, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";
import { getApps, getRoomIdByRoomSlug } from "../../services/rooomService";

function RoomNavigation({ roomRoute }) {
  const { slug } = useParams();
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
    room: {
      subscriberCount,
      liveRoom: { liveRoom: room },
    },
  } = state;
  const [hasApps, setHasApps] = useState(false);

  // Fetch apps and update hasApps state
  useEffect(() => {
    if (slug) {
      getRoomIdByRoomSlug(slug).then(async (res) => {
        if (res?.data?.roomId)
          getApps(res.data.roomId).then((appsRes) => {
            if (appsRes && appsRes.data && appsRes.data.length > 0) {
              setHasApps(true);
            }
          });
      });
    } else {
      const id = roomRoute.replace("rooms/", "");
      getApps(id).then((res) => {
        if (res && res.data && res.data.length > 0) {
          setHasApps(true);
        }
      });
    }
  }, []);

  const isHostOrModerator = () => {
    const ownerId = room.owner?._id?.toString();
    const userId = user?._id?.toString();
    if (
      ownerId === userId ||
      (room?.moderators && room.moderators.some((user) => user === userId))
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="w-full flex gap-4 sm:gap-6 capitalize text-darkGray font-medium transition-all duration-500 md:pt-0 md:pb-0 pt-5 py-5 overflow-x-auto custom-scrollbar ">
      <div className="hover:text-primary cursor-pointer">
        <NavLink exact activeClassName="text-primary" to={`/${roomRoute}`}>
          general
        </NavLink>
      </div>

      <div className="hover:text-primary cursor-pointer">
        <NavLink
          exact
          activeClassName="text-primary"
          to={`/${roomRoute}/analytics`}
        >
          <div className="flex md:flex-row flex-col gap-1 items-center justify-center">
            <p> analytics</p>
          </div>
        </NavLink>
      </div>
      <div className="hover:text-primary cursor-pointer">
        <NavLink
          exact
          className="relative"
          activeClassName="text-primary "
          to={`/${roomRoute}/subscribers`}
        >
          <span className="mr-1 z-20">subscribers</span>
          {subscriberCount > 0 && (
            <div className="absolute z-10 -top-2 -right-2 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
              {subscriberCount}
            </div>
          )}
        </NavLink>
      </div>
      <div className="hover:text-primary cursor-pointer">
        <NavLink
          exact
          activeClassName="text-primary"
          to={`/${roomRoute}/watch`}
        >
          watch
        </NavLink>
      </div>
      {hasApps && (
        <div className="hover:text-primary cursor-pointer">
          <NavLink
            exact
            activeClassName="text-primary"
            to={`/${roomRoute}/apps`}
          >
            Apps
          </NavLink>
        </div>
      )}
      <div className="hover:text-primary cursor-pointer">
        <NavLink
          exact
          activeClassName="text-primary"
          to={`/${roomRoute}/personas`}
        >
          Personas
        </NavLink>
      </div>
      {room?.isTestimonialEnabled && isHostOrModerator() && (
        <div className="hover:text-primary cursor-pointer">
          <NavLink
            exact
            activeClassName="text-primary"
            to={`/${roomRoute}/testimonials`}
          >
            Testimonials
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default RoomNavigation;
//
//This code is a React component that renders a navigation bar for a room. It uses the `useState` and `useEffect` hooks to manage state and side effects. The component fetches the subscriber list and updates it every 2 seconds. It also fetches apps and updates the `hasApps` state accordingly. The component renders a navigation bar with links
