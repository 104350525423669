import * as actionTypes from "../constants";
import * as partnerService from "../services/partnerService";

export function getPromotedPartners(
  dispatch: any,
  offset: string = "",
  page: string = "3",
) {
  dispatch({
    type: actionTypes.GET_PROMOTED_PARTNERS_REQUEST,
  });
  return partnerService
    .getPromotedPartners({ offset, page })
    .then((res) => {
      if (offset) {
        dispatch({
          type: actionTypes.GET_MORE_PROMOTED_PARTNERS,
          payload: res.data,
        });
        return;
      }
      dispatch({
        type: actionTypes.GET_PROMOTED_PARTNERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PROMOTED_PARTNERS_FAILURE,
      });
      throw err;
    });
}
