import { useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import Loader from "../../VibehutUI/Loader";
import { Persona } from "../../types";
import PersonaMiniCard from "./PersonaMiniCard";
import { getProfilePersonas } from "../../actions/personaAction";
import DoubleStar from "../../VibehutUI/Icons/DoubleStar";
import CreatePersona from "./CreatePersona";
import { showLoginModal } from "../../actions/uiModalActions";
import globals from "../../globals";

interface Props {
  userId: string;
  isProfileModalV2?: boolean;
  setIsOpenProfileModal?: any;
}

function ProfilePersonas({
  userId,
  isProfileModalV2,
  setIsOpenProfileModal,
}: Props) {
  const { state, dispatch } = useContext(StoreContext);
  const {
    persona: { profilePersonas, isFetchingProfilePersonas },
    auth: { user },
  } = state;

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (userId) {
      getProfilePersonas(dispatch, userId, { signal });
    }
    return () => controller.abort();
  }, [dispatch, userId]);

  const showCreateModal = () => {
    if (!user) {
      showLoginModal(dispatch);
      return;
    }
    globals.showModal("Create Persona", <CreatePersona />);
  };

  if (isFetchingProfilePersonas) {
    return <Loader />;
  }

  if (profilePersonas.length === 0 && user?._id === userId) {
    return (
      <div className="px-8 pb-6">
        <p
          className={`font-bold font-ubuntu  ${
            isProfileModalV2 ? "text-frescoWhite" : "dark:text-frescoWhite"
          }`}
        >
          Personas
        </p>
        {profilePersonas.length === 0 && (
          <div
            className={`flex flex-col gap-2 pt-2 ${
              isProfileModalV2 ? "text-frescoWhite" : "dark:text-frescoWhite"
            }`}
          >
            Train your persona to be your online assistant. Tell it what you're
            looking for and let it be your first responder.
          </div>
        )}
        <div className="pt-2">
          <button
            className="px-1 py-1 bg-gradient-btn-border text-white rounded-3xl "
            onClick={showCreateModal}
          >
            <div className="bg-gradient-btn flex align-center items-center  rounded-3xl justify-center px-1.5 sm:px-4 py-2">
              <>
                <DoubleStar />
                <p className="text-sm sm:text-base ml-0.5">
                  Add Your First Persona
                </p>
              </>
            </div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="px-8 pb-8">
      <p
        className={`font-bold font-ubuntu  ${
          isProfileModalV2 ? "text-frescoWhite" : "dark:text-frescoWhite"
        }`}
      >
        {profilePersonas?.length === 0 ? (
          <>No Personas Available</>
        ) : (
          <>Personas</>
        )}
      </p>
      <div className="flex flex-col gap-2 pt-4">
        {profilePersonas.map((persona: Persona) => {
          return (
            <PersonaMiniCard
              persona={persona}
              isProfileModalV2={isProfileModalV2}
              key={persona?._id}
              setIsOpenProfileModal={setIsOpenProfileModal}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ProfilePersonas;
