import moment from "moment";
import React, { useState, useContext, memo } from "react";
import Avatar from "../../VibehutUI/Avatar";
import ProfileModal from "../../VibehutUI/ProfileModal";
import { StoreContext } from "../../Context/StoreContext";
import { getShortenedName } from "../../utils/utils";
import PaidBadge from "../../VibehutUI/Icons/PaidBadge";
import { Persona } from "../../types";

interface Props {
  user: any;
  persona: Persona;
  isOpenProfile?: boolean;
  size?: string;
}

function PersonaCreatorCard({
  user,
  persona,
  isOpenProfile = true,
  size = "xs",
}: Props) {
  const { state } = useContext(StoreContext);
  const { auth } = state;

  function copy(text: string) {
    const el = document.createElement("input");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      {isOpen && (
        <ProfileModal
          copyText={copy}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          user={user}
          isLoggedInUser={user._id === auth?.user?._id}
        />
      )}
      <button type="button">
        <div
          className={`flex items-center space-x-3 cursor-pointer px-2
                             py-1.5 rounded-xl   hover:bg-frescoWhite dark:hover:bg-navyGray
                             `}
          onClick={() => {
            return user.provider === "guest"
              ? undefined
              : setIsOpen(true && isOpenProfile);
          }}
        >
          <div className="relative">
            <Avatar
              username={
                user.provider === "metamask"
                  ? user.username.slice(0, 5)
                  : user.username
              }
              src={user.profile_picture}
              size={size}
            />
          </div>

          <div>
            <p className=" flex flex-col">
              <span
                className={`font-medium truncate flex  text-left items-center text-matteGray text-base
                                         dark:text-white
                                            `}
              >
                {user && user.username
                  ? `${getShortenedName(user.username, 16)} ${
                      user.provider === "guest" ? "· (Guest)" : ""
                    }`
                  : ""}
                <PaidBadge
                  isInline={true}
                  serviceRole={user.serviceRole}
                  customClass="ml-1"
                />
              </span>
            </p>
            <p className="text-xs text-darkGray flex text-left items-start font-normal tracking-wide ">
              {`Creator  ·  Last update  ${moment(
                persona.updatedAt,
              ).fromNow()}`}
            </p>
          </div>
        </div>
      </button>
    </React.Fragment>
  );
}

function areEqual(prevProps: Props, nextProps: Props) {
  return prevProps.user._id === nextProps.user._id;
}
export default memo(PersonaCreatorCard, areEqual);
