import moment from "moment";
import "moment-duration-format";
import { Link } from "react-router-dom";
import { getUserData } from "../services/userService";
import Instagram from "../VibehutUI/Icons/Instagram";
import LinkedIn from "../VibehutUI/Icons/LinkedIn";
import Metamask from "../VibehutUI/Icons/Metamask";
import Warpcast from "../VibehutUI/Icons/Warpcast";
import PasswordIcon from "../VibehutUI/Icons/PasswordIcon";
import Polygon from "../VibehutUI/Icons/Polygon";
import TikTok from "../VibehutUI/Icons/Tiktok";
import Linkify from "react-linkify";
import Avatar from "../VibehutUI/Avatar";
import Facebook from "../VibehutUI/Icons/Facebook";
import { singleClaimedRoomRoutes, singleRoomRoutes } from "../routes";
import Subscriptions from "../VibehutUI/Icons/Subscriptions";
import { decryptMsg } from "./AES";
import InlineSubscription from "../VibehutUI/Icons/InlineSubscription";
import PaidBadge from "../VibehutUI/Icons/PaidBadge";
import { userRoleTypes } from "../constants";
import Telegram from "../VibehutUI/Icons/Telegram";
import Thread from "../VibehutUI/Icons/Thread";
import DeSo from "../VibehutUI/Icons/DeSo";
import SubscriberApproval from "../VibehutUI/Icons/SubscriberApproval";
import Worldcoin from "../VibehutUI/Icons/Worldcoin";
import TwitterV2 from "../VibehutUI/Icons/TwitterV2";
import Google from "../VibehutUI/Icons/Google";
import LinkPreview from "../VibehutUI/LinkPreview";
import PaidBadgeRaw from "../VibehutUI/Icons/PaidBadgeRaw";
import { useCallback } from "react";
import globals from "../globals";
import PersonaIntro from "../components/peronas/personaIntro";
import axios from "./axios";

export function calculateTimeDifference(dateFuture, dateNow) {
  let differenceInSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate minutes
  const minutes = Math.floor(differenceInSeconds / 60);
  differenceInSeconds -= minutes * 60;

  //calculate seconds
  const seconds = Math.floor(differenceInSeconds);

  const difference = `${minutes}:${seconds} min`;

  return difference;
}

export function isShortNameLenght(condition, limit) {
  let activeConditions = 0;

  for (const i in condition) if (condition[i]) activeConditions++;
  return activeConditions >= limit;
}

export const ensureHttps = (link) => {
  if (!link.startsWith("http://") && !link.startsWith("https://")) {
    link = "https://" + link;
  }
  return link;
};
export function isMobile() {
  var match = window.matchMedia || window.msMatchMedia;
  if (match) {
    var mq = match("(pointer:coarse)");
    return mq.matches;
  }
  return false;
}
export const isActiveRoom = (activeCalls, roomId) => {
  return activeCalls.some((call) => call.room._id === roomId) || false;
};
export const checkMyRoomSubscription = (subscriptions, currentRoomID) => {
  const found = subscriptions.find(
    (element) => element?.room._id === currentRoomID,
  );
  if (!found) return null;
  return found;
};

export const renderPostText = (
  post,
  openUserModal,
  isRoomBody,
  roomRedirectFun = undefined,
) => {
  const bodyText = (isRoomBody ? post?.roomBody : post?.body) || "";
  const userIdToUserMap = {};
  const roomIdToRoomMap = {};
  const eventIdToEventMap = {};
  const watchIdToWatchMap = {};
  const callIdToCallMap = {};
  const appIdToAppMap = {};
  const datToMap = {};
  const personaIdToPersonaMap = {};
  const personaVideoInsightIdToPersonaVideoInsightMap = {};
  let eventPatterns;
  let eventTimePatterns;

  if (!bodyText) {
    return undefined;
  }

  const userPatterns =
    post.usersMentioned?.map((user) => {
      userIdToUserMap[user._id.toString()] = user;
      return `$(_user:${user._id.toString()})`;
    }) || [];
  const roomPatterns =
    post.roomsMentioned?.map((room) => {
      roomIdToRoomMap[room._id.toString()] = room;
      return `$(_room:${room._id.toString()})`;
    }) || [];
  const watchPatterns =
    post.watchMentioned?.map((watch) => {
      if (watch._id) {
        watchIdToWatchMap[watch._id.toString()] = watch;
        return `$(_watch:${watch._id.toString()})`;
      }
      return "";
    }) || [];

  const callPatterns =
    post.callsMentioned?.map((call) => {
      callIdToCallMap[call.toString()] = call.toString();
      return `$(_call:${call.toString()})`;
    }) || [];
  const appPatterns =
    post.appMentioned?.map((app) => {
      if (app._id) {
        appIdToAppMap[app._id.toString()] = app;
        return `$(_app:${app._id.toString()})`;
      }
      return "";
    }) || [];

  const datePatterns =
    post.datesMentioned?.map((date) => {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedDate = date.toLocaleString(undefined, {
        timeZone: browserTimeZone,
      });
      datToMap[date.toString()] = formattedDate.toString();
      return `$(_date:${formattedDate.toString()})`;
    }) || [];

  const personaPatterns =
    post.personasMentioned?.map((persona) => {
      personaIdToPersonaMap[persona?._id?.toString()] = persona;
      return `$(_persona:${persona?._id?.toString()})`;
    }) || [];

  const personaVideoInsightPatterns =
    post.personaVideoInsightsMentioned?.map((videoInsight) => {
      personaVideoInsightIdToPersonaVideoInsightMap[
        videoInsight?._id?.toString()
      ] = videoInsight;
      return `$(_personaVideoInsight:${videoInsight?._id?.toString()})`;
    }) || [];

  if (post.eventsMentioned && post.eventsMentioned.length > 0) {
    eventPatterns = post.eventsMentioned.map((event) => {
      eventIdToEventMap[event._id?.toString()] = event;
      return `$(_event:${event._id?.toString()})`;
    });

    eventTimePatterns = post.eventsMentioned.map((event) => {
      eventIdToEventMap[event._id?.toString()] = event;
      return `$(_eventStartTime:${event._id?.toString()})`;
    });
  }
  return bodyText.split(/\s/).map((part, index) => {
    if (part === "$(_resource:resource)") {
      return (
        <Link
          key={index}
          to={"/news?tab=Recent"}
          className="text-darkNight dark:text-frescoWhite font-medium"
        >
          {`resource `}
        </Link>
      );
    } else if (userPatterns.includes(part)) {
      const userId = part.substring(8, 32);
      const user = getUserData(userIdToUserMap[userId]);
      const handleClick = () => {
        if (user.provider !== "guest") openUserModal(user);
      };
      return (
        <span
          key={index}
          onClick={handleClick}
          className="text-darkNight dark:text-frescoWhite font-medium cursor-pointer"
        >
          {`${user.username} ${user.provider === "guest" ? "· Guest " : ""}`}{" "}
          <PaidBadge
            serviceRole={user.serviceRole}
            isInline={true}
            customClass="mb-px"
          />{" "}
        </span>
      );
    } else if (roomPatterns.includes(part)) {
      const roomId = part.substring(8, 32);
      const room = roomIdToRoomMap[roomId];
      const path = room?.slug
        ? singleClaimedRoomRoutes.path.replace(":slug", room.slug)
        : room.name.includes(" with $(_user)")
          ? `/call?roomKey=${room._id}`
          : singleRoomRoutes.path.replace(":roomId", room._id);
      if (!room.specificChatId) {
        return (
          <Link
            key={index}
            to={path}
            className="text-darkNight dark:text-frescoWhite font-medium "
          >
            {room.name.length > 100
              ? room.name.slice(0, 100) + "... "
              : room.name.replace(" with $(_user)", "") + " "}
            {room.hasSubscription && (
              <>
                <span className="inline -mt-2">
                  <span className="text-darkGray font-normal"> ( </span>
                  <Subscriptions isInline={true} />
                  <span className="text-darkGray font-normal">
                    Paid Room ){" "}
                  </span>
                </span>
              </>
            )}
            {room.isPasswordProtected ? (
              <>
                <span className="inline -mt-2">
                  <span className="text-darkGray font-normal"> ( </span>
                  <PasswordIcon isInline={true} marginTop="-mt-1.5" />{" "}
                  <span className="text-darkGray font-normal">
                    Password Room ){" "}
                  </span>
                </span>
              </>
            ) : (
              ""
            )}
            {room?.desoPostRoom?.postHashHex && (
              <>
                diamond ({" "}
                {getDiamondIconsFromDiamondLevel(
                  room?.desoPostRoom?.diamondLevel,
                )}{" "}
                required )
              </>
            )}
            {room?.isSubscriberRequireApproval && (
              <span className="inline -mt-2">
                <span className="text-darkGray font-normal"> ( </span>
                <SubscriberApproval
                  ratio="18"
                  isInline={true}
                  marginTop="-mt-1.5"
                />
                <span className="text-darkGray font-normal">
                  {" "}
                  Approved Room ){" "}
                </span>
              </span>
            )}
            {(room?.isCCRoom || room?.isDAORoom) && (
              <span className="inline -mt-2">
                <span className="text-darkGray">
                  {" "}
                  (
                  {room?.isCCRoom && room?.isDAORoom
                    ? "Creator Coins and DAO Token"
                    : room?.isCCRoom
                      ? "Creator Coins"
                      : " DAO Token"}{" "}
                  required){" "}
                </span>
              </span>
            )}
            {room?.planAccessible && (
              <span className="inline -mt-2">
                <span className="text-darkGray font-normal"> ( </span>
                <PaidBadgeRaw
                  isInline={true}
                  color={calcPlanIconColor(room?.planAccessible)}
                  ratio="14"
                  customClass="-mt-0.5"
                />
                <span className="text-darkGray font-normal">
                  {" "}
                  {room?.planAccessible} Plan Room ){" "}
                </span>
              </span>
            )}
            {room.isNftCollectionRoom ? (
              <>
                <span className="inline -mt-2">
                  <span className="text-darkGray font-normal"> ( </span>
                  <Polygon
                    bgColor={room?.isAccessible ? "#FFCC00" : "#B5B5B5"}
                    isInline={true}
                  />{" "}
                  <span className="text-darkGray font-normal">Nft Room ) </span>
                </span>
              </>
            ) : (
              ""
            )}
          </Link>
        );
      } else {
        return (
          <span
            key={index}
            className="text-darkNight dark:text-frescoWhite font-medium cursor-pointer"
            onClick={() => roomRedirectFun(room)}
          >
            {room.name.length > 100
              ? room.name.slice(0, 100) + "... "
              : room.name.replace(" with $(_user)", "") + " "}
            {room.hasSubscription && (
              <>
                <span className="inline -mt-2">
                  <span className="text-darkGray font-normal"> ( </span>
                  <Subscriptions isInline={true} />
                  <span className="text-darkGray font-normal">
                    Paid Room ){" "}
                  </span>
                </span>
              </>
            )}
            {room.isPasswordProtected ? (
              <>
                <span className="inline -mt-2">
                  <span className="text-darkGray font-normal"> ( </span>
                  <PasswordIcon isInline={true} />
                  <span className="text-darkGray font-normal">
                    Password Room ){" "}
                  </span>
                </span>
              </>
            ) : (
              ""
            )}
            {room?.isSubscriberRequireApproval && (
              <span className="inline -mt-2">
                <span className="text-darkGray font-normal"> ( </span>
                <SubscriberApproval ratio="18" isInline={true} />
                <span className="text-darkGray font-normal">
                  {" "}
                  Approved Room ){" "}
                </span>
              </span>
            )}
            {room?.planAccessible && (
              <span className="inline -mt-2">
                <span className="text-darkGray font-normal"> ( </span>
                <PaidBadgeRaw
                  isInline={true}
                  color={calcPlanIconColor(room?.planAccessible)}
                  ratio="14"
                  customClass="-mt-0.5"
                />
                <span className="text-darkGray font-normal">
                  {" "}
                  {room?.planAccessible} Plan Room ){" "}
                </span>
              </span>
            )}
            {room.isNftCollectionRoom ? (
              <>
                <span className="inline -mt-2">
                  <span className="text-darkGray font-normal"> ( </span>
                  <Polygon
                    bgColor={room?.isAccessible ? "#FFCC00" : "#B5B5B5"}
                    isInline={true}
                  />
                  <span className="text-darkGray font-normal">Nft Room ) </span>
                </span>
              </>
            ) : (
              ""
            )}
          </span>
        );
      }
    } else if (
      post.eventsMentioned &&
      post.eventsMentioned.length > 0 &&
      eventPatterns.includes(part)
    ) {
      const eventId = part.substring(9, 33);
      const event = eventIdToEventMap[eventId];
      return (
        <Link
          key={index}
          to={`/rooms/${event.room}/events/${event._id}`}
          className="text-darkNight dark:text-frescoWhite font-medium"
        >
          {event.title}{" "}
          {event.isPaidEvent && (
            <>
              <span className="inline -mt-2">
                <span className="text-darkGray font-normal"> ( </span>

                <InlineSubscription isInline={true} size="16" />

                <span className="text-darkGray font-normal">Paid ) </span>
              </span>
            </>
          )}
        </Link>
      );
    } else if (
      post.watchMentioned &&
      post.watchMentioned.length > 0 &&
      watchPatterns?.includes(part)
    ) {
      const watchId = part.substring(9, 33);
      const watch = watchIdToWatchMap[watchId];
      return (
        <Link
          key={index}
          to={`/watch/${watchId}`}
          className="text-darkNight dark:text-frescoWhite font-medium"
        >
          {watch.title}{" "}
        </Link>
      );
    } else if (
      post.callsMentioned &&
      post.callsMentioned.length > 0 &&
      callPatterns?.includes(part)
    ) {
      const callId = part.substring(8, 32);
      return (
        <Link
          key={index}
          to={`/calls/${callId}`}
          className="text-darkNight dark:text-frescoWhite font-medium"
        >
          **See Thanks**
        </Link>
      );
    } else if (
      post.appMentioned &&
      post.appMentioned.length > 0 &&
      appPatterns?.includes(part) &&
      post.roomsMentioned &&
      post.roomsMentioned.length > 0
    ) {
      const appId = part.substring(7, 31);
      const app = appIdToAppMap[appId];
      return (
        <Link
          key={index}
          to={`/rooms/${post.roomsMentioned[0]._id}/apps/${app.slug}`}
          className="text-darkNight dark:text-frescoWhite font-medium"
        >
          {app.name}{" "}
        </Link>
      );
    } else if (
      post.eventsMentioned &&
      post.eventsMentioned.length > 0 &&
      eventTimePatterns.includes(part)
    ) {
      const eventId = part.substring(18, 42);
      const event = eventIdToEventMap[eventId];
      return (
        <span className="font-medium">
          {moment(event?.startDate).format("LT")}
        </span>
      );
    } else if (datePatterns.includes(part)) {
      const datePart = part.substring(8, 32);
      const date = datToMap[datePart];
      const zone = moment.tz.guess();
      const abbr = moment.tz(zone).format("z");

      const formattedDate = moment(date).format(`MMM D [at] ha [${abbr}]`);
      return (
        <span
          key={index}
          className="text-darkNight dark:text-frescoWhite font-medium cursor-pointer"
        >
          {formattedDate}{" "}
        </span>
      );
    } else if (
      post.personasMentioned &&
      post.personasMentioned.length > 0 &&
      personaPatterns?.includes(part)
    ) {
      const personaId = part.substring(11, 35);
      const persona = personaIdToPersonaMap[personaId];

      const showPersonaInfoModal = useCallback(() => {
        globals.showModal(
          persona.name,
          <PersonaIntro _persona={persona} loadFromComponent={true} />,
        );
      }, [persona]);
      return (
        <span
          key={index}
          onClick={showPersonaInfoModal}
          className="text-darkNight dark:text-frescoWhite font-medium cursor-pointer"
        >
          {persona.name}{" "}
        </span>
      );
    } else if (
      post.personaVideoInsightsMentioned &&
      post.personaVideoInsightsMentioned.length > 0 &&
      personaVideoInsightPatterns?.includes(part)
    ) {
      const videoInsightId = part.substring(23, 47);
      const videoInsight =
        personaVideoInsightIdToPersonaVideoInsightMap[videoInsightId];
      return (
        <a
          key={index}
          href={videoInsight.videoLink}
          className="text-darkNight dark:text-frescoWhite font-medium"
          target="_blank"
        >
          {videoInsight.metaData.title}{" "}
        </a>
      );
    } else {
      return (
        <span key={index}>
          <Linkify componentDecorator={componentDecorator}>{part} </Linkify>
        </span>
      );
    }
  });
};

function splitString(input) {
  const regex = /^(.*?)\)(.*)$/;
  const match = regex.exec(input);

  if (match) {
    const [, firstPart, secondPart] = match;
    return [`${firstPart})`, secondPart];
  }

  return [];
}

export const renderMessageText = (
  chat,
  openUserModal,
  fromConversation = false,
) => {
  if (!chat.message) {
    return "";
  }
  let bodyText = "";
  if (!chat?.isEncrypted) {
    bodyText = chat.message.trimLeft().replace(/^@ami\b/g, "");
  } else {
    bodyText = getDecryptMessage(chat.room, chat?.isEncrypted, chat.message);
  }

  const userIdToUserMap = {};

  if (!bodyText) {
    return undefined;
  }

  const userPatterns =
    chat.usersMentioned
      ?.map((user) => {
        if (user?._id) {
          userIdToUserMap[user._id.toString()] = user;
          return `$(_user:${user._id.toString()})`;
        }
        return null;
      })
      .filter(Boolean) || [];

  return bodyText.split(/\s/).map((part, index) => {
    const subParts = splitString(part);
    if (subParts.length > 1) {
      return subParts.map((subPart, index) => {
        if (userPatterns.includes(subPart)) {
          const userId = subPart.substring(8, 32);
          const user = getUserData(userIdToUserMap[userId]);
          const handleClick = () => {
            if (user.provider !== "guest") openUserModal(user);
          };
          return (
            <span
              key={index}
              onClick={handleClick}
              className={` ${
                fromConversation ? "text-darkNight dark:text-frescoWhite" : ""
              }  font-medium cursor-pointer`}
            >
              {`@${user.username} ${
                user.provider === "guest" ? "· Guest " : ""
              }`}
            </span>
          );
        } else {
          return (
            <span key={index}>
              <Linkify componentDecorator={componentDecorator}>
                {subPart}
              </Linkify>
            </span>
          );
        }
      });
    } else if (userPatterns.includes(part)) {
      const userId = part.substring(8, 32);
      const user = getUserData(userIdToUserMap[userId]);
      const handleClick = () => {
        if (user.provider !== "guest") openUserModal(user);
      };
      return (
        <span
          key={index}
          onClick={handleClick}
          className={` ${
            chat?.isSend || fromConversation
              ? "text-darkNight dark:text-frescoWhite"
              : ""
          } cursor-pointer`}
        >
          {`@${user.username} ${user.provider === "guest" ? "· Guest " : ""}`}
        </span>
      );
    } else {
      return (
        <span key={index}>
          <Linkify componentDecorator={componentDecorator}>{part} </Linkify>
        </span>
      );
    }
  });
};

export const _renderSocialIcon = (
  provider,
  bottomPosition = "-bottom-1",
  icon,
) => {
  switch (provider) {
    case "bitclout":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <DeSo />
        </span>
      );
    case "linkedin":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <LinkedIn />
        </span>
      );
    case "twitter":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full text-black dark:text-frescoWhite`}
        >
          <TwitterV2 />
        </span>
      );
    case "metamask":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Metamask />
        </span>
      );
    case "warpcast":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Warpcast />
        </span>
      );
    case "instagram":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Instagram />
        </span>
      );
    case "tiktok":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <TikTok />
        </span>
      );
    case "facebook":
      return (
        <span
          className={`absolute md:bottom-6 bottom-3  md:right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full md:h-12 md:w-12 h-10 w-10 flex items-center justify-center`}
        >
          <Facebook ratio="30" />
        </span>
      );
    case "google":
      return (
        <span
          className={`absolute md:bottom-6 bottom-3  md:right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full md:h-12 md:w-12 h-10 w-10 flex items-center justify-center`}
        >
          <Google ratio="30" />
        </span>
      );
    case "worldcoin":
      return (
        <span
          className={`absolute md:bottom-6 bottom-3  md:right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full md:h-12 md:w-12 h-10 w-10 flex items-center justify-center`}
        >
          <Worldcoin ratio="30" />
        </span>
      );
    default:
      return (
        <span
          className={`absolute md:bottom-6 bottom-3  md:right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full md:h-12 md:w-12 h-10 w-10 flex items-center justify-center`}
        >
          {icon && (
            <span className="pl-0.5">
              <img src={icon} className="w-[30px] h-[30px]" alt={provider} />
            </span>
          )}
        </span>
      );
  }
};

export const _renderSocialProviderText = (provider) => {
  switch (provider) {
    case "bitclout":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          DeSo
        </p>
      );
    case "twitter":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          X
        </p>
      );
    case "metamask":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          Metamask
        </p>
      );
    case "warpcast":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          Warpcast
        </p>
      );
    case "instagram":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          Instagram
        </p>
      );
    case "tiktok":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          TikTok
        </p>
      );
    case "linkedin":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          LinkedIn
        </p>
      );
    case "facebook":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          Facebook
        </p>
      );
    case "google":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          Google
        </p>
      );
    case "worldcoin":
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          Worldcoin
        </p>
      );
    default:
      return (
        <p className="flex items-start text-start font-ubuntu text-darkGray">
          {provider}
        </p>
      );
  }
};

export const _renderSocialProviderTextIcon = (provider, icon) => {
  switch (provider) {
    case "bitclout":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">DeSo</span>
          <span className="pl-0.5">
            <DeSo ratio={16} />
          </span>
        </p>
      );
    case "twitter":
      return (
        <p className="flex text-sm items-center  font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">X</span>
          <span className="text-black dark:text-frescoWhite pl-0.5">
            <TwitterV2 ratio={16} />
          </span>
        </p>
      );
    case "metamask":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">Metamask</span>
          <span className="pl-0.5">
            <Metamask ratio={16} />
          </span>
        </p>
      );
    case "warpcast":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">Warpcast</span>
          <span className="pl-0.5">
            <Warpcast ratio={16} />
          </span>
        </p>
      );
    case "instagram":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">Instagram</span>
          <span className="pl-0.5">
            <Instagram ratio={16} />
          </span>
        </p>
      );
    case "tiktok":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">TikTok</span>
          <span className="pl-0.5">
            <TikTok ratio={16} />
          </span>
        </p>
      );
    case "linkedin":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">LinkedIn</span>
          <span className="pl-0.5">
            <LinkedIn ratio={20} />
          </span>
        </p>
      );
    case "facebook":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">Facebook</span>
          <span className="pl-0.5">
            <Facebook ratio={20} />
          </span>
        </p>
      );
    case "google":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">Google</span>
          <span className="pl-0.5">
            <Google ratio={18} />
          </span>
        </p>
      );
    case "worldcoin":
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">Worldcoin</span>
          <span className="pl-0.5">
            <Worldcoin ratio={20} />
          </span>
        </p>
      );
    default:
      return (
        <p className="flex text-sm items-center font-normal tracking-wide">
          <span className="font-ubuntu text-darkGray">{provider}</span>
          {icon && (
            <span className="pl-0.5">
              <img src={icon} className="w-5 h-5" alt={provider} />
            </span>
          )}
        </p>
      );
  }
};

export function getUniqueArrayByProperty(array, prop) {
  const flags = [],
    result = [];
  for (let i = 0; i < array.length; i++) {
    if (flags[array[i][prop]]) continue;
    if (!array[i][prop]) continue;
    flags[array[i][prop]] = true;
    result.push(array[i]);
  }
  return result;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function getCallDuration(callDuration) {
  let duration = moment.duration(callDuration, "milliseconds").format("ss", {
    useToLocaleString: true,
  });
  duration = duration.replace(/,/g, "");
  return forHumans(parseInt(duration));
}

function forHumans(seconds) {
  var levels = [
    [Math.floor(seconds / 31536000), "y"],
    [Math.floor((seconds % 31536000) / 86400), "d"],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), "h"],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), "m"],
    [(((seconds % 31536000) % 86400) % 3600) % 60, "s"],
  ];
  var returntext = "";

  for (var i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue;
    returntext +=
      " " +
      levels[i][0] +
      (levels[i][0] === 1
        ? levels[i][1].substr(0, levels[i][1].length)
        : levels[i][1]);
  }
  return returntext.trim();
}

export function getAverageTime(callDuration, callCount) {
  let duration =
    moment.duration(callDuration, "milliseconds").asSeconds() / callCount;
  duration = Math.round(duration);
  return forHumans(parseInt(duration));
}
export function removeDuplicatesEmoji(array) {
  const uniqueUnified = {};
  return array.filter((item) =>
    !uniqueUnified[item.unified] ? (uniqueUnified[item.unified] = true) : false,
  );
}

export function filterDuplicate(events) {
  return events.filter(
    (v, i, a) => a.findIndex((v2) => v2._id === v._id) === i,
  );
}

export const getEnv = () => {
  switch (window.location.host) {
    case "vibehut-633f7.web.app":
      return "staging";

    case "vibehut-staging.herokuapp.com":
      return "staging";

    case "vibehut.io":
    case "news.vibehut.io":
      return "prod";

    case "vibehut-test.looklike.io":
      return "testing";

    default:
      return "local";
  }
};

export const wait = (p_milliSeconds) =>
  new Promise((p_resolve) => setTimeout(p_resolve, p_milliSeconds));

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return `<a target="_blank" className="font-bold break-all block underline" href="${url}">${url}</a>`;
  });
};

// export const componentDecorator = (decoratedHref, decoratedText, key) => (
//   <LinkPreview url={decoratedText} />
// );

export const componentDecorator = (decoratedHref, decoratedText, key) => (
  <a
    href={decoratedHref}
    key={key}
    target="_blank"
    className=" text-weight-bold break-all underline"
    rel="noreferrer"
  >
    {decoratedText}
  </a>
);

// Generate a list of label value year list from 2002 to current year
export const generateYearArray = () => {
  const start = new Date(2022, 0, 1);
  const end = new Date();
  let years = [];
  while (start <= end) {
    years.push(start.getFullYear());
    start.setFullYear(start.getFullYear() + 1);
  }

  years = years.map((y) => {
    return {
      label: y,
      value: new Date(y, 0, 1),
    };
  });
  return years;
};

const lastyear = (pre) => {
  var d = new Date();
  d.setFullYear(d.getFullYear() - pre, 0, 1);
  d.setHours(0, 0, 0, 0);
  return d;
};
const lastdays = (pre) => {
  var d = new Date();
  d.setDate(d.getDate() - pre);
  return d;
};

export const options = [
  { label: "Last 24 hours", value: lastdays(1) },
  { label: "Last 7 days", value: lastdays(8) },
  { label: "Last 30 days", value: lastdays(30) },
  { label: "From 2023", value: lastyear(1) },
  { label: "All time", value: null },
];

export const carouselResponsiveConfig = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 769 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 641 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

export const getRoomImage = (post) => {
  if (
    (!post?.eventsMentioned || post?.eventsMentioned?.length === 0) &&
    post?.roomsMentioned &&
    post.roomsMentioned[0]?.cover?.urls?.small
  ) {
    return (
      <div className="pl-2">
        <div className="h-10 w-10">
          <img
            src={post.roomsMentioned[0]?.cover?.urls?.small}
            alt="event"
            className="w-full h-full rounded"
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const getEventImage = (post) => {
  if (
    post?.eventsMentioned?.length > 0 &&
    post.eventsMentioned[0]?.cover?.urls?.small &&
    post?.usersMentioned?.length > 0
  ) {
    return (
      <div className="pl-2">
        <div className="h-10 w-10">
          <img
            src={post.eventsMentioned[0]?.cover?.urls?.small}
            alt="event"
            className="w-full h-full rounded"
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const getVideoThumbnail = (post) => {
  if (
    post?.watchMentioned?.length > 0 &&
    post.watchMentioned[0]?.thumbnail &&
    post?.usersMentioned?.length > 0
  ) {
    return (
      <div className="pl-2">
        <div className="h-10 w-10">
          <img
            src={post.watchMentioned[0]?.thumbnail}
            alt="event"
            className="w-full h-full rounded"
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};
export const getAppImage = (post) => {
  if (
    post?.appMentioned?.length > 0 &&
    post.appMentioned[0]?.coverImageUrl &&
    post?.appMentioned?.length > 0
  ) {
    return (
      <div className="pl-2">
        <div className="h-10 w-10">
          <img
            src={post.appMentioned[0]?.coverImageUrl}
            alt="event"
            className="w-full h-full rounded"
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const getPersonaImage = (post) => {
  if (
    post?.personasMentioned?.length > 0 &&
    post.personasMentioned[0]?.avatar
  ) {
    return (
      <div className="pl-2">
        <div className="h-10 w-10">
          <img
            src={post.personasMentioned[0]?.avatar}
            alt="event"
            className="w-full h-full rounded"
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const getPostImageSection = (post) => {
  if (
    post?.eventsMentioned?.length > 0 &&
    post.eventsMentioned[0]?.cover?.urls?.small &&
    post?.usersMentioned?.length > 0
  ) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (post?.body?.includes("joined")) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (post?.body?.includes("free to talk")) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (post?.watchMentioned[0] && post?.usersMentioned[0]) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (
    post?.appMentioned &&
    post?.appMentioned[0] &&
    post?.usersMentioned[0]
  ) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (
    post?.roomsMentioned &&
    post.roomsMentioned[0]?.cover?.urls?.small &&
    post?.usersMentioned?.length > 0
  ) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (
    post?.roomsMentioned &&
    post.roomsMentioned[0]?.cover?.urls?.small &&
    post?.usersMentioned?.length === 0
  ) {
    return (
      <div className="h-10 w-10">
        <img
          src={post.roomsMentioned[0]?.cover?.urls?.small}
          alt="room"
          className="w-full h-full rounded"
        />
      </div>
    );
  } else if (post?.usersMentioned?.length > 0) {
    return (
      <Avatar
        src={getUserData(post.usersMentioned[0]).profile_picture}
        size="sm"
        username={getUserData(post.usersMentioned[0]).username}
      />
    );
  } else if (post?.personasMentioned?.length > 0) {
    return (
      <Avatar
        src={post.personasMentioned[0]?.avatar}
        size="sm"
        username={post.personasMentioned[0]?.name}
      />
    );
  } else {
    return <></>;
  }
};

export function ObjectCleaner(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function checkUsernameRegex(username) {
  const regex = /^[a-zA-Z0-9_-]{3,16}$/;
  return regex.test(username);
}

export function isRoomSlugValid(roomSlug, isEmptyAllowed = false) {
  // Room slug should be a string
  if (typeof roomSlug !== "string") {
    return {
      isValid: false,
      reason: "Room slug should be a string",
    };
  }

  // Room slug should not be empty
  if (!isEmptyAllowed && roomSlug.trim() === "") {
    return {
      isValid: false,
      reason: "Room slug should not be empty",
    };
  }

  // Room slug should only contain alphanumeric characters, hyphens, or underscores
  if (!/^[a-zA-Z0-9-_ ]*$/.test(roomSlug)) {
    return {
      isValid: false,
      reason:
        "Room slug should only contain alphanumeric characters, hyphens, or underscores",
    };
  }

  // Room slug should not start or end with hyphens, underscores
  if (/^[-_]|[-_]$/.test(roomSlug)) {
    return {
      isValid: false,
      reason: "Room slug should not start or end with hyphens, or underscores",
    };
  }

  // Room slug should not contain consecutive hyphens, underscores, or spaces
  if (/[-_ ]{2,}/.test(roomSlug)) {
    return {
      isValid: false,
      reason:
        "Room slug should not contain consecutive hyphens, underscores, or spaces",
    };
  }

  // Room slug is valid
  return {
    isValid: true,
    reason: null,
  };
}

export function convertSlugToReadableFormat(roomName) {
  return roomName.split("-").join(" ");
}

export function createRoomSlug(roomName, isUnique = false) {
  // Replace all spaces with hyphens and remove all non-alphanumeric characters
  const slug = roomName
    .replace(/[^\w\s-]/g, "")
    .trim()
    .replace(/[\s]+/g, "-")
    .toLowerCase();

  if (isUnique) {
    // Add a random string to the end of the slug to make it unique
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${slug}-${randomString}`;
  }
  return slug;
}

export function userSlugLimitCheck(slug, userRole, slugCharactersLimit) {
  for (const item of slugCharactersLimit) {
    if (item?.name === userRole?.name) {
      if (slug.length <= item.minLimit) {
        return "Slug should be more than " + item.minLimit + " characters";
      }
    }
  }
}

export function calcDayDifference(startDate, currentDate) {
  const _startDate = moment(startDate);
  const _currentDate = moment(currentDate);
  return _currentDate.diff(_startDate, "days");
}

export const userServiceRoleMap = {
  "64e73f1803b9abebda5adf69": {
    planName: "Individual Plan role",
    planRole: userRoleTypes.FREE,
    badgeColor: "#7A7A7A",
  },
  "63b6ec661ecac8c97cfda29c": {
    planName: "Individual Plan role",
    planRole: userRoleTypes.STARTER,
    badgeColor: "#716DFF",
  },
  "63b6ece31ecac8c97cfda2da": {
    planName: "Pro Plan role",
    planRole: userRoleTypes.PRO,
    badgeColor: "#0095F6",
  },
  "63b6ed2c1ecac8c97cfda307": {
    planName: "Business Plan role",
    planRole: userRoleTypes.BUSINESS,
    badgeColor: "#FFCC00",
  },
};

export function getUserRole(role, serviceRole) {
  const roles = role.roles;
  const foundRole = roles.find((element) => element._id === serviceRole);
  if (foundRole) {
    return foundRole;
  } else {
    return null;
  }
}

export function currencyFormat(amount) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function handleCurrencyInputChange(e, setAmount) {
  e.preventDefault();
  const { value = "" } = e.target;
  const parsedValue = value.replace(/[^\d.]/gi, "");
  if (isNaN(parsedValue)) return;
  setAmount(parsedValue);
}

export function convertToDecimal(number) {
  if (typeof number === "undefined") {
    return "0.00";
  }
  return Number(number).toFixed(2);
}

export function checkCreateRoomPermission(role, myRoomsCount, user) {
  const userRole = getUserRole(role, user.serviceRole);
  const limit = userRole ? userRole?.limits?.rooms : 1;

  if (typeof myRoomsCount !== "number") {
    return false;
  }

  if (myRoomsCount >= limit) {
    return false;
  }

  return true;
}

export function checkUploadExternalVideoPermission(
  role,
  externalVideosCount,
  user,
) {
  const userRole = getUserRole(role, user.serviceRole);
  const limit = userRole ? userRole?.limits?.externalVideos : 1;

  if (typeof externalVideosCount !== "number") {
    return false;
  }

  if (externalVideosCount >= limit) {
    return false;
  }

  return true;
}

export function isStarterPlanUser(role, user) {
  const userRole = getUserRole(role, user?.serviceRole);
  if (userRole?.name === "Individual Plan role") {
    return true;
  }
  return false;
}

export function isFreePlanUser(role, user) {
  const userRole = getUserRole(role, user?.serviceRole);
  if (userRole?.name === "Free Plan role") {
    return true;
  }
  return false;
}

export function isProPlusPlanUser(role, user) {
  const userRole = getUserRole(role, user?.serviceRole);
  if (
    userRole?.name === "Pro Plan role" ||
    userRole?.name === "Business Plan role"
  ) {
    return true;
  }
  return false;
}

export function checkCreateSlugRoomPermission(
  role,
  myRoomsSlugCount,
  serviceRole,
) {
  const userRole = getUserRole(role, serviceRole);
  const limit = userRole ? userRole.limits.slugs : 1;

  if (typeof myRoomsSlugCount !== "number") {
    return false;
  }

  if (myRoomsSlugCount >= limit) {
    return false;
  }

  return true;
}

export const getDecryptMessage = (roomId, isEncrypted, message) => {
  try {
    if (!isEncrypted) {
      return message;
    }
    const regex = /^U2F.*/;
    if (!regex.test(message)) {
      return message;
    }
    const secretKey = roomId + process.env.REACT_APP_AES_SECRET_KEY;
    const dcMessage = decryptMsg(secretKey, message);
    return dcMessage;
  } catch (error) {
    console.log("error", error);
  }
};

export const _renderTwitter = (url) => {
  const regex = /https:\/\/twitter\.com\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://twitter.com/${url}`;
  return (
    <span className="-mt-1">
      <a
        href={regUrl}
        target="_blank"
        rel="noreferrer"
        className="text-black dark:text-frescoWhite "
      >
        <TwitterV2 ratio={18} />
      </a>
    </span>
  );
};

export const _renderInstagram = (url) => {
  const regex = /https:\/\/www\.instagram\.com\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://www.instagram.com/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <Instagram ratio={24} />
      </a>
    </span>
  );
};

export const _renderFacebook = (url) => {
  const regex = /https:\/\/www\.facebook\.com\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://www.facebook.com/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <Facebook ratio={20} />
      </a>
    </span>
  );
};

export const _renderTelegram = (url) => {
  const regex = /https:\/\/telegram\.me\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://telegram.me/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <Telegram ratio={24} />
      </a>
    </span>
  );
};
export const _renderTiktok = (url) => {
  const regex = /https:\/\/tiktok\.com\/@([a-zA-Z0-9_]+)/;
  let tiktokUrl = "";
  if (regex.test(url)) {
    tiktokUrl = url;
  } else {
    if (url[0] === "@") {
      tiktokUrl = `https://tiktok.com/${url}`;
    } else {
      tiktokUrl = `https://tiktok.com/@${url}`;
    }
  }
  return (
    <span>
      <a href={tiktokUrl} target="_blank" rel="noreferrer">
        <TikTok ratio={20} />
      </a>
    </span>
  );
};

export const _renderLinkedIn = (url) => {
  const regex =
    /^(?:(?:https?:\/\/)?(?:www\.|w\.)?.*linkedin\.com\/(in|company)\/([a-zA-Z0-9_/-]+)\/?)$/;
  const match = url.match(regex);

  let validUrl;
  if (match) {
    const type = match[1];
    const identifier = match[2];
    validUrl = `https://www.linkedin.com/${type}/${identifier}`;
  } else {
    validUrl = `https://www.linkedin.com/in/${url}`;
  }

  return (
    <span>
      <a href={validUrl} target="_blank" rel="noreferrer">
        <LinkedIn ratio={26} />
      </a>
    </span>
  );
};

export const _renderThread = (url) => {
  const regex = /https:\/\/threads\.net\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://threads.net/${url}`;
  return (
    <span>
      <a
        href={regUrl}
        target="_blank"
        rel="noreferrer"
        className="text-black dark:text-frescoWhite"
      >
        <Thread ratio={22} />
      </a>
    </span>
  );
};

export const _renderDeso = (url) => {
  const regex = /https:\/\/diamondapp\.com\/u\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://diamondapp.com/u/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <DeSo ratio={24} />
      </a>
    </span>
  );
};

export function getNext10Days() {
  const today = new Date();
  const next10Days = [];
  const currentHour = moment(today).format("HH");
  for (let i = 0; i < 10; i++) {
    const nextDay = new Date(today);
    const nxt = nextDay.setDate(today.getDate() + i);
    const _today = moment(today).format("DD");
    const _date = moment(nxt).format("DD");
    if (!(currentHour === 23 && _today === _date)) {
      next10Days.push(nextDay);
    }
  }
  return next10Days;
}

export function getAllHours(date) {
  const currentDay = new Date().getDay();
  const newDay = new Date(date).getDay();
  let currentHour;
  if (currentDay === newDay) {
    currentHour = new Date().getHours() + 1;
  } else {
    currentHour = 0;
  }

  const hours = [];

  for (let i = currentHour; i < 24; i++) {
    const hour = new Date(date);
    hour.setHours(i);
    hour.setMinutes(0);
    hour.setSeconds(0);
    hours.push(hour);
  }

  return hours;
}

export function detectInAppBrowser() {
  let ua = navigator.userAgent;

  ua = ua.toLowerCase().trim();
  const isIOS =
    ua.includes("iphone") || ua.includes("ipod") || ua.includes("ipad");
  const isAndroid = ua.includes("android");

  // Facebook
  if (ua.includes("fbios") || ua.includes("fb_iab")) {
    return isIOS
      ? "is_facebook_ios"
      : isAndroid
        ? "is_facebook_android"
        : "is_facebook_unknown";
  }

  // Instagram
  if (ua.includes("instagram")) {
    return isIOS
      ? "is_instagram_ios"
      : isAndroid
        ? "is_instagram_android"
        : "is_instagram_unknown";
  }

  // LINE
  if (ua.includes(" line/")) {
    return isIOS
      ? "is_line_ios"
      : isAndroid
        ? "is_line_android"
        : "is_line_unknown";
  }

  const isIos = /iphone|ipod|ipad/.test(navigator.userAgent);

  if (!isIos && navigator.userAgent.includes("wv")) {
    return "is_android_webview";
  }

  const is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
    navigator.userAgent,
  );

  if (is_uiwebview) {
    return "is_ios_webview";
  }

  return "";
}

export function getShortenedName(name, limit = 40) {
  if (name) {
    if (name.length > limit) {
      return name.slice(0, limit) + "...";
    } else {
      return name;
    }
  }
  return "";
}

export const validateEmailRegex = (email) => {
  return email.toLowerCase().match(
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

export function checkIsDesoUser(user) {
  if (user) {
    if (
      user?.provider === "bitclout" &&
      user?.bitclout_data?.bitclout_publickey
    ) {
      return true;
    }
  }
  return false;
}
export function checkIsDesoPostAndUser(user, post) {
  const isDesoPost = post.postSource === "deso";
  const isDesoUser = checkIsDesoUser(user);
  if (isDesoPost && isDesoUser) {
    return true;
  }
  return false;
}

export function getActiveUserPublicKey(user) {
  if (user) {
    if (
      user?.provider === "bitclout" &&
      user?.bitclout_data?.bitclout_publickey
    ) {
      return user?.bitclout_data?.bitclout_publickey;
    }
  }
  return "";
}

export const processDesoPostToVibeHutUserPost = (desoPost) => {
  return {
    id: desoPost.PostHashHex,
    postSource: "deso",
    body: desoPost.Body,
    imageUrls: desoPost.ImageURLs,
    videoUrls: desoPost.VideoURLs,
    isHidden: desoPost.IsHidden,
    parentPostId: desoPost.ParentStakeID,
    postedBy: {
      username: desoPost.ProfileEntryResponse?.Username,
      profilePicture: getDesoProfilePicture(
        desoPost.ProfileEntryResponse?.ExtraData?.LargeProfilePicURL,
        desoPost.ProfileEntryResponse.PublicKeyBase58Check,
      ),
    },
    createdAt: new Date(Math.round(desoPost.TimestampNanos / 1000000)),
    isLiked: desoPost.PostEntryReaderState?.LikedByReader,
    likeCount: desoPost.LikeCount,
    diamondCount: desoPost.DiamondCount,
    // rawPost: desoPost,
  };
};

const getDesoProfilePicture = (largeProfilePicURL, publicKeyBase58Check) => {
  if (largeProfilePicURL) {
    return largeProfilePicURL;
  }
  return `https://diamondapp.com/api/v0/get-single-profile-picture/${publicKeyBase58Check}`;
};

export const isEmptyObject = (obj) => {
  if (obj) {
    return Object.keys(obj).length === 0;
  }
  return true;
};

export const calcPlanIconColor = (planName) => {
  switch (planName) {
    case "Business":
      return "#FFCC00";
    case "Pro":
      return "#0095F6";
    case "Starter":
      return "#716DFF";
    default:
      return "#7A7A7A";
  }
};

export const saveDraftToLocalStorage = (roomId, draft) => {
  try {
    const storedChatMessages = localStorage.getItem("DraftStore");
    const drafts = storedChatMessages ? JSON.parse(storedChatMessages) : {};
    drafts[roomId] = {
      timestamp: Date.now(),
      draft,
    };
    localStorage.setItem("DraftStore", JSON.stringify(drafts));
  } catch (error) {}
};

export const saveChatRoomToLocalStorage = (id, messages) => {
  try {
    const storedChatMessages = localStorage.getItem("ChatRoomStore");
    const chatMessages = storedChatMessages
      ? JSON.parse(storedChatMessages)
      : {};
    chatMessages[id] = messages;
    localStorage.setItem("ChatRoomStore", JSON.stringify(chatMessages));
  } catch (error) {
    console.log("error", error);
  }
};

export const saveConversationToLocalStorage = (conversations) => {
  try {
    localStorage.setItem(
      "ChatConversationStore",
      JSON.stringify(conversations),
    );
  } catch (error) {
    console.log("error", error);
  }
};

export const saveChatMessagesToLocalStorage = (id, messages) => {
  try {
    const storedChatMessages = localStorage.getItem("ChatStore");
    const chatMessages = storedChatMessages
      ? JSON.parse(storedChatMessages)
      : {};
    chatMessages[id] = messages;

    localStorage.setItem("ChatStore", JSON.stringify(chatMessages));
  } catch (error) {
    console.log("error", error);
  }
};
export const saveToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.log("error", error);
  }
};

export const updateChatMessageToLocalStorage = (id, message) => {
  try {
    const storedChatMessages = localStorage.getItem("ChatStore");
    const chatMessages = storedChatMessages
      ? JSON.parse(storedChatMessages)
      : {};

    chatMessages[id] = chatMessages[id] || [];
    chatMessages[id].push(message);

    localStorage.setItem("ChatStore", JSON.stringify(chatMessages));
  } catch (error) {
    console.log("error", error);
  }
};
export const saveApprovedPromotionToLocalStrorage = (promotion) => {
  try {
    localStorage.setItem("ApprovedPromotion", JSON.stringify(promotion));
  } catch (error) {
    console.log("error", error);
  }
};
export const extractFirstUrl = (text) => {
  const urlRegex = /https?:\/\/[^ \n]+/i;
  const match = text.match(urlRegex);
  return match ? match[0] : null;
};

export const handleTransformDataTransferIntoURL = (dataTransfer, callback) => {
  const [firstItem] = dataTransfer.items;
  const blob = firstItem.getAsFile();
  if (blob) {
    callback(blob);
    return;
  }

  firstItem.getAsString(async (text) => {
    const url = extractFirstUrl(text);
    if (!url) {
      callback(null);
      return;
    }
    const res = await fetch(url);
    const data = await res.blob();
    callback(data);
  });
};

export const extractUrlHostname = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error("Error parsing URL:", error.message);
    return null;
  }
};

export const getAmountFromDiamondLevel = (diamondLevel) => {
  switch (diamondLevel) {
    case 1:
      return 0.01;
    case 2:
      return 0.01;
    case 3:
      return 0.05;
    case 4:
      return 0.51;
    case 5:
      return 5.12;
    case 6:
      return 51.2;
    default:
      return 0.0;
  }
};

export const getDiamondIconsFromDiamondLevel = (diamondLevel) => {
  const diamondIcons = Array.from({ length: diamondLevel }, (_, index) => (
    <span key={index}>💎</span>
  ));

  return diamondIcons.length > 0 ? diamondIcons : <span>💎</span>;
};

export const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
  if (originalPrice < 0 || discountPercentage < 0 || discountPercentage > 100) {
    console.error("Invalid input. Please provide valid values.");
    return null;
  }

  const discountAmount = (originalPrice * discountPercentage) / 100;
  const discountedPrice = originalPrice - discountAmount;
  const roundedDiscountedPrice = discountedPrice.toFixed(2);
  return roundedDiscountedPrice;
};

export function checkUpdatePersonaDetailsPermission(role, user) {
  const userRole = getUserRole(role, user.serviceRole);
  if (userRole.permissions.includes("personaDetails:write")) {
    return true;
  }
  return false;
}

export function checkPersonaCompanyAffiliationWritePermission(role, user) {
  const userRole = getUserRole(role, user.serviceRole);
  if (userRole.permissions.includes("personaCompanyAffiliation:write")) {
    return true;
  }
  return false;
}

export function groupEventsByDates(events) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const groupedEvents = events.reduce((acc, event) => {
    const startDate = new Date(event.startDate);
    startDate.setHours(0, 0, 0, 0);

    if (startDate.getTime() === today.getTime()) {
      if (!acc["Today"]) {
        acc["Today"] = [];
      }
      acc["Today"].push(event);
    } else if (startDate.getTime() === tomorrow.getTime()) {
      if (!acc["Tomorrow"]) {
        acc["Tomorrow"] = [];
      }
      acc["Tomorrow"].push(event);
    } else {
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(startDate);

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(event);
    }

    return acc;
  }, {});

  const finalGroupedEvents = Object.keys(groupedEvents).reduce((acc, date) => {
    if (groupedEvents[date].length > 1) {
      acc[date] = groupedEvents[date];
    } else {
      if (!acc["Others"]) {
        acc["Others"] = [];
      }
      acc["Others"].push(groupedEvents[date][0]);
    }
    return acc;
  }, {});

  return finalGroupedEvents;
}

export const formatForDateInput = (date) => {
  return moment(date).format("YYYY-MM-DDTHH:mm");
};

export const getMediaUrl = (media) => {
  if (media?.secure_url) {
    return media.secure_url;
  } else if (media?.regular) {
    return media.regular;
  } else if (media?.full) {
    return media.full;
  } else {
    return media.raw;
  }
};

export function checkIsMetamaskUser(user) {
  if (user) {
    if (user?.provider === "metamask" && user?.metamask_data?.wallet_address) {
      return true;
    }
  }
  return false;
}

export const copyUrlLink = async (url, title) => {
  if (isMobile() && navigator.share) {
    navigator
      .share({
        ...(title && {
          title: title,
        }),
        url: url,
      })
      .catch();
  } else {
    navigator.clipboard.writeText(url);
  }
};

export const getMetamaskUsername = (metamaskUser) => {
  if (metamaskUser?.ens) {
    return metamaskUser.ens;
  }
  if (metamaskUser?.farcaster?.username) {
    return metamaskUser.farcaster.username;
  }
  return metamaskUser?.wallet_address?.slice(0, 15) + "...";
};

export const getMetamaskUserProfileImage = (metamaskUser) => {
  if (metamaskUser?.farcaster?.avatarUrl) {
    return metamaskUser.farcaster.avatarUrl;
  }
  return "";
};

export function validateYouTubeUrl(url) {
  if (url != undefined || url != "") {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return true;
    } else {
      return false;
    }
  }
}

export function getThumbnailFromYoutubeVideo(url, size) {
  if (url === null) {
    return "";
  }

  size = size === null ? "big" : size;

  let videoId;
  const results = url.match("[\\?&]v=([^&#]*)");
  if (results && results[1]) {
    videoId = results[1];
  } else {
    const parts = url.split("/");
    videoId = parts[parts.length - 1].split("?")[0];
  }

  if (size === "small") {
    return `http://img.youtube.com/vi/${videoId}/2.jpg`;
  } else {
    return `http://img.youtube.com/vi/${videoId}/0.jpg`;
  }
}

export function convertToEmbedUrl(youtubeUrl) {
  const videoIdMatch = youtubeUrl.match(
    /(?:\?v=|\/embed\/|\/\d\/|\/vi\/|youtu.be\/|\/\d\?|\/\d#)([a-zA-Z0-9_-]{11})/,
  );
  if (videoIdMatch && videoIdMatch[1]) {
    const videoId = videoIdMatch[1];
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    return null;
  }
}

export const combineDateTime = (date, time) => {
  if (!time) {
    const combinedDateTime = new Date(date);
    combinedDateTime.setHours(0, 0, 0, 0);
    return combinedDateTime;
  }
  const combinedDateTime = new Date(date);
  combinedDateTime.setHours(time.getHours(), time.getMinutes());
  return combinedDateTime;
};

export function convertToOEmbedUrl(youtubeUrl) {
  let videoId;
  if (youtubeUrl.includes("youtube.com/watch")) {
    videoId = youtubeUrl.split("v=")[1].split("&")[0];
  } else if (youtubeUrl.includes("youtu.be/")) {
    videoId = youtubeUrl.split("youtu.be/")[1].split("?")[0];
  } else {
    return "";
  }

  const oEmbedUrl = `https://www.youtube.com/oembed?url=http%3A//youtube.com/watch%3Fv%3D${videoId}&format=json`;

  return oEmbedUrl;
}

async function fetchOEmbedData(oEmbedUrl) {
  try {
    const response = await axios.get(oEmbedUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching oEmbed data:", error);
    return null;
  }
}

export async function getOEmbedDataFromYoutubeUrl(youtubeUrl) {
  const oEmbedUrl = convertToOEmbedUrl(youtubeUrl);
  if (!oEmbedUrl) {
    return null;
  }

  const oEmbedData = await fetchOEmbedData(oEmbedUrl);
  return oEmbedData;
}

export function getYoutubeVideoId(youtubeUrl) {
  let videoId;
  if (youtubeUrl.includes("youtube.com/watch")) {
    videoId = youtubeUrl.split("v=")[1].split("&")[0];
  } else if (youtubeUrl.includes("youtu.be/")) {
    videoId = youtubeUrl.split("youtu.be/")[1].split("?")[0];
  } else {
    return "";
  }

  return videoId;
}

export function timeLeftToday() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = moment().tz(timezone);
  const endOfDay = now.clone().endOf("day");
  const minutesDifference = endOfDay.diff(now, "minutes");
  if (minutesDifference < 60) {
    return { difference: minutesDifference, isMinute: true };
  }
  return { difference: endOfDay.diff(now, "hours"), isMinute: false };
}

export function generateSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .trim()
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}
