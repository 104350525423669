import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { getUserData } from '../../../../utils/vibehutUtils';
import useAuthUser from '../../../../hooks/useAuthUser/useAuthUser';
import Avatar from '../../../../VibehutUI/Avatar';
import { isMobile } from '../../../../utils';
import { getShortenedName } from '../../../../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    paddingTop: `${(9 / 16) * 100}%`,
    background: '#303234',
    borderRadius: '12px',
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  identityContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  identity: {
    background: '#303234',
    color: 'white',
    padding: '0.18em 0.3em',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
}));

export default function LocalVideoPreview() {
  const classes = useStyles();
  const { localTracks } = useVideoContext();
  const { authUser } = useAuthUser();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  const userData = getUserData(authUser);

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classes.avatarContainer}>
            <Avatar src={userData.profile_picture} username={userData.username} size={isMobile ? '60px' : '3xl'} />
          </div>
        )}
      </div>
      <div className="absolute top-2 right-2">
        <LocalAudioLevelIndicator />
      </div>
      <div className={classes.identityContainer}>
        <div className={`text-shadow absolute z-30 bottom-1 left-2  md:bottom-3.5 md:left-4 text-white`}>
          {getShortenedName(userData.username, 16)}
        </div>
      </div>
    </div>
  );
}
