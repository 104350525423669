import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import Avatar from '../Avatar';
import Close from '../Icons/Close';

import ShowActiveUser from './ShowActiveUser';

interface TalkingUsersModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (data: boolean) => void;
  usersCount: number;
  talkingUsers: any[];
  lobby_users: any[];
  hideLobbyUser?: boolean;
}

function TalkingUsersModal({
  isModalOpen,
  setIsModalOpen,
  usersCount,
  talkingUsers,
  lobby_users,
  hideLobbyUser = false,
}: TalkingUsersModalProps) {
  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={() => setIsModalOpen(false)}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-sm  my-8 overflow-hidden text-left align-middle transition-all transform  bg-white shadow-xl rounded-2xl h-420px">
                <div className="bg-white  py-5 px-4 border-b border-frescoWhite ">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-darkNight ">All People · {usersCount}</p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={() => setIsModalOpen(false)}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="showScrolbar">
                  <div className="mt-3 overflow-y-auto  custom-scrollbar">
                    {!hideLobbyUser ? (
                      <div className="px-4">
                        <p className="text-base text-black font-bold">In Lobby</p>
                        <div className="pt-2">
                          {lobby_users && lobby_users.length > 0 ? (
                            lobby_users.map((user, index) => (
                              <div className="flex items-center py-1" key={index}>
                                <div className="">
                                  <div className="relative">
                                    <Avatar src={user.profile_picture} username={user.username} size="sm-2" />
                                  </div>
                                </div>
                                <div className="text-sm font-medium text-darkNight pl-5">{user.username}</div>
                              </div>
                            ))
                          ) : (
                            <p className="text-sm font-medium text-darkNight">No users in the Lobby</p>
                          )}
                        </div>
                      </div>
                    ) : null}

                    <div className="h-64">
                      <div className="pt-3 px-4">
                        <p className="text-base text-black font-bold">Talking</p>
                      </div>
                      <div>
                        {talkingUsers &&
                          talkingUsers.length > 0 &&
                          talkingUsers.map((user, index) => <ShowActiveUser users={user} index={index} />)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default TalkingUsersModal;
