import React from "react";

const Referral = () => {
  return (
    <div className="w-8 h-8 p-1 bg-[#6a66ff] dark:text-frescoWhite  rounded-lg flex justify-center items-center">
      <svg
        width="15"
        height="15"
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1654 18V16.3333C14.1654 15.4493 13.8142 14.6014 13.1891 13.9763C12.5639 13.3512 11.7161 13 10.832 13H4.16536C3.28131 13 2.43346 13.3512 1.80834 13.9763C1.18322 14.6014 0.832031 15.4493 0.832031 16.3333V18"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5013 9.66667C9.34225 9.66667 10.8346 8.17428 10.8346 6.33333C10.8346 4.49238 9.34225 3 7.5013 3C5.66035 3 4.16797 4.49238 4.16797 6.33333C4.16797 8.17428 5.66035 9.66667 7.5013 9.66667Z"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.168 18.0001V16.3334C19.1674 15.5948 18.9216 14.8774 18.4691 14.2937C18.0166 13.7099 17.3831 13.293 16.668 13.1084"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.332 3.1084C14.049 3.29198 14.6846 3.70898 15.1384 4.29366C15.5922 4.87833 15.8386 5.59742 15.8386 6.33757C15.8386 7.07771 15.5922 7.7968 15.1384 8.38147C14.6846 8.96615 14.049 9.38315 13.332 9.56673"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Referral;
