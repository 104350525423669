import React, { useContext } from "react";
import Button from "../../VibehutUI/Button";
import globals from "../../globals";
import MessageComposer from "../../VibehutUI/MessageComposer";
import { StoreContext } from "../../Context/StoreContext";
import * as actionTypes from "../../constants";
import { getUserData } from "../../services/userService";
import { getDecryptMessage } from "../../utils/utils";
import { encryptMsg } from "../../utils/AES";

function EditChatModal({ chat, editMessage, setIsOpen = undefined }) {
  const { dispatch } = useContext(StoreContext);
  const handleSubmit = (newMsg) => {
    if (newMsg) {
      try {
        const secretKey = chat.room + process.env.REACT_APP_AES_SECRET_KEY;
        const encryptedMessage =
          msg.trim().substring(0, 4) === "@ami"
            ? msg
            : encryptMsg(secretKey, newMsg);
        editMessage({ chatId: chat._id, message: encryptedMessage });
        dispatch({
          type: actionTypes.EDIT_MESSAGE,
          payload: {
            message: encryptedMessage,
            chatId: chat._id,
            isEncrypted: chat.isEncrypted,
          },
        });
        if (!!setIsOpen) {
          setIsOpen(false);
        } else {
          globals.hideModal();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getText = () => {
    let bodyText = getDecryptMessage(chat.room, chat.isEncrypted, chat.message);
    const userIdToUserMap = {};
    const userPatterns =
      chat.usersMentioned?.map((user) => {
        userIdToUserMap[user._id.toString()] = user;
        return `$(_user:${user._id.toString()})`;
      }) || [];
    const result = bodyText.split(/\s/).map((part) => {
      if (userPatterns.includes(part)) {
        const userId = part.substring(8, 32);
        const user = getUserData(userIdToUserMap[userId]);
        return `@${user.username}`;
      } else {
        return part;
      }
    });
    return result.join(" ");
  };

  const msg =
    chat.usersMentioned.length > 0
      ? getText()
      : getDecryptMessage(chat.room, chat.isEncrypted, chat.message);
  return (
    <>
      <div className=" justify-center py-4  rounded-2xl">
        <div>
          <div className="flex flex-row items-center gap-4 w-full">
            <div className="w-full">
              <MessageComposer
                onSubmit={handleSubmit}
                maxRows={5}
                value={msg}
                roomId={true}
              />
            </div>
          </div>
        </div>
        <div className="pt-3 flex flex-row-reverse">
          <div className="mr-2">
            <Button
              size="vibhut-sm"
              variant="vibhut-secondary"
              onClick={() => {
                globals.hideModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditChatModal;
