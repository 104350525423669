import * as actionTypes from "../constants";

export const openEventSubscriptionModal = (dispatch, event, message, type) => {
  dispatch({
    type: actionTypes.OPEN_EVENT_MODAL,
    payload: {
      selectedEvent: event,
      message: message,
      type: type,
    },
  });
};

export const closeEventSubscriptionModal = (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_EVENT_MODAL,
    payload: actionTypes.CLOSE_MODAL,
  });
};
