import * as actionTypes from "../constants";
import { isMobile } from "../videoChat/utils";

const initialState = {
  magData: [],
  offset: 0,
  isMore: false,
  isMoreFetching: false,
  unreadMessagesCount: 0,
  isChatLoading: true,
  canScroll: true,
  isAnimatedScroll: true,
  isPersonaThinking: false,
  unlockedChatRoom: [],
  liveStreamMessages: [],
  liveStreamUnreadMessageCount: 0,
  activeTab: "members",
};

function deepCopy(obj) {
  const copy = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      copy[key] = deepCopy(obj[key]);
    } else {
      copy[key] = obj[key];
    }
  }
  return copy;
}

function chatReducer(state, action) {
  switch (action.type) {
    case actionTypes.GET_ROOM_MESSAGES_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return { ...state, isChatLoading: true };

    case actionTypes.GET_ROOM_MESSAGES_SUCCESS:
      const ofst =
        action.payload.length >= 20 ? action.offset + 20 : action.offset;
      if (action.loadMore) {
        return {
          ...state,
          magData: [...action.payload, ...state.magData],

          offset: ofst,
          isMoreFetching: false,
          isMore: action.payload.length >= 20 ? true : false,
          isChatLoading: false,
        };
      } else {
        return {
          ...state,
          magData: action.payload,
          offset: ofst,
          isMore: action.payload.length >= 20 ? true : false,
          isChatLoading: false,
          isAnimatedScroll: false,
        };
      }

    case actionTypes.GET_ROOM_MESSAGES_FAILURE:
      return { ...state, isChatLoading: false };

    case actionTypes.ADD_MESSAGE:
      return {
        ...state,
        magData: [...state.magData, action.payload],
      };

    case actionTypes.UPDATE_MESSAGES: {
      const newChats = action.payload;

      return {
        ...state,
        magData: newChats,
      };
    }

    case actionTypes.UPDATE_MESSAGE:
      const { magData } = state;
      const msg = magData.find(
        (msg) => msg.uniqueId === action.payload.uniqueId,
      );
      const index = magData.findIndex(
        (msg) => msg.uniqueId === action.payload.uniqueId,
      );
      magData[index] = { ...msg, ...action.payload.data };
      return {
        ...state,
        magData: magData,
      };

    case actionTypes.UPDATE_UNREAD_MESSAGES_STATUS:
      let msgData = state.magData;
      msgData = msgData.map((msg) => {
        return msg.room === action.payload.roomId
          ? { ...msg, isRead: true }
          : msg;
      });

      return {
        ...state,
        magData: msgData,
      };

    case actionTypes.GET_COUNT_UNREAD_MSG_REQUEST:
      return state;

    case actionTypes.GET_COUNT_UNREAD_MSG_SUCCESS: {
      return {
        ...state,
        unreadMessagesCount: action.payload.unreadMessagesCount,
      };
    }

    case actionTypes.UPDATE_UNREAD_MESSAGE_INCREMENT_ALL: {
      return {
        ...state,
        unreadMessagesCount: state.unreadMessagesCount + action.payload.count,
      };
    }

    case actionTypes.GET_COUNT_UNREAD_MSG_FAILURE:
      return state;

    case actionTypes.CLEAR_MESSAGES: {
      return initialState;
    }

    case actionTypes.EDIT_MESSAGE:
      const { magData: messages } = state;
      const editedMsg = messages.find(
        (msg) => msg._id === action.payload.chatId,
      );
      const editedMsgIndex = messages.findIndex(
        (msg) => msg._id === action.payload.chatId,
      );
      messages[editedMsgIndex] = {
        ...editedMsg,
        message: action.payload.message,
        isEdited: true,
        isEncrypted: action.payload.isEncrypted,
      };
      return {
        ...state,
        magData: messages,
      };

    case actionTypes.DELETE_MESSAGE:
      const allMessages = [...state.magData];
      const deletedMsgIndex = allMessages.findIndex(
        (msg) => msg._id === action.payload.chatId,
      );

      if (deletedMsgIndex !== -1) {
        allMessages[deletedMsgIndex].message =
          `This message has been removed by ${allMessages[deletedMsgIndex].username}`;
        allMessages[deletedMsgIndex].isEncrypted = false;
        allMessages[deletedMsgIndex].deletedAt = new Date();
      }

      return {
        ...state,
        magData: allMessages,
      };

    case actionTypes.ADD_CHAT_REACTION:
      const { magData: allChats } = state;
      const allChatsCopy = deepCopy(allChats);
      const chat = allChatsCopy.find(
        (msg) => msg._id === action.payload.chatId,
      );
      if (!chat) {
        return {
          ...state,
        };
      }
      const chatIndex = allChatsCopy.findIndex(
        (msg) => msg._id === action.payload.chatId,
      );
      const allReactions = chat.reactions;
      let reacton = allReactions.find(
        (p_reaction) => p_reaction.unified === action.payload.reaction.unified,
      );
      const reactIndex = allReactions.findIndex(
        (p_reaction) => p_reaction.unified === action.payload.reaction.unified,
      );
      if (!reacton) {
        chat.reactions.push(action.payload.reaction);
      } else {
        if (reacton.owners) {
          reacton.owners = action.payload.reaction.owners;
        } else {
          reacton = { ...reacton, owners: action.payload.reaction.owners };
        }
        chat.reactions[reactIndex] = reacton;
      }

      allChatsCopy[chatIndex] = chat;
      return {
        ...state,
        magData: allChatsCopy,
      };

    case actionTypes.REMOVE_CHAT_REACTION:
      const { magData: allChatsForRemove } = state;
      const allChatsForRemoveCopy = deepCopy(allChatsForRemove);
      const selectedChat = allChatsForRemoveCopy.find(
        (msg) => msg._id === action.payload.chatId,
      );
      const selectedChatIndex = allChatsForRemoveCopy.findIndex(
        (msg) => msg._id === action.payload.chatId,
      );
      let reactions = selectedChat?.reactions ? selectedChat?.reactions : [];
      const reaction = reactions.find(
        (p_reaction) => p_reaction._id === action.payload.reactionId,
      );
      const reactionIndex = reactions.findIndex(
        (p_reaction) => p_reaction._id === action.payload.reactionId,
      );
      if (reaction.owners?.length === 1) {
        reactions = reactions.filter(
          (p_reaction) => p_reaction._id !== action.payload.reactionId,
        );
      } else {
        reaction.owners = reaction.owners.filter(
          (owner) => owner._id !== action.payload.userId,
        );
        reactions[reactionIndex] = reaction;
      }
      allChatsForRemoveCopy[selectedChatIndex].reactions = reactions;
      allChatsForRemoveCopy[selectedChatIndex] = selectedChat;
      return {
        ...state,
        magData: allChatsForRemoveCopy,
      };
    case actionTypes.TOGGLE_SCROLL:
      return {
        ...state,
        canScroll: action.payload,
      };
    case actionTypes.TOGGLE_IS_ANIMATED_SCROLL:
      return {
        ...state,
        isAnimatedScroll: action.payload,
      };
    case actionTypes.RESET_MSG_DATA:
      return {
        ...state,
        magData: [],
        offset: 0,
        isMore: false,
        isMoreFetching: false,
        isChatLoading: true,
        canScroll: true,
        isAnimatedScroll: true,
      };
    case actionTypes.IS_PERSONA_THINKING_OR_NOT:
      return {
        ...state,
        isPersonaThinking: action.payload.isPersonaThinking,
      };
    case actionTypes.UNLOCK_CHAT_ROOM_SUCCESS:
      return {
        ...state,
        unlockedChatRoom: [
          ...state.unlockedChatRoom,
          action.payload.chatRoomId,
        ],
      };
    case actionTypes.GET_LIVE_STREAM_ROOM_MESSAGES_SUCCESS:
      return {
        ...state,
        liveStreamMessages: [...action.payload],
      };

    case actionTypes.ADD_LIVE_STREAM_MESSAGE:
      return {
        ...state,
        liveStreamMessages: [...state.liveStreamMessages, action.payload],
      };
    case actionTypes.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case actionTypes.UPDATE_LIVE_STREAM_MESSAGE_COUNT:
      if (state.activeTab === "live" && !isMobile) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        liveStreamUnreadMessageCount: state.liveStreamUnreadMessageCount + 1,
      };
    case actionTypes.RESET_LIVE_STREAM_MESSAGE_COUNT:
      return {
        ...state,
        liveStreamUnreadMessageCount: 0,
      };

    default:
      return state;
  }
}

export default chatReducer;
