import React, { createContext, useContext, useEffect, useState } from "react";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import {
  retrieveLiveStream,
  enableLiveVideo as enableLiveVideoApi,
  disableMuxCall as disableMuxCallApi,
  EnableLiveStreamOptions,
} from "../../services/liveVideoService";
import { getCallById } from "../../../services/callService";
import { StoreContext } from "../../../Context/StoreContext";

interface MuxAsset {
  id: string;
  stream_key: string;
  playback_ids: {
    policy: string;
    id: string;
  }[];
  liveStremId: string;
  created_at: string;
}

interface WebInput {
  url: string;
  id: string;
  live_stream_id: string;
  created_at: string;
}

interface LiveStream {
  callId: string;
  muxAsset: MuxAsset;
  webInput: WebInput;
  status: "idle" | "live" | "finished";
  isVibehutLive: boolean;
}

type LiveVideoContextType = {
  isInitialized: boolean;
  enableLiveVideo: (options: EnableLiveStreamOptions) => Promise<void>;
  disableLiveVideo: () => void;
  liveStream: LiveStream | null;
  isLive: boolean;
  isLoading: boolean;
  enableError: string;
  liveStreamChatId: string;
  isVibehutLiveOn: boolean;
};

export const LiveVideoContext = createContext<LiveVideoContextType>(null!);

export const LiveVideoProvider: React.FC = ({ children }) => {
  const { state } = useContext(StoreContext);
  const { auth } = state;
  const [isInitialized, setIsInitialized] = useState(false);
  const [liveStreamChatId, setLiveStreamChatId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [liveStream, setLiveStream] =
    useState<LiveVideoContextType["liveStream"]>(null);
  const { callId } = useVibehutData(globals.roomId);
  const [error, setError] = useState("");

  useEffect(() => {
    if (callId) {
      initialize(callId);
    }

    return () => {
      setIsInitialized(false);
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId]);

  const getCallData = () => {
    getCallById(auth?.user?.token, callId)
      .then((res) => {
        const liveChatRoom = res.data.call?.chatRoom;
        if (liveChatRoom) {
          setLiveStreamChatId(liveChatRoom);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (liveStream?.status === "live" && !liveStreamChatId) {
      getCallData();
    }
  }, [liveStream?.status, callId, liveStreamChatId]);

  const fetchLiveStream = async (p_callId: string) => {
    const liveStreamResponse = await retrieveLiveStream(p_callId);
    const _liveStream = liveStreamResponse?.data?.liveStream || null;
    return _liveStream;
  };

  const initialize = async (p_callId: string) => {
    try {
      const _liveStream = await fetchLiveStream(p_callId);
      setLiveStream(_liveStream);
      setIsInitialized(true);
      setIsLoading(false);

      globals.onCallLiveToggle = (p_data) => {
        setLiveStreamChatId(p_data.muxLiveStream.chatRoom);
        setLiveStream(p_data.muxLiveStream);
      };
    } catch (error) {
      console.error(error);
    }
  };

  const enableLiveVideo = async (
    options: EnableLiveStreamOptions = {
      isRtmpEnabled: false,
      isMobileLayout: false,
    },
  ) => {
    setError("");
    if (!callId) {
      return;
    }
    try {
      setIsLoading(true);
      await enableLiveVideoApi(callId, options);
      const _liveStream = await fetchLiveStream(callId);
      setLiveStream(_liveStream);
      setIsLoading(false);
    } catch (p_error) {
      console.error(p_error);
    }
  };

  const disableLiveVideo = async () => {
    if (!callId) {
      return;
    }
    try {
      setIsLoading(true);
      await disableMuxCallApi(callId);
      const _liveStream = await fetchLiveStream(callId);
      setLiveStream(_liveStream);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LiveVideoContext.Provider
      value={{
        isInitialized,
        enableLiveVideo,
        disableLiveVideo,
        liveStream,
        isLive: liveStream?.status === "live",
        isLoading,
        enableError: error,
        liveStreamChatId,
        isVibehutLiveOn: liveStream?.isVibehutLive || false,
      }}
    >
      {children}
    </LiveVideoContext.Provider>
  );
};
