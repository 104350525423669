import React from 'react';

function More() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.85714 8.84615C3.33053 8.84615 3.71429 8.43288 3.71429 7.92308C3.71429 7.41328 3.33053 7 2.85714 7C2.38376 7 2 7.41328 2 7.92308C2 8.43288 2.38376 8.84615 2.85714 8.84615Z"
        fill="white"
        stroke="white"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99997 8.84615C8.47335 8.84615 8.85711 8.43288 8.85711 7.92308C8.85711 7.41328 8.47335 7 7.99997 7C7.52658 7 7.14282 7.41328 7.14282 7.92308C7.14282 8.43288 7.52658 8.84615 7.99997 8.84615Z"
        fill="white"
        stroke="white"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1428 8.84615C13.6162 8.84615 13.9999 8.43288 13.9999 7.92308C13.9999 7.41328 13.6162 7 13.1428 7C12.6694 7 12.2856 7.41328 12.2856 7.92308C12.2856 8.43288 12.6694 8.84615 13.1428 8.84615Z"
        fill="white"
        stroke="white"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default More;
