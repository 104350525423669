import React from 'react'

const DoubleStar = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.53107 1.26725C3.69215 0.831943 4.30785 0.831943 4.46893 1.26725L5.00058 2.70402C5.05122 2.84088 5.15912 2.94878 5.29598 2.99942L6.73275 3.53107C7.16806 3.69215 7.16806 4.30785 6.73275 4.46893L5.29598 5.00058C5.15912 5.05122 5.05122 5.15912 5.00058 5.29598L4.46893 6.73275C4.30785 7.16806 3.69215 7.16806 3.53107 6.73275L2.99942 5.29598C2.94878 5.15912 2.84088 5.05122 2.70402 5.00058L1.26725 4.46893C0.831943 4.30785 0.831943 3.69215 1.26725 3.53107L2.70402 2.99942C2.84088 2.94878 2.94878 2.84088 2.99942 2.70402L3.53107 1.26725Z" fill="currentColor" />
            <path d="M9.61568 6.38165C9.84579 5.75978 10.7254 5.75978 10.9555 6.38165L11.715 8.43417C11.7873 8.62968 11.9415 8.78383 12.137 8.85618L14.1895 9.61568C14.8114 9.84579 14.8114 10.7254 14.1895 10.9555L12.137 11.715C11.9415 11.7873 11.7873 11.9415 11.715 12.137L10.9555 14.1895C10.7254 14.8114 9.84579 14.8114 9.61568 14.1895L8.85618 12.137C8.78383 11.9415 8.62968 11.7873 8.43417 11.715L6.38165 10.9555C5.75978 10.7254 5.75978 9.84579 6.38165 9.61568L8.43417 8.85618C8.62968 8.78383 8.78383 8.62968 8.85618 8.43417L9.61568 6.38165Z" fill="currentColor" />
        </svg>
    )
}

export default DoubleStar