import React, { useState } from "react";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import Avatar from "../../VibehutUI/Avatar";
import Arrow from "../../VibehutUI/Icons/Arrow";
import More from "../../VibehutUI/Icons/More";
import { getUserData } from "../../utils/vibehutUtils";
import useVibehutChatContext from "../../hooks/useVibehutChatContext/useVibehutChatContext";
import MobileMoreOption from "../../VibehutUI/MobileMoreOption";
import ProfileModal from "../../VibehutUI/ProfileModal";
import MemberListDialog from "../MemberListDialog";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import ReactionMenu from "../Buttons/ReactionMenu/ReactionMenu";

export default function MobileMenuBar() {
  const { authUser } = useAuthUser();
  const { unreadMessagesCount } = useVibehutChatContext();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [memberListOpen, setMemberListOpen] = useState(false);
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";

  const { roomData, sendReactionInCall } = useVibehutData(globals.roomId);
  const authUserData = getUserData(authUser);
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);

  return (
    <div className="bg-componentColor  w-full py-4 pr-4 pl-4  rounded-4xl">
      <div className="flex flex-col ">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div>
              <Avatar
                username={authUserData.username}
                src={authUserData.profile_picture}
                size="sm"
              />
            </div>
            <div className="flex flex-col">
              <p className="text-white font-medium text-base">
                {authUserData.username}
              </p>
              <p
                className="text-sm text-white flex items-center space-x-1 cursor-pointer"
                onClick={() => {
                  if (roomData.isGroupRoom) {
                    setMemberListOpen(true);
                  } else {
                    setIsProfileOpen(true);
                  }
                }}
              >
                <span>
                  {roomData.isGroupRoom ? "View Members" : "View Profile"}
                </span>
                <span className="pt-0.5 text-primaryGray">
                  <Arrow />
                </span>
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <ReactionMenu sendReactionInCall={sendReactionInCall} />
            <ToggleAudioButton
              disabled={isReconnecting}
              fromConnectingPage={false}
            />
            <div
              className="w-8 h-8 flex items-center justify-center rounded-full bg-matteGray cursor-pointer relative"
              onClick={() => {
                setIsMoreOpen(true);
              }}
            >
              <More />
              {unreadMessagesCount === 0 ? null : (
                <span className="w-5 h-5 rounded-full -top-1 -right-1 bg-cvRed absolute text-white flex justify-center items-center text-xs">
                  {unreadMessagesCount}
                </span>
              )}
            </div>
            <EndCallButton />
          </div>
        </div>
        <MobileMoreOption
          isMoreOpen={isMoreOpen}
          handleMoreOption={setIsMoreOpen}
        />
        <ProfileModal
          isOpen={isProfileOpen}
          setIsOpen={setIsProfileOpen}
          userState={roomData?.peerUser?.userState}
          user={roomData.peerUser}
        />
        <MemberListDialog
          isOpen={memberListOpen}
          setIsOpen={setMemberListOpen}
        />
      </div>
    </div>
  );
}
