import React, { useMemo } from 'react';

function StopRecording({ isDisable = false }) {
  const color = useMemo(() => {
    if (isDisable) {
      return 'rgb(156 163 175 / 1)';
    }
    return '#DB0A40';
  }, [isDisable]);
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.25" stroke={color} strokeWidth="1.5" />
      <rect x="5" y="5" width="6" height="6" rx="1" fill={color} />
    </svg>
  );
}

export default StopRecording;
