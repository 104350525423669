import React, { useContext } from "react";
import Chat from "../../VibehutUI/Icons/Chat";
import { getSpecificChatRoom } from "../../services/rooomService";
import { StoreContext } from "../../Context/StoreContext";
import globals from "../../globals";
import SpecificChatModal from "./SpecificChatModal";
import GeneralRoom from "../../VibehutUI/Icons/GeneralRoom";
import Button from "../../VibehutUI/Button";
import { Menu } from "@headlessui/react";
import { useEffect } from "react";
import { showLoginModal } from "../../actions/uiModalActions";
import { specificChatType } from "../../constants";
import FeedChat from "../../VibehutUI/Icons/FeedChat";
import { showToast } from "../../actions/toastActions";
import PersonaChatHeader from "../../components/peronas/PersonaChatHeader";
import moment from "moment";
import { getThumbnailFromYoutubeVideo } from "../../utils/utils";

function SpecificChat({
  chatItem,
  type,
  roomId = null,
  setIsOpenProfileModal = null,
  buttonVariant = "secondary",
}) {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    room: {
      liveRoom: { liveRoom: room },
    },
  } = state;

  const titleAsPerEventType = (isOpenDiscussionChat = false) => {
    if (
      isOpenDiscussionChat ||
      (chatItem?.title === "Open Discussion" && chatItem?.isAutoCreated)
    ) {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedDate = chatItem?.startDate.toLocaleString(undefined, {
        timeZone: browserTimeZone,
      });
      const roomName = room?.name ? room?.name : chatItem?.room.name;
      if (roomName) {
        return `Open Discussion for ${roomName} at ${moment(
          formattedDate,
        ).format("dddd, MMM D, h:mm A")}`;
      }
      return `Open Discussion at ${moment(formattedDate).format(
        "dddd, MMM D, h:mm A",
      )}`;
    } else {
      return chatItem?.title;
    }
  };

  const getThumbnail = () => {
    switch (type) {
      case "watch":
        return chatItem?.thumbnail;
      case "prompt":
        return chatItem?.coverImageUrl;
      case "event":
        return chatItem?.cover?.urls?.regular;
      case "eventPage":
        return chatItem?.cover?.urls?.regular;
      case "notification":
        return chatItem?.cover?.urls?.regular;
      case "feedPage":
        return chatItem?.imageUrls?.[0] ? chatItem?.imageUrls[0] : undefined;
      case "persona":
        return chatItem?.avatar;
      case "partner":
        return chatItem?.icon;
      case "videoTranscript":
        return getThumbnailFromYoutubeVideo(chatItem.videoLink);
      default:
        return undefined;
    }
  };

  const getTitle = () => {
    switch (type) {
      case specificChatType.WATCH:
        return chatItem?.title;
      case specificChatType.PROMPT:
        return chatItem?.name;
      case specificChatType.RESOURCE:
        return chatItem?.name;
      case specificChatType.EVENT:
        return titleAsPerEventType();
      case specificChatType.EVENTPAGE:
        return chatItem?.title;
      case specificChatType.NOTIFICATION:
        return chatItem?.name;
      case specificChatType.REQUESTEVENT:
        return titleAsPerEventType(true);
      case specificChatType.FEEDPAGE:
        return chatItem?.body || "";
      case specificChatType.PERSONA:
        return chatItem?.name;
      case specificChatType.PARTNER:
        return chatItem?.title;
      case specificChatType.VIDEOTRANSCRIPT:
        return chatItem?.metaData.title;
      default:
        break;
    }
  };
  useEffect(() => {
    if (
      type === specificChatType.NOTIFICATION ||
      type === specificChatType.REQUESTEVENT
    ) {
      getConversation();
    }
  }, [chatItem._id]);

  const renderChatHeader = () => {
    switch (type) {
      case "persona":
        return <PersonaChatHeader persona={chatItem} />;
      default:
        return (
          <div className="w-full">
            <div className={`flex items-center justify-between w-full`}>
              <div className="w-full">
                <div className="flex items-center gap-3 cursor-pointer w-full">
                  <div className={`w-12 h-10 relative`}>
                    {getThumbnail() ? (
                      <img
                        src={getThumbnail()}
                        alt="room"
                        className="w-full h-full rounded-lg"
                        loading="lazy"
                      />
                    ) : (
                      <GeneralRoom width={48} height={40} />
                    )}
                  </div>

                  <p
                    className={`w-full text-matteGray font-medium dark:text-frescoWhite custom-two-line-clamp`}
                  >
                    {getTitle()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };
  const showConfirmationModal = ({ room }) => {
    globals.showModal(
      renderChatHeader(),
      <>
        <SpecificChatModal room={room} chatType={type} />
      </>,
      true,
      true,
    );
  };
  const getConversation = async (e) => {
    try {
      e?.stopPropagation();
      if (user?.token) {
        let data;
        switch (type) {
          case specificChatType.WATCH:
            data = {
              name: chatItem.title,
              specificChatId: chatItem._id,
              owner: chatItem.owner[0]._id,
              is_private: true,
              isGroupRoom: true,
              ...(chatItem?.thumbnail
                ? {
                    cover: {
                      source: "custom",
                      urls: {
                        raw: chatItem.thumbnail,
                        full: chatItem.thumbnail,
                        regular: chatItem.thumbnail,
                        small: chatItem.thumbnail,
                        thumb: chatItem.thumbnail,
                        small_s3: chatItem.thumbnail,
                      },
                    },
                  }
                : {
                    cover: {
                      source: "custom",
                      urls: {
                        raw: chatItem?.room[0]?.cover?.urls?.regular,
                        full: chatItem?.room[0]?.cover?.urls?.regular,
                        regular: chatItem?.room[0]?.cover?.urls?.regular,
                        small: chatItem?.room[0]?.cover?.urls?.regular,
                        thumb: chatItem?.room[0]?.cover?.urls?.regular,
                        small_s3: chatItem?.room[0]?.cover?.urls?.regular,
                      },
                    },
                  }),
              ...(roomId && {
                roomId: roomId,
              }),
              specificChatType: specificChatType.WATCH,
              isOwnerIncludeToChat: true,
            };
            break;
          case specificChatType.PROMPT:
            data = {
              name: chatItem.name,
              specificChatId: chatItem._id,
              is_private: true,
              isGroupRoom: true,
              cover: {
                source: "custom",
                urls: {
                  raw: chatItem.coverImageUrl,
                  full: chatItem.coverImageUrl,
                  regular: chatItem.coverImageUrl,
                  small: chatItem.coverImageUrl,
                  thumb: chatItem.coverImageUrl,
                  small_s3: chatItem.coverImageUrl,
                },
              },
              owner: chatItem.owner,
              specificChatType: specificChatType.PROMPT,
              isOwnerIncludeToChat: true,
            };
            break;
          case specificChatType.RESOURCE:
            data = {
              name: chatItem.name,
              specificChatId: chatItem._id,
              is_private: true,
              isGroupRoom: true,
              owner: chatItem.owner,
              roomId: roomId,
              specificChatType: specificChatType.RESOURCE,
              isOwnerIncludeToChat: true,
            };
            break;
          case specificChatType.EVENT:
            data = {
              name: chatItem.title,
              specificChatId: chatItem._id,
              is_private: true,
              isGroupRoom: true,
              cover: chatItem.cover,
              roomId: roomId,
              owner: chatItem.owner,
              specificChatType: specificChatType.EVENT,
              isOwnerIncludeToChat: true,
            };
            break;
          case specificChatType.EVENTPAGE:
            data = {
              name: chatItem.title,
              specificChatId: chatItem._id,
              is_private: true,
              isGroupRoom: true,
              cover: chatItem.cover,
              roomId: roomId,
              owner: chatItem.owner,
              specificChatType: specificChatType.EVENTPAGE,
              isOwnerIncludeToChat: true,
            };
            break;
          case specificChatType.NOTIFICATION:
            data = {
              name: chatItem.name,
              specificChatId: chatItem.specificChatId,
              is_private: true,
              isGroupRoom: true,
              cover: {
                source: "custom",
                urls: {
                  raw: chatItem?.cover?.urls?.regular,
                  full: chatItem?.cover?.urls?.regular,
                  regular: chatItem?.cover?.urls?.regular,
                  small: chatItem?.cover?.urls?.regular,
                  thumb: chatItem?.cover?.urls?.regular,
                  small_s3: chatItem?.cover?.urls?.regular,
                },
              },
              specificChatType: specificChatType.NOTIFICATION,
            };
            break;
          case specificChatType.REQUESTEVENT:
            data = {
              name: "Open Discussion",
              specificChatId: chatItem._id,
              is_private: true,
              isGroupRoom: true,
              roomId: roomId,
              owner: chatItem.owner,
              specificChatType: specificChatType.REQUESTEVENT,
              isOwnerIncludeToChat: true,
            };
            break;
          case "feedPage":
            data = {
              name: chatItem.body?.slice(0, 80) || "TestFeed",
              specificChatId: chatItem.id,
              is_private: true,
              specificChatType: "feedPage",
              isGroupRoom: true,
            };
            if (chatItem.imageUrls && chatItem.imageUrls.length > 0) {
              data.cover = {
                source: "custom",
                urls: {
                  raw: chatItem.imageUrls[0],
                  full: chatItem.imageUrls[0],
                  regular: chatItem.imageUrls[0],
                  small: chatItem.imageUrls[0],
                  thumb: chatItem.imageUrls[0],
                  small_s3: chatItem.imageUrls[0],
                },
              };
            }
            break;
          case specificChatType.PARTNER:
            data = {
              name: chatItem.title?.slice(0, 80) || "Partner Chat",
              specificChatId: chatItem.recordId,
              is_private: true,
              specificChatType: "partner",
              isGroupRoom: true,
            };
            if (chatItem.icon) {
              data.cover = {
                source: "custom",
                urls: {
                  raw: chatItem.icon,
                  full: chatItem.icon,
                  regular: chatItem.icon,
                  small: chatItem.icon,
                  thumb: chatItem.icon,
                  small_s3: chatItem.icon,
                },
              };
            }
            break;
          case specificChatType.PERSONA:
            data = {
              name: chatItem.name,
              specificChatId: chatItem._id,
              is_private: true,
              isGroupRoom: true,
              cover: {
                source: "custom",
                urls: {
                  raw: chatItem.avatar,
                  full: chatItem.avatar,
                  regular: chatItem.avatar,
                  small: chatItem.avatar,
                  thumb: chatItem.avatar,
                  small_s3: chatItem.avatar,
                },
              },
              owner: chatItem.owner._id,
              isOwnerIncludeToChat: chatItem.includeMe,
              specificChatType: specificChatType.PERSONA,
            };
            break;
          case specificChatType.VIDEOTRANSCRIPT:
            {
              const thumbnail = getThumbnailFromYoutubeVideo(
                chatItem.videoLink,
              );
              data = {
                name: chatItem.metaData.title,
                specificChatId: chatItem._id,
                is_private: true,
                isGroupRoom: true,
                cover: {
                  source: "custom",
                  urls: {
                    raw: thumbnail,
                    full: thumbnail,
                    regular: thumbnail,
                    small: thumbnail,
                    thumb: thumbnail,
                    small_s3: thumbnail,
                  },
                },
                owner: chatItem.user,
                isOwnerIncludeToChat: true,
                specificChatType: specificChatType.VIDEOTRANSCRIPT,
              };
            }
            break;

          default:
            break;
        }
        const response = await getSpecificChatRoom(data, user?.token);

        if (response.data) {
          const room = response.data.room;

          // const res = await countChatByRoom(user?.token, room._id);
          // const chatCount = res.data;
          showConfirmationModal({ room });
        }
      } else {
        showLoginModal(dispatch);
      }
    } catch (error) {
      showToast(dispatch, "error", "Unable to get conversation.", 2000);
    }
  };
  const getUI = () => {
    switch (type) {
      case "watch":
        return (
          <div
            onClick={getConversation}
            className={`w-9 h-9 bg-frescoWhite rounded-full flex items-center justify-center cursor-pointer`}
          >
            <Chat />
          </div>
        );
      case "prompt":
        return (
          <Button onClick={getConversation}>
            Chat {chatItem.chatCount > 0 && `(${chatItem.chatCount})`}
          </Button>
        );
      case "eventPage":
        return (
          <Button onClick={getConversation}>
            Join Chat{" "}
            {chatItem?.specificChatRoom?.[0]?.chatCount > 0 &&
              `(${chatItem.specificChatRoom[0].chatCount})`}
          </Button>
        );
      case "resource":
        return (
          <p
            className="text-xs font-normal text-darkGray pt-1 underline cursor-pointer"
            onClick={getConversation}
          >
            Chat {chatItem.chatCount > 0 && `(${chatItem.chatCount})`}
          </p>
        );
      case "event":
        return (
          <Menu.Item>
            <button
              onClick={getConversation}
              className={`group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
            >
              Chat <Chat />
            </button>
          </Menu.Item>
        );
      case "feedPage":
        return (
          <div className="flex items-center gap-2">
            <div
              onClick={getConversation}
              className={`w-9 h-9 dark:text-frescoWhite rounded-full flex items-center justify-center cursor-pointer`}
            >
              <FeedChat ratio={22} strokeWidth="0.5" />
              <span className="pl-1 text-darkGray">
                {chatItem?.chatCount > 0 && `${chatItem.chatCount}`}
              </span>
            </div>
          </div>
        );
      case "partner":
        return (
          <Button onClick={getConversation} variant="vibhut-primary-outline">
            Chat{" "}
          </Button>
        );
      case "persona":
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsOpenProfileModal?.(false);
              getConversation();
            }}
            variant={buttonVariant}
          >
            Chat{" "}
          </Button>
        );
      case "videoTranscript":
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              getConversation();
            }}
          >
            Chat with transcript
          </Button>
        );
      default:
        break;
    }
  };
  return <>{getUI()}</>;
}

export default SpecificChat;
