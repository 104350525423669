import axios from "../utils/axios";

export function getPublicRooms(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/rooms", {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
export function searchRooms(token, data, offset = 0, isSelfOrganized = false) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/rooms/search-rooms?limit=10&offset=${offset}&isSelfOrganized=${isSelfOrganized}`,
        { keyword: data },
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
export function getVibehutNewsRoom(token, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-vibehut-news-room?roomId=${roomId}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.nessage);
        }
      })
      .catch((err) => reject(err));
  });
}

export function searchMyRoomAndSubscribed(token, data, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/rooms/search-subscribed-my-rooms?limit=8&offset=${offset}`,
        { keyword: data },
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function blockUserFromRoom(token, data) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/rooms/update-general-block-users`, data, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function checkRoomHasLiveEventAndUserAccess(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/check-live-events-access/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllRooms(token, data, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/rooms/get-all-rooms?limit=30&offset=${offset}`,
        { roomTypes: data },
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
export function getMyRoomCount(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-my-room-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyRoomSlugCount(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-my-room-slug-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyRooms(token, offset, limit = 18) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-my-rooms?limit=${limit}&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSubscribedRooms(token, offset, limit = 18) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/subscribed?limit=${limit}&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getNFTCollectionRooms(token, fetchType = "accessible") {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/nft-collection-rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          fetchType: fetchType,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSingleRoom(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/${roomId}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getGenaralRoom(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-general-discussion`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRandomPicture(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/rooms/get-random-picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createRoom(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateRoom(data, roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/rooms/${roomId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export function deleteRoom(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`/rooms/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getPopularRooms(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-popular-rooms`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getTopRooms(token, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-top-rooms?offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getTrendingRooms() {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-trending-rooms?limit=8&offset=0`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createRoomSearch(token, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/room-search-count/${roomId}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getNewlyAddedRooms(token, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/newly-added-rooms?limit=18&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyRecentRooms(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-recent-call-rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMySubscribedRecentRooms(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-subscribed-recent-call-rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteUser(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/invite-user", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteDesoUser(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/invite-deso-user", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function generateInstantCallUrl(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/generate-meeting-url`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserRoomsByUserId(token, userId, data, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/rooms/get-user-rooms?userId=${userId}&limit=18&offset=${offset}`,
        { roomTypes: data },
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getChatRoom(token, userId, source) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-chat-room/${userId}`, {
        cancelToken: source?.token,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getChatRoomById(token, roomId, checkSubscription, source) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/rooms/get-chat-room-by-id/${roomId}?checkSubscription=${checkSubscription}`,
        {
          cancelToken: source?.token,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomSubscribers(token, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/subscriber-list/${roomId}?limited=${false}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomCallHistoryData(token, roomId, date) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/callHistory/get-call-history-by-roomId?roomId=${roomId}&timestamp=${date.getTime()}`,
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getModeratorsByRoomId(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-rooms-moderators/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getConversations(token, offset, type = "all") {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/rooms/chat/get-conversations?type=${type}&limit=10&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function SearchConversations(token, offset, type = "all", name) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/rooms/chat/search-conversations?type=${type}&limit=10&offset=${offset}&name=${name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getActiveConversations(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/chat/get-active-conversations`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getActiveRooms(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/call/get-active-rooms`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateRoomResourceOpenCount(resourceId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        `/rooms/update-room-resource-count/${resourceId}`,
        {},
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getResourceEventList(resourceId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-room-resource-open-list/${resourceId}`, {}, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getResourceDataById(token, resourceId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-resource-data/${resourceId}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function deleteResource(resourceId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`/rooms/delete-room-resource/${resourceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomIdByRoomSlug(slug) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/room-slug/${slug}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateRoomCacheData(slug) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/rooms/update-room-cache`,
        { slug: slug },
        {
          headers: {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createRoomResources(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/create-room-resources", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateRoomResource(data, token, resourceId) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(`/rooms/update-room-resource/${resourceId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export function voteResource(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/vote-room-resource", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function diamondResource(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/diamond-room-resource", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getResourceVoteList(resourceId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-room-resource-vote-list/${resourceId}`, {}, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomResourcesTags(roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-room-resources-tags/${roomId}`, {}, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getApps(roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-apps/${roomId}`, {}, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAppBySlug(slug) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-app/${slug}`, {}, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getReviewdPromptId(messages, token, appId, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/rooms/get-reviewed-prompt-id`,
        { messages: messages, app: appId, roomId: roomId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getPromptById(id, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/prompt/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getSpecificChatRoom(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/specific-chat", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createRoomByAI(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/rooms/create-room-by-ami", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomSubscriberApprovalStatus(roomId, token) {
  return axios
    .get(`/rooms/subscriber-status/${roomId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function updateRoomChattingUsers(data, roomId, token) {
  return axios
    .post(`/rooms/update-room-chat-users/${roomId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function checkUserDiamondedToPost(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/check-user-diamonded-to-post/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function checkDesoHolderToPublicKey(roomId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/check-deso-hodler-to-public-key/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomSubscriberCount(token, roomId) {
  return axios
    .get(`/rooms/subscriber-count/${roomId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function inviteForHostApprovalRoom(token, data, roomId) {
  return axios
    .post(`/rooms/invite-for-host-approval-room/${roomId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function acceptInviteForHostApprovalRoom(token, roomId) {
  return axios
    .post(
      `/rooms/accept-invite-for-host-approval-room/${roomId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function submitSubscriberApproval(token, data) {
  return axios
    .post(`/rooms/submit-approval`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getSubscriberApprovalSubmission(token, roomId, userId) {
  return axios
    .get(`/rooms/approval-submission/${roomId}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
}

export function getHomePopularRooms(token, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/rooms/get-home-popular-rooms?limit=10&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
