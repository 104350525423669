const globals = {
  onApplyRoomFilter: (roomTypes) => {},
  showModal: (title, content, canClose, isChatModal) => {},
  hideModal: () => {},
  /**
   *
   * @param {'low' | 'standard' | 'high'} priority
   */
  changeLocalTrackPriority: (priority) => {},
  joinHomeNamespaceEvent: (eventName) => {},
  setNewVideoAvailableState: (isAvailable) => {},

  closePaymentStatusModal: () => {},
  onStripePaymentSuccess: () => {},
  defaultOnStripePaymentSuccess: () => {},
  handleSubscription: () => {},
};

export default globals;
