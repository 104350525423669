import React, { useContext, useMemo, useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import Copy2 from "../Icons/Copy2";
import PopOver from "../Popover";
import Button from "../Button";
import { calcDayDifference, renderPostText } from "../../utils/utils";
import Loader from "../Loader";
import {
  updateUserReferralCode,
  createDefaultUserReferralCode,
} from "../../actions/referralAction";
import { createReferralTransaction } from "../../actions/referralTransactionAction";
import {
  countUserReferralTransaction,
  getReferralUser,
} from "../../services/referralService";
import { frontEndURL } from "../../utils/axios";
import moment from "moment";
import ProfileModal from "../ProfileModal";

function ReferralSystem() {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth: { user, isReferralCodeFetching, referral },
    setting: { systemSetting },
    referral: {
      isFetching: isReferralFetching,
      isCompleted: isReferralCompleted,
    },
  } = state;
  const [referralCount, setReferralCount] = useState(0);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [referralCode, setReferralCode] = useState(
    referral?.referralCode || "",
  );
  const [myReffCode, setMyReffCode] = useState(referral?.referralCode);
  const [referralCodeErrorPrompt, setReferralCodeErrorPrompt] = useState("");
  const [referralTransactionCode, setReferralTransactionCode] = useState("");
  const [post, setPost] = useState(null);
  const [profileUser, setProfileUser] = useState({});
  const [
    referralTransactionCodeErrorPrompt,
    setReferralTransactionCodeErrorPrompt,
  ] = useState("");
  const [copied, setCopied] = useState(false);

  const copyLoginLink = async () => {
    navigator.clipboard.writeText(
      `${frontEndURL}/login?invitation_code=${myReffCode}`,
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleReferralCodeChange = (e) => {
    if (e.target.value.length > 8) return;
    setReferralCode(e.target.value);
    setReferralCodeErrorPrompt("");
  };

  const handleReferralTransactionCodeChange = (e) => {
    if (e.target.value.length > 8) return;
    setReferralTransactionCode(e.target.value);
    setReferralTransactionCodeErrorPrompt("");
  };

  React.useEffect(() => {
    countUserReferralTransaction(user?.token).then((res) => {
      setReferralCount(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const fetchReferralUser = async (token) => {
      try {
        const response = await getReferralUser(token);
        const data = response.data;
        const _post = {
          body: `Invited by $(_user:${data.referredBy._id}) on ${moment(
            data.createdAt,
          ).format("MMM D, YYYY")}`,
          usersMentioned: [data.referredBy],
        };
        setPost(_post);
      } catch (error) {
        console.log(error);
      }
    };
    if (user?.token) {
      fetchReferralUser(user?.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token]);

  React.useEffect(() => {
    if (!referral?.referralCode && systemSetting?.isReferralSystemActive) {
      createDefaultUserReferralCode(user?.token, dispatch).then((res) => {
        setReferralCode(res?.referralCode);
      });
    }
  }, [
    dispatch,
    referral?.referralCode,
    systemSetting?.isReferralSystemActive,
    user?.token,
  ]);

  const changeUserReferralCode = async () => {
    if (!referralCode && referral?.referralCode) return;
    if (
      referralCode &&
      referralCode.length &&
      referral?.referralCode !== referralCode &&
      referral?.updatedAt
    ) {
      if (!referral.isDefaultReferral) {
        const dayDiff = calcDayDifference(referral?.updatedAt, new Date());
        if (dayDiff < 30) {
          const dayRemain = 30 - dayDiff;
          setReferralCodeErrorPrompt(
            `You can change the referral Code after ${dayRemain} ${
              dayRemain > 1 ? "days" : "day"
            }.`,
          );
          return;
        }
      }
    }
    await updateUserReferralCode(referralCode, user?.token, dispatch)
      .then(() => {
        setMyReffCode(referralCode);
      })
      .catch((err) => {
        console.log(err);
        setReferralCodeErrorPrompt(err);
      });
  };

  const createReferral = async () => {
    if (!referralTransactionCode) return;
    await createReferralTransaction(
      dispatch,
      referralTransactionCode,
      user?.token,
    ).catch((err) => {
      setReferralTransactionCodeErrorPrompt(err);
    });
  };

  const showReferralInputToUser = useMemo(() => {
    const dayDiff = calcDayDifference(user?.createdAt, new Date());
    return user && user?.createdAt && dayDiff < 1 && !isReferralCompleted;
  }, [user, isReferralCompleted]);

  const copyReferralCode = async (address) => {
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 2000);
  };

  const openUserModal = (user) => {
    setProfileUser(user);
  };

  const closeUserModal = () => {
    setProfileUser({});
  };

  return (
    <div className="font-ubuntu">
      <div>
        <div>
          <div className="flex flex-col justify-between items-start space-y-2">
            <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite hidden md:block">
              Vibehut Referral Code
            </p>
            <p className="text-darkGray text-sm">
              This referral code will be used to referral your friends
            </p>
          </div>

          <div className="w-full pt-2 flex flex-col">
            <div className=" flex flex-col space-y-1.5 max-w-md ">
              <div className="flex items-center space-x-2 mr-2">
                <input
                  type="text"
                  className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                  value={referralCode}
                  name="referralCode"
                  max={8}
                  onChange={handleReferralCodeChange}
                  placeholder="Referral Code"
                />
                {isReferralCodeFetching ? (
                  <Loader />
                ) : (
                  <Button
                    disabled={referralCode === referral?.referralCode}
                    disabledClasses="hover:opacity-50 cursor-not-allowed"
                    onClick={changeUserReferralCode}
                    size="vibhut-sm"
                    variant="vibhut-primary"
                  >
                    Change
                  </Button>
                )}
              </div>
              <p className={` text-xs text-gray-400`}>
                It can be only 8 characters long
              </p>
              {referralCodeErrorPrompt && (
                <p className={`text-xs text-cvRed`}>
                  {referralCodeErrorPrompt}
                </p>
              )}
              <div className="mt-2 relative">
                {!!myReffCode && (
                  <>
                    <input
                      type="text"
                      className="border-0 focus:outline-none focus:outline-0 focus:ring-0  bg-frescoWhite  py-[15px] pl-4 pr-12 md:px-4  rounded-lg my-1 w-full text-darkGray"
                      value={`${frontEndURL}/login?invitation_code=${myReffCode}`}
                      readOnly="true"
                    />
                    {copied ? (
                      <span className="absolute right-10 top-2">
                        <PopOver text="Copied" size="20" />
                      </span>
                    ) : undefined}
                    <span
                      className="absolute right-3 top-5 cursor-pointer bg-frescoWhite"
                      onClick={copyLoginLink}
                    >
                      <Copy2 />
                    </span>
                  </>
                )}
              </div>
            </div>
            {referral && (
              <div className="pt-2 flex space-x-2 ">
                <p className="text-black dark:text-frescoWhite">
                  Your referral code is{" "}
                </p>
                <p className="bg-frescoWhite px-2 rounded-md">
                  {referral.referralCode}
                </p>
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyReferralCode(referral?.referralCode)}
                >
                  {isAddressCopied ? <PopOver text={"Copied"} /> : undefined}
                  <Copy2 />
                </span>
              </div>
            )}

            <div className="pt-2">
              <div className="flex justify-between p-3 border border-lightGray dark:border-matteGray  rounded-2xl">
                <div className="flex flex-col">
                  <div className="text-sm text-darkGray dark:text-frescoWhite">
                    Your Referral Count
                  </div>
                  <div className="text-lg font-medium text-primary">
                    {referralCount}
                  </div>
                </div>
              </div>
            </div>

            {showReferralInputToUser && (
              <>
                <hr className="border-0 border-b border-frescoWhite dark:border-matteGray my-4" />
                <p className="text-md   flex-grow  text-black dark:text-frescoWhite  mb-2">
                  Did someone refer you?
                </p>
                <div className=" flex flex-col space-y-1 max-w-md ">
                  <div className="flex items-center space-x-2 mr-2">
                    <input
                      type="text"
                      className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                      value={referralTransactionCode}
                      name="referralCode"
                      max={8}
                      onChange={handleReferralTransactionCodeChange}
                      placeholder="Referral Code"
                    />
                    {isReferralFetching ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={createReferral}
                        disabled={!referralTransactionCode}
                        disabledClasses="cursor-not-allowed bg-gray-400"
                      >
                        Done
                      </Button>
                    )}
                  </div>
                  {referralTransactionCodeErrorPrompt && (
                    <p className={`text-xs text-cvRed`}>
                      {referralTransactionCodeErrorPrompt}
                    </p>
                  )}
                </div>
              </>
            )}
            {!!post && (
              <div className="pt-4">
                <p className="text-sm font-normal dark:text-frescoWhite">
                  <div className="text-gray-700 dark:text-frescoWhite">
                    {renderPostText(post, openUserModal)}
                  </div>
                </p>
                <ProfileModal
                  isOpen={profileUser._id ? true : false}
                  setIsOpen={(open) => !open && closeUserModal()}
                  user={profileUser}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralSystem;
