import { LocalAudioTrack } from 'twilio-video';
import { useCallback } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';
import { globals } from '../../globals';

export default function useLocalAudioToggle() {
  const { localTracks, onError } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const displayErrorMessage = () => {
    onError({
      message:
        "Please grant <span class='text-black font-bold'>mic</span> permissions for your browser. Need more help? <a href='https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop' target='_blank' class='text-black font-bold' >Click here to see the setup guide.</a> ",
      code: 0,
      name: 'Audio permission denied!',
    });
  };

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    } else {
      displayErrorMessage();
    }
  }, [audioTrack]);

  const handleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.enable();
    } else {
      displayErrorMessage();
    }
  }, [audioTrack]);

  const handleAudioDisabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.disable();
    } else {
      displayErrorMessage();
    }
  }, [audioTrack]);

  globals.onMuteUser = (data: any) => {
    if (data.isAudioEnabled) {
      audioTrack.disable();
    } else {
      // has stopped from unmutting
      // audioTrack.enable();
    }
  };

  return [isEnabled, toggleAudioEnabled, handleAudioEnabled, handleAudioDisabled] as const;
}
