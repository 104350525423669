import React from "react";

const SubscriptionIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#FFCC00" />
      <path
        d="M16.6667 9.33301L10 17.333H16L15.3333 22.6663L22 14.6663H16L16.6667 9.33301Z"
        fill="#1F2023"
        stroke="#1F2023"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SubscriptionIcon;
