import * as actionTypes from "../constants";

const initialState = {
  rawRecordings: [],
  isFetchingRecordings: false,
  error: null,
  myCallCount: undefined,
};

function callReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_RAW_RECORDINGS_REQUEST:
      return { ...state, isFetchingRecordings: true };
    case actionTypes.GET_RAW_RECORDINGS_SUCCESS: {
      return {
        ...state,
        rawRecordings: [...action.payload],
        isFetchingRecordings: false,
      };
    }
    case actionTypes.GET_RAW_RECORDINGS_FAILURE:
      return {
        ...state,
        isFetchingRecordings: false,
        error: "Unable to fetch Raw Recordings",
      };
    case actionTypes.GET_MY_CALL_COUNT_REQUEST:
    case actionTypes.GET_MY_CALL_COUNT_FAILURE:
      return {
        ...state,
      };
    case actionTypes.GET_MY_CALL_COUNT_SUCCESS:
      return {
        ...state,
        myCallCount: action.payload.myCallCount,
      };
    default:
      return state;
  }
}

export default callReducer;
