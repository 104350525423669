import React from 'react';
import Avatar from '../../VibehutUI/Avatar';

interface DesoUserCardProps {
  user: any;
  size?: string;
  subtitle?: string;
  isSidebarCard?: boolean;
}

function DesoUserCard({ user, size = 'xs', subtitle = '', isSidebarCard = false }: DesoUserCardProps) {
  return (
    <React.Fragment>
      <div className="px-2">
        <div
          className={`flex items-center space-x-3 cursor-pointer  px-2 py-1.5 rounded-xl`}
          onClick={() => {
            window.open(`https://diamondapp.com/u/${user.Username}`, '_blank');
          }}
        >
          <div className="relative">
            <Avatar src="" username={user.Username} size={size} isDesoCard={true} />
          </div>

          <div>
            <p className=" flex flex-col">
              <span className={` font-medium text-matteGray dark:text-frescoWhite text-base`}>
                {user && user.Username
                  ? isSidebarCard && user.Username.length > 8
                    ? user.Username.slice(0, 8) + '...'
                    : user.Username
                  : ''}
              </span>
            </p>
            {subtitle ? <p className="text-xs   font-normal tracking-wide">{subtitle}</p> : undefined}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DesoUserCard;
