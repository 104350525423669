import LeftSidebar from "../../components/LeftSidebar";
import RightSidebar from "../../components/RightSidebar";
import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import { StoreContext } from "../../Context/StoreContext";
import Avatar from "../../VibehutUI/Avatar";
import Button from "../../VibehutUI/Button";
import {
  useLocation,
  useParams,
  useHistory,
  useRouteMatch,
  Link,
} from "react-router-dom";
import RoomDropdownMobile from "../../components/rooms/RoomDropdownMobile";
import CreateRoom from "../../components/rooms/CreateRoom";
import Bell from "../../VibehutUI/Icons/Bell";
import Chat from "../../VibehutUI/Icons/Chat";
import NotificationModel from "../../VibehutUI/Notification/NotificationModel";
import {
  getNotifications,
  countUnSeenNotifications,
} from "../../actions/notificationActions";
import { countUnreadMessages } from "../../actions/chatActions";
// import { getAllEvents } from "../../actions/eventActions";
import { getUserData } from "../../services/userService";
import { checkNewVideoAvailable } from "../../services/publishStore";
import { getUserWaves } from "../../actions/waveActions";
import { Menu, Transition } from "@headlessui/react";
import { logout } from "../../actions/authActions";
import UserIcon from "../../VibehutUI/Icons/UserIcon";
import Logout from "../../VibehutUI/Icons/Logout";
import MainSearch from "../../components/MainSearch";
import MyProfileModal from "../../VibehutUI/MyProfileModel";
import EventSubscriptionModal from "../../VibehutUI/EventSubscriptionModal";
import Toast from "../../VibehutUI/Toast";
import Div100vh from "react-div-100vh";
import {
  checkCreateRoomPermission,
  checkIsDesoUser,
  isMobile,
} from "../../utils/utils";
import InCompleteProfileModal from "../../VibehutUI/InCompleteProfileModal";
import RoomModel from "../../VibehutUI/RoomModal";
import Settings from "../../VibehutUI/Icons/Settings";
import RoomNavigation from "../../VibehutUI/RoomNavigation";
import { countUserMissedCalls } from "../../services/userService";
import globals from "../../globals";
import Call from "../../VibehutUI/Icons/Call";
import { updateMessageDeliveredStatus } from "../../services/chatService";
import { updateInstantNotifications } from "../../services/notificationService";
import { profileRoute } from "../../routes";
import { getRoomIdByRoomSlug } from "../../actions/roomAction";
import {
  openNotificationModal,
  openPlanModal,
} from "../../actions/profileModalActions";
import ConfirmationModal from "../../VibehutUI/ConfirmationModal";
import LoginModal from "../../VibehutUI/LoginModal";
import EventNavigation from "../../VibehutUI/EventNavigation";
import { useDesoProtocol } from "../../hooks/useDesoProtocol";
import UserNotificationActionModal from "../../VibehutUI/UserNotificationAction";
import { showLoginModal } from "../../actions/uiModalActions";
import AddressBookModal from "../../VibehutUI/AddressBookModal";
import CallStreak from "../../components/home/CallStreak";
import { useDisconnect } from "wagmi";

const withoutPaddingRoutes = ["/feed", "/news"];

const MainLayout = (props) => {
  const [isOpenLeft, setIsOpenLeft] = useState(false);
  const [isOpenRight, setIsOpenRight] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isOpenRoomPass, setOpenRoomPass] = useState(false);
  const [missedCallCount, setMissedCallCount] = useState(0);
  const [isNewVideoAvailable, setNewVideoAvailableState] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let { slug, roomId, eventId } = useParams();
  const desoProtocol = useDesoProtocol();
  const { disconnectAsync } = useDisconnect();
  const isLiveStreamPage = useMemo(
    () => match.path === "/rooms/:roomId/calls/:callId/live",
    [match.path],
  );

  const {
    state: {
      auth: { user, showInCompleteProfileModal },
      notification: { data, totalNotifications },
      room: { roomId: roomCustomId, chatRoom, myRoomsCount },
      chat: { unreadMessagesCount },
      role,
    },
    dispatch,
  } = useContext(StoreContext);

  if (!roomId) {
    roomId = roomCustomId;
  }
  const getRoomId = useCallback(async () => {
    try {
      await getRoomIdByRoomSlug(slug ? slug : roomId, dispatch);
    } catch (error) {
      console.log("error", error);
    }
  }, [slug]);

  useEffect(() => {
    if ((slug && slug !== roomId) || roomId) {
      getRoomId();
    }
  }, [getRoomId]);

  const handleClose = () => {
    setIsOpenLeft(false);
    setIsOpenRight(false);
  };

  useEffect(() => {
    if (user?.token) {
      countUnreadMessages(user?.token, dispatch);
    }
    const intervalId = setInterval(() => {
      if (user?.token) {
        countUnSeenNotifications(user?.token, dispatch);
        getNotifications(user?.token, dispatch, false, 0, true).then(
          async (instantNotifications) => {
            if (instantNotifications.length > 0) {
              await updateInstantNotifications(user?.token);
            }
          },
        );
      }
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        if (user?.token) {
          const res = await countUserMissedCalls(user?.token);
          if (res) {
            setMissedCallCount(res.data);
          }
        }
      })();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [user]);

  useEffect(() => {
    globals.setNewVideoAvailableState = setNewVideoAvailableState;
    if (!user?.token) {
      return;
    }

    checkNewVideoAvailable(user.token)
      .then((res) => {
        setNewVideoAvailableState(res.data || false);
      })
      .catch(() => {});

    updateMessageDeliveredStatus(user?.token);
  }, [user]);

  const getNotification = async () => {
    setIsNotificationOpen(true);
    if (totalNotifications > 0 || data.length === 0) {
      getNotifications(user?.token, dispatch, false, 0, false).then(
        async () => {
          await getUserWaves(user?.token, dispatch);
        },
      );
    }
  };

  const goToChat = () => {
    const currentPath = history.location.pathname + history.location.search;
    if (currentPath === "/chat") {
      window.location.reload();
      return;
    }
    if (!chatRoom) {
      history.push("/chat");
      return;
    }

    const isChatRoom = chatRoom?.isChatRoom;
    const targetId = isChatRoom
      ? chatRoom?.chatUsers.find((u) => u._id !== user?._id)._id
      : chatRoom?._id;
    const targetPath = isChatRoom
      ? `/chat?userId=${targetId}`
      : `/chat?roomId=${targetId}`;

    if (!!!unreadMessagesCount) {
      history.push(targetPath);
      return;
    }

    if (currentPath === targetPath) {
      window.location.reload();
    } else {
      history.push(targetPath);
    }
  };

  const isChatRoomConnected =
    isMobile() &&
    location.pathname === "/chat" &&
    (location.search.includes("?roomId") ||
      location.search.includes("?userId"));

  const handleCreateRoom = () => {
    if (!user) {
      return showLoginModal(dispatch);
    }
    if (checkCreateRoomPermission(role, myRoomsCount, user)) {
      setIsOpen(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const showWeeklyCall = useCallback(() => {
    return (
      user &&
      location.pathname.indexOf("rooms") < 0 &&
      !match.path.includes("/:slug") &&
      !match.path.includes("/resources")
    );
  }, [location.pathname, match.path, user]);

  const _renderHeader = () => {
    return (
      <div className="flex items-center md:py-5 justify-between px-4 sm:px-12 sm:space-x-6  lg:border-b md:dark:border-matteGray w-full">
        {(match.path.includes("/:slug") &&
          !match.path.includes("/:slug/events/:eventId")) ||
        (match.path.includes("rooms/:roomId") &&
          !match.path.includes("rooms/:roomId/events/:eventId")) ? (
          <>
            {roomId && (
              <div className="w-full">
                <RoomNavigation
                  roomRoute={slug ? slug : `rooms/${roomId}`}
                  match={match}
                />
              </div>
            )}
          </>
        ) : undefined}
        {(match.path.includes("/:slug") &&
          match.path.includes("/:slug/events")) ||
        match.path.includes("rooms/:roomId/events/:eventId") ? (
          <div className="w-full">
            <EventNavigation
              eventIdWithSlug={
                slug
                  ? `${slug}/events/${eventId}`
                  : `rooms/${roomId}/events/${eventId}`
              }
              match={match}
              eventId={eventId}
            />
          </div>
        ) : undefined}
        {location.pathname === "/rooms" && <MainSearch />}

        <div
          className={` ${
            location.pathname !== "/rooms"
              ? "flex items-center justify-end w-full"
              : "w-fit "
          } `}
        >
          {user && (
            <div className="w-full flex items-center justify-between">
              {showWeeklyCall() && (
                <div className="hidden md:hidden lg:hidden xl:block">
                  <CallStreak />
                </div>
              )}
              <div className="flex gap-4 justify-end w-full">
                <div className="xl:block hidden">
                  <div className="flex gap-4 items-center">
                    <button
                      type="button"
                      onClick={goToChat}
                      className="relative text-darkNight dark:text-frescoWhite"
                    >
                      {<Chat ratio="24" />}
                      {unreadMessagesCount > 0 && (
                        <div className="absolute -top-2 -right-2 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
                          {unreadMessagesCount}
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div className="xl:block hidden">
                  <div className="flex gap-4 items-center ">
                    <button
                      type="button"
                      className="relative text-darkNight dark:text-frescoWhite"
                      onClick={() => {
                        history.push("/calls");
                      }}
                    >
                      <Call />
                      {missedCallCount > 0 && (
                        <div className="absolute -top-2 -right-1.5 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
                          {missedCallCount}
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div className="xl:block hidden">
                  <div className="flex gap-4 items-center">
                    <button
                      type="button"
                      onClick={getNotification}
                      className="relative text-black dark:text-frescoWhite"
                    >
                      {<Bell />}
                      {totalNotifications > 0 && (
                        <div className="absolute -top-2 -right-2 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
                          {totalNotifications}
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!user && !isMobile() && (
            <div className="w-full flex justify-end items-end ">
              <Button>
                <Link
                  className="place-self-center flex justify-center items-center px-2"
                  to="/login"
                >
                  <span className="flex items-center space-x-2">
                    <span>Login</span>
                  </span>
                </Link>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="dark:bg-darkNight bg-white">
      <div className="flex transition-all dark:bg-darkNight bg-white duration-1000 relative 4xl:max-w-11xl 4xl:mx-auto 4xl:shadow-xl 4xl:rounded dark:shadow-4xl">
        <div
          style={{ minWidth: 272, maxWidth: 282 }}
          className="h-screen xl:min-w-282pxl xl:w-260pxl hidden xl:block  overflow-y-hidden dark:bg-darkNight dark:border-matteGray  font-ubuntu"
        >
          <LeftSidebar
            missedCallCount={missedCallCount}
            isNewVideoAvailable={isNewVideoAvailable}
          />
        </div>
        {(isOpenLeft || isOpenRight) && (
          <div
            className="w-full bg-overlay bg-opacity-75 transition-opacity absolute z-20 h-full overflow-hidden top-0 left-0"
            onClick={handleClose}
          ></div>
        )}
        <div className="xl:w-auto flex flex-grow w-full z-10">
          {!isChatRoomConnected && (
            <div className="bg-white dark:bg-darkNight dark:border-matteGray border-b border-grayLighter w-full flex justify-between px-2 py-3 items-center xl:hidden fixed top-0 z-20">
              <button
                className="mobile-menu-button p-2 focus:outline-none text-darkGray dark:bg-componentColor bg-frescoWhite rounded-full"
                onClick={() => setIsOpenLeft(true)}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {location.pathname === "/rooms" ? (
                <div className="flex items-center space-x-2">
                  <RoomDropdownMobile />
                  <Button
                    onClick={handleCreateRoom}
                    size="vibhut-sm"
                    variant="vibhut-primary"
                  >
                    Create
                  </Button>
                </div>
              ) : (
                user && (
                  <div className="w-full flex justify-between items-center">
                    <div className="pl-4">
                      <CallStreak />
                    </div>

                    <div className="flex items-center space-x-2">
                      <div>
                        <Menu>
                          <Menu.Button>
                            <div className="">
                              <Avatar
                                username={getUserData(user).username}
                                src={getUserData(user).profile_picture}
                                size={"xs"}
                              />
                            </div>
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute  w-40 origin-top-right  bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50 right-0">
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  <button
                                    onClick={() =>
                                      history.replace(
                                        profileRoute.path.replace(
                                          ":username",
                                          user?.vibehutUsername || user?._id,
                                        ),
                                      )
                                    }
                                    className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                                  >
                                    View Profile
                                    <span className="pr-1">
                                      <UserIcon />
                                    </span>
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  <button
                                    onClick={() =>
                                      openNotificationModal(dispatch)
                                    }
                                    className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                                  >
                                    Settings
                                    <span className="pr-1">
                                      <Settings />
                                    </span>
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  <button
                                    onClick={async () => {
                                      if (checkIsDesoUser(user)) {
                                        const jwt =
                                          await desoProtocol.identity.jwt();
                                        if (jwt) {
                                          await desoProtocol.identity.logout();
                                        }
                                      }
                                      disconnectAsync();
                                      await logout(dispatch);
                                      history.replace("/login");
                                    }}
                                    className={`group text-darkNight flex rounded-md items-center justify-between w-full px-2 py-2 text-sm`}
                                  >
                                    <span className="">Log out</span>
                                    <Logout />
                                  </button>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <div className="pt-1 flex">
                        {user && (
                          <>
                            <div className="pl-2">
                              <button
                                type="button"
                                onClick={goToChat}
                                className="relative text-darkNight dark:text-frescoWhite "
                              >
                                {<Chat ratio="24" />}
                                {unreadMessagesCount > 0 && (
                                  <div className="absolute  -top-2 -right-2 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
                                    {unreadMessagesCount}
                                  </div>
                                )}
                              </button>
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                className="relative text-darkNight dark:text-frescoWhite "
                                onClick={() => {
                                  history.push("/calls");
                                }}
                              >
                                <Call />
                                {missedCallCount > 0 && (
                                  <div className="absolute -top-2 -right-1.5 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
                                    {missedCallCount}
                                  </div>
                                )}
                              </button>
                            </div>
                            <div className="pl-2">
                              <button
                                type="button"
                                onClick={getNotification}
                                className="relative text-darkNight dark:text-frescoWhite"
                              >
                                {<Bell />}
                                {totalNotifications > 0 && (
                                  <div className="absolute  -top-2 -right-2 px-1.5 py-1 text-xs font-medium leading-none text-white bg-cvRed rounded-full font-ubuntu">
                                    {totalNotifications}
                                  </div>
                                )}
                              </button>
                            </div>
                          </>
                        )}
                        <div>
                          <button
                            className="mobile-menu-button text-black dark:text-frescoWhite  pl-2 focus:outline-none "
                            onClick={() => setIsOpenRight(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              aria-hidden="true"
                              focusable="false"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
              {!user && (
                <div className="w-full flex justify-end items-end ">
                  <Button>
                    <Link
                      className="place-self-center flex justify-center items-center px-1"
                      to="/login"
                    >
                      <span className="flex items-center space-x-2">
                        <span>Login</span>
                      </span>
                    </Link>
                  </Button>
                </div>
              )}
            </div>
          )}
          <div
            className={`${
              isMobile()
                ? `${isChatRoomConnected ? "" : "mt-14"}`
                : " mt-14 h-screen overflow-auto custom-scrollbar"
            }  w-full xl:mt-0 relative ${
              location.pathname !== "/rooms" && "overflow-auto custom-scrollbar"
            } `}
          >
            {_renderHeader()}
            {withoutPaddingRoutes.includes(location.pathname) ? (
              <div className={` dark:bg-darkNight bg-white`}>
                {props.children}
              </div>
            ) : (
              <div
                className={`md:px-12 ${
                  isChatRoomConnected ? "px-0" : "px-6"
                }   dark:bg-darkNight bg-white  ${
                  location.pathname === "/rooms" &&
                  "h-[90vh] overflow-auto custom-scrollbar"
                } `}
              >
                {props.children}
              </div>
            )}{" "}
          </div>
        </div>

        {!isLiveStreamPage && (
          <div
            style={{ minWidth: 272, maxWidth: 282 }}
            className="h-screen xl:min-w-282pxl  hidden xl:block"
          >
            <RightSidebar />
          </div>
        )}

        <Div100vh>
          <div
            className={`h-full z-40  bg-white w-80 xl:hidden block fixed inset-0  transform  ${
              isOpenLeft ? "" : "-translate-x-full"
            }  xl:relative xl:translate-x-0 transition duration-200 ease-in-out`}
          >
            <div className="h-full">
              <LeftSidebar isOpen={isOpenLeft} setIsOpen={setIsOpenLeft} />
            </div>
          </div>
        </Div100vh>

        {!isLiveStreamPage && (
          <Div100vh>
            <div
              className={`h-full z-40  bg-white w-80 xl:hidden block fixed inset-y-0  right-0 transform  ${
                isOpenRight ? "" : "translate-x-full"
              }  xl:relative xl:translate-x-0 transition duration-200 ease-in-out`}
            >
              <div className="h-full">
                <RightSidebar isOpen={isOpenRight} setIsOpen={setIsOpenRight} />
              </div>
            </div>
          </Div100vh>
        )}
        {user ? (
          <CreateRoom isOpen={isOpen} setIsOpen={setIsOpen} />
        ) : undefined}
        {user ? (
          <NotificationModel
            isOpen={isNotificationOpen}
            setIsOpen={setIsNotificationOpen}
          />
        ) : undefined}
        {user ? <MyProfileModal /> : undefined}
        {user ? <EventSubscriptionModal /> : undefined}
        {user ? <Toast autoHideDuration={6000} /> : undefined}
        <InCompleteProfileModal isOpen={showInCompleteProfileModal} />
        <RoomModel
          isOpen={isOpenRoomPass}
          roomId={slug}
          setIsOpen={setOpenRoomPass}
        />
        <ConfirmationModal
          title="Upgrade to Premium"
          text={"You have reached your limit of adding Rooms."}
          description="Upgrade to premium to add more Rooms."
          openModal={openConfirmationModal}
          setOpenModal={setOpenConfirmationModal}
          primaryButtonText="Upgrade"
          primaryAction={() => {
            setOpenConfirmationModal(false);
            openPlanModal(dispatch);
          }}
          secondaryButtonText={"Cancel"}
          secondaryAction={() => {
            setOpenConfirmationModal(false);
          }}
          showLearnMore={true}
        />
        <LoginModal />
        <UserNotificationActionModal />
        <AddressBookModal />
      </div>
    </div>
  );
};

export default MainLayout;
