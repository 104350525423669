import * as actionTypes from "../constants";
import * as premiumCreatorService from "../services/premiumCreatorService";

export function getPremiumCreationAccess(dispatch, name, token) {
  dispatch({
    type: actionTypes.GET_PREMIUM_CREATOR_ACCESS_REQUEST,
  });
  return premiumCreatorService
    .getPremiumCreationAccess(name, token)
    .then((res) => {
      const premiumCreator = res.data.premiumCreator;
      if (premiumCreator) {
        dispatch({
          type: actionTypes.GET_PREMIUM_CREATOR_ACCESS_SUCCESS,
          payload: premiumCreator,
        });
      } else {
        dispatch({
          type: actionTypes.GET_PREMIUM_CREATOR_ACCESS_FAILURE,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PREMIUM_CREATOR_ACCESS_FAILURE,
      });
      throw err;
    });
}
