import CheckMark from '../../VibehutUI/Icons/CheckMark';
import SaveMessageIcon from '../../VibehutUI/Icons/SaveMessage';
import Loader from '../../VibehutUI/Loader';
import { globals } from '../../globals';
import useVibehutChatContext from '../../hooks/useVibehutChatContext/useVibehutChatContext';
import useVibehutData from '../../hooks/useVibehutData/useVibehutData';

interface Props {
  chat: any;
}

function SaveMessage({ chat }: Props) {
  const { roomData } = useVibehutData(globals.roomId);
  const { saveMessage, savingMessageId } = useVibehutChatContext();
  const handleSaveMessage = () => {
    if (chat.persistedAt) {
      return;
    }
    saveMessage(chat._id, roomData._id, Boolean(roomData.isWaveCall));
  };
  const getSaveMessageStatusIcon = () => {
    if (savingMessageId === chat._id) {
      return <Loader color="text-white" ratio="h-[10px] w-[10px]" addMargin={false} />;
    }
    if (chat.persistedAt) {
      return (
        <span className="text-white">
          <CheckMark height={10} width={10} />
        </span>
      );
    }
    return (
      <span className="text-white">
        <SaveMessageIcon height={10} width={10} />
      </span>
    );
  };

  return (
    <div onClick={handleSaveMessage} className="p-2 cursor-pointer">
      {getSaveMessageStatusIcon()}
    </div>
  );
}

export default SaveMessage;
