import { useCallback, useContext, useEffect, useRef } from "react";
import { VibehutDataContext } from "../VibehutDataProvider";

function FlyingEmojisOverlay() {
  const overlayRef = useRef();
  const { roomData, setRoomData } = useContext(VibehutDataContext);

  const handleRemoveFlyingEmoji = useCallback((node) => {
    if (!overlayRef.current) return;
    overlayRef.current.removeChild(node);
  }, []);

  const handleDisplayFlyingEmoji = useCallback(
    ({ emoji, username, callStreak }) => {
      if (!overlayRef.current) {
        return;
      }

      const node = document.createElement("div");

      node.appendChild(document.createTextNode(emoji));
      node.className =
        Math.random() * 1 > 0.5 ? "emoji wiggle-1" : "emoji wiggle-2";
      let leftPosition = Math.random() * 100 - 20;
      if (leftPosition < 20) {
        leftPosition = leftPosition + 20;
      }
      node.style.left = `${leftPosition}%`;
      const usernameSpan = document.createElement("span");
      usernameSpan.textContent = username;
      usernameSpan.className = "username";
      if (callStreak >= 7) {
        usernameSpan.className =
          "bg-gradient-btn text-lg animate-rooms text-transparent bg-clip-text";
      }
      node.appendChild(usernameSpan);
      node.src = "";
      overlayRef.current.appendChild(node);

      node.addEventListener("animationend", (e) =>
        handleRemoveFlyingEmoji(e.target),
      );
    },
    [],
  );

  useEffect(() => {
    if (roomData.callReaction.emoji) {
      handleDisplayFlyingEmoji(roomData.callReaction);
      setRoomData({
        ...roomData,
        callReaction: {
          emoji: "",
          username: "",
          callStreak: 0,
        },
      });
    }
  }, [roomData.callReaction]);

  useEffect(
    () => () =>
      overlayRef.current?.childNodes.forEach((n) =>
        n.removeEventListener("animationend", handleRemoveFlyingEmoji),
      ),
    [handleRemoveFlyingEmoji],
  );

  return (
    <div className="flying-emojis" ref={overlayRef}>
      <style jsx>{`
        .flying-emojis {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh; /* Full height of the viewport */
          width: 400px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end; /* Start from bottom */
          overflow: hidden;
          pointer-events: none;
          user-select: none;
          z-index: 9999;
        }

        .flying-emojis .emoji {
          position: absolute;
          font-size: 40px;
          line-height: 1;
          width: 48px;
          height: 48px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .emoji .username {
          font-size: 16px;
          color: #fff;
          font-weight: 700;
        }

        .flying-emojis .wiggle-1 {
          animation:
            emerge 10s forwards,
            wiggle-1 2s ease-in-out infinite alternate;
        }

        .flying-emojis .wiggle-2 {
          animation:
            emerge 10s forwards,
            wiggle-2 2s ease-in-out infinite alternate;
        }

        @keyframes emerge {
          from {
            top: 100vh; /* Move from bottom */
            opacity: 1;
          }
          50% {
            opacity: 1; /* Maintain full opacity until halfway */
          }
          to {
            top: -10px; /* Move to top */
            opacity: 0; /* Decrease opacity to 0 at the top */
          }
        }

        @keyframes wiggle-1 {
          from {
            transform: rotate(-30deg);
          }
          to {
            transform: rotate(30deg);
          }
        }

        @keyframes wiggle-2 {
          from {
            transform: rotate(30deg);
          }
          to {
            transform: rotate(-30deg);
          }
        }
      `}</style>
    </div>
  );
}

export default FlyingEmojisOverlay;
