import { useContext, useEffect, useState } from 'react';
import Avatar from '../../VibehutUI/Avatar';
import Div100vh from 'react-div-100vh';
import Button from '../../VibehutUI/Button';
import useVibehutData from '../../hooks/useVibehutData/useVibehutData';
import { globals } from '../../globals';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useAuthUser from '../../hooks/useAuthUser/useAuthUser';

export default function ConnectedScreen() {
  const isGroupCall = true;
  const { room } = useVideoContext();
  const { authUser } = useAuthUser();
  const { roomData } = useVibehutData(globals.roomId);

  const handleEndCall = () => {
    try {
      const isClosed = window.close();

      globals.socket?.disconnect();

      if (isClosed === undefined) {
        window.location.search = '';
        window.location.pathname = '/';
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-darkNight">
      <Div100vh>
        <div className="flex flex-col justify-center items-center h-full">
          <div className="border-gradient-br-blue-green-gray-900 gradient-border-3 rounded-full">
            {isGroupCall ? (
              <Avatar src={roomData?.cover?.urls?.small || ''} username={roomData?.name} />
            ) : (
              <Avatar src={authUser.profile_picture} username={authUser?.username} />
            )}
          </div>
          <div className="flex flex-col justify-center items-center">
            <div>
              {isGroupCall ? (
                <p className="text-center md:text-4xl text-3xl font-bold text-white pt-8 custom-third-line-clamp max-w-lg">
                  Connected in {roomData?.name?.replace('with $(_user)', '') || room!.name}
                </p>
              ) : (
                <p className="text-center md:text-4xl text-3xl font-bold text-white pt-8">
                  {authUser?.username ? `Connected to ${authUser.username}` : ''}
                </p>
              )}
              {isGroupCall ? (
                undefined
              ) : (
                <p className="pt-2 text-xl text-lightGray">“If you can’t interrupt people, it’s not real life”</p>
              )}
            </div>
            <div className="pt-10">
              <Button size="lg" onClick={handleEndCall} variant="danger">
                Leave
              </Button>
            </div>
          </div>
        </div>
      </Div100vh>
    </div>
  );
}
