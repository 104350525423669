import React from "react";

function MainLogo({
  ratio = 20,
  isGreyScaleActive = false,
  isInline = false,
  marginTop = "mt-0",
}) {
  return (
    <svg
      width={ratio}
      height={ratio}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isGreyScaleActive && `custom-grayscale`} ${
        isInline ? "inline" : ""
      } ${marginTop} `}
    >
      <path
        d="M17.7705 9.91289C18.4697 9.55964 18.5389 8.62541 17.9878 8.06867C15.6789 5.73607 11.4809 1.4288 9.68655 1.41413C7.2929 1.39446 3.89916 5.98461 1.77759 8.80124C1.23953 9.51556 1.54481 10.6557 2.39517 10.9326C2.75497 11.0497 3.11344 11.1375 3.46182 11.1557C3.48133 11.1567 3.50066 11.1594 3.51962 11.1641C3.66602 11.2004 3.75995 11.3427 3.73507 11.4915C3.35431 13.7689 2.77408 17.1899 4.27541 17.9268C4.40296 17.9894 4.53142 18.0536 4.65856 18.117C4.76384 18.1695 4.87695 18.2069 4.99829 18.2273C6.1814 18.4266 7.32232 18.5379 8.45026 18.5792C8.97829 18.5986 9.41884 18.1863 9.50827 17.6655C9.59461 17.1634 9.72248 16.5931 9.88492 16.0544C10.0464 15.5157 10.2428 15.0101 10.464 14.6373C10.6854 14.2642 10.9314 14.0247 11.1953 14.0196L11.2084 14.0188H11.2212H11.2353L11.2484 14.0179C11.5128 14.0132 11.7684 14.2417 12.005 14.6046C12.2414 14.9685 12.4578 15.4657 12.6412 15.9969C12.8255 16.5282 12.9777 17.0935 13.0845 17.5915C13.1792 18.0311 13.5822 18.3506 14.0287 18.2971C14.3245 18.2617 14.6226 18.2236 14.9236 18.1831C16.9137 17.9155 16.3326 14.247 15.9197 11.7741C15.8161 11.1541 16.2011 10.5711 16.7882 10.3465C17.1224 10.2186 17.4502 10.0747 17.7705 9.91289Z"
        fill="url(#paint0_linear_3534_10651)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3534_10651"
          x1="9.84166"
          y1="1.41406"
          x2="9.84166"
          y2="18.5979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#011AFF" />
          <stop offset="1" stopColor="#716DFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MainLogo;
