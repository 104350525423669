import moment from "moment";
import React, { useState, useContext, memo, useMemo } from "react";
import StarRatingComponent from "react-star-rating-component";
import Avatar from "../Avatar";
import ProfileModal from "../ProfileModal";
import DeSo from "../Icons/DeSo";
import Metamask from "../Icons/Metamask";
import Warpcast from "../Icons/Warpcast";
import Instagram from "../Icons/Instagram";
import TikTok from "../Icons/Tiktok";
import { StoreContext } from "../../Context/StoreContext";
import { _renderSocialIcon, getShortenedName } from "../../utils/utils";
import LinkedIn from "../Icons/LinkedIn";
import Arrow from "../Icons/Arrow";
import { Link } from "react-router-dom";
import UserMenu from "../UserMenu";
import Facebook from "../Icons/Facebook";
import PaidBadge from "../Icons/PaidBadge";
import Worldcoin from "../Icons/Worldcoin";
import TwitterV2 from "../Icons/TwitterV2";
import Google from "../Icons/Google";

function UserCard({
  user,
  userState = null,
  isOpenProfile = true,
  showSocialIcon = false,
  showSocialProvider = false,
  size = "xs",
  subtitle = "",
  room = undefined,
  roomId = undefined,
  date = "",
  isSidebarCard = false,
  isMemeberCard = false,
  isNormalCard = true,
  subscription = undefined,
  fromCallProfile = false,
  isGroupRoom = false,
  ishostUser = false,
  totalUsersInCall = 0,
  openRecentCallsModel = () => {},
  groupCallUsers = [],
  isMissedCall = false,
  isInviteUserList = false,
  isRoomOwner = false,
  isWaveCall = false,
  isYou = false,
  isSearchResultCard = false,
  roomOwner = undefined,
  rating = 5,
  showRating = false,
}) {
  const { state } = useContext(StoreContext);
  const { auth } = state;
  function copy(text) {
    const el = document.createElement("input");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
  const [isOpen, setIsOpen] = useState(false);
  const isWarpCastUser = useMemo(() => {
    if (user?.provider === "metamask") {
      if (user?.farcaster?.username) {
        return true;
      }
    }
    return false;
  }, [user]);
  const _renderSocialProviderTextIcon = () => {
    switch (user.provider) {
      case "bitclout":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">DeSo</span>
            <span className="pl-0.5">
              <DeSo ratio={16} />
            </span>
          </p>
        );
      case "twitter":
        return (
          <p className="flex text-sm items-center  font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">X</span>
            <span className="pl-0.5">
              <TwitterV2 ratio={16} />
            </span>
          </p>
        );
      case "metamask":
        if (user?.farcaster?.username) {
          return (
            <p className="flex text-sm items-center font-normal tracking-wide">
              <span className="font-ubuntu text-darkGray">Warpcast</span>
              <span className="pl-0.5">
                <Warpcast ratio={16} />
              </span>
            </p>
          );
        }

        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Metamask</span>
            <span className="pl-0.5">
              <Metamask ratio={16} />
            </span>
          </p>
        );
      case "instagram":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Instagram</span>
            <span className="pl-0.5">
              <Instagram ratio={16} />
            </span>
          </p>
        );
      case "tiktok":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">TikTok</span>
            <span className="pl-0.5">
              <TikTok ratio={16} />
            </span>
          </p>
        );
      case "linkedin":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">LinkedIn</span>
            <span className="pl-0.5">
              <LinkedIn ratio={20} />
            </span>
          </p>
        );
      case "facebook":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Facebook</span>
            <span className="pl-0.5">
              <Facebook ratio={20} />
            </span>
          </p>
        );
      case "google":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Facebook</span>
            <span className="pl-0.5">
              <Google ratio={20} />
            </span>
          </p>
        );
      case "worldcoin":
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">Worldcoin</span>
            <span className="pl-0.5">
              <Worldcoin ratio={20} />
            </span>
          </p>
        );
      default:
        return (
          <p className="flex text-sm items-center font-normal tracking-wide">
            <span className="font-ubuntu text-darkGray">{user?.provider}</span>
            {user?.icon && (
              <span className="pl-0.5">
                <img src={user.icon} className="w-5 h-5" alt={user?.provider} />
              </span>
            )}
          </p>
        );
    }
  };

  return (
    <React.Fragment>
      {isOpen && (
        <ProfileModal
          copyText={copy}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          userState={userState}
          user={user}
          isLoggedInUser={user._id === auth?.user?._id}
          fromCallProfile={fromCallProfile}
          isWaveCall={isWaveCall}
        />
      )}

      {isSidebarCard ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <UserMenu
            isInviteUserList={isInviteUserList}
            user={user}
            size={size}
            userState={userState}
            showSocialIcon={showSocialIcon}
            isMemeberCard={isMemeberCard}
            room={room}
            date={date}
            showSocialProvider={showSocialProvider}
            _renderSocialProviderTextIcon={_renderSocialProviderTextIcon}
            subtitle={subtitle}
          />
        </div>
      ) : (
        <button
          type="button"
          className={` ${
            isMemeberCard ? "flex justify-between items-center w-full pr-2" : ""
          } `}
        >
          <div
            className={`flex items-center space-x-3 cursor-pointer ${
              isMemeberCard ? "" : " px-2"
            } py-1.5 rounded-xl  ${
              !isMemeberCard &&
              ` hover:bg-frescoWhite  ${
                isInviteUserList ? "" : "dark:hover:bg-navyGray"
              } `
            }`}
            onClick={() => {
              return user.provider === "guest"
                ? undefined
                : setIsOpen(true && isOpenProfile);
            }}
          >
            <div className="relative">
              <Avatar
                username={
                  user.provider === "metamask"
                    ? user.username.slice(0, 5)
                    : user.username
                }
                src={user.profile_picture}
                size={size}
                userState={userState}
              />

              {showSocialIcon
                ? _renderSocialIcon(
                    isWarpCastUser ? "warpcast" : user.provider,
                    "1.5",
                  )
                : undefined}
            </div>

            <div>
              <p className=" flex flex-col">
                <span
                  className={`font-medium truncate flex  text-left items-center ${
                    isMemeberCard
                      ? "text-white text-sm  "
                      : isMissedCall
                        ? "text-cvRed"
                        : "text-matteGray text-base"
                  }  ${
                    isInviteUserList
                      ? ""
                      : `${
                          isMissedCall ? "dark:text-cvRed" : "dark:text-white"
                        }`
                  } `}
                >
                  {user && user.username
                    ? `${getShortenedName(user.username, 16)} ${
                        user.provider === "guest" ? "· (Guest)" : ""
                      }`
                    : ""}
                  <PaidBadge
                    isInline={true}
                    serviceRole={user.serviceRole}
                    customClass="ml-1"
                  />

                  {isGroupRoom && totalUsersInCall - 1 > 1 ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        openRecentCallsModel(groupCallUsers, roomOwner);
                      }}
                      className="flex items-center space-x-1.5 cursor-pointer"
                    >
                      <span>{` + ${totalUsersInCall - 1} others `}</span>

                      <span className="pt-1">
                        <Arrow />
                      </span>
                    </span>
                  ) : undefined}
                </span>
                <span
                  className={`text-xs ${
                    isMissedCall
                      ? "text-cvRed"
                      : `text-darkGray ${
                          isInviteUserList ? "" : "dark:text-darkGray"
                        }`
                  } text-left items-start font-normal`}
                >
                  {room && roomId ? (
                    <Link to={`/${roomId}`}>{room}</Link>
                  ) : (
                    room && <span>{getShortenedName(room)}</span>
                  )}
                  {date ? <span> · {date}</span> : undefined}
                </span>
              </p>
              {showSocialProvider ? _renderSocialProviderTextIcon() : undefined}
              {showRating && (
                <span className="items-start text-start flex">
                  <StarRatingComponent
                    name="rate"
                    starCount={5}
                    value={rating}
                    emptyStarColor="#808080"
                    starColor="#ffd700"
                    editing={false}
                  />
                </span>
              )}

              {subtitle ? (
                <p className="text-xs text-darkGray items-start text-left font-normal tracking-wide">
                  {subtitle}
                </p>
              ) : undefined}
              <p className="text-xs text-darkGray dark:text-gray-300 font-normal tracking-wide">
                {subscription?.room ? (
                  <React.Fragment>
                    <span>
                      {subscription.room.name} · Subscribed{" "}
                      {moment(subscription.createdAt).fromNow()}
                    </span>
                  </React.Fragment>
                ) : undefined}
              </p>
              {!fromCallProfile && (
                <p className="text-xs text-darkGray flex text-left items-start font-normal tracking-wide ">
                  {userState?.status === "online" ? (
                    <React.Fragment>
                      <span>
                        {userState.room === "Wave Call with $(_user)"
                          ? ""
                          : userState.room || "online"}
                      </span>{" "}
                    </React.Fragment>
                  ) : user.last_seen || user.room ? (
                    userState?.status === "free to talk" ? (
                      user.services?.length > 0 ? (
                        user.services.length === 1 ? (
                          <span>{user.services[0].name}</span>
                        ) : (
                          <span>{user.services.length} services</span>
                        )
                      ) : (
                        <span className="capitalize">{userState.status}</span>
                      )
                    ) : isSearchResultCard ? undefined : (
                      <React.Fragment>
                        {!isNormalCard && (
                          <span className="text-start">
                            {getShortenedName(user.room?.name)}
                            {userState?.status !== "oncall"
                              ? user.last_seen && user.room?.name
                                ? " · " + moment(user.last_seen).fromNow()
                                : ""
                              : ""}
                            {userState?.status !== "oncall"
                              ? user.last_seen && !user.room?.name
                                ? "" + moment(user.last_seen).fromNow()
                                : ""
                              : ""}
                          </span>
                        )}
                      </React.Fragment>
                    )
                  ) : undefined}
                </p>
              )}
              {isMemeberCard ? (
                <span className="text-xs text-notifyBg flex items-start font-normal tracking-wide ">
                  {isRoomOwner && isYou
                    ? "Host · You"
                    : isRoomOwner
                      ? "Host"
                      : isYou
                        ? "You"
                        : ""}
                </span>
              ) : undefined}
            </div>
          </div>
        </button>
      )}
    </React.Fragment>
  );
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.user._id === nextProps.user._id &&
    prevProps.userState?.room === nextProps.userState?.room &&
    prevProps.userState?.roomId === nextProps.userState?.roomId &&
    prevProps.userState?.status === nextProps.userState?.status
  );
}
export default memo(UserCard, areEqual);
