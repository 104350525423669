import React from "react";

function DeSo({ ratio = 20 }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00049 2V5.99937L13.7659 11.8471L3.00049 17.9999H11.8705C14.7047 17.9999 17.0003 16.031 17.0003 13.6018V10.0012L3.00049 2Z"
        fill="url(#desoSvgGradient1)"
      />
      <path
        d="M3.00049 2L17.0003 10.0012V6.39809C17.0003 3.96892 14.7022 2 11.8705 2H3.00049Z"
        fill="url(#desoSvgGradient2)"
      />
      <path
        d="M10.3649 10.0013L3 14.0006V18L13.7654 11.8472L10.3649 10.0013Z"
        fill="url(#desoSvgGradient3)"
      />
    </svg>
  );
}
export default DeSo;
