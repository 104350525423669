import { useContext, useEffect, useState } from "react";
import { getPersonaMatchingSummary } from "../../../services/personaService";
import { StoreContext } from "../../../Context/StoreContext";
import Loader from "../../../VibehutUI/Loader";
import { PersonaIntro } from "../../../types";
import * as actionTypes from "../../../constants";
import DoubleStar from "../../../VibehutUI/Icons/DoubleStar";
import { showToast } from "../../../actions/toastActions";

interface Props {
  personaIntro: PersonaIntro | undefined;
  personaId: string;
}

function MatchingSummary({ personaIntro, personaId }: Props) {
  const { state, dispatch } = useContext(StoreContext);
  const [matchingSummary, setMatchingSummary] = useState({
    summary: personaIntro?.summary || "",
    related: personaIntro?.related || [],
    asked: personaIntro?.asked || [],
  });
  const [error, setError] = useState<null | string>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [regenerate, setRegenerate] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [retry, setRetry] = useState(0);

  const {
    auth: { user },
  } = state;

  useEffect(() => {
    if (
      (!personaIntro || !personaIntro?.summary || regenerate) &&
      user?.token &&
      retry < 3
    ) {
      const controller = new AbortController();
      const { signal } = controller;

      getPersonaMatchingSummary(user?.token, personaId, { signal })
        .then((res) => {
          if (res.data?.summary) {
            setMatchingSummary(res.data);
            dispatch({
              type: actionTypes.UPDATE_PERSONA_INTRO,
              payload: res.data,
            });
            setRegenerate(false);
            setIsRetry(false);
            return;
          }
          setRetry((retry) => retry + 1);
          setIsRetry(true);
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            setError(error.message || "An error occurred");
          }
        })
        .finally(() => {
          setIsFetching(false);
        });

      return () => controller.abort();
    } else {
      if (retry === 3) {
        setIsRetry(false);
        showToast(
          dispatch,
          "info",
          "It seems ChatGPT is a little tired, try again in 10 seconds.",
          2000,
        );
      }
      setIsFetching(false);
    }
  }, [dispatch, personaId, personaIntro, user?.token, regenerate, retry]);

  if (isFetching) {
    return <Loader />;
  }

  if (isRetry) {
    return (
      <p className="text-sm font-medium text-darkGray flex items-center gap-2">
        <span>We found a better intro, please wait one moment</span>
        <span className="flex items-center text-primary cursor-pointer gap-1 ">
          <Loader ratio="h-4 w-4" rightMargin={false} />
          <span>{retry}/3</span>
        </span>
      </p>
    );
  }

  if (!matchingSummary.summary) {
    return <></>;
  }

  return (
    <div>
      <p className="dark:text-frescoWhite text-sm font-medium">
        {matchingSummary.summary}
      </p>
      <div className="pt-2">
        <p className="font-bold text-sm dark:text-frescoWhite">
          List of Interests and Their Relevance to Your Personas
        </p>
        <ul>
          {matchingSummary.related.map((relate, index) => (
            <li
              key={relate + index}
              className="text-sm list-disc dark:text-frescoWhite"
            >
              {relate}
            </li>
          ))}
        </ul>
      </div>
      <div className="pt-2">
        <p className="font-bold text-sm dark:text-frescoWhite">
          List of Questions to Ask the Person
        </p>
        <ul>
          {matchingSummary.asked.map((ask, index) => (
            <li
              key={ask + index}
              className="text-sm list-disc dark:text-frescoWhite"
            >
              {ask}
            </li>
          ))}
        </ul>
      </div>
      <p
        className="text-sm font-medium text-primary flex items-center gap-1 cursor-pointer pt-2"
        onClick={() => setRegenerate(true)}
      >
        {" "}
        {regenerate ? (
          <Loader ratio="h-4 w-4" rightMargin={false} />
        ) : (
          <DoubleStar />
        )}{" "}
        <span> {regenerate ? "Regenerating" : "Regenerate"} Match</span>
      </p>
    </div>
  );
}

export default MatchingSummary;
