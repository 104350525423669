import React, { useContext } from "react";
import { getUserData } from "../../services/userService";
import { StoreContext } from "../../Context/StoreContext";
import UserCard from "../UserCard";
import Button from "../Button";
import { blockUserFromRoom } from "../../actions/roomAction";
import Loader from "../Loader";

const SendBlock = ({ user, roomId, ownerId }) => {
  const { state, dispatch } = useContext(StoreContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    auth,
    room: { blockedUsers },
  } = state;

  const handleBlockUser = async (isBlock = true) => {
    if (!auth?.user?._id) return false;
    setIsLoading(true);
    await blockUserFromRoom(
      {
        roomId: roomId,
        ownerId: auth?.user?._id,
        blocked: isBlock,
        userId: user._id,
      },
      auth?.user?.token,
      dispatch
    );
    setIsLoading(false);
  };

  return (
    <div className="py-2 flex items-center justify-between">
      <UserCard
        user={{
          ...getUserData(user),
          room: user.room,
        }}
        userState={user.userState}
        isSearchResultCard={true}
        fromCallProfile={true}
        isInviteUserList={true}
      />
      <div className="px-2">
        {isLoading ? (
          <Loader />
        ) : !blockedUsers?.some(
            (blockedUser) => blockedUser._id === user._id
          ) ? (
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={() => handleBlockUser(true)}
          >
            Block
          </Button>
        ) : (
          <Button
            size="vibhut-sm-outline"
            variant="vibhut-primary-outline"
            onClick={() => handleBlockUser(false)}
          >
            Un-Block
          </Button>
        )}
      </div>
    </div>
  );
};

export default SendBlock;
