import React from "react";

function NoResult() {
  return (
    <svg
      width="148"
      height="123"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 148 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4160_4173)">
        <path
          d="M129.616 81.0111C144.75 97.8028 100.88 122.001 65.4372 122.001C29.994 122.001 5.46519 103.252 1.25859 81.0111C-8.953 27.0865 88.2676 -7.18791 65.4372 40.0208C32.1613 108.834 117.698 67.7869 129.616 81.0111Z"
          fill="#3F3D56"
        />
        <path
          d="M47.4566 28.7524C48.1691 28.7524 48.7466 28.1732 48.7466 27.4586C48.7466 26.744 48.1691 26.1648 47.4566 26.1648C46.7442 26.1648 46.1666 26.744 46.1666 27.4586C46.1666 28.1732 46.7442 28.7524 47.4566 28.7524Z"
          fill="#D0CDE1"
        />
        <path
          d="M29.9805 106.218C30.6929 106.218 31.2705 105.638 31.2705 104.924C31.2705 104.209 30.6929 103.63 29.9805 103.63C29.268 103.63 28.6905 104.209 28.6905 104.924C28.6905 105.638 29.268 106.218 29.9805 106.218Z"
          fill="#D0CDE1"
        />
        <path
          d="M49.7476 47.0044C50.0641 47.0044 50.3206 46.7471 50.3206 46.4297C50.3206 46.1123 50.0641 45.855 49.7476 45.855C49.4312 45.855 49.1746 46.1123 49.1746 46.4297C49.1746 46.7471 49.4312 47.0044 49.7476 47.0044Z"
          fill="#D0CDE1"
        />
        <path
          d="M19.6657 76.8979C19.9821 76.8979 20.2387 76.6406 20.2387 76.3232C20.2387 76.0058 19.9821 75.7485 19.6657 75.7485C19.3492 75.7485 19.0927 76.0058 19.0927 76.3232C19.0927 76.6406 19.3492 76.8979 19.6657 76.8979Z"
          fill="#D0CDE1"
        />
        <path
          d="M95.0135 98.4557C95.33 98.4557 95.5865 98.1984 95.5865 97.881C95.5865 97.5636 95.33 97.3063 95.0135 97.3063C94.6971 97.3063 94.4405 97.5636 94.4405 97.881C94.4405 98.1984 94.6971 98.4557 95.0135 98.4557Z"
          fill="#D0CDE1"
        />
        <path
          d="M17.8033 47.5791C18.1197 47.5791 18.3763 47.3218 18.3763 47.0044C18.3763 46.687 18.1197 46.4297 17.8033 46.4297C17.4868 46.4297 17.2303 46.687 17.2303 47.0044C17.2303 47.3218 17.4868 47.5791 17.8033 47.5791Z"
          fill="#D0CDE1"
        />
        <path
          d="M52.6117 31.7704H52.3247V31.4825H52.1817V31.7704H51.8947V31.9138H52.1817V32.2016H52.3247V31.9138H52.6117V31.7704Z"
          fill="#D0CDE1"
        />
        <path
          d="M30.695 36.5134H30.408V36.2256H30.265V36.5134H29.978V36.6568H30.265V36.9447H30.408V36.6568H30.695V36.5134Z"
          fill="#D0CDE1"
        />
        <path
          d="M16.3708 63.5334H16.0888V63.2455H15.9458V63.5334H15.6588V63.6768H15.9458V63.9646H16.0888V63.6768H16.3758L16.3708 63.5334Z"
          fill="#D0CDE1"
        />
        <path
          d="M56.6233 110.816C56.9398 110.816 57.1963 110.559 57.1963 110.242C57.1963 109.924 56.9398 109.667 56.6233 109.667C56.3069 109.667 56.0503 109.924 56.0503 110.242C56.0503 110.559 56.3069 110.816 56.6233 110.816Z"
          fill="#D0CDE1"
        />
        <path
          d="M54.761 81.4975C55.0774 81.4975 55.334 81.2402 55.334 80.9228C55.334 80.6054 55.0774 80.3481 54.761 80.3481C54.4445 80.3481 54.188 80.6054 54.188 80.9228C54.188 81.2402 54.4445 81.4975 54.761 81.4975Z"
          fill="#D0CDE1"
        />
        <path
          d="M122.517 92.133C122.834 92.133 123.09 91.8757 123.09 91.5583C123.09 91.2409 122.834 90.9836 122.517 90.9836C122.201 90.9836 121.944 91.2409 121.944 91.5583C121.944 91.8757 122.201 92.133 122.517 92.133Z"
          fill="#D0CDE1"
        />
        <path
          d="M53.3285 97.4507H53.0415V97.1629H52.8985V97.4507H52.6115V97.5941H52.8985V97.882H53.0415V97.5941H53.3285V97.4507Z"
          fill="#D0CDE1"
        />
        <path
          d="M81.1182 107.655H80.8322V107.368H80.6892V107.655H80.4022V107.798H80.6892V108.086H80.8322V107.798H81.1182V107.655Z"
          fill="#D0CDE1"
        />
        <path
          d="M10.9256 82.0732H10.6386V81.7854H10.4956V82.0732H10.2086V82.2166H10.4956V82.5035H10.6386V82.2166H10.9256V82.0732Z"
          fill="#D0CDE1"
        />
        <path
          d="M50.7503 61.377H50.4633V61.0891H50.3203V61.377H50.0363V61.5204H50.3233V61.8082H50.4663V61.5204H50.7533L50.7503 61.377Z"
          fill="#D0CDE1"
        />
        <path
          d="M33.1302 44.4178H32.8432V44.1299H32.7002V44.4178H32.4132V44.5612H32.7002V44.8491H32.8432V44.5612H33.1302V44.4178Z"
          fill="#D0CDE1"
        />
        <path
          d="M44.8813 72.7306H44.5943V72.4427H44.4513V72.7306H44.1643V72.874H44.4513V73.1618H44.5943V72.874H44.8813V72.7306Z"
          fill="#D0CDE1"
        />
        <path
          d="M27.687 90.5523H27.4V90.2645H27.257V90.5523H26.97V90.6957H27.257V90.9836H27.4V90.6957H27.687V90.5523Z"
          fill="#D0CDE1"
        />
        <path
          d="M33.5598 66.2634C38.2273 66.2634 42.011 65.6842 42.011 64.9696C42.011 64.255 38.2273 63.6758 33.5598 63.6758C28.8924 63.6758 25.1086 64.255 25.1086 64.9696C25.1086 65.6842 28.8924 66.2634 33.5598 66.2634Z"
          fill="#6C63FF"
        />
        <path
          d="M34.4205 65.3969C30.9657 65.3969 25.8234 65.0137 25.8234 63.9596C25.8234 62.9055 30.9637 62.5224 34.4205 62.5224C37.8774 62.5224 43.0177 62.9055 43.0177 63.9596C43.0177 65.0137 37.8754 65.3969 34.4205 65.3969ZM34.4205 62.8102C32.4291 62.7927 30.439 62.9188 28.4655 63.1873C26.6031 63.4722 26.1113 63.8172 26.1113 63.9596C26.1113 64.102 26.6031 64.4471 28.4655 64.7319C32.4194 65.2349 36.4207 65.2349 40.3745 64.7319C42.2359 64.4471 42.7288 64.102 42.7288 63.9596C42.7288 63.8172 42.2369 63.4722 40.3745 63.1873C38.4013 62.9202 36.4115 62.7955 34.4205 62.8142V62.8102Z"
          fill="#D0CDE1"
        />
        <path
          d="M136.161 75.8658C124.238 62.6427 38.7061 103.689 71.977 34.8776C86.8081 4.20379 50.96 7.92982 26.8291 29.0934C50.1963 12.9948 78.4029 12.5314 64.9574 40.3387C31.6864 109.152 117.223 68.1049 129.136 81.329C134.681 87.4822 132.304 94.6294 125.321 101.217C137.328 93.3225 143.287 83.7732 136.161 75.8658Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.022 121.738L65.8381 121.668C63.2868 120.786 61.1595 118.974 59.8786 116.591C58.5978 114.208 58.2572 111.429 58.9244 108.805L58.9684 108.613L59.1523 108.682C61.7034 109.564 63.8305 111.377 65.1111 113.76C66.3917 116.143 66.7322 118.922 66.065 121.545L66.022 121.738ZM60.1809 116.427C61.4927 118.587 63.4472 120.28 65.7691 121.267C66.3582 118.784 66.0164 116.17 64.8088 113.923C63.6011 111.676 61.6121 109.953 59.2203 109.082C58.7672 111.571 59.1031 114.14 60.1809 116.427Z"
          fill="#D0CDE1"
        />
        <path
          d="M61.3216 115.53C63.5005 117.012 65.1476 119.157 66.02 121.648C63.417 122.186 60.7077 121.721 58.4309 120.346C56.1541 118.971 54.4767 116.786 53.7321 114.226C56.3358 113.834 58.9963 114.291 61.3216 115.53Z"
          fill="#6C63FF"
        />
        <path
          d="M108.932 41.1571C108.932 41.1571 101.157 48.8018 104.422 49.7375C107.687 50.6733 111.42 42.7168 111.42 42.7168L108.932 41.1571Z"
          fill="#FFB9B9"
        />
        <path
          d="M116.706 66.8993L115.773 71.2672L122.771 71.5792L121.682 66.8983L116.706 66.8993Z"
          fill="#FFB9B9"
        />
        <path
          d="M138.787 93.8892L137.854 98.2571L144.852 98.569L143.763 93.8882L138.787 93.8892Z"
          fill="#FFB9B9"
        />
        <path
          d="M126.503 37.725L127.125 40.2214C127.125 40.2214 109.864 36.7892 110.797 45.5261C111.598 51.4624 113.275 57.2461 115.773 62.6868C115.773 62.6868 115.463 65.4952 116.084 65.9635C116.706 66.4319 116.084 67.9915 116.084 67.9915C116.084 67.9915 121.837 68.7719 121.993 67.3677C122.076 66.4289 122.024 65.4831 121.837 64.5594C121.837 64.5594 122.148 64.5594 121.837 62.9988C121.526 61.4382 120.27 48.8569 119.338 47.1408C119.338 47.1408 127.591 53.4826 131.946 52.7023C131.699 57.6117 132.331 62.5258 133.812 67.2112C136.301 75.4797 136.301 76.4155 136.301 76.4155C136.301 76.4155 139.255 92.1731 138.478 94.0446L138.322 94.8249H144.075C144.075 94.8249 143.608 86.5565 144.075 85.4642C144.542 84.372 144.542 75.0113 141.898 70.3315C140.025 67.0217 140.374 57.4072 141.398 53.608C142.268 50.7213 142.464 47.6725 141.971 44.6976C141.782 43.3187 141.321 41.9915 140.614 40.7941C139.907 39.5967 138.969 38.5532 137.854 37.725H126.503Z"
          fill="#2F2E41"
        />
        <path
          d="M131.09 14.2455C134.182 14.2455 136.688 11.7309 136.688 8.62889C136.688 5.52692 134.182 3.01227 131.09 3.01227C127.999 3.01227 125.492 5.52692 125.492 8.62889C125.492 11.7309 127.999 14.2455 131.09 14.2455Z"
          fill="#FFB9B9"
        />
        <path
          d="M129.613 12.6077C129.613 12.6077 131.323 17.6004 131.168 18.5362C131.013 19.472 136.145 16.6637 136.145 16.6637C136.145 16.6637 134.123 11.6709 134.745 10.2667L129.613 12.6077Z"
          fill="#FFB9B9"
        />
        <path
          d="M135.833 14.9436L130.391 17.128L130.701 19.0006C130.701 19.0006 130.235 19.6244 130.391 19.9364C130.546 20.2483 128.369 21.496 128.68 21.9644C128.991 22.4327 126.814 24.3043 126.97 24.7727C127.126 25.241 121.993 28.0494 124.792 33.5095C125.659 34.7079 126.106 36.1607 126.062 37.6407C126.055 37.7196 126.047 37.7989 126.037 37.8784C125.726 40.2184 137.855 37.5665 137.855 37.5665V34.9147C137.855 34.9147 138.944 34.4463 138.788 32.4183C138.632 30.3903 137.699 31.3261 138.788 30.2338C139.876 29.1416 139.876 29.2981 139.72 28.3613C139.658 27.9872 139.645 26.6432 139.653 25.1638C139.668 22.0371 138.653 18.993 136.766 16.5042L135.833 14.9436Z"
          fill="#D0CDE1"
        />
        <path
          d="M132.879 19.0036L122.771 29.612L107.999 41.3126L111.265 43.9644L138.477 25.7104C138.477 25.7104 140.809 15.7269 132.879 19.0036Z"
          fill="#D0CDE1"
        />
        <path
          d="M117.173 69.2392C117.173 69.2392 116.084 67.3667 115.307 67.9915L109.864 72.3595C109.864 72.3595 100.224 75.1678 109.553 77.3522C109.553 77.3522 114.685 78.1326 115.773 77.3522C116.254 77.045 116.801 76.8565 117.369 76.8023C117.937 76.7481 118.509 76.8296 119.039 77.0403C119.506 77.3522 125.415 76.8839 125.415 75.6362C125.415 74.3885 122.156 68.9333 122.156 68.9333C122.156 68.9333 120.287 71.1108 117.173 69.2392Z"
          fill="#2F2E41"
        />
        <path
          d="M139.254 96.2291C139.254 96.2291 138.165 94.3566 137.387 94.9814L131.945 99.3493C131.945 99.3493 122.304 102.158 131.634 104.342C131.634 104.342 136.765 105.122 137.854 104.342C138.335 104.035 138.882 103.846 139.45 103.792C140.017 103.738 140.59 103.82 141.12 104.03C141.587 104.342 147.496 103.874 147.496 102.626C147.496 101.378 144.237 95.9232 144.237 95.9232C144.237 95.9232 142.364 98.1016 139.254 96.2291Z"
          fill="#2F2E41"
        />
        <path
          d="M124.382 6.4013C124.382 6.4013 125.328 -1.9464 133.424 0.423611C133.424 0.423611 139.151 -0.744846 141.173 6.43139L143.226 13.9536L142.287 13.4632L141.871 14.422L140.371 14.8343L139.701 13.5625L139.42 15.1281L129.062 17.2293C131.287 14.411 132.511 10.9283 132.54 7.33306L131.511 8.47143L124.382 6.4013Z"
          fill="#2F2E41"
        />
        <path
          d="M41.5362 60.4462L35.7181 54.6089C36.4328 53.5158 36.718 52.1957 36.5185 50.9038C36.3191 49.6119 35.6493 48.4402 34.6386 47.6151C33.6279 46.7901 32.3481 46.3705 31.0468 46.4374C29.7456 46.5043 28.5153 47.0531 27.5939 47.9774C26.6726 48.9018 26.1257 50.1362 26.059 51.4417C25.9923 52.7473 26.4105 54.0313 27.2328 55.0453C28.0551 56.0594 29.223 56.7315 30.5107 56.9315C31.7983 57.1316 33.1141 56.8455 34.2036 56.1284L40.0217 61.9657L41.5362 60.4462ZM28.6665 54.3702C28.1424 53.8445 27.7855 53.1747 27.6409 52.4455C27.4962 51.7163 27.5704 50.9604 27.854 50.2735C28.1375 49.5866 28.6178 48.9994 29.2339 48.5864C29.8501 48.1733 30.5745 47.9528 31.3156 47.9528C32.0566 47.9528 32.7811 48.1733 33.3972 48.5864C34.0134 48.9994 34.4936 49.5866 34.7772 50.2735C35.0608 50.9604 35.1349 51.7163 34.9903 52.4455C34.8457 53.1747 34.4888 53.8445 33.9647 54.3702C33.2617 55.0746 32.3087 55.47 31.3152 55.4696C30.3217 55.4692 29.369 55.0731 28.6665 54.3682V54.3702Z"
          fill="#6C63FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4160_4173">
          <rect
            width="146.992"
            height="122.001"
            fill="white"
            transform="translate(0.50415)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoResult;
