import React, { useState } from 'react';
import { globals } from '../../globals';
import { loginAsGuest } from '../../services/userService';
import Button from '../Button';

interface propsGuestModal {
  onclose: () => void;
}

const GuestModal = ({ onclose }: propsGuestModal) => {
  const joinCallAsGuest = async (username: string) => {
    try {
      const res = await loginAsGuest(username);
      globals.guestUserDetails(res.data);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };
  const [username, setUsername] = useState<string>('');

  const startFuncCall = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onclose();
      joinCallAsGuest(username);
    }
  };

  return (
    <div className="p-4">
      <React.Fragment>
        <div>
          <input
            type="text"
            className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full"
            value={username}
            placeholder="Enter Username.."
            onChange={e => setUsername(e.target.value)}
            onKeyDown={startFuncCall}
          />
        </div>
        <div className="flex flex-row justify-end pt-4">
          <div className="mr-2">
            <Button onClick={onclose}>Cancel</Button>
          </div>
          <div>
            <Button
              onClick={() => {
                onclose();
                joinCallAsGuest(username);
              }}
              disabled={!username}
            >
              Join
            </Button>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default GuestModal;

// export default function BugReport({ appState }) {
//   return (
//     <Button
//       onClick={() => {
//         globals.showModal(
//           "Report a Bug",
//           <ReportBugContent onclose={globals.hideModal} appState={appState} />
//         );
//       }}
//     >
//       Report a Bug
//     </Button>
//   );
// }
