import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import SendActive from "../../VibehutUI/Icons/SendActive";
import SendInActive from "../../VibehutUI/Icons/SendInActive";

function LiveMessageInput({ onSubmit, fromCall }: any) {
  const [text, setText] = useState("");
  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setText(e.target.value);
  };

  const handleClick = () => {
    if (text.trim() === "") {
      return;
    }
    onSubmit(text);
    setText("");
  };

  const handleKeyPress = (e: {
    key: string;
    shiftKey: any;
    preventDefault: () => void;
  }) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (text.trim() === "") {
        return;
      }
      onSubmit(text);
      setText("");
    }
  };
  const colorClassNames = !fromCall
    ? "text-matteGray dark:bg-matteGray dark:text-white md:border-lightGray dark:border-0"
    : "bg-matteGray text-white placeholder-primaryGray";
  return (
    <div className="relative w-full">
      <TextareaAutosize
        onChange={handleChange}
        className={`${colorClassNames} marker:py-2  pr-8 pl-4 custom-scrollbar rounded-xl w-full  outline-none resize-none overflow-auto h-[80vh]`}
        placeholder="Type a message..."
        maxRows={3}
        value={text}
        onKeyPress={handleKeyPress}
      />
      <button
        type="button"
        disabled={!text.length || text.trim() === ""}
        className="absolute right-3 bottom-3"
        onClick={handleClick}
      >
        {text.length && text.trim() !== "" ? (
          <SendActive />
        ) : (
          <SendInActive roomId={!fromCall ? "roomId" : ""} />
        )}
      </button>
    </div>
  );
}

export default LiveMessageInput;
