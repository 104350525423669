import React from 'react';

const ScreenShareOffV2 = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6668 3.33333C14.6668 2.59695 14.0699 2 13.3335 2H10.5H4.5H2.66683C1.93045 2 1.3335 2.59695 1.3335 3.33333V10C1.3335 10.7364 1.93045 11.3333 2.66683 11.3333H13.3335C14.0699 11.3333 14.6668 10.7364 14.6668 10V3.33333Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.3335 14H10.6668" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11.3333V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 5.67L8 8.67" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6.67L8 4.67L6 6.67" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ScreenShareOffV2;
