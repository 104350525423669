import axios from "../utils/axios";

export function saveCompliment(token, params) {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/compliments`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateCompliment(token, id, params) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/compliments/${id}`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function deleteCompliment(token, id) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`/compliments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCompliments(callId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/compliments/${callId}`, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateComplimentFeaturedStatus(token, id, isFeatured = false) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        `/compliments/update-featured/${id}`,
        { isFeatured },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomCompliments(roomId, isFeatured = false) {
  let path = `/compliments/room/${roomId}`;
  if (isFeatured) path += `?isFeatured=true`;
  return new Promise(function (resolve, reject) {
    axios
      .get(path, {})
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
