import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IUser, SocialLinks } from '../../components/VibehutDataProvider';
import { ensureHttps } from '../../../utils/utils';
import DeSo from '../Icons/DeSo';
import {
  _renderFacebook,
  _renderInstagram,
  _renderLinkedIn,
  _renderTelegram,
  _renderTiktok,
  _renderTwitter,
} from '../../utils/utils';
import Metamask from '../Icons/Metamask';
import LinkIcon from '../Icons/LinkIcon';
import { globals } from '../../globals';
import Button from '../Button';
import LinkedIn from '../Icons/LinkedIn';
import TikTok from '../Icons/Tiktok';
import Instagram from '../Icons/Instagram';
import Telegram from '../Icons/Telegram';
import TwitterV2 from '../Icons/TwitterV2';

const SocialLinkIcons = ({ profile }: { profile: IUser }) => {
  const spanContainerRef = useRef<HTMLDivElement>(null);
  const [socialLinksExistType, setSocialLinksExistType] = useState<string[]>([] as string[]);

  const data = useCallback(() => {
    const socialLinks: SocialLinks = profile?.socialLinks || ({} as SocialLinks);
    const socialLinksTypes: string[] = [];
    Object.keys(socialLinks).forEach((key, index, arr) => {
      if (socialLinks[key as keyof SocialLinks] && key !== '_id') {
        socialLinksTypes.push(key);
      }
    });
    setSocialLinksExistType(socialLinksTypes);
  }, [profile]);

  useEffect(() => {
    if (profile?.socialLinks) {
      data();
    }
  }, [profile]);

  const [isAddressCopied, setIsAddressCopied] = useState(false);

  const copyMetamaskAddress = async (address: string) => {
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 3000);
  };

  return (
    <div ref={spanContainerRef} className="flex flex-row gap-1.5 text-xs items-center">
      {profile?.provider === 'bitclout' && (
        <span>
          <a
            href={`https://diamondapp.com/u/${profile?.username}`}
            target="_blank"
            rel="noreferrer"
            className="text-darkGray bg-darkGray "
          >
            <DeSo ratio={16} isGreyScaleActive={true} />
          </a>
        </span>
      )}
      {profile?.provider === 'twitter' && _renderTwitter(profile?.username)}
      {socialLinksExistType
        ?.slice(0, profile?.provider === 'bitclout' || profile?.provider === 'twitter' ? 2 : 3)
        .map((icon, ind) => (
          <span key={ind}>
            {icon === 'twitter' && profile?.provider !== 'twitter' ? (
              _renderTwitter(profile?.socialLinks?.twitter as string)
            ) : icon === 'facebook' ? (
              _renderFacebook(profile?.socialLinks?.facebook as string)
            ) : icon === 'instagram' ? (
              _renderInstagram(profile?.socialLinks?.instagram as string)
            ) : icon === 'telegram' ? (
              _renderTelegram(profile?.socialLinks?.telegram as string)
            ) : icon === 'tiktok' ? (
              _renderTiktok(profile?.socialLinks?.tiktok as string)
            ) : icon === 'linkedIn' ? (
              _renderLinkedIn(profile?.socialLinks?.linkedIn as string)
            ) : icon === 'metamask' ? (
              <span>
                <Metamask ratio={16} isGreyScaleActive={true} />
              </span>
            ) : icon === 'link' ? (
              <span>
                <a href={`${profile?.socialLinks?.link}`} target="_blank" rel="noreferrer">
                  <LinkIcon ratio={16} isGreyScaleActive={true} />
                </a>
              </span>
            ) : (
              undefined
            )}
          </span>
        ))}
      {socialLinksExistType?.length >= 3 && (
        <p
          className="underline text-sm cursor-pointer text-notifyBg"
          onClick={() => {
            globals.showModal(
              profile.username,
              <div className="overflow-y-auto custom-scrollbar h-80  px-3 flex flex-col gap-1 divide-y dark:divide-matteGray">
                {profile?.provider === 'bitclout' && (
                  <div className="flex justify-between items-center  py-1.5">
                    <div className="flex space-x-3">
                      <DeSo ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">Diamond App</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          window.open(`https://diamondapp.com/u/${profile?.username}`, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}
                {profile?.socialLinks?.twitter && profile?.socialLinks?.twitter !== '' ? (
                  <div className="flex justify-between items-center  py-1.5 ">
                    <div className="flex space-x-3 items-center">
                        <TwitterV2 ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">X</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          const regex = /https:\/\/twitter\.com\/([a-zA-Z0-9_]+)/;
                          const url = profile?.socialLinks?.twitter;
                          const regUrl = regex.test(url || '') ? url : `https://twitter.com/${url}`;
                          window.open(regUrl, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                ) : (
                  profile?.provider === 'twitter' && (
                    <div className="flex justify-between items-center  py-1.5 ">
                      <div className="flex space-x-3">
                        <TwitterV2 ratio={20} />
                        <p className="text-darkNight dark:text-frescoWhite">X</p>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            const regex = /https:\/\/twitter\.com\/([a-zA-Z0-9_]+)/;
                            const url = profile?.socialLinks?.twitter || '';
                            const regUrl = regex.test(url) ? url : `https://twitter.com/${url}`;
                            window.open(regUrl, '_blank', 'noopener,noreferrer');
                          }}
                          variant="secondary"
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  )
                )}
                {profile?.socialLinks?.linkedIn && (
                  <div className="flex justify-between items-center  py-1.5 ">
                    <div className="flex space-x-3">
                      <LinkedIn ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">Linkedin</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          const regex = /https:\/\/www\.linkedin\.com\/in\/([a-zA-Z0-9_]+)/;
                          const url = profile?.socialLinks?.linkedIn || '';
                          const regUrl = regex.test(url) ? url : `https://www.linkedin.com/in/${url}`;
                          window.open(regUrl, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}

                {profile?.socialLinks?.metamask && (
                  <div className="flex justify-between items-center py-1.5">
                    <div className="flex space-x-3">
                      <Metamask ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">Metamask</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => copyMetamaskAddress(profile?.socialLinks?.metamask || '')}
                        variant="secondary"
                      >
                        {isAddressCopied ? 'Copied' : 'Copy'}
                      </Button>
                    </div>
                  </div>
                )}
                {profile?.socialLinks?.tiktok && (
                  <div className="flex justify-between items-center py-1.5 ">
                    <div className="flex space-x-3">
                      <TikTok ratio={'20'} />
                      <p className="text-darkNight dark:text-frescoWhite">TikTok</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          const regex = /https:\/\/tiktok\.com\/@([a-zA-Z0-9_]+)/;
                          const url = profile?.socialLinks?.tiktok || '';
                          const regUrl = regex.test(url) ? url : `https://tiktok.com/@${url}`;
                          window.open(regUrl, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}
                {profile?.socialLinks?.instagram && (
                  <div className="flex justify-between items-center py-1.5">
                    <div className="flex space-x-3">
                      <Instagram ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">Instagram</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          const regex = /https:\/\/www\.instagram\.com\/([a-zA-Z0-9_]+)/;
                          const url = profile?.socialLinks?.instagram || '';
                          const regUrl = regex.test(url) ? url : `https://www.instagram.com/${url}`;
                          window.open(regUrl, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}
                {profile?.socialLinks?.telegram && (
                  <div className="flex justify-between items-center py-1.5">
                    <div className="flex space-x-3">
                      <Telegram ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">Telegram</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          const regex = /https:\/\/telegram\.me\/([a-zA-Z0-9_]+)/;
                          const url = profile?.socialLinks?.telegram || '';
                          const regUrl = regex.test(url) ? url : `https://telegram.me/${url}`;
                          window.open(regUrl, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}

                {profile?.socialLinks?.link && (
                  <div className="flex justify-between items-center py-1.5">
                    <div className="flex space-x-3">
                      <LinkIcon ratio={20} />
                      <p className="text-darkNight dark:text-frescoWhite">Link</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          window.open(`${ensureHttps(profile?.socialLinks?.link)}`, '_blank', 'noopener,noreferrer');
                        }}
                        variant="secondary"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                )}
              </div>,
              true
            );
          }}
        >
          see all
        </p>
      )}
    </div>
  );
};
export default SocialLinkIcons;
