import React from "react";

function PopOver({
  text,
  size = 24,
  leftPosition = "-left-8",
  topPosition = "-top-11",
  arrowPosition = "bottom",
}) {
  return (
    <>
      <div className={`absolute ${leftPosition} ${topPosition}  z-40`}>
        <div className="">
          <div
            className={`font-medium py-1.5 px-0.5 text-sm bottom-full bg-darkNight dark:bg-frescoWhite rounded-lg  text-white dark:text-darkNight w-${size} min-w-fit relative`}
          >
            <div>
              <p className="font-medium text-md text-center">{text}</p>
            </div>
            {arrowPosition === "left" ? (
              <svg
                className="absolute text-darkNight dark:text-frescoWhite h-5 w-full -left-[124px] top-1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"
                />
              </svg>
            ) : (
              <svg
                className="absolute text-darkNight dark:text-frescoWhite h-5 w-full left-0 top-full"
                x="0px"
                y="0px"
                aria-hidden="true"
                focusable="false"
                viewBox="0 0 255 255"
              >
                <polygon
                  className="fill-current"
                  points="0,0 127.5,127.5 255,0"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PopOver;
