import { Switch } from "@headlessui/react";
import React, { useContext, useEffect, useState } from "react";
import { updateUserState } from "../actions/userActions";
import { StoreContext } from "../Context/StoreContext";
import { showLoginModal } from "../actions/uiModalActions";
import { getUserPublicProfile } from "../services/userService";

//FreeToTalk
function FreeToTalk() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    user: { freeToTalkUsers },
  } = state;
  const [enabled, setEnabled] = useState(
    user?.userState?.status === "free to talk" ? true : false,
  );
  useEffect(() => {
    if (
      enabled &&
      freeToTalkUsers.findIndex((u) => {
        return u._id === user._id;
      }) === -1
    ) {
      setEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeToTalkUsers.length]);

  useEffect(() => {
    if (user) {
      const timeoutId = setTimeout(async () => {
        try {
          const _user = (await getUserPublicProfile(user._id)).data;
          if (_user?.userState && _user.userState?.status === "free to talk") {
            setEnabled(true);
          }
        } catch (error) {}
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [user?._id]);

  const updateState = async (value) => {
    try {
      if (!user?.token) {
        showLoginModal(dispatch);
      } else {
        setEnabled(value);
        const status = value ? "free to talk" : null;
        await updateUserState(user?.token, status, dispatch);
      }
    } catch (error) {
      const toggleValue =
        user?.userState?.status === "free to talk" ? true : false;
      setEnabled(toggleValue);
    }
  };
  return (
    <div className="bg-white dark:bg-darkNight absolute bottom-0 -ml-4 border-t border-frescoWhite dark:border-matteGray p-4 z-50 md:z-auto">
      <div className="flex  items-center">
        <div>
          <p className="text-darkNight dark:text-frescoWhite font-ubuntu font-bold">
            Free To Talk
          </p>
          <p className="text-sm text-darkGray font-ubuntu pr-3 md:pr-0">
            Let the community know you’re open to chat.
          </p>
        </div>
        <div>
          <Switch
            checked={enabled}
            onChange={updateState}
            className={`${
              enabled ? "bg-primary" : "bg-gray-200"
            } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
          >
            <span
              className={`${
                enabled ? "translate-x-6" : "translate-x-1"
              } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default FreeToTalk;
