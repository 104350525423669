import { DefaultEventsMap } from "@socket.io/component-emitter";
import * as io from "socket.io-client";
import { Promotion } from "../components/VibehutDataProvider";

export interface Globals {
  socket: io.Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  isSocketConnected: boolean;
  roomId: string;
  eventId: string;
  chats: any[];
  boostedCallData: any;
  //socket event:
  onChatReactionUpdate: (data: any) => void;
  onRoomNameData: ({
    roomName,
    isPasswordProtected,
    isPasswordVerified,
    isWaveRoom,
  }: any) => void;
  onChatStart: ({
    user,
    token,
    connectionWithUser,
    isGroupCall,
    call,
  }: any) => void;
  onUpdateCall: ({ queue_room }: any) => void;
  onRoomPromotion: (data: Promotion) => void;
  onChatEnd: () => void;
  onChatMessage: (data: any) => void;
  onDeletedMessage: (data: any) => void;
  onRecordingConcent: (consent: boolean) => void;
  onIsRecording: (isRecording: boolean) => void;
  onUserKickOut: () => void;
  onInitiateCall: (data: any) => void;
  helloServer: (p_data: any) => void;
  showModal: (
    title: string,
    content: JSX.Element,
    isClosable?: boolean,
  ) => void;
  hideModal: () => void;
  onJoinRoomCallback: (p_data: any) => void;
  showEmptyScreen: boolean;
  guestUserDetails: (user: any) => void;
  systemSettings: any;
  user: any;
  onGuestUserJoinCallRequest: (
    guestUsername: string,
    guestUserId: string,
    profilePicture: string,
  ) => void;
  onIsRequestAcceptByHost: (isAllowed: boolean) => void;
  onMuteUser: (p_data: any) => void;
  onSendReactionInCall: (p_data: any) => void;
  onChatIdUpdate: (data: any) => void;
  onChatReactionAdded: (data: any) => void;
  onSaveMessageToRoom: (data: any) => void;
  reconnectBackendSocket: () => void;
  onCallLiveToggle: (data: any) => void;
  closeWindowNow: () => void;
}

export const globals: Globals = {
  socket: undefined,
  isSocketConnected: false,
  roomId: "",
  eventId: "",
  chats: [],
  onChatReactionUpdate: (data: any) => {},
  onRoomNameData: ({
    roomName,
    isPasswordProtected,
    isPasswordVerified,
    isWaveRoom,
  }: any) => {},
  onChatStart: ({
    user,
    token,
    connectionWithUser,
    isGroupCall,
    call,
  }: any) => {},
  onUpdateCall: ({ queue_room }: any) => {},
  onRoomPromotion: (data: Promotion) => {},
  onChatEnd: () => {},
  onChatMessage: (data: any) => {},
  onDeletedMessage: (data: any) => {},
  onRecordingConcent: (consent: boolean) => {},
  showEmptyScreen: false,
  systemSettings: {},
  boostedCallData: {},
  user: {},
  onIsRecording: (isRecording: boolean) => {},
  onUserKickOut: () => {},
  onInitiateCall: (data: any) => {},
  helloServer: (p_data: any) => {},
  showModal: (title, content) => {},
  hideModal: () => {},
  onJoinRoomCallback: (p_data: any) => {},
  guestUserDetails: (user: any) => {},
  onGuestUserJoinCallRequest: (
    guestUsername: string,
    guestUserId: string,
    profilePicture: string,
  ) => {},
  onIsRequestAcceptByHost: (isAllowed: boolean) => {},
  onMuteUser: (p_data: any) => {},
  onSendReactionInCall: (p_data: any) => {},
  onChatIdUpdate: (data: any) => {},
  onChatReactionAdded: (data: any) => {},
  onSaveMessageToRoom: (data: any) => {},
  reconnectBackendSocket: () => {},
  onCallLiveToggle: () => {},
  closeWindowNow: () => {
    const isClosed = window.close();

    if (isClosed === undefined) {
      window.location.search = "";
      window.location.pathname = "/";
    }
  },
};
