import * as actionTypes from "../constants";
import * as userService from "../services/userService";
import { getAuthUserData } from "./authActions";

export function getRecentCalls(token, dispatch, loadMore, page) {
  dispatch({
    type: actionTypes.GET_RECENT_CALLS_REQUEST,
    loadMore: loadMore,
  });
  return userService
    .getRecentCalls(page, token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_RECENT_CALLS_SUCCESS,
        payload: {
          recentCalls: res.data,
          loadMore: loadMore,
          page: page,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getCallStreak(token, dispatch, timezone, streakMode) {
  dispatch({
    type: actionTypes.GET_CALL_STREAK_REQUEST,
  });
  return userService
    .getCallStreak(token, timezone, streakMode)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_CALL_STREAK_SUCCESS,
        payload: {
          callStreak: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function updateMetamskEns(token, ens, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_USER_ENS_REQUEST,
  });
  return userService
    .updateMetamskEns(ens, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_USER_ENS_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function updateProfile(profile, token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_USER_PROFILE_REQUEST,
  });
  return userService
    .updateProfile(profile, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PROFILE_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PROFILE_FAILURE,
      });
      throw err;
    });
}

export function getOnCallUsers(dispatch) {
  dispatch({
    type: actionTypes.GET_ON_CALL_USERS_REQUEST,
  });
  return userService
    .getOnCallUsers()
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ON_CALL_USERS_SUCCESS,
        payload: {
          onCallUsers: res.data.users,
          onCallUsersCount: res.data.count,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getLastActiveUsers(dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_USER_CALL_ACTIVITY_REQUEST,
    loadMore: loadMore,
  });
  return userService
    .getLastActiveUsers(offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_USER_CALL_ACTIVITY_SUCCESS,
        payload: {
          users: res.data.users,
          loadMore: loadMore,
          offset: offset,
          lastActiveUsersCount: res.data.count,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function updateUserState(token, status, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_USER_STATE_REQUEST,
  });
  return userService
    .updateUserState(token, status)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_USER_STATE_SUCCESS,
        payload: {
          user: res.data.user,
        },
      });
    })
    .then(() => {
      getAuthUserData(token, dispatch);
    })
    .catch((err) => {
      throw err;
    });
}

export function getSidebarCalls(token, dispatch) {
  dispatch({
    type: actionTypes.GET_SIDEBAR_RECENT_CALLS_REQUEST,
  });
  return userService
    .getSidebarCalls(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SIDEBAR_RECENT_CALLS_SUCCESS,
        payload: {
          sidebarRecentCalls: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function updateAppSettings(token, appSettings, dispatch) {
  return userService
    .updateAppSettings(appSettings, token)
    .then(() => {
      getAuthUserData(token, dispatch);
    })
    .catch((err) => {
      throw err;
    });
}

export function getRecentRecordedCalls(token, dispatch, loadMore, page) {
  dispatch({
    type: actionTypes.GET_RECENT_CALLS_REQUEST,
    loadMore: loadMore,
  });
  return userService
    .getRecentRecordedCalls(page, token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_RECENT_CALLS_SUCCESS,
        payload: {
          recentCalls: res.data,
          loadMore: loadMore,
          page: page,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getThanksCalls(token, dispatch, loadMore, page) {
  dispatch({
    type: actionTypes.GET_RECENT_CALLS_REQUEST,
    loadMore: loadMore,
  });
  return userService
    .getThanksCalls(page, token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_RECENT_CALLS_SUCCESS,
        payload: {
          recentCalls: res.data,
          loadMore: loadMore,
          page: page,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}
