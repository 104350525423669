import * as actionTypes from "../constants";

const initialState = {
  hasAccess: false,
  isFetching: false,
};

function eventSubscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasAccess: action.payload,
      };
    case actionTypes.GET_PREMIUM_EVENT_CREATOR_ACCESS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}

export default eventSubscriptionReducer;
