import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

function Empty({
  Image,
  title,
  description,
  showButton = true,
  customPadding = "pt-20",
  descTextSize = "text-base",
}) {
  return (
    <div>
      <div
        className={`flex justify-center flex-col items-center  ${customPadding} `}
      >
        {!!Image && <div className="pl-5">{Image}</div>}
        <p className="text-darkNight dark:text-white text-lg font-bold font-ubuntu pt-2">
          {title}
        </p>
        <p
          className={`text-darkGray dark:text-primaryGray  font-normal font-ubuntu pt-2 text-center ${descTextSize} `}
        >
          {description}
        </p>
        {showButton && (
          <Link to="/rooms">
            <div className="pt-6">
              <Button variant="vibhut-primary" size="vibhut-lg">
                View Rooms
              </Button>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default Empty;
