import React from 'react';

function SendInActive() {
  return (
    <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 26C20.6272 26 26 20.6272 26 14C26 7.37277 20.6272 2 14 2C7.37277 2 2 7.37277 2 14C2 20.6272 7.37277 26 14 26Z"
        fill="#7A7A7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2105 11.8284C19.2105 11.8284 14.7769 7.38434 14.7398 7.34158C14.5595 7.13276 14.296 7 14 7C13.6916 7 13.4188 7.14469 13.2384 7.36893C13.1999 7.41716 8.78949 11.8284 8.78949 11.8284C8.4035 12.2167 8.4035 12.8467 8.78949 13.2345C9.17547 13.6229 9.80164 13.6229 10.1871 13.2345L13.0116 10.3935V20.0006C13.0116 20.5525 13.4544 21 14 21C14.5461 21 14.9884 20.5525 14.9884 20.0006V10.3935L17.8129 13.2345C18.1984 13.6229 18.8245 13.6229 19.2105 13.2345C19.5965 12.8467 19.5965 12.2167 19.2105 11.8284Z"
        fill="#474747"
      />
    </svg>
  );
}

export default SendInActive;
