import * as actionTypes from "../constants";

const initialState = {
  publishVideos: [],
  total: 0,
  isFetching: false,
  error: null,
  currentCallId: null,
  isMyFetching: false,
  myPublishVideos: [],
  currentVideoPlayingId: null,
  isUpdating: false,
  myVideosCount: [],
};

function publishStoreReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_PUBLISH_VIDEO_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_ALL_PUBLISH_VIDEO_SUCCESS: {
      return {
        ...state,
        publishVideos:
          action.payload.page === 1
            ? action.payload.data
            : [...state.publishVideos, ...action.payload.data],
        total: action.payload.count,
        isFetching: false,
      };
    }

    case actionTypes.GET_ALL_PUBLISH_VIDEO_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.GET_MY_PUBLISH_VIDEO_REQUEST:
      return { ...state, isMyFetching: true };

    case actionTypes.GET_MY_PUBLISH_VIDEO_SUCCESS: {
      return {
        ...state,
        myPublishVideos: action.payload,
        isMyFetching: false,
      };
    }

    case actionTypes.GET_MY_PUBLISH_VIDEO_FAILURE:
      return { ...state, isMyFetching: false };
    case actionTypes.PUBLISH_VIDEO_REQUEST:
      return { ...state, isFetching: true, currentCallId: action.payload };

    case actionTypes.PUBLISH_VIDEO_SUCCESS: {
      return {
        ...state,
        publishVideos: [...state.publishVideos, action.payload.video],
        isFetching: false,
        currentCallId: null,
      };
    }

    case actionTypes.PUBLISH_VIDEO_FAILURE:
      return { ...state, isFetching: false, currentCallId: null };

    case actionTypes.UPDATE_VIDEO_REQUEST:
      return { ...state, isUpdating: true };

    case actionTypes.UPDATE_VIDEO_SUCCESS: {
      const { publishVideos } = state;
      const index = publishVideos.findIndex(
        (video) => video._id === action.payload.video._id,
      );
      publishVideos[index] = {
        ...action.payload.video,
        videoUrl: publishVideos[index].videoUrl,
      };
      return {
        ...state,
        publishVideos: publishVideos,
        isUpdating: false,
      };
    }

    case actionTypes.UPDATE_VIDEO_FAILURE:
      return { ...state, isUpdating: false };

    case actionTypes.UPDATE_VIDEO_STATUS_REQUEST:
      return { ...state, isFetching: true, currentCallId: action.payload };

    case actionTypes.UPDATE_VIDEO_STATUS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        currentCallId: null,
      };
    }

    case actionTypes.UPDATE_VIDEO_STATUS_FAILURE:
      return { ...state, isFetching: false, currentCallId: null };

    case actionTypes.SET_CURRENT_PLAYING_VIDEO:
      return { ...state, currentVideoPlayingId: action.payload.videoId };
    case actionTypes.GET_MY_EXTERNAL_VIDEOS_COUNT_REQUEST:
      return {
        ...state,
      };
    case actionTypes.GET_MY_EXTERNAL_VIDEOS_COUNT_SUCCESS:
      return {
        ...state,
        myVideosCount: action.payload.myVideosCount,
      };
    case actionTypes.GET_MY_EXTERNAL_VIDEOS_COUNT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default publishStoreReducer;
