import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import ScreenShareOn from '../../../VibehutUI/Icons/ScreenShareOn';
import ScreenShareOffV2 from '../../../VibehutUI/Icons/ScreenShareOffV2';
import useAuthUser from '../../../hooks/useAuthUser/useAuthUser';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const { authUser } = useAuthUser();
  const screenShareParticipant = useScreenShareParticipant();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;
  const isAuthUserSharing = authUser._id === screenShareParticipant?.identity;

  let tooltipMessage = '';

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (isAuthUserSharing) {
    tooltipMessage = STOP_SCREEN_SHARE_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  const handleToggleScreenShare = () => {
    if (!disableScreenShareButton) {
      toggleScreenShare();
    }
  };

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled && !isAuthUserSharing ? 'not-allowed' : 'pointer' }}
    >
      <div
        onClick={handleToggleScreenShare}
        className={`w-10 h-10 flex items-center justify-center rounded-full cursor-pointer ${
          isSharingScreen ? 'cloutGradient' : 'bg-gray-700'
        } `}
      >
        {isSharingScreen ? <ScreenShareOn /> : <ScreenShareOffV2 />}
      </div>
    </Tooltip>
  );
}
