import React from "react";

function SubscriberApproval({
  ratio = 16,
  isInline = false,
  marginTop = "mt-0",
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isInline ? "inline" : ""} ${marginTop}`}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5303 3.53033C14.8232 3.23744 14.8232 2.76256 14.5303 2.46967C14.2374 2.17678 13.7626 2.17678 13.4697 2.46967L11 4.93934L10.5303 4.46967C10.2374 4.17678 9.76256 4.17678 9.46967 4.46967C9.17678 4.76256 9.17678 5.23744 9.46967 5.53033L10.4697 6.53033C10.7626 6.82322 11.2374 6.82322 11.5303 6.53033L14.5303 3.53033Z"
        fill="#B5B5B5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.73521 5.81676C7.73521 6.95819 6.7727 7.88351 5.58538 7.88351C4.39806 7.88351 3.43555 6.95819 3.43555 5.81676C3.43555 4.67532 4.39806 3.75 5.58538 3.75C6.7727 3.75 7.73521 4.67532 7.73521 5.81676ZM3.68134 10.0318C2.06119 10.0318 0.747803 11.3451 0.747803 12.9653V14.5197C0.747803 14.9247 1.07615 15.253 1.48119 15.253H9.51894C9.92397 15.253 10.2523 14.9247 10.2523 14.5197V12.9653C10.2523 11.3451 8.93893 10.0318 7.31878 10.0318H3.68134Z"
        fill="#B5B5B5"
      />
    </svg>
  );
}

export default SubscriberApproval;
