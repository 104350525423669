import React from "react";

function LoadingSkelaton() {
  return (
    <div className="w-full flex space-x-4 mt-4">
      <div className="w-1/2">
        <div className="rounded-xl w-full animate-pulse bg-frescoWhite h-36"></div>
        <p className="rounded-xl w-28 animate-pulse bg-frescoWhite h-6 mt-2"></p>
        <div className="rounded-xl mt-4 w-full animate-pulse bg-frescoWhite h-48"></div>
      </div>
      <div className="w-1/2">
        <div className="rounded-xl animate-pulse bg-frescoWhite  h-52"></div>
        <p className="rounded-xl w-28 animate-pulse bg-frescoWhite h-6 mt-2"></p>
        <div className="rounded-xl mt-4 w-full animate-pulse bg-frescoWhite h-32"></div>
      </div>
    </div>
  );
}

export default LoadingSkelaton;
