import React from "react";

function GeneralRoom({ width = 28, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8V20C28 24.4183 24.4183 28 20 28H8C3.58172 28 0 24.4183 0 20V8Z"
        fill="#716DFF"
      />
      <g clipPath="url(#clip0_3045_10945)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6458 14.161L20.2857 13.8146V19.7594C20.2857 20.3145 20.1002 20.8682 19.7392 21.2946C19.3748 21.7251 18.8492 21.9995 18.2666 21.9995H9.73308C9.15049 21.9995 8.62487 21.7251 8.26048 21.2946C7.8995 20.8682 7.71402 20.3145 7.71402 19.7594V13.8152L7.35447 14.161C7.036 14.4672 6.52958 14.4573 6.22334 14.1388C5.91711 13.8203 5.92702 13.3139 6.24549 13.0077L13.1965 6.32371C13.6453 5.8921 14.355 5.8921 14.8038 6.32371L21.7548 13.0077C22.0733 13.3139 22.0832 13.8203 21.7769 14.1388C21.4707 14.4573 20.9643 14.4672 20.6458 14.161Z"
          fill="white"
        />
        <rect
          x="13.9998"
          y="14.8008"
          width="2.39994"
          height="2.39994"
          rx="0.35949"
          fill="#716DFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3045_10945">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GeneralRoom;
