import axios from "../utils/axios";

export function getNotifications(token, offset, isInstant) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification?limit=15&offset=${offset}&isInstant=${isInstant}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function countUnSeenNotifications(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/count-notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getNotificationSettings(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/get-notification-settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateInstantNotifications(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/notification/update-instant-notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
