import React from "react";

function Watch() {
  return (
    <svg
      width="28"
      height="28"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0002 14.0001C23.0002 18.9707 18.9707 23.0002 14.0001 23.0002C9.02947 23.0002 5 18.9707 5 14.0001C5 9.02947 9.02947 5 14.0001 5C18.9707 5 23.0002 9.02947 23.0002 14.0001Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 10L18.0003 14L12.0002 18.0001V10Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Watch;
