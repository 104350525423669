import React, { Suspense, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { mainLayoutRoutes, authLayoutRoutes, subDomainRoutes } from "./index";
import MainLayout from "../Layouts/MainLayout";
import AuthLayout from "../Layouts/AuthLayout";
import InstantNotification from "../components/InstantNotification";
import globals from "../globals";
import VibehutModal from "../components/VibehutModal";
import PaymentStatus from "../VibehutUI/PaymentStatus";
import { StoreContext } from "../Context/StoreContext";
import { getAuthUserData } from "../services/authService";
import * as actionTypes from "../constants";

const childRoutes = (Layout, routes) =>
  routes.map(
    ({ component: Component, wrapper, guard, children, path }, index) => {
      const Guard = guard || React.Fragment;
      const Wrapper = wrapper || React.Fragment;

      return children ? (
        children.map((element, index) => {
          const Guard = element.guard || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props) => (
                <Guard>
                  <Layout>
                    <Suspense
                      fallback={
                        <div className="flex flex-col items-center justify-center mt-12">
                          {/* <Loader /> */}
                        </div>
                      }
                    >
                      <Wrapper>
                        <element.component {...props} {...element.props} />
                      </Wrapper>
                    </Suspense>
                  </Layout>
                </Guard>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Guard>
              <Layout>
                <Suspense
                  fallback={
                    <div className="flex flex-col items-center justify-center mt-12">
                      {/* <Loader /> */}
                    </div>
                  }
                >
                  <Component {...props} />
                </Suspense>
              </Layout>
            </Guard>
          )}
        />
      ) : null;
    },
  );

const defaultModalConfig = {
  showModal: false,
  canClose: true,
  title: "",
  content: (
    <div>
      <span></span>
    </div>
  ),
  isChatModal: false,
};

const Routes = () => {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth,
    user,
    persona: { formState },
  } = state;
  const [isPaymentStatusOpen, setIsPaymentStatusOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState(defaultModalConfig);
  const onModalClose = () => {
    if (modalConfig.isChatModal) {
      dispatch({
        type: actionTypes.GET_CHAT_ROOM_SUCCESS,
        payload: {
          chatRoom: undefined,
        },
      });
      dispatch({
        type: "SET_REQUEST_EVENT_CHAT_MODAL",
        payload: null,
      });
    }
    dispatch({
      type: actionTypes.UPDATE_FORM_DATA_STATE,
      payload: { ...formState.data, name: "" },
    });
    setModalConfig({
      ...modalConfig,
      showModal: false,
    });
  };

  React.useEffect(() => {
    if (user?.isPaymentStatus !== undefined) {
      if (user?.isPaymentStatus === "success") {
        getAuthUserData(auth?.user?.token, dispatch);
      }
      setIsPaymentStatusOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isPaymentStatus]);

  globals.closePaymentStatusModal = () => {
    setIsPaymentStatusOpen(false);
  };

  globals.showModal = (
    title,
    content,
    canClose = true,
    isChatModal = false,
  ) => {
    dispatch({
      type: actionTypes.SET_RSVP_EVENT_ERROR_MESSAGE,
      payload: {
        message: "",
      },
    });
    setModalConfig({
      title,
      content,
      showModal: true,
      canClose,
      isChatModal,
    });
  };

  globals.hideModal = () => onModalClose();

  return (
    <Router>
      <PaymentStatus
        status={user?.isPaymentStatus}
        isOpen={isPaymentStatusOpen}
        setIsOpen={setIsPaymentStatusOpen}
        message={user?.paymentMessage}
      />
      <Switch>
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {childRoutes(MainLayout, mainLayoutRoutes)}
        <Route path="/*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <InstantNotification />
      <VibehutModal
        isOpen={modalConfig.showModal}
        onClose={onModalClose}
        content={modalConfig.content}
        title={modalConfig.title}
        canClose={modalConfig.canClose}
        isChatModal={modalConfig.isChatModal}
      />
    </Router>
  );
};

export const NewsRoutes = () => {
  return (
    <Router>
      <Switch>
        {childRoutes(AuthLayout, subDomainRoutes)}
        <Route path="/*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
