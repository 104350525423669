import * as actionTypes from "../constants";

function userReducer(state, action) {
  switch (action.type) {
    case actionTypes.GET_RECENT_CALLS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return {
        ...state,
        isRecentCallFetching: true,
      };

    case actionTypes.GET_RECENT_CALLS_SUCCESS: {
      const pg =
        action.payload.recentCalls.length >= 10
          ? action.payload.page + 1
          : action.payload.page;
      if (action.payload.loadMore) {
        return {
          ...state,
          recentCalls: [...state.recentCalls, ...action.payload.recentCalls],
          isMoreFetching: false,
          page: pg,
          isMore: action.payload.recentCalls.length >= 10 ? true : false,
        };
      } else {
        return {
          ...state,
          recentCalls: action.payload.recentCalls,
          isRecentCallFetching: false,
          page: pg,
          isMore: action.payload.recentCalls.length >= 10 ? true : false,
        };
      }
      // return {
      //   ...state,
      //   recentCalls: action.payload.recentCalls,
      //   isFetching: false,
      // };
    }

    case actionTypes.GET_RECENT_CALLS_FAILURE:
      return { ...state, isRecentCallFetching: false };

    case actionTypes.GET_CALL_STREAK_REQUEST:
      return {
        ...state,
        isCallStreakFetching: true,
      };

    case actionTypes.GET_CALL_STREAK_SUCCESS: {
      return {
        ...state,
        callStreak: action.payload.callStreak,
        isCallStreakFetching: false,
      };
    }

    case actionTypes.GET_CALL_STREAK_FAILURE:
      return { ...state, isCallStreakFetching: false };

    // case actionTypes.GET_ON_CALL_USERS_REQUEST:
    //   return { ...state, isFetching: true };

    // case actionTypes.GET_ON_CALL_USERS_SUCCESS: {
    //   return {
    //     ...state,
    //     onCallUsers: action.payload.onCallUsers,
    //     onCallUsersCount: action.payload.onCallUsersCount,
    //     isFetching: false,
    //   };
    // }

    // case actionTypes.GET_ON_CALL_USERS_FAILURE:
    //   return { ...state, isFetching: false };

    // case actionTypes.GET_LAST_ACTIVE_USERS_REQUEST:
    //   return { ...state, isFetching: true };

    // case actionTypes.GET_LAST_ACTIVE_USERS_SUCCESS: {
    //   return {
    //     ...state,
    //     lastActiveUsers: action.payload.lastActiveUsers,
    //     lastActiveUsersCount: action.payload.lastActiveUsersCount,
    //     isFetching: false,
    //   };
    // }

    // case actionTypes.GET_LAST_ACTIVE_USERS_FAILURE:
    //   return { ...state, isFetching: false };

    case actionTypes.GET_USER_CALL_ACTIVITY_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreUsersFetching: true,
        };
      }
      return { ...state };

    case actionTypes.GET_USER_CALL_ACTIVITY_SUCCESS: {
      let onCallUsers = [];
      let sidebarUsers = [];
      let isMoreUsersFetching;
      const ofst =
        action.payload.users.length >= 15
          ? action.payload.offset + 15
          : action.payload.offset;
      if (action.payload.loadMore) {
        sidebarUsers = [...state.sidebarUsers, ...action.payload.users];
        isMoreUsersFetching = false;
      } else {
        sidebarUsers = action.payload.users;
        isMoreUsersFetching = state.isMoreUsersFetching;
      }

      onCallUsers = sidebarUsers.filter(
        (user) => user.userState.status === "oncall",
      );

      onCallUsers = onCallUsers.sort((a, b) => {
        if (
          (a.userState.status === "online" ||
            a.userState.status === "onCall") &&
          b.userState.status !== "online" &&
          b.userState.status !== "onCall"
        ) {
          return -1;
        }
        if (
          (b.userState.status === "online" ||
            b.userState.status === "onCall") &&
          a.userState.status !== "online" &&
          a.userState.status !== "onCall"
        ) {
          return 1;
        }
        return 0;
      });

      const freeToTalkUsers = sidebarUsers.filter(
        (user) => user.userState.status === "free to talk",
      );

      return {
        ...state,
        onCallUsers: onCallUsers,
        lastActiveUsers: sidebarUsers.filter(
          (user) =>
            user.userState.status !== "oncall" &&
            user.userState.status !== "free to talk",
        ),
        lastActiveUsersCount:
          action.payload.lastActiveUsersCount || state.lastActiveUsersCount,
        onCallUsersCount: onCallUsers.length,
        freeToTalkUsers: freeToTalkUsers,
        freeToTalkUsersCount: freeToTalkUsers.length,
        isFetching: false,
        isSidebarDataFetching: false,
        sidebarUsers: sidebarUsers,
        offset: ofst,
        isMoreUsers: action.payload.users.length >= 10 ? true : false,
        isMoreUsersFetching: isMoreUsersFetching,
      };
    }

    case actionTypes.GET_USER_CALL_ACTIVITY_UPDATE: {
      const sidebarUsers = state.sidebarUsers;
      sidebarUsers.map((user) => {
        const u = action.payload.users.find((us) => us._id === user._id);
        if (u) {
          user.userState = u.userState;
        } else {
          if (
            ["free to talk", "online", "oncall"].includes(user.userState.status)
          ) {
            user.userState = {
              status: "offline",
              room: null,
              roomId: null,
            };
          }
        }
        return user;
      });

      const existingUserIds = sidebarUsers.map(({ _id }) => _id);

      const newUsers = action.payload.users.filter(
        ({ _id }) => !existingUserIds.includes(_id),
      );

      sidebarUsers.push(...newUsers);

      let onCallUsers = sidebarUsers.sort((a, b) => {
        if (
          (a.userState.status === "online" ||
            a.userState.status === "onCall") &&
          b.userState.status !== "online" &&
          b.userState.status !== "onCall"
        ) {
          return -1;
        }
        if (
          (b.userState.status === "online" ||
            b.userState.status === "onCall") &&
          a.userState.status !== "online" &&
          a.userState.status !== "onCall"
        ) {
          return 1;
        }
        return 0;
      });
      onCallUsers = sidebarUsers.filter(
        (user) => user.userState.status === "oncall",
      );
      const freeToTalkUsers = sidebarUsers.filter(
        (user) => user.userState.status === "free to talk",
      );
      freeToTalkUsers.map((user) => {
        const u = action.payload.users.find((us) => us._id === user._id);
        if (u) {
          user.userState = u.userState;
        }
        return user;
      });
      let lastActiveUsers = sidebarUsers.filter(
        (user) =>
          user.userState.status !== "oncall" &&
          user.userState.status !== "free to talk",
      );

      lastActiveUsers = lastActiveUsers.map((user) => {
        const u = action.payload.users.find((us) => us._id === user._id);
        if (!u) {
          user.userState = {
            status: "offline",
            room: null,
            roomId: null,
          };
        }
        return user;
      });

      return {
        ...state,
        sidebarUsers: sidebarUsers,
        lastActiveUsers: lastActiveUsers,
        lastActiveUsersCount:
          action.payload.lastActiveUsersCount || state.lastActiveUsersCount,
        onCallUsers: onCallUsers,
        onCallUsersCount: onCallUsers.length,
        freeToTalkUsers: freeToTalkUsers,
        freeToTalkUsersCount: freeToTalkUsers.length,
      };
    }
    case actionTypes.UPDATE_USER_STATE_SUCCESS: {
      const onCallUsers = state.onCallUsers;
      const freeToTalkUsers = state.freeToTalkUsers;
      const index = onCallUsers.findIndex(
        (user) => user._id === action.payload.user._id,
      );
      if (index !== -1) {
        if (action.payload.user.userState.status) {
          freeToTalkUsers.push(onCallUsers[index]);
        }
        onCallUsers.splice(index, 1);
      }
      return {
        ...state,
        onCallUsers: onCallUsers,
        freeToTalkUsers: freeToTalkUsers,
      };
    }

    case actionTypes.UPDATE_RECENT_CALL_LIST: {
      const recentCalls = state.recentCalls;
      if (!recentCalls.length) {
        return state;
      }
      const index = recentCalls.findIndex(
        (call) => call._id === action.payload.call._id,
      );
      if (index === -1) {
        return state;
      }

      const users = recentCalls[index].users;
      const room = recentCalls[index].room;

      recentCalls[index] = { ...action.payload.call, users, room };
      return {
        ...state,
        recentCalls: recentCalls,
      };
    }
    case actionTypes.UPDATE_STATUS_BY_CALL_ID: {
      const recentCalls = state.recentCalls;
      const index = recentCalls.findIndex(
        (call) => call._id === action.payload.callId,
      );
      if (index === -1) {
        return state;
      }
      if (action.payload.type === "horizontal") {
        const twilioRecordingComposition = {
          status: action.payload.status,
        };
        recentCalls[index] = {
          ...recentCalls[index],
          twilioRecordingComposition,
        };
      }
      if (action.payload.type === "vertical") {
        const twilioVerticalRecordingComposition = {
          status: action.payload.status,
        };
        recentCalls[index] = {
          ...recentCalls[index],
          twilioVerticalRecordingComposition,
        };
      }
      if (action.payload.type === "audio") {
        const twilioAudioRecordingComposition = {
          status: action.payload.status,
        };
        recentCalls[index] = {
          ...recentCalls[index],
          twilioAudioRecordingComposition,
        };
      }
      return {
        ...state,
        recentCalls: recentCalls,
      };
    }
    case actionTypes.UPDATE_CALL_STATUS_BY_CALL_ID: {
      const recentCalls = state.recentCalls;
      const index = recentCalls.findIndex(
        (call) => call._id === action.payload.callId,
      );
      if (index === -1) {
        return state;
      }
      const audioTranscript = {
        status: action.payload.status,
        text: action.payload.text,
      };
      recentCalls[index] = {
        ...recentCalls[index],
        audioTranscript,
      };
      return {
        ...state,
        recentCalls: recentCalls,
      };
    }
    case actionTypes.PUBLISH_VIDEO_STATUS: {
      const recentCalls = state.recentCalls;
      const index = recentCalls.findIndex(
        (call) => call._id === action.payload.video.callId,
      );
      if (index === -1) {
        return state;
      }
      recentCalls[index].publishVideo = action.payload.video;
      return {
        ...state,
        recentCalls: recentCalls,
      };
    }
    case actionTypes.UPDATE_USER_CALL_ACTIVITY_SUCCESS: {
      const { onCallUsers } = state;

      const index = onCallUsers.findIndex(
        (user) => user._id === action.payload?._id,
      );
      if (index === -1) {
        const newUser = {
          _id: action.payload._id,
          bitclout_data: {
            provider: action.payload.provider,
            _id: action.payload._id,
            username: action.payload.username,
            bitclout_publickey: action.payload.bitclout_publickey,
            description: action.payload.description,
            profile_picture: action.payload.profile_picture,
            investorCount: action.payload.investorCount,
          },
          provider: action.payload.provider,
          room: {
            name: action.payload.userState.room,
          },
          userState: action.payload.userState,
          last_seen: action.payload.last_seen,
        };
        return {
          ...state,
          onCallUsers: [newUser, ...onCallUsers],
          lastActiveUsersCount: onCallUsers.filter(
            (user) => user.userState.status !== "oncall",
          ).length,
          onCallUsersCount: onCallUsers.filter(
            (user) => user.userState.status === "oncall",
          ).length,
          isFetching: false,
        };
      }
      const onCallUpdatedUsers = onCallUsers.filter(
        (user) => user._id !== action.payload._id,
      );
      onCallUsers[index].userState = action.payload.userState;
      const updatedUser = onCallUsers[index];
      let updatedSidebarUsers = [updatedUser, ...onCallUpdatedUsers];
      //sort by online
      updatedSidebarUsers = updatedSidebarUsers.sort((a, b) => {
        if (
          (a.userState.status === "online" ||
            a.userState.status === "onCall") &&
          b.userState.status !== "online" &&
          b.userState.status !== "onCall"
        ) {
          return -1;
        }
        if (
          (b.userState.status === "online" ||
            b.userState.status === "onCall") &&
          a.userState.status !== "online" &&
          a.userState.status !== "onCall"
        ) {
          return 1;
        }
        return 0;
      });

      return {
        ...state,
        onCallUsers: updatedSidebarUsers,
        lastActiveUsersCount: updatedSidebarUsers.filter(
          (user) => user.userState.status !== "oncall",
        ).length,
        onCallUsersCount: updatedSidebarUsers.filter(
          (user) => user.userState.status === "oncall",
        ).length,
        isFetching: false,
      };
    }
    case actionTypes.GET_SIDEBAR_RECENT_CALLS_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_SIDEBAR_RECENT_CALLS_SUCCESS: {
      return {
        ...state,
        sidebarRecentCalls: action.payload.sidebarRecentCalls,
        isFetching: false,
      };
    }

    case actionTypes.UPDATE_ISPAYMENT_STATUS: {
      return {
        ...state,
        isPaymentStatus: action.payload.status,
        paymentMessage: action.payload.message,
      };
    }

    case actionTypes.UPDATE_ACCOUNT_CONNECTION_STATUS: {
      return {
        ...state,
        isAccountConnStatus: action.payload,
      };
    }

    case actionTypes.GET_SIDEBAR_RECENT_CALLS_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.UPDATE_CALL_COMPLIMENTS: {
      return {
        ...state,
        recentCalls: state.recentCalls.map((call) => {
          if (call._id === action.payload.callId) {
            return {
              ...call,
              compliments: (call.compliments || 0) + 1,
            };
          }
          return call;
        }),
      };
    }

    case actionTypes.UPDATE_USER_GAMIFICATION: {
      const _gamification = action.payload;
      const countTrueValues = Object.values(_gamification).filter(
        (value) => value === true,
      ).length;
      const gamification = {
        data: _gamification,
        completed: countTrueValues,
        total: 16,
      };
      return {
        ...state,
        gamification,
      };
    }

    default:
      return state;
  }
}

export default userReducer;
