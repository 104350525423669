import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import Avatar from "../../VibehutUI/Avatar";
import Button from "../../VibehutUI/Button";
import { StoreContext } from "../../Context/StoreContext";
import * as actionTypes from "../../constants";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import globals from "../../globals";
import WaveButton from "../../VibehutUI/WaveButton";
import { videoAppUrl } from "../../utils/axios";
import { renderPostText } from "../../utils/utils";
import { openEventSubscriptionModal } from "../../actions/eventModalActions";
// import { checkEventSubscription } from "../../services/eventSubscriptionService";
import { checkRoomHasLiveEventAndUserAccess } from "../../services/rooomService";
import useIsPWA from "../../hooks/usePWA";
import Close from "../../VibehutUI/Icons/Close";

function InstantNotificationCard({ item: notification, declineCall }) {
  const { state, dispatch } = useContext(StoreContext);
  const { auth } = state;

  function playSound() {
    if (notification.type === "chat" || notification.type === "wave") {
      if (notification.type === "chat" && auth.user?.appSettings?.sound?.chat) {
        const audio = new Audio("/notification.wav");
        audio.play();
      }
      if (notification.type === "wave" && auth.user?.appSettings?.sound?.wave) {
        const audio = new Audio("/notification.wav");
        audio.play();
      }
    } else {
      const audio = new Audio("/notification.wav");
      audio.play();
    }
  }

  useEffect(() => {
    playSound();
  }, [notification]);

  const renderInstantNotificationIcon = (notificationObject) => {
    switch (notification.type) {
      case "composition_created":
        if (notificationObject.notification?.roomsMentioned.length > 0) {
          return (
            <Avatar
              src={
                notificationObject.notification?.roomsMentioned[0].cover.urls
                  .full || ""
              }
              username={
                notificationObject.notification?.roomsMentioned[0].name || ""
              }
              size="sm-2"
            />
          );
        }
        return <></>;

      default:
        return (
          <Avatar
            src={
              notification?.from
                ? notification.from.cover
                : notification.user.profile_picture
            }
            username={
              notification?.from
                ? notification?.from.name
                : notification.user.username
            }
            size="sm-2"
            userState={notification.user.userState}
          />
        );
    }
  };

  return (
    <motion.li
      positionTransition
      initial={{ opacity: 0, x: 100, translateX: 100 }}
      animate={{ opacity: 1, x: 0, translateX: 0 }}
      exit={{
        opacity: 0,
        x: 100,
        translateX: 100,
        transition: { duration: 0.2 },
      }}
    >
      <div className="w-343px justify-center p-4  rounded-2xl bg-componentColor text-white relative">
        <div>
          <div
            className="absolute right-4 top-3 cursor-pointer"
            onClick={() => {
              dispatch({
                type: actionTypes.REMOVE_INSTANT_NOTIFICATION,
                payload: {
                  id: notification?.id,
                },
              });
            }}
          >
            <Close />
          </div>
          <div className="flex flex-row items-center gap-4">
            {renderInstantNotificationIcon(notification)}
            <InstantNotificationBody notification={notification} />
          </div>
        </div>

        <InstantNotificationButtons
          notification={notification}
          declineCall={declineCall}
        />
      </div>
    </motion.li>
  );
}

const InstantNotificationButtons = ({ notification, declineCall }) => {
  const { dispatch, state } = useContext(StoreContext);
  const [isFetching, setIsFetching] = useState(false);
  const { auth } = state;
  const isPWA = useIsPWA();
  const history = useHistory();
  const checkAccessAndRedirect = async (roomId) => {
    setIsFetching(true);
    const checkRoomAccess = await checkRoomHasLiveEventAndUserAccess(
      roomId,
      auth.user.token,
    );
    if (checkRoomAccess.data.isUserAllowed) {
      setIsFetching(false);
      if (!isPWA) {
        window.open(`${videoAppUrl}/call?roomKey=${notification.roomKey}`);
      } else {
        window.location.href = `${videoAppUrl}/call?roomKey=${notification.roomKey}`;
      }
    } else {
      setIsFetching(false);
      openEventSubscriptionModal(
        dispatch,
        checkRoomAccess.data.event,
        `There is a live event going on in this room. Please subscribe to join the event. Otherwise you can wait join the room after the event ends.`,
        "event",
      );
    }
  };

  const startCall = async () => {
    dispatch({
      type: actionTypes.SET_SINGLE_WAVE_SUCCESS,
      payload: {
        waveUser: notification.user.username,
      },
    });

    removeInstantNotification(notification.id);
    checkAccessAndRedirect(notification.roomKey);
    // history.push(`/call?roomKey=${notification.roomKey}`);
  };

  const removeInstantNotification = (id) => {
    dispatch({
      type: actionTypes.REMOVE_INSTANT_NOTIFICATION,
      payload: {
        id,
      },
    });
  };

  const openNotification = () => {
    globals.showModal(
      "Notification",
      <>
        <div className=" justify-center p-4  rounded-2xl  ">
          <div>
            <div className="flex flex-row items-center gap-4">
              <div>
                <p className="dark:text-frescoWhite">
                  {`${notification?.user?.username}  is inviting you to join a call in ${notification?.room?.name}...`}
                </p>
                <p className="text-sm  break-all text-darkGray">
                  {notification?.message}
                </p>
              </div>
            </div>
          </div>
          <div className="pt-3 flex flex-row-reverse">
            <Button
              size="vibhut-sm"
              variant="vibhut-primary"
              onClick={() => {
                globals.hideModal();
                startCall();
              }}
              disabled={isFetching}
            >
              {isFetching ? "Loading..." : "Join Call"}
            </Button>
            <div className="mr-2">
              <Button
                size="vibhut-sm"
                variant="vibhut-secondary"
                onClick={() => {
                  globals.hideModal();
                  removeInstantNotification(notification.id);
                }}
              >
                Ignore
              </Button>
            </div>
          </div>
        </div>
      </>,
    );
  };

  return (
    <div
      className={`pt-3 flex flex-row-reverse  ${
        notification.type === "decline" ? "px-0" : "px-0"
      } `}
    >
      {notification.type === "calling" || notification.type === "invitation" ? (
        notification.message && notification.message.length > 100 ? (
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={() => {
              openNotification();
            }}
          >
            Open
          </Button>
        ) : (
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={() => {
              startCall();
            }}
            disabled={isFetching}
          >
            {isFetching ? "Joining..." : "Join Call"}
          </Button>
        )
      ) : undefined}
      {notification.type === "missed" ? (
        <Button
          size="vibhut-sm"
          variant="vibhut-primary"
          onClick={() => {
            startCall();
          }}
        >
          Call Back
        </Button>
      ) : undefined}
      {notification.type === "wave" ? (
        <WaveButton
          user={notification.user}
          notficationId={notification.id}
          removeInstantNotification={removeInstantNotification}
        />
      ) : undefined}
      {notification.type === "calling" ? (
        <div className="mr-2">
          <Button
            size="vibhut-sm"
            variant="vibhut-danger"
            onClick={() =>
              declineCall(
                notification.user._id,
                notification.id,
                notification.roomKey,
              )
            }
          >
            Decline
          </Button>
        </div>
      ) : undefined}
      {notification.type === "chat" ? (
        <Button
          size="vibhut-sm"
          variant="vibhut-primary"
          onClick={() => {
            removeInstantNotification(notification.id);
            if (notification.isRoomMessage) {
              history.push(`/chat?roomId=${notification.roomKey}`);
            } else {
              history.push(`/chat?userId=${notification.user._id}`);
            }
          }}
        >
          Reply
        </Button>
      ) : undefined}
      {notification.type === "missed" ||
      notification.type === "invitation" ||
      notification.type === "wave" ||
      notification.type === "chat" ? (
        <div className="mr-2">
          <Button
            size="vibhut-sm"
            variant="vibhut-matteGray"
            onClick={() => removeInstantNotification(notification.id)}
          >
            Ignore
          </Button>
        </div>
      ) : undefined}

      {notification.type === "decline" ? (
        <Button
          size="vibhut-sm"
          variant="vibhut-matteGray"
          onClick={() => removeInstantNotification(notification.id)}
        >
          Close
        </Button>
      ) : undefined}
    </div>
  );
};

const InstantNotificationBody = ({ notification }) => {
  return (
    <>
      {notification.type === "calling" ? (
        // <p>{notification.user.username} is calling you...</p>
        <p className="instant-card">
          {renderPostText(notification.notification)}
        </p>
      ) : undefined}
      {notification.type === "missed" ? (
        <div>
          <p className="instant-card">
            {renderPostText(notification.notification)}
          </p>
          {/* <p>You just missed a call from {notification.user.username}</p> */}
          <p className="text-sm text-lightGray">
            {moment(notification.time).fromNow()}
          </p>
        </div>
      ) : undefined}
      {notification.type === "decline" ? (
        // <p>{notification.user.username} has declined your call</p>
        <p className="instant-card">
          {renderPostText(notification.notification)}
        </p>
      ) : undefined}
      {notification.type === "invitation" ? (
        <div>
          <p>
            <p className="instant-card">
              {renderPostText(notification.notification)}
            </p>
            {/* {`${notification.user.username}  is inviting you to join a call in ${notification.room.name}...`} */}
          </p>
          {/* <p className="text-sm text-lightGray">
            {notification.message.length > 100
              ? notification.message.substring(0, 100) + "..."
              : notification.message}
          </p> */}
          {/* <p className="text-sm text-lightGray">
            {`${moment(notification.time).fromNow()} · ${
              notification.room.totalUsersInRoom
            } talking`}
          </p> */}
          <p className="text-sm text-lightGray">
            {`${moment(notification.time).fromNow()}`}
          </p>
        </div>
      ) : undefined}
      {notification.type === "wave" ? (
        <p>{notification.user.username} waved to you. Wave back?</p>
      ) : undefined}
      {notification.type === "chat" ? (
        <p>
          <div>
            {/* {notification?.from ? (
              <p>{`New message from ${notification.user.username} in ${notification?.from.name}`}</p>
            ) : (
              <p>{`New message from ${notification.user.username}`}</p>
            )} */}
            <p className="instant-card">
              {renderPostText(notification.notification)}
            </p>
            {/* <p className="text-sm text-lightGray">
              {notification.message.length > 100
                ? notification.message.substring(0, 100) + "..."
                : notification.message}
            </p> */}
            <p className="text-sm text-lightGray">
              {`${moment(notification.time).fromNow()}`}
            </p>
          </div>
        </p>
      ) : undefined}
      {notification.type === "composition_created" ? (
        <p>
          <div>
            <p className="instant-card">
              {renderPostText(notification.notification)}
            </p>
            <p className="text-sm text-lightGray">
              {`${moment(notification.time).fromNow()}`}
            </p>
          </div>
        </p>
      ) : undefined}
    </>
  );
};

export default InstantNotificationCard;
