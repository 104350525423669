import axios from "../utils/axios";

export function getAllApiKeys(token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "GET",
      url: "/api-key",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createApikey(token, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "/api-key",
      data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function deleteApikey(token, apiKey) {
  return new Promise(function (resolve, reject) {
    axios({
      method: "DELETE",
      url: `/api-key/${apiKey}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
