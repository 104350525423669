import { useContext } from 'react';
import { VibehutChatContext } from '../../components/VibehutChatProvider';

export default function useVibehutChatContext() {
  const context = useContext(VibehutChatContext);
  if (!context) {
    throw new Error('useVibehutChatContext must be used within a ChatProvider');
  }
  return context;
}
