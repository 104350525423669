import Drawer from "@material-ui/core/Drawer";
import { useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Close from "../../VibehutUI/Icons/Close";
import clsx from "clsx";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import KickedUser from "../VibehutCallSidebar/KickedUser";
import Tick from "../../icons/Tick";
import Avatar from "../../VibehutUI/Avatar";
import { VibehutDataContext } from "../VibehutDataProvider";
import { joinRequestFromGuest } from "../VibehutDataProvider";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: "flex",
    width: "100%",
    height: `calc(100%)`,
    background: "#303234",
    zIndex: 10,
  },
  thumbnailContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "8px",
    overflowY: "auto",
    height: "100%",
    width: "100%",
  },
  container: {
    minHeight: "56px",
    background: "#303234",
    borderBottom: "1px solid #474747",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1em",
  },
  text: {
    color: "#F2F2F2",
    fontWeight: "bold",
  },
  closeBackgroundSelection: {
    cursor: "pointer",
    display: "flex",
    background: "transparent",
    border: "0",
    padding: "0.4em",
  },
}));

interface MemberListDialogProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

function MemberListDialog({ isOpen, setIsOpen }: MemberListDialogProps) {
  const classes = useStyles();

  const { approvalRequestFromGuest } = useContext(VibehutDataContext);
  const { roomData, requestAcceptByHost } = useVibehutData(globals.roomId);

  const liveUsers = roomData.liveUsers;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isOpen}
      transitionDuration={0}
      classes={{
        paper: classes.drawer,
      }}
    >
      <div className={clsx(classes.container)}>
        <div className={classes.text}>Members</div>
        <button
          className={classes.closeBackgroundSelection}
          onClick={() => setIsOpen(false)}
          type="button"
        >
          <Close />
        </button>
      </div>
      <div className={classes.thumbnailContainer}>
        {liveUsers.map((user) => (
          <div className="border-b border-matteGray py-1 w-full">
            <KickedUser user={user} />
          </div>
        ))}
        {roomData.isRequireApproval && (
          <div className="w-full mt-2  h-full">
            <p className="font-bold text-white ">
              Waiting to join{" "}
              {approvalRequestFromGuest.length > 0 &&
                `·${approvalRequestFromGuest.length}`}
            </p>

            <div className="h-full w-full">
              <div className="mt-1 overflow-y-auto h-full text-white">
                {approvalRequestFromGuest.length > 0 ? (
                  <div className="text-black">
                    <div className="flex flex-col gap-1 ">
                      {approvalRequestFromGuest.map(
                        (item: joinRequestFromGuest, index: number) => (
                          <div key={index} className="py-1 w-full">
                            <div className="flex justify-between items-center">
                              <div className="flex items-center gap-2">
                                <Avatar
                                  username={item.username}
                                  src={item.profilePicture}
                                  size="sm"
                                />
                                <div>
                                  <p className="max-w-28 text-frescoWhite  break-words">
                                    {item.username}
                                  </p>
                                  <p className="text-frescoWhite text-[11px] italic">
                                    Asked to join{" "}
                                    {moment(item.joinTime).fromNow()}
                                  </p>
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <span>
                                  <button
                                    className="bg-matteGray p-9px rounded-full cursor-pointer hover:bg-cvRed"
                                    onClick={() => {
                                      requestAcceptByHost(false, item);
                                    }}
                                  >
                                    <Close color="white" ratio="11" />
                                  </button>
                                </span>
                                <span className="py-[1px] flex items-end">
                                  <button
                                    onClick={() => {
                                      requestAcceptByHost(true, item);
                                    }}
                                    className="bg-primary hover:bg-primaryHover p-[7px] rounded-full cursor-pointer "
                                  >
                                    <Tick ratio="15" />
                                  </button>
                                </span>
                              </div>
                            </div>
                            <p className="text-frescoWhite text-xs ml-2 pt-1 italic">
                              Asked to join {moment(item.joinTime).fromNow()}
                            </p>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="py-4 flex justify-center items-center">
                    <span>No Waiting user present now.</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default MemberListDialog;
