import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef, useState } from 'react';
import Button from '../Button';
import Close from '../Icons/Close';
import Loader from '../Loader';
import useVibehutData from '../../hooks/useVibehutData/useVibehutData';
import { globals } from '../../globals';

interface EditProfileSocialsModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  user: any;
}

export default function EditProfileSocialsModal({ isOpen, setIsOpen, user }: EditProfileSocialsModalProps) {
  const { updateUserProfileSocials } = useVibehutData(globals.roomId);

  const cancelButtonRef = useRef(null);
  const [socialLinks, setSociaLinks] = useState({
    instagram: user.socialLinks?.instagram || '',
    linkedIn: user.socialLinks?.linkedIn || '',
    twitter: user.socialLinks?.twitter || '',
    metamask: user.socialLinks?.metamask || '',
    tiktok: user.socialLinks?.tiktok || '',
    telegram: user.socialLinks?.telegram || '',
    link: user.socialLinks?.link || '',
    facebook: user.socialLinks?.facebook || '',
  });

  const handleChange = (e: { target: { name: string; value: React.SetStateAction<string> } }) => {
    setSociaLinks({ ...socialLinks, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setIsOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-30">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu ">
                <div className="bg-white dark:bg-navyGray border-b border-frescoWhite p-4 ">
                  <div className="flex justify-between  items-center">
                    <div className="flex flex-row justify-start items-center">
                      <p className="font-medium text-lg text-black dark:text-frescoWhite">Edit Profile Socials</p>
                    </div>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>

                <div>
                  <div className="px-4 pt-4">
                    <div className="font-ubuntu pb-4">
                      <p className="text-darkNight dark:text-frescoWhite font-medium">Social Profiles</p>
                      <p className="text-darkGray text-sm">Display social links on your profile</p>
                    </div>
                    <div>
                      <div>
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Instagram</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.instagram}
                          name="instagram"
                          onChange={handleChange}
                          placeholder="Username"
                        />
                      </div>
                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Twitter</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.twitter}
                          name="twitter"
                          onChange={handleChange}
                          placeholder="Username"
                          disabled={user.provider === 'twitter'}
                        />
                      </div>
                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Metamask</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.metamask}
                          name="metamask"
                          onChange={handleChange}
                          placeholder="Wallet Address / ENS"
                        />
                      </div>
                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">LinkedIn</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.linkedIn}
                          name="linkedIn"
                          onChange={handleChange}
                          placeholder="Profile URL"
                        />
                      </div>
                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Tiktok</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.tiktok}
                          name="tiktok"
                          onChange={handleChange}
                          placeholder="Username"
                        />
                      </div>
                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Telegram</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.telegram}
                          name="telegram"
                          onChange={handleChange}
                          placeholder="Username"
                        />
                      </div>
                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Facebook</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.facebook}
                          name="facebook"
                          onChange={handleChange}
                          placeholder="Username"
                        />
                      </div>

                      <div className="pt-2">
                        <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">Link</p>
                        <input
                          type="text"
                          className="border  outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full placeholder-notifyBg text-sm"
                          value={socialLinks?.link}
                          name="link"
                          onChange={handleChange}
                          placeholder="Username"
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" px-4 py-3 flex flex-row-reverse ">
                    {false ? (
                      <Loader />
                    ) : (
                      <Button
                        onClick={() => {
                          updateUserProfileSocials(user._id, socialLinks);
                          setIsOpen(false);
                        }}
                        size="vibhut-sm"
                        variant="vibhut-primary"
                      >
                        Save
                      </Button>
                    )}

                    <div className="mr-3">
                      <Button onClick={() => setIsOpen(false)} size="vibhut-sm" variant="vibhut-secondary">
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
