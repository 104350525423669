import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import Button from "../../VibehutUI/Button";
import { StoreContext } from "../../Context/StoreContext";
import PremiumWrapper from "../PremiumWrapper";
import { openPlanModal } from "../../actions/profileModalActions";
import ConfirmationModal from "../../VibehutUI/ConfirmationModal";
import { createRoom } from "../../actions/roomAction";
import Loader from "../../VibehutUI/Loader";
import BannerPicker from "./BannerPicker";
import Back from "../../VibehutUI/Icons/Back";
import Close from "../../VibehutUI/Icons/Close";
import { getMyRoomCount } from "../../actions/roomAction";
import { getRandomPicture } from "../../services/rooomService";
import LimitedTextarea from "../../VibehutUI/LimitedTextarea";
import HidEye from "../../VibehutUI/Icons/HidEye";
import VisEye from "../../VibehutUI/Icons/VisEye";
import { uploadMedia } from "../../utils/uploadMedia";
import {
  ObjectCleaner,
  checkCreateSlugRoomPermission,
  handleCurrencyInputChange,
  isRoomSlugValid,
  userSlugLimitCheck,
} from "../../utils/utils";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import SelectBox from "../../VibehutUI/SelectBox";
import { planType, subscriptionTypeOptions } from "../../constants";
import InputBox from "../../VibehutUI/InputBox/inputBox";
import { showToast } from "../../actions/toastActions";

const slugCharactersLimit = [
  {
    name: "Individual Plan role",
    minLimit: 6,
  },
  {
    name: "Pro Plan role",
    minLimit: 4,
  },
  {
    name: "Business Plan role",
    minLimit: 2,
  },
];

export default function CreateRoom({ isOpen, setIsOpen }) {
  const { state, dispatch } = useContext(StoreContext);
  const coverImage = {
    urls: {
      raw: "https://images.unsplash.com/photo-1632633446780-a017032d265a?ixid=MnwyNjkwNTR8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MzQ4NDA4NjM&ixlib=rb-1.2.1",
      full: "https://images.unsplash.com/photo-1632633446780-a017032d265a?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNjkwNTR8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MzQ4NDA4NjM&ixlib=rb-1.2.1&q=85",
      regular:
        "https://images.unsplash.com/photo-1632633446780-a017032d265a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjkwNTR8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MzQ4NDA4NjM&ixlib=rb-1.2.1&q=80&w=1080",
      small:
        "https://images.unsplash.com/photo-1632633446780-a017032d265a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjkwNTR8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MzQ4NDA4NjM&ixlib=rb-1.2.1&q=80&w=400",
      thumb:
        "https://images.unsplash.com/photo-1632633446780-a017032d265a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjkwNTR8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MzQ4NDA4NjM&ixlib=rb-1.2.1&q=80&w=200",
    },
  };

  const {
    auth: {
      user: { token, serviceRole },
    },
    room: { isFetching, myRoomsSlugCount },
    role,
    eventCreation,
    premiumCreator,
  } = state;
  const history = useHistory();
  const cancelButtonRef = useRef(null);
  const [name, setName] = useState("");
  const [roomSlug, setRoomSlug] = useState("");
  const [cover, setCover] = useState(null);
  const [description, setDescription] = useState("");
  const [password, setPassword] = useState("");
  const [amount, setAmount] = useState(0);
  const [weeklyPassCharge, setWeeklyPassCharge] = useState(0);

  const [isPrivate, setIsPrivate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [isGroupRoom, setIsGroupRoom] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [coverOpen, setCoverOpen] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [maxUsersPerGroup, setMaxUsersPerGroup] = useState(16);
  const [isGuestAllowed, setIsGuestAllowed] = useState(false);
  const [isRequireApproval, setIsRequireApproval] = useState(false);
  const [isTestimonialEnabled, setIsTestimonialEnabled] = useState(false);
  const [totalCalls, setTotalCalls] = useState("1");
  const [isSubscriberRequireApproval, setIsSubscriberRequireApproval] =
    useState(false);
  const [isAnswersRequireForApproval, setIsAnswersRequireForApproval] =
    useState(false);
  const [isSelfOrganized, setIsSelfOrganized] = useState(true);
  const [showWeeklyPass, setShowWeeklyPass] = useState(0);
  const [isCCRoom, setIsCCORoom] = useState(false);
  const [isDAORoom, setIsDAOORoom] = useState(false);

  const [isNftCollectionRoom, setIsNftCollectionRoom] = useState(false);
  const [selfOrganizeCount, setSelfOrganizeCount] = useState(2);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  // handle validation states
  const [showPaymentErr, setShowPaymentErr] = useState("");
  const [showWeeklyPassChargeErr, setShowWeeklyPassChargeErr] = useState("");
  const [showPasswordErr, setShowPasswordErr] = useState(false);
  const [showMaxPerGroupErr, setShowMaxPerGroupErr] = useState(false);
  const [selfOrganizeCountErr, setSelfOrganizeCountErr] = useState(false);
  const [showTotalLiveCallsErr, setShowTotalLiveCallsErr] = useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [coverFile, setCoverFile] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isBreakoutRoom, setIsBreakoutRoom] = useState(false);
  const [slugError, setSlugError] = useState("");
  const [planAccessibleType, setPlanAccessibleType] = useState(planType[0]);
  const [isPlanAccessible, setIsPlanAccessible] = useState(false);
  const [isDesoPostRoom, setIsDesoPostRoom] = useState(false);
  const [desoPostRoom, setDesoPostRoom] = useState("");
  const [approvalQuestions, setApprovalQuestions] = useState(["", "", ""]);
  const [questionsErr, setQuestionsErr] = useState("");
  const [showDesoPostRoomErr, setShowDesoPostRoomErr] = useState("");
  const [isQuickJoin, setIsQuickJoin] = useState(false);

  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypeOptions[0],
  );

  const [CCDetails, setCCDetails] = useState([
    {
      username: "",
      amount: 0,
    },
  ]);
  const [DAODetails, setDAODetails] = useState([
    {
      token: "",
      amount: 0,
    },
  ]);
  const handleCCUsernameChange = (id) => (event) => {
    const { value } = event.target;
    setCCDetails((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              username: value,
            }
          : el,
      ),
    );
  };
  const handleCCAmountChange = (id) => (event) => {
    const { value } = event.target;
    if (value < 0) return;
    setCCDetails((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              amount: value,
            }
          : el,
      ),
    );
  };
  const handleDAOTokenChange = (id) => (event) => {
    const { value } = event.target;
    setDAODetails((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              token: value,
            }
          : el,
      ),
    );
  };
  const handleDAOAmountChange = (id) => (event) => {
    const { value } = event.target;
    if (value < 0) return;
    setDAODetails((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              amount: value,
            }
          : el,
      ),
    );
  };
  const removeCC = (key) => {
    const CCs = CCDetails.filter((_, index) => {
      return key !== index;
    });
    setCCDetails(CCs);
  };

  const addCCInput = () => {
    setCCDetails([
      ...CCDetails,
      {
        username: "",
        amount: 0,
      },
    ]);
  };
  const removeDAO = (key) => {
    const DAOs = DAODetails.filter((_, index) => {
      return key !== index;
    });
    setDAODetails(DAOs);
  };

  const addDAOInput = () => {
    setDAODetails([
      ...DAODetails,
      {
        token: "",
        amount: 0,
      },
    ]);
  };
  const [nftDetails, setNftDetails] = useState([
    {
      url: "",
      title: "",
    },
  ]);

  const handleNftUrlChange = (id) => (event) => {
    const { value } = event.target;
    setNftDetails((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              url: value,
            }
          : el,
      ),
    );
  };

  const handleNftTitleChange = (id) => (event) => {
    const { value } = event.target;
    setNftDetails((input) =>
      input.map((el, index) =>
        index === id
          ? {
              ...el,
              title: value,
            }
          : el,
      ),
    );
  };

  const removeNft = (key) => {
    const nfts = nftDetails.filter((_, index) => {
      return key !== index;
    });
    setNftDetails(nfts);
  };

  const addNFTInput = () => {
    setNftDetails([
      ...nftDetails,
      {
        url: "",
        title: "",
      },
    ]);
  };

  const userRole = role.roles.find((role) => role._id === serviceRole);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (!showWeeklyPass) {
      setWeeklyPassCharge(0);
    }
  }, [showWeeklyPass]);

  const handleSlugChange = (e) => {
    const slug = e.target.value.replace(/\s+/g, "-");
    setRoomSlug(slug);
    const slugValidate = isRoomSlugValid(slug, true);
    if (!slugValidate.isValid) {
      setSlugError(slugValidate.reason);
    } else {
      setSlugError("");
    }
  };

  const handleSlugLimit = (e) => {
    if (e.target.value === "") {
      return;
    }
    const slugLimitError = userSlugLimitCheck(
      e.target.value,
      userRole,
      slugCharactersLimit,
    );
    setSlugError(slugLimitError || "");
  };

  const handleDescriptionChange = (text) => {
    setDescription(text);
  };

  const handlePasswordChange = (e) => {
    if (e.target.value.length <= 16) {
      setPassword(e.target.value);
      setShowPasswordErr(false);
    }
  };

  const handleSelfOrganizeCount = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "0") {
      return;
    }
    if (e.target.value === "" || re.test(e.target.value)) {
      if (Number(e.target.value) <= 16) {
        return setSelfOrganizeCount(e.target.value);
      }
    }
    setSelfOrganizeCountErr(false);
  };

  const handleGroupCallChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "0") {
      return;
    }
    if (e.target.value === "" || re.test(e.target.value)) {
      if (Number(e.target.value) <= 16) {
        return setMaxUsersPerGroup(e.target.value);
      }
    }

    setShowMaxPerGroupErr(false);
  };
  const handleTotalCallChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "0") {
      return;
    }
    if (e.target.value === "" || re.test(e.target.value)) {
      setTotalCalls(e.target.value);
    }
    setShowTotalLiveCallsErr(false);
  };

  const hasUserPermissionForPlanRoom = useMemo(() => {
    return premiumCreator.premiumAccess.some(
      (access) => access?.name === "room:planAccessible",
    );
  }, [premiumCreator]);

  React.useEffect(() => {
    if (isOpen) {
      getRandomCover();
    }
  }, [isOpen]);

  const getRandomCover = () => {
    setCoverOpen(false);
    setCoverLoading(true);
    getRandomPicture(token)
      .then((res) => {
        setCover(res.data);
      })
      .catch((error) => {
        setCover(coverImage);
        console.log("ERROR: ", error);
      })
      .finally(() => {
        setCoverLoading(false);
      });
  };

  const handleCreateRoom = (media = undefined) => {
    if (
      roomSlug &&
      !checkCreateSlugRoomPermission(role, myRoomsSlugCount, serviceRole)
    ) {
      setOpenConfirmationModal(true);
      return;
    }
    let _maxUsersPerGroup = isGroupRoom ? maxUsersPerGroup : "0";
    if (isGroupRoom && !_maxUsersPerGroup) {
      _maxUsersPerGroup = "16";
    }
    let _totalCalls = isGroupRoom ? totalCalls : "0";
    if (isGroupRoom && !_totalCalls) {
      _totalCalls = " 1";
    }
    if (isNftCollectionRoom && nftDetails?.some((nft) => nft.url === "")) {
      return showToast(dispatch, "error", "Please fill all the fields", 2000);
    }
    if (isCCRoom && CCDetails?.some((cc) => cc.username === "")) {
      return showToast(dispatch, "error", "Please fill all the fields", 2000);
    }
    if (isDAORoom && DAODetails?.some((dao) => dao.token === "")) {
      return showToast(dispatch, "error", "Please fill all the fields", 2000);
    }
    if (isSelfOrganized) {
      if (selfOrganizeCount === "") {
        setSelfOrganizeCountErr("Please enter a valid number");
      } else if (Number(selfOrganizeCount) < 2) {
        setSelfOrganizeCountErr("Minimum two people are required");
      }
    }
    if (isPasswordProtected && password === "") {
      setShowPasswordErr(true);
    } else if (
      hasSubscription &&
      (Number.isNaN(parseInt(amount)) || Number(amount) === 0)
    ) {
      if (Number(amount) === 0) {
        setShowPaymentErr(" Minimum amount to charge is $1.00");
        setTimeout(() => {
          setShowPaymentErr("");
        }, 5000);
        return;
      } else {
        setShowPaymentErr("Please enter a valid amount");
        setTimeout(() => {
          setShowPaymentErr("");
        }, 5000);
        return;
      }
    } else if (Number.isNaN(parseInt(weeklyPassCharge))) {
      setShowWeeklyPassChargeErr("Please enter a valid amount");
      setTimeout(() => {
        setShowWeeklyPassChargeErr("");
      }, 5000);
      return;
    } else if (
      isGroupRoom &&
      (_maxUsersPerGroup === 0 || _maxUsersPerGroup > 16)
    ) {
      setShowMaxPerGroupErr(true);
    } else if (isGroupRoom && totalCalls === 0) {
      setShowTotalLiveCallsErr(true);
    } else if (
      isDesoPostRoom &&
      (!desoPostRoom?.postHashHex || !desoPostRoom?.diamondLevel)
    ) {
      setShowDesoPostRoomErr("Please enter a valid details");
    } else if (
      isAnswersRequireForApproval &&
      (approvalQuestions.some((question) => question.trim() === "") ||
        !approvalQuestions.length)
    ) {
      setQuestionsErr("Please enter all approval questions");
    } else {
      const breakoutRoomNames = [];
      if (isBreakoutRoom) {
        const num = totalCalls;
        for (let i = 1; i <= num; i++) {
          breakoutRoomNames.push({
            ["Call " + i]: "Call " + i,
          });
        }
      }
      const roomBody = {
        name: name.trim(),
        slug: roomSlug,
        description,
        cover: media?.url
          ? {
              urls: {
                raw: media.url,
                full: media.url,
                regular: media.url,
                small: media.url,
                thumb: media.url,
              },
              source: "custom",
            }
          : cover,
        is_private: isPrivate,
        isPasswordProtected,
        password,
        isGroupRoom,
        maxUsersPerGroup: _maxUsersPerGroup,
        totalLiveCalls: _totalCalls * _maxUsersPerGroup,
        totalCalls: _totalCalls,
        hasSubscription,
        subscriptionType: subscriptionType.value,
        subscriptionAmount: amount,
        isBreakoutRoom: isGroupRoom ? isBreakoutRoom : false,
        breakoutRoomNames:
          breakoutRoomNames.length > 0 ? breakoutRoomNames : undefined,
        isGuestAllowed,
        isRequireApproval,
        isTestimonialEnabled,
        isSelfOrganized,
        ...(isSelfOrganized && { selfOrganizeCount }),
        isSubscriberRequireApproval,
        isAnswersRequireForApproval,
        approvalQuestions,
        ...(weeklyPassCharge &&
          weeklyPassCharge > 0 && { weeklyPassCharge: weeklyPassCharge }),
        isNftCollectionRoom,
        ...(isNftCollectionRoom && { nftDetails: nftDetails }),
        isCCRoom,
        ...(isCCRoom && { CCDetails: CCDetails }),
        isDAORoom,
        ...(isDAORoom && { DAODetails: DAODetails }),
        ...(isPlanAccessible && { planAccessible: planAccessibleType?.value }),
        ...(isDesoPostRoom && { desoPostRoom }),
        isQuickJoin,
      };
      createRoom(ObjectCleaner(roomBody), token, dispatch)
        .then(() => {
          setIsOpen(false);
          setCover(coverImage);
          getMyRoomCount(token, dispatch);
          setName("");
          setDescription("");
          setRoomSlug("");
          setSlugError("");
          setPlanAccessibleType("");
          setNextClicked(false);
          if (history.location.pathname === "/") {
            history.push("/rooms");
          }
        })
        .catch((error) => {
          setNextClicked(false);
          if (error.message) {
            showToast(dispatch, "error", error.message, 2000);
          }
        });
    }
  };

  const handleUploadAndCreateRoom = async () => {
    if (coverFile) {
      await uploadMedia(
        coverFile,
        cover?.urls?.small,
        setIsImageUploading,
        setCover,
        setCoverFile,
        handleCreateRoom,
      );
    } else {
      await handleCreateRoom();
    }
  };

  const handleDesoPostRoomSettingChange = (e) => {
    if (e.target.name === "diamondLevel") {
      if (e.target.value === "") {
        setDesoPostRoom({
          ...desoPostRoom,
          [e.target.name]: e.target.value,
        });
        return;
      }
      const re = /^[0-9\b]+$/;
      if (e.target.value === "0" || !re.test(e.target.value)) {
        return;
      }

      setShowDesoPostRoomErr("");
    }
    setDesoPostRoom({ ...desoPostRoom, [e.target.name]: e.target.value });
  };

  const handleQuestionChange = (index, value) => {
    setQuestionsErr("");
    const newQuestions = [...approvalQuestions];
    newQuestions[index] = value;
    setApprovalQuestions(newQuestions);
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setIsOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-30">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {!nextClicked ? (
                <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-428px font-ubuntu max-h-[90vh] overflow-auto custom-scrollbar">
                  <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row justify-start items-center">
                        {coverOpen ? (
                          <span
                            className="bg-frescoWhite rounded-full p-1 cursor-pointer text-gray-600 mr-2"
                            onClick={() => {
                              setCoverOpen(false);
                            }}
                          >
                            <Back />
                          </span>
                        ) : undefined}
                        <p className="font-medium text-lg text-black dark:text-frescoWhite">
                          {coverOpen ? "Change Cover" : "Create Room"}
                        </p>
                      </div>
                      {/* {coverOpen ? (
                      <span
                        className="bg-frescoWhite rounded-full p-1 cursor-pointer text-gray-600"
                        onClick={() => {
                          setCoverOpen(false);
                        }}
                      >
                        <Back />
                      </span>
                    ) : ( */}
                      <span
                        className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                        onClick={() => {
                          setCoverOpen(false);
                          setIsOpen(false);
                        }}
                      >
                        <Close />
                      </span>
                    </div>
                  </div>
                  {coverOpen ? (
                    <BannerPicker
                      setCover={setCover}
                      setCoverLoading={setCoverLoading}
                      setCoverOpen={setCoverOpen}
                      setCoverFile={setCoverFile}
                    />
                  ) : (
                    <div>
                      <div className="bg-white dark:bg-navyGray p-4 relative flex space-x-3">
                        <div className="w-64">
                          {(cover?.urls?.small || !!coverFile) && (
                            <img
                              alt="room"
                              src={
                                coverFile
                                  ? URL.createObjectURL(coverFile)
                                  : cover?.urls?.small
                              }
                              className="rounded-xl object-cover w-full"
                              loading="lazy"
                            />
                          )}
                        </div>
                        <div className="">
                          <p className="text-base font-medium text-darkNight dark:text-frescoWhite -mt-1">
                            Room Cover
                          </p>
                          <p className="text-sm font-normal text-darkGray pb-1">
                            This cover will appear as a banner and thumbnail
                            anytime you share a room.
                          </p>
                          {coverLoading ? (
                            <div className="centerAbsolute mt-1 text-sm py-2 px-3 font-normal rounded-2xl">
                              <Loader />
                            </div>
                          ) : (
                            <Button
                              onClick={() => setCoverOpen(true)}
                              size="vibhut-sm"
                              variant="vibhut-primary"
                            >
                              Change
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="px-4 space-y-1.5">
                        <InputBox
                          type="text"
                          primaryLabel=" Room Name"
                          required
                          value={name}
                          placeholder="E.g. Producivity Hacks"
                          onChange={handleNameChange}
                        />
                        <InputBox
                          type="text"
                          primaryLabel="Unique URL"
                          secondaryLabel="Claim your vibehut.io/[url]"
                          value={roomSlug}
                          placeholder="E.g. IELTS Speaking"
                          onChange={handleSlugChange}
                          onBlur={handleSlugLimit}
                          errorText={slugError}
                        />
                        <div>
                          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                            Description
                          </p>
                          <LimitedTextarea
                            limit={500}
                            value={description}
                            rows={5}
                            onChange={handleDescriptionChange}
                          />
                        </div>
                      </div>
                      <div className=" px-4 py-3 flex flex-row-reverse ">
                        {name.trim() === "" ? (
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-primary"
                            disabled={true}
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setNextClicked(true);
                            }}
                            size="vibhut-sm"
                            variant="vibhut-primary"
                          >
                            Next
                          </Button>
                        )}

                        <div className="mr-3">
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-secondary"
                            onClick={() => setIsOpen(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu max-h-[90vh] overflow-auto custom-scrollbar">
                  <div className="bg-white dark:bg-navyGray dark:border-matteGray p-4 border-b border-frescoWhite">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row justify-start items-center">
                        <span
                          className="bg-frescoWhite rounded-full p-1 cursor-pointer text-gray-600 mr-2"
                          onClick={() => {
                            setNextClicked(false);
                          }}
                        >
                          <Back />
                        </span>
                        <p className="font-medium text-lg text-black dark:text-frescoWhite">
                          Room Settings
                        </p>
                      </div>

                      <span
                        className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <Close />
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="px-4">
                      {/* implemented password input box */}
                      <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
                        <div className="flex mt-3 flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Collect Payment
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <PremiumWrapper
                              permission={["paidRoom:write"]}
                              fallbackComponent={
                                <Switch
                                  onClick={() => {
                                    setOpenModal(true);
                                  }}
                                  className={`${
                                    hasSubscription
                                      ? "bg-primary"
                                      : "bg-gray-200"
                                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                                >
                                  <span
                                    className={`${
                                      hasSubscription
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                                  />
                                </Switch>
                              }
                            >
                              <Switch
                                checked={hasSubscription}
                                onChange={() =>
                                  setHasSubscription(!hasSubscription)
                                }
                                className={`${
                                  hasSubscription ? "bg-primary" : "bg-gray-200"
                                } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                              >
                                <span
                                  className={`${
                                    hasSubscription
                                      ? "translate-x-6"
                                      : "translate-x-1"
                                  } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                                />
                              </Switch>
                            </PremiumWrapper>

                            <ConfirmationModal
                              title="Upgrade to Premium"
                              text={"Collecting payment is a premium feature."}
                              description="Upgrade to premium to access this feature."
                              openModal={openModal}
                              setOpenModal={setOpenModal}
                              primaryButtonText="Upgrade"
                              primaryAction={() => {
                                setOpenModal(false);
                                openPlanModal(dispatch);
                              }}
                              secondaryButtonText={"Cancel"}
                              secondaryAction={() => {
                                setOpenModal(false);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className={`${hasSubscription ? "" : "hidden"} mt-2`}
                        >
                          <div className="flex justify-between items-center py-1.5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Select Subscription Type
                            </p>
                            <SelectBox
                              onChange={(value) => setSubscriptionType(value)}
                              selected={subscriptionType}
                              options={subscriptionTypeOptions}
                              width="w-60"
                            />
                          </div>
                          <CurrencyInput
                            prefix="$"
                            name="currencyInput"
                            id="currencyInput"
                            data-number-to-fixed="2"
                            data-number-stepfactor="100"
                            value={amount}
                            placeholder="$0.00"
                            className="text-sm border outline-none border-primaryGray py-2 px-2 rounded-xl my-2 w-60"
                            onChange={(e) => {
                              handleCurrencyInputChange(e, setAmount);
                              setShowPaymentErr("");
                            }}
                            onBlur={() => setAmount(Number(amount).toFixed(2))}
                            allowDecimals
                            decimalsLimit="2"
                            decimalScale="2"
                            disableAbbreviations
                          />
                          {showPaymentErr ? (
                            <p className={` text-xs text-cvRed`}>
                              {showPaymentErr}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {eventCreation.hasAccess && (
                        <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
                          <div className="flex mt-3 flex-row">
                            <div className="w-4/5">
                              <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                                Weekly pass charge
                              </p>
                              <p className="text-xs text-gray-400">
                                Provide a weekly pass for accessing all the paid
                                events.
                              </p>
                            </div>
                            <div className="w-1/5 mt-2">
                              <Switch
                                checked={showWeeklyPass}
                                onChange={() =>
                                  setShowWeeklyPass((prev) => !prev)
                                }
                                className={`${
                                  showWeeklyPass ? "bg-primary" : "bg-gray-200"
                                } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                              >
                                <span
                                  className={`${
                                    showWeeklyPass
                                      ? "translate-x-6"
                                      : "translate-x-1"
                                  } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                                />
                              </Switch>
                            </div>
                          </div>

                          <div className={`${showWeeklyPass ? "" : "hidden"}`}>
                            <CurrencyInput
                              prefix="$"
                              name="currencyInput"
                              id="currencyInput"
                              data-number-to-fixed="2"
                              data-number-stepfactor="100"
                              value={weeklyPassCharge}
                              placeholder="$0.00"
                              className="text-sm border outline-none border-primaryGray py-2 px-2 rounded-xl my-1 w-40"
                              onChange={(e) => {
                                handleCurrencyInputChange(
                                  e,
                                  setWeeklyPassCharge,
                                );
                                setShowWeeklyPassChargeErr("");
                              }}
                              onBlur={() =>
                                setWeeklyPassCharge(
                                  Number(weeklyPassCharge).toFixed(2),
                                )
                              }
                              allowDecimals
                              decimalsLimit="2"
                              decimalScale="2"
                              disableAbbreviations
                            />
                            {showWeeklyPassChargeErr ? (
                              <p className={` text-xs text-cvRed`}>
                                {showWeeklyPassChargeErr}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
                        <div className="flex mt-3 flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Password
                            </p>
                            <p className="text-xs text-gray-400">
                              A password will be required to start a call.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isPasswordProtected}
                              onChange={setIsPasswordProtected}
                              className={`${
                                isPasswordProtected
                                  ? "bg-primary"
                                  : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isPasswordProtected
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div
                          className={`${isPasswordProtected ? "" : "hidden"} `}
                        >
                          <div className="relative w-fit">
                            <input
                              type={isPassVisible ? "text" : "password"}
                              className={`${
                                showPasswordErr
                                  ? "border-red-500"
                                  : "border-lightGray"
                              } bordertext-primaryGray placeholder-grayScale-gray  outline-none  py-2 px-4 rounded-xl my-1 w-60 relative`}
                              value={password}
                              max={16}
                              onChange={handlePasswordChange}
                              placeholder="Password"
                            />
                            <span
                              className="cursor-pointer absolute top-4 right-3 z-10"
                              onClick={() => setIsPassVisible(!isPassVisible)}
                            >
                              {isPassVisible ? <VisEye /> : <HidEye />}
                            </span>
                          </div>
                          {showPasswordErr ? (
                            <p className={` text-xs text-cvRed`}>
                              Password can't be empty
                            </p>
                          ) : null}

                          <p className={` text-gray-400 text-xs`}>
                            16 Characters max
                          </p>
                        </div>
                      </div>
                      <div className="py-3 dark:border-matteGray border-b border-frescoWhite">
                        <div className="flex  flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Group Calls
                            </p>
                            <p className="text-xs text-gray-400">
                              Each person who joins this room will enter a group
                              conversation.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isGroupRoom}
                              onChange={setIsGroupRoom}
                              className={`${
                                isGroupRoom ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isGroupRoom
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className={`${isGroupRoom ? "" : "hidden"}`}>
                          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite pt-4">
                            Max Per Group
                          </p>

                          <input
                            type="text"
                            className={` ${
                              showMaxPerGroupErr
                                ? "border-red-500"
                                : "border-lightGray"
                            }  border  placeholder-grayScale-gray  outline-none  py-2 px-4 rounded-xl my-1 w-60 `}
                            value={maxUsersPerGroup}
                            onChange={handleGroupCallChange}
                          />
                          {showMaxPerGroupErr ? (
                            <p className={` text-xs text-cvRed`}>
                              Enter value between 0 and 16
                            </p>
                          ) : null}

                          <p className={`text-gray-400 text-xs`}>
                            16 is the maximum.
                          </p>
                          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite pt-4">
                            Max Number of Calls
                          </p>
                          <input
                            type="text"
                            className={` ${
                              showTotalLiveCallsErr
                                ? "border-red-500"
                                : "border-lightGray"
                            }  border  placeholder-grayScale-gray  outline-none  py-2 px-4 rounded-xl my-1 w-60 `}
                            value={totalCalls}
                            onChange={handleTotalCallChange}
                          />
                          {showTotalLiveCallsErr ? (
                            <p className={` text-xs text-cvRed`}>
                              Enter value greater than Zero
                            </p>
                          ) : null}

                          <p className={`text-gray-400 text-xs w-64`}>
                            When this is exceeded, it will create a queue.
                            Additional people will wait in the lobby.
                          </p>

                          <div className="flex  flex-row pt-4">
                            <div className="w-4/5">
                              <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                                Breakout Room
                              </p>
                              <p className="text-xs text-gray-400">
                                When turned on, all calls will be listed for a
                                user to join.
                              </p>
                            </div>
                            <div className="w-1/5 mt-2">
                              <Switch
                                checked={isBreakoutRoom}
                                onChange={setIsBreakoutRoom}
                                className={`${
                                  isBreakoutRoom ? "bg-primary" : "bg-gray-200"
                                } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                              >
                                <span
                                  className={`${
                                    isBreakoutRoom
                                      ? "translate-x-6"
                                      : "translate-x-1"
                                  } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                                />
                              </Switch>
                            </div>
                          </div>
                        </div>
                      </div>
                      {hasUserPermissionForPlanRoom && (
                        <div className="dark:border-matteGray border-b border-frescoWhite py-3">
                          <div
                            className={`flex justify-between items-center ${
                              planAccessibleType ? " pt-1.5" : " py-1.5"
                            }`}
                          >
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Is This Plan Accessible Room ?
                            </p>

                            <Switch
                              checked={isPlanAccessible}
                              onChange={() =>
                                setIsPlanAccessible(!isPlanAccessible)
                              }
                              className={`${
                                isPlanAccessible ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isPlanAccessible
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                          {isPlanAccessible && (
                            <div className="flex justify-between items-center py-1.5">
                              <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                                Select Accessible Type
                              </p>

                              <SelectBox
                                onChange={(value) => {
                                  setPlanAccessibleType(value);
                                }}
                                selected={planAccessibleType}
                                options={planType}
                              />
                            </div>
                          )}
                        </div>
                      )}

                      <div className="py-3 dark:border-matteGray border-b border-frescoWhite">
                        <div className=" flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Unlisted
                            </p>
                            <p className="text-xs text-gray-400">
                              Will not appear in public rooms and anyone with
                              link can view it
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isPrivate}
                              onChange={setIsPrivate}
                              className={`${
                                isPrivate ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isPrivate ? "translate-x-6" : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                      <div className="py-3 dark:border-matteGray border-b border-frescoWhite">
                        <div className=" flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Quick Join
                            </p>
                            <p className="text-xs text-gray-400">
                              Join instant call
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isQuickJoin}
                              onChange={setIsQuickJoin}
                              className={`${
                                isQuickJoin ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isQuickJoin
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                      <div className="py-3 dark:border-matteGray border-b border-frescoWhite">
                        <div className=" flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Guest User Allowed
                            </p>
                            <p className="text-xs text-gray-400">
                              Guest users can join this room.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isGuestAllowed}
                              onChange={setIsGuestAllowed}
                              className={`${
                                isGuestAllowed ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isGuestAllowed
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                      <div className="py-3 dark:border-matteGray border-b border-frescoWhite">
                        <div className="flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Subscriber Approval
                            </p>
                            <p className="text-xs text-gray-400">
                              Subscriber approval to join this room.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isSubscriberRequireApproval}
                              onChange={setIsSubscriberRequireApproval}
                              className={`${
                                isSubscriberRequireApproval
                                  ? "bg-primary"
                                  : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isSubscriberRequireApproval
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        {isSubscriberRequireApproval && (
                          <>
                            <div className="flex flex-row pt-2">
                              <div className="w-4/5">
                                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                                  Approval Questions Required
                                </p>
                                <p className="text-xs text-gray-400">
                                  Subscribers need to answer all your questions
                                  for approval
                                </p>
                              </div>
                              <div className="w-1/5 mt-2">
                                <Switch
                                  checked={isAnswersRequireForApproval}
                                  onChange={setIsAnswersRequireForApproval}
                                  className={`${
                                    isAnswersRequireForApproval
                                      ? "bg-primary"
                                      : "bg-gray-200"
                                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                                >
                                  <span
                                    className={`${
                                      isAnswersRequireForApproval
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                                  />
                                </Switch>
                              </div>
                            </div>
                            <>
                              {isAnswersRequireForApproval && (
                                <div className="pt-2">
                                  {approvalQuestions.map((question, index) => (
                                    <div key={index}>
                                      <LimitedTextarea
                                        limit={500}
                                        value={question}
                                        rows={3}
                                        onChange={(value) =>
                                          handleQuestionChange(index, value)
                                        }
                                        placeHolder={`Question ${index + 1}`}
                                      />
                                    </div>
                                  ))}
                                  {questionsErr ? (
                                    <p className="text-xs text-cvRed">
                                      {questionsErr}
                                    </p>
                                  ) : null}
                                </div>
                              )}
                            </>
                          </>
                        )}
                      </div>
                      <div className="py-3 flex flex-row border-b dark:border-matteGray border-frescoWhite">
                        <div className="w-4/5">
                          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                            Require Approval to Join Call
                          </p>
                          <p className="text-xs text-gray-400">
                            All users must be let in by host or moderator.
                          </p>
                        </div>
                        <div className="w-1/5 mt-2">
                          <Switch
                            checked={isRequireApproval}
                            onChange={setIsRequireApproval}
                            className={`${
                              isRequireApproval ? "bg-primary" : "bg-gray-200"
                            } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                          >
                            <span
                              className={`${
                                isRequireApproval
                                  ? "translate-x-6"
                                  : "translate-x-1"
                              } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                            />
                          </Switch>
                        </div>
                      </div>
                      <div className="py-3 flex flex-row border-b dark:border-matteGray border-frescoWhite">
                        <div className="w-4/5">
                          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                            Testimonials
                          </p>
                          <p className="text-xs text-gray-400">
                            Allow users to leave testimonials to the room.
                          </p>
                        </div>
                        <div className="w-1/5 mt-2">
                          <Switch
                            checked={isTestimonialEnabled}
                            onChange={setIsTestimonialEnabled}
                            className={`${
                              isTestimonialEnabled
                                ? "bg-primary"
                                : "bg-gray-200"
                            } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                          >
                            <span
                              className={`${
                                isTestimonialEnabled
                                  ? "translate-x-6"
                                  : "translate-x-1"
                              } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                            />
                          </Switch>
                        </div>
                      </div>
                      <div className="py-3  border-b dark:border-matteGray border-frescoWhite">
                        <div className="flex mt-3 flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Self Organized
                            </p>
                            <p className="text-xs text-gray-400">
                              An event will be automatically created when
                              certain individuals make a request
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isSelfOrganized}
                              onChange={setIsSelfOrganized}
                              className={`${
                                isSelfOrganized ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isSelfOrganized
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className={`${isSelfOrganized ? "" : "hidden"} `}>
                          <InputBox
                            primaryLabel="Min Request Required"
                            value={selfOrganizeCount}
                            onChange={handleSelfOrganizeCount}
                            errorText={selfOrganizeCountErr}
                          />
                          <p className={`text-gray-400 text-xs`}>
                            16 is the maximum.
                          </p>
                        </div>
                      </div>
                      <div className="py-3  border-b dark:border-matteGray border-frescoWhite">
                        <div className="flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              Deso Post Room Settings
                            </p>
                            <p className="text-xs text-gray-400">
                              Allow users who sent Diamonds on a DeSo post
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isDesoPostRoom}
                              onChange={setIsDesoPostRoom}
                              className={`${
                                isDesoPostRoom ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isDesoPostRoom
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className={`${isDesoPostRoom ? "" : "hidden"} `}>
                          <InputBox
                            value={desoPostRoom.postHashHex}
                            onChange={handleDesoPostRoomSettingChange}
                            errorText={selfOrganizeCountErr}
                            placeholder="Deso PostHashHex"
                            name="postHashHex"
                          />
                          <InputBox
                            value={desoPostRoom.diamondLevel}
                            onChange={handleDesoPostRoomSettingChange}
                            errorText={showDesoPostRoomErr}
                            placeholder="Diamond Level"
                            name="diamondLevel"
                          />
                        </div>
                      </div>
                      <div className="py-3">
                        <div className="flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              DeSo Creator Coin Required
                            </p>
                            <p className="text-xs text-gray-400">
                              Require users to hold a DeSo Creator Coins.
                              Minimum amount is optional.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isCCRoom}
                              onChange={() => setIsCCORoom(!isCCRoom)}
                              className={`${
                                isCCRoom ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isCCRoom ? "translate-x-6" : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className={`${isCCRoom ? "" : "hidden"}`}>
                          {CCDetails.map((cc, index) => (
                            <div
                              key={index}
                              className={`py-2 ${
                                index !== CCDetails.length - 1 ? "border-b" : ""
                              } border-frescoWhite dark:border-matteGray`}
                            >
                              <div>
                                {index !== 0 && (
                                  <div className="flex justify-end items-center">
                                    <p
                                      className="text-sm font-medium text-cvRed cursor-pointer"
                                      onClick={() => removeCC(index)}
                                    >
                                      Remove
                                    </p>
                                  </div>
                                )}
                                <InputBox
                                  primaryLabel="Username"
                                  required
                                  value={cc.username}
                                  onChange={(e) =>
                                    handleCCUsernameChange(index)(e)
                                  }
                                  placeholder="Username"
                                  customClasses="w-60"
                                />

                                <InputBox
                                  primaryLabel="Minium Amount"
                                  value={cc.amount}
                                  type="number"
                                  onChange={(e) =>
                                    handleCCAmountChange(index)(e)
                                  }
                                  placeholder="Minium Amount"
                                  customClasses="w-60"
                                />
                              </div>
                            </div>
                          ))}
                          <p
                            className="text-xs text-gray-600 dark:text-frescoWhite underline cursor-pointer"
                            onClick={addCCInput}
                          >
                            Add More
                          </p>
                        </div>
                      </div>
                      <div className="py-3">
                        <div className="flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              DeSo DAO Token Required
                            </p>
                            <p className="text-xs text-gray-400">
                              Require users to hold a DeSo DAO Tokesn. Minimum
                              amount is optional.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isDAORoom}
                              onChange={() => setIsDAOORoom(!isDAORoom)}
                              className={`${
                                isDAORoom ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isDAORoom ? "translate-x-6" : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className={`${isDAORoom ? "" : "hidden"}`}>
                          {DAODetails.map((dao, index) => (
                            <div
                              key={index}
                              className={`py-2 ${
                                index !== DAODetails.length - 1
                                  ? "border-b"
                                  : ""
                              } border-frescoWhite dark:border-matteGray`}
                            >
                              <div>
                                {index !== 0 && (
                                  <div className="flex justify-end items-center">
                                    <p
                                      className="text-sm font-medium text-cvRed cursor-pointer"
                                      onClick={() => removeDAO(index)}
                                    >
                                      Remove
                                    </p>
                                  </div>
                                )}
                                <InputBox
                                  primaryLabel="DAO Token"
                                  required
                                  value={dao.token}
                                  onChange={(e) =>
                                    handleDAOTokenChange(index)(e)
                                  }
                                  placeholder="DAO Token"
                                  customClasses="w-60"
                                />

                                <InputBox
                                  primaryLabel="Minium Amount"
                                  value={dao.amount}
                                  type="number"
                                  onChange={(e) =>
                                    handleDAOAmountChange(index)(e)
                                  }
                                  placeholder="Minium Amount"
                                  customClasses="w-60"
                                />
                              </div>
                            </div>
                          ))}
                          <p
                            className="text-xs text-gray-600 dark:text-frescoWhite underline cursor-pointer"
                            onClick={addDAOInput}
                          >
                            Add More
                          </p>
                        </div>
                      </div>
                      <div className="py-3">
                        <div className="flex flex-row">
                          <div className="w-4/5">
                            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                              NFT Collection Room
                            </p>
                            <p className="text-xs text-gray-400">
                              An NFT or multiple NFTs may be required to start a
                              call.
                            </p>
                          </div>
                          <div className="w-1/5 mt-2">
                            <Switch
                              checked={isNftCollectionRoom}
                              onChange={setIsNftCollectionRoom}
                              className={`${
                                isNftCollectionRoom
                                  ? "bg-primary"
                                  : "bg-gray-200"
                              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                            >
                              <span
                                className={`${
                                  isNftCollectionRoom
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div
                          className={`${isNftCollectionRoom ? "" : "hidden"}`}
                        >
                          {nftDetails.map((nft, index) => (
                            <div
                              key={index}
                              className={`py-2 ${
                                index !== nftDetails.length - 1
                                  ? "border-b"
                                  : ""
                              } border-frescoWhite dark:border-matteGray`}
                            >
                              <div>
                                {index !== 0 && (
                                  <div className="flex justify-end items-center">
                                    <p
                                      className="text-sm font-medium text-cvRed cursor-pointer"
                                      onClick={() => removeNft(index)}
                                    >
                                      Remove
                                    </p>
                                  </div>
                                )}
                                <InputBox
                                  primaryLabel="NFT URL"
                                  required
                                  value={nft.url}
                                  onChange={(e) => handleNftUrlChange(index)(e)}
                                  placeholder="NFT Url"
                                  customClasses="w-60"
                                />
                                <InputBox
                                  primaryLabel="NFT Title"
                                  value={nft.title}
                                  onChange={(e) =>
                                    handleNftTitleChange(index)(e)
                                  }
                                  placeholder="NFT Title"
                                  customClasses="w-60"
                                />
                              </div>
                            </div>
                          ))}
                          <p
                            className="text-xs text-gray-600 dark:text-frescoWhite underline cursor-pointer"
                            onClick={addNFTInput}
                          >
                            Add More
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" px-4 py-3 flex flex-row-reverse ">
                      {isFetching || isImageUploading ? (
                        <Loader />
                      ) : (
                        <Button
                          size="vibhut-sm"
                          variant="vibhut-primary"
                          onClick={handleUploadAndCreateRoom}
                        >
                          Done
                        </Button>
                      )}
                      <div className="mr-3">
                        <Button
                          size="vibhut-sm"
                          variant="vibhut-secondary"
                          onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ConfirmationModal
        title="Upgrade to Premium"
        text={"You have reached your limit of adding Slugs to a Rooms."}
        description="Upgrade to premium to add more Slugs."
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        primaryButtonText="Upgrade"
        primaryAction={() => {
          setOpenConfirmationModal(false);
          openPlanModal(dispatch);
        }}
        secondaryButtonText={"Cancel"}
        secondaryAction={() => {
          setOpenConfirmationModal(false);
        }}
        showLearnMore={true}
      />
    </>
  );
}
