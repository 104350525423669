import { useEffect, useState } from 'react';
import axios from '../../utils/axios';

const useWatchingCount = (callId: string) => {
  const [watchingCount, setWatchingCount] = useState(0);

  useEffect(() => {
    if (callId) {
      const updateWatchingCount = async (_callId: string) => {
        try {
          const res = await axios.get(`/calls/${_callId}/mux/counts`);
          const viewers = res.data.viewers;
          setWatchingCount(viewers);
        } catch (error) {
          setWatchingCount(0);
        }
      };

      const interval = setInterval(() => {
        updateWatchingCount(callId);
      }, 1000 * 10);

      return () => {
        clearInterval(interval);
      };
    }
  }, [callId]);

  return watchingCount;
};

export default useWatchingCount;
