import * as authService from "../services/authService";
import * as actionTypes from "../constants";
import { getLoggedInUserDataService } from "../services/userService";

export function loginWithBitclout(identityData, dispatch) {
  const { publicKey, jwt } = identityData;
  //dispatch request action.
  dispatch({
    type: actionTypes.LOGIN_WITH_BITCLOUT_REQUEST,
  });
  //call the api
  return authService
    .loginUsingBitClout(publicKey, jwt)
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_WITH_BITCLOUT_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function loginWithTwitter(token, dispatch) {
  dispatch({
    type: actionTypes.LOGIN_WITH_TWITTER_REQUEST,
  });
  return authService
    .loginWithTwitter(token)
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_WITH_TWITTER_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function loginWithMetamask(wallet_address, ens, dispatch) {
  dispatch({
    type: actionTypes.LOGIN_WITH_METAMASK_REQUEST,
  });

  return authService
    .loginWithMetamask(wallet_address, ens)
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_WITH_METAMASK_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getLoggedInUserData(userId, dispatch) {
  dispatch({
    type: actionTypes.GET_LOGGEDIN_USER_DATA_REQUEST,
  });
  return getLoggedInUserDataService(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_LOGGEDIN_USER_DATA_SUCCESS,
        payload: {
          user: res.data.user,
        },
      });
      // const userData = getUserData(res.data);
    })
    .catch((err) => {
      throw err;
    });
}

export async function logout(dispatch) {
  await dispatch({
    type: actionTypes.GET_PUBLIC_ROOMS_SUCCESS,
    payload: {
      rooms: [],
    },
  });
  await dispatch({
    type: actionTypes.GET_MY_ROOMS_SUCCESS,
    payload: {
      rooms: [],
    },
  });
  await dispatch({
    type: actionTypes.LOGOUT,
  });
}

export function getAuthUserData(token, dispatch) {
  dispatch({
    type: actionTypes.GET_AUTH_USER_DATA_REQUEST,
  });
  return authService
    .getAuthUserData(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_AUTH_USER_DATA_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function loginWithCustomWallet(data, dispatch) {
  dispatch({
    type: actionTypes.LOGIN_WITH_CUSTOM_WALLET_REQUEST,
  });

  return authService
    .loginWithCustomWallet(data)
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_WITH_CUSTOM_WALLET_SUCCESS,
        payload: {
          user: res.data,
        },
      });
    })
    .catch((err) => {
      throw err;
    });
}
