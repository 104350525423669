import { baseURL } from "../constants";
import { globals } from "../globals";

export type EnableLiveStreamOptions =
  | {
      isRtmpEnabled: false;
      isMobileLayout: boolean;
      isPaidLive: boolean;
      livePassCharge: number;
      isVibehutLive: boolean;
    }
  | {
      isRtmpEnabled: true;
      rtmpUrl: string;
      streamKey: string;
      isMobileLayout: boolean;
      isPaidLive: boolean;
      livePassCharge: number;
      isVibehutLive: boolean;
    };

const getToken = () => {
  const _authUser = globals.user;
  const token = _authUser.token;
  return token;
};

export const enableLiveVideo = async (
  callId: string,
  options: EnableLiveStreamOptions,
) => {
  const endpoint = `${baseURL}/api/v1/calls/${callId}/mux/enable`;

  return fetch(endpoint, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ ...options }),
  }).then((res) => res.json());
};

export const disableMuxCall = async (callId: string) => {
  const endpoint = `${baseURL}/api/v1/calls/${callId}/mux/disable`;

  return fetch(endpoint, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({}),
  }).then((res) => res.json());
};

export const retrieveLiveStream = async (callId: string) => {
  const endpoint = `${baseURL}/api/v1/calls/${callId}/mux/live-stream`;

  return fetch(endpoint, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((res) => res.json());
};
