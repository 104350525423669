function ConnectionSkeleton({
  gridConfig = "md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 grid-cols-1",
}) {
  return (
    <div className={`grid ${gridConfig} gap-8 py-5`}>
      {new Array(9).fill(0).map((_, index) => (
        <div className="" key={index}>
          <div className="cardWidth flex flex-col space-y-2 justify-center items-center border dark:border-matteGray border-lightGray rounded-xl px-6 py-4 relative cursor-pointer h-60 z-10">
            <div className="h-16 w-16 animate-pulse bg-frescoWhite dark:bg-darkGray rounded-full"></div>
            <div className="rounded-xl w-36 animate-pulse bg-frescoWhite dark:bg-darkGray h-6"></div>
            <div className="rounded-xl w-40 animate-pulse bg-frescoWhite dark:bg-darkGray h-4"></div>

            <div className="rounded-3xl w-20 animate-pulse bg-frescoWhite dark:bg-darkGray h-9"></div>
            <div className="absolute top-0 right-3 rounded-full w-6 animate-pulse bg-frescoWhite dark:bg-darkGray h-6"></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ConnectionSkeleton;
