import React from "react";

function NotificationSkeleton({ totalCard = 6 }) {
  return (
    <div className="py-4 flex w-full flex-col items-center space-y-5">
      {new Array(totalCard).fill(0).map((_, index) => (
        <div className="flex w-full space-x-3" key={index}>
          <div className="rounded-full w-10 animate-pulse bg-frescoWhite dark:bg-darkGray h-10"></div>
          <div className="bg-transparent w-4/5 space-y-2">
            <p className="rounded-xl w-full animate-pulse bg-frescoWhite dark:bg-darkGray h-4 "></p>
            <p className="rounded-xl w-12 animate-pulse bg-frescoWhite dark:bg-darkGray h-4"></p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NotificationSkeleton;
