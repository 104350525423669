import React, { useContext } from "react";
import moment from "moment";
import Home from "../VibehutUI/Icons/Home";
import Room from "../VibehutUI/Icons/Room";
import User from "../VibehutUI/Icons/User";
import { NavLink } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";
import Event from "../VibehutUI/Icons/Event";
import Watch from "../VibehutUI/Icons/Watch";
import Coffee from "../VibehutUI/Icons/Coffee";
import BarChart from "../VibehutUI/Icons/BarChart";
import Shop from "../VibehutUI/Icons/Shop";
import Feed from "../VibehutUI/Icons/Feed";
import Tag from "../VibehutUI/Tag";
import Call from "../VibehutUI/Icons/Call";

function NavigationBar({ isNewVideoAvailable, setIsOpen = undefined }) {
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
    feed: { latestPost },
  } = state;

  return (
    <nav role="navigation" className="flex flex-col space-y-5">
      <NavLink
        exact
        aria-label="Home"
        role="link"
        className="text-darkGray sm:hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/"
      >
        <span
          className="flex items-center space-x-2"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <Home />
          <span className="">Home</span>
        </span>
      </NavLink>
      <NavLink
        exact
        aria-label="Rooms"
        role="link"
        className="text-darkGray sm:hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/rooms"
      >
        <span
          className="flex items-center space-x-2"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <Room />
          <span>Rooms</span>
        </span>
      </NavLink>
      <NavLink
        exact
        aria-label="Feed"
        role="link"
        className="text-darkGray sm:hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/feed"
      >
        <span
          className="flex items-center space-x-4 pl-1"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <Feed />
          <span className="flex items-center space-x-1.5">
            <span>Feed</span>
            {latestPost?.createdAt && (
              <span className="text-xs">
                &#8226; {moment(latestPost?.createdAt).fromNow()}
              </span>
            )}
          </span>
        </span>
      </NavLink>
      <NavLink
        exact
        aria-label="Personas"
        role="link"
        className="text-darkGray sm:hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/personas"
      >
        <span
          className="flex items-center space-x-3 ml-1.5"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <User />
          <span>Personas</span>
          <Tag title={"New"} />
        </span>
      </NavLink>
      <NavLink
        exact
        role="link"
        aria-label="News"
        className="text-darkGray sm:hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/news"
      >
        <span
          className="flex items-center space-x-2 ml-1.5"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <button type="button" className="relative">
            <Coffee ratio="21" />
          </button>
          <span>News</span>
          <Tag title={"New"} />
        </span>
      </NavLink>
      {user && (
        <>
          <NavLink
            exact
            role="link"
            aria-label="Events"
            className="text-darkGray sm:hover:text-primary transition-all duration-500"
            activeClassName="text-primary"
            to="/calls"
          >
            <span
              className="flex items-center space-x-2 ml-1.5"
              onClick={() => setIsOpen && setIsOpen(false)}
              onTouchEnd={() => setIsOpen && setIsOpen(false)}
            >
              <Call ratio="18" />
              <p className="pl-1">Calls and Recordings</p>
            </span>
          </NavLink>
          <NavLink
            exact
            role="link"
            aria-label="Events"
            className="text-darkGray sm:hover:text-primary transition-all duration-500"
            activeClassName="text-primary"
            to="/events"
          >
            <span
              className="flex items-center space-x-2"
              onClick={() => setIsOpen && setIsOpen(false)}
              onTouchEnd={() => setIsOpen && setIsOpen(false)}
            >
              <Event />
              <span>Events</span>
            </span>
          </NavLink>
        </>
      )}
      <NavLink
        exact
        role="link"
        aria-label="Watch"
        className="text-darkGray hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/watch"
      >
        <span
          className="flex items-center space-x-2"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <button type="button" className="relative">
            {isNewVideoAvailable && (
              <div className="absolute  -top-1 -right-1 px-1 py-1 text-xs  bg-cvRed rounded-full"></div>
            )}
            <Watch />
          </button>
          <span>Watch</span>
        </span>
      </NavLink>

      <NavLink
        exact
        role="link"
        aria-label="Watch"
        className="text-darkGray hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        to="/leaderboard/users-calls"
      >
        <span
          className="flex items-center space-x-2"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <button type="button" className="relative">
            <BarChart />
          </button>
          <span>Leaderboard</span>
        </span>
      </NavLink>

      <a
        aria-label="Watch"
        className="text-darkGray hover:text-primary transition-all duration-500"
        activeClassName="text-primary"
        href="https://shop.vibehut.io/"
        target="_blank"
        rel="noreferrer"
      >
        <span
          className="flex items-center space-x-2"
          onClick={() => setIsOpen && setIsOpen(false)}
          onTouchEnd={() => setIsOpen && setIsOpen(false)}
        >
          <button type="button" className="relative">
            <Shop />
          </button>
          <span>Shop</span>
        </span>
      </a>
    </nav>
  );
}

export default NavigationBar;
