import React, { useState, useRef, useContext } from 'react';
import Button from '../../VibehutUI/Button';
import { VibehutDataContext } from '../VibehutDataProvider';
import { globals } from '../../globals';
import { updateBreakoutRoomNames } from '../../services/roomService';
import Loader from '../../VibehutUI/Loader';

interface BreakoutRoomEditProps {
  roomIndex: number;
  roomName: string;
  callId: string | undefined;
}

function BreakoutRoomEdit({ roomIndex, roomName, callId }: BreakoutRoomEditProps) {
  const { setRoomData, roomData } = useContext(VibehutDataContext);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [breakoutRoomName, setBreakoutRoomName] = useState(roomName);
  const [isUpdating, setIsUpdating] = useState(false);

  function handleChange(e: { target: { value: React.SetStateAction<string> } }) {
    setBreakoutRoomName(e.target.value);
  }

  function deepCopy(obj: any) {
    let copy: any = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        copy[key] = deepCopy(obj[key]);
      } else {
        copy[key] = obj[key];
      }
    }
    return copy;
  }

  const handleSubmit = async () => {
    setIsUpdating(true);
    let data = deepCopy(roomData);
    if (callId) {
      data.callDetails[callId].subRoomName = breakoutRoomName;
    } else {
      const objectToUpdate = data.filteredBreakoutRoomNames[roomIndex];
      const keyToUpdate = Object.keys(objectToUpdate)[0];
      data.filteredBreakoutRoomNames[roomIndex][keyToUpdate] = breakoutRoomName;
    }

    if (roomName !== breakoutRoomName) {
      const breakoutRoomNames = roomData.breakoutRoomNames;
      const indexToUpdate = breakoutRoomNames.findIndex((obj: any) => Object.values(obj)[0] === roomName);
      const keyToUpdate2 = Object.keys(breakoutRoomNames[indexToUpdate])[0];
      breakoutRoomNames[indexToUpdate][keyToUpdate2] = breakoutRoomName;
      data.breakoutRoomNames = breakoutRoomNames;
      await updateBreakoutRoomNames(roomData._id, breakoutRoomNames)
        .then(() => {
          setIsUpdating(false);
          setRoomData(data);
          globals.hideModal();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    } else {
      setIsUpdating(false);
      globals.hideModal();
    }
  };

  return (
    <>
      <div className="">
        <div className="bg-white dark:bg-navyGray p-4">
          <div className="flex font-ubuntu">
            <div className={`w-full flex items-center relative`}>
              <div className="my-auto w-full flex flex-col">
                <div className="flex flex-col space-y-2">
                  <input
                    ref={inputRef}
                    type="text"
                    className={`border-2 border-secondary
                                            text-sm placeholder-gray-400 py-2 px-3  rounded-xl  focus:ring-1 outline-none text-darkNight    focus:border-blue-500`}
                    onChange={handleChange}
                    value={breakoutRoomName}
                  ></input>

                  <p className={`pl-0.5 text-xs text-cvRed`}>
                    {`${!breakoutRoomName ? 'Please enter a room name.' : ''}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" px-4 py-3 flex flex-row justify-end space-x-3 dark:bg-navyGray">
          <Button size="vibhut-sm" variant="vibhut-secondary" onClick={() => globals.hideModal()}>
            Cancel
          </Button>
          {isUpdating ? (
            <Loader />
          ) : (
            <Button onClick={handleSubmit} size="vibhut-sm" variant="vibhut-primary" disabled={!breakoutRoomName}>
              Save
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
export default BreakoutRoomEdit;
