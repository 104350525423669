import { getEnv } from './utils/vibehutUtils';
export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'VibehutVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'VibehutVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'VibehutVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'VibehutVideoApp-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;

const domains = {
  local: 'localhost:8080',
  staging: 'vibehut-staging.herokuapp.com',
  prod: 'vibehut.io',
  testing: 'vibehut-test.looklike.io',
};

const protocols = {
  local: 'http://',
  staging: 'https://',
  prod: 'https://',
  testing: 'https://',
};

const environment = getEnv();

export const domain = domains[environment];
export const baseURL = `${protocols[environment]}${domain}`;
export const frontEndURL = `${protocols[environment]}${window.location.host}`;

export const userRoleTypes = {
  INDIVIDUAL: 'individual',
  PRO: 'pro',
  BUSINESS: 'business',
};
