import React, { Fragment, useRef, useContext, useState } from "react";
import { Dialog, Switch, Transition, Tab } from "@headlessui/react";
import PhoneInput from "react-phone-input-2";
import "./phoneInput.css";

import Loader from "../Loader";
import Close from "../Icons/Close";
import Notification from "../Icons/Notification";
import MoreInfo from "../Icons/MoreInfo";
import { closeModal } from "../../actions/profileModalActions";
import Warning from "../Icons/Warning";
import MyAccount from "../Icons/MyAccount";
import Back from "../Icons/Back";
import X from "../Icons/X";
import ContactInfo from "../Icons/ContactInfo";
import Gamification from "../Icons/Gamification";
import Button from "../../VibehutUI/Button";
import axios from "../../utils/axios";
import { StoreContext } from "../../Context/StoreContext";
import { getNotificationSettings } from "../../services/notificationService";
import {
  sendEmailOtp,
  sendNumberOtp,
  verifyEmailOtp,
  verifyNumberOtp,
} from "../../services/notifSettingService";
import LoaderButton from "../LoaderButton";
import DeleteModel from "../DeleteModel";
import WithdrawStatus from "../WithdrawStatus";
import PlanProfile from "../../components/profile/PlanProfile";
import * as profileTypes from "../../constants/commonTypes";
import Plans from "../Icons/Plans";
import { updateAppSettings } from "../../actions/userActions";
import SubscriptionsPage from "../../pages/SubscriptionsPage";
import GamificationPage from "../../pages/GamificationPage";
import SubscriptionIcon from "../Icons/SubscriptionIcon";
import { addMoney } from "../../services/paymentService";
import Chip from "../Chip";
import {
  checkIsDesoUser,
  convertToDecimal,
  handleCurrencyInputChange,
  isMobile,
} from "../../utils/utils";
import CurrencyInput from "react-currency-input-field";
import ReferralSystem from "../ReferralSystem";
import PublicApi from "../../components/PublicApi";
import API from "../Icons/API";
import Referral from "../Icons/Referral";
import useIsPWA from "../../hooks/usePWA";
import PushNotificationToggle from "../PushNotificationToggle";
import SettingsIcon from "../Icons/Settings";
import Settings from "./Settings";
const ModalTabs = {
  NOTIFICATIONS: "NOTIFICATIONS",
  CONTACT: "CONTACT",
  WALLET: "WALLET",
  SUBSCRIPTION: "SUBSCRIPTION",
  PLANS: "PLANS",
  GAMIFICATION: "GAMIFICATION",
  REFERRAL: "REFERRAL",
  PUBLICAPI: "PUBLICAPI",
  SETTINGS: "SETTINGS",
};

export default function MyProfileModal() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user: authUser },
    accountSettingsModal: { openModal, currentModal, isTopUpActive },
    setting: { isFetching: isSettingFetching, systemSetting },
  } = state;
  const cancelButtonRef = useRef(null);
  const inputNumberRef = useRef();
  const isPWA = useIsPWA();
  const modalTabsData = {
    NOTIFICATIONS: {
      label: "Notifications",
      icon: <Notification />,
    },
    CONTACT: {
      label: "Contact",
      icon: <ContactInfo />,
    },
    SUBSCRIPTION: {
      label: "Subscriptions",
      icon: <SubscriptionIcon />,
    },
    WALLET: {
      label: "Wallet",
      icon: <MyAccount />,
    },
    PLANS: {
      label: "Plans",
      icon: <Plans />,
    },
    GAMIFICATION: {
      label: "Things To Do",
      icon: <Gamification />,
    },
    ...(systemSetting?.isReferralSystemActive && {
      REFERRAL: {
        label: "Referral",
        icon: <Referral />,
      },
    }),
    PUBLICAPI: {
      label: "Public API Keys",
      icon: <API />,
    },
    SETTINGS: {
      label: "Settings",
      icon: (
        <div className="p-2 bg-primaryGreen rounded-[0.6rem]">
          <SettingsIcon color="#fff" />
        </div>
      ),
    },
  };

  const [openMyProfile, setOpenMyProfile] = useState(false);
  const [email, setEmail] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [showOtpBox, setShowOtpBox] = React.useState(false);
  const [showPhoneOtpBox, setShowPhoneOtpBox] = React.useState(false);
  const [otpValue, setOtpValue] = React.useState("");
  const [phoneOtpValue, setphoneOtpValue] = React.useState("");
  const [visEmailText, setvisEmailText] = React.useState("hidden");
  const [visNumberText, setvisNumberText] = React.useState("hidden");
  const [showWarning, setShowWarning] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);
  const [isVerifyingPhone, setIsVerifyingPhone] = React.useState(false);
  const [notificationSettings, setNotificationSettings] = React.useState({
    email: {
      enabled: true,
      isEmailVerified: false,
      value: "",
    },
    phoneNumber: {
      enabled: true,
      isPhoneNumberVerified: false,
      value: "",
    },
  });
  const [isEmailRemoving, setIsEmailRemoving] = React.useState(false);
  const [isNumberRemoving, setIsNumberRemoving] = React.useState(false);
  const [isShowEmailRemoveBtn, setIsShowEmailRemoveBtn] = React.useState(false);
  const [isShowMobileRemoveBtn, setIsShowMobileRemoveBtn] =
    React.useState(false);

  // mobile view
  const [mobileModel, setMobileModel] = React.useState(true);

  // modal change
  const [activeModal, setActiveModal] = React.useState(ModalTabs.CONTACT);

  // hangle wrong otp
  const [wrongEmailOtp, setWrongEmailOtp] = React.useState(false);
  const [wrongNumOtp, setWrongNumOtp] = React.useState(false);

  // stripe balance
  const [stripeBal, setStripeBal] = React.useState("0");
  const [pendingBal, setPendingBal] = React.useState("0");

  // vibebuck toggle
  const [toggleWallet, setToggleWallet] = React.useState(false);

  // toogle add and withdraw vibebuck
  const [toogleVibebuck, setToogleVibebuck] = React.useState(false);

  // handle error response
  const [handleRes, sethandleRes] = React.useState("");
  const [handleVibebucksRes, setHandleVibebucksRes] = React.useState("");

  // withdraw initiated
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
  const [transferId, setTransferId] = React.useState("");

  // timer
  const [otpTimer, setOtpTimer] = React.useState(0);
  const [otpPhnTimer, setOtpPhnTimer] = React.useState(0);
  const [showTimer, setShowTimer] = React.useState({
    emailTimer: false,
    phoneTimer: false,
  });
  const [showRetryBtn, setShowRetryBtn] = React.useState({
    emailRetryBtn: false,
    phoneRetryBtn: false,
  });
  const [notifSettings, setNotifSettings] = React.useState({
    emailIsWavesEnabled: false,
    emailIsRoomEnabled: false,
    emailIsEventEnabled: false,
    emailIsUserEnabled: false,
    mobileIsWavesEnabled: false,
    mobileIsRoomEnabled: false,
    mobileIsEventEnabled: false,
    mobileIsUserEnabled: false,
  });
  const [isConfirmationEmailOpen, setIsConfirmationEmailOpen] =
    React.useState(false);
  const [isConfirmationPhoneOpen, setIsConfirmationPhoneOpen] =
    React.useState(false);

  const toggleWalletVibeBuck = () => {
    setToggleWallet(true);
    setToogleVibebuck(true);
    setActiveModal(ModalTabs.WALLET);
  };

  const initialSettings = {
    sound: {
      chat: true,
      wave: true,
    },
    banner: {
      chat: true,
      wave: true,
    },
    decryptOnServer: {
      chat: false,
    },
  };

  const [appSettings, setAppSettings] = React.useState(
    authUser?.appSettings ? authUser.appSettings : initialSettings,
  );

  const updateNotificationSettings = () => {
    if (openMyProfile && authUser?.token) {
      getNotificationSettings(authUser.token)
        .then((res) => {
          const _notificationSettings = res.data.notificationSettings;
          setNotificationSettings(_notificationSettings);
          setNotifSettings({
            emailIsRoomEnabled:
              _notificationSettings.email.filtersSettings.isRoomEnabled,
            emailIsWavesEnabled:
              _notificationSettings.email.filtersSettings.isWavesEnabled,
            emailIsEventEnabled:
              _notificationSettings.email.filtersSettings.isEventEnabled,
            emailIsUserEnabled:
              _notificationSettings.email.filtersSettings.isUserEnabled,
            mobileIsRoomEnabled:
              _notificationSettings.phoneNumber.filtersSettings.isRoomEnabled,
            mobileIsWavesEnabled:
              _notificationSettings.phoneNumber.filtersSettings.isWavesEnabled,
            mobileIsEventEnabled:
              _notificationSettings.phoneNumber.filtersSettings.isEventEnabled,
            mobileIsUserEnabled:
              _notificationSettings.phoneNumber.filtersSettings.isUserEnabled,
          });
          if (
            _notificationSettings.email.value &&
            _notificationSettings.email.isEmailVerified
          ) {
            setIsShowEmailRemoveBtn(true);
          }
          if (_notificationSettings.email.isEmailVerified) {
            setEmail(_notificationSettings.email.value || "");
          }
          if (
            _notificationSettings.phoneNumber.value &&
            _notificationSettings.phoneNumber.isPhoneNumberVerified
          ) {
            setIsShowMobileRemoveBtn(true);
          }
          if (_notificationSettings.phoneNumber.isPhoneNumberVerified) {
            setNumber(`+${_notificationSettings.phoneNumber.value}` || "");
          }
          if (
            !_notificationSettings.email.isEmailVerified &&
            !_notificationSettings.phoneNumber.isPhoneNumberVerified
          ) {
            setShowWarning(true);
          }
        })
        .catch((err) => console.log("NOTIFICATION SETTINGS err:", err));
    }
  };

  React.useEffect(() => {
    if (isTopUpActive) {
      setToggleWallet(isTopUpActive);
      setToogleVibebuck(isTopUpActive);
    }
    if (openModal === true) {
      switch (currentModal) {
        case profileTypes.NOTIFICATION:
          setActiveModal(ModalTabs.NOTIFICATIONS);
          break;
        case profileTypes.CONTACT:
          setActiveModal(ModalTabs.CONTACT);
          break;
        case profileTypes.WALLET:
          setActiveModal(ModalTabs.WALLET);
          break;
        case profileTypes.PLAN:
          setActiveModal(ModalTabs.PLANS);
          break;
        case profileTypes.GAMIFICATION:
          setActiveModal(ModalTabs.GAMIFICATION);
          break;
        case profileTypes.REFERRAL:
          setActiveModal(ModalTabs.REFERRAL);
          break;
        case profileTypes.PUBLICAPI:
          setActiveModal(ModalTabs.PUBLICAPI);
          break;
        case profileTypes.SETTINGS:
          setActiveModal(ModalTabs.SETTINGS);
          break;
        default:
          setActiveModal(ModalTabs.CONTACT);
          break;
      }
      setOpenMyProfile(true);
    } else {
      setOpenMyProfile(false);
    }
  }, [openModal, isTopUpActive]);

  React.useEffect(() => {
    updateNotificationSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMyProfile]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleEmailChange = (e) => {
    setIsShowEmailRemoveBtn(false);
    setEmail(e.target.value);
    setShowOtpBox(false);
    setOtpValue("");

    setvisEmailText("hidden");
  };

  const handleNumberChange = (e) => {
    setIsShowMobileRemoveBtn(false);
    setNumber(`+${e}`);
    setShowPhoneOtpBox(false);
    setphoneOtpValue("");
    setvisNumberText("hidden");
  };

  const handleOtpChange = (e) => {
    setOtpValue(e.target.value);
    setWrongEmailOtp(false);
  };

  const handlePhoneOtpChange = (e) => {
    setphoneOtpValue(e.target.value);
    setWrongNumOtp(false);
  };

  React.useEffect(() => {
    let interval;
    if (showTimer.emailTimer) {
      interval = setInterval(() => {
        setOtpTimer((otpTimer) => otpTimer + 1);
      }, 1000);
      // localStorage.setItem("count", otpTimer);
      if (otpTimer === 60) {
        // localStorage.removeItem("count");
        setShowTimer({ ...showTimer, emailTimer: false });
        setShowRetryBtn({ ...showRetryBtn, emailRetryBtn: true });
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimer, otpTimer]);

  React.useEffect(() => {
    let intervalphn;
    if (showTimer.phoneTimer) {
      intervalphn = setInterval(() => {
        setOtpPhnTimer((otpPhnTimer) => otpPhnTimer + 1);
      }, 1000);
      // localStorage.setItem("countphn", otpPhnTimer);
      if (otpPhnTimer === 60) {
        // localStorage.removeItem("countphn");
        setShowTimer({ ...showTimer, phoneTimer: false });
        setShowRetryBtn({ ...showRetryBtn, phoneRetryBtn: true });
        clearInterval(intervalphn);
      }
    }
    return () => clearInterval(intervalphn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimer, otpPhnTimer]);

  React.useEffect(() => {
    if (activeModal === ModalTabs.CONTACT && inputNumberRef.current) {
      inputNumberRef.current.classList.remove("form-control");
    }
  }, [activeModal, number]);

  const verifyEmail = () => {
    setShowRetryBtn({ ...showRetryBtn, emailRetryBtn: false });
    setOtpTimer(0);
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(email)) {
      sendEmailOtp(authUser?.token, email)
        .then((res) => {
          const _notificationSettings = res.data;
          setShowOtpBox(true);
          setShowTimer({ ...showTimer, emailTimer: true });
          setNotificationSettings(_notificationSettings);
          if (_notificationSettings.email.isEmailVerified) {
            setEmail(_notificationSettings.email.value || "");
          }
          if (_notificationSettings.phoneNumber.isPhoneNumberVerified) {
            setNumber(`+${_notificationSettings.phoneNumber.value}` || "");
          }
        })
        .catch((err) => console.log("SEND EMAIL OTP err: ", err));
    } else if (!regEx.test(email) && email !== "") {
      setvisEmailText("");
    } else {
      setvisEmailText("");
    }
  };

  const verifyPhone = () => {
    setShowRetryBtn({ ...showRetryBtn, phoneRetryBtn: false });
    setOtpPhnTimer(0);
    sendNumberOtp(authUser?.token, number)
      .then((res) => {
        setShowPhoneOtpBox(true);
        setShowTimer({ ...showTimer, phoneTimer: true });
        const _notificationSettings = res.data;
        setNotificationSettings(_notificationSettings);
        if (_notificationSettings.email.isEmailVerified) {
          setEmail(_notificationSettings.email.value || "");
        }
        if (_notificationSettings.phoneNumber.isPhoneNumberVerified) {
          setNumber(`+${_notificationSettings.phoneNumber.value}` || "");
        }
      })
      .catch((err) => console.log("SEND NUMBER OTP err: ", err));
  };

  const verifyOtp = () => {
    setIsVerifying(true);
    verifyEmailOtp(authUser?.token, otpValue)
      .then((res) => {
        console.log(res);
        if (res.data.isVerified === true) {
          setShowOtpBox(false);
          updateNotificationSettings();
          setShowRetryBtn({
            ...showRetryBtn,
            emailRetryBtn: false,
          });
          setOtpValue("");
        } else {
          setWrongEmailOtp(true);
        }
      })
      .catch((err) => console.log("VERIFY EMAIL OTP err: ", err))
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const verifyPhoneOtp = () => {
    setIsVerifyingPhone(true);
    verifyNumberOtp(authUser?.token, phoneOtpValue)
      .then((res) => {
        if (res.data.isVerified === true) {
          setShowPhoneOtpBox(false);
          updateNotificationSettings();
          setShowRetryBtn({
            ...showRetryBtn,
            phoneRetryBtn: false,
          });
          setphoneOtpValue("");
        } else {
          setWrongNumOtp(true);
        }
      })
      .catch((err) => console.log("VERIFY NUMBER OTP err: ", err))
      .finally(() => {
        setIsVerifyingPhone(false);
      });
  };

  const updateNotificationRecieveSettings = (e) => {
    setNotifSettings({ ...notifSettings, [e.target.name]: e.target.checked });
    const data = {
      emailSettings: {
        isRoomEnabled:
          e.target.name === "emailIsRoomEnabled"
            ? e.target.checked
            : notifSettings.emailIsRoomEnabled,
        isWavesEnabled:
          e.target.name === "emailIsWavesEnabled"
            ? e.target.checked
            : notifSettings.emailIsWavesEnabled,
        isEventEnabled:
          e.target.name === "emailIsEventEnabled"
            ? e.target.checked
            : notifSettings.emailIsEventEnabled,
        isUserEnabled:
          e.target.name === "emailIsUserEnabled"
            ? e.target.checked
            : notifSettings.emailIsUserEnabled,
      },
      phoneSettings: {
        isRoomEnabled:
          e.target.name === "mobileIsRoomEnabled"
            ? e.target.checked
            : notifSettings.mobileIsRoomEnabled,
        isWavesEnabled:
          e.target.name === "mobileIsWavesEnabled"
            ? e.target.checked
            : notifSettings.mobileIsWavesEnabled,
        isEventEnabled:
          e.target.name === "mobileIsEventEnabled"
            ? e.target.checked
            : notifSettings.mobileIsEventEnabled,
        isUserEnabled:
          e.target.name === "mobileIsUserEnabled"
            ? e.target.checked
            : notifSettings.mobileIsUserEnabled,
      },
    };
    axios
      .post(`/notification/save-notification-filters-settings`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authUser?.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const removeEmail = () => {
    setIsEmailRemoving(true);
    axios
      .post(
        `/notification/save-notification-settings`,
        {
          notificationSettings: {
            ...notificationSettings,
            email: {
              ...notificationSettings.email,
              value: "",
              isEmailVerified: false,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser?.token}`,
          },
        },
      )
      .then((res) => {
        setIsEmailRemoving(false);
        if (res.status === 200) {
          setIsShowEmailRemoveBtn(false);
          setEmail("");
          setNotificationSettings({
            ...notificationSettings,
            email: {
              enabled: true,
              isEmailVerified: false,
              value: "",
            },
          });
        } else {
          console.log(res);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const removeNumber = () => {
    setIsNumberRemoving(true);
    axios
      .post(
        `/notification/save-notification-settings`,
        {
          notificationSettings: {
            ...notificationSettings,
            phoneNumber: {
              ...notificationSettings.phoneNumber,
              value: "",
              isPhoneNumberVerified: false,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser?.token}`,
          },
        },
      )
      .then((res) => {
        setIsNumberRemoving(false);
        if (res.status === 200) {
          setIsShowMobileRemoveBtn(false);
          setNumber("");
          setNotificationSettings({
            ...notificationSettings,
            phoneNumber: {
              enabled: true,
              isPhoneNumberVerified: false,
              value: "",
            },
          });
        } else {
          console.log(res);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const handleChangeAppSettings = async (type) => {
    try {
      setAppSettings({
        sound: {
          wave:
            type === "sound-wave"
              ? !appSettings.sound.wave
              : appSettings.sound.wave,
          chat:
            type === "sound-chat"
              ? !appSettings.sound.chat
              : appSettings.sound.chat,
        },
        banner: {
          wave:
            type === "banner-wave"
              ? !appSettings.banner.wave
              : appSettings.banner.wave,
          chat:
            type === "banner-chat"
              ? !appSettings.banner.chat
              : appSettings.banner.chat,
        },
        decryptOnServer: {
          chat:
            type === "decrypt-chat"
              ? !appSettings?.decryptOnServer?.chat
              : appSettings?.decryptOnServer?.chat,
        },
        receiveMessageOnDeSoAccount:
          type === "receiveMessageOnDeSoAccount"
            ? !appSettings?.receiveMessageOnDeSoAccount
            : appSettings?.receiveMessageOnDeSoAccount,
      });
      const body = {
        sound: {
          wave:
            type === "sound-wave"
              ? !appSettings.sound.wave
              : appSettings.sound.wave,
          chat:
            type === "sound-chat"
              ? !appSettings.sound.chat
              : appSettings.sound.chat,
        },
        banner: {
          wave:
            type === "banner-wave"
              ? !appSettings.banner.wave
              : appSettings.banner.wave,
          chat:
            type === "banner-chat"
              ? !appSettings.banner.chat
              : appSettings.banner.chat,
        },
        decryptOnServer: {
          chat:
            type === "decrypt-chat"
              ? !appSettings?.decryptOnServer?.chat
              : appSettings?.decryptOnServer?.chat,
        },
        receiveMessageOnDeSoAccount:
          type === "receiveMessageOnDeSoAccount"
            ? !appSettings?.receiveMessageOnDeSoAccount
            : appSettings?.receiveMessageOnDeSoAccount,
      };
      await updateAppSettings(authUser?.token, body, dispatch);
    } catch (error) {
      setAppSettings({
        ...appSettings,
        sound: {
          ...appSettings.sound,
          chat: appSettings.sound.chat,
        },
      });
      console.log("error::", error);
    }
  };

  const _renderContactModal = () => {
    return (
      <div className=" flex flex-col pl-2 pr-2 sm:pr-10">
        <div className="pb-3">
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
            Email Address
          </p>
          <div>
            <div className="flex items-center">
              <input
                type="text"
                className="border mr-2  outline-none border-primaryGray py-2 px-4 rounded-xl mt-1 w-full sm:w-72 placeholder-notifyBg text-sm"
                value={email}
                onChange={handleEmailChange}
                placeholder="johndoe@gmail.com"
                disabled={isShowEmailRemoveBtn}
              />
              {isShowEmailRemoveBtn && (
                <div>
                  {isEmailRemoving ? (
                    <Loader />
                  ) : (
                    <Button
                      variant="danger"
                      onClick={() => {
                        setIsConfirmationEmailOpen(true);
                      }}
                      // size="xs"
                    >
                      Remove
                    </Button>
                  )}
                </div>
              )}
            </div>

            {email &&
            !showOtpBox &&
            (email !== notificationSettings.email.value ||
              (email === notificationSettings.email.value &&
                !notificationSettings.email.isEmailVerified)) ? (
              <div className="pt-3">
                <Button
                  varient="primary"
                  onClick={() => {
                    verifyEmail();
                  }}
                >
                  Send Verification Code
                </Button>
              </div>
            ) : undefined}
          </div>

          {showOtpBox ? (
            <p className="text-xs  text-darkGray dark:text-gray-300 pt-2">
              An email has been sent!
              {showTimer.emailTimer && <span> Retry in {60 - otpTimer}s </span>}
            </p>
          ) : undefined}
          <p className={` ${visEmailText} text-xs text-cvRed`}>
            {`${email ? "Please enter valid Email" : "Please enter a Email."}`}
          </p>
        </div>

        <div className={`${showOtpBox ? "visible" : "hidden"} pb-3`}>
          <div>
            <input
              type="text"
              className="border mr-2  outline-none border-primaryGray py-2 px-4 rounded-xl w-full sm:w-72 placeholder-notifyBg text-sm"
              value={otpValue}
              onChange={handleOtpChange}
              placeholder="Enter 6-digit code..."
            />
            {wrongEmailOtp ? (
              <p className={` text-xs dark:text-frescoWhite text-cvRed mt-2`}>
                The code is incorrect. Please try again.
              </p>
            ) : undefined}
            <div className="flex pt-3 space-x-2">
              {showRetryBtn.emailRetryBtn ? (
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    verifyEmail();
                  }}
                >
                  Re-send Code
                </Button>
              ) : undefined}
              {isVerifying ? (
                <LoaderButton />
              ) : (
                <Button
                  size="vibhut-sm"
                  variant="vibhut-primary"
                  disabled={otpValue.length !== 6}
                  onClick={() => {
                    verifyOtp();
                  }}
                >
                  Verify
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="pb-3">
          <p className="text-sm font-medium text-darkNight dark:text-frescoWhite mb-2">
            Phone Number (with country code)
          </p>
          <div>
            <div className="flex items-center">
              <PhoneInput
                country={"us"}
                value={number}
                inputClass="border mr-2 outline-none border-primaryGray  pl-4 rounded-xl  ml-7 w-11/12 sm:w-64 placeholder-notifyBg text-sm"
                dropdownClass="border mr-2 outline-none border-primaryGray py-2 px-4 rounded-xl"
                placeholder="(416) 502-9867"
                onChange={handleNumberChange}
                disabled={isShowMobileRemoveBtn}
                enableLongNumbers={true}
              />

              {isShowMobileRemoveBtn && (
                <div>
                  {isNumberRemoving ? (
                    <Loader />
                  ) : (
                    <Button
                      variant="danger"
                      onClick={() => {
                        setIsConfirmationPhoneOpen(true);
                      }}
                      // size="xs"
                    >
                      Remove
                    </Button>
                  )}
                </div>
              )}
            </div>

            {number &&
            !showPhoneOtpBox &&
            (number !== `+${notificationSettings.phoneNumber.value}` ||
              (number === `+${notificationSettings.phoneNumber.value}` &&
                !notificationSettings.phoneNumber.isPhoneNumberVerified)) ? (
              <div className="pt-3">
                <Button
                  varient="primary"
                  onClick={() => {
                    verifyPhone();
                  }}
                >
                  Send Verification Code
                </Button>
              </div>
            ) : undefined}
          </div>

          {showPhoneOtpBox ? (
            <p className="text-xs  text-darkGray dark:text-gray-300 pt-2">
              A SMS has been sent!
              {showTimer.phoneTimer && (
                <span> Retry in {60 - otpPhnTimer}s </span>
              )}
            </p>
          ) : undefined}

          <p className={` ${visNumberText} text-xs text-cvRed`}>
            {`${
              number
                ? "Please enter valid Phone Number"
                : "Please enter a Phone Number."
            }`}
          </p>
        </div>

        <div className={`${showPhoneOtpBox ? "visible" : "hidden"} `}>
          <div>
            <input
              type="text"
              className="border mr-2  outline-none border-primaryGray py-2 px-4 rounded-xl mt-1 w-full sm:w-72 placeholder-notifyBg text-sm"
              value={phoneOtpValue}
              onChange={handlePhoneOtpChange}
              placeholder="Enter 6-digit code..."
            />
            {wrongNumOtp ? (
              <p className={` text-xs dark:text-frescoWhite text-cvRed pt-1`}>
                The code is incorrect. Please try again.
              </p>
            ) : undefined}
            <div className="pt-3 flex space-x-2">
              {showRetryBtn.phoneRetryBtn ? (
                <Button
                  size="vibhut-sm"
                  variant="vibhut-secondary"
                  onClick={() => {
                    verifyPhone();
                  }}
                >
                  Re-send Code
                </Button>
              ) : undefined}
              {isVerifyingPhone ? (
                <LoaderButton />
              ) : (
                <Button
                  size="vibhut-sm"
                  variant="vibhut-primary"
                  disabled={phoneOtpValue.length !== 6}
                  onClick={() => {
                    verifyPhoneOtp();
                  }}
                >
                  Verify
                </Button>
              )}
            </div>
          </div>
        </div>

        <DeleteModel
          handleDelete={removeEmail}
          title={"Confirmation"}
          isOpen={isConfirmationEmailOpen}
          setIsOpen={setIsConfirmationEmailOpen}
          text={`Are you sure you want to remove your email `}
          btnvalue={"Remove"}
        />
        <DeleteModel
          handleDelete={removeNumber}
          title={"Confirmation"}
          isOpen={isConfirmationPhoneOpen}
          setIsOpen={setIsConfirmationPhoneOpen}
          text={`Are you sure you want to remove your phone number `}
          btnvalue={"Remove"}
        />
      </div>
    );
  };

  const retrieveUserWalletBalance = () => {
    axios
      .get(`/stripe/walletbalance`, {
        headers: {
          Authorization: `Bearer ${authUser?.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          dispatch({
            type: "VIBEBUCKS_WITHDRAW_SUCCESS",
            payload: res.data.data,
          });
          // setWalletBal(res.data.data);
        } else {
          console.log("RESPONSE not succeed", res);
        }
      })
      .catch((error) => {
        console.log("ERR IN CATCH: ", error);
      });
  };

  const _renderWalletModal = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isConnectLoading, setIsConnectLoading] = React.useState(false);
    const [iswithdrawLoading, setIsWithdrawLoading] = React.useState(false);
    const [manageAccLoading, setManagerAccLoading] = React.useState(false);
    const [isvibebuckswithdrawLoading, setIsvibebuckswithdrawLoading] =
      React.useState(false);
    const [addAmount, setaddAmount] = React.useState("");
    const [withdrawAmount, setwithdrawAmount] = React.useState("");

    const withdrawVibebucks = async () => {
      setHandleVibebucksRes("");
      if (Number.isNaN(parseInt(withdrawAmount))) {
        setHandleVibebucksRes("Please enter valid amount");
        setwithdrawAmount("");
        setTimeout(() => {
          setHandleVibebucksRes("");
        }, 3000);
        return;
      }

      if (withdrawAmount > authUser?.wallet_balance) {
        setHandleVibebucksRes("Not enough Balance in your account");
        setwithdrawAmount("");
        setTimeout(() => {
          setHandleVibebucksRes("");
        }, 5000);
        return;
      }
      if (parseInt(withdrawAmount) < 100) {
        setHandleVibebucksRes("Minimum withdrawal amount is $100");
        setTimeout(() => {
          setHandleVibebucksRes("");
        }, 3000);
        return;
      }
      setIsvibebuckswithdrawLoading(true);
      axios
        .post(
          `/stripe/withdraw`,
          {
            amount: withdrawAmount,
          },
          {
            headers: {
              Authorization: `Bearer ${authUser?.token}`,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            setTransferId(res.data.data._id);
            setIsvibebuckswithdrawLoading(false);
            setToggleWallet(false);
            retrieveUserWalletBalance();
            setOpenMyProfile(false);
            closeModal(dispatch);
            setIsSuccessOpen(true);
            setwithdrawAmount("");
          } else if (res.status === 400) {
            setHandleVibebucksRes(res.data);
            setIsvibebuckswithdrawLoading(false);
            setTimeout(() => {
              setHandleVibebucksRes("");
            }, 5000);
          } else {
            console.log("RESPONSE not succeed", res);
          }
        })
        .catch((error) => {
          console.log("ERR IN CATCH: ", error);
        });
    };

    const handleAddMoney = async () => {
      if (!authUser?.token) {
        return;
      }
      if (Number.isNaN(parseInt(addAmount))) {
        setHandleVibebucksRes("Please enter valid amount");
        setaddAmount("");
        setTimeout(() => {
          setHandleVibebucksRes("");
        }, 3000);
        return;
      }
      setIsLoading(true);
      await addMoney(authUser.token, addAmount);
    };

    const connectStripeAcc = async () => {
      setIsConnectLoading(true);
      sethandleRes("");
      axios
        .post(
          `/stripe/onboard`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authUser?.token}`,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = res.data.data.url;
          } else {
            console.log("RESPONSE not succeed", res);
          }
        })
        .catch((error) => {
          console.log("ERR IN CATCH: ", error);
        });
    };

    const manageStripeAcc = async () => {
      setManagerAccLoading(true);
      axios
        .get(
          `/stripe/login`,

          {
            headers: {
              Authorization: `Bearer ${authUser?.token}`,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            // window.location.href = res.data.data.url;
            window.open(res.data.data.url);
            setManagerAccLoading(false);
          } else if (res.data.status == "failure") {
            console.log(res.data);
          } else {
            console.log("RESPONSE not succeed", res);
          }
        })
        .catch((error) => {
          console.log("ERR IN CATCH: ", error);
        });
    };

    return (
      <div className="flex flex-col">
        {toggleWallet ? (
          <>
            <div className="flex justify-between items-center  px-3">
              <span
                className="bg-frescoWhite rounded-full  cursor-pointer"
                tabIndex={0}
                onClick={() => {
                  setToggleWallet(false);
                }}
              >
                <Back />
              </span>
            </div>

            <div className="p-3 pt-2">
              {toogleVibebuck ? (
                <div className="flex  flex-col justify-between p-3 border border-lightGray dark:border-matteGray  rounded-2xl">
                  <div className="flex flex-col">
                    <div className="text-sm text-darkGray dark:text-frescoWhite pb-1">
                      Vibebucks
                    </div>
                    <div className="text-lg font-medium text-primary">
                      <CurrencyInput
                        prefix="$"
                        name="currencyInput"
                        id="currencyInput"
                        data-number-to-fixed="2"
                        data-number-stepfactor="100"
                        value={addAmount}
                        placeholder="$0.00"
                        className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-22rem h-12 placeholder-grayScale-gray placeholder:font-normal"
                        onChange={(e) => {
                          handleCurrencyInputChange(e, setaddAmount);
                        }}
                        onBlur={() =>
                          setaddAmount(Number(addAmount).toFixed(2))
                        }
                        onKeyDown={(event) => {
                          if (event.key === "Enter") handleAddMoney();
                        }}
                        allowDecimals
                        decimalsLimit="2"
                        decimalScale="2"
                        disableAbbreviations
                      />
                    </div>
                    <div className="flex flex-direction-column flex-wrap mt-2">
                      {[50, 250, 750].map((val, index) => {
                        if (!val) return null;
                        return (
                          <Chip
                            key={val + index}
                            content={`$${val}`}
                            onClick={() => {
                              setaddAmount(val);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className="flex items-center pt-2">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        size="vibhut-sm"
                        variant="vibhut-primary"
                        onClick={() => handleAddMoney()}
                        disabled={
                          addAmount === "" ||
                          addAmount <= 0 ||
                          addAmount > 10000 ||
                          addAmount < 10
                        }
                      >
                        Add Vibebucks
                      </Button>
                    )}
                  </div> */}
                  <div className="text-sm text-cvRed pt-1 px-1">
                    {handleVibebucksRes}
                  </div>
                </div>
              ) : (
                <div className="flex  flex-col justify-between p-3 border border-lightGray dark:border-matteGray  rounded-2xl">
                  {authUser?.stripe_data?.is_full_connected ? (
                    <React.Fragment>
                      <div className="flex flex-col">
                        <div className="text-sm text-darkGray dark:text-frescoWhite pb-1">
                          Vibebucks
                        </div>
                        <div className="text-lg font-medium text-primary">
                          <CurrencyInput
                            prefix="$"
                            name="currencyInput"
                            id="currencyInput"
                            data-number-to-fixed="2"
                            data-number-stepfactor="100"
                            value={withdrawAmount}
                            placeholder="$0.00"
                            className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-22rem h-12 placeholder-grayScale-gray placeholder:font-normal"
                            onChange={(e) => {
                              handleCurrencyInputChange(e, setwithdrawAmount);
                            }}
                            onBlur={() =>
                              setwithdrawAmount(
                                Number(withdrawAmount).toFixed(2),
                              )
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") withdrawVibebucks();
                            }}
                            allowDecimals
                            decimalsLimit="2"
                            decimalScale="2"
                            disableAbbreviations
                          />
                        </div>
                      </div>
                      <div className="flex items-center pt-2">
                        {isvibebuckswithdrawLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            size="vibhut-sm"
                            variant="vibhut-primary"
                            onClick={() => withdrawVibebucks()}
                            disabled={
                              withdrawAmount === "" || withdrawAmount <= 0
                            }
                          >
                            Withdraw Vibebucks
                          </Button>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="flex flex-col">
                      <div className="text-sm text-darkGray dark:text-frescoWhite pb-1">
                        But wait, you need to connect your bank account first.
                      </div>
                      {isConnectLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          variant="vibhut-primary"
                          onClick={() => connectStripeAcc()}
                        >
                          Connect your bank account
                        </Button>
                      )}
                    </div>
                  )}
                  <div className="text-sm text-cvRed pt-1 px-1">
                    {handleVibebucksRes}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="">
            <div className=" px-3 sm:w-96 ">
              <p className="text-sm  text-darkGray dark:text-frescoWhite">
                Your wallet is where you’ll load money for Vibebucks to make
                purchasing tickets for events, subscriptions and more, seamless.
              </p>
            </div>
            <div className="p-3 pt-2">
              <div className="flex justify-between p-3 border border-lightGray dark:border-matteGray  rounded-2xl">
                <div className="flex flex-col">
                  <div className="text-sm text-darkGray dark:text-frescoWhite">
                    Vibebucks
                  </div>
                  <div className="text-lg font-medium text-primary">
                    ${convertToDecimal(authUser?.wallet_balance)}
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                  {/* <div className="flex items-center ">
                    <Button
                      size="vibhut-sm"
                      variant="vibhut-primary"
                      onClick={() => {
                        setToggleWallet(true);
                        setToogleVibebuck(true);
                      }}
                    >
                      Add Vibebucks
                    </Button>
                  </div> */}
                  <div className="flex items-center pt-1">
                    <Button
                      size="vibhut-sm"
                      variant="vibhut-primary"
                      onClick={() => {
                        setToggleWallet(true);
                        setToogleVibebuck(false);
                      }}
                    >
                      Withdraw Vibebucks
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 pt-2">
              <div className="flex justify-between p-3 border border-lightGray dark:border-matteGray  rounded-2xl">
                <div className="flex flex-col">
                  <div className="flex items-center text-sm text-darkGray dark:text-frescoWhite ">
                    Pending Payout
                    <span className="pl-1">
                      <MoreInfo />
                    </span>
                  </div>
                  <div className="text-lg font-medium dark:text-white">
                    ${pendingBal}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 pt-2">
              <div className="flex justify-between p-3 border border-lightGray dark:border-matteGray  rounded-2xl">
                <div className="flex flex-col">
                  <div className="text-sm text-darkGray dark:text-frescoWhite">
                    Total Withdrawn Vibebucks
                  </div>
                  <div className="text-lg font-medium text-green-500 ">
                    ${stripeBal}
                  </div>
                </div>
                <div className="flex items-center ">
                  {iswithdrawLoading || isConnectLoading || manageAccLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {authUser?.stripe_data?.is_full_connected ? (
                        <div className="flex flex-col">
                          <Button
                            variant="vibhut-primary"
                            onClick={() => {
                              manageStripeAcc();
                            }}
                          >
                            Manage your Account
                          </Button>
                        </div>
                      ) : (
                        <Button
                          variant="vibhut-primary"
                          onClick={() => connectStripeAcc()}
                        >
                          Connect your bank account
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="text-sm text-cvRed pt-1 px-1">{handleRes}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const _renderNotificationModel = () => {
    return (
      <div className="flex flex-col my-3 ">
        {showWarning && (
          <div className="flex p-3 bg-primaryYellow  sm:w-96 rounded-2xl">
            <div className="flex justify-center items-center pr-2">
              <Warning />
            </div>
            <div className="">
              <p className="text-sm font-normal text-black ">
                Please add your email or phone number to the “Contact Info” tab
                to recieve notifications.
              </p>
            </div>
            <div
              onClick={() => setShowWarning(false)}
              className="flex align-item-center cursor-pointer"
            >
              <X />
            </div>
          </div>
        )}
        {(isPWA || !isMobile()) && <PushNotificationToggle />}
        {checkIsDesoUser(authUser) && (
          <div>
            <div>
              <div className="py-3 flex flex-row border-b dark:border-matteGray border-frescoWhite">
                <div className="w-4/5">
                  <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                    Receive messages from Vibehutlobby
                  </p>
                  <p className="text-xs text-gray-400">
                    You can receive messages from vibehutLobby on your DeSo
                    account.
                  </p>
                </div>
                <div className=" flex gap-2 items-center">
                  <Switch
                    checked={appSettings?.receiveMessageOnDeSoAccount}
                    onChange={() =>
                      handleChangeAppSettings("receiveMessageOnDeSoAccount")
                    }
                    className={`${
                      appSettings?.receiveMessageOnDeSoAccount
                        ? "bg-primary"
                        : "bg-gray-200"
                    } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                  >
                    <span
                      className={`${
                        appSettings?.receiveMessageOnDeSoAccount
                          ? "translate-x-6"
                          : "translate-x-1"
                      } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-row-reverse sm:w-96 pt-3">
          <span className="text-sm text-black dark:text-frescoWhite pl-4 font-ubuntu">
            SMS
          </span>
          <span className="text-sm text-black dark:text-frescoWhite font-ubuntu">
            Email
          </span>
        </div>
        <div className="flex flex-row sm:w-96 pt-2 border-b dark:border-matteGray border-frescoWhite pb-3">
          <div className=" w-2/3 flex flex-col">
            <div className="font-medium text-darkNight dark:text-frescoWhite">
              Rooms
            </div>
            <div className="text-sm text-darkGray">
              All room related notifications
            </div>
          </div>

          <div className="w-1/3 flex justify-end items-center">
            <div className="form-check form-check-inline pr-4">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="emailIsRoomEnabled"
                checked={notifSettings.emailIsRoomEnabled}
                value={notifSettings.emailIsRoomEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
            <div className="form-check form-check-inline">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="mobileIsRoomEnabled"
                checked={notifSettings.mobileIsRoomEnabled}
                value={notifSettings.mobileIsRoomEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row sm:w-96 pt-3 border-b dark:border-matteGray border-frescoWhite pb-3">
          <div className=" w-2/3 flex flex-col">
            <div className="font-medium text-darkNight dark:text-frescoWhite">
              Waves
            </div>
            <div className="text-sm text-darkGray">
              All wave related notifications
            </div>
          </div>

          <div className="w-1/3 flex justify-end items-center">
            <div className="form-check form-check-inline pr-4">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="emailIsWavesEnabled"
                checked={notifSettings.emailIsWavesEnabled}
                value={notifSettings.emailIsWavesEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
            <div className="form-check form-check-inline">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="mobileIsWavesEnabled"
                checked={notifSettings.mobileIsWavesEnabled}
                value={notifSettings.mobileIsWavesEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row sm:w-96 pt-3 border-b dark:border-matteGray border-frescoWhite pb-3">
          <div className=" w-2/3 flex flex-col">
            <div className="font-medium text-darkNight dark:text-frescoWhite">
              Event Reminders
            </div>
            <div className="text-sm text-darkGray">
              Reminder 10 minutes before an event begins
            </div>
          </div>

          <div className="w-1/3 flex justify-end items-center">
            <div className="form-check form-check-inline pr-4">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="emailIsEventEnabled"
                checked={notifSettings.emailIsEventEnabled}
                value={notifSettings.emailIsEventEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
            <div className="form-check form-check-inline">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="mobileIsEventEnabled"
                checked={notifSettings.mobileIsEventEnabled}
                value={notifSettings.mobileIsEventEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row sm:w-96 pt-3  pb-3">
          <div className=" w-2/3 flex flex-col">
            <div className="font-medium text-darkNight dark:text-frescoWhite">
              Users
            </div>
            <div className="text-sm text-darkGray">
              All user related notifications
            </div>
          </div>

          <div className="w-1/3 flex justify-end items-center">
            <div className="form-check form-check-inline pr-4">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="emailIsUserEnabled"
                checked={notifSettings.emailIsUserEnabled}
                value={notifSettings.emailIsUserEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
            <div className="form-check form-check-inline">
              <input
                className="appearance-none h-4 w-4 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                type="checkbox"
                name="mobileIsUserEnabled"
                checked={notifSettings.mobileIsUserEnabled}
                value={notifSettings.mobileIsUserEnabled}
                onChange={updateNotificationRecieveSettings}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col pt-3 space-y-6">
          <div className=" flex flex-col sm:w-96">
            <p className="dark:text-frescoWhite font-semibold ">
              Notification Sounds
            </p>
            <p className="text-darkGray text-sm">
              Notification banners play a sound to alert you.
            </p>
            <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Chat
                </p>

                <Switch
                  checked={appSettings.sound.chat}
                  onChange={() => handleChangeAppSettings("sound-chat")}
                  className={`${
                    appSettings.sound.chat ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      appSettings.sound.chat ? "translate-x-6" : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
            <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Wave
                </p>

                <Switch
                  checked={appSettings.sound.wave}
                  onChange={() => handleChangeAppSettings("sound-wave")}
                  className={`${
                    appSettings.sound.wave ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      appSettings.sound.wave ? "translate-x-6" : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
          </div>
          <div className=" flex flex-col sm:w-96  ">
            <p className="dark:text-frescoWhite font-semibold ">
              Notification Banners
            </p>
            <p className="text-darkGray text-sm">
              Notification’s received while logged in to Vibehut.
            </p>
            <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Chat
                </p>

                <Switch
                  checked={appSettings.banner.chat}
                  onChange={() => handleChangeAppSettings("banner-chat")}
                  className={`${
                    appSettings.banner.chat ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      appSettings.banner.chat
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
            <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Wave
                </p>

                <Switch
                  checked={appSettings.banner.wave}
                  onChange={() => handleChangeAppSettings("banner-wave")}
                  className={`${
                    appSettings.banner.wave ? "bg-primary" : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      appSettings.banner.wave
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
          </div>
          <div className=" flex flex-col sm:w-96  ">
            <p className="dark:text-frescoWhite font-semibold ">
              Decrypt content on server side
            </p>
            <p className="text-darkGray text-sm">
              Receive notifications with decrypted content
            </p>
            <div className="py-3 border-b dark:border-matteGray border-frescoWhite">
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
                  Chat
                </p>
                <Switch
                  checked={appSettings?.decryptOnServer?.chat}
                  onChange={() => handleChangeAppSettings("decrypt-chat")}
                  className={`${
                    appSettings?.decryptOnServer?.chat
                      ? "bg-primary"
                      : "bg-gray-200"
                  } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
                >
                  <span
                    className={`${
                      appSettings?.decryptOnServer?.chat
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderSubscriptions = () => {
    return <SubscriptionsPage />;
  };

  const _renderReferrals = () => {
    if (isSettingFetching) {
      return <Loader />;
    }
    return <ReferralSystem />;
  };

  const _renderGamification = () => {
    return <GamificationPage />;
  };

  const _renderPublicApi = () => {
    return <PublicApi />;
  };

  const _renderSettings = () => {
    return <Settings />;
  };

  return (
    <>
      <Transition.Root show={openMyProfile} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => {
            closeModal(dispatch);
            setOpenMyProfile(false);
          }}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 z-50"
            >
              <div
                className={`inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl w-343px h-[80vh] ${
                  activeModal === ModalTabs.PLANS
                    ? "sm:w-[1250px] sm:h-[750px]"
                    : "sm:w-660px"
                }   transform transition-all sm:my-8 sm:align-middle font-ubuntu`}
              >
                <div className="h-full">
                  <div className=" bg-white dark:bg-navyGray p-4 md:border-b border-frescoWhite dark:border-matteGray">
                    <div className="flex justify-between items-center  ">
                      <div className="flex items-center space-x-2">
                        <p className="font-medium text-lg text-black dark:text-frescoWhite">
                          My Account
                        </p>
                      </div>
                      <span
                        className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                        tabIndex={0}
                        onClick={() => {
                          closeModal(dispatch);
                          setOpenMyProfile(false);
                        }}
                      >
                        <Close />
                      </span>
                    </div>
                  </div>
                  <div className=" flex-row hidden md:flex h-full">
                    <div
                      className={`${
                        mobileModel ? "hidden" : "visible"
                      } sm:block  w-full ${
                        activeModal === ModalTabs.PLANS
                          ? "sm:w-2/12  "
                          : "sm:w-1/3"
                      }    border-r border-frescoWhite dark:border-matteGray p-4 overflow-y-auto custom-scrollbar h-[70vh]`}
                    >
                      {Object.keys(modalTabsData).map((tabKey, index) => (
                        <div
                          key={modalTabsData[tabKey].label + index}
                          className={`  ${
                            activeModal === tabKey
                              ? " text-darkNight bg-frescoWhite font-medium"
                              : "text-darkGray"
                          } cursor-pointer flex items-center rounded-xl p-2 mb-2`}
                          onClick={() => setActiveModal(tabKey)}
                        >
                          {modalTabsData[tabKey].icon}
                          <div className="mx-3">
                            {modalTabsData[tabKey].label}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      className={`${
                        mobileModel ? "visible" : "hidden"
                      } sm:block w-full ${
                        activeModal === ModalTabs.PLANS
                          ? "sm:w-10/12 "
                          : "sm:w-2/3"
                      } `}
                    >
                      <div
                        className={`${
                          activeModal === ModalTabs.NOTIFICATIONS
                            ? "block"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 h-[58vh] custom-scrollbar overflow-y-auto`}
                      >
                        {_renderNotificationModel()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.CONTACT
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderContactModal()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.PLANS ? "visible" : "hidden"
                        } bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        <PlanProfile
                          toggleWalletVibeBuck={toggleWalletVibeBuck}
                        />
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.WALLET
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderWalletModal()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.SUBSCRIPTION
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderSubscriptions()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.GAMIFICATION
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderGamification()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.REFERRAL
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderReferrals()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.PUBLICAPI
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderPublicApi()}
                      </div>
                      <div
                        className={`${
                          activeModal === ModalTabs.SETTINGS
                            ? "visible"
                            : "hidden"
                        }   bg-white dark:bg-navyGray px-5 pt-6 `}
                      >
                        {_renderSettings()}
                      </div>
                    </div>
                  </div>

                  <div className="block md:hidden h-[80vh]">
                    <div className="w-full max-w-md px-2  sm:px-0">
                      <Tab.Group defaultIndex={1}>
                        <Tab.List className="flex space-x-1 border-b dark:border-matteGray border-frescoWhite overflow-x-auto">
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={0}
                          >
                            Notification
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={1}
                          >
                            Contact
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={2}
                          >
                            Wallet
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={3}
                          >
                            Plans
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={4}
                          >
                            Subscriptions
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={5}
                          >
                            Gamification
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={6}
                          >
                            Public&nbsp;API&nbsp;Keys
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                "focus:outline-none dark:text-frescoWhite text-darkNight",
                                selected ? " border-b-2 border-primary" : "",
                              )
                            }
                            tabIndex={7}
                          >
                            Settings
                          </Tab>
                          {systemSetting.isReferralSystemActive && (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "pr-2.5 py-2.5 text-sm leading-5 font-medium",
                                  "focus:outline-none dark:text-frescoWhite text-darkNight",
                                  selected ? " border-b-2 border-primary" : "",
                                )
                              }
                              tabIndex={8}
                            >
                              Referrals
                            </Tab>
                          )}
                        </Tab.List>
                        <Tab.Panels className="mt-4">
                          <Tab.Panel key={0} className="px-2">
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderNotificationModel()}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={1}>{_renderContactModal()}</Tab.Panel>
                          <Tab.Panel key={2}>
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderWalletModal()}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={3}>
                            <div className="custom-scrollbar overflow-y-auto">
                              <PlanProfile />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={4}>
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderSubscriptions()}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={5}>
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderGamification()}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={6}>
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderPublicApi()}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={7}>
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderSettings()}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel key={8}>
                            <div className="h-[50vh] custom-scrollbar overflow-y-auto">
                              {_renderReferrals()}
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <WithdrawStatus
        isSuccessOpen={isSuccessOpen}
        setIsSuccessOpen={setIsSuccessOpen}
        transferId={transferId}
      />
    </>
  );
}
