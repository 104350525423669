import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import { isMobile } from "../../utils";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Typography, Grid, Hidden as MaterialHidden } from "@material-ui/core";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import TalkingUsersModal from "../../VibehutUI/TalkingUsersModal";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import { getRoomName } from "../../utils/utils";
import DoubleStar from "../../VibehutUI/Icons/DoubleStar";
import MenuOption, { IconContainer } from "./Menu/Menu";
import BoostCall from "../../VibehutUI/Boostcall";
import { useAppState } from "../../state";
import StopRecordingIcon from "../../icons/StopRecordingIcon";
import StartRecordingIcon from "../../icons/StartRecordingIcon";
import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import useVibehutAuth from "../../state/useVibehutAuth/useVibehutAuth";
import ReactionMenu from "../Buttons/ReactionMenu/ReactionMenu";
import useLiveVideo from "../../hooks/useLiveVideo/useLiveVideo";
import Loader from "../../VibehutUI/Loader";

const Hidden: any = MaterialHidden;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#1F2023",
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: "fixed",
      display: "flex",
      padding: "0 1.43em",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      margin: "0 auto",
      borderRadius: "8px",
      height: "104px",
      width: "300px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white",
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7",
        },
      },
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isRecording = useIsRecording();
  const { updateRecordingRules } = useVibehutAuth();
  const { roomType } = useAppState();
  const isReconnecting = roomState === "reconnecting";
  const { room } = useVideoContext();
  const { roomData, sendReactionInCall } = useVibehutData(globals.roomId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowRxn, setIsShowRxn] = useState(false);
  const { isLive, disableLiveVideo, isLoading } = useLiveVideo();

  const callId = room!.name;
  const { authUser } = useAuthUser();
  const _liveUsers = roomData?.calls[callId];

  const authUserId = authUser?._id;
  const roomOwnerId = roomData.owner?._id;
  const isRoomAdmin =
    roomOwnerId === authUserId ||
    (roomData.moderators !== undefined &&
      roomData.moderators.includes(authUserId));

  useEffect(() => {
    if (globals.boostedCallData) {
      const boostCallInitiatedUser = _liveUsers.find(
        (user) => user._id === globals.boostedCallData.userId,
      );
      if (boostCallInitiatedUser) {
        globals.boostedCallData = {};
      }
    }
  }, [globals.boostedCallData]);

  const showBoostCallModal = () => {
    globals.showModal("Set Call Message", <BoostCall />);
  };

  return (
    <>
      {isSharingScreen && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.screenShareBanner}
        >
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()} type="button">
            Stop Sharing
          </Button>
        </Grid>
      )}
      <div className="bg-darkNight flex justify-center md:space-x-10 space-x-3 py-4 relative  transition-all duration-500 ease-in">
        <div className="absolute left-0 top-3 text-white text-sm font-medium hidden md:block pl-4 max-w-lg">
          <p className="flex flex-col items-start">
            <span className="custom-one-line-clamp ">
              {getRoomName(roomData, callId, authUser)}
            </span>
            <p className="text-primaryGray text-left flex items-center text-sm font-medium">
              {roomData.talkingUsersCount} Talking, {roomData.lobbyUsersCount}{" "}
              in Lobby
              <span
                className="text-primary cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                &nbsp; See All &nbsp;
              </span>
            </p>
          </p>
        </div>
        <div className="flex space-x-2 justify-center items-center z-40">
          <ToggleAudioButton
            disabled={isReconnecting}
            fromConnectingPage={false}
          />
          <ToggleVideoButton
            disabled={isReconnecting}
            fromConnectingPage={false}
          />
          <ReactionMenu
            isShowRxn={isShowRxn}
            setIsShowRxn={setIsShowRxn}
            sendReactionInCall={sendReactionInCall}
          />
          {!isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
          <Hidden smDown>
            <MenuOption />
          </Hidden>
          <div className="">
            <EndCallButton />
          </div>
        </div>

        {!roomData.isWaveCall &&
          !roomData.isInstantRoom &&
          !roomData.is_private && (
            <div
              className="bg-gradient-btn flex text-sm align-center text-white  cursor-pointer transition-all duration-500 ease-in shadow-md  items-center rounded-full justify-center px-3 py-2.5"
              onClick={showBoostCallModal}
            >
              <DoubleStar />
              <p className="ml-1">
                {roomData.isBoostedCallRoom
                  ? "Call Message Set"
                  : "Set Call Message"}{" "}
              </p>
            </div>
          )}

        {isLive && isRoomAdmin && (
          <div className="px-0">
            <button
              className="bg-cvRed flex align-center text-sm text-white  cursor-pointer transition-all duration-500 ease-in shadow-md  items-center rounded-full justify-center pl-[16px] pr-[12px]  py-2.5"
              onClick={() => {
                if (isLoading) {
                  return;
                }
                disableLiveVideo();
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader color="white" ratio="h-6 w-6" />
              ) : (
                <Typography variant="body1">
                  <span className={`text-frescoWhite `}>End Live</span>
                </Typography>
              )}
            </button>
          </div>
        )}

        {roomType !== "peer-to-peer" &&
          roomType !== "go" &&
          isRoomAdmin &&
          isRecording && (
            <div className="px-1">
              <button
                className={`${
                  isRecording ? "bg-cvRed" : "bg-gradient-btn"
                } flex align-center text-sm text-white  cursor-pointer transition-all duration-500 ease-in shadow-md  items-center rounded-full justify-center pl-[16px] pr-[12px]  py-2.5`}
                onClick={() => {
                  if (isRecording) {
                    updateRecordingRules(room!.sid, [
                      { type: "exclude", all: true },
                    ]);
                  } else {
                    updateRecordingRules(room!.sid, [
                      { type: "include", all: true },
                    ]);
                  }
                }}
                disabled={!Boolean(isRoomAdmin)}
              >
                <IconContainer>
                  {isRecording ? (
                    <StopRecordingIcon color="#F2F2F2" />
                  ) : (
                    <StartRecordingIcon color="#F2F2F2" />
                  )}
                </IconContainer>
                <Typography variant="body1">
                  <span className={`text-frescoWhite `}>
                    {isRecording ? "Stop" : "Start"} Recording
                  </span>
                </Typography>
              </button>
            </div>
          )}
      </div>
      <TalkingUsersModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        usersCount={roomData.liveUsersCount}
        talkingUsers={Object.values(roomData.calls)}
        lobby_users={roomData.lobbyUsers}
      />
    </>
  );
}
