import { Dialog, Transition } from "@headlessui/react";
import Button from "../Button";
import React, { Fragment, useContext } from "react";
import { logout } from "../../actions/authActions";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";
import { useDesoProtocol } from "../../hooks/useDesoProtocol";
import { checkIsDesoUser } from "../../utils/utils";
import { useDisconnect } from "wagmi";

function InCompleteProfileModal({ isOpen }) {
  const { dispatch, state } = useContext(StoreContext);
  const { auth } = state;
  const { disconnectAsync } = useDisconnect();
  const history = useHistory();
  const desoProtocol = useDesoProtocol();

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex flex-row justify-between px-4 py-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    One More Thing...
                  </Dialog.Title>
                </div>
                <hr className="" />
                <div className="px-4 py-4">
                  <p className="text-sm text-gray-500">
                    It looks like you signed up with a DeSo account, but you
                    haven’t finished setting it up. ( if already finished
                    setting up the profile in DeSo, try refreshing the page. )
                  </p>
                </div>

                <div className=" px-4 py-4 flex flex-row-reverse ">
                  <Button size="vibhut-sm" variant="vibhut-primary">
                    <a
                      target="_blank"
                      href="https://diamondapp.com/update-profile"
                      rel="noreferrer"
                    >
                      Go to DeSo
                    </a>
                  </Button>

                  <div className="mr-3">
                    <Button
                      size="vibhut-sm"
                      variant="vibhut-secondary"
                      onClick={async () => {
                        if (checkIsDesoUser(auth?.user)) {
                          const jwt = await desoProtocol.identity.jwt();
                          if (jwt) {
                            await desoProtocol.identity.logout();
                          }
                        }
                        disconnectAsync();
                        await logout(dispatch);
                        history.replace("/login");
                      }}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default InCompleteProfileModal;
