interface ToolTipProps {
  isOpen: boolean;
  handleToolTip: () => void;
}

function ToolTip({ isOpen, handleToolTip }: ToolTipProps) {
  return (
    <div className={`absolute z-50 -top-36   ${isOpen ? 'block' : 'hidden'}`}>
      <div className="relative ml-[66px] mb-4">
        <div className="bg-white text-xs py-1 right-0 bottom-full w-60 rounded-lg">
          <div>
            <div className="flex justify-between items-center py-2 px-4">
              <p className="font-medium text-lg text-black flex items-center space-x-2">
                <span>Record a Call</span>
                <span className="px-2 py-0.5 text-white bg-primaryGreen rounded text-xs">New</span>
              </p>
              <span
                className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                onClick={() => {
                  handleToolTip();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-600"
                  fill="none"
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
            </div>
            <div className="w-full  border-b border-frescoWhite"></div>
            <div className="py-2 px-4">
              <p className="text-sm text-darkGray font-normal text-left">
                Recordings are automatically saved to “Recent Calls,” so you can watch and download it later.
              </p>
            </div>
          </div>
          <svg
            className="absolute text-white h-6 w-full left-0 top-full"
            x="0px"
            y="0px"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 255 255"
          >
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default ToolTip;
