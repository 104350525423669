import * as actionTypes from "../constants";
import { Promotion } from "../types";

interface InitialState {
  promotionData: Promotion;
  myPromotions: Promotion[];
  isFetchingMyPromotions: boolean;
  offset: string | null;
  isFetching: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isDeleting: boolean;
}

const initialState: InitialState = {
  promotionData: {
    recordId: "",
    userId: "",
    image: "",
    title: "",
    link: "",
    description: "",
    startDate: null,
  },
  myPromotions: [],
  offset: "",
  isFetchingMyPromotions: false,
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  isDeleting: false,
};

function promotionReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_MY_PROMOTION_REQUEST:
      return {
        ...state,
        isFetchingMyPromotions: true,
      };
    case actionTypes.GET_APPROVED_PROMOTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_APPROVED_PROMOTION_SUCCESS: {
      return {
        ...state,
        promotionData: action.payload.promotion as Promotion,
        isFetching: false,
      };
    }

    case actionTypes.GET_MY_PROMOTION_SUCCESS: {
      return {
        ...state,
        myPromotions: action.payload.promotions,
        offset: action.payload.offset,
        isFetchingMyPromotions: false,
      };
    }

    case actionTypes.GET_APPROVED_PROMOTION_FAILURE:
    case actionTypes.GET_MY_PROMOTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchingMyPromotions: false,
      };

    case actionTypes.CREATE_PROMOTION_REQUEST:
      return { ...state, isCreating: true };

    case actionTypes.CREATE_PROMOTION_SUCCESS: {
      return {
        ...state,
        myPromotions: [action.payload, ...state.myPromotions],
        isCreating: false,
      };
    }

    case actionTypes.CREATE_PROMOTION_FAILURE:
      return { ...state, isCreating: false };
    default:
      return state;
  }
}

export default promotionReducer;
