import React from 'react';

function CameraFlip() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2022_11418)">
        <path
          d="M15.5834 12.6667C15.5834 13.0203 15.4429 13.3594 15.1929 13.6095C14.9428 13.8595 14.6037 14 14.2501 14H2.25008C1.89646 14 1.55732 13.8595 1.30727 13.6095C1.05722 13.3594 0.916748 13.0203 0.916748 12.6667V5.33333C0.916748 4.97971 1.05722 4.64057 1.30727 4.39052C1.55732 4.14048 1.89646 4 2.25008 4H4.91675L6.25008 2H10.2501L11.5834 4H14.2501C14.6037 4 14.9428 4.14048 15.1929 4.39052C15.4429 4.64057 15.5834 4.97971 15.5834 5.33333V12.6667Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.24992 11.3333C9.72268 11.3333 10.9166 10.1394 10.9166 8.66667C10.9166 7.19391 9.72268 6 8.24992 6C6.77716 6 5.58325 7.19391 5.58325 8.66667C5.58325 10.1394 6.77716 11.3333 8.24992 11.3333Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2022_11418">
          <rect width="16" height="16" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CameraFlip;
