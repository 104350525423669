import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";
import axios from "../utils/axios";

function StripeCallback(props) {
  const data = new URLSearchParams(props.location.search);
  const status = data.get("status");
  const { state, dispatch } = useContext(StoreContext);
  const { auth } = state;
  const history = useHistory();
  const updatePaymentInfo = async () => {
    await axios
      .post("/payment/update-payment-info", data, {
        headers: {
          Authorization: `Bearer ${auth.user.token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_ISPAYMENT_STATUS",
          payload: {
            status: status,
            message: "Your VibeBucks purchase was successful.",
          },
        });
        history.push("/");
      });
  };

  React.useEffect(() => {
    updatePaymentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className="text-center mt-10">Updating Payment Info...</div>;
}

export default StripeCallback;
