import { useContext, useState } from "react";
import {
  componentDecorator,
  getDecryptMessage,
  isMobile,
  renderMessageText,
} from "../../utils/utils";
import Linkify from "react-linkify";
import globals from "../../globals";
import EditChatModal from "../../components/chat/EditChatModal";
import Edit from "../Icons/Edit";
import DeleteModel from "../DeleteModel";
import Trash from "../Icons/Trash";
import { StoreContext } from "../../Context/StoreContext";
import * as actionTypes from "../../constants";
import { getAmiFullMsgResponse } from "../../services/chatService";
import Loader from "../Loader";
import ChatReaction from "../../components/chat/ChatReaction";
import Reactions from "../../components/chat/Reactions";
import Rocket from "../Icons/Rocket";
import ProfileModal from "../ProfileModal";
import { useRef } from "react";
import AddReaction from "../Icons/AddReaction";
import PopOver from "../Popover";
import ReactHtmlParser from "react-html-parser";
import { Lightbox } from "react-modal-image";
import AllReactionsModal from "../../components/chat/AllReactionModal";
import AllReactions from "../../components/chat/AllReactions";
import EditChatModalWrapper from "../../components/chat/EditChatModalWrapper";
import useLongPress from "../../hooks/useLongPress";
import Close from "../Icons/Close";
import Markdown from "markdown-to-jsx";
import ReplyIcon from "../Icons/ReplyArrow";

function RoomChatBubble({
  message,
  isSend,
  chat,
  deleteMessage,
  editMessage,
  setAttachedMessage,
  addReaction,
  removeUserOrAddReaction,
  addUserToReaction,
  isAmiBot,
  username,
  isGroupChat,
  roomId,
  isModal = false,
  isPersonaChat,
}) {
  const isReply = chat?.isReply;
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isShowOptionIcon, setIsShowOptionIcon] = useState(false);
  const [isFullResponseLoading, setIsFullResponseLoading] = useState(false);
  const [showEditPopover, setShowEditPopover] = useState(false);
  const [showReactionPopover, setShowReactionPopover] = useState(false);
  const [showReplyPopover, setShowReplyPopover] = useState(false);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [showReactionsPopover, setShowReactionsPopover] = useState(false);
  const { state, dispatch } = useContext(StoreContext);
  const [isOpenReactionModal, setIsOpenReactionModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const menuRef = useRef();
  const [lastTapTime, setLastTapTime] = useState(0);
  const {
    auth: { user },
  } = state;
  const [isOpenAddReaction, setIsOpenAddReaction] = useState(false);
  const [profileUser, setProfileUser] = useState({});
  const [showImgBox, setShowImgBox] = useState(false);

  const onLongPress = () => {
    !chat?.deletedAt && setIsShowOptionIcon(true);
  };

  const onClick = () => {};

  const handleReplyToMessage = () => {
    setAttachedMessage(chat);
  };

  const longPressEvent = useLongPress(onLongPress, onClick);
  const direction = isSend
    ? "float-right bg-primary text-left text-white"
    : `float-left bg-bubbleBg text-black text-left`;

  const editChat = () => {
    if (isModal) {
      setIsOpenEditModal(true);
    } else {
      globals.showModal(
        "Edit message",
        <EditChatModal chat={chat} editMessage={editMessage} />,
      );
    }
  };

  const allReactions = () => {
    if (isModal) {
      setIsOpenReactionModal(true);
    } else {
      globals.showModal("All Reactions", <AllReactions chat={chat} />);
    }
  };

  const handleDelete = () => {
    try {
      deleteMessage(chat._id);
      dispatch({
        type: actionTypes.TOGGLE_SCROLL,
        payload: false,
      });
      dispatch({
        type: actionTypes.DELETE_MESSAGE,
        payload: {
          chatId: chat._id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = (chatId) => {
    if (chatId) {
      setIsOpenDelete(true);
    }
  };

  const handleFullMsgClick = async (chatId) => {
    setIsFullResponseLoading(true);
    try {
      const fullMsg = await getAmiFullMsgResponse(user?.token, chatId);
      setIsFullResponseLoading(false);
      openFullMsgModal("Full message", fullMsg.data);
    } catch (e) {
      setIsFullResponseLoading(false);
    } finally {
      setIsFullResponseLoading(false);
    }
  };

  const openFullMsgModal = (title, fullMsg) => {
    globals.showModal(
      title,
      <>
        <div className="overflow-y-auto custom-scrollbar max-h-72 pr-2 flex flex-col gap-1 divide-y dark:divide-matteGray dark:text-frescoWhite whitespace-pre-wrap break-words text-wrap">
          {fullMsg}
        </div>
      </>,
    );
  };

  const _renderSpaces = (spaceLen) => {
    let spaces = "";
    for (let i = 0; i < spaceLen; i++) {
      spaces += " ";
    }
    return spaces;
  };

  const spaceLen = message ? message.length - message?.trimLeft().length : 0;

  const openUserModal = (user) => {
    setProfileUser(user);
  };

  const closeUserModal = () => {
    setProfileUser({});
  };

  let pressTimer;
  function handleTouchStart() {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapTime;
    if (tapLength < 500 && tapLength > 0) {
      handleDoubleClick();
    }
    setLastTapTime(currentTime);
    if (lastTapTime > 500 && menuRef.current) {
      setIsShowOptionIcon(true);
      pressTimer = setTimeout(() => {
        menuRef.current.click();
      }, 600);
    }
  }

  function handleTouchMove() {
    clearTimeout(pressTimer);
  }

  function handleTouchEnd() {
    clearTimeout(pressTimer);
  }

  function handleDoubleClick() {
    const reaction = {
      emoji: "❤️",
      unified: "2764-fe0f",
      owner: user?._id,
    };
    addReaction({ ...reaction, chatId: chat._id });
  }

  const initialReactions = [
    { emoji: "❤️", unified: "2764-fe0f", name: "Heart" },
    { emoji: "👍", unified: "1f44d", name: "Thumbs Up" },
    { emoji: "🙌", unified: "1f64c", name: "Raising Hands" },
    { emoji: "😂", unified: "1f602", name: "Face with Tears of Joy" },
    { emoji: "👀", unified: "1f440", name: "Eyes" },
    { emoji: "👎", unified: "1f44e", name: "Thumbs Down" },
    { emoji: "🤖", unified: "1f916", name: "Robot" },
  ];

  const handleInstantReaction = (react) => {
    const reaction = {
      emoji: react.emoji,
      unified: react.unified,
      owner: user?._id,
    };
    addReaction({ ...reaction, chatId: chat._id });
    setIsShowOptionIcon(false);
  };

  const toggleEditPopover = (isShow) => {
    if (isMobile()) {
      return;
    }
    setShowEditPopover(isShow);
  };
  const toggleAddReactionPopover = (isShow) => {
    if (isMobile()) {
      return;
    }
    setShowReactionPopover(isShow);
  };
  const toggleReplyMessagePopover = (isShow) => {
    if (isMobile()) {
      return;
    }
    setShowReplyPopover(isShow);
  };
  const toggleDeletePopover = (isShow) => {
    if (isMobile()) {
      return;
    }
    setShowDeletePopover(isShow);
  };
  const toggleReactionsPopover = (isShow) => {
    if (isMobile()) {
      return;
    }
    setShowReactionsPopover(isShow);
  };

  const getMessage = (chat) => {
    if (isAmiBot && message) {
      return ReactHtmlParser(message);
    } else if (isPersonaChat && message) {
      return <Markdown options={{ forceInline: true }}>{message}</Markdown>;
    } else if (chat.usersMentioned && chat.usersMentioned.length > 0) {
      return renderMessageText(chat, openUserModal);
    } else {
      if (message) {
        if (!chat?.isEncrypted) {
          return message?.trimLeft().replace(/^@ami\b/g, "");
        }
        const decryptMessage = getDecryptMessage(
          roomId,
          chat?.isEncrypted,
          message,
        );
        const msg = decryptMessage?.trimLeft().replace(/^@ami\b/g, "");
        return msg ? msg : "";
      }
      return "";
    }
  };
  const renderContentAsPerType = (attachedMessage) => {
    if (attachedMessage?.attachments?.length > 0) {
      return (
        <div className="w-full h-full flex flex-col">
          <span className="text-xs font-bold cursor-pointer text-black ">
            {attachedMessage?.username}
          </span>
          <img
            alt="media"
            src={attachedMessage?.attachments[0].mediaUrl}
            className="rounded-xl object-contain h-60"
            loading="lazy"
          />
        </div>
      );
    }
    if (attachedMessage?.msg) {
      const chat = {
        message: attachedMessage.msg,
        ...attachedMessage,
      };
      return (
        <div
          className=" bg-frescoWhite dark:bg-darkGray p-[10px] border-l-[2px] border-l-matteGray dark:border-l-white"
          style={{ borderRadius: "2px" }}
        >
          <div className="w-full h-full flex flex-col">
            <span className="text-xs font-bold cursor-pointer text-black dark:text-frescoWhite">
              {chat?.username}
            </span>
            <span className=" text-black dark:text-frescoWhite text-sm">
              {renderMessageText(chat, undefined, false)}
            </span>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const handleShowLightBox = () => {
    setShowImgBox(true);
  };

  return (
    <>
      <div className="relative">
        <div
          className={`flow-root overflow-hidden ${isSend ? "pr-1.5" : "pl-2"} `}
        >
          <div
            onMouseEnter={() => {
              !chat?.deletedAt && setIsShowOptionIcon(true);
            }}
            onMouseLeave={() => setIsShowOptionIcon(false)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <span
              style={{
                maxWidth: isPersonaChat ? "100%" : "20rem",
                minWidth: "2.5rem",
              }}
              className={`rounded-2xl text-sm ${direction} ${
                isSend
                  ? `relative py-2 px-5 from-me`
                  : `relative py-2 px-5 from-them`
              } `}
              // {...longPressEvent}
            >
              <span className="flex items-end justify-center">
                <span className="flex whitespace-pre-wrap break-words text-wrap flex-col">
                  {isReply && renderContentAsPerType(chat?.replyFor)}
                  <span className="group ">
                    {isGroupChat && username && (
                      <div
                        className="text-xs font-bold cursor-pointer"
                        onClick={() => {
                          chat?.user && openUserModal(chat.user);
                        }}
                      >
                        ~ {username}
                      </div>
                    )}
                    {message.trim().substring(0, 4) === "@ami" && (
                      <span className="font-bold">
                        {_renderSpaces(spaceLen)}
                        @ami
                      </span>
                    )}

                    {isPersonaChat ? (
                      getMessage(chat)
                    ) : (
                      <Linkify componentDecorator={componentDecorator}>
                        {getMessage(chat)}
                      </Linkify>
                    )}
                    {chat?.isEdited && (
                      <span className="text-xs"> (edited)</span>
                    )}
                    {chat?.persistedAt && (
                      <span className="text-xs"> (persisted)</span>
                    )}
                  </span>
                  {chat?.attachments?.length > 0 && !chat?.deletedAt && (
                    <div>
                      <img
                        alt="room"
                        src={chat?.attachments[0].mediaUrl}
                        className="rounded-xl object-contain cursor-pointer"
                        loading="lazy"
                        onClick={handleShowLightBox}
                      />
                      {showImgBox && (
                        <Lightbox
                          medium={chat?.attachments[0].mediaUrl}
                          large={chat?.attachments[0].mediaUrl}
                          onClose={() => setShowImgBox(false)}
                        />
                      )}
                    </div>
                  )}
                  {!chat?.deletedAt && (
                    <Reactions
                      chat={chat}
                      removeUserOrAddReaction={removeUserOrAddReaction}
                      addUserToReaction={addUserToReaction}
                    />
                  )}
                </span>
              </span>
            </span>
            {isShowOptionIcon && (
              <div
                className={`bg-white dark:bg-navyGray flex absolute ${
                  isSend ? "right-1" : "left-1"
                } z-20 -top-8 rounded p-1 items-center gap-0.5 shadow`}
              >
                {initialReactions.map((reaction) => (
                  <div
                    className="cursor-pointer text-lg"
                    onClick={() => handleInstantReaction(reaction)}
                    key={reaction.unified}
                  >
                    {reaction.emoji}
                  </div>
                ))}
                <div className="relative">
                  {showReactionPopover && (
                    <div className="z-30">
                      <PopOver text="React" size="12" leftPosition="-left-4" />
                    </div>
                  )}
                  <div
                    className="cursor-pointer"
                    onClick={() => setIsOpenAddReaction(true)}
                    onMouseEnter={() => toggleAddReactionPopover(true)}
                    onMouseLeave={() => toggleAddReactionPopover(false)}
                  >
                    <AddReaction />
                  </div>
                </div>
                <div className="relative">
                  {showReplyPopover && (
                    <div className="z-30">
                      <PopOver text="Reply" size="12" leftPosition="-left-4" />
                    </div>
                  )}
                  <div
                    className="cursor-pointer dark:text-frescoWhite"
                    onClick={() => handleReplyToMessage()}
                    onMouseEnter={() => toggleReplyMessagePopover(true)}
                    onMouseLeave={() => toggleReplyMessagePopover(false)}
                  >
                    <ReplyIcon />
                  </div>
                </div>
                {isSend && (
                  <div className="relative pl-1">
                    {showEditPopover && (
                      <div className="z-30">
                        <PopOver text="Edit" size="12" leftPosition="-left-4" />
                      </div>
                    )}
                    <div
                      className="cursor-pointer dark:text-frescoWhite"
                      onClick={() => editChat()}
                      onMouseEnter={() => toggleEditPopover(true)}
                      onMouseLeave={() => toggleEditPopover(false)}
                    >
                      <Edit />
                    </div>
                  </div>
                )}
                {isAmiBot &&
                  !isSend &&
                  (isFullResponseLoading ? (
                    <span className="ml-2">
                      <Loader ratio="w-4 h-4" justify="flex-start" />
                    </span>
                  ) : (
                    <div
                      className="text-sm cursor-pointer dark:text-frescoWhite pl-1"
                      onClick={() => {
                        handleFullMsgClick(chat._id);
                      }}
                    >
                      Full AI Message
                    </div>
                  ))}
                {isPersonaChat &&
                  !isSend &&
                  (isFullResponseLoading ? (
                    <span className="ml-2">
                      <Loader ratio="w-4 h-4" justify="flex-start" />
                    </span>
                  ) : (
                    <div
                      className="text-sm cursor-pointer dark:text-frescoWhite pl-1"
                      onClick={() => {
                        handleFullMsgClick(chat._id);
                      }}
                    >
                      Full AI Message
                    </div>
                  ))}
                {isSend && (
                  <div className="relative pl-1">
                    {showDeletePopover && (
                      <div className="z-30">
                        <PopOver
                          text="Delete"
                          size="12"
                          leftPosition="-left-4"
                        />
                      </div>
                    )}
                    <div
                      className="cursor-pointer"
                      onClick={() => handleDeleteClick(chat._id)}
                      onMouseEnter={() => toggleDeletePopover(true)}
                      onMouseLeave={() => toggleDeletePopover(false)}
                    >
                      <Trash />
                    </div>
                  </div>
                )}
                {chat.reactions && chat.reactions.length > 0 && (
                  <div className="relative pl-1">
                    {showReactionsPopover && (
                      <div className="z-30">
                        <PopOver
                          text="Reactions"
                          size="24"
                          leftPosition="-left-12"
                        />
                      </div>
                    )}
                    <div
                      className="cursor-pointer dark:text-frescoWhite"
                      onClick={() => allReactions()}
                      onMouseEnter={() => toggleReactionsPopover(true)}
                      onMouseLeave={() => toggleReactionsPopover(false)}
                    >
                      <Rocket />
                    </div>
                  </div>
                )}

                <div className="relative pl-1 md:hidden block">
                  <div
                    className="cursor-pointer dark:text-frescoWhite"
                    onClick={() => setIsShowOptionIcon(false)}
                  >
                    <Close />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <DeleteModel
          handleDelete={handleDelete}
          title={"Delete"}
          isOpen={isOpenDelete}
          setIsOpen={setIsOpenDelete}
          text={" Are you sure to delete this message ?"}
        />
        <ChatReaction
          isOpen={isOpenAddReaction}
          setIsOpen={setIsOpenAddReaction}
          chat={chat}
          addReaction={addReaction}
        />
        <ProfileModal
          isOpen={profileUser._id ? true : false}
          setIsOpen={(open) => !open && closeUserModal()}
          user={profileUser}
        />
        <AllReactionsModal
          isOpen={isOpenReactionModal}
          setIsOpen={setIsOpenReactionModal}
          chat={chat}
        />
        <EditChatModalWrapper
          isOpen={isOpenEditModal}
          setIsOpen={setIsOpenEditModal}
          chat={chat}
          editMessage={editMessage}
        />
      </div>
    </>
  );
}

export default RoomChatBubble;
