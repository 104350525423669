import React from "react";

function ChatSkeleton() {
  return (
    <div className="py-4 flex flex-col gap-3">
      <p className="rounded-2xl w-48 animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-20  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-40  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
      <p className="rounded-2xl w-28 animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      <p className="rounded-2xl w-52 animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-20  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-40  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
      <p className="rounded-2xl w-28 animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      <p className="rounded-2xl w-40 animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      <p className="rounded-2xl w-60 animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-20  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-40  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
      <div className=" flex items-end justify-end">
        <p className="rounded-2xl w-60  animate-pulse bg-frescoWhite dark:bg-darkGray h-7"></p>
      </div>
    </div>
  );
}

export default ChatSkeleton;
