import { baseURL } from '../constants';

export const getEventById = async (eventId: string) => {
  const endpoint = `${baseURL}/api/v1/events/event-by-id/${eventId}`;

  return fetch(endpoint, {
    method: 'Get',
    headers: {
      'content-type': 'application/json',
    },
  }).then(res => res.json());
};
