import React, { useContext } from "react";
import usePushNotifications from "../../hooks/usePushNotifications";
import { Switch } from "@headlessui/react";
import { StoreContext } from "../../Context/StoreContext";
import Button from "../Button";

function PushNotificationToggle() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user: authUser },
  } = state;
  const { userConsent, onClickAskUserPermission, onClickSendNotification } =
    usePushNotifications(authUser?.token);
  const isConsentGranted = userConsent === "granted";
  return (
    <div>
      <div>
        <div className="py-3 flex flex-row border-b dark:border-matteGray border-frescoWhite">
          <div className="w-4/5">
            <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
              Push Notifications
            </p>
            <p className="text-xs text-gray-400">
              Stay updated with real-time alerts and notifications.
            </p>
          </div>
          <div className=" flex gap-2 items-center">
            {/* {isConsentGranted && (
              <Button onClick={onClickSendNotification}>Preview</Button>
            )} */}
            <Switch
              checked={isConsentGranted}
              onChange={onClickAskUserPermission}
              className={`${
                isConsentGranted ? "bg-primary" : "bg-gray-200"
              } relative inline-flex items-center  rounded-full h-31px w-51px float-right`}
            >
              <span
                className={`${
                  isConsentGranted ? "translate-x-6" : "translate-x-1"
                } inline-block w-27px h-27px transform bg-white shadow rounded-full duration-200`}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PushNotificationToggle;
