import Loader from '../../VibehutUI/Loader'
import DoubleStar from '../../VibehutUI/Icons/DoubleStar'

interface Props {
    isGenerating: string;
    setDataForPersona: (type: string) => void;
    type: string
}

function GenerateTextLabel({ isGenerating, setDataForPersona, type }: Props) {
    return (
        <p className="text-sm font-medium text-primary flex items-center gap-1 cursor-pointer" onClick={() => setDataForPersona(type)} > {isGenerating === type ? <Loader ratio='h-4 w-4' rightMargin={false} /> : <DoubleStar />}  <span> {isGenerating === type ? 'Generating' : 'Generate'}  using AI</span></p>
    )
}

export default GenerateTextLabel