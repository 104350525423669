import moment from "moment";
import * as actionTypes from "../constants";
import { filterDuplicate, groupEventsByDates } from "../utils/utils";

const initialState = {
  events: [],
  isMoreRoomEventsFetching: false,
  isMoreRoomEvents: false,
  roomEventsOffset: 0,
  isAllMoreFetching: false,
  isAllMore: false,
  isFetching: false,
  isRsvpUpdating: false,
  error: null,
  eventId: null,
  pastEvents: [],
  liveEvents: [],
  upcomingEvents: [],
  popularEvents: [],
  homePageEvent: null,
  isEventsPageLoading: true,
  allEventsOfRoom: [],
  roomUpcomigEvents: [],
  roomPastEvents: [],
  roomLiveEvents: [],
  userLiveEvents: [],
  userPastEvents: [],
  userUpcomingEvents: [],
  userProfileLiveEvents: [],
  userProfileUpcomingEvents: [],
  userProfilePastEvents: [],
  isUserEventsFetching: false,
  isProfileUserEventsFetching: false,
  allEvents: [],
  myEvents: [],
  calenderEvents: [],
  newlyAddedEvents: [],
  offset: 0,
  isMore: false,
  isMoreFetching: false,
  isPopularMore: false,
  isPopularMoreFetching: false,
  HomeUpcomingEvents: [],
  isHomeUpcomingMore: false,
  isHomeUpcomingFetching: false,
  HomeliveEvents: [],
  isHomeLiveMore: false,
  isHomeLiveMoreFetching: false,
  userAllEventOffset: 0,
  isUserAllEventMore: false,
  isUserAllEventFetching: false,
  updatedRsvpEvent: undefined,
  roomAllEventOffset: 0,
  isRoomAllEventMore: false,
  isRoomAllEventFetching: false,
  isFetchingModalEvents: false,
  rsvpEventErrorMessage: "",
  groupedLiveEvents: {},
  groupedUpcomingEvents: {},
  groupedPastEvents: {},
};

function eventReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ROOM_EVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreRoomEventsFetching: true,
        };
      }
      return { ...state, isFetching: true };

    case actionTypes.GET_ROOM_EVENTS_SUCCESS: {
      const ofst =
        action.payload.events.length >= 3
          ? action.payload.offset + 3
          : action.payload.offset;
      if (action.payload.loadMore) {
        return {
          ...state,
          isFetching: false,
          events: [...state.events, ...action.payload.events],
          roomEventsOffset: ofst,
          isMoreRoomEventsFetching: false,
          isMoreRoomEvents: action.payload.events.length >= 3 ? true : false,
        };
      } else {
        return {
          ...state,
          isFetching: false,
          events: action.payload.events,
          roomEventsOffset: ofst,
          isMoreRoomEvents: action.payload.events.length >= 3 ? true : false,
        };
      }
    }
    case actionTypes.RESET_ROOM_EVENTS_SUCCESS: {
      return {
        ...state,
        events: [],
        roomEventsOffset: 0,
        isMoreRoomEvents: false,
      };
    }
    case actionTypes.GET_ROOM_EVENTS_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.GET_ROOM_ALLEVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isRoomAllEventFetching: true,
        };
      }
      return { ...state, isFetchingModalEvents: true };

    case actionTypes.GET_ROOM_ALLEVENTS_SUCCESS: {
      const ofst =
        action.payload.events.length >= 6
          ? action.payload.offset + 6
          : action.payload.offset;
      if (action.payload.loadMore) {
        return {
          ...state,
          isFetchingModalEvents: false,
          roomPastEvents: [...state.roomPastEvents, ...action.payload.events],
          roomAllEventOffset: ofst,
          isRoomAllEventFetching: false,
          isRoomAllEventMore: action.payload.events.length >= 6 ? true : false,
        };
      }
      return {
        ...state,
        roomPastEvents: action.payload.events,
        isFetchingModalEvents: false,
        roomAllEventOffset: ofst,
        isRoomAllEventMore: action.payload.events.length >= 6 ? true : false,
      };
    }

    case actionTypes.GET_ROOM_ALLEVENTS_FAILURE:
      return {
        ...state,
        isFetchingModalEvents: false,
        isRoomAllEventFetching: false,
      };

    case actionTypes.CREATE_EVENT_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.CREATE_EVENT_SUCCESS: {
      const events = [action.payload, ...state.events];
      // events.map((event) => {

      // })
      events.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.startDate) - new Date(b.startDate);
      });

      // events.sort((a, b) => {
      //   return moment(a.startDate).format() - moment(b.startDate).format();
      // });
      return {
        ...state,
        events: events,
        isFetching: false,
      };
    }

    case actionTypes.CREATE_EVENT_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.UPDATE_EVENT_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.UPDATE_EVENT_SUCCESS: {
      const {
        events,
        liveEvents,
        pastEvents,
        upcomingEvents,
        roomUpcomigEvents,
        roomPastEvents,
        roomLiveEvents,
        allEvents,
        calenderEvents,
        HomeUpcomingEvents,
        HomeliveEvents,
        newlyAddedEvents,
        popularEvents,
      } = state;
      const index = events.findIndex(
        (event) => event._id === action.payload._id,
      );
      const allEventsIndex = allEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const liveIndex = liveEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const pastIndex = pastEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const upcomingIndex = upcomingEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const roomUpcomingIndex = roomUpcomigEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const roomPastIndex = roomPastEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const roomLiveIndex = roomLiveEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const calenderEventIndex = calenderEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const HomeUpcomingEventIndex = HomeUpcomingEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const popularEventIndex = popularEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const HomeliveEventsIndex = HomeliveEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      const newlyAddedEventIndex = newlyAddedEvents.findIndex(
        (event) => event._id === action.payload._id,
      );

      events[index] = action.payload;
      allEvents[allEventsIndex] = action.payload;
      liveEvents[liveIndex] = action.payload;
      pastEvents[pastIndex] = action.payload;
      upcomingEvents[upcomingIndex] = action.payload;
      roomUpcomigEvents[roomUpcomingIndex] = action.payload;
      roomPastEvents[roomPastIndex] = action.payload;
      roomLiveEvents[roomLiveIndex] = action.payload;
      calenderEvents[calenderEventIndex] = action.payload;
      HomeUpcomingEvents[HomeUpcomingEventIndex] = action.payload;
      popularEvents[popularEventIndex] = action.payload;
      HomeliveEvents[HomeliveEventsIndex] = action.payload;
      newlyAddedEvents[newlyAddedEventIndex] = action.payload;

      const groupedLiveEvents = groupEventsByDates(liveEvents);
      const groupedUpcomingEvents = groupEventsByDates(upcomingEvents);
      const groupedPastEvents = groupEventsByDates(pastEvents);

      return {
        ...state,
        events: events,
        allEvents: allEvents,
        liveEvents: liveEvents,
        pastEvents: pastEvents,
        upcomingEvents: upcomingEvents,
        roomUpcomigEvents: roomUpcomigEvents,
        roomPastEvents: roomPastEvents,
        roomLiveEvents: roomLiveEvents,
        calenderEvents: calenderEvents,
        HomeUpcomingEvents: HomeUpcomingEvents,
        HomeliveEvents: HomeliveEvents,
        newlyAddedEvents: newlyAddedEvents,
        popularEvents: popularEvents,
        isFetching: false,
        groupedLiveEvents: groupedLiveEvents,
        groupedUpcomingEvents: groupedUpcomingEvents,
        groupedPastEvents: groupedPastEvents,
      };
    }

    case actionTypes.UPDATE_EVENT_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.DELETE_EVENT_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.DELETE_EVENT_SUCCESS: {
      const _liveEvents = state.liveEvents.filter(
        (event) => event._id !== action.payload._id,
      );
      const _pastEvents = state.pastEvents.filter(
        (event) => event._id !== action.payload._id,
      );
      const _upcomingEvents = state.upcomingEvents.filter(
        (event) => event._id !== action.payload._id,
      );

      const groupedLiveEvents = groupEventsByDates(_liveEvents);
      const groupedUpcomingEvents = groupEventsByDates(_upcomingEvents);
      const groupedPastEvents = groupEventsByDates(_pastEvents);

      return {
        ...state,
        events: state.events.filter(
          (event) => event._id !== action.payload._id,
        ),
        pastEvents: _pastEvents,
        liveEvents: _liveEvents,
        upcomingEvents: _upcomingEvents,
        roomPastEvents: state.roomPastEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        roomLiveEvents: state.roomLiveEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        roomUpcomigEvents: state.roomUpcomigEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        userProfileLiveEvents: state.userProfileLiveEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        userProfileUpcomingEvents: state.userProfileUpcomingEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        userProfilePastEvents: state.userProfilePastEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        calenderEvents: state.calenderEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        HomeUpcomingEvents: state.HomeUpcomingEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        HomeliveEvents: state.HomeliveEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        newlyAddedEvents: state.newlyAddedEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        popularEvents: state.popularEvents.filter(
          (event) => event._id !== action.payload._id,
        ),
        isFetching: false,
        groupedLiveEvents: groupedLiveEvents,
        groupedUpcomingEvents: groupedUpcomingEvents,
        groupedPastEvents: groupedPastEvents,
      };
    }

    case actionTypes.DELETE_EVENT_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.UPDATE_RSVP_REQUEST:
      return { ...state, eventId: action.payload, isRsvpUpdating: true };

    case actionTypes.UPDATE_RSVP_SUCCESS:
      const {
        events,
        upcomingEvents,
        popularEvents,
        calenderEvents,
        newlyAddedEvents,
        HomeUpcomingEvents,
        HomeliveEvents,
      } = state;
      const index = events.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const popularEventsIndex = popularEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const calenderEventIndex = calenderEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const upcomingIndex = upcomingEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const userUpcomingIndex = state.userUpcomingEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const userLiveEventsIndex = state.userLiveEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const userProfileUpcomingIndex =
        state.userProfileUpcomingEvents.findIndex(
          (event) => event._id === action.payload.event._id,
        );
      const userProfilePastEventsIndex = state.userProfilePastEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const userProfileLiveEventsIndex = state.userProfileLiveEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const newlyAddedIndex = newlyAddedEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const homeUpcomingIndex = HomeUpcomingEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      const HomeliveEventsIndex = HomeliveEvents.findIndex(
        (event) => event._id === action.payload.event._id,
      );
      events[index] = action.payload.event;
      upcomingEvents[upcomingIndex] = action.payload.event;
      popularEvents[popularEventsIndex] = action.payload.event;
      calenderEvents[calenderEventIndex] = action.payload.event;

      const userUpcomingEvents = [...state.userUpcomingEvents];
      const userLiveEvents = [...state.userLiveEvents];
      const userProfileUpcomingEvents = [...state.userProfileUpcomingEvents];
      const userProfilePastEvents = [...state.userProfilePastEvents];
      const userProfileLiveEvents = [...state.userProfileLiveEvents];

      userUpcomingEvents[userUpcomingIndex] = action.payload.event;
      userLiveEvents[userLiveEventsIndex] = action.payload.event;
      userProfileUpcomingEvents[userProfileUpcomingIndex] =
        action.payload.event;
      userProfilePastEvents[userProfilePastEventsIndex] = action.payload.event;
      userProfileLiveEvents[userProfileLiveEventsIndex] = action.payload.event;
      newlyAddedEvents[newlyAddedIndex] = action.payload.event;
      HomeUpcomingEvents[homeUpcomingIndex] = action.payload.event;
      HomeliveEvents[HomeliveEventsIndex] = action.payload.event;

      const groupedUpcomingEvents = groupEventsByDates(upcomingEvents);

      return {
        ...state,
        isRsvpUpdating: false,
        events: events,
        upcomingEvents: upcomingEvents,
        popularEvents: popularEvents,
        eventId: null,
        userUpcomingEvents: userUpcomingEvents,
        userLiveEvents: userLiveEvents,
        userProfileUpcomingEvents: userProfileUpcomingEvents,
        userProfilePastEvents: userProfilePastEvents,
        userProfileLiveEvents: userProfileLiveEvents,
        // allEvents: state.events.filter(
        //   (event) => event._id !== action.payload._id
        // ),
        calenderEvents: calenderEvents,
        newlyAddedEvents: newlyAddedEvents,
        HomeUpcomingEvents: HomeUpcomingEvents,
        HomeliveEvents: HomeliveEvents,
        groupedUpcomingEvents: groupedUpcomingEvents,
      };
    case actionTypes.UPDATE_RSVP_FAILURE:
      return { ...state, isRsvpUpdating: false, eventId: null };

    case actionTypes.GET_ALL_EVENTS_REQUEST:
      // return { ...state, isFetching: true };
      if (action.loadMore) {
        return {
          ...state,
          isAllMoreFetching: true,
        };
      }
      return { ...state };

    case actionTypes.GET_ALL_EVENTS_SUCCESS: {
      const ofst =
        action.payload.events.length >= 6
          ? action.payload.offset + 6
          : action.payload.offset;

      const currntTime = moment().format();
      const liveEvents = action.payload.events.filter((event) => {
        return (
          moment(event.startDate).format() < currntTime &&
          moment(event.endDate).format() > currntTime
        );
      });
      const upcomingEvents = action.payload.events.filter((event) => {
        return (
          moment(event.endDate).format() > currntTime &&
          moment(event.startDate).format() > currntTime
        );
      });
      const pastEvents = action.payload.events.filter((event) => {
        return (
          moment(event.startDate).format() < currntTime &&
          moment(event.endDate).format() < currntTime
        );
      });
      upcomingEvents.sort(function (a, b) {
        return new Date(a.startDate) - new Date(b.startDate);
      });

      if (action.payload.loadMore) {
        let allLiveEvents = [...state.liveEvents, ...liveEvents];
        allLiveEvents = filterDuplicate(allLiveEvents);

        let allUpcomingEvents = [...state.upcomingEvents, ...upcomingEvents];
        allUpcomingEvents = filterDuplicate(allUpcomingEvents);

        let allPastEvents = [...state.pastEvents, ...pastEvents];
        allPastEvents = filterDuplicate(allPastEvents);

        const allEvents = [...state.allEvents, ...action.payload.events];

        const groupedLiveEvents = groupEventsByDates(allLiveEvents);
        const groupedUpcomingEvents = groupEventsByDates(allUpcomingEvents);
        const groupedPastEvents = groupEventsByDates(allPastEvents);
        return {
          ...state,
          liveEvents: allLiveEvents,
          upcomingEvents: allUpcomingEvents,
          pastEvents: allPastEvents,
          allEvents: allEvents,
          offset: ofst,
          isAllMoreFetching: false,
          isAllMore: action.payload.events.length >= 6 ? true : false,
          groupedLiveEvents: {
            ...groupedLiveEvents,
          },
          groupedUpcomingEvents: {
            ...groupedUpcomingEvents,
          },
          groupedPastEvents: {
            ...groupedPastEvents,
          },
        };
      } else {
        const groupedLiveEvents = groupEventsByDates(liveEvents);
        const groupedUpcomingEvents = groupEventsByDates(upcomingEvents);
        const groupedPastEvents = groupEventsByDates(pastEvents);
        return {
          ...state,
          liveEvents: liveEvents,
          upcomingEvents: upcomingEvents,
          pastEvents: pastEvents,
          isFetching: false,
          isEventsPageLoading: false,
          allEvents: action.payload.events,
          offset: ofst,
          isAllMore: action.payload.events.length >= 6 ? true : false,
          groupedLiveEvents: groupedLiveEvents,
          groupedUpcomingEvents: groupedUpcomingEvents,
          groupedPastEvents: groupedPastEvents,
        };
      }
    }

    case actionTypes.GET_ALL_EVENTS_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        myEvents: action.payload.events,
        isFetching: false,
      };

    case actionTypes.GET_CALENDER_EVENTS_REQUEST:
      if (action.payload.firstTimeFetching) {
        return { ...state, isFetching: true };
      }
      return { ...state };

    case actionTypes.GET_CALENDER_EVENTS_SUCCESS: {
      let calenderEvents = [...state.calenderEvents, ...action.payload.events];
      calenderEvents = filterDuplicate(calenderEvents);

      return {
        ...state,
        calenderEvents: calenderEvents,
        isFetching: false,
      };
    }

    case actionTypes.GET_USER_RSVPD_EVENTS_REQUEST:
      return { ...state, isUserEventsFetching: true };

    case actionTypes.GET_USER_RSVPD_EVENTS_SUCCESS: {
      const currntTime = moment().format();
      const userLiveEvents = action.payload.events.filter((event) => {
        return (
          moment(event.startDate).format() < currntTime &&
          moment(event.endDate).format() > currntTime
        );
      });
      const userUpcomingEvents = action.payload.events.filter((event) => {
        return (
          moment(event.endDate).format() > currntTime &&
          moment(event.startDate).format() > currntTime
        );
      });
      const userPastEvents = action.payload.events.filter((event) => {
        return (
          moment(event.startDate).format() < currntTime &&
          moment(event.endDate).format() < currntTime
        );
      });
      userUpcomingEvents.sort((a, b) => {
        return moment(a.startDate).format() - moment(b.startDate).format();
      });
      userPastEvents.sort(function (a, b) {
        return new Date(b.startDate) - new Date(a.startDate);
      });
      return {
        ...state,
        userUpcomingEvents: userUpcomingEvents.reverse(),
        userLiveEvents: userLiveEvents,
        userPastEvents: userPastEvents,
        isUserEventsFetching: false,
      };
    }

    case actionTypes.GET_USER_RSVPD_EVENTS_FAILURE:
      return { ...state, isUserEventsFetching: false };

    case actionTypes.GET_POPULAR_EVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isPopularMoreFetching: true,
        };
      }
      return { ...state };

    case actionTypes.GET_POPULAR_EVENTS_SUCCESS: {
      const ofst =
        action.payload.events.length >= 6
          ? action.payload.offset + 6
          : action.payload.offset;

      if (action.payload.loadMore) {
        const popularEvents = [
          ...state.popularEvents,
          ...action.payload.events,
        ];

        return {
          ...state,
          popularEvents: popularEvents,
          offset: ofst,
          isPopularMoreFetching: false,
          isPopularMore: action.payload.events.length >= 6 ? true : false,
        };
      } else {
        return {
          ...state,
          popularEvents: action.payload.events,
          offset: ofst,
          isPopularMore: action.payload.events.length >= 6 ? true : false,
        };
      }
    }
    case actionTypes.GET_P_USER_RSVPD_EVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isUserAllEventFetching: true,
        };
      }
      return { ...state, isProfileUserEventsFetching: true };

    case actionTypes.GET_P_USER_RSVPD_EVENTS_SUCCESS: {
      const ofst =
        action.payload.events.length >= 6
          ? action.payload.offset + 6
          : action.payload.offset;

      const currntTime = moment().format();
      const userLiveEvents = action.payload.events?.filter((event) => {
        return (
          moment(event.startDate).format() < currntTime &&
          moment(event.endDate).format() > currntTime
        );
      });
      const userUpcomingEvents = action.payload.events?.filter((event) => {
        return (
          moment(event.endDate).format() > currntTime &&
          moment(event.startDate).format() > currntTime
        );
      });
      const userPastEvents = action.payload.events.filter((event) => {
        return (
          moment(event.startDate).format() < currntTime &&
          moment(event.endDate).format() < currntTime
        );
      });
      userUpcomingEvents.sort(function (a, b) {
        return new Date(a.startDate) - new Date(b.startDate);
      });

      userPastEvents.sort(function (a, b) {
        return new Date(a.startDate) - new Date(b.startDate);
      });
      if (action.payload.loadMore) {
        return {
          ...state,
          isProfileUserEventsFetching: false,
          userProfileUpcomingEvents: [
            ...state.userProfileUpcomingEvents,
            ...userUpcomingEvents,
          ],
          userProfilePastEvents: [
            ...state.userProfilePastEvents,
            ...userPastEvents,
          ],
          userProfileLiveEvents: [
            ...state.userProfileLiveEvents,
            ...userLiveEvents,
          ],
          userAllEventOffset: ofst,
          isUserAllEventFetching: false,
          isUserAllEventMore: action.payload.events.length >= 6 ? true : false,
        };
      } else {
        return {
          ...state,
          userProfileUpcomingEvents: userUpcomingEvents,
          userProfilePastEvents: userPastEvents,
          userProfileLiveEvents: userLiveEvents,
          isProfileUserEventsFetching: false,
          userAllEventOffset: ofst,
          isUserAllEventMore: action.payload.events.length >= 6 ? true : false,
        };
      }
    }
    case actionTypes.GET_POPULAR_EVENTS_FAILURE:
      return { ...state, isFetching: false, isPopularMoreFetching: false };

    case actionTypes.GET_P_USER_RSVPD_EVENTS_FAILURE:
      return { ...state, isProfileUserEventsFetching: false };

    case actionTypes.GET_LIVE_EVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isHomeLiveMoreFetching: true,
        };
      }
      return { ...state };

    case actionTypes.GET_LIVE_EVENTS_SUCCESS: {
      if (action.payload.loadMore) {
        let liveEvents = [...state.HomeliveEvents, ...action.payload.events];
        liveEvents = filterDuplicate(liveEvents);

        return {
          ...state,
          HomeliveEvents: liveEvents,
          isHomeLiveMoreFetching: false,
          isHomeLiveMore: action.payload.events.length >= 6 ? true : false,
        };
      } else {
        return {
          ...state,
          HomeliveEvents: action.payload.events,
          isHomeLiveMore: action.payload.events.length >= 6 ? true : false,
        };
      }
    }
    case actionTypes.GET_LIVE_EVENTS_FAILURE:
      return { ...state, isFetching: false, isHomeLiveMoreFetching: false };

    case actionTypes.GET_UPCOMING_EVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isHomeUpcomingFetching: true,
        };
      }
      return { ...state };

    case actionTypes.GET_UPCOMING_EVENTS_SUCCESS: {
      if (action.payload.loadMore) {
        let upComingEvents = [
          ...state.HomeUpcomingEvents,
          ...action.payload.events,
        ];
        upComingEvents = filterDuplicate(upComingEvents);

        return {
          ...state,
          HomeUpcomingEvents: upComingEvents,
          isHomeUpcomingFetching: false,
          isHomeUpcomingMore: action.payload.events.length >= 6 ? true : false,
        };
      } else {
        return {
          ...state,
          HomeUpcomingEvents: action.payload.events,
          isHomeUpcomingMore: action.payload.events.length >= 6 ? true : false,
        };
      }
    }
    case actionTypes.GET_UPCOMING_EVENTS_FAILURE:
      return { ...state, isFetching: false, isMoreFetching: false };

    case actionTypes.GET_HOMEPAGE_EVENT_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.GET_HOMEPAGE_EVENT_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_HOMEPAGE_EVENT_SUCCESS: {
      return {
        ...state,
        homePageEvent: action.payload.event,
        isFetching: false,
      };
    }

    case actionTypes.UPDATE_HOME_RSVP_REQUEST:
      return { ...state, eventId: action.payload, isRsvpUpdating: true };

    case actionTypes.UPDATE_HOME_RSVP_SUCCESS:
      return {
        ...state,
        homePageEvent: action.payload.event,
        eventId: null,
      };
    case actionTypes.UPDATE_HOME_RSVP_FAILURE:
      return { ...state, isRsvpUpdating: false, eventId: null };

    case actionTypes.GET_NEWLY_ADDED_EVENTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      return { ...state };

    case actionTypes.GET_NEWLY_ADDED_EVENTS_SUCCESS: {
      const ofst =
        action.payload.events?.length >= 6
          ? action.payload.offset + 6
          : action.payload.offset;

      if (action.payload.loadMore) {
        let newlyAddedEvents = [
          ...state.newlyAddedEvents,
          ...action.payload.events,
        ];
        newlyAddedEvents = filterDuplicate(newlyAddedEvents);

        return {
          ...state,
          newlyAddedEvents: newlyAddedEvents,
          offset: ofst,
          isMoreFetching: false,
          isMore: action.payload.events?.length >= 6 ? true : false,
        };
      } else {
        return {
          ...state,
          newlyAddedEvents: action.payload.events,
          offset: ofst,
          isMore: action.payload.events?.length >= 6 ? true : false,
        };
      }
    }

    case actionTypes.GET_NEWLY_ADDED_EVENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isMoreFetching: false,
      };

    case actionTypes.SET_RSVP_UPDATED_EVENT: {
      return {
        ...state,
        updatedRsvpEvent: action.payload.event,
      };
    }
    case actionTypes.SET_RSVP_EVENT_ERROR_MESSAGE:
      return {
        ...state,
        isRsvpUpdating: false,
        eventId: null,
        rsvpEventErrorMessage: action.payload.message,
      };

    default:
      return state;
  }
}

export default eventReducer;
