import { Dialog, Transition } from "@headlessui/react";
import Button from "../Button";
import React, { Fragment } from "react";
import Loader from "../Loader";

interface RoomPasswordModalProps {
  isOpen: boolean;
  onContinue: () => void;
  onLeave: () => void;
  showContinueLoading: boolean;
}

function UserInAnotherRoomModal({
  isOpen,
  onContinue,
  onLeave,
  showContinueLoading,
}: RoomPasswordModalProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle font-ubuntu w-450px">
                <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-darkNight dark:text-frescoWhite">
                      But wait...
                    </p>
                  </div>
                </div>
                <div className="bg-white dark:bg-navyGray p-4">
                  <div className="flex font-ubuntu">
                    <div className="w-full flex items-center relative">
                      <div className="my-auto w-full flex flex-col text-darkNight dark:text-frescoWhite">
                        You might be active in another room/call, by clicking on
                        continue you&apos;ll be disconnected from previous call.
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" px-4 py-3 flex flex-row justify-end space-x-3 dark:bg-navyGray">
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={onLeave}
                  >
                    Leave
                  </Button>
                  <Button
                    disabled={showContinueLoading}
                    onClick={onContinue}
                    size="vibhut-sm"
                    variant="vibhut-primary"
                  >
                    {showContinueLoading ? (
                      <Loader color="white" />
                    ) : (
                      "Continue"
                    )}
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default UserInAnotherRoomModal;
