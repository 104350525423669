import * as actionTypes from "../constants";
import { getUserData } from "../services/userService";

const initialState = {
  data: [],
  isFetching: false,
  error: null,
  totalNotifications: 0,
  offset: 0,
  isMore: false,
  isMoreFetching: false,
  instantNotifications: [],
};

const getInstantNotificationType = (type) => {
  switch (type) {
    case "user_send_message":
      return "chat";
    case "user_calling":
      return "calling";
    case "user_call_missed":
      return "missed";
    case "user_call_decline":
      return "decline";
    case "user_invitation":
      return "invitation";
    case "waved":
      return "wave";
    case "composition_created":
      return "composition_created";
    default:
      return "";
  }
};

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS_REQUEST: {
      if (action.loadMore) {
        return {
          ...state,
          isMoreFetching: true,
        };
      }
      if (action.isInstant) {
        return { ...state };
      }
      return { ...state, isFetching: true };
    }

    case actionTypes.GET_NOTIFICATIONS_SUCCESS: {
      if (action.payload.isInstant) {
        const instantNotifications = action.payload.notifications.map(
          (notification) => {
            return {
              user:
                notification?.usersMentioned?.length > 0
                  ? getUserData(notification.usersMentioned[0])
                  : {},
              type: getInstantNotificationType(notification.type),
              message: notification.body,
              roomKey: notification?.metaData?.roomId
                ? notification?.metaData?.roomId
                : "",
              id: notification._id,
              time: notification.createdAt,
              isInstant: true,
              notification: notification,
              isRoomMessage:
                Boolean(notification?.metaData?.isRoomMessage) || false,
            };
          },
        );

        return {
          ...state,
          instantNotifications: [
            ...state.instantNotifications,
            ...instantNotifications,
          ],
        };
      }
      const ofst =
        action.payload.notifications.length >= 15
          ? action.payload.offset + 15
          : action.payload.offset;

      const notifications = action.payload.notifications.filter(
        (notification) => notification?.body,
      );

      // const notifications = action.payload.notifications.filter(
      //   (notification) => {
      //     return (
      //       notification.type !== "waved" && notification.type !== "waved_back"
      //     );
      //   }
      // );
      if (action.payload.loadMore) {
        return {
          ...state,
          data: [...state.data, ...notifications],
          isMoreFetching: false,
          offset: ofst,
          isMore: action.payload.notifications.length >= 15 ? true : false,
        };
      }
      return {
        ...state,
        data: notifications,
        isFetching: false,
        totalNotifications: 0,
        offset: ofst,
        isMore: action.payload.notifications.length >= 15 ? true : false,
      };
    }

    case actionTypes.GET_NOTIFICATIONS_FAILURE:
      return { ...state, isFetching: false, isMoreFetching: false };

    case actionTypes.GET_COUNT_NOTIFICATIONS_REQUEST:
      return state;

    case actionTypes.GET_COUNT_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        totalNotifications: action.payload.notifications,
      };
    }

    case actionTypes.GET_COUNT_NOTIFICATIONS_FAILURE:
      return state;

    case actionTypes.ADD_INSTANT_NOTIFICATION: {
      let notifications = state.instantNotifications;
      notifications = notifications.filter(
        (notf) =>
          notf.id !== action.payload.notification.id &&
          notf.type !== action.payload.notification.type,
      );
      return {
        ...state,
        instantNotifications: [action.payload.notification, ...notifications],
      };
    }
    case actionTypes.REMOVE_INSTANT_NOTIFICATION: {
      return {
        ...state,
        instantNotifications: state.instantNotifications.filter(
          (notification) => notification.id !== action.payload.id,
        ),
      };
    }
    case actionTypes.REMOVE_ALL_INSTANT_NOTIFICATION: {
      return {
        ...state,
        instantNotifications: [],
      };
    }
    default:
      return state;
  }
}

export default notificationReducer;
