// Description: Reducer for UI state
// create initial state to display or hide UI
import * as actionTypes from "../constants/actionTypes";
const initialState = {
  selectedEvent: null,
  message: null,
  type: null,
  showEventSubscriptionModal: false,
};
function eventModalReducer(state = initialState, action) {
  function toggleChange(stateName) {
    switch (stateName) {
      case actionTypes.OPEN_EVENT_MODAL:
        return {
          selectedEvent: action.payload.selectedEvent,
          message: action.payload.message,
          type: action.payload.type,
          showEventSubscriptionModal: true,
        };
      case actionTypes.CLOSE_EVENT_MODAL:
        return {
          selectedEvent: null,
          showEventSubscriptionModal: false,
        };
      default:
        return {
          selectedEvent: null,
          showEventSubscriptionModal: false,
        };
    }
  }

  switch (action.type) {
    case actionTypes.OPEN_EVENT_MODAL:
      return toggleChange(actionTypes.OPEN_EVENT_MODAL);
    case actionTypes.CLOSE_EVENT_MODAL:
      return toggleChange(actionTypes.CLOSE_EVENT_MODAL);
    default:
      return state;
  }
}
export default eventModalReducer;
