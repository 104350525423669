import React from 'react';

function DeSo({ ratio = 20, isGreyScaleActive = false }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isGreyScaleActive && `custom-grayscale`}`}
    >
      <path
        d="M3.00049 2V5.99937L13.7659 11.8471L3.00049 17.9999H11.8705C14.7047 17.9999 17.0003 16.031 17.0003 13.6018V10.0012L3.00049 2Z"
        fill="url(#paint0_linear_888_2374)"
      />
      <path
        d="M3.00049 2L17.0003 10.0012V6.39809C17.0003 3.96892 14.7022 2 11.8705 2H3.00049Z"
        fill="url(#paint1_linear_888_2374)"
      />
      <path d="M10.3649 10.0013L3 14.0006V18L13.7654 11.8471L10.3649 10.0013Z" fill="url(#paint2_linear_888_2374)" />
      <defs>
        <linearGradient
          id="paint0_linear_888_2374"
          x1="6.442"
          y1="5.59824"
          x2="11.1277"
          y2="17.1716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0057FF" />
          <stop offset="1" stopColor="#00A3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_888_2374"
          x1="8.72631"
          y1="5.67955"
          x2="17.7578"
          y2="3.23968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0283FF" />
          <stop offset="1" stopColor="#01C2FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_888_2374"
          x1="5.61253"
          y1="16.5182"
          x2="17.9382"
          y2="-0.208463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0157FF" />
          <stop offset="1" stopColor="#00FFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

// function DeSo() {
//   return (
//     <img
//       height={20}
//       width={20}
//       alt="DeSoIcon"
//       style={{ height: 20, width: 20 }}
//       src="/DeSo.png"
//     ></img>
//   );
// }

export default DeSo;
