import React, { useCallback, useContext, useRef, useState } from "react";
import moment from "moment-timezone";
import "./App.css";
import Routes from "./routes/Routes";
import { ThemeProvider } from "./Context/ThemeContext";
import { StoreContext } from "./Context/StoreContext";
import SvgDefinations from "./VibehutUI/Icons/SvgDefinations";
import { googleAnalyticsActions } from "../src/utils/google-analytics/google-analytics";
import { getLoggedInUserData } from "./actions/authActions";
import { baseURL } from "./utils/axios";
import * as io from "socket.io-client";
import { getAuthUserData } from "./actions/authActions";
import {
  getMyRoomCount,
  getMyRoomSlugCount,
  getRoomSubscriberCount,
} from "./actions/roomAction";
import { getMyExternalVideosCount } from "./actions/publishStoreActions";
import { getAllRoles } from "./actions/rolesAction";
import {
  getExchangeRate,
  getSystemSetting,
} from "./actions/systemSettingAction";
import { getMySubscriptions } from "./actions/subscriptions";
import globals from "./globals";
import AccountStatus from "./VibehutUI/AccountStatus";
import * as actionTypes from "./constants";
import { addPushNotificationSubscription } from "./utils/swHelper";
import { getPremiumCreationAccess } from "./actions/eventSubscriptionAction";
import { getPremiumCreationAccess as getUserPremiumAccess } from "./actions/premiumCreatorAction";
import { getAccessPasses } from "./actions/actionPassActions";
import { getUserReferral } from "./actions/referralAction";
import {
  createReferralTransaction,
  getReferralTransaction,
} from "./actions/referralTransactionAction";
import Button from "./VibehutUI/Button";
import { DesoProtocolProvider } from "./Context/DesoProtocolProvider";
import useIsPWA from "./hooks/usePWA";
import { getLatestFeedPosts } from "./actions/feedActions";
import { getApprovedPromotion } from "./actions/promotionAction";
import { getPromotedPartners } from "./actions/partnerAction";
import { getMyCallCount } from "./actions/callActions";
import { Web3ModalProvider } from "./Context/Web3ModalProvider";

function App() {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth,
    user,
    event: { rsvpEventErrorMessage },
    setting: { systemSetting },
    room: { roomId },
    notification: { totalNotifications },
  } = state;
  const socketRef = useRef();
  const isPWA = useIsPWA();
  const [isAccountStatusOpen, setIsAccountStatusOpen] = useState(false);
  // const beforeunloadFunction = () => {
  //   //call when browser is closed or tab is closed
  //   window.addEventListener("beforeunload", function (e) {
  //     Mixpanel.time_event("Session End");
  //     //call time_event again at Log Out to send an event with the session time
  //     Mixpanel.track("Session End");
  //   });
  // };

  // React.useEffect(() => {
  //   Mixpanel.time_event("Session Start");
  //   Mixpanel.track("Session Start");

  //   if (auth.user) {
  //     const userData = getUserData(auth.user);

  //     Mixpanel.identify(auth.user._id);

  //     if (userData) {
  //       Mixpanel.people.set({
  //         name: userData.username,
  //         ...userData,
  //       });
  //     }
  //   }

  //   beforeunloadFunction();
  // }, []);

  const changeFavicon = (iconUrl) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon !== null) {
      favicon.href = iconUrl;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = iconUrl;
      document.head.appendChild(newFavicon);
    }
  };

  React.useEffect(() => {
    if (totalNotifications && totalNotifications > 0) {
      changeFavicon(`${process.env.PUBLIC_URL}/favicon_filled.ico`);
    } else {
      changeFavicon(`${process.env.PUBLIC_URL}/favicon.ico`);
    }
  }, [totalNotifications]);

  const getExchangeRateFn = useCallback(() => {
    getExchangeRate(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (window.location.pathname !== "/partners") {
      getPromotedPartners(dispatch);
    }
    const zone = moment.tz.guess();
    getApprovedPromotion(dispatch, zone);
    getSystemSetting(dispatch);
    getAllRoles(dispatch).then(() => {});
    getExchangeRateFn();
    if (auth?.user) {
      getPremiumCreationAccess(
        dispatch,
        "event",
        auth.user._id,
        auth?.user?.token,
      );
      getUserPremiumAccess(
        dispatch,
        ["room:planAccessible"],
        auth?.user?.token,
      );
      Promise.all([
        getMySubscriptions(auth?.user?.token, dispatch),
        getMyRoomCount(auth?.user?.token, dispatch),
        getMyCallCount(dispatch, auth?.user?.token),
        getMyRoomSlugCount(auth?.user?.token, dispatch),
        getMyExternalVideosCount(auth?.user?.token, dispatch),
      ]);
    }
  }, [auth?.user]);

  React.useEffect(() => {
    if (auth?.user && systemSetting?.isReferralSystemActive) {
      getReferralTransaction(dispatch, auth?.user?.token);
      getUserReferral(dispatch, auth?.user?.token);
    }
  }, [auth?.user, systemSetting]);

  React.useEffect(() => {
    if (auth.user) {
      getLoggedInUserData(auth.user._id, dispatch);
    }
  }, []);

  React.useEffect(() => {
    googleAnalyticsActions.initGoogleAnalytics("UA-219523093-1");
  });

  const addInstantNotification = (notification) => {
    const localUser = JSON.parse(localStorage.getItem("user"));
    if (
      notification.type === "chat" ||
      notification.type === "wave" ||
      notification.type === "calling" ||
      notification.type === "missed" ||
      notification.type === "decline"
    ) {
      if (
        notification.type === "chat" &&
        localUser?.appSettings?.banner?.chat
      ) {
        dispatch({
          type: "ADD_INSTANT_NOTIFICATION",
          payload: {
            notification: notification,
          },
        });
      }
      if (
        (notification.type === "wave" ||
          notification.type === "calling" ||
          notification.type === "missed" ||
          notification.type === "decline") &&
        localUser?.appSettings?.banner?.wave
      ) {
        dispatch({
          type: "ADD_INSTANT_NOTIFICATION",
          payload: {
            notification: notification,
          },
        });
      }
    } else {
      dispatch({
        type: "ADD_INSTANT_NOTIFICATION",
        payload: {
          notification: notification,
        },
      });
    }
  };

  const removeInstantNotification = ({ id }) => {
    dispatch({
      type: "REMOVE_INSTANT_NOTIFICATION",
      payload: {
        id,
      },
    });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await getLatestFeedPosts({
          dispatch,
          token: auth?.user?.token,
          lastPostHash: "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 20000);

    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await getRoomSubscriberCount({
          dispatch,
          token: auth?.user?.token,
          roomId,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (roomId && auth?.user?.token) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, [roomId]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (socketRef.current?.emit) {
        socketRef.current?.emit("ping-pong");
      }
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    const disconnectSocket = () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = undefined;
      }
    };
    if (auth?.user?.token) {
      socketRef.current = io.connect(`${baseURL}/home`, {
        auth: {
          token: auth?.user?.token,
        },
      });
      socketRef.current.on("connect", () => {
        if (auth?.user?._id) {
          socketRef.current.emit("join_event", auth.user._id);
        }
        socketRef.current.on("instant_notify", addInstantNotification);
        socketRef.current.on(
          "dismissInstantNotification",
          removeInstantNotification,
        );
        socketRef.current.on("composition_call_update", updateOptionStatus);

        globals.joinHomeNamespaceEvent = (eventName) => {
          socketRef.current.emit("join_event", eventName);
        };
        socketRef.current.on(
          "update_all_unread_msg_count",
          handleAllUnreadMessageCount,
        );
      });
    } else {
      disconnectSocket();
    }

    return () => {
      disconnectSocket();
    };
  }, [auth?.user?.token]);

  const handleAllUnreadMessageCount = (data) => {
    dispatch({
      type: "UPDATE_UNREAD_MESSAGE_INCREMENT",
      payload: {
        count: data.count,
        roomId: data.roomId,
        last_chat: { ...data.last_chat, room: data.roomId },
      },
    });
    dispatch({
      type: "UPDATE_UNREAD_MESSAGE_INCREMENT_ALL",
      payload: {
        count: data.count,
      },
    });
  };

  React.useEffect(() => {
    if (auth?.user?.token) {
      getAuthUserData(auth?.user?.token, dispatch);
      if (!isPWA) {
        addPushNotificationSubscription(auth?.user?.token);
      }
      getAccessPasses(auth?.user?.token, dispatch);
    }
  }, [auth?.user?.token]);

  React.useEffect(() => {
    if (user?.isAccountConnStatus !== undefined) {
      if (user?.isAccountConnStatus === "success") {
        getAuthUserData(auth?.user?.token, dispatch);
      }
      setIsAccountStatusOpen(true);
    }
  }, [user?.isAccountConnStatus]);

  const checkReferral = async () => {
    const invitationCode = localStorage.getItem("vibehut_invitation_code");
    if (invitationCode) {
      await createReferralTransaction(
        dispatch,
        invitationCode,
        auth?.user?.token,
      )
        .then(() => {
          localStorage.removeItem("vibehut_invitation_code");
        })
        .catch((err) => {
          console.log("err::", err);
        });
    }
  };

  React.useEffect(() => {
    if (auth?.user?.token) {
      checkReferral();
    }
  }, [auth?.user?.token]);

  const updateOptionStatus = ({ call }) => {
    dispatch({
      type: actionTypes.UPDATE_RECENT_CALL_LIST,
      payload: { call },
    });
  };
  const openEventErrorModal = () => {
    if (!rsvpEventErrorMessage) {
      return;
    }
    globals.showModal(
      "Attend Event",
      <>
        <div className=" justify-center p-4  rounded-2xl  ">
          <div>
            <div className="flex flex-row items-center gap-4">
              <div>
                <p className="dark:text-frescoWhite">{rsvpEventErrorMessage}</p>
              </div>
            </div>
          </div>
          <div className="pt-3 flex flex-row-reverse">
            <div className="mr-2">
              <Button
                size="vibhut-sm"
                variant="vibhut-secondary"
                onClick={() => {
                  globals.hideModal();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </>,
    );
  };
  return (
    <ThemeProvider>
      <Web3ModalProvider>
        <DesoProtocolProvider>
          <div className="">
            <Routes />
            <SvgDefinations />
          </div>
          <AccountStatus
            status={user?.isAccountConnStatus}
            isOpen={isAccountStatusOpen}
            setIsOpen={setIsAccountStatusOpen}
          />
          {openEventErrorModal()}
        </DesoProtocolProvider>
      </Web3ModalProvider>
    </ThemeProvider>
  );
}

export default App;
