import React, { useState } from 'react';

function TwitterV2({ ratio = 20, isInline = true, isHoverActive = false }) {
  if (!isHoverActive) {
    return (
      <svg width={ratio} height={ratio} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.839058 1.51875C1.11093 1.86875 6.2375 8.58281 6.24375 8.59844C6.24843 8.61094 5.22968 9.7875 3.70937 11.525C2.3125 13.1219 1.15468 14.4453 1.13593 14.4656L1.10468 14.5H2.22187H3.33906L5.32031 12.2359L7.30312 9.97344L7.35937 10.0453C7.39062 10.0844 8.17031 11.1031 9.09218 12.3078L10.7672 14.5H12.9922C14.2172 14.5 15.2187 14.4969 15.2187 14.4922C15.2187 14.4859 9.81562 7.33281 9.57656 7.02344C9.57031 7.01406 10.5531 5.87656 11.9547 4.275C13.2687 2.77187 14.3516 1.53281 14.3609 1.52187C14.3766 1.50312 14.2 1.5 13.2625 1.5H12.1469L10.3437 3.5625C9.35312 4.69687 8.53593 5.625 8.53125 5.625C8.525 5.625 7.81718 4.69687 6.95937 3.5625L5.4 1.5H3.1125C1.28437 1.5 0.82812 1.50469 0.839058 1.51875ZM8.64843 7.95469C10.8062 10.8078 12.5734 13.15 12.575 13.1578C12.5781 13.1656 12.3437 13.1719 11.9875 13.1719H11.3953L7.59218 8.2C5.5 5.46562 3.70937 3.12344 3.6125 2.99687L3.43593 2.76562L4.08125 2.76719H4.72656L8.64843 7.95469Z"
          fill="url(#paint1_linear_11023_106129)"
        />
        <defs>
          <linearGradient
            id="paint1_linear_11023_106129"
            x1="8.02881"
            y1="14.5"
            x2="8.02881"
            y2="1.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0F1419" />
            <stop offset="0.213542" stop-color="#0F1419" />
            <stop offset="0.838542" stop-color="#0F1419" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={ratio}
      height={ratio}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` custom-black`}
    >
      <path
        d="M0.839058 1.51875C1.11093 1.86875 6.2375 8.58281 6.24375 8.59844C6.24843 8.61094 5.22968 9.7875 3.70937 11.525C2.3125 13.1219 1.15468 14.4453 1.13593 14.4656L1.10468 14.5H2.22187H3.33906L5.32031 12.2359L7.30312 9.97344L7.35937 10.0453C7.39062 10.0844 8.17031 11.1031 9.09218 12.3078L10.7672 14.5H12.9922C14.2172 14.5 15.2187 14.4969 15.2187 14.4922C15.2187 14.4859 9.81562 7.33281 9.57656 7.02344C9.57031 7.01406 10.5531 5.87656 11.9547 4.275C13.2687 2.77187 14.3516 1.53281 14.3609 1.52187C14.3766 1.50312 14.2 1.5 13.2625 1.5H12.1469L10.3437 3.5625C9.35312 4.69687 8.53593 5.625 8.53125 5.625C8.525 5.625 7.81718 4.69687 6.95937 3.5625L5.4 1.5H3.1125C1.28437 1.5 0.82812 1.50469 0.839058 1.51875ZM8.64843 7.95469C10.8062 10.8078 12.5734 13.15 12.575 13.1578C12.5781 13.1656 12.3437 13.1719 11.9875 13.1719H11.3953L7.59218 8.2C5.5 5.46562 3.70937 3.12344 3.6125 2.99687L3.43593 2.76562L4.08125 2.76719H4.72656L8.64843 7.95469Z"
        fill="url(#paint0_linear_11023_106129)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11023_106129"
          x1="8.02881"
          y1="14.5"
          x2="8.02881"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7A7A" />
          <stop offset="0.213542" stop-color="#7A7A7A" />
          <stop offset="0.838542" stop-color="#7A7A7A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TwitterV2;
