import React from "react";

function Event() {
  return (
    <svg
      width="28"
      height="28"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7"
        y="7"
        width="14"
        height="15"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M11 5.75C11 5.33579 10.6642 5 10.25 5C9.83579 5 9.5 5.33579 9.5 5.75V8.25C9.5 8.66421 9.83579 9 10.25 9C10.6642 9 11 8.66421 11 8.25V5.75Z"
        fill="currentColor"
      />
      <path
        d="M11 5.75C11 5.33579 10.6642 5 10.25 5C9.83579 5 9.5 5.33579 9.5 5.75V8.25C9.5 8.66421 9.83579 9 10.25 9C10.6642 9 11 8.66421 11 8.25V5.75Z"
        stroke="currentColor"
      />
      <path
        d="M18.5 5.75C18.5 5.33579 18.1642 5 17.75 5C17.3358 5 17 5.33579 17 5.75V8.25C17 8.66421 17.3358 9 17.75 9C18.1642 9 18.5 8.66421 18.5 8.25V5.75Z"
        fill="currentColor"
      />
      <path
        d="M18.5 5.75C18.5 5.33579 18.1642 5 17.75 5C17.3358 5 17 5.33579 17 5.75V8.25C17 8.66421 17.3358 9 17.75 9C18.1642 9 18.5 8.66421 18.5 8.25V5.75Z"
        stroke="currentColor"
      />
      <rect x="6" y="11" width="16" height="2" fill="currentColor" />
    </svg>
  );
}

export default Event;
