/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import SendActive from "../Icons/SendActive";
import SendInActive from "../Icons/SendInActive";
import { getUserData, searchUsers } from "../../services/userService";
import { StoreContext } from "../../Context/StoreContext";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyle from "./mentionsInputStyle";
import AttachmentButton from "./AttachmentButton";
import AttachmentContainer from "./AttachmentContainer";
import { uploadAttachment } from "../../utils/uploadMedia";
import { useDropzone } from "react-dropzone";
import GifButton from "./GifButton";
import {
  handleTransformDataTransferIntoURL,
  saveDraftToLocalStorage,
} from "../../utils/utils";
import * as actionTypes from "../../constants";
import UserCard from "../UserCard";
import axios from "../../utils/axios";

const amiUser = {
  id: "ami",
  display: "ami",
  profile: { _id: "ami", username: "ami" },
};

function MComposer({
  onSubmit,
  maxRows = 3,
  roomId = null,
  value = "",
  attachedMessage,
  setAttachedMessage,
  attachmentFile,
  setAttachmentFile,
  chatType,
  setCurrentInputMessageLength,
}) {
  const [text, setText] = useState(value);
  const textareaRef = useRef(null);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [searchedUser, setSearchedUser] = useState([]);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [gif, setGif] = useState(null);
  const { state, dispatch } = useContext(StoreContext);

  useEffect(() => {
    const drafts = JSON.parse(localStorage.getItem("DraftStore")) || {};
    if (drafts[roomId]) {
      setText(drafts[roomId].draft);
      setCurrentInputMessageLength?.(drafts[roomId].draft.length);
    }
  }, [roomId]);

  useEffect(() => {
    saveDraftToLocalStorage(roomId, text);
  }, [text, roomId]);

  const {
    auth: { user },
    chat: { isPersonaThinking },
    room: { chatRoom },
  } = state;

  const [userMentions, setUserMentions] = useState([]);

  const handleClick = async () => {
    try {
      let attachments = [];
      if (attachmentFile) {
        setIsMediaUploading(true);
        const response = await uploadAttachment(attachmentFile);
        setAttachmentFile(null);
        if (response.data.secure_url)
          attachments = [
            { mediaUrl: response.data.secure_url, mediaType: "image" },
          ];
      }
      if (gif) {
        attachments = [...attachments, { mediaUrl: gif, mediaType: "image" }];
      }
      let result = text;
      if (result.trim() === "" && !attachmentFile && !gif) {
        return;
      }
      let filteredUserMentions = [];
      if (result) {
        for (const user of userMentions) {
          if (user._id !== "ami") {
            result = result.replace(
              `@[@${getUserData(user).username}](${user._id})`,
              `$(_user:${user._id})`,
            );
          } else {
            result = result.replace(`@[@ami](ami)`, `@ami`);
          }
        }

        filteredUserMentions = removeUser(result);
        filteredUserMentions = filteredUserMentions.filter(
          (user) => user._id !== "ami",
        );
      }
      onSubmit(result, filteredUserMentions, attachments, attachedMessage);
      setText("");
      setUserMentions([]);
      setAttachedMessage(null);
    } catch (error) {
    } finally {
      setGif(null);
      setIsMediaUploading(false);
      setCurrentInputMessageLength?.(0);
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
    setCurrentInputMessageLength?.(e.target.value.length);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.selectionStart = value.length;
      textareaRef.current.selectionEnd = value.length;
    }
  }, [value]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [attachedMessage?.message]);

  const removeUser = (st) => {
    const array = userMentions;
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (!st.includes(obj._id)) {
        array.splice(i, 1);
        i--;
      }
    }
    return array;
  };

  const getSubscribersList = async () => {
    const header = user?.token
      ? {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      : undefined;
    await axios
      .get(`/rooms/subscriber-list/${roomId}?limited=${false}`, header)
      .then((res) => {
        if (res.status === 200) {
          const users =
            res.data.data?.subsUserInfo?.map((user) => {
              const _user = getUserData(user);
              return {
                id: _user._id,
                display: _user.username,
                profile: _user,
              };
            }) || [];
          setDefaultUsers([...users, amiUser]);
        }
      })
      .catch();
  };

  useEffect(() => {
    if (!chatRoom?.isChatRoom) {
      if (
        chatType !== actionTypes.specificChatType.EVENT &&
        chatType !== actionTypes.specificChatType.EVENTPAGE &&
        chatType !== actionTypes.specificChatType.FEEDPAGE
      ) {
        getSubscribersList();
      }
    } else {
      const convUser = chatRoom?.chatUsers.find((u) => u._id !== user?._id);
      if (convUser) {
        const _user = getUserData(convUser);
        setDefaultUsers([
          {
            id: _user._id,
            display: _user.username,
            profile: _user,
          },
          amiUser,
        ]);
      }
    }
  }, [roomId, chatRoom]);

  const handleKeyPress = async (e) => {
    try {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        let result = text;
        let attachments = [];
        if (attachmentFile) {
          setIsMediaUploading(true);
          const response = await uploadAttachment(attachmentFile);
          setAttachmentFile(null);
          if (response.data.secure_url)
            attachments = [
              { mediaUrl: response.data.secure_url, mediaType: "image" },
            ];
        }
        if (gif) {
          attachments = [...attachments, { mediaUrl: gif, mediaType: "image" }];
          setGif(null);
        }
        if (result.trim() === "" && !attachmentFile && !gif) {
          return;
        }
        let filteredUserMentions = [];
        if (result) {
          for (const user of userMentions) {
            if (user._id !== "ami") {
              result = result.replace(
                `@${getUserData(user).username}`,
                `$(_user:${user._id})`,
              );
            }
          }

          filteredUserMentions = removeUser(result);
          filteredUserMentions = filteredUserMentions.filter(
            (user) => user._id !== "ami",
          );
        }
        onSubmit(result, filteredUserMentions, attachments, attachedMessage);
        setText("");
        setUserMentions([]);
        setAttachedMessage(null);
      }
    } catch (error) {
      setGif(null);
    } finally {
      setIsMediaUploading(false);
      setCurrentInputMessageLength?.(0);
    }
  };

  const geButtonDisableState = () => {
    if (isPersonaThinking) {
      return true;
    }
    if (isMediaUploading) {
      return true;
    }
    if (attachmentFile) {
      return false;
    }
    if (gif) {
      return false;
    }
    if ((!text.length || text.trim() === "") && !attachmentFile && !gif) {
      return true;
    }
    return false;
  };

  const handlePaste = (e) => {
    const event = e;
    if (event.clipboardData) {
      handleTransformDataTransferIntoURL(event.clipboardData, (blob) => {
        if (
          blob &&
          blob.type &&
          typeof blob.type === "string" &&
          blob.type.includes("image")
        ) {
          setAttachmentFile(blob);
        }
      });
    }
  };

  const { getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      dispatch({
        type: actionTypes.TOGGLE_SCROLL,
        payload: true,
      });
      setAttachmentFile(acceptedFiles[0]);
    },
    multiple: false,
    accept: "image/*",
    disableClick: true,
  });

  const searchMentionUsers = async (query, callback) => {
    try {
      if (!query) {
        callback(defaultUsers);
        return;
      }

      const vibehutResponse = await searchUsers(query);

      const usersSet = new Set();
      const vibehutUsers = vibehutResponse.data.users;
      setSearchedUser(vibehutUsers);
      for (const user of vibehutUsers) {
        usersSet.add(user);
      }

      const suggestions = Array.from(usersSet).map((user) => {
        const _user = getUserData(user);
        return {
          id: _user._id,
          display: _user.username,
          profile: _user,
        };
      });

      suggestions.sort((a, b) => {
        const aIndex = a.display.toLowerCase().indexOf(query.toLowerCase());
        const bIndex = b.display.toLowerCase().indexOf(query.toLowerCase());

        return aIndex - bIndex;
      });

      suggestions.push(amiUser);

      callback(suggestions);
    } catch (error) {}
  };

  const isNotPersonaChat = useMemo(
    () => chatRoom.specificChatType !== actionTypes.specificChatType.PERSONA,
    [chatRoom.specificChatType],
  );

  const renderSuggestion = (suggestion) => {
    return (
      <div className="flex items-center justify-between ">
        {suggestion.profile._id == "ami" ? (
          <div className="px-2 flex items-center gap-2 py-1">
            <div
              className={`w-10 h-10 bg-frescoWhite 
                  rounded-full flex items-center justify-center cursor-pointer mt-1.5`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/robot.webp`}
                alt="ami"
                className="w-6 h-6"
                onError={(e) => {
                  e.target.onerror = null;
                  setSrcError(true);
                }}
              />
            </div>
            <p className="pr-2 text-navyGray dark:text-white">@ami</p>
          </div>
        ) : (
          <UserCard user={suggestion.profile} size="sm-3" />
        )}
      </div>
    );
  };

  const appendSpaceAndCloseSuggestionsOnAdd = (id, display) => {
    const user = searchedUser.find((user) => user._id === id);
    if (user) {
      setUserMentions([...userMentions, user]);
    } else {
      setUserMentions([...userMentions, { _id: "ami", user: "ami" }]);
    }
    if (textareaRef.current) {
      textareaRef.current.value += " ";
      const event = new Event("input", { bubbles: true });
      textareaRef.current.dispatchEvent(event);
      textareaRef.current.blur();
      textareaRef.current.focus();
    }
  };

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`relative w-full bg-white dark:bg-darkNight ${
          !!attachmentFile || !!gif ? "border rounded-2xl px-2" : ""
        } `}
      >
        {(!!attachmentFile || !!gif || !!attachedMessage) && (
          <AttachmentContainer
            attachedMessage={attachedMessage}
            setAttachedMessage={setAttachedMessage}
            attachmentFile={attachmentFile}
            setAttachmentFile={setAttachmentFile}
            gif={gif}
            setGif={setGif}
          />
        )}
        <MentionsInput
          placeholder={
            chatRoom.isChatRoom ? "Type Message.." : "Use '@' for mention"
          }
          value={text}
          onChange={handleChange}
          style={mentionsInputStyle}
          onKeyDown={handleKeyPress}
          inputRef={textareaRef}
          className={`mention_control ${
            roomId
              ? " border-0 text-matteGray dark:bg-matteGray dark:text-white md:border-lightGray"
              : "bg-matteGray text-white"
          }   rounded-2xl w-full py-1 mb-1`}
          allowSuggestionsAboveCursor={true}
          onPaste={handlePaste}
        >
          <Mention
            markup="@__display__"
            data={searchMentionUsers}
            appendSpaceOnAdd={true}
            onAdd={(id, display) =>
              appendSpaceAndCloseSuggestionsOnAdd(id, display)
            }
            displayTransform={(id, display) => display}
            regex={/@{([^}]+)}/}
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
        <div
          className={`absolute right-3.5 transition-all z-50 ${
            isNotPersonaChat ? " bottom-0" : " bottom-1.5"
          }`}
        >
          <div className="flex items-center gap-1">
            {isNotPersonaChat && (
              <>
                <AttachmentButton
                  setAttachmentFile={setAttachmentFile}
                  isMediaUploading={isMediaUploading}
                  isDisable={gif ? true : false}
                />
                <GifButton
                  setGif={setGif}
                  isDisable={attachmentFile ? true : false}
                />
              </>
            )}
            <button
              type="button"
              disabled={geButtonDisableState()}
              className="cursor-pointer"
              onClick={handleClick}
            >
              {((text.length && text.trim() !== "") ||
                attachmentFile ||
                !!gif) &&
              !isPersonaThinking ? (
                <SendActive />
              ) : (
                <SendInActive roomId={roomId} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MComposer;
