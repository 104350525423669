import * as actionTypes from "../constants";
import * as subscriptionService from "../services/subscriptionService";

export function createSubscription(data, token, dispatch) {
  return new Promise(function (resolve, reject) {
    dispatch({
      type: actionTypes.CREATE_SUBSCRIPTION_REQUEST,
    });
    return subscriptionService
      .createSubscription(data, token)
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_SUBSCRIPTION_SUCCESS,
          payload: res.data.subscription,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_SUBSCRIPTION_FAILURE,
        });
        reject(err);
      });
  });
}

export function getMySubscriptions(token, dispatch) {
  dispatch({
    type: actionTypes.GET_MY_SUBSCRIPTIONS_REQUEST,
  });
  return subscriptionService
    .getMySubscriptions(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_SUBSCRIPTIONS_SUCCESS,
        payload: res.data.subscriptions,
      });
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function getMySubscribers(token, dispatch) {
  dispatch({
    type: actionTypes.GET_MY_SUBSCRIBERS_REQUEST,
  });
  return subscriptionService
    .getMySubscribers(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_SUBSCRIBERS_SUCCESS,
        payload: res.data.subscribers,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function unSubscription(data, token, dispatch) {
  return new Promise(function (resolve, reject) {
    dispatch({
      type: actionTypes.UN_SUBSCRIPTION_REQUEST,
    });
    return subscriptionService
      .unSubscription(data, token)
      .then((res) => {
        dispatch({
          type: actionTypes.UN_SUBSCRIPTION_SUCCESS,
          payload: res.data.room,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        throw err;
      });
  });
}
