import * as actionTypes from "../constants";

const initialState = {
  data: [],
  isFetching: false,
  error: null,
  offset: 0,
  isMore: false,
  userConnections: [],
  mutualConections: [],
  isMoreMutual: false,
  isFetchingMutual: false,
  offsetMutual: 0,
};

function connectionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CONNECTION_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_CONNECTION_SUCCESS: {
      const newConnections = state.data;

      for (const connection of action.payload.connections) {
        if (
          state.data.findIndex(
            (p_connection) => p_connection._id === connection._id
          ) === -1
        ) {
          newConnections.push(connection);
        }
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          data: newConnections,
          isFetching: false,
          offset: state.offset + action.payload.connections.length,
          isMore: action.payload.connections.length >= 30 ? true : false,
        };
      } else {
        return {
          ...state,
          data: action.payload.connections,
          isFetching: false,
          offset: state.offset + action.payload.connections.length,
          isMore: action.payload.connections.length >= 30 ? true : false,
        };
      }
    }

    case actionTypes.GET_CONNECTION_FAILURE:
      return { ...state, isFetching: true };

    case actionTypes.GET_USER_CONNECTION_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_USER_CONNECTION_SUCCESS: {
      const userConnections = state.userConnections;

      for (const connection of action.payload.connections) {
        if (
          state.data.findIndex(
            (p_connection) => p_connection._id === connection._id
          ) === -1
        ) {
          userConnections.push(connection);
        }
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          userConnections: userConnections,
          isFetching: false,
          offset: state.offset + action.payload.connections.length,
          isMore: action.payload.connections.length >= 30 ? true : false,
        };
      } else {
        return {
          ...state,
          userConnections: action.payload.connections,
          isFetching: false,
          offset: state.offset + action.payload.connections.length,
          isMore: action.payload.connections.length >= 30 ? true : false,
        };
      }
    }

    case actionTypes.GET_USER_CONNECTION_FAILURE:
      return { ...state, isFetching: true };

    case actionTypes.GET_MUTUAL_CONNECTION_REQUEST:
      return { ...state, isFetchingMutual: true };

    case actionTypes.GET_MUTUAL_CONNECTION_SUCCESS: {
      const mutualConections = state.mutualConections;

      for (const connection of action.payload.connections) {
        if (
          state.data.findIndex(
            (p_connection) => p_connection._id === connection._id
          ) === -1
        ) {
          mutualConections.push(connection);
        }
      }

      if (action.payload.loadMore) {
        return {
          ...state,
          mutualConections: mutualConections,
          isFetchingMutual: false,
          offsetMutual: state.offsetMutual + action.payload.connections.length,
          isMoreMutual: action.payload.connections.length >= 30 ? true : false,
        };
      } else {
        return {
          ...state,
          mutualConections: action.payload.connections,
          isFetchingMutual: false,
          offsetMutual: state.offsetMutual + action.payload.connections.length,
          isMoreMutual: action.payload.connections.length >= 30 ? true : false,
        };
      }
    }

    case actionTypes.GET_MUTUAL_CONNECTION_FAILURE:
      return { ...state, isFetchingMutual: true };

    case actionTypes.UPDATE_CONNECTION_REQUEST: {
      return { ...state, isFetching: true };
    }

    case actionTypes.UPDATE_CONNECTION_SUCCESS: {
      const newConnections = state.data;
      const index = newConnections.findIndex(
        (p_connection) => p_connection._id === action.payload._id
      );
      if (index !== -1) {
        newConnections[index] = action.payload;
      } else {
        newConnections.push(action.payload);
      }
      return {
        ...state,
        data: newConnections,
        isFetching: false,
      };
    }

    case actionTypes.UPDATE_CONNECTION_FAILURE:
      return { ...state, isFetching: false };

    case actionTypes.REMOVE_CONNECTION_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.REMOVE_CONNECTION_SUCCESS: {
      return {
        ...state,
        data:
          state.data.length > 0
            ? state.data.filter(
                (connection) => connection._id !== action.payload._id
              )
            : state.data,
        isFetching: false,
      };
    }

    case actionTypes.REMOVE_CONNECTION_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}

export default connectionReducer;
