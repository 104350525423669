import React, { useContext, useRef } from "react";
import UserCard from "../VibehutUI/UserCard";
import SearchBar from "../VibehutUI/SearchBar";
import { Link } from "react-router-dom";
import { getUserData, searchVibhutAndDesoUsers } from "../services/userService";
import { StoreContext } from "../Context/StoreContext";
import { baseURL, videoAppUrl } from "../utils/axios";
import * as io from "socket.io-client";
import * as actionTypes from "../constants";
import { Tab } from "@headlessui/react";
import UserCardSkeleton from "../VibehutUI/Skeletons/UserCardSkeleton";
import useDebounce from "../hooks/useDebounce";
import FreeToTalk from "./FreeToTalk";
import { getActiveRooms, getTopRooms } from "../actions/roomAction";
import WaveButton from "../VibehutUI/WaveButton";
import { isActiveRoom, isMobile } from "../utils/utils";
import { getLastActiveUsers, getSidebarCalls } from "../actions/userActions";
import Loader from "../VibehutUI/Loader";
import Button from "../VibehutUI/Button";
import { useEffect } from "react";
import moment from "moment";
import GeneralRoom from "../VibehutUI/Icons/GeneralRoom";
import useIsPWA from "../hooks/usePWA";
import { getShortenedName } from "../utils/utils";

function RightSidebar({ isOpen = false, setIsOpen }) {
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isSeraching, setIsSeraching] = React.useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const isPWA = useIsPWA();
  const { state, dispatch } = useContext(StoreContext);
  const socketRef = useRef();
  const {
    auth,
    user: {
      onCallUsers,
      onCallUsersCount,
      lastActiveUsersCount,
      isSidebarDataFetching,
      freeToTalkUsers,
      freeToTalkUsersCount,
      offset,
      isMoreUsersFetching,
      isMoreUsers,
      lastActiveUsers,
      sidebarRecentCalls,
    },
    room: {
      topRooms,
      isTopRoomMore,
      isFetchingTopRooms,
      isFetchingMoreTopRooms,
      activeCalls,
    },
  } = state;
  const getActiveRoomsFun = async () => {
    await Promise.all([
      getActiveRooms(dispatch, auth?.user?.token),
      getTopRooms(auth?.user?.token, dispatch, 0, false, true),
    ]);
  };

  useEffect(() => {
    if (!topRooms.length > 0) {
      getTopRooms(auth?.user?.token, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getActiveRoomsFun();
    const interval = setInterval(() => getActiveRoomsFun(), 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMoreUsers = async () => {
    await getLastActiveUsers(dispatch, isMoreUsers, offset);
  };

  const getSidebarRecentCalls = async () => {
    await getSidebarCalls(auth?.user?.token, dispatch);
  };

  useEffect(() => {
    getLastActiveUsers(dispatch, false, 0);
    if (sidebarRecentCalls.length === 0 && auth?.user?.token) {
      getSidebarRecentCalls();
    }
  }, [auth?.user?.token]);

  const updateSocketData = ({ callActivitesUsers }) => {
    dispatch({
      type: actionTypes.GET_USER_CALL_ACTIVITY_UPDATE,
      payload: {
        users: callActivitesUsers,
      },
    });
  };
  React.useEffect(() => {
    socketRef.current = io.connect(`${baseURL}/sidebar`);
    socketRef.current.on("connect", () => {
      socketRef.current.emit("join-sidebar", updateSocketData);
    });

    socketRef.current.on("update-sidebar", updateSocketData);

    return () => {
      socketRef.current.removeAllListeners();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setIsSeraching(true);
    if (debouncedSearchTerm) {
      searchVibhutAndDesoUsers(searchTerm)
        .then((response) => {
          const users = response.data.users;
          setIsSeraching(false);
          setSearchResults(users);
        })
        .catch((err) => {
          console.log("err", err);
          setIsSeraching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  const goToCallPage = (roomId) => {
    if (!isPWA) {
      window.open(`${videoAppUrl}/call?roomKey=${roomId}`);
    } else {
      window.location.href = `${videoAppUrl}/call?roomKey=${roomId}`;
    }
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="px-4 py-6 border-l bg-white dark:bg-darkNight dark:border-matteGray border-lightGray font-ubuntu overflow-hidden h-screen">
      <div className="pb-4 pl-2 pr-2 md:hidden block text-darkGray">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() => setIsOpen(false)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </div>
      <div>
        <SearchBar
          handleChange={handleChange}
          results={searchResults}
          value={searchTerm}
          isSeraching={isSeraching}
        />
      </div>

      {searchTerm === "" && (
        <div>
          {sidebarRecentCalls.length !== 0 ? (
            <div className="pt-4">
              <p className="text-xs font-bold dark:text-white text-darkNight px-2">
                Recents Calls ·{" "}
                {sidebarRecentCalls.length > 3 ? 3 : sidebarRecentCalls.length}
              </p>
              <div
                className=" overflow-y-scroll custom-scrollbar"
                style={{
                  maxHeight:
                    freeToTalkUsersCount >= 3 && sidebarRecentCalls.length >= 3
                      ? "15vh"
                      : "25vh",
                }}
              >
                {(sidebarRecentCalls?.length || 0) > 0 &&
                  sidebarRecentCalls.slice(0, 3).map((call, i) => {
                    const userData = getUserData(call.users);
                    return (
                      <div
                        key={call._id + i}
                        className="pt-1 flex items-center justify-between"
                      >
                        <UserCard
                          userState={call.users.userState}
                          user={userData}
                          room={
                            call.room?.name?.replace("with $(_user)", "") || ""
                          }
                          date={moment(call.createdAt).fromNow()}
                          isMissedCall={false}
                        />
                        {!auth?.user?.token ? undefined : auth?.user?._id ===
                          call.users._id ? undefined : call.room.isWaveRoom ? (
                          <div>
                            <WaveButton
                              user={call.users}
                              size="vibhut-xs"
                              showOnlyCall={true}
                            />
                          </div>
                        ) : (
                          <Button
                            size="vibhut-xs"
                            variant="vibhut-primary"
                            onClick={() => goToCallPage(call.room?._id)}
                          >
                            Call
                          </Button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
          {freeToTalkUsersCount !== 0 ? (
            <div className="pt-4">
              <p className="text-xs font-bold dark:text-white text-darkNight px-2">
                Free to talk · {freeToTalkUsersCount}
              </p>
              <div
                className=" overflow-y-scroll custom-scrollbar"
                style={{
                  maxHeight:
                    onCallUsersCount >= 3 && freeToTalkUsersCount >= 3
                      ? "15vh"
                      : "20vh",
                }}
              >
                {(freeToTalkUsers?.length || 0) > 0 &&
                  freeToTalkUsers.map((user, i) => {
                    const userData = user.fromSocket ? user : getUserData(user);
                    return (
                      <div
                        key={user._id + i}
                        className="pt-1 flex items-center justify-between"
                      >
                        <UserCard
                          user={{
                            ...userData,
                            room: user.room,
                            username:
                              userData.username.length <= 14
                                ? userData.username
                                : auth?.user?._id === user._id ||
                                    !auth?.user?.token
                                  ? userData.username
                                  : userData.username.slice(0, 8) + "...",
                          }}
                          userState={user.userState}
                          isNormalCard={false}
                        />
                        {!auth?.user?.token ? undefined : auth?.user?._id ===
                          user._id ? undefined : (
                          <div>
                            <WaveButton
                              user={user}
                              size="vibhut-xs"
                              showOnlyCall={true}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
          {onCallUsersCount !== 0 ? (
            <div className="pt-4">
              <p className="text-xs font-bold dark:text-white text-darkNight px-2">
                On a call · {onCallUsersCount}
              </p>
              <div
                className=" overflow-y-scroll custom-scrollbar"
                style={{
                  maxHeight:
                    onCallUsersCount > 3 && freeToTalkUsersCount > 3
                      ? "15vh"
                      : freeToTalkUsersCount >= 4
                        ? "15vh"
                        : "25vh",
                }}
              >
                <div className="h-full">
                  {(onCallUsers?.length || 0) > 0 &&
                    onCallUsers.map((user, index) => {
                      const isUserWaveCall =
                        user?.userState &&
                        user?.userState?.room.includes("Wave")
                          ? true
                          : false;
                      return user ? (
                        <div key={user._id + index} className="pt-1">
                          <UserCard
                            user={{
                              ...(user.fromSocket ? user : getUserData(user)),
                            }}
                            room={user.userState.room || user.room}
                            userState={user.userState}
                            isNormalCard={false}
                            isWaveCall={isUserWaveCall}
                          />
                        </div>
                      ) : undefined;
                    })}
                </div>
              </div>
            </div>
          ) : null}

          <div className="pt-4 ">
            <Tab.Group>
              <Tab.List className={"flex justify-evenly px-2"}>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm dark:text-white text-darkNight font-medium leading-5",
                      "ring-white/60 ring-offset-2 focus:outline-none focus:ring-2",
                      selected ? "shadow bg-primary !text-white" : "",
                    )
                  }
                >
                  Rooms
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm dark:text-white text-darkNight font-medium leading-5",
                      "ring-white/60 ring-offset-2  focus:outline-none focus:ring-2",
                      selected ? "shadow bg-primary !text-white" : "",
                    )
                  }
                >
                  People · {lastActiveUsersCount}
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="h-full">
                    {isFetchingTopRooms ? (
                      <div
                        className="overflow-y-scroll custom-scrollbar"
                        style={{
                          maxHeight: isMobile() ? "70vh" : "86vh",
                          paddingBottom: "60vh",
                        }}
                      >
                        <UserCardSkeleton />
                      </div>
                    ) : (
                      <div
                        className="overflow-y-scroll custom-scrollbar"
                        style={{
                          maxHeight: isMobile() ? "70vh" : "86vh",
                          paddingBottom: "60vh",
                        }}
                      >
                        <div className="h-full">
                          {(topRooms?.length || 0) > 0 &&
                            topRooms.map((room, i) => {
                              return (
                                <div
                                  key={room._id + i}
                                  className={`px-2 py-2 rounded-xl  hover:bg-frescoWhite dark:hover:bg-navyGray ${
                                    isActiveRoom(activeCalls, room?._id)
                                      ? "animate-rooms animate bubbly-button mx-2 my-1"
                                      : ""
                                  }`}
                                >
                                  <Link to={`/rooms/${room?._id}`}>
                                    <div className="flex gap-2">
                                      <div className="w-10 h-10  relative">
                                        {room.cover.urls.small ? (
                                          <img
                                            src={room.cover.urls.small}
                                            alt="room"
                                            className="w-full h-full rounded-lg"
                                            loading="lazy"
                                          />
                                        ) : (
                                          <GeneralRoom width={40} height={40} />
                                        )}
                                      </div>
                                      <div>
                                        <div className="flex gap-2 dark:text-white text-darkNight">
                                          <span>
                                            {getShortenedName(room.name, 20)}
                                          </span>
                                        </div>
                                        <div className="flex gap-1  text-xs text-darkGray dark:text-gray-300 font-normal">
                                          <span>
                                            {room?.subscriptions?.count}{" "}
                                            {room?.subscriptions?.count === 1
                                              ? "Sub"
                                              : "Subs"}{" "}
                                            ·
                                          </span>
                                          <span>
                                            {moment(room?.lastActive).fromNow()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              );
                            })}

                          {isFetchingTopRooms ? undefined : isFetchingMoreTopRooms ? (
                            <div>
                              <Loader />
                            </div>
                          ) : (
                            isTopRoomMore && (
                              <div className="flex justify-center pt-4">
                                <Button
                                  size="vibhut-sm"
                                  variant="vibhut-secondary"
                                  onClick={() =>
                                    getTopRooms(
                                      auth?.user?.token,
                                      dispatch,
                                      topRooms?.length,
                                      true,
                                    )
                                  }
                                >
                                  Load More
                                </Button>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  {" "}
                  <div className="h-full">
                    {isSidebarDataFetching ? (
                      <div
                        className="overflow-y-scroll custom-scrollbar"
                        style={{
                          maxHeight: isMobile() ? "70vh" : "86vh",
                          paddingBottom: "60vh",
                        }}
                      >
                        <UserCardSkeleton />
                      </div>
                    ) : (
                      <div
                        className="overflow-y-scroll custom-scrollbar"
                        style={{
                          maxHeight: isMobile() ? "70vh" : "86vh",
                          paddingBottom: "60vh",
                        }}
                      >
                        <div className="h-full">
                          {(lastActiveUsers?.length || 0) > 0 &&
                            lastActiveUsers.map((user, i) => {
                              const userData = getUserData(user);
                              return user && userData.username ? (
                                <div key={user._id + i} className="px-1 pt-1">
                                  <UserCard
                                    user={{
                                      ...(user.fromSocket ? user : userData),
                                      room: user.room,
                                    }}
                                    userState={user.userState}
                                    isNormalCard={false}
                                  />
                                </div>
                              ) : undefined;
                            })}
                          {!isMoreUsersFetching ? (
                            isMoreUsers && (
                              <div className="flex justify-center pt-4">
                                <Button
                                  size="vibhut-sm"
                                  variant="vibhut-secondary"
                                  onClick={handleLoadMoreUsers}
                                >
                                  Load More
                                </Button>
                              </div>
                            )
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <FreeToTalk />
          </div>
        </div>
      )}
    </div>
  );
}

export default RightSidebar;
