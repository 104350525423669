import { useMemo } from "react";
import StarIcon from "../../../VibehutUI/Icons/StarIcon";
import ChartIcon from "../../../VibehutUI/Icons/Chart";

interface TagProps {
  title: string;
  isClickable?: boolean;
  onClick?: (tag: string) => void;
  isSelected?: boolean;
  tagType?: string;
}
export default function Tag({
  title,
  isClickable,
  onClick,
  isSelected,
  tagType = "normal",
}: TagProps) {
  const colorClasses = useMemo(() => {
    if (!isSelected) {
      return "border-primaryGray text-darkGray dark:border-matteGray font-normal";
    }
    return "border-primary text-white bg-primary font-medium";
  }, [isSelected]);

  const textColorClasses = useMemo(() => {
    if (!isSelected) {
      return "dark:text-gray-200 text-darkGray";
    }
    return "text-white";
  }, [isSelected]);

  return (
    <div
      onClick={(e) => {
        if (isClickable && !!onClick) {
          onClick(title);
          return;
        }
        e.stopPropagation();
      }}
      className={`${colorClasses} mt-[1px] flex items-center space-x-1 border-[1px]  py-0.5 px-1 rounded justify-center w-fit ${
        isClickable && "cursor-pointer"
      }`}
    >
      {tagType === "premium" && (
        <StarIcon fill="currentColor" width="15" height="13" />
      )}
      {tagType === "popular" && <ChartIcon width="15" height="13" />}
      <span className={` ${textColorClasses} text-xs`}>{title}</span>
    </div>
  );
}
