import * as actionTypes from "../constants";
import * as notificationService from "../services/notificationService";

export function getNotifications(token, dispatch, loadMore, offset, isInstant) {
  dispatch({
    type: actionTypes.GET_NOTIFICATIONS_REQUEST,
    loadMore: loadMore,
    isInstant: isInstant,
  });
  return notificationService
    .getNotifications(token, offset, isInstant)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications: res.data.notifications,
          loadMore: loadMore,
          offset: offset,
          isInstant: isInstant,
        },
      });
      return res.data.notifications;
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_NOTIFICATIONS_FAILURE,
      });
      throw err;
    });
}

export function countUnSeenNotifications(token, dispatch) {
  dispatch({
    type: actionTypes.GET_COUNT_NOTIFICATIONS_REQUEST,
  });
  return notificationService
    .countUnSeenNotifications(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_COUNT_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_COUNT_NOTIFICATIONS_FAILURE,
      });
      throw err;
    });
}
