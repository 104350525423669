import React, { useContext, useEffect } from "react";
import Button from "../VibehutUI/Button";
import { useState } from "react";
import { StoreContext } from "../Context/StoreContext";
import {
  createApikey,
  deleteApikey,
  getAllApiKeys,
} from "../services/apiKeyService";
import Loader from "../VibehutUI/Loader";
import CopyButton from "../VibehutUI/CopyButton";
import DeleteModel from "../VibehutUI/DeleteModel";

function PublicApi() {
  const { state } = useContext(StoreContext);
  const {
    auth: { user: authUser },
  } = state;

  const [apiKeys, setApiKeys] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [appName, setAppName] = useState("");
  const [error, setError] = useState("");
  const [deleteApiKey, setDeleteApiKey] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const getApiKeys = async () => {
    setIsFetching(true);
    await getAllApiKeys(authUser?.token)
      .then((response) => {
        setApiKeys(response.data.apiKeys);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };
  useEffect(() => {
    if (authUser?.token) {
      getApiKeys();
    }
  }, [authUser]);

  const handleCreateApiKey = async () => {
    if (!authUser?.token) {
      return;
    }
    if (!appName) {
      setError("App name is required");
      return;
    }
    if (apiKeys.length >= 8) {
      setError("You cannot create more than 8 API keys.");
      return;
    }
    setIsCreating(true);
    await createApikey(authUser?.token, { appName })
      .then((response) => {
        setApiKeys([...apiKeys, response.data.apiKeyData]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAppName("");
        setIsCreating(false);
        setIsCreateMode(false);
      });
  };
  const isCreateModeToggle = () => {
    setIsCreateMode((isCreateMode) => !isCreateMode);
  };
  const handleChange = (e) => {
    setAppName(e.target.value);
    setError("");
  };
  const handleDelete = async (apiKey) => {
    setIsDeleting(true);
    await deleteApikey(authUser?.token, apiKey)
      .then((response) => {
        let _apiKeys = apiKeys;
        _apiKeys = apiKeys.filter(
          (apiKeyItem) => apiKeyItem.apiKey !== response.data.apiKey
        );
        setApiKeys(_apiKeys);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDeleting(false);
        setError("");
      });
  };
  if (isFetching || isDeleting) {
    return <Loader />;
  }
  if (isCreateMode) {
    return (
      <div className=" flex flex-col pl-2 pr-2 sm:pr-10">
        <div className="pb-3">
          <div className="flex justify-between items-center flex-row-reverse md:flex-row">
            <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite hidden md:block">
              Public Api Keys
            </p>

            <Button
              onClick={isCreateModeToggle}
              variant="secondary"
              disabled={isCreating}
            >
              Cancel
            </Button>
          </div>
          <div className="pt-2">
            <p className="text-sm font-medium text-darkNight dark:text-frescoWhite">
              App Name*
            </p>
            <input
              type="text"
              className="border outline-none border-primaryGray py-2 px-4 rounded-xl my-1 w-full"
              value={appName}
              placeholder="E.g. DeSocialWorld"
              onChange={handleChange}
            />
            <p className="space-y-1.5 text-xs font-normal text-cvRed">
              {error}
            </p>
          </div>
          <div className="pt-2">
            <Button onClick={handleCreateApiKey} disabled={isCreating}>
              <span className="flex items-center">
                {isCreating && <Loader color="white" ratio="h-6 w-6" />}
                <span>{isCreating ? "Creating.." : "Create"}</span>
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
  if (apiKeys.length === 0) {
    return (
      <div className="flex justify-center items-center flex-col h-80">
        <p className="dark:text-frescoWhite text-darkNight pb-2">
          You do not have any API key.
        </p>

        <Button onClick={isCreateModeToggle} disabled={isCreating}>
          Create
        </Button>
      </div>
    );
  }
  return (
    <div className=" flex flex-col pl-2 pr-2 sm:pr-10">
      <div className="pb-3">
        <div className="flex justify-between items-center flex-row-reverse md:flex-row">
          <p className="text-xl  font-bold text-black dark:text-frescoWhite hidden md:block">
            Public Api Keys
          </p>
          <Button onClick={isCreateModeToggle} disabled={isCreating}>
            Create
          </Button>
        </div>
        <div>
          <a
            href="https://vibehut.notion.site/Vibehut-V1-Api-6f005655867747228cd5fef603f2ca45"
            target="_blank"
            rel="noreferrer"
            className="text-primary underline"
          >
            Documentation
          </a>
        </div>

        <div className="flex flex-col gap-2 pt-2 subscription_list">
          {apiKeys.map((apiKeyData) => {
            return (
              <div
                key={apiKeyData._id}
                className="flex gap-4 flex-col border px-4 py-2 rounded dark:border-matteGray border-frescoWhite"
              >
                <div className="flex items-center justify-between">
                  <p className="dark:text-frescoWhite text-darkNight">
                    {apiKeyData?.appName}
                  </p>
                  <div>
                    <Button
                      onClick={() => setDeleteApiKey(apiKeyData.apiKey)}
                      disabled={isCreating}
                      variant="danger"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
                <div className="flex items-center justify-between md:pt-2">
                  <p className="dark:text-frescoWhite text-darkNight text-sm">
                    {apiKeyData.apiKey}
                  </p>
                  <div>
                    <CopyButton
                      title={`Vibehut`}
                      url={`${apiKeyData.apiKey}`}
                      bgColor="transparent"
                      topPosition="-top-12"
                    />
                  </div>
                </div>
                <DeleteModel
                  handleDelete={() => handleDelete(apiKeyData.apiKey)}
                  title={"Confirmation"}
                  isOpen={deleteApiKey === apiKeyData.apiKey}
                  setIsOpen={() => setDeleteApiKey(null)}
                  text={" Are you sure to delete this api key ?"}
                  btnvalue={"Remove"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PublicApi;
