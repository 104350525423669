import React from "react";

function RoomChipSkeleton({ totalCard = 9 }) {
  return (
    <div className="pt-2">
      <p className="rounded-xl animate-pulse bg-frescoWhite dark:bg-darkGray w-24 h-4 "></p>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 grid-cols-1 gap-4 pt-3 pb-5">
        {new Array(totalCard).fill(0).map((_, index) => (
          <div className="" key={index}>
            <div className="rounded-xl w-full animate-pulse bg-frescoWhite dark:bg-darkGray h-24"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RoomChipSkeleton;
