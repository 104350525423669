import axios from "../utils/axios";

export function getGamificationData(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get("/gamification/get-gamification-data", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
