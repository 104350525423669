import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Div100vh from "react-div-100vh";
import { useHistory } from "react-router-dom";
import { getUserData } from "../services/userService";
import Loader from "../VibehutUI/Loader";
import Button from "../VibehutUI/Button";
import { isMobile } from "../utils/utils";
import Avatar from "../VibehutUI/Avatar";
import ReviewModal from "../VibehutUI/ReviewModal/ReviewModal";
import ReviewConfirmationModal from "../VibehutUI/ReviewModal/ReviewConfirmationModal";
import { StoreContext } from "../Context/StoreContext";
import { getCallUsers } from "../services/callService";
import { showAddressBookModal } from "../actions/uiModalActions";
import AddressBookModal from "../VibehutUI/AddressBookModal";
import Toast from "../VibehutUI/Toast";
import CallStreak from "../components/home/CallStreak";
import { SideAd } from "../components/Ads";

function ReviewPage() {
  const { callId } = useParams();
  const history = useHistory();
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isReviewConfirmModalOpen, setIsReviewConfirmModalOpen] = useState(
    false,
  );
  const [showThankBtn, setShowThankBtn] = useState(true);
  const [callUsers, setCallUsers] = useState([]);
  const [room, setRoom] = useState();
  const [isLoading, setLoading] = useState(true);
  const [call, setCall] = useState(undefined);

  const fetchCurrentCallUserDetails = async () => {
    if (callId && user?.token) {
      try {
        setLoading(true);
        const callDetails = await getCallUsers(callId, user?.token);
        const users = callDetails.data?.users.map(user => getUserData(user));
        setCall({
          isRecordedCall: callDetails.data.isRecordedCall,
          _id: callDetails.data._id,
        });

        setRoom(callDetails.data.room);
        setCallUsers(users);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchCurrentCallUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId, user?.token]);

  const handleEndCall = () => {
    if (call?.isRecordedCall) {
      history.replace(`/?callId=${call?._id}`);
    } else {
      history.replace("/");
    }
  };

  const reviewCompletionCallback = () => {
    setIsReviewModalOpen(false);
    setIsReviewConfirmModalOpen(true);
    setShowThankBtn(false);
  };

  if (isLoading) {
    return (
      <div className="text-center bg-darkNight flex justify-center items-center min-h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-darkNight">
      <Div100vh>
        <div className="flex flex-col justify-center items-center h-full p-1 relative">
          <div
            className={`grid ${
              callUsers && callUsers.length <= 3
                ? "grid-flow-col auto-cols-max"
                : "lg:grid-cols-4 3xl:grid-cols-4 grid-cols-2"
            } gap-8 py-5`}
          >
            {callUsers?.slice(0, 12).map(user => {
              return (
                <Avatar
                  src={user?.profile_picture}
                  username={user?.username}
                  key={user._id}
                />
              );
            })}
          </div>
          <CallStreak isReviewPage={true} />
          <div className="flex flex-col justify-center items-center">
            <div className="space-y-1">
              <p className="text-center md:text-32px text-3xl font-bold text-white pt-8 custom-third-line-clamp max-w-lg">
                Enjoy your call?
              </p>
              <p className="pt-2 text-xl text-lightGray text-center">
                Say thanks by leaving a rating and review.
              </p>
            </div>
            <div className="pt-10 flex md:flex-row flex-col md:gap-2 gap-4">
              <div className="space-x-2 ">
                <Button
                  variant="vibhut-primary-outline"
                  onClick={handleEndCall}
                  size={`${isMobile() ? "vibhut-sm" : "vibhut-lg"}`}
                >
                  <span className="flex items-center gap-3 text-sm sm:text-base">
                    Back to Vibehut
                  </span>
                </Button>
                {showThankBtn && (
                  <Button
                    onClick={() => setIsReviewModalOpen(true)}
                    size={`${isMobile() ? "vibhut-sm" : "vibhut-lg"}`}
                  >
                    Say Thanks
                  </Button>
                )}
              </div>

              <Button
                onClick={() =>
                  showAddressBookModal(dispatch, {
                    modalType: "vibehut-invite",
                  })
                }
                size={`${isMobile() ? "vibhut-sm" : "vibhut-lg"}`}
              >
                Invite people to Vibehut
              </Button>
            </div>
          </div>
        </div>
      </Div100vh>
      <ReviewModal
        room={room}
        callUsers={callUsers}
        isOpen={isReviewModalOpen}
        setIsOpen={setIsReviewModalOpen}
        reviewCompletionCallback={reviewCompletionCallback}
      />
      <ReviewConfirmationModal
        isOpen={isReviewConfirmModalOpen}
        setIsOpen={setIsReviewConfirmModalOpen}
        call={call}
      />
      <AddressBookModal />
      {user ? <Toast autoHideDuration={6000} /> : undefined}
    </div>
  );
}

export default ReviewPage;
