import * as actionTypes from "../constants";
import * as connectionService from "../services/connectionService";

export function updateMyConnection(data, token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CONNECTION_FAILURE,
  });
  return connectionService
    .updateMyConnection(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_CONNECTION_SUCCESS,
        payload: res.data.connection,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function removeConnection(data, token, dispatch) {
  dispatch({
    type: actionTypes.REMOVE_CONNECTION_REQUEST,
  });
  return connectionService
    .updateMyConnection(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.REMOVE_CONNECTION_SUCCESS,
        payload: res.data.connection,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function getMyConnections(token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_CONNECTION_REQUEST,
  });
  return connectionService
    .getMyConnections(token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_CONNECTION_SUCCESS,
        payload: {
          connections: res.data.connections,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_CONNECTION_FAILURE,
      });
      throw err;
    });
}

export function getUserConnections(userId, token, dispatch, loadMore, offset) {
  dispatch({
    type: actionTypes.GET_USER_CONNECTION_REQUEST,
  });
  return connectionService
    .getUserFollowers(userId, token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_USER_CONNECTION_SUCCESS,
        payload: {
          connections: res.data.followersList,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_CONNECTION_FAILURE,
      });
      throw err;
    });
}

export function getMutualConnections(
  userId,
  token,
  dispatch,
  loadMore,
  offset
) {
  dispatch({
    type: actionTypes.GET_MUTUAL_CONNECTION_REQUEST,
  });
  return connectionService
    .getUserFollowings(userId, token, offset)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MUTUAL_CONNECTION_SUCCESS,
        payload: {
          connections: res.data.followingsList,
          loadMore: loadMore,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MUTUAL_CONNECTION_FAILURE,
      });
      throw err;
    });
}
