import { useAppState } from '../../../state';
import useDevices from '../../../hooks/useDevices/useDevices';

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 0 && (
        <>
          <p className="text-sm text-darkNight pb-1 font-medium">Speaker</p>
          <select
            name=""
            id=""
            className="border border-frescoWhite  focus:ring-0 outline-none text-darkNight text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-xl"
            onChange={e => setActiveSinkId(e.target.value as string)}
            value={activeSinkId}
          >
            {audioOutputDevices.map(device => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
}
