import React, { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import axios from "../../utils/axios";
import SubscriberTable from "./SubscriberTable";
import Download from "../../VibehutUI/Icons/Download";
import { StoreContext } from "../../Context/StoreContext";
import Empty from "../../VibehutUI/Empty";
import NoConnections from "../../VibehutUI/Icons/NoConnections";
import Loader from "../../VibehutUI/Loader";
import BlockUserTableList from "../../VibehutUI/BlockUserTableList";
import BlockUserModal from "../../VibehutUI/BlockUserModal";
import InviteModalForHostApprovalRoom from "../../components/rooms/InviteModalForHostApprovalRoom";
import { resetBlockList } from "../../actions/roomAction";
import * as actionTypes from "../../constants/actionTypes";
import Button from "../../VibehutUI/Button";

function RoomSubscibers() {
  let { roomId } = useParams();
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth: { user },
    room: { roomId: roomCustomId, blockedUsers },
  } = state;
  if (!roomId) {
    roomId = roomCustomId;
  }
  const [subscriberList, setSubscriberList] = useState([]);
  const [isSubsFetching, setIsSubsFetching] = useState(true);
  const [isBlockListFetching, setIsBlockListFetching] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenInvite, setIsOpenInvite] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);

  const [room, setRoom] = useState();
  React.useEffect(() => {
    const header = user?.token
      ? {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      : undefined;
    if (roomId) {
      resetBlockList(dispatch);
      setIsSubsFetching(true);
      axios
        .get(`/rooms/subscriber-list/${roomId}?limited=${true}`, header)
        .then((res) => {
          if (res.status === 200) {
            const _room = res.data.data.room;
            let subsUserInfo = res.data.data.subsUserInfo;
            if (
              _room?.isSubscriberRequireApproval &&
              _room?.owner?._id !== user?._id &&
              !_room?.moderators?.some(
                (moderatorId) => moderatorId === user?._id,
              )
            ) {
              subsUserInfo = subsUserInfo.filter(
                (info) =>
                  info.roomSubscribeStatus !== "Declined" &&
                  info.roomSubscribeStatus !== "Pending",
              );
            }
            setSubscriberList(subsUserInfo);
            setRoom(_room);
          } else {
            console.log(res);
          }
        })
        .finally(() => {
          setIsSubsFetching(false);
        });
      setIsBlockListFetching(true);
      axios
        .get(`/rooms/get-general-block-users/${roomId}`, header)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.BLOCK_USER_SUCCESS,
              payload: res.data?.data || [],
            });
          }
        })
        .finally(() => {
          setIsBlockListFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const headers = [
    { label: "Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Subscribe Date", key: "subscribeDate" },
    { label: "Last Seen", key: "lastSeen" },
  ];

  const csvReport = {
    data: subscriberList,
    headers: headers,
    filename: room ? `${room.name}_subscriber_list.csv` : "subscriber_list.csv",
  };

  return isSubsFetching ? (
    <div className="py-10">
      <Loader />
    </div>
  ) : (
    <React.Fragment>
      <div className="flex justify-between items-center pt-6">
        <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite">
          Subscriber List {room && `- ${room.name}`}
        </p>
        {room?.isSubscriberRequireApproval && room.owner?._id === user?._id && (
          <Button onClick={() => setIsOpenInvite(true)}>Invite</Button>
        )}
      </div>
      {subscriberList.length === 0 ? (
        <Empty
          Image={<NoConnections />}
          title="No Subscriber Yet"
          description="Be the first person to subscribe this room, you will get all notification about this room. "
          showButton={false}
        />
      ) : (
        <>
          <div
            className="w-full"
            style={{
              containerType: "inline-size",
            }}
          >
            <SubscriberTable subscriberList={subscriberList} room={room} />
          </div>
          <div className="flex py-3 space-x-2 items-center">
            <p className="text-darkGray font-medium">Export to CSV -</p>
            <CSVLink className="text-white flex" {...csvReport}>
              <Download />
            </CSVLink>
          </div>
        </>
      )}
      <div className="py-4 flex justify-between items-center">
        <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite">
          Blocked Users
        </p>
        {(user?._id === room?.owner?._id ||
          room?.moderators?.some((modId) => modId === user?._id)) && (
          <input
            type="text"
            className="rounded-xl select-none outline-none placeholder-darkGray search-input bg-white w-64"
            placeholder="Search..."
            onClick={() => {
              setIsOpenBlockModal(true);
            }}
            autoComplete="new-value"
            readOnly
          />
        )}
      </div>
      {isBlockListFetching ? (
        <div className="py-10">
          <Loader />
        </div>
      ) : (
        <div
          className="w-full"
          style={{
            containerType: "inline-size",
          }}
        >
          <BlockUserTableList subscriberList={blockedUsers} room={room} />
        </div>
      )}
      {isOpenBlockModal && (
        <BlockUserModal
          isOpen={isOpenBlockModal}
          setIsOpen={setIsOpenBlockModal}
          roomId={roomId}
          ownerId={room?.owner?._id}
        />
      )}
      <InviteModalForHostApprovalRoom
        setIsOpen={setIsOpenInvite}
        isOpen={isOpenInvite}
        roomId={room?._id}
        invitedUsers={invitedUsers}
        setInvitedUsers={setInvitedUsers}
      />
    </React.Fragment>
  );
}

export default RoomSubscibers;
