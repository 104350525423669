import { useEffect, useState } from "react";

function useIsPWA() {
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      // Check if the app is running in standalone mode (PWA)
      setIsPWA(true);
    }

    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default beforeinstallprompt event
      event.preventDefault();
      setIsPWA(true);
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      // Clean up event listener
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  return isPWA;
}

export default useIsPWA;
