import React from "react";

const Gamification = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#716DFF" />
      <g clipPath="url(#clip0_10589_139054)">
        <path
          d="M16.6667 9.33333L10 17.3333H16L15.3333 22.6667L22 14.6667H16L16.6667 9.33333Z"
          fill="#474747"
          stroke="#474747"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="8" y="8" width="16" height="16" rx="4" fill="#9C99FF" />
        <path
          d="M11 16.9097L14.0491 20L21 12"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10589_139054">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Gamification;
