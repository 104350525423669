import * as actionTypes from "../constants";
import * as personaService from "../services/personaService";
import { Persona, PersonaActivity, PersonaFaq } from "../types";

export function getMyPersonas(dispatch: any, token: string) {
  dispatch({
    type: actionTypes.GET_MY_PERSONAS_REQUEST,
  });
  return personaService
    .getMyPersonas(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_PERSONAS_SUCCESS,
        payload: {
          personas: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_PERSONAS_FAILURE,
      });
      throw err;
    });
}

export function getAllPersonas({
  dispatch,
  token,
  query,
  isMorePersonas,
  offset = 0,
  orderBy = "popular",
}: any) {
  dispatch({
    type: actionTypes.GET_ALL_PERSONAS_REQUEST,
    isMorePersonas: isMorePersonas,
  });
  return personaService
    .getAllPersonas({ token, query, offset, orderBy })
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ALL_PERSONAS_SUCCESS,
        payload: {
          personas: res.data,
          isMorePersonas: isMorePersonas,
          offset: offset,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ALL_PERSONAS_FAILURE,
      });
      throw err;
    });
}

export function createPersona(data: Persona, token: string, dispatch: any) {
  dispatch({
    type: actionTypes.CREATE_PERSONA_REQUEST,
  });
  return personaService
    .createPersona(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_PERSONA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_PERSONA_FAILURE,
      });
      throw err;
    });
}

export function updatePersona(
  data: Persona,
  personaId: string,
  token: string,
  dispatch: any,
) {
  dispatch({
    type: actionTypes.UPDATE_PERSONA_REQUEST,
  });
  return personaService
    .updatePersona(data, personaId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_PERSONA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_PERSONA_FAILURE,
      });
      throw err;
    });
}

export function deletePersona(personaId: string, token: string, dispatch: any) {
  dispatch({
    type: actionTypes.DELETE_PERSONA_REQUEST,
  });
  return personaService
    .deletePersona(personaId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.DELETE_PERSONA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw err;
    });
}

export function updatePersonaClickCount(
  personaId: string,
  token: string,
  dispatch: any,
) {
  dispatch({
    type: actionTypes.UPDATE_PERSONA_CLICK_COUNT_REQUEST,
  });
  return personaService
    .updatePersonaClickCount(personaId, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_PERSONA_CLICK_COUNT_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: actionTypes.UPDATE_PROFILE_PERSONA_CLICK_COUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_PERSONA_CLICK_COUNT_FAILURE,
      });
      throw err;
    });
}

export function createPersonaActivity(
  data: PersonaActivity,
  token: string,
  dispatch: any,
) {
  dispatch({
    type: actionTypes.CREATE_PERSONA_ACTIVITY_REQUEST,
  });
  return personaService
    .createPersonaActivity(data, token)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_PERSONA_ACTIVITY_SUCCESS,
        payload: {
          personaActivity: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_PERSONA_ACTIVITY_FAILURE,
      });
      throw err;
    });
}

export function getPersonaActivity(
  dispatch: any,
  token: string,
  personaId: string,
) {
  dispatch({
    type: actionTypes.GET_PERSONA_ACTIVITY_REQUEST,
  });
  return personaService
    .getPersonaActivity(token, personaId)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PERSONA_ACTIVITY_SUCCESS,
        payload: {
          personaActivity: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PERSONA_ACTIVITY_FAILURE,
      });
      throw err;
    });
}

export function getProfilePersonas(
  dispatch: any,
  userId: string,
  options?: any,
) {
  dispatch({
    type: actionTypes.GET_PROFILE_PERSONAS_REQUEST,
  });
  return personaService
    .getPersonaByCreator(userId, options)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PROFILE_PERSONAS_SUCCESS,
        payload: {
          personas: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PROFILE_PERSONAS_FAILURE,
      });
      throw err;
    });
}

export function getPersonaFaqs(
  dispatch: any,
  token: string,
  personaId: string,
) {
  dispatch({
    type: actionTypes.GET_PERSONA_FAQS_REQUEST,
  });
  return personaService
    .getPersonaFaqs(token, personaId)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PERSONA_FAQS_SUCCESS,
        payload: {
          personaFaqs: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PERSONA_FAQS_FAILURE,
      });
      throw err;
    });
}

export function createPersonaFaq(
  data: PersonaFaq,
  token: string,
  dispatch: any,
  personaId: string,
) {
  dispatch({
    type: actionTypes.CREATE_PERSONA_FAQ_REQUEST,
  });
  return personaService
    .createPersonaFaq(data, token, personaId)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_PERSONA_FAQ_SUCCESS,
        payload: {
          personaFaqs: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_PERSONA_FAQ_FAILURE,
      });
      throw err;
    });
}

export function getPersonasPremiumTags(dispatch: any, options: any) {
  dispatch({
    type: actionTypes.GET_PERSONA_PREMIUM_TAGS_REQUEST,
  });
  return personaService
    .getPersonasPremiumTags(options)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PERSONA_PREMIUM_TAGS_SUCCESS,
        payload: {
          personaPremiumTags: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_PERSONA_PREMIUM_TAGS_FAILURE,
      });
      throw err;
    });
}

export function getSubscribedRoomPersonas(dispatch: any, token: string) {
  dispatch({
    type: actionTypes.GET_ROOM_SUBSCRIBED_PERSONAS_REQUEST,
  });
  return personaService
    .getSubscribedRoomPersonas(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ROOM_SUBSCRIBED_PERSONAS_SUCCESS,
        payload: {
          personas: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ROOM_SUBSCRIBED_PERSONAS_FAILURE,
      });
      throw err;
    });
}

export function getRoomSubscribersPersonas(
  dispatch: any,
  roomId: string,
  query?: any | null,
) {
  dispatch({
    type: actionTypes.GET_ROOM_SUBSCRIBER_PERSONAS_REQUEST,
  });
  return personaService
    .getRoomSubscribersPersonas(roomId, query)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_ROOM_SUBSCRIBER_PERSONAS_SUCCESS,
        payload: {
          personas: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ROOM_SUBSCRIBER_PERSONAS_FAILURE,
      });
      throw err;
    });
}

export function getMyPersonaVideoInsights(dispatch: any, token: string) {
  dispatch({
    type: actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_REQUEST,
  });
  return personaService
    .getMyPersonaVideoInsights(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_SUCCESS,
        payload: {
          personaVideoInsights: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_PERSONA_VIDEO_INSIGHTS_FAILURE,
      });
      throw err;
    });
}

export function getMyPersonaArticles(dispatch: any, token: string) {
  dispatch({
    type: actionTypes.GET_MY_PERSONA_ARTICLES_REQUEST,
  });
  return personaService
    .getMyPersonaArticles(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_PERSONA_ARTICLES_SUCCESS,
        payload: {
          personaArticles: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_MY_PERSONA_ARTICLES_FAILURE,
      });
      throw err;
    });
}

export function initiatePersonaVideoInsight(
  dispatch: any,
  token: string,
  body: {
    persona: string;
    videoLink: string;
    type: string;
    metaData: any;
    externalId: string;
  },
) {
  dispatch({
    type: actionTypes.CREATE_MY_PERSONA_VIDEO_INSIGHT_REQUEST,
  });
  return personaService
    .initiatePersonaVideoInsight(token, body)
    .then((res) => {
      if (!res.data?.alreadyGenerated) {
        dispatch({
          type: actionTypes.CREATE_MY_PERSONA_VIDEO_INSIGHT_SUCCESS,
          payload: {
            personaVideoInsight: res.data,
          },
        });
      } else {
        dispatch({
          type: actionTypes.ALREADY_ADDED_PERSONA_VIDEO_INSIGHT,
          payload: {
            personaVideoInsight: res.data,
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_MY_PERSONA_VIDEO_INSIGHT_FAILURE,
      });
      throw err;
    });
}

export function regeneratePersonaVideoInsight(
  dispatch: any,
  token: string,
  insightId: string,
) {
  dispatch({
    type: actionTypes.UPDATE_PERSONA_VIDEO_INSIGHT_REQUEST,
  });
  return personaService
    .regeneratePersonaVideoInsight(token, insightId)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_PERSONA_VIDEO_INSIGHT_SUCCESS,
        payload: {
          personaVideoInsight: res.data,
        },
      });
      dispatch({
        type: actionTypes.ALREADY_ADDED_PERSONA_VIDEO_INSIGHT,
        payload: {
          personaVideoInsight: null,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_PERSONA_VIDEO_INSIGHT_FAILURE,
      });
      throw err;
    });
}

export function generatePersonaArticles(
  dispatch: any,
  token: string,
  body: {
    persona: string;
    channel: string;
    type: string;
  },
) {
  dispatch({
    type: actionTypes.CREATE_MY_PERSONA_ARTICLE_REQUEST,
  });
  return personaService
    .generatePersonaArticles(token, body)
    .then((res) => {
      dispatch({
        type: actionTypes.CREATE_MY_PERSONA_ARTICLE_SUCCESS,
        payload: {
          personaArticle: res.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CREATE_MY_PERSONA_ARTICLE_FAILURE,
      });
      throw err;
    });
}
