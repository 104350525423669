import React, { useContext } from "react";
import { StoreContext } from "../../Context/StoreContext";

function Reactions({ chat, removeUserOrAddReaction, addUserToReaction }) {
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;
  const handleReactionClick = (reaction) => {
    if (reaction.owners.find((owner) => owner._id === user?._id)) {
      removeUserOrAddReaction({ ...reaction, owner: user?._id });
    } else {
      addUserToReaction({ ...reaction, owner: user?._id });
    }
  };
  const checkIsAuthUserReacted = (reaction) => {
    return reaction?.owners.find((owner) => owner._id === user?._id)
      ? chat.isSend
        ? "border-2 border-white"
        : "border-2 border-primary"
      : "";
  };
  return (
    <div className="flex gap-1 relative flex-wrap">
      {chat.reactions &&
        chat.reactions.map((reaction) => (
          <div className="flex flex-col" key={reaction.emoji}>
            <span>
              <span
                onClick={() => handleReactionClick(reaction)}
                className={`cursor-pointer bg-bitClout rounded-3xl flex gap-1 px-2 items-center py-0.5 ${checkIsAuthUserReacted(
                  reaction
                )}`}
              >
                {reaction?.owners?.length > 1 && (
                  <span
                    className={`${chat.isSend ? "text-white" : "text-white"}`}
                  >
                    {reaction?.owners?.length}
                  </span>
                )}{" "}
                {reaction.emoji}
              </span>
            </span>
          </div>
        ))}
    </div>
  );
}

export default Reactions;
