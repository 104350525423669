import * as actionTypes from "../constants";

export const showLoginModal = (dispatch, data = null) => {
  dispatch({
    type: actionTypes.SHOW_MODAL,
    payload: {
      modalType: actionTypes.ModalType.LOGIN,
      data: data,
    },
  });
};

export const showNotificationActionModal = (dispatch, data = null) => {
  dispatch({
    type: actionTypes.SHOW_MODAL,
    payload: {
      modalType: actionTypes.ModalType.NOTIFICATIONSUBSCRIPTION,
      data: data,
    },
  });
};

export const hideUiModal = (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_MODAL,
  });
};

export const showAddressBookModal = (dispatch, data = null) => {
  dispatch({
    type: actionTypes.SHOW_MODAL,
    payload: {
      modalType: actionTypes.ModalType.ADDRESSBOOK,
      data: data,
    },
  });
};
