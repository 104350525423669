import TextareaAutosize from "react-textarea-autosize";
const LimitedTextarea2 = ({
  rows,
  cols,
  value,
  limit,
  isCommentInput = false,
  onChange,
  placeHolder = "Let others know what to expect...",
}) => {
  const setFormattedContent = (value) => {
    let text = value;
    onChange(text);
  };

  return (
    <div className="relative   p-0">
      <TextareaAutosize
        cacheMeasurements
        onChange={(e) => setFormattedContent(e.target.value)}
        className={`mt-1.5 border dark:border-0 text-matteGray   border-lightGray placeholder-notifyBg text-sm
                pr-8 pl-4 custom-scrollbar rounded-xl w-full outline-none resize-none overflow-auto`}
        placeholder={placeHolder}
        rows={rows}
        maxRows={rows}
        value={value}
        minRows={rows}
      />
      <div
        className={`absolute top-[${isCommentInput ? "29px" : "19px"}] right-0`}
      >
        {/* <p className="text-notifyBg text-sm">
          {value.length}/{limit}
        </p> */}
      </div>
    </div>
  );
};

export default LimitedTextarea2;
