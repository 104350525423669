import axios from "../utils/axios";

export const getAccessPasses = async (authToken) => {
  return axios
    .get(`/accessPass`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }

      return {
        data: [],
      };
    });
};
