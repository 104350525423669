import axios from "../utils/axios";

export function getPosts(token, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/posts?limit=10&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getPremiumActivityPosts(token, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/premiumActivity?limit=30&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomPosts(token, offset, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/posts/room?roomId=${roomId}&limit=5&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserPosts(token, offset, userId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/posts/user?userId=${userId}&limit=5&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getPersonaPosts(token, offset) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/posts/persona?limit=5&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getEventPosts(token, offset, eventId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/posts/event?eventId=${eventId}&limit=5&offset=${offset}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
