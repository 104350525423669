import { useEthers } from "@usedapp/core";
import React, { useCallback, useEffect, useReducer } from "react";
import { getGenaralRoom } from "../actions/roomAction";
import rootReducer from "../reducers";
import { updateMetamskEns } from "../actions/userActions";
import { ethers } from "ethers";
import { calculateAndFormatDeSoInUsd } from "../utils/desoCalculator";
import * as actionTypes from "../constants";

let user = window.localStorage.getItem("user");
const currentCallRoomId = window.localStorage.getItem("currentCallRoomId");
const currentRoomKey = new URLSearchParams(window.location.search).get(
  "roomKey",
);
const removeGuestUser = () => {
  if (user) {
    const guestUser = JSON.parse(user);
    if (
      guestUser.provider === "guest" &&
      currentRoomKey !== currentCallRoomId
    ) {
      window.localStorage.removeItem("user");
      user = null;
    }
  }
};
removeGuestUser();

const initialState = {
  auth: {
    user: user ? JSON.parse(user) : null,
    isFetching: false,
    isRecentCallFetching: false,
    isCallStreakFetching: false,
    showInCompleteProfileModal: false,
    referral: null,
    isReferralCodeFetching: false,
  },
  user: {
    userId: null,
    callStreak: [],
    isFetching: false,
    onCallUsers: [],
    onCallUsersCount: 0,
    lastActiveUsers: [],
    lastActiveUsersCount: 0,
    freeToTalkUsers: [],
    freeToTalkUsersCount: 0,
    page: 0,
    isMore: false,
    isMoreFetching: false,
    isPaymentStatus: undefined,
    paymentMessage: null,
    isSidebarDataFetching: true,
    sidebarUsers: [],
    offset: 0,
    isMoreUsers: false,
    isMoreUsersFetching: false,
    sidebarRecentCalls: [],
    gamification: {
      data: null,
      total: 17,
      completed: 0,
    },
    recentCalls: [],
  },

  chat: {
    magData: [],
    offset: 0,
    isMore: false,
    isMoreFetching: false,
    unreadMessagesCount: 0,
    isChatLoading: true,
    canScroll: true,
    isAnimatedScroll: true,
    isPersonaThinking: false,
    unlockedChatRoom: [],
    liveStreamMessages: [],
    liveStreamUnreadMessageCount: 0,
    activeTab: "members",
  },
  room: {
    roomId: null,
    rooms: [],
    searchedRooms: [],
    isSearching: false,
    isMoreSearching: false,
    myRooms: [],
    myRoomsCount: undefined,
    subscriberCount: undefined,
    myRoomsSlugCount: undefined,
    subscibedRooms: [],
    nftCollectionRooms: [],
    isFetching: false,
    isFetchingMyRooms: false,
    offset: 0,
    isMore: false,
    generalRoom: null,
    recentRooms: [],
    popularRooms: [],
    topRooms: [],
    isFetchingTopRooms: false,
    isFetchingMoreTopRooms: false,
    isTopRoomMore: false,
    trendingRooms: [],
    oneTimeloaderAllRooms: true,
    oneTimeloaderMyRooms: true,
    liveRoom: [],
    oneTimeloaderUserRooms: true,
    oneTimeloaderSubscribedRooms: true,
    userRooms: [],
    invitedUsers: [],
    blockedUsers: [],
    notificationsubscriptions: 0,
    totalSubscribedRooms: 0,
    chatRoom: undefined,
    roomChats: [],
    isFetchingChatRoom: false,
    conversations: [],
    isFetchingConversations: false,
    conversationOffset: 0,
    isMoreConv: false,
    isMoreConvFetching: false,
    dmConversations: [],
    isFetchingDmConversations: false,
    dmConversationOffset: 0,
    isMoreDmConv: false,
    isMoreDmConvFetching: false,
    roomConversations: [],
    isFetchingRoomConversations: false,
    roomConversationOffset: 0,
    isMoreRoomConv: false,
    isMoreRoomConvFetching: false,
    isFetchingMoreConversations: false,
    moreConversations: [],
    moreConversationOffset: 0,
    isMoreConvMore: false,
    isMoreConvMoreFetching: false,
    activeCalls: [],
    homePopularRooms: [],
    isFetchingHomePopRoom: false,
    isMoreHomePopularRoomFetching: false,
    isMoreHomePopRoom: false,
    homePopRoomOffset: 0,
    isFetchingScreenRoom: true,
  },
  connection: {
    data: [],
    isFetching: false,
    error: null,
    offset: 0,
    isMore: false,
    userConnections: [],
    mutualConections: [],
  },
  eventCreation: {
    hasAccess: false,
    isFetching: false,
  },

  event: {
    events: [],
    isMoreRoomEventsFetching: false,
    isMoreRoomEvents: false,
    roomEventsOffset: 0,
    liveEvents: [],
    upcomingEvents: [],
    pastEvents: [],
    isFetching: false,
    eventId: null,
    popularEvents: [],
    HomeliveEvents: [],
    HomeUpcomingEvents: [],
    homePageEvent: null,
    isEventsPageLoading: true,
    allEventsOfRoom: [],
    roomUpcomigEvents: [],
    roomPastEvents: [],
    roomLiveEvents: [],
    allEvents: [],
    calenderEvents: [],
    userLiveEvents: [],
    userProfileLiveEvents: [],
    userProfileUpcomingEvents: [],
    userProfilePastEvents: [],
    userUpcomingEvents: [],
    offset: 0,
    isMore: false,
    isMoreFetching: false,
    newlyAddedEvents: [],
    userAllEventOffset: 0,
    isUserAllEventMore: false,
    isUserAllEventFetching: false,
    updatedRsvpEvent: undefined,
    roomAllEventOffset: 0,
    isRoomAllEventMore: false,
    isRoomAllEventFetching: false,
    isFetchingModalEvents: false,
    rsvpEventErrorMessage: "",
    groupedLiveEvents: {},
    groupedUpcomingEvents: {},
    groupedPastEvents: {},
  },
  notification: {
    data: [],
    isFetching: false,
    totalNotifications: 0,
    offset: 0,
    isMore: false,
    instantNotifications: [],
  },
  wave: {
    waves: [],
    isFetchingWave: false,
    isUserWaveing: false,
  },
  post: {
    posts: [],
    premiumActivityPosts: [],
    premiumOffset: 0,
    isPremiumFetching: false,
    isPremiumMoreFetching: false,
    isFetching: false,
    error: null,
    offset: 0,
    isMore: false,
    isMoreFetching: false,
    homePosts: localStorage.getItem("vibehutActivity")
      ? JSON.parse(localStorage.getItem("vibehutActivity"))
      : [],
    isActivityFetching: false,
    roomOffset: 0,
    isMoreRoomPosts: false,
    roomPosts: [],
    userPosts: [],
    isMoreUserPosts: false,
    userOffset: 0,
    personaPosts: [],
    isMorePersonaPosts: false,
    personaOffset: 0,
    eventPosts: [],
    isMoreEventPosts: false,
    eventOffset: 0,
  },
  subscription: {
    mySubscriptions: [],
    isFetching: false,
    mySubscribers: [],
    lastFetchedTime: 0,
  },
  publishStore: {
    publishVideos: [],
    total: 0,
    isFetching: false,
    isMyFetching: false,
    myPublishVideos: [],
    currentVideoPlayingId: null,
    isUpdating: false,
    myVideosCount: [],
  },
  role: {
    isFetching: false,
    roles: [],
  },
  eventSubscriptionModal: {
    selectedEvent: null,
    message: null,
    type: null,
    showEventSubscriptionModal: false,
  },
  globalToast: {
    message: null,
    type: null,
    show: false,
    autoHideDuration: 6000,
  },
  globalUiModal: {
    isOpen: false,
    modalType: null,
    data: null,
  },
  accountSettingsModal: {
    openModal: false,
    setNotificationModal: false,
    setContactModal: true,
    setWalletModal: false,
    setPlanModal: false,
    setAppSettingsModal: false,
    setGamificationModal: false,
    currentModal: "CONTACT",
    isTopUpActive: false,
  },
  setting: {
    isFetching: false,
    systemSetting: {},
    exchangeRate: {
      BuyDeSoFeeBasisPoints: 0,
      NanosPerETHExchangeRate: 0,
      NanosSold: 0,
      SatoshisPerBitCloutExchangeRate: 0,
      SatoshisPerDeSoExchangeRate: 0,
      USDCentsPerBitCloutExchangeRate: 0,
      USDCentsPerBitCloutReserveExchangeRate: 0,
      USDCentsPerBitcoinExchangeRate: 0,
      USDCentsPerDeSoBlockchainDotCom: 0,
      USDCentsPerDeSoCoinbase: 0,
      USDCentsPerDeSoExchangeRate: 0,
      USDCentsPerDeSoReserveExchangeRate: 0,
      USDCentsPerETHExchangeRate: 0,
    },
    diamondExchangeLists: [],
  },
  accessPass: {
    data: [],
    isFetching: false,
  },
  referral: {
    isFetching: false,
    isCompleted: false,
  },
  requestEvent: {
    requestEvents: [],
    isFetching: false,
    error: null,
    eventId: null,
    isCreatingIndex: null,
    requestEventChatModal: null,
  },
  feed: {
    feedPosts: [],
    activityFeedPosts: [],
    isFeedFetching: false,
    isMoreFeedFetching: false,
    isMoreFeedAvailable: false,
    error: null,
    page: 0,
    lastPostHash: "",
    latestPost: null,
  },
  singlePost: {
    post: null,
    isFetching: false,
  },
  commentModal: {
    showCommentModal: false,
    parentPost: null,
  },
  premiumCreator: {
    isFetching: false,
    premiumAccess: [],
  },
  persona: {
    myPersonas: [],
    allPersonas: [],
    profilePersonas: [],
    subscribedRoomPersonas: [],
    error: null,
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
    aiReplyEnable: true,
    formState: {
      data: {
        _id: "",
        name: "",
        isEnable: true,
        about: "",
        lookingFor: "",
        notLookingFor: "",
        highlights: "",
        lowlights: "",
        generatedBio: "",
        avatar: {
          urls: {
            regular: "",
            small: "",
          },
        },
        avatarFile: null,
        includeMe: true,
        notifyMe: true,
        recordedVideoLink: "",
        peopleShouldKnow: "",
        tags: [],
        premiumTag: "",
        faqs: [
          {
            question: "",
            answer: "",
          },
        ],
        companyAffiliation: {
          isActive: false,
          imgUrl: "",
          title: "",
          link: "",
        },
        roomSpecific: false,
        room: "",
      },
      errorMessage: {
        nameError: "",
        avatarError: "",
        aboutError: "",
        generatedBioError: "",
        tagsError: "",
        faqsError: "",
        premiumTagsError: "",
        companyAffiliationError: "",
      },
    },
    personaActivity: [],
    personaFaqs: [],
    isCreatingActivity: false,
    isFetchingActivity: false,
    isFetchingMyPersonas: false,
    isFetchingProfilePersonas: false,
    isCreatingFaq: false,
    isFetchingFaqs: false,
    personaPremiumTags: [],
    isFetchingPersonaPremiumTags: false,
    isFetchingSubscribedRoomPersonas: false,
    isFetchingRoomSubscriberPersonas: false,
    roomScriberPersonas: [],
    personaVideoInsights: [],
    isFetchingPersonaVideoInsights: false,
    isCreatingPersonaVideoInsight: false,
    isPersonaVideoInsightSuccessfullyCreated: false,
    alreadyAddedPersonaVideoInsight: null,
    isUpdatingPersonaVideoInsight: false,
    isPersonaVideoInsightSuccessfullyUpdated: true,
    personaArticles: [],
    isFetchingPersonaArticles: false,
    isCreatingPersonaArticle: false,
  },
  partner: {
    partners: [],
    offset: "",
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
  promotion: {
    myPromotions: [],
    offset: "",
    approvedPromotion: {},
    isFetchingMyPromotions: false,
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
  bookingType: {
    bookingTypes: [],
    offset: "",
    isFetchingMyPromotions: false,
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
  news: {
    newsPosts: [],
    isFeedFetching: false,
    isMoreFeedFetching: false,
    isMoreFeedAvailable: false,
    error: null,
    page: 0,
    lastUpdated: undefined,
  },
  calls: {
    rawRecordings: [],
    isFetchingRecordings: false,
    error: null,
    myCallCount: undefined,
  },
};

const getEns = (wallet_address) =>
  new Promise(function (resolve, reject) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider.send("eth_requestAccounts", []);

    const ens = provider.lookupAddress(wallet_address);
    resolve(ens);
    reject();
  });

export const StoreContext = React.createContext();

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const { account } = useEthers();
  // Important(!): memoize array value. Else all context consumers update on *every* render
  const store = React.useMemo(() => [state, dispatch], [state]);
  React.useEffect(() => {
    dispatch({ type: "__INIT__" });
  }, []);

  const {
    auth: { user },
    room: { generalRoom },
    setting: { exchangeRate },
  } = state;

  const getDiamondExchangeList = useCallback(() => {
    const calculateDiamondsWorth = (count) => {
      const nanos = 5000 * Math.pow(10, count);
      const decimalPlaces = Math.max(2, 4 - count);
      const usd = calculateAndFormatDeSoInUsd(
        nanos,
        exchangeRate.USDCentsPerDeSoCoinbase,
        true,
        decimalPlaces,
      );
      return Number(usd);
    };
    const options = [0.01, 0.01];
    for (let i = 3; i < 7; i++) {
      const usd = calculateDiamondsWorth(i);
      const roundedUsd = Math.round(usd * 1000) / 1000;
      options.push(roundedUsd);
    }
    dispatch({
      type: actionTypes.UPDATE_DIAMOND_EXCHANGE_SUCCESS,
      payload: options,
    });
  }, [exchangeRate.USDCentsPerDeSoCoinbase]);

  useEffect(() => {
    if (exchangeRate?.USDCentsPerDeSoCoinbase) {
      getDiamondExchangeList();
    }
  }, [exchangeRate.USDCentsPerDeSoCoinbase]);

  useEffect(() => {
    if (generalRoom === null) {
      getGenaralRoom(user?.token, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.provider === "metamask" && account) {
      getEns(account)
        .then((ens) => {
          updateMetamskEns(user.token, ens, dispatch);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <StoreContext.Provider value={{ state: store[0], dispatch: store[1] }}>
      {" "}
      {children}{" "}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
