import * as actionTypes from "../constants";
import * as referralService from "../services/referralService";

export function updateUserReferralCode(referralCode, token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_AUTH_USER_REFERRAL_REQUEST,
  });
  return referralService
    .updateUserReferralCode(referralCode, token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_AUTH_USER_REFERRAL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_AUTH_USER_REFERRAL_FAILURE,
      });
      throw err;
    });
}

export function createDefaultUserReferralCode(token, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_AUTH_USER_REFERRAL_REQUEST,
  });
  return referralService
    .createDefaultUserReferralCode(token)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_AUTH_USER_REFERRAL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_AUTH_USER_REFERRAL_FAILURE,
      });
    });
}

export function getUserReferral(dispatch, token) {
  dispatch({
    type: actionTypes.GET_AUTH_USER_REFERRAL_REQUEST,
  });
  return referralService
    .getUserReferral(token)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_AUTH_USER_REFERRAL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_AUTH_USER_REFERRAL_FAILURE,
      });
    });
}
