import UserCard from "../../components/CardComponents/UserCard";
import DesoUserCard from "../../components/CardComponents/DesoUserCard";
import { getUserData } from "../../utils/vibehutUtils";
import { SetStateAction, useContext, useMemo } from "react";
import { VibehutDataContext } from "../../components/VibehutDataProvider";

interface SendInviteProps {
  user: any;
  invitedUsers: any[];
  setInvitedUsers: React.Dispatch<SetStateAction<any[]>>;
  desoInvitedUsers: any[];
  setDesoInvitedUsers: React.Dispatch<SetStateAction<any[]>>;
}

export default function SendInvite({
  user,
  invitedUsers,
  desoInvitedUsers,
  setInvitedUsers,
  setDesoInvitedUsers,
}: SendInviteProps) {
  const { invitedUsers: alreadyInvitedUserList } =
    useContext(VibehutDataContext);

  const alreadyInvitedUser = useMemo(() => {
    return alreadyInvitedUserList?.some((item) => {
      return item === user?._id || item === user?.PublicKeyBase58Check;
    });
  }, [alreadyInvitedUserList, user.PublicKeyBase58Check, user._id]);

  const isDesoUserChecked = (publicKey: string) => {
    const data =
      (desoInvitedUsers &&
        desoInvitedUsers?.length > 0 &&
        desoInvitedUsers?.some((item) => {
          return item === publicKey;
        })) ||
      false;
    return data;
  };

  const isUserChecked = (userId: string) => {
    const data =
      (invitedUsers &&
        invitedUsers?.length > 0 &&
        invitedUsers?.some((item) => {
          return item === userId;
        })) ||
      false;
    return data;
  };

  const isCheckInput = (user: any) => {
    const isDesoUser = user?.PublicKeyBase58Check;
    if (isDesoUser) {
      return isDesoUserChecked(user.PublicKeyBase58Check);
    }
    return isUserChecked(user._id);
  };

  const handleUserSelect = (e: any) => {
    const user = JSON.parse(e.target.value);
    const isDesoUser = user?.PublicKeyBase58Check;
    if (isDesoUser) {
      if (isDesoUserChecked(user.PublicKeyBase58Check)) {
        const _users = desoInvitedUsers?.filter(
          (f) => f !== user.PublicKeyBase58Check,
        );
        setDesoInvitedUsers(_users);
        return;
      }
      setDesoInvitedUsers((prev: any) => {
        return [...prev, user.PublicKeyBase58Check];
      });
    } else {
      if (isUserChecked(user._id)) {
        const _users = invitedUsers?.filter((f) => f !== user._id);
        setInvitedUsers(_users);
        return;
      }
      setInvitedUsers((prev) => {
        return [...prev, user._id];
      });
    }
  };

  return (
    <div>
      <div className="py-2 flex items-center justify-between">
        {user?.PublicKeyBase58Check ? (
          <DesoUserCard user={user} />
        ) : (
          <UserCard
            user={{
              ...getUserData(user),
              room: user.room,
            }}
            userState={user.userState}
            isSearchResultCard
            fromCallProfile
            isInviteUserList
            showSocialLinks={false}
            showConnectionStatus={false}
          />
        )}
        <div className="px-2">
          <input
            type="checkbox"
            name="roomTypes"
            className="appearance-none h-[18px] w-[18px] border-primaryGray rounded-full transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
            checked={isCheckInput(user) || !!alreadyInvitedUser}
            disabled={!!alreadyInvitedUser}
            value={JSON.stringify(user)}
            onChange={handleUserSelect}
          />
        </div>
      </div>
      {!!alreadyInvitedUser && (
        <p className="text-darkGray text-xs ml-3 italic">Already Invited</p>
      )}
    </div>
  );
}
