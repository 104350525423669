import React, { useState, Fragment, useContext, useEffect } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import StarRatingComponent from "react-star-rating-component";
import { useParams } from "react-router-dom";
import Close from "../Icons/Close";
import Button from "../Button";
import Loader from "../Loader";
import StarIconFilled from "../Icons/StarIconFilled";
import LimitedTextarea from "../LimitedTextarea";
import { StoreContext } from "../../Context/StoreContext";
import { saveCompliment } from "../../services/complimentService";
import StarIcon from "../Icons/StarIcon";
import DownArrow from "../Icons/DownArrow";
import UserCard from "../UserCard";

export default function ReviewModal({
  room,
  callUsers,
  isOpen,
  setIsOpen,
  reviewCompletionCallback,
}) {
  const { state } = useContext(StoreContext);
  const {
    auth: { user },
  } = state;
  const { callId } = useParams();
  const [reviewText, setReviewText] = useState("");
  const [userThanked, setUserThanked] = useState();
  const [rating, setRating] = useState(5);
  const [isSaving, setIsSaving] = useState(false);
  const [isRoomSelected, setRoomSelected] = useState(true);

  useEffect(() => {
    if (user?.token) {
      const callUser = callUsers.find((item) => item._id !== user._id);
      setUserThanked(callUser);
    }
  }, [user, callUsers]);

  const handleRating = (rate) => {
    setRating(rate);
  };

  const submitCompliment = () => {
    setIsSaving(true);
    saveCompliment(user?.token, {
      rating: rating,
      description: reviewText,
      callId: callId,
      ...(!isRoomSelected && userThanked?._id && { user: userThanked._id }),
    })
      .then(() => {
        setReviewText("");
        setRating(5);
      })
      .catch(() => { })
      .finally(() => {
        reviewCompletionCallback();
        setIsSaving(false);
      });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md text-left overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="bg-white p-4 border-b border-frescoWhite">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-darkNight">
                      Say Thanks
                    </p>
                    <span
                      className="bg-frescoWhite p-9px rounded-full cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="px-4 pt-4 space-y-4">
                  <div className="space-y-2">
                    <p className="text-sm font-medium text-darkNight">Rating</p>
                    <StarRatingComponent
                      name="rate"
                      starCount={5}
                      value={rating}
                      onStarClick={handleRating}
                      renderStarIcon={(index, value) => {
                        if (index <= value) {
                          return <StarIconFilled />;
                        }
                        return <StarIcon />;
                      }}
                    />
                  </div>
                  {room && room.isGroupRoom && !room?.isWaveRoom ? (
                    <Menu as="div" className="relative inline-block text-left">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="">
                              <div className="flex items-center space-x-2">
                                <span className=" ">To : </span>
                                <span className="flex items-center py-2 px-3.5 bg-frescoWhite space-x-1 rounded-full cursor-pointer text-sm">
                                  <p className="flex items-center gap-2">
                                    {isRoomSelected
                                      ? room.name
                                      : userThanked?.name ||
                                      userThanked?.username}
                                    <span
                                      className={` transition-all mt-1 ${!open ? "" : "rotate-180"
                                        }`}
                                    >
                                      <DownArrow />
                                    </span>
                                  </p>
                                </span>
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute left-0 top-8 w-60 mt-4 origin-top-right bg-white dark:bg-componentColor divide-y divide-gray-100 dark:divide-matteGray rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                              <div
                                onClick={() => {
                                  setRoomSelected(true);
                                }}
                                className="px-1 py-1 relative"
                              >
                                <Menu.Item
                                  as="div"
                                  className="flex items-center"
                                >
                                  <div className="group flex rounded-md items-center w-full px-2 pl-3.5 py-2 text-sm space-x-3 cursor-pointer">
                                    <img
                                      alt="room"
                                      src={
                                        room.cover?.urls?.small
                                          ? room.cover?.urls?.small
                                          : `${process.env.PUBLIC_URL}/images/user.png`
                                      }
                                      className="w-8 object-cover h-8 rounded-full"
                                      loading="lazy"
                                    />
                                    <p className="font-medium text-lg text-navyGray dark:text-frescoWhite custom-chat-line-clamp ">
                                      {room.name}
                                    </p>
                                  </div>
                                </Menu.Item>
                              </div>
                              {callUsers.map((user, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setUserThanked(user);
                                    setRoomSelected(false);
                                  }}
                                  className="px-1 py-1 relative"
                                >
                                  <Menu.Item
                                    as="div"
                                    className="flex items-center"
                                  >
                                    <span
                                      className={`group flex rounded-md items-center justify-between w-full px-2  text-sm`}
                                    >
                                      <span className="text-darkNight dark:text-frescoWhite">
                                        <UserCard
                                          user={user}
                                          isOpenProfile={false}
                                        />
                                      </span>
                                    </span>
                                  </Menu.Item>
                                </div>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  ) : (
                    <p>
                      <span className=" ">To-</span>
                      <span className="ml-3 py-2 px-3.5 bg-frescoWhite space-x-1 rounded-full   text-sm">
                        <span>
                          {userThanked?.name || userThanked?.username}
                        </span>
                      </span>
                    </p>
                  )}

                  <div className="pb-2 space-y-1">
                    <p className="text-sm font-medium text-darkNight">Review</p>
                    <LimitedTextarea
                      limit={2000}
                      value={reviewText}
                      rows={7}
                      onChange={(text) => setReviewText(text)}
                      placeHolder="Leave a review of your call..."
                    />
                  </div>
                </div>
                <div className="px-4 py-3 flex flex-row justify-end space-x-3">
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                  {isSaving ? (
                    <Loader />
                  ) : (
                    <Button
                      size="vibhut-sm"
                      variant="vibhut-primary"
                      onClick={() => submitCompliment()}
                    >
                      Send
                    </Button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
