import CryptoJS from 'crypto-js';

export const encryptMsg = (secretKey: string, msg: string) => {
  const ciphertext = CryptoJS.AES.encrypt(msg, secretKey).toString();
  return ciphertext;
};

export const decryptMsg = (secretKey: string, ciper: string) => {
  try {
    const originalText = CryptoJS.AES.decrypt(ciper, secretKey).toString(CryptoJS.enc.Utf8);

    if (originalText) {
      return originalText;
    }
    return ciper;
  } catch (error) {
    return ciper;
  }
};
