import React, { useContext, useEffect, useState } from "react";
import useVibehutData from "../../hooks/useVibehutData/useVibehutData";
import { globals } from "../../globals";
import Button from "../Button";
import LimitedTextarea from "../LimitedTextArea/LimitedTextArea";
import { StoreContext } from "../../../Context/StoreContext";
import { isFreePlanUser, saveToLocalStorage } from "../../../utils/utils";
import ConfirmationModal from "../../../VibehutUI/ConfirmationModal";
import { openPlanModal } from "../../../actions/profileModalActions";
import { showToast } from "../../../actions/toastActions";

const DefaultMSG = [
  "Join us now 🎉",
  "It's a Vibe in here, join anytime 🙌",
  "Be right back ⏰",
  "Wait a minute to join ⏳",
];

const BoostCall = () => {
  const { state, dispatch } = useContext(StoreContext);
  const {
    auth,
    calls: { myCallCount },
    role,
  } = state;
  const { boostCall } = useVibehutData(globals.roomId);
  const [message, setMessage] = useState<string>("");
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const boostMsg = localStorage.getItem("customBoostMessage");

    if (boostMsg) setMessage(JSON.parse(boostMsg));
  }, []);

  const handleCustomInput = () => {
    if (myCallCount > 25 || !isFreePlanUser(role, auth.user)) {
      setIsCustom(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  return (
    <div className="">
      <div className="flex flex-col gap-y-2 pt-3 pl-2">
        {DefaultMSG.map((msg, ind) => {
          return (
            <label key={ind} className="flex gap-1.5 dark:text-white">
              <input
                type="radio"
                name="call message"
                className="appearance-none  h-4 w-4 border-primaryGray rounded-full transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
                onClick={() => {
                  setMessage(msg);
                  setIsCustom(false);
                }}
              />
              <p className="text-base">{msg}</p>
            </label>
          );
        })}
        <label className="flex gap-1.5 dark:text-white">
          <input
            type="radio"
            name="call message"
            className="appearance-none  h-4 w-4 border-primaryGray rounded-full transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer  text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
            onClick={handleCustomInput}
          />
          <p className="text-base">Custom Input</p>
        </label>
        {isCustom && (
          <LimitedTextarea
            limit={200}
            value={message}
            rows={5}
            onChange={(text: string) => setMessage(text)}
            placeHolder="Write your message here"
          />
        )}
        <label className="flex pt-3">
          <input
            type="checkbox"
            name="roomTypes"
            className="appearance-none h-3 w-3 border-primaryGray rounded-sm transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer text-primary focus:outline-none focus:ring-primary ring-white focus:ring-1"
            checked={showMessage}
            onChange={() => setShowMessage(!showMessage)}
          />
          <p className="text-sm dark:text-white">Show Message</p>
        </label>
      </div>
      <div className=" px-4 py-3 flex flex-row-reverse  ">
        <div className="flex space-x-2">
          <Button
            size="vibhut-sm"
            variant="vibhut-secondary"
            onClick={() => globals.hideModal()}
          >
            Cancel
          </Button>
          <Button
            size="vibhut-sm"
            variant="vibhut-primary"
            onClick={() => {
              boostCall(message, showMessage);
              if (isCustom) {
                if (message.length === 0) {
                  showToast(
                    dispatch,
                    "warning",
                    "Please input custom message",
                    2000,
                  );
                  return;
                }
                saveToLocalStorage("customBoostMessage", message);
              }
              globals.hideModal();
            }}
          >
            {isCustom && localStorage.getItem("customBoostMessage")?.length
              ? "Update"
              : "Set Message"}
          </Button>
        </div>
      </div>
      <ConfirmationModal
        description=""
        title="Oops! You are on free plan."
        text="To add custom message, you need to complete atleast 25 calls or upgrade to premium."
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        primaryButtonText="Upgrade"
        primaryAction={() => {
          setOpenConfirmationModal(false);
          openPlanModal(dispatch);
        }}
        secondaryButtonText="Cancel"
        secondaryAction={() => {
          setOpenConfirmationModal(false);
        }}
        showLearnMore
      />
    </div>
  );
};

export default BoostCall;
