import React from "react";

function NoNotification() {
  return (
    <svg
      width="250"
      height="189"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 250 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.482 158.662C117.482 158.662 120.664 153.439 124.749 156.527C128.834 159.615 136.4 164.825 136.4 164.825C136.4 164.825 137.949 168.157 133.939 166.906C129.928 165.654 116.836 163.64 116.836 163.64L117.482 158.662Z"
        fill="#FEB8B8"
      />
      <path
        d="M28.5627 125.495C29.5766 117.339 34.6297 109.302 42.4056 106.647C39.339 115.043 39.3397 124.253 42.4075 132.648C43.6024 135.881 45.2679 139.353 44.144 142.612C43.4447 144.639 41.734 146.191 39.8217 147.161C37.9093 148.131 35.787 148.593 33.6912 149.046L33.2788 149.388C29.9518 141.872 27.5487 133.652 28.5627 125.495Z"
        fill="#F0F0F0"
      />
      <path
        d="M42.4812 106.802C37.9529 111.878 35.1384 118.252 34.4375 125.019C34.2607 126.473 34.3142 127.946 34.596 129.384C34.9157 130.775 35.602 132.055 36.5836 133.092C37.4795 134.076 38.5098 134.98 39.1508 136.165C39.4699 136.777 39.6562 137.449 39.6974 138.138C39.7386 138.826 39.6338 139.516 39.3899 140.161C38.8239 141.785 37.7085 143.109 36.5728 144.365C35.3117 145.76 33.9798 147.189 33.4438 149.039C33.3788 149.263 33.035 149.149 33.0999 148.925C34.0325 145.707 37.1548 143.88 38.6438 140.982C39.3386 139.629 39.6302 138.059 38.9788 136.632C38.4093 135.384 37.3475 134.451 36.4319 133.462C35.4594 132.465 34.7458 131.245 34.3534 129.909C33.9994 128.49 33.8892 127.022 34.0277 125.566C34.2872 122.285 35.0609 119.064 36.3204 116.023C37.7386 112.544 39.7412 109.333 42.2416 106.53C42.3964 106.357 42.635 106.63 42.4812 106.802Z"
        fill="white"
      />
      <path
        d="M34.5354 122.827C33.4164 122.555 32.4304 121.894 31.7535 120.963C31.0766 120.031 30.7529 118.888 30.8401 117.74C30.8586 117.507 31.2204 117.525 31.2019 117.758C31.1187 118.827 31.4203 119.891 32.0524 120.758C32.6845 121.624 33.6054 122.236 34.6489 122.483C34.8756 122.537 34.7607 122.881 34.5354 122.827Z"
        fill="white"
      />
      <path
        d="M36.0639 132.653C38.0849 131.446 39.5768 129.521 40.2422 127.262C40.3079 127.038 40.6517 127.152 40.586 127.376C39.8893 129.725 38.3326 131.725 36.2268 132.976C36.026 133.096 35.8642 132.771 36.0639 132.653Z"
        fill="white"
      />
      <path
        d="M38.0742 112.888C38.4853 113.083 38.9384 113.173 39.3929 113.15C39.8475 113.127 40.2891 112.991 40.6783 112.755C40.8775 112.634 41.039 112.958 40.8411 113.079C40.41 113.338 39.922 113.487 39.4199 113.514C38.9178 113.54 38.4168 113.443 37.9607 113.232C37.916 113.215 37.8793 113.182 37.8579 113.14C37.8365 113.097 37.8321 113.048 37.8455 113.003C37.8608 112.957 37.8934 112.92 37.9362 112.898C37.979 112.877 38.0286 112.873 38.0742 112.888Z"
        fill="white"
      />
      <path
        d="M66.1069 121.217C65.9847 121.296 65.8625 121.376 65.7401 121.458C64.1025 122.536 62.5551 123.744 61.1131 125.072C61 125.173 60.8869 125.277 60.7769 125.381C57.3369 128.618 54.5275 132.465 52.4915 136.727C51.6828 138.424 51.0044 140.18 50.4621 141.98C49.7133 144.466 49.099 147.22 47.6167 149.254C47.4645 149.467 47.2991 149.672 47.1216 149.865H33.7261C33.6956 149.85 33.665 149.838 33.6345 149.822L33.0996 149.847C33.1211 149.752 33.1454 149.654 33.1669 149.559C33.1791 149.504 33.1944 149.449 33.2066 149.394C33.2157 149.358 33.225 149.321 33.2311 149.287C33.2341 149.275 33.2372 149.263 33.2402 149.254C33.2463 149.22 33.2556 149.189 33.2617 149.159C33.3961 148.611 33.5347 148.064 33.6773 147.517C33.6773 147.514 33.6773 147.514 33.6803 147.511C34.7776 143.344 36.2323 139.231 38.2646 135.471C38.3258 135.358 38.3868 135.241 38.4541 135.128C39.3796 133.438 40.4417 131.827 41.6296 130.31C42.2828 129.481 42.9774 128.686 43.7109 127.928C45.6099 125.972 47.8046 124.328 50.2145 123.054C55.019 120.517 60.5813 119.544 65.7158 121.095C65.8471 121.134 65.9756 121.174 66.1069 121.217Z"
        fill="#F0F0F0"
      />
      <path
        d="M66.0771 121.387C59.4066 122.712 53.3232 126.106 48.6909 131.087C47.6746 132.142 46.8309 133.35 46.1907 134.668C45.6086 135.971 45.386 137.407 45.546 138.825C45.6687 140.151 45.9477 141.493 45.7461 142.826C45.6328 143.506 45.3769 144.155 44.9953 144.73C44.6138 145.305 44.115 145.792 43.5319 146.161C42.1027 147.116 40.4156 147.501 38.7526 147.82C36.9062 148.175 34.9827 148.513 33.4417 149.667C33.255 149.807 33.049 149.509 33.2355 149.369C35.9166 147.362 39.5095 147.783 42.4426 146.366C43.8112 145.705 44.9889 144.627 45.3279 143.095C45.6244 141.755 45.3379 140.371 45.2021 139.03C45.0257 137.648 45.1901 136.244 45.681 134.941C46.2521 133.595 47.0479 132.356 48.0344 131.277C50.2166 128.813 52.7725 126.708 55.6084 125.038C58.8345 123.115 62.3657 121.758 66.0493 121.025C66.2773 120.98 66.3036 121.342 66.0771 121.387H66.0771Z"
        fill="white"
      />
      <path
        d="M50.0885 129.396C49.3583 128.505 48.9687 127.384 48.989 126.232C49.0094 125.08 49.4386 123.973 50.1998 123.109C50.3544 122.934 50.6327 123.166 50.4779 123.341C49.7675 124.145 49.3679 125.176 49.3511 126.249C49.3343 127.321 49.7015 128.364 50.3863 129.19C50.5349 129.37 50.2363 129.575 50.0885 129.396H50.0885Z"
        fill="white"
      />
      <path
        d="M45.3949 138.162C47.7351 138.415 50.0847 137.777 51.9754 136.374C52.1626 136.235 52.3685 136.533 52.1816 136.672C50.2114 138.128 47.7648 138.787 45.3302 138.518C45.0981 138.492 45.1641 138.136 45.3949 138.162Z"
        fill="white"
      />
      <path
        d="M58.896 123.591C59.1066 123.995 59.4141 124.34 59.7909 124.595C60.1677 124.851 60.602 125.008 61.0548 125.054C61.287 125.077 61.2208 125.433 60.9901 125.411C60.4901 125.358 60.0107 125.183 59.5937 124.902C59.1768 124.62 58.8351 124.241 58.5983 123.798C58.5725 123.758 58.5629 123.709 58.5714 123.663C58.5799 123.616 58.6059 123.574 58.6441 123.546C58.6836 123.518 58.7323 123.508 58.7795 123.517C58.8266 123.525 58.8685 123.552 58.896 123.591Z"
        fill="white"
      />
      <path
        d="M136.597 179.555C136.99 176.394 138.948 173.28 141.962 172.251C140.773 175.505 140.773 179.074 141.962 182.328C142.425 183.581 143.071 184.926 142.635 186.189C142.323 186.97 141.724 187.601 140.96 187.952C140.203 188.296 139.404 188.542 138.584 188.683L138.424 188.815C137.135 185.902 136.204 182.717 136.597 179.555Z"
        fill="#F0F0F0"
      />
      <path
        d="M141.992 172.31C140.237 174.278 139.147 176.748 138.875 179.371C138.807 179.934 138.827 180.505 138.936 181.062C139.06 181.601 139.326 182.098 139.707 182.499C140.085 182.854 140.419 183.254 140.702 183.69C140.825 183.927 140.898 184.188 140.913 184.455C140.929 184.722 140.889 184.989 140.794 185.239C140.543 185.85 140.172 186.404 139.703 186.868C139.214 187.409 138.698 187.963 138.49 188.679C138.465 188.766 138.332 188.722 138.357 188.635C138.718 187.388 139.928 186.68 140.505 185.557C140.654 185.304 140.743 185.021 140.765 184.729C140.788 184.437 140.743 184.143 140.635 183.871C140.373 183.412 140.04 182.997 139.648 182.643C139.271 182.256 138.995 181.783 138.842 181.265C138.705 180.716 138.663 180.147 138.716 179.583C138.817 178.311 139.117 177.063 139.605 175.884C140.154 174.536 140.93 173.292 141.9 172.205C141.96 172.138 142.052 172.244 141.992 172.31H141.992Z"
        fill="white"
      />
      <path
        d="M138.913 178.521C138.479 178.416 138.097 178.16 137.834 177.799C137.572 177.437 137.446 176.995 137.48 176.55C137.487 176.459 137.627 176.466 137.62 176.557C137.588 176.971 137.705 177.383 137.95 177.719C138.195 178.055 138.552 178.292 138.957 178.388C139.045 178.409 139 178.542 138.913 178.521H138.913Z"
        fill="white"
      />
      <path
        d="M139.504 182.33C140.288 181.862 140.866 181.116 141.124 180.24C141.15 180.154 141.283 180.198 141.257 180.284C140.987 181.195 140.384 181.97 139.567 182.455C139.489 182.501 139.427 182.376 139.504 182.33Z"
        fill="white"
      />
      <path
        d="M140.283 174.669C140.442 174.745 140.618 174.78 140.794 174.771C140.971 174.762 141.142 174.709 141.293 174.618C141.37 174.571 141.433 174.696 141.356 174.743C141.189 174.844 140.999 174.901 140.805 174.912C140.61 174.922 140.416 174.884 140.239 174.802C140.222 174.796 140.207 174.783 140.199 174.767C140.191 174.75 140.189 174.731 140.194 174.714C140.2 174.696 140.213 174.682 140.23 174.673C140.246 174.665 140.265 174.663 140.283 174.669L140.283 174.669Z"
        fill="white"
      />
      <path
        d="M151.148 177.898C151.1 177.928 151.053 177.959 151.005 177.991C150.371 178.409 149.771 178.877 149.212 179.392C149.168 179.431 149.125 179.471 149.082 179.511C147.749 180.766 146.66 182.257 145.871 183.909C145.557 184.566 145.295 185.247 145.084 185.944C144.794 186.908 144.556 187.975 143.982 188.763C143.923 188.846 143.859 188.925 143.79 189H138.598C138.586 188.994 138.575 188.99 138.563 188.984L138.355 188.993C138.364 188.956 138.373 188.919 138.382 188.882C138.386 188.86 138.392 188.839 138.397 188.818C138.4 188.804 138.404 188.789 138.406 188.776C138.408 188.772 138.409 188.767 138.41 188.763C138.412 188.75 138.416 188.738 138.418 188.727C138.47 188.514 138.524 188.302 138.579 188.09L138.58 188.088C138.986 186.468 139.583 184.901 140.357 183.422C140.381 183.378 140.405 183.333 140.431 183.289C140.789 182.634 141.201 182.009 141.661 181.422C141.914 181.1 142.184 180.792 142.468 180.499C143.204 179.741 144.054 179.103 144.988 178.61C146.85 177.626 149.006 177.249 150.996 177.85C151.047 177.866 151.097 177.881 151.148 177.898Z"
        fill="#F0F0F0"
      />
      <path
        d="M151.135 177.963C148.55 178.476 146.192 179.792 144.397 181.722C144.003 182.131 143.676 182.599 143.428 183.11C143.203 183.615 143.116 184.171 143.178 184.721C143.267 185.233 143.293 185.754 143.256 186.272C143.212 186.535 143.113 186.787 142.965 187.01C142.817 187.232 142.624 187.421 142.398 187.564C141.83 187.9 141.2 188.119 140.546 188.207C139.83 188.345 139.084 188.476 138.487 188.923C138.415 188.977 138.335 188.862 138.407 188.808C139.446 188.03 140.839 188.193 141.976 187.644C142.246 187.532 142.488 187.359 142.682 187.139C142.875 186.92 143.016 186.658 143.094 186.376C143.161 185.851 143.145 185.32 143.045 184.8C142.977 184.265 143.04 183.721 143.231 183.216C143.452 182.694 143.76 182.214 144.143 181.796C144.988 180.841 145.979 180.025 147.078 179.378C148.328 178.633 149.697 178.107 151.124 177.823C151.213 177.805 151.223 177.945 151.135 177.963H151.135Z"
        fill="white"
      />
      <path
        d="M144.94 181.067C144.657 180.722 144.506 180.287 144.514 179.841C144.522 179.394 144.688 178.965 144.983 178.63C145.043 178.563 145.151 178.653 145.091 178.72C144.816 179.032 144.661 179.432 144.654 179.847C144.648 180.263 144.79 180.667 145.055 180.987C145.113 181.057 144.997 181.136 144.94 181.067Z"
        fill="white"
      />
      <path
        d="M143.12 184.465C144.027 184.563 144.938 184.315 145.67 183.772C145.743 183.718 145.823 183.833 145.75 183.887C144.987 184.452 144.039 184.707 143.095 184.603C143.005 184.593 143.031 184.455 143.12 184.465H143.12Z"
        fill="white"
      />
      <path
        d="M148.354 178.817C148.435 178.974 148.554 179.107 148.701 179.206C148.847 179.305 149.015 179.366 149.191 179.384C149.281 179.393 149.255 179.531 149.165 179.522C148.972 179.502 148.786 179.434 148.624 179.325C148.462 179.216 148.33 179.069 148.238 178.897C148.228 178.882 148.224 178.863 148.228 178.845C148.231 178.827 148.241 178.81 148.256 178.799C148.271 178.789 148.29 178.785 148.308 178.788C148.327 178.792 148.343 178.802 148.354 178.817Z"
        fill="white"
      />
      <path
        d="M225.791 112.408C224.233 99.8712 216.466 87.5184 204.514 83.4385C209.228 96.3427 209.227 110.499 204.511 123.403C202.675 128.372 200.115 133.709 201.842 138.717C202.917 141.834 205.546 144.218 208.486 145.709C211.425 147.2 214.687 147.911 217.908 148.607L218.542 149.132C223.656 137.58 227.35 124.946 225.791 112.408Z"
        fill="#F0F0F0"
      />
      <path
        d="M204.395 83.6758C211.355 91.4776 215.681 101.275 216.758 111.677C216.975 113.906 217.002 116.187 216.515 118.385C216.023 120.524 214.968 122.492 213.46 124.085C212.083 125.598 210.499 126.987 209.514 128.809C208.475 130.729 208.435 132.911 209.146 134.951C210.016 137.447 211.731 139.481 213.476 141.413C215.414 143.557 217.462 145.753 218.286 148.596C218.385 148.94 218.914 148.765 218.814 148.421C217.381 143.476 212.582 140.666 210.293 136.212C209.225 134.133 208.777 131.72 209.778 129.526C210.653 127.607 212.285 126.174 213.693 124.654C215.171 123.057 216.304 121.306 216.888 119.192C217.485 117.03 217.547 114.743 217.388 112.518C216.989 107.474 215.8 102.524 213.864 97.8491C211.684 92.5019 208.606 87.5671 204.763 83.2582C204.525 82.9916 204.158 83.411 204.395 83.6758L204.395 83.6758Z"
        fill="white"
      />
      <path
        d="M216.61 108.306C218.33 107.889 219.845 106.873 220.886 105.44C221.926 104.008 222.424 102.252 222.29 100.487C222.261 100.13 221.705 100.157 221.734 100.515C221.861 102.159 221.398 103.794 220.426 105.126C219.455 106.458 218.039 107.398 216.435 107.778C216.087 107.86 216.263 108.389 216.61 108.306H216.61Z"
        fill="white"
      />
      <path
        d="M214.258 123.409C211.152 121.554 208.859 118.595 207.836 115.123C207.735 114.779 207.207 114.954 207.308 115.298C208.379 118.909 210.771 121.983 214.008 123.906C214.317 124.09 214.565 123.591 214.258 123.409Z"
        fill="white"
      />
      <path
        d="M211.169 93.0287C210.537 93.329 209.84 93.4677 209.142 93.4322C208.443 93.3968 207.764 93.1882 207.166 92.8254C206.86 92.6385 206.612 93.137 206.916 93.3228C207.579 93.7205 208.329 93.9499 209.1 93.9908C209.872 94.0317 210.642 93.8829 211.343 93.5574C211.412 93.5323 211.468 93.4819 211.501 93.4166C211.534 93.3513 211.541 93.2759 211.52 93.2058C211.497 93.1359 211.447 93.0781 211.381 93.0449C211.315 93.0118 211.239 93.0059 211.169 93.0287Z"
        fill="white"
      />
      <path
        d="M168.082 105.831C168.27 105.953 168.458 106.075 168.646 106.202C171.163 107.858 173.541 109.716 175.758 111.757C175.932 111.912 176.106 112.072 176.275 112.232C181.562 117.206 185.88 123.12 189.01 129.671C190.253 132.279 191.295 134.978 192.129 137.745C193.28 141.565 194.224 145.8 196.502 148.925C196.736 149.253 196.991 149.567 197.263 149.865H217.853C217.9 149.841 217.947 149.822 217.994 149.799L218.816 149.836C218.783 149.691 218.745 149.54 218.712 149.395C218.694 149.31 218.67 149.226 218.651 149.141C218.637 149.085 218.623 149.028 218.614 148.976C218.609 148.958 218.604 148.939 218.6 148.925C218.59 148.873 218.576 148.826 218.567 148.779C218.36 147.938 218.147 147.097 217.928 146.255C217.928 146.251 217.928 146.251 217.923 146.246C216.237 139.841 214.001 133.52 210.877 127.74C210.783 127.566 210.689 127.387 210.586 127.213C209.163 124.616 207.531 122.139 205.705 119.807C204.701 118.534 203.633 117.312 202.506 116.146C199.587 113.14 196.214 110.613 192.509 108.655C185.125 104.755 176.575 103.261 168.683 105.643C168.481 105.704 168.284 105.765 168.082 105.831Z"
        fill="#F0F0F0"
      />
      <path
        d="M168.128 106.093C178.381 108.13 187.732 113.347 194.852 121.003C196.367 122.652 197.761 124.458 198.695 126.506C199.589 128.51 199.931 130.716 199.685 132.897C199.497 134.935 199.068 136.997 199.378 139.045C199.705 141.204 200.986 142.971 202.781 144.171C204.978 145.64 207.571 146.232 210.127 146.722C212.965 147.267 215.922 147.787 218.29 149.561C218.577 149.776 218.894 149.318 218.607 149.103C214.486 146.018 208.964 146.665 204.456 144.487C202.352 143.471 200.542 141.814 200.021 139.459C199.565 137.4 200.005 135.272 200.214 133.211C200.433 131.046 200.284 128.965 199.478 126.926C198.654 124.84 197.327 122.976 195.861 121.295C192.507 117.508 188.578 114.272 184.219 111.706C179.26 108.749 173.833 106.663 168.171 105.537C167.821 105.468 167.78 106.023 168.128 106.093V106.093Z"
        fill="white"
      />
      <path
        d="M192.705 118.403C193.828 117.033 194.427 115.31 194.395 113.539C194.364 111.769 193.704 110.067 192.534 108.739C192.297 108.471 191.869 108.827 192.107 109.096C193.199 110.331 193.813 111.916 193.839 113.565C193.865 115.213 193.3 116.817 192.248 118.086C192.019 118.362 192.478 118.677 192.705 118.403L192.705 118.403Z"
        fill="white"
      />
      <path
        d="M199.918 131.877C196.321 132.266 192.709 131.285 189.803 129.129C189.515 128.915 189.199 129.373 189.486 129.586C192.515 131.825 196.275 132.838 200.017 132.424C200.374 132.385 200.273 131.837 199.918 131.877Z"
        fill="white"
      />
      <path
        d="M179.166 109.482C178.842 110.102 178.369 110.632 177.79 111.025C177.211 111.417 176.543 111.66 175.847 111.73C175.49 111.765 175.592 112.313 175.947 112.278C176.715 112.196 177.452 111.928 178.093 111.496C178.734 111.063 179.259 110.481 179.623 109.799C179.663 109.737 179.678 109.663 179.665 109.591C179.651 109.519 179.612 109.455 179.553 109.411C179.492 109.37 179.417 109.354 179.345 109.367C179.272 109.38 179.208 109.421 179.166 109.482V109.482Z"
        fill="white"
      />
      <path
        d="M249.794 150.17H0.305623C0.224567 150.17 0.14683 150.138 0.0895148 150.081C0.0321993 150.023 0 149.945 0 149.864C0 149.783 0.0321993 149.705 0.0895148 149.648C0.14683 149.591 0.224567 149.559 0.305623 149.559H249.694C249.775 149.559 249.853 149.591 249.91 149.648C249.968 149.705 250 149.783 250 149.864C250 150.033 249.963 150.17 249.794 150.17Z"
        fill="#CACACA"
      />
      <path
        d="M87.5076 177.076H32.3955C32.3144 177.076 32.2367 177.044 32.1794 176.987C32.122 176.929 32.0898 176.852 32.0898 176.771C32.0898 176.689 32.122 176.612 32.1794 176.554C32.2367 176.497 32.3144 176.465 32.3955 176.465H87.4077C87.4887 176.465 87.5665 176.497 87.6238 176.554C87.6811 176.612 87.7133 176.689 87.7133 176.771C87.7133 176.939 87.6763 177.076 87.5076 177.076Z"
        fill="#CACACA"
      />
      <path
        d="M174 189H118.888C118.807 189 118.729 188.968 118.672 188.911C118.614 188.853 118.582 188.775 118.582 188.694C118.582 188.613 118.614 188.536 118.672 188.478C118.729 188.421 118.807 188.389 118.888 188.389H173.9C173.981 188.389 174.059 188.421 174.116 188.478C174.173 188.536 174.206 188.613 174.206 188.694C174.206 188.863 174.169 189 174 189Z"
        fill="#CACACA"
      />
      <path
        d="M225.957 176.771H170.845C170.764 176.771 170.686 176.738 170.629 176.681C170.571 176.624 170.539 176.546 170.539 176.465C170.539 176.384 170.571 176.306 170.629 176.249C170.686 176.191 170.764 176.159 170.845 176.159H225.857C225.938 176.159 226.016 176.191 226.073 176.249C226.13 176.306 226.163 176.384 226.163 176.465C226.163 176.634 226.126 176.771 225.957 176.771Z"
        fill="#CACACA"
      />
      <path
        d="M101.852 165.152C98.4281 165.152 95.2938 164.824 92.8036 164.048C89.8436 163.126 87.9851 161.635 87.279 159.613C86.9472 158.755 86.8481 157.824 86.9918 156.915C87.1355 156.005 87.5168 155.15 88.0972 154.436C92.476 148.728 107.671 147.118 109.71 146.928L113.846 139.093C114.111 137.223 112.859 135.808 115.022 135.773L140.614 135.425C142.738 135.38 142.135 136.696 142.499 138.519L146.398 145.482L129.617 152.567L132.154 158.752L131.989 158.848C127.078 161.698 112.954 165.152 101.852 165.152Z"
        fill="#2F2E41"
      />
      <path
        d="M144.075 145.779C144.075 145.779 167.511 143.096 165.982 153.828C164.454 164.56 146.623 172.161 119.621 153.828L122.678 148.462C122.678 148.462 132.74 147.961 138.853 145.278L144.075 145.779Z"
        fill="#2F2E41"
      />
      <path
        d="M110.897 97.1603C110.897 97.1603 106.443 96.3501 105.228 103.237C104.014 110.123 102.068 126.435 102.068 126.435L96.0257 147.389C95.3131 147.767 94.7229 148.341 94.3239 149.042C93.9249 149.744 93.7337 150.544 93.7725 151.351C93.8114 152.157 94.0787 152.935 94.5433 153.595C95.0079 154.255 95.6506 154.769 96.3962 155.077C97.1419 155.385 97.9599 155.475 98.7545 155.335C99.5492 155.195 100.288 154.833 100.884 154.289C101.48 153.745 101.909 153.043 102.121 152.264C102.334 151.485 102.32 150.662 102.082 149.891L110.166 127.652L111.707 108.504V98.4711C111.707 98.1989 111.631 97.9321 111.488 97.7006C111.345 97.4691 111.141 97.282 110.897 97.1603Z"
        fill="#FEB8B8"
      />
      <path
        d="M142.249 97.1603C142.249 97.1603 146.703 96.3501 147.918 103.237C149.133 110.123 151.079 126.435 151.079 126.435L157.121 147.389C157.833 147.767 158.424 148.341 158.823 149.042C159.222 149.744 159.413 150.544 159.374 151.351C159.335 152.157 159.068 152.935 158.603 153.595C158.139 154.255 157.496 154.769 156.75 155.077C156.005 155.385 155.187 155.475 154.392 155.335C153.597 155.195 152.859 154.833 152.263 154.289C151.666 153.745 151.237 153.043 151.025 152.264C150.813 151.485 150.827 150.662 151.065 149.891L142.98 127.652L141.439 108.504V98.4711C141.439 98.1989 141.515 97.9321 141.658 97.7006C141.801 97.4691 142.006 97.282 142.249 97.1603Z"
        fill="#FEB8B8"
      />
      <path
        d="M141.91 91.4677L133.485 88.3153L117.154 88.1045L110.125 91.4677L112.099 102.819C112.099 102.819 108.113 109.571 112.502 116.324L113.487 140.387C113.487 140.387 129.231 149.52 142.521 138.552L139.517 116.18C139.517 116.18 144.541 105.8 140.647 101.943L141.91 91.4677Z"
        fill="#6C63FF"
      />
      <path
        d="M138.477 94.9952L141.908 91.4678C141.908 91.4678 149.397 95.4086 149.243 108.589L139.796 105.677L138.477 94.9952Z"
        fill="#6C63FF"
      />
      <path
        d="M113.937 94.995L110.291 91.3477C110.291 91.3477 103.858 96.3257 104.012 109.506L112.618 105.677L113.937 94.995Z"
        fill="#6C63FF"
      />
      <path
        d="M137.703 156.053C137.703 156.053 135.079 150.529 130.696 153.177C126.313 155.826 118.249 160.224 118.249 160.224C118.249 160.224 116.363 163.378 120.482 162.548C124.6 161.719 137.831 161.071 137.831 161.071L137.703 156.053Z"
        fill="#FEB8B8"
      />
      <path
        d="M130.413 57.3114C130.45 57.2758 130.489 57.2438 130.525 57.2073C132.87 54.8612 133.346 51.5329 131.587 49.7734C129.925 48.1106 126.863 48.4461 124.552 50.4679C122.571 48.5807 119.914 48.0546 118.194 49.3574C116.211 50.8596 116.227 54.2216 118.229 56.8667C118.366 57.0478 118.51 57.219 118.658 57.3838C115.993 58.3595 113.693 60.1304 112.067 62.4571C110.442 64.7837 109.57 67.5536 109.57 70.3921V83.5758C109.57 83.9624 109.646 84.3452 109.794 84.7023C109.942 85.0595 110.159 85.384 110.432 85.6574C110.705 85.9307 111.03 86.1476 111.387 86.2955C111.744 86.4435 112.127 86.5196 112.513 86.5196H136.87C137.624 86.5196 138.347 86.2199 138.881 85.6863C139.414 85.1528 139.714 84.4291 139.714 83.6745V70.3921C139.713 67.5154 138.818 64.7103 137.151 62.3662C135.484 60.0221 133.129 58.2554 130.413 57.3114Z"
        fill="#2F2E41"
      />
      <path
        d="M133.198 81.8176C137.712 77.3021 137.712 69.9811 133.198 65.4656C128.684 60.9502 121.366 60.9502 116.852 65.4656C112.339 69.9811 112.339 77.3021 116.852 81.8176C121.366 86.333 128.684 86.333 133.198 81.8176Z"
        fill="#FFB6B6"
      />
      <path
        d="M112.881 66.3685C115.522 68.1896 118.657 69.1588 121.865 69.1459C120.72 69.9396 119.418 70.4758 118.046 70.7174C122.311 71.6326 126.719 71.6573 130.994 70.7901C131.944 70.6377 132.861 70.3227 133.705 69.8588C134.125 69.6241 134.491 69.3034 134.779 68.9178C135.067 68.5321 135.271 68.0901 135.378 67.6205C135.662 65.997 134.397 64.5221 133.083 63.528C131.071 62.0293 128.751 60.9968 126.291 60.505C123.831 60.0133 121.293 60.0747 118.86 60.6848C117.271 61.0956 115.679 61.7898 114.647 63.0662C113.615 64.3426 113.31 66.3105 114.293 67.6249L112.881 66.3685Z"
        fill="#2F2E41"
      />
      <path
        d="M51.0607 43.2952L51.425 42.804L61.1283 50.0042C60.6009 48.3964 58.7345 43.2996 55.6747 41.266C52.0194 38.8365 49.4477 39.5922 48.0466 41.7019C46.6455 43.8116 46.9455 46.4762 50.6008 48.9058C53.6993 50.9653 59.219 50.6789 60.8294 50.5439L51.0607 43.2952Z"
        fill="#F2F2F2"
      />
      <path
        d="M197.057 3.87441L197.507 4.28791L189.339 13.1934C190.993 12.836 196.254 11.509 198.594 8.67624C201.39 5.29201 200.906 2.65462 198.954 1.04113C197.002 -0.572353 194.322 -0.550985 191.526 2.83325C189.157 5.70192 188.867 11.2237 188.834 12.84L197.057 3.87441Z"
        fill="#F2F2F2"
      />
      <path
        d="M65.332 98.9603L65.7824 99.3739L57.6148 108.279C59.2681 107.922 64.5294 106.595 66.8695 103.762C69.665 100.378 69.1812 97.7406 67.2295 96.1271C65.2778 94.5136 62.5975 94.535 59.8018 97.9192C57.4321 100.788 57.1426 106.31 57.1094 107.926L65.332 98.9603Z"
        fill="#F2F2F2"
      />
      <path
        d="M134.396 14.0795L134.925 14.3859L128.874 24.8468C130.411 24.1404 135.262 21.7072 136.935 18.4354C138.933 14.5268 137.891 12.0563 135.636 10.903C133.382 9.74956 130.77 10.3499 128.772 14.2586C127.078 17.5719 127.988 23.0257 128.305 24.611L134.396 14.0795Z"
        fill="#F2F2F2"
      />
      <path
        d="M190.937 79.5082L191.466 79.8146L185.416 90.2755C186.952 89.5691 191.803 87.1359 193.476 83.8642C195.474 79.9555 194.432 77.4851 192.178 76.3317C189.923 75.1783 187.311 75.7787 185.313 79.6874C183.619 83.0006 184.529 88.4544 184.846 90.0397L190.937 79.5082Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default NoNotification;
