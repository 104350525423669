import React from 'react';

function TikTok({ ratio = '16', isGreyScaleActive = false }) {
  return (
    <svg
      width={ratio}
      height={ratio}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isGreyScaleActive && `custom-grayscale`}`}
    >
      <path
        d="M6.23483 6.30123V5.67773C5.99012 5.64346 5.74296 5.62469 5.49523 5.62155C3.09683 5.61702 0.973478 6.99168 0.253239 9.01503C-0.466999 11.0384 0.383159 13.241 2.35179 14.4524C1.62989 13.7691 1.14704 12.9156 0.96151 11.9949C0.775986 11.0742 0.895723 10.1257 1.30627 9.26382C1.71681 8.40198 2.40062 7.66365 3.27515 7.13797C4.14967 6.61228 5.17753 6.3217 6.23467 6.3013L6.23483 6.30123Z"
        fill="#25F4EE"
      />
      <path
        d="M6.36785 13.3639C7.70881 13.3623 8.81105 12.4279 8.86977 11.2431V0.669498H11.0538C11.0092 0.448553 10.9879 0.224424 10.9902 0L8.00297 0V10.5634C7.95329 11.7543 6.84841 12.6976 5.50106 12.6995C5.09841 12.6965 4.70234 12.6089 4.34546 12.444C4.57676 12.7274 4.88053 12.9585 5.23198 13.1184C5.58344 13.2782 5.97265 13.3624 6.36785 13.3639ZM15.1331 4.25699V3.66929C14.3293 3.66957 13.5434 3.45979 12.8739 3.0663C13.4608 3.66971 14.2538 4.08768 15.1331 4.25699Z"
        fill="#25F4EE"
      />
      <path
        d="M12.8739 3.06629C12.2144 2.40211 11.8509 1.54828 11.8512 0.664322H11.0538C11.1568 1.15305 11.3713 1.61809 11.6842 2.03106C11.9971 2.44404 12.4019 2.79628 12.8739 3.06629ZM5.49529 8.26363C4.93601 8.26615 4.39373 8.43396 3.95467 8.74038C3.51561 9.04679 3.20497 9.47424 3.07213 9.95475C2.93929 10.4353 2.99188 10.9412 3.22154 11.3923C3.4512 11.8433 3.84474 12.2135 4.33961 12.444C4.06904 12.1136 3.90702 11.7234 3.87143 11.3167C3.83585 10.9099 3.92808 10.5024 4.13795 10.1391C4.34783 9.77582 4.66716 9.47091 5.06068 9.25807C5.4542 9.04523 5.90658 8.93276 6.36784 8.93306C6.61861 8.93597 6.86762 8.97049 7.10736 9.03559V6.3472C6.86253 6.31473 6.61541 6.29766 6.36784 6.29611H6.23489V8.34026C5.99387 8.28309 5.74472 8.25727 5.49529 8.26363Z"
        fill="#FE2C55"
      />
      <path
        d="M15.1333 4.25699V6.30121C13.6453 6.29865 12.1959 5.88206 10.9904 5.11045V10.4816C10.984 13.1617 8.52572 15.3314 5.49548 15.3314C4.37007 15.3332 3.27176 15.0261 2.35205 14.4524C3.10201 15.1659 4.07752 15.663 5.15149 15.8791C6.22547 16.0953 7.34814 16.0203 8.37326 15.6641C9.39839 15.3078 10.2785 14.6868 10.8988 13.8818C11.5192 13.0769 11.8511 12.1254 11.8514 11.1512V5.7953C13.0609 6.56183 14.5121 6.97298 16 6.97071V4.33857C15.7087 4.3378 15.4183 4.31046 15.1333 4.25699Z"
        fill="#FE2C55"
      />
      <path
        d="M10.9902 10.4816V5.11044C12.1993 5.87766 13.6508 6.28889 15.1389 6.28585V4.2417C14.2597 4.07749 13.4648 3.66496 12.8739 3.06629C12.4019 2.79628 11.9971 2.44404 11.6842 2.03106C11.3713 1.61809 11.1567 1.15305 11.0537 0.664322H8.86975V11.2432C8.84848 11.7037 8.66556 12.1469 8.34641 12.5112C8.02726 12.8755 7.58772 13.1428 7.08889 13.276C6.59006 13.4092 6.0567 13.4016 5.56295 13.2544C5.06919 13.1071 4.63956 12.8275 4.33376 12.4543C3.83881 12.2239 3.44518 11.8537 3.21546 11.4026C2.98574 10.9516 2.93312 10.4455 3.06597 9.96495C3.19882 9.4844 3.5095 9.05692 3.94863 8.75049C4.38775 8.44406 4.9301 8.27628 5.48943 8.27382C5.74029 8.27581 5.98946 8.31025 6.22903 8.37607V6.33184C5.16622 6.34778 4.1316 6.63688 3.25134 7.1639C2.37109 7.69091 1.68321 8.43306 1.2716 9.29986C0.85998 10.1667 0.742406 11.1207 0.93321 12.0455C1.12401 12.9704 1.61495 13.8262 2.34616 14.5086C3.2749 15.0634 4.37478 15.3508 5.49527 15.3314C8.52551 15.3314 10.9838 13.1617 10.9902 10.4816Z"
        fill="black"
      />
    </svg>
  );
}

export default TikTok;
