import React from "react";

function Play({ color = "#1F2023", width = "10", height = "14" }) {
  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72896 5.5594C10.4568 5.97529 10.4568 7.02471 9.72896 7.4406L1.62082 12.0738C0.898606 12.4865 0 11.965 0 11.1332L0 1.86678C0 1.03497 0.898605 0.513489 1.62082 0.926181L9.72896 5.5594Z"
        fill={color}
      />
    </svg>
  );
}

export default Play;
