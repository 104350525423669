import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useContext,
  memo,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import Avatar from "../Avatar";
import { getCoinPrice } from "../../services/userService";
import Loader from "../Loader";
import DeSo from "../Icons/DeSo";
import Metamask from "../Icons/Metamask";
import Warpcast from "../Icons/Warpcast";
import Instagram from "../Icons/Instagram";
import TikTok from "../Icons/Tiktok";
import LinkedIn from "../Icons/LinkedIn";
import Linkify from "react-linkify";
import WaveButton from "../WaveButton";
import { StoreContext } from "../../Context/StoreContext";
import axios from "../../utils/axios";
import { Link, useHistory } from "react-router-dom";
import Room from "../Icons/Room";
import Arrow from "../Icons/Arrow";
import Button from "../Button";
import Close from "../Icons/Close";
import { frontEndURL } from "../../utils/axios";
import PopOver from "../Popover";
import Copy2 from "../Icons/Copy2";
import ProfileStatsSkeleton from "../Skeletons/ProfileStatsSkeleton";
import * as actionTypes from "../../constants";
import Axios from "axios";
import Chat from "../Icons/Chat";
import Facebook from "../Icons/Facebook";
import { profileRoute } from "../../routes";
import MainLogo from "../Icons/MainLogo";
import Worldcoin from "../Icons/Worldcoin";
import TwitterV2 from "../Icons/TwitterV2";
import ConnectionButton from "../ConnectionButton";
import Google from "../Icons/Google";
import { isMobile } from "../../utils/utils";
import ProfilePersonas from "../../components/peronas/ProfilePersonas";

function ProfileModal({
  isOpen,
  setIsOpen,
  user,
  copyText,
  userState = null,
  isLoggedInUser = false,
  fromCallProfile = false,
  isWaveCall = false,
}) {
  const history = useHistory();
  const cancelButtonRef = useRef(null);
  const provider = user?.provider;
  const [coinPrice, setCoinPrice] = useState(user?.coin_price);

  const [handleReadMore, setHandleReadMore] = useState(false);
  const { state, dispatch } = useContext(StoreContext);
  const [callsCount, setcallsCount] = useState(0);
  const [isCallCounting, setIsCallCounting] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [isConnectCounting, setIsConnectCounting] = useState(false);
  const [userRoomStatus, setUserRoomStatus] = useState(null);
  const [isGettingRoomStatus, setIsGettingRoomStatus] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const { auth } = state;

  useEffect(() => {
    const source = Axios.CancelToken.source();

    if (isOpen && provider === "bitclout") {
      getCoinPrice({
        username: user.username,
        publicKey: user.bitclout_publickey,
        source,
      })
        .then((res) => {
          setCoinPrice(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const source = Axios.CancelToken.source();

    if (isOpen) {
      setIsCallCounting(true);
      setIsConnectCounting(true);

      axios
        .post(
          "/user/get-calls-count",
          {
            from: user?._id,
            jwt: auth?.user?.token,
          },
          {
            cancelToken: source?.token,
          },
        )
        .then((res) => {
          if (res.status === 200) {
            setIsCallCounting(false);
            setcallsCount(res.data.data);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          if (Axios.isCancel(error)) return;
        });

      axios
        .get(`/connections/count-followers/${user?._id}`, {
          cancelToken: source?.token,
          headers: {
            Authorization: `Bearer ${auth?.user?.token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setIsConnectCounting(false);
            setFollowerCount(res.data.data.connections);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          if (Axios.isCancel(error)) return;
        });
      axios
        .get(`/connections/count-followings/${user?._id}`, {
          cancelToken: source?.token,
          headers: {
            Authorization: `Bearer ${auth?.user?.token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setIsConnectCounting(false);
            setFollowingCount(res.data.data.connections);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          if (Axios.isCancel(error)) return;
        });
    }

    if (isOpen) {
      if (!userState) {
        setIsGettingRoomStatus(true);
        axios
          .get(`/user/get-user-room-status/${user?._id}`, {
            cancelToken: source?.token,
          })
          .then((res) => {
            console.log("RES: : ", res);
            if (res.status === 200) {
              setUserRoomStatus(res.data.data);
              setIsGettingRoomStatus(false);
            } else {
              console.log(res);
            }
          })
          .catch((error) => {
            if (Axios.isCancel(error)) return;
          });
      } else {
        setUserRoomStatus(userState);
      }
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const hangleReadMoreBtn = () => {
    if (user.description.length > 300) {
      setHandleReadMore(true);
    }
  };
  const hangleReadMoreDescriptionBtn = () => {
    if (user.vibehutDescription.length > 300) {
      setHandleReadMore(true);
    }
  };
  const hangleShowLessDescriptionBtn = () => {
    if (user.vibehutDescription.length > 300) {
      setHandleReadMore(false);
    }
  };
  const hangleShowLessBtn = () => {
    if (user.description.length > 300) {
      setHandleReadMore(false);
    }
  };

  const _renderChatButton = () => {
    return (
      <Button
        size="vibhut-sm"
        variant="vibhut-secondary"
        onClick={() => {
          setIsOpen(false);
          dispatch({
            type: actionTypes.GET_CHAT_ROOM_SUCCESS,
            payload: {
              chatRoom: undefined,
            },
          });
          fromCallProfile
            ? window.open(
                `${frontEndURL}/chat?userId=${user._id}`,
                "_blank",
                "noopener,noreferrer",
              )
            : history.push(`/chat?userId=${user._id}`);
        }}
        customClasses="flex items-center gap-1"
      >
        <Chat ratio="18" />
        <span>Chat</span>
      </Button>
    );
  };

  const copyMetamaskAddress = async (address) => {
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 2000);
  };

  const onRoomCardClick = () => {
    if (isWaveCall) {
      return;
    }
    setIsOpen(false);
    if (
      userRoomStatus.room === "Wave Call" ||
      userRoomStatus.room.includes("👋 with")
    ) {
      dispatch({
        type: actionTypes.SET_SINGLE_WAVE_SUCCESS,
        payload: {
          waveUser: user.username,
        },
      });
      history.replace(`/call?roomKey=${userRoomStatus?.roomId}`);
    } else {
      const roomLink = userRoomStatus?.roomSlug
        ? userRoomStatus?.roomSlug
        : userRoomStatus?.roomId;
      fromCallProfile
        ? window.open(
            `${frontEndURL}/${roomLink}`,
            "_blank",
            "noopener,noreferrer",
          )
        : history.replace(`/${roomLink}`);
    }
  };

  const _renderUserProfile = () => {
    const componentDecorator = (href, text, key) => {
      return (
        <a
          href={href}
          key={key}
          target="_blank"
          className="text-primary text-weight-bold break-all "
          rel="noreferrer"
        >
          {text.length > 55 ? text.slice(0, 55) + "..." : text}
        </a>
      );
    };

    switch (provider) {
      case "bitclout":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <DeSo />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              <a
                href={`https://diamondapp.com/u/${user.username}`}
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            <p className="dark:text-frescoWhite text-matteGray text-center px-8 w-full break-words leading-5 text-sm pt-2">
              <Linkify componentDecorator={componentDecorator}>
                {user.vibehutDescription ? (
                  user.vibehutDescription.length < 300 ? (
                    user.vibehutDescription
                  ) : user.vibehutDescription.length > 300 &&
                    !handleReadMore ? (
                    <>
                      {user.vibehutDescription.slice(0, 300)}...
                      <button
                        className=" text-primary text-center px-1 leading-5 text-sm"
                        onClick={hangleReadMoreDescriptionBtn}
                      >
                        Read More
                      </button>
                    </>
                  ) : undefined
                ) : user.description.length < 300 ? (
                  user.description
                ) : user.description.length > 300 && !handleReadMore ? (
                  <>
                    {user.description.slice(0, 300)}...
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      onClick={hangleReadMoreBtn}
                    >
                      Read More
                    </button>
                  </>
                ) : undefined}

                {handleReadMore ? (
                  <span className="dark:text-frescoWhite text-matteGray text-center px-2 leading-5 text-sm">
                    {user.vibehutDescription
                      ? user.vibehutDescription
                      : user.description}
                    <br />
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      onClick={
                        user.vibehutDescription
                          ? hangleShowLessDescriptionBtn
                          : hangleShowLessBtn
                      }
                    >
                      Show less
                    </button>
                  </span>
                ) : undefined}
              </Linkify>
            </p>

            {coinPrice === undefined ||
            coinPrice === null ||
            isCallCounting ||
            isConnectCounting ? (
              <ProfileStatsSkeleton showItem={5} />
            ) : (
              <div className="flex space-x-3 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`$${coinPrice}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Coin Price
                  </p>
                </div>

                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {provider === "twitter"
                      ? user.follower_count
                      : user.investorCount}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    {provider === "twitter" ? "Followers" : "Investors"}
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}

            <div className="pb-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>

                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );
      case "twitter":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full w-8 h-8">
                <TwitterV2
                  ratio={16}
                  isInline={false}
                  customClasses="mt-1 ml-0.5"
                />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              <a
                href={`https://twitter.com/${user.username}`}
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}

            <p className="dark:text-frescoWhite text-matteGray text-center px-2 leading-5 text-sm pt-2">
              <Linkify componentDecorator={componentDecorator}>
                {user.description.length < 300 ? user.description : undefined}
                {user.description.length > 300 && !handleReadMore ? (
                  <span>
                    {user.description.slice(0, 300)}
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      onClick={hangleReadMoreBtn}
                    >
                      Read More
                    </button>
                  </span>
                ) : undefined}
                {handleReadMore ? (
                  <span className="dark:text-frescoWhite text-matteGray text-center px-2 leading-5 text-sm">
                    {user.description}
                    <br />
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      onClick={hangleShowLessBtn}
                    >
                      Show less
                    </button>
                  </span>
                ) : undefined}
              </Linkify>
            </p>
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold flex space-x-2">
                    <span className="flex items-center">
                      <TwitterV2 ratio={11} isGreyScaleActive />{" "}
                      {user.follower_count}
                    </span>
                    <span className="flex items-center">
                      <MainLogo ratio={16} isGreyScaleActive />
                      {`${followerCount}`}
                    </span>
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold flex space-x-2">
                    <span className="flex items-center">
                      <TwitterV2 ratio={11} isGreyScaleActive />{" "}
                      {user.following_count}
                    </span>
                    <span className="flex items-center">
                      <MainLogo ratio={16} isGreyScaleActive />
                      {followingCount}
                    </span>
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );
      case "linkedin":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <LinkedIn />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );
      case "metamask":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                {user?.farcaster?.username ? <Warpcast /> : <Metamask />}
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}
              {user?.provider === "metamask" ? (
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyMetamaskAddress(user.fullAddress)}
                >
                  {isAddressCopied ? <PopOver text={"Copied"} /> : undefined}
                  <Copy2 />
                </span>
              ) : undefined}
              {user?.provider === "worldcoin" ? (
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyMetamaskAddress(user.sub)}
                >
                  {isAddressCopied ? <PopOver text={"Copied"} /> : undefined}
                  <Copy2 />
                </span>
              ) : undefined}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );
      case "instagram":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Instagram />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              <a
                href={`https://instagram.com/${user.username}`}
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            <p className="dark:text-frescoWhite text-matteGray text-center px-2 pt-2 leading-5 text-sm">
              <Linkify componentDecorator={componentDecorator}>
                {user.description.length < 300 ? user.description : undefined}
                {user.description.length > 300 && !handleReadMore ? (
                  <span>
                    {user.description.slice(0, 300)}
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      onClick={hangleReadMoreBtn}
                    >
                      Read More
                    </button>
                  </span>
                ) : undefined}
                {handleReadMore ? (
                  <span className="dark:text-frescoWhite text-matteGray text-center px-2 leading-5 text-sm">
                    {user.description}
                    <br />
                    <button
                      className=" text-primary text-center px-1 leading-5 text-sm"
                      onClick={hangleShowLessBtn}
                    >
                      Show less
                    </button>
                  </span>
                ) : undefined}
              </Linkify>
            </p>
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={4} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {user.follower_count}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );

      case "facebook":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Facebook />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>

                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );

      case "google":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Google />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>

                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="pb-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );

      case "tiktok":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <TikTok />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3">
              <a
                href={`https://www.tiktok.com/@${user.username}`}
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {user.username}
              </a>
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                {/* <div className="flex items-center flex-col ">
                <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                  {user.follower_count}
                </p>
                <p className="text-base text-darkGray font-regular font-ubuntu">
                  Followers
                </p>
              </div> */}
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}

                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
            {/* <p className="dark:text-frescoWhite text-matteGray text-center px-2 leading-5 text-sm">
              {user?.description}
            </p> */}
            {/* <div className={`flex text-white pt-8`}>
              <div className={`text-center`}>
                <p className="text-xs text-primaryGray">Followers</p>
                <p className="text-base dark:text-white text-matteGray">
                  {user.follower_count}
                </p>
              </div>
            </div> */}
          </div>
        );
      case "worldcoin":
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <Worldcoin />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
              </span>
            </div>
          </div>
        );

      default:
        return (
          <div className=" flex flex-col items-center">
            <div className="relative">
              <Avatar
                src={user.profile_picture}
                size="md"
                username={user.username}
              />
              <span className="absolute right-0 top-10 bg-white p-1.5 rounded-full">
                <img
                  src={user.icon}
                  className="w-[20px] h-[20px]"
                  alt={provider}
                />
              </span>
            </div>
            <p className="dark:text-white text-matteGray text-lg font-medium pt-3 flex gap-2 items-center">
              {user.username}
              {!!user?.walletId ? (
                <span
                  className="cursor-pointer relative"
                  onClick={() => copyMetamaskAddress(user.wallet_address)}
                >
                  {isAddressCopied ? <PopOver text={"Copied"} /> : undefined}
                  <Copy2 />
                </span>
              ) : undefined}
            </p>
            {!isGettingRoomStatus ? (
              userRoomStatus?.room ? (
                <div
                  className={`flex items-center border border-lightGray rounded-full px-4 py-1 gap-2 my-4 ${
                    !isWaveCall && "cursor-pointer"
                  }  `}
                  onClick={onRoomCardClick}
                >
                  <span className="text-darkGray">
                    <Room />
                  </span>
                  <span className="flex flex-col">
                    <span className="uppercase text-primaryGreen text-center text-xs font-medium">
                      Active
                    </span>
                    <span className=" text-darkGray text-sm font-normal  -mt-0.5">
                      {userRoomStatus?.room.replace(" with $(_user)", "")}
                    </span>
                  </span>
                  <span className="text-darkGray pl-1">
                    <Arrow />
                  </span>
                </div>
              ) : null
            ) : (
              <Loader />
            )}
            {isCallCounting || isConnectCounting ? (
              <ProfileStatsSkeleton showItem={3} />
            ) : (
              <div className="flex space-x-4 py-4">
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${callsCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Total Calls
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followerCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Followers
                  </p>
                </div>
                <div className="flex items-center flex-col ">
                  <p className="text-lg dark:text-white text-darkNight font-ubuntu font-bold">
                    {`${followingCount}`}
                  </p>
                  <p className="md:text-base text-sm text-darkGray font-regular font-ubuntu">
                    Following
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <span className="flex items-center sm:gap-4 gap-3">
                <Link
                  to={profileRoute.path.replace(
                    ":username",
                    user?.vibehutUsername || user._id,
                  )}
                >
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {!isMobile() && "View"} Profile
                  </Button>
                </Link>
                {isLoggedInUser || !auth.user ? undefined : _renderChatButton()}
                {isLoggedInUser || !auth.user ? undefined : (
                  <WaveButton user={user} showOnlyCall={true} />
                )}
                {isLoggedInUser || !auth.user ? undefined : (
                  <ConnectionButton userId={user?._id} />
                )}
              </span>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 md:z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`inline-block align-bottom bg-white dark:bg-navyGray rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-450px font-ubuntu overflow-y-auto custom-scrollbar`}
              >
                <div className="bg-white dark:bg-navyGray p-3">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite"></p>
                    <span
                      className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="bg-white dark:bg-navyGray p-3 max-h-[80vh] overflow-auto custom-scrollbar">
                  {_renderUserProfile()}
                  <ProfilePersonas
                    userId={user?._id}
                    setIsOpenProfileModal={setIsOpen}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default memo(ProfileModal);
