import React from "react";

function Shop() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9481_120480)">
        <path
          d="M8 24C8.55228 24 9 23.5523 9 23C9 22.4477 8.55228 22 8 22C7.44772 22 7 22.4477 7 23C7 23.5523 7.44772 24 8 24Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 24C19.5523 24 20 23.5523 20 23C20 22.4477 19.5523 22 19 22C18.4477 22 18 22.4477 18 23C18 23.5523 18.4477 24 19 24Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 5H6.45455L8.76909 16.6044C8.84807 17.0034 9.06438 17.3618 9.38015 17.6169C9.69593 17.872 10.0911 18.0075 10.4964 17.9997H18.8909C19.2962 18.0075 19.6913 17.872 20.0071 17.6169C20.3229 17.3618 20.5392 17.0034 20.6182 16.6044L22 9.33323H7.31818"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9481_120480">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Shop;
