import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getMySubscriptions } from "../../actions/subscriptions";
import Loader from "../../VibehutUI/Loader";
import { SubscriptionCard } from "./subscriptionCard";
import Empty from "../../VibehutUI/Empty";
import NoSubsciptions from "../../VibehutUI/Icons/NoSubsciptions";

function MySubscriptions() {
  const { state, dispatch } = useContext(StoreContext);

  const {
    auth,
    subscription: { mySubscriptions, isFetching },
  } = state;

  useEffect(() => {
    getMySubscriptions(auth?.user?.token, dispatch);
  }, [auth?.user?.token, dispatch]);

  return (
    <div>
      {isFetching ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {mySubscriptions && mySubscriptions.length > 0 ? (
            <div className="video_page">
              <div className="subscription_list">
                {mySubscriptions.map((subscription, index) => (
                  <SubscriptionCard subscription={subscription} key={index} />
                ))}
              </div>
            </div>
          ) : (
            <Empty
              Image={<NoSubsciptions height="150" width="200" />}
              title="Your room subscriptions will appear here"
              description=""
              showButton={false}
              descTextSize="text-sm"
              customPadding="pt-2"
            />
          )}
        </>
      )}
    </div>
  );
}

export default MySubscriptions;
