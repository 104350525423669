import * as actionTypes from "../constants";

const initialState = {
  requestEvents: [],
  isFetching: false,
  error: null,
  eventId: null,
  isCreatingIndex: null,
  requestEventChatModal: null,
};

function requestEventReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_REQUEST_EVENT_REQUEST:
    case actionTypes.UPDATE_REQUEST_EVENT_REQUEST:
      return { ...state, isCreatingIndex: action.payload };

    case actionTypes.CREATE_REQUEST_EVENT_SUCCESS:
    case actionTypes.UPDATE_REQUEST_EVENT_SUCCESS: {
      const requestEvents = [action.payload, ...state.requestEvents];
      return {
        ...state,
        requestEvents: requestEvents,
        isCreatingIndex: null,
      };
    }

    case actionTypes.CREATE_REQUEST_EVENT_FAILURE:
    case actionTypes.UPDATE_REQUEST_EVENT_FAILURE:
      return { ...state, isCreatingIndex: null };
    case actionTypes.GET_ALL_REQUEST_EVENT_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.GET_ALL_REQUEST_EVENT_SUCCESS: {
      return {
        ...state,
        requestEvents: action.payload,
        isFetching: false,
      };
    }

    case actionTypes.GET_ALL_REQUEST_EVENT_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.CREATE_EVENT_FROM_REQUEST_EVENT_REQUEST:
      return { ...state, isCreatingIndex: action.payload };

    case actionTypes.CREATE_EVENT_FROM_REQUEST_EVENT_SUCCESS: {
      const requestEvents = [...state.requestEvents];
      const requestEventIndex = state.requestEvents.findIndex(
        (event) => event._id === action.payload._id,
      );
      requestEvents[requestEventIndex] = action.payload;

      return {
        ...state,
        isCreatingIndex: null,
        requestEvents,
      };
    }

    case actionTypes.CREATE_EVENT_FROM_REQUEST_EVENT_FAILURE:
      return { ...state, isCreatingIndex: null };
    case actionTypes.SET_REQUEST_EVENT_CHAT_MODAL:
      return {
        ...state,
        requestEventChatModal: action.payload,
      };

    default:
      return state;
  }
}

export default requestEventReducer;
