import React, { createContext, useEffect, useState } from "react";
import { getAllRoles } from "../../services/roleService";

export interface Promotion {
  isActive: boolean;
  link: string;
  title: string;
  imgUrl: string;
}

export interface SocialLinks {
  twitter: string;
  facebook: string;
  instagram: string;
  linkedIn: string;
  tiktok: string;
  metamask: string;
  telegram: string;
  link: String;
}

export interface IUser {
  _id: string;
  provider: string;
  username: string;
  publickey: string;
  profile_picture: string;
  description: string;
  investorCount: number;
  coinprice: number;
  userState: {
    status: string;
    room: string;
    roomId: string;
  };
  promotion?: Promotion;
  socialLinks?: SocialLinks;
  serviceRole?: string;
}

export interface Role {
  _id: string;
  name: string;
  permissions: string[];
  limits: {
    rooms: number;
    services: number;
    amitBot: number;
  };
}

export interface IRoom {
  _id: string;
  name: string;
  type: "Lobby" | "Room";
  description: string;
  is_private: boolean;
  cover: {
    urls: {
      raw: string;
      full: string;
      regular: string;
      small: string;
      thumb: string;
    };
    source: string;
  };
  isWaveCall: string;
  isGroupRoom: boolean;
  owner: IUser | undefined;

  calls: { [callId: string]: IUser[] };
  lobbyUsers: IUser[];
  blockedUsers: IUser[];

  liveUsersCount: number;
  lobbyUsersCount: number;
  talkingUsersCount: number;
  maxUsersPerGroup: number;
  peerUser: IUser | undefined;
  connectionWithPeerUser: boolean;
  isBoostedCallRoom?: boolean;
  isPasswordProtected: boolean;
  roomPassword: string;
  isPasswordVerified: boolean;
  isWaveRoom: boolean;
  isInstantRoom: boolean;
  liveUsers: IUser[];
  blockedGeneralUsers: string[];
  recordingConsent: boolean;
  isRecording: boolean;
  moderators: string[];
  isBreakoutRoom: boolean;
  totalLiveCalls: number;
  isGuestAllowed: boolean;
  isRequireApproval: boolean;
  totalCalls: number;
  breakoutRoomNames: any[];
  filteredBreakoutRoomNames: any[];
  callDetails: any;
  promotion?: Promotion;
  labels: any[];
  isSubscriberRequireApproval: boolean;
  subscriberApprovedUsers: IUser[];
  desoPostRoom: {
    postHashHex: string;
    diamondLevel: string;
  };
  isCCRoom: boolean;
  isDAORoom: boolean;
  CCDetails: any[];
  DAODetails: any[];
  callReaction: {
    emoji: "";
    username: "";
    callStreak: 0;
  };
  isQuickJoin: boolean;
}
export interface joinRequestFromGuest {
  username: string;
  userId: string;
  joinTime: string;
  isRequestAcceptedByHost: string;
  profilePicture: string;
}
export interface IVibehutDataContext {
  roomData: IRoom;
  setRoomData: (roomData: Partial<IRoom>) => void;
  callId: string;
  setCallId: (callId: string) => void;
  invitedUsers: string[];
  setInvitedUsers: (userIds: string[]) => void;
  timerValue: string;
  setSecond: any;
  setMinute: any;
  setIsAnyJoinRequestFromGuest: any;
  isAnyJoinRequestFromGuest: {
    isOpenJoinRequestModal: boolean;
    username: string;
    userId: string;
    isRequestAcceptedByHost: string;
    profilePicture: string;
  };
  approvalRequestFromGuest: joinRequestFromGuest[];
  setApprovalRequestFromGuest: any;
  roles: Role[];
}

const initialInvitedUser: string[] = [];

const initialRoom: IRoom = {
  _id: "",
  name: "",
  type: "Lobby",

  description: "",
  is_private: false,
  cover: {
    urls: {
      raw: "",
      full: "",
      regular: "",
      small: "",
      thumb: "",
    },
    source: "",
  },
  isWaveCall: "",
  isGroupRoom: true,
  owner: undefined,

  calls: {},
  lobbyUsers: [],
  blockedUsers: [],
  isSubscriberRequireApproval: false,
  subscriberApprovedUsers: [],
  blockedGeneralUsers: [],
  liveUsersCount: 0,
  lobbyUsersCount: 0,
  talkingUsersCount: 0,
  maxUsersPerGroup: 0,
  peerUser: undefined,
  connectionWithPeerUser: false,

  isPasswordProtected: false,
  roomPassword: "",
  isPasswordVerified: false,
  isWaveRoom: false,
  isInstantRoom: false,
  liveUsers: [],
  isBoostedCallRoom: false,
  recordingConsent: false,
  isRecording: false,
  moderators: [],
  isBreakoutRoom: false,
  totalLiveCalls: 0,
  isGuestAllowed: false,
  isRequireApproval: false,
  totalCalls: 0,
  breakoutRoomNames: [],
  filteredBreakoutRoomNames: [],
  callDetails: {},
  promotion: {
    isActive: false,
    link: "",
    title: "",
    imgUrl: "",
  },
  labels: [],
  desoPostRoom: {
    postHashHex: "",
    diamondLevel: "",
  },
  isCCRoom: false,
  isDAORoom: false,
  CCDetails: [],
  DAODetails: [],
  callReaction: {
    emoji: "",
    username: "",
    callStreak: 0,
  },
  isQuickJoin: false,
};

export const VibehutDataContext = createContext<IVibehutDataContext>(null!);

interface Props {
  children: any;
}

export const VibehutDataProvider = ({ children }: Props) => {
  const [roomData, setRoomData] = useState<IRoom>(initialRoom);
  const [roles, setRoles] = useState<Role[]>([]);
  const [callId, setCallId] = useState("");
  const [invitedUsers, setInvitedUsers] =
    useState<string[]>(initialInvitedUser);
  const [timerValue, setTimerValue] = useState<string>("");
  const [counter, setCounter] = useState(0);
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isAnyJoinRequestFromGuest, setIsAnyJoinRequestFromGuest] = useState({
    isOpenJoinRequestModal: false,
    username: "",
    userId: "",
    isRequestAcceptedByHost: "",
    profilePicture: "",
  });
  const [approvalRequestFromGuest, setApprovalRequestFromGuest] = useState<
    joinRequestFromGuest[]
  >([]);

  const updateRoomData = (p_data: Partial<IRoom>) => {
    setRoomData((prevRoomData) => ({
      ...prevRoomData,
      ...p_data,
    }));
  };

  const updateInvitedUserData = (p_data: string[]) => {
    setInvitedUsers((prevInvitedUserData) => [
      ...prevInvitedUserData,
      ...p_data,
    ]);
  };

  const updateWaitingList = (
    data: joinRequestFromGuest,
    isRemove: boolean = false,
  ) => {
    setApprovalRequestFromGuest((prevUserData) => {
      if (isRemove) {
        return prevUserData.filter((item) => item.userId !== data.userId);
      } else {
        const userExists = prevUserData.some(
          (item) => item.userId === data.userId,
        );

        if (!userExists) {
          return [...prevUserData, data];
        }

        return prevUserData;
      }
    });
  };

  useEffect(() => {
    let intervalId: any;

    if (callId) {
      intervalId = setInterval(() => {
        const secondCounter = (second + counter) % 60;
        const minuteCounter = minute + Math.floor(counter / 60);
        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `${minuteCounter}`
            : minuteCounter;
        setTimerValue(`${computedMinute}m ${computedSecond}s`);
        setCounter(counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [callId, counter]);

  useEffect(() => {
    getAllRoles().then((res: any) => {
      setRoles(res.data.roles);
    });
  }, []);

  return (
    <VibehutDataContext.Provider
      value={{
        roomData,
        callId,
        setCallId,
        setRoomData: updateRoomData,
        invitedUsers,
        setInvitedUsers: updateInvitedUserData,
        timerValue,
        setSecond,
        setMinute,
        setIsAnyJoinRequestFromGuest,
        isAnyJoinRequestFromGuest,
        approvalRequestFromGuest,
        setApprovalRequestFromGuest: updateWaitingList,
        roles,
      }}
    >
      {children}
    </VibehutDataContext.Provider>
  );
};
