import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../Button";
import Tick from "../Icons/Tick";

export default function ReviewConfirmationModal({ isOpen, setIsOpen, call }) {
  const history = useHistory();
  const handleEndCall = () => {
    if (call && call?.isRecordedCall) {
      history.replace(`/?callId=${call?._id}`);
    } else {
      history.replace("/");
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md  text-left overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="px-4 pt-4 space-y-1 flex flex-col items-center justify-center">
                  <div>
                    <span className="w-20 h-20 bg-primaryGreen text-navyGray rounded-full flex items-center justify-center">
                      <Tick ratio={"50"} />
                    </span>
                  </div>
                  <p className="pt-2 text-xl font-bold text-center">
                    Thanks submitted!
                  </p>
                  <p className="text-darkGray text-base font-normal text-center">
                    To see all ratings and reviews for calls, check your recent
                    calls.
                  </p>
                </div>
                <div className=" px-4 py-3 flex flex-row justify-center space-x-3 bg-white">
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-primary"
                    onClick={handleEndCall}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
