import { useContext, useEffect, useState } from "react";
import {
  Promotion,
  SocialLinks,
  VibehutDataContext,
  joinRequestFromGuest,
} from "../../components/VibehutDataProvider";
import { frontEndURL } from "../../constants";
import { globals } from "../../globals";
import { getRoomLobbyData } from "../../services/roomService";
import { loginAsGuest } from "../../services/userService";
import useVibehutChatContext from "../useVibehutChatContext/useVibehutChatContext";
import useVideoContext from "../useVideoContext/useVideoContext";
import useAuthUser from "../useAuthUser/useAuthUser";
import { inviteUsersToRoomCall } from "../../../services/callService";

export default function useVibehutData(
  roomId: string,
  v?: string,
  password?: string,
) {
  const vibehutChatContext = useVibehutChatContext();
  const {
    roomData,
    setRoomData,
    setCallId,
    invitedUsers,
    setInvitedUsers,
    timerValue,
    callId,
    setMinute,
    setSecond,
    setIsAnyJoinRequestFromGuest,
    isAnyJoinRequestFromGuest,
    roles,
    setApprovalRequestFromGuest,
    approvalRequestFromGuest,
  } = useContext(VibehutDataContext);
  const { connect: videoConnect } = useVideoContext();
  const { authUser } = useAuthUser();
  const [instantRoomId, setInstantRoomId] = useState("");
  const [roomPassword, setRoomPassword] = useState("");

  const getRoomStatsCallback = ({
    users,
    liveUsersCount,
    room_data,
    error,
    calls,
    callDetails,
    lobbyUsersCount,
    talkingUsersCount,
    maxUsersPerGroup,
    lobby_users,
  }: any) => {
    if (error) {
      alert(error.message || "Something went wrong");
      window.close();
      return;
    }
    const updatesRoomData: any = {};

    if (users) {
      updatesRoomData.liveUsers = users;
    }

    if (liveUsersCount) {
      updatesRoomData.liveUsersCount = liveUsersCount;
    }

    if (lobbyUsersCount) {
      updatesRoomData.lobbyUsersCount = lobbyUsersCount;
    }

    if (talkingUsersCount) {
      updatesRoomData.talkingUsersCount = talkingUsersCount;
    }
    if (maxUsersPerGroup) {
      updatesRoomData.maxUsersPerGroup = maxUsersPerGroup;
    }

    if (calls) {
      updatesRoomData.calls = calls;
    }

    if (callDetails) {
      updatesRoomData.callDetails = callDetails;
      updatesRoomData.filteredBreakoutRoomNames =
        room_data.filteredBreakoutRoomNames;
    }

    if (lobby_users) {
      updatesRoomData.lobbyUsers = lobby_users;
    }

    if (room_data?.type) {
      updatesRoomData.type = room_data.type;
    }

    if (room_data?.name) {
      updatesRoomData.name = room_data.name;
    }

    if (room_data?.description) {
      updatesRoomData.description = room_data.description;
    }

    if (room_data?.is_private) {
      updatesRoomData.is_private = room_data.is_private;
    }

    if (room_data?.cover) {
      updatesRoomData.cover = room_data.cover;
    }

    if (room_data?.owner) {
      updatesRoomData.owner = room_data.owner;
    }

    if (room_data?.blockedUsers) {
      updatesRoomData.blockedUsers = roomData.blockedUsers;
    }
    if (room_data?.isSubscriberRequireApproval) {
      updatesRoomData.isSubscriberRequireApproval =
        roomData.isSubscriberRequireApproval;
    }
    if (room_data?.subscriberApprovedUsers) {
      updatesRoomData.subscriberApprovedUsers =
        roomData.subscriberApprovedUsers;
    }
    if (room_data?.isQuickJoin) {
      updatesRoomData.isQuickJoin = room_data.isQuickJoin;
    }
    setRoomData(updatesRoomData);
  };

  useEffect(() => {
    if (v) {
      setInstantRoomId(v);
    }
    if (password) {
      setRoomPassword(password);
    }
  }, [v, password]);

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function convertMsToTime(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    setMinute(parseInt(padTo2Digits(minutes)));
    setSecond(parseInt(padTo2Digits(seconds)));
  }
  function getDifference(callTime: string) {
    const msBetweenDates: any =
      new Date().getTime() - new Date(callTime).getTime();
    convertMsToTime(msBetweenDates);
  }

  function muteUser(userId: string, isAudioEnabled: Boolean) {
    try {
      if (
        (roomId || instantRoomId) &&
        globals.isSocketConnected &&
        globals.socket
      ) {
        globals.socket.emit("userMuteOut", {
          userId: userId,
          roomId: roomId,
          isAudioEnabled: isAudioEnabled,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  function updateUserProfileSocials(
    userId: String,
    socialLinks: Partial<SocialLinks>,
  ) {
    try {
      if (
        (roomId || instantRoomId) &&
        globals.isSocketConnected &&
        globals.socket
      ) {
        globals.socket.emit("updateUserProfileSocials", {
          userId: userId,
          roomId: roomId,
          socialLinks: socialLinks,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  useEffect(() => {
    globals.helloServer = (data) => {
      alert(data);
    };

    globals.onRoomNameData = ({
      roomName,
      isPasswordProtected,
      isPasswordVerified,
      isWaveRoom,
    }: any) => {
      setRoomData({
        name: roomName,
        isPasswordProtected: isPasswordProtected,
        isPasswordVerified: isPasswordVerified,
        isWaveRoom: isWaveRoom,
      });
    };

    globals.onChatStart = ({
      user,
      token,
      connectionWithUser,
      isGroupCall,
      call,
    }: any) => {
      setRoomData({
        peerUser: user,
        connectionWithPeerUser: connectionWithUser,
        isGroupRoom: isGroupCall,
      });

      setCallId(call._id);

      if (call.users.length > 1) {
        getDifference(call.callCreated);
      }

      vibehutChatContext.updateCallId(call._id);

      vibehutChatContext.updateRoomId(roomData._id);

      videoConnect(token);
    };

    globals.onChatEnd = () => {
      globals.socket?.disconnect();
      window.location.reload();
    };

    globals.onUpdateCall = ({ queue_room }: any) => {
      setRoomData({
        calls: queue_room.calls,
        liveUsers: queue_room.users,
        lobbyUsers: queue_room.lobby_users,
        liveUsersCount: queue_room.liveUsersCount,
        lobbyUsersCount: queue_room.lobbyUsersCount,
        talkingUsersCount: queue_room.talkingUsersCount,
        maxUsersPerGroup: queue_room.maxUsersPerGroup,
      });
    };

    globals.onRoomPromotion = (p_data: Promotion) => {
      setRoomData({
        promotion: p_data,
      });
    };

    globals.onChatMessage = (data: any) => {
      vibehutChatContext.onChatMessage(data);
    };

    globals.onDeletedMessage = (data: any) => {
      vibehutChatContext.onDeletedMessage(data);
    };

    globals.onChatReactionAdded = (data: any) => {
      vibehutChatContext.onChatReactionAdded(data);
    };

    globals.onChatIdUpdate = (data: any) => {
      vibehutChatContext.onChatIdUpdate(data);
    };

    globals.onChatReactionUpdate = (data: any) => {
      vibehutChatContext.onChatReactionUpdate(data);
    };

    globals.onRecordingConcent = (concent: boolean) => {
      setRoomData({
        recordingConsent: concent,
      });
    };

    globals.onIsRecording = (isRecording: boolean) => {
      setRoomData({
        isRecording: isRecording,
      });
    };

    globals.onUserKickOut = () => {
      window.location.href = `${frontEndURL}/call?roomKey=${roomId}&kickedUser=true`;
    };

    globals.onGuestUserJoinCallRequest = (
      guestUsername,
      guestUserId,
      profilePicture,
    ) => {
      setApprovalRequestFromGuest({
        username: guestUsername,
        userId: guestUserId,
        joinTime: new Date().toISOString(),
        profilePicture: profilePicture,
        isRequestAcceptedByHost: "",
      });
    };

    globals.onIsRequestAcceptByHost = (isAllowed) => {
      setIsAnyJoinRequestFromGuest({
        ...isAnyJoinRequestFromGuest,
        isRequestAcceptedByHost: isAllowed ? "accepted" : "rejected",
      });
    };

    globals.onInitiateCall = (data) => {
      if (data.isTotalCallsLimitExceeded) {
        alert("Total live calls limit excluded.");
      } else if (data.call) {
        alert("New call initiated.");
      } else {
        alert("Please try again.");
      }
    };

    globals.onSaveMessageToRoom = (data: any) => {
      vibehutChatContext.onSaveMessageToRoom(data);
    };

    callEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, globals.isSocketConnected, instantRoomId, roomPassword]);

  const inviteUsersFun = async (
    desoInvitedUsers: string[],
    invitedUsers: string[],
    roomId: any,
    invitationMsg: any,
    token?: string,
  ) => {
    try {
      if (
        (roomId || instantRoomId) &&
        globals.isSocketConnected &&
        globals.socket
      ) {
        await inviteUsersToRoomCall(
          {
            invitedUsers,
            invitationMsg,
            roomId,
            desoInvitedUsers,
          },
          token,
        );
        setInvitedUsers([...invitedUsers, ...desoInvitedUsers]);
      }
    } catch (error) {}
  };

  const kickedOutUser = (userId: string) => {
    try {
      if (
        (roomId || instantRoomId) &&
        globals.isSocketConnected &&
        globals.socket
      ) {
        globals.socket.emit("userKickedOut", {
          userId: userId,
          roomId: roomId,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const boostCall = (msg: string, isShowMessage: boolean) => {
    try {
      if (
        (roomId || instantRoomId) &&
        globals.isSocketConnected &&
        globals.socket
      ) {
        globals.socket.emit("boostCall", {
          roomId: roomId,
          callId: callId,
          msg: msg,
          isShowMessage,
        });
        setRoomData({
          isBoostedCallRoom: true,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const initiateAnotherCall = () => {
    try {
      if (roomId && globals.isSocketConnected && globals.socket) {
        globals.socket.emit("initiateAnotherCall", {
          roomId: roomId,
          userId: authUser._id,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const sendInviteInstantNotification = (
    userId: string,
    inviteMsg: string | undefined = undefined,
  ) => {
    if (
      (roomId || instantRoomId) &&
      globals.isSocketConnected &&
      globals.socket
    ) {
      if (inviteMsg) {
        globals.socket.emit("inviteUser", {
          userId,
          roomId,
          inviteMsg,
        });
      } else {
        globals.socket.emit("inviteUser", { userId, roomId });
      }
    }
  };
  const gotoNextCall = () => {
    if (globals.isSocketConnected && globals.socket) {
      globals.socket.emit("leaveroom");
    }
  };

  const joinCallAsGuest = async (username: string) => {
    try {
      const res = await loginAsGuest(username);
      globals.guestUserDetails(res.data);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const requestForJoinCallAsGuest = async (
    username: string,
    userId: string,
    isGuest: boolean,
    profilePicture?: string,
  ) => {
    try {
      if (globals.isSocketConnected && globals.socket) {
        setIsAnyJoinRequestFromGuest({
          ...isAnyJoinRequestFromGuest,
          isRequestAcceptedByHost: "pending",
        });
        globals.socket.emit("requestForJoinCall", {
          username,
          roomId,
          userId,
          isGuest,
          profilePicture: profilePicture ? profilePicture : "",
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const requestAcceptByHost = (
    isAllowed: boolean,
    user: joinRequestFromGuest,
  ) => {
    try {
      if (globals.isSocketConnected && globals.socket) {
        setApprovalRequestFromGuest(user, true);
        globals.socket.emit("requestAcceptByHost", {
          roomId,
          isAllowed,
          userId: user.userId,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const callEvents = async () => {
    try {
      if (roomId || instantRoomId) {
        const roomRes = await getRoomLobbyData({
          roomId: roomId || undefined,
          v: instantRoomId || undefined,
          password: roomPassword || undefined,
        });
        const room = roomRes.data.room;
        globals.roomId = room._id;

        let callDetails = null;
        let filteredBreakoutRoomNames = [];
        if (!globals.isSocketConnected || !globals.socket) {
          callDetails = room.callDetails;
          filteredBreakoutRoomNames = room.filteredBreakoutRoomNames;
        }
        setRoomData({
          _id: room._id,
          name: room.name,
          isWaveCall: room.isWaveRoom,
          owner: room.owner,
          cover: room.cover,
          isGroupRoom: room.isGroupRoom,
          isPasswordProtected: room.isPasswordProtected,
          isPasswordVerified: roomRes.data.isPasswordVerified,
          isWaveRoom: roomRes.data.isWaveRoom,
          isInstantRoom: room?.isInstantRoom,
          moderators: room.moderators || [],
          maxUsersPerGroup: Number(room.maxUsersPerGroup),
          isBreakoutRoom: room.isBreakoutRoom,
          totalLiveCalls: room.totalLiveCalls,
          isGuestAllowed: room?.isGuestAllowed,
          isRequireApproval: room?.isRequireApproval,
          totalCalls: room.totalCalls,
          breakoutRoomNames: room.breakoutRoomNames,
          blockedGeneralUsers: room.blockedGeneralUsers || [],
          filteredBreakoutRoomNames:
            filteredBreakoutRoomNames.length > 0
              ? filteredBreakoutRoomNames
              : room.breakoutRoomNames,
          callDetails: callDetails ? callDetails : {},
          labels: room.labels || [],
          blockedUsers: room.blockedUsers || [],
          isSubscriberRequireApproval:
            room.isSubscriberRequireApproval || false,
          subscriberApprovedUsers: room.subscriberApprovedUsers || [],
          desoPostRoom: room.desoPostRoom || {},
          isCCRoom: room.isCCRoom || false,
          isDAORoom: room.isDAORoom || false,
          CCDetails: room.CCDetails || {},
          DAODetails: room.DAODetails || {},
          promotion: room?.promotion,
          isQuickJoin: room?.isQuickJoin,
        });
        if (globals.isSocketConnected && globals.socket) {
          globals.socket.emit(
            "get-room-stats",
            globals.roomId,
            getRoomStatsCallback,
          );
        }
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  function sendReactionInCall(
    username: string,
    userId: string,
    emoji: string,
    callStreak: number = 0,
  ) {
    try {
      if (
        (roomId || instantRoomId) &&
        globals.isSocketConnected &&
        globals.socket
      ) {
        globals.socket.emit("send-reaction-in-call", {
          username,
          roomId,
          userId,
          emoji,
          callStreak,
        });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  globals.onSendReactionInCall = (data) => {
    let username = data.username;
    if (data.userId === authUser._id) {
      username = "You";
    }
    setRoomData({
      ...roomData,
      callReaction: {
        username,
        emoji: data.emoji,
        callStreak: data.callStreak,
      },
    });
  };

  return {
    roomData,
    invitedUsers,
    inviteUsersFun,
    sendInviteInstantNotification,
    instantRoomId,
    kickedOutUser,
    gotoNextCall,
    timerValue,
    callId,
    joinCallAsGuest,
    initiateAnotherCall,
    requestForJoinCallAsGuest,
    isAnyJoinRequestFromGuest,
    requestAcceptByHost,
    muteUser,
    updateUserProfileSocials,
    roles,
    boostCall,
    sendReactionInCall,
  };
}
