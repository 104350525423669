import * as actionTypes from "../constants";

const initialState = {
  feedPosts: [],
  activityFeedPosts: [],
  isFeedFetching: false,
  isMoreFeedFetching: false,
  isMoreFeedAvailable: false,
  error: null,
  page: 0,
  lastPostHash: "",
  latestPost: null,
  singlePost: null,
  isFetchingSinglePost: false,
};

function feedReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_FEED_ACTIVITY_POSTS_REQUEST: {
      return {
        ...state,
        isFeedFetching: true,
      };
    }
    case actionTypes.GET_FEED_ACTIVITY_POSTS_SUCCESS: {
      return {
        ...state,
        activityFeedPosts: action.payload.activityFeedPosts,
        isFeedFetching: false,
        singlePost: null,
      };
    }
    case actionTypes.GET_FEED_ACTIVITY_POSTS_FAILURE: {
      return {
        ...state,
        isFeedFetching: false,
      };
    }
    case actionTypes.GET_FEED_POSTS_REQUEST:
      if (action.loadMore) {
        return {
          ...state,
          isMoreFeedFetching: true,
        };
      }
      return { ...state, isFeedFetching: true };
    case actionTypes.GET_LATEST_POST_SUCCESS:
      return {
        ...state,
        latestPost: action.payload,
      };
    case actionTypes.GET_FEED_POSTS_SUCCESS: {
      if (action.payload.loadMore) {
        return {
          ...state,
          feedPosts: [...state.feedPosts, ...action.payload.feedPosts],
          isMoreFeedFetching: false,
          isFeedFetching: false,
          isMoreFeedAvailable: action.payload.isMoreFeedAvailable,
          page: state.page + 1,
          lastPostHash: action.payload.lastPostHash,
          singlePost: null,
        };
      } else {
        return {
          ...state,
          feedPosts: action.payload.feedPosts,
          isMoreFeedFetching: false,
          isFeedFetching: false,
          page: state.page + 1,
          isMoreFeedAvailable: action.payload.isMoreFeedAvailable,
          lastPostHash: action.payload.lastPostHash,
          singlePost: null,
        };
      }
    }
    case actionTypes.GET_FEED_POSTS_FAILURE:
      return { ...state, isFeedFetching: false, isMoreFeedFetching: false };
    case actionTypes.RESET_USER_FEED_STATE:
      return {
        feedPosts: [],
        activityFeedPosts: [],
        isFeedFetching: false,
        isMoreFeedFetching: false,
        isMoreFeedAvailable: false,
        error: null,
        page: 0,
        lastPostHash: "",
        singlePost: null,
      };
    case actionTypes.UPDATE_FEED_LIKE: {
      const { postId, isLiked } = action.payload;
      const postIndex = state.feedPosts.findIndex((post) => post.id === postId);
      const activityPostIndex = state.activityFeedPosts.findIndex(
        (post) => post.post.id === postId,
      );

      const updatePost = (postList, index, type) => {
        if (index !== -1) {
          const updatedPosts = [...postList];

          if (type === "activity") {
            const updatedPost = { ...updatedPosts[index].post };
            updatedPost.isLiked = isLiked;
            if (isLiked) {
              updatedPost.likeCount += 1;
            } else {
              updatedPost.likeCount -= 1;
            }

            updatedPosts[index].post = updatedPost;
          } else {
            const updatedPost = { ...updatedPosts[index] };
            updatedPost.isLiked = isLiked;
            if (isLiked) {
              updatedPost.likeCount += 1;
            } else {
              updatedPost.likeCount -= 1;
            }

            updatedPosts[index] = updatedPost;
          }

          return updatedPosts;
        }
        return postList;
      };

      const updatedFeedPosts = updatePost(state.feedPosts, postIndex, "feed");
      const updatedActivityFeedPosts = updatePost(
        state.activityFeedPosts,
        activityPostIndex,
        "activity",
      );

      return {
        ...state,
        feedPosts: updatedFeedPosts,
        activityFeedPosts: updatedActivityFeedPosts,
      };
    }
    case actionTypes.UPDATE_FEED_DIAMOND_COUNT: {
      const { postId } = action.payload;
      const postIndex = state.feedPosts.findIndex((post) => post.id === postId);
      const activityPostIndex = state.activityFeedPosts.findIndex(
        (post) => post.post.id === postId,
      );

      const updatePost = (postList, index, type) => {
        if (index !== -1) {
          const updatedPosts = [...postList];

          if (type === "activity") {
            let updatedPost = { ...updatedPosts[index].post };
            updatedPost = {
              ...updatedPost,
              diamondCount: updatedPost.diamondCount + 1,
              postEntryReaderState: {
                ...updatedPost.postEntryReaderState,
                DiamondLevelBestowed:
                  updatedPost.postEntryReaderState.DiamondLevelBestowed +
                  action.payload.diamondLevel,
              },
            };
            updatedPosts[index].post = updatedPost;
          } else {
            let updatedPost = { ...updatedPosts[index] };
            updatedPost = {
              ...updatedPost,
              diamondCount:
                (updatedPost.diamondCount ? updatedPost.diamondCount : 0) + 1,
              postEntryReaderState: {
                ...updatedPost.postEntryReaderState,
                DiamondLevelBestowed:
                  updatedPost.postEntryReaderState.DiamondLevelBestowed +
                  action.payload.diamondLevel,
              },
            };
            updatedPosts[index] = updatedPost;
          }

          return updatedPosts;
        }
        return postList;
      };

      const updatedFeedPosts = updatePost(state.feedPosts, postIndex, "feed");
      const updatedActivityFeedPosts = updatePost(
        state.activityFeedPosts,
        activityPostIndex,
        "activity",
      );

      return {
        ...state,
        feedPosts: updatedFeedPosts,
        activityFeedPosts: updatedActivityFeedPosts,
      };
    }

    case actionTypes.UPDATE_COMMENT_COUNT: {
      const { postId, commentCount } = action.payload;
      const postIndex = state.feedPosts.findIndex((post) => post.id === postId);

      if (postIndex !== -1) {
        const updatedFeedPosts = [...state.feedPosts];
        const updatedPost = { ...updatedFeedPosts[postIndex] };

        updatedPost.commentCount = commentCount;

        updatedFeedPosts[postIndex] = updatedPost;

        return {
          ...state,
          feedPosts: updatedFeedPosts,
        };
      }

      return state;
    }

    case actionTypes.GET_SINGLE_POST_REQUEST:
      return {
        ...state,
        isFetchingSinglePost: true,
      };

    case actionTypes.GET_SINGLE_POST_SUCCESS:
      return {
        ...state,
        singlePost: action.payload,
        isFetchingSinglePost: false,
      };

    case actionTypes.GET_SINGLE_POST_FAILURE:
      return {
        ...state,
        isFetchingSinglePost: false,
      };
    case actionTypes.UPDATE_SINGLE_FEED_LIKE: {
      const { isLiked } = action.payload;
      const updatePost = (type) => {
        const updatedPost = { ...state.singlePost };
        if (type === "activity") {
          updatedPost.isLiked = isLiked;
          if (isLiked) {
            updatedPost.likeCount += 1;
          } else {
            updatedPost.likeCount -= 1;
          }
        } else {
          updatedPost.isLiked = isLiked;
          if (isLiked) {
            updatedPost.likeCount += 1;
          } else {
            updatedPost.likeCount -= 1;
          }
        }

        return updatedPost;
      };

      const updatedFeedPost = updatePost("feed");

      return {
        ...state,
        singlePost: updatedFeedPost,
      };
    }
    case actionTypes.UPDATE_SINGLE_FEED_DIAMOND_COUNT: {
      return {
        ...state,
        singlePost: {
          ...state.singlePost,
          postEntryReaderState: {
            ...state.singlePost.postEntryReaderState,
            DiamondLevelBestowed:
              state.singlePost.postEntryReaderState.DiamondLevelBestowed +
              action.payload.diamondLevel,
          },
          diamondCount: state.singlePost.diamondCount + 1,
        },
      };
    }
    case actionTypes.CREATE_FEED_SUCCESS: {
      return {
        ...state,
        feedPosts: [action.payload, ...state.feedPosts],
      };
    }
    case actionTypes.RESET_SINGLE_POST: {
      return {
        ...state,
        singlePost: null,
      };
    }
    default:
      return state;
  }
}

export default feedReducer;
