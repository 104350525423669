import React from "react";

function DiamondIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 1000 1000"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1272 -1453)">
          <g transform="translate(1272 1453)">
            <path d="M0 0H1000V1000H0z"></path>
            <g fillRule="nonzero" transform="translate(73 151)">
              <g>
                <g>
                  <path
                    fill="#FFC941"
                    d="M853.7575 230L1.2425 230 91.2975 2.5 763.7025 2.5z"
                  ></path>
                  <path
                    fill="#FFC941"
                    d="M427.5 772.925L1.2425 230 853.7575 230z"
                  ></path>
                  <path
                    fill="#FAAA4B"
                    d="M91.2975 1.9675L211.38 231.235 427.5 2.73z"
                  ></path>
                  <path
                    fill="#FAAA4B"
                    d="M763.7025 1.9675L643.62 231.235 427.5 2.73z"
                  ></path>
                  <path
                    fill="#F38F18"
                    d="M210.3725 232.11L427.5 773.0325 1.245 231.1075z"
                    opacity="0.4"
                  ></path>
                  <path
                    fill="#F38F18"
                    d="M644.6275 232.11L427.5 773.0325 853.755 231.1075z"
                    opacity="0.4"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DiamondIcon;
