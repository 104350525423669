import { Transition, Dialog } from '@headlessui/react';
import Close from '../Icons/Close';
import PopOver from '../Popover';
import Copy2 from '../Icons/Copy2';
import { Fragment, useState } from 'react';

interface CreatedLinkModalProps {
  isOpen: boolean;
  setIsOpen: any;
  link: string;
}

export default function CreatedLinkModal({ isOpen, setIsOpen, link }: CreatedLinkModalProps) {
  const [copied, setCopied] = useState(false);
  const copyRoomLink = async () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-navyGray  text-left align-middle shadow-xl transition-all">
                <div className="bg-white dark:bg-navyGray p-4 border-b border-frescoWhite dark:border-matteGray">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg text-black dark:text-frescoWhite tracking-wide">
                      Live stream link
                    </p>
                    <span
                      className="bg-frescoWhite rounded-full p-9px cursor-pointer"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Close />
                    </span>
                  </div>
                </div>
                <div className="p-4">
                  <div>
                    <p className="text-sm text-gray-400">Copy this link and send it to people.</p>
                  </div>
                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className="border-0 focus:outline-none focus:outline-0 focus:ring-0  bg-frescoWhite  py-[15px] pl-4 pr-12 md:px-4  rounded-lg my-1 w-full text-darkGray"
                      value={link}
                      readOnly={true}
                    />
                    {copied ? (
                      <span className="absolute right-10 top-2">
                        <PopOver text="Copied" size={20} />
                      </span>
                    ) : (
                      undefined
                    )}
                    <span className="absolute right-3 top-5 cursor-pointer bg-frescoWhite" onClick={copyRoomLink}>
                      <Copy2 />
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
