import React, { createContext, useEffect, useRef, useState } from "react";
import { globals } from "../../globals";
import useWindowSize from "../../hooks/common/useWindowSize";
import useAuthUser from "../../hooks/useAuthUser/useAuthUser";
import {
  getChatByCallId,
  getPersistedMessagesByRoom,
  saveMessageToRoom,
} from "../../services/chatService";
import { getUserData } from "../../utils/vibehutUtils";
import { encryptMsg } from "../../utils/AES";
import { v4 as uuid } from "uuid";

type VibehutChatContextType = {
  isChatTabOpen: boolean;
  savingMessageId: string | undefined;
  addReaction: (reaction: any) => void;
  removeUserOrAddReaction: (body: any) => void;
  addUserToReaction: (body: any) => void;
  setIsChatTabOpen: (isChatTabOpen: boolean) => void;
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  isLiveStreamChatWindowOpen: boolean;
  setIsLiveStreamChatWindowOpen: (isLiveStreamChatWindowOpen: boolean) => void;
  hasUnreadMessages: boolean;
  messages: any[];
  updateCallId: (callId: any) => void;
  sendMessage: (text: string, roomId: string, attachments: string[]) => void;
  onChatMessage: (data: any) => void;
  onDeletedMessage: (data: any) => void;
  onChatIdUpdate: (data: any) => void;
  unreadMessagesCount: number;
  onChatReactionAdded: (data: any) => void;
  onChatReactionUpdate: (data: any) => void;
  saveMessage: (chatId: string, roomId: string, isWaveCall: boolean) => void;
  onSaveMessageToRoom: (data: any) => void;
  updateRoomId: (roomId: any) => void;
  attachmentFile: any;
  setAttachmentFile: (attachmentFile: any) => void;
  isMediaUploading: boolean;
  setIsMediaUploading: (isMediaUploading: any) => void;
  attachedMessage: string;
  setAttachedMessage: (attachedMessage: string) => void;
};

export const VibehutChatContext = createContext<VibehutChatContextType>(null!);

export const VibehutChatProvider: React.FC = ({ children }) => {
  const { authUser } = useAuthUser();
  const [callId, setCallId] = useState("");
  const [roomId, setRoomId] = useState("");
  const isChatWindowOpenRef = useRef(false);
  const isShowUnreadCountRef = useRef(true);
  const [isChatTabOpen, setIsChatTabOpen] = useState(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [isLiveStreamChatWindowOpen, setIsLiveStreamChatWindowOpen] =
    useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [savingMessageId, setSavingMessageId] = useState<string | undefined>(
    undefined,
  );
  const [isShowUnreadCount, setIsShowUnreadCount] = useState(true);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [isMediaUploading, setIsMediaUploading] = useState(false);

  const { width: windowWidth } = useWindowSize();

  const getChatData = async () => {
    try {
      const { data: persistedMessages } =
        await getPersistedMessagesByRoom(roomId);
      persistedMessages.forEach((message: any) => {
        message.username = getUserData(message.userId).username;
        message.isSend =
          message.userId._id === authUser._id
            ? message.isAmiBot
              ? false
              : true
            : false;
      });

      if (persistedMessages.length > 0) {
        isShowUnreadCountRef.current = false;
        setIsShowUnreadCount(false);
      }
      const { data: activeCallMessages } = await getChatByCallId(callId);
      activeCallMessages.forEach((message: any) => {
        message.username = getUserData(message.userId).username;
        message.userId = undefined;
      });
      const filteredMessages = persistedMessages.filter(
        (obj: any) =>
          !activeCallMessages.some((item: any) => item._id === obj._id),
      );
      setMessages([...filteredMessages, ...activeCallMessages]);
      globals.chats = [...filteredMessages, ...activeCallMessages];
      if (activeCallMessages.length > 0) {
        isShowUnreadCountRef.current = true;
        setIsShowUnreadCount(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (roomId && callId) {
      getChatData();
    }
    return () => {
      setMessages([]);
      globals.chats = [];
    };
  }, [roomId, callId]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (
      !isChatWindowOpenRef.current &&
      messages.length &&
      isShowUnreadCountRef.current
    ) {
      setHasUnreadMessages(true);
      setUnreadMessagesCount((prev) => ++prev);
    }
  }, [messages, isShowUnreadCount]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatTabOpen || isChatWindowOpen;
    if (isChatTabOpen || isChatWindowOpen) {
      setHasUnreadMessages(false);
      setUnreadMessagesCount(0);
    }
  }, [isChatTabOpen, isChatWindowOpen]);

  useEffect(() => {
    if (typeof windowWidth === "number" && windowWidth >= 960) {
      setIsChatWindowOpen(false);
    }
  }, [windowWidth]);

  const updateCallId = (p_callId: string) => {
    setCallId(p_callId);
  };
  const updateRoomId = (p_roomId: string) => {
    setRoomId(p_roomId);
  };
  const updateMessages = (p_messages: any[]) => {
    setMessages((previous) => [...previous, ...p_messages]);
  };

  const sendMessage = (
    p_text: string,
    chatRoomId: string,
    attachments: any[],
  ) => {
    const secretKey = chatRoomId + process.env.REACT_APP_AES_SECRET_KEY;
    let encryptedMessage = p_text;
    if (p_text) {
      encryptedMessage =
        p_text.trim().substring(0, 4) === "@ami"
          ? p_text
          : encryptMsg(secretKey, p_text);
    }

    const isEncrypted = p_text.trim().substring(0, 4) === "@ami" ? false : true;

    const uniqueId = uuid();
    globals.socket?.emit("send_chat", {
      msg: encryptedMessage,
      uniqueId: uniqueId,
      isEncrypted,
      attachments,
    });
    const userData = getUserData(authUser);
    const payload = {
      msg: encryptedMessage,
      isSend: true,
      isDanger: false,
      isBot: false,
      username: userData.username,
      isEncrypted: isEncrypted,
      uniqueId: uniqueId,
      reactions: [],
      attachments,
    };
    globals.chats = [...globals.chats, payload];
    updateMessages([payload]);
  };

  const addReaction = (body: any) => {
    globals.socket?.emit("add_call_chat_reaction", body);
  };

  const removeUserOrAddReaction = (body: any) => {
    globals.socket?.emit("remove_user_or_add_reaction_call_chat", body);
  };

  const addUserToReaction = (body: any) => {
    globals.socket?.emit("add_user_to_reaction_call_chat", body);
  };

  const onDeletedMessage = (p_message: any) => {
    const p_messages = globals.chats;
    const filteredMessages = p_messages.filter((m) => m._id !== p_message._id);
    setMessages(filteredMessages);
    globals.chats = filteredMessages;
  };

  const onChatMessage = (p_message: any) => {
    globals.chats = [...globals.chats, p_message];
    updateMessages([p_message]);
  };

  const onChatIdUpdate = (p_message: any) => {
    const p_messages = globals.chats;
    const msgIndex = p_messages.findIndex(
      (m) => m.uniqueId === p_message.uniqueId,
    );
    p_messages[msgIndex]._id = p_message._id;
    setMessages(p_messages);
    globals.chats = p_messages;
  };

  const onChatReactionAdded = (reaction: any) => {
    const allChatsCopy = [...globals.chats];
    const chat = allChatsCopy.find((msg) => msg._id === reaction.chat._id);

    if (!chat) {
      return;
    }
    const chatIndex = allChatsCopy.findIndex(
      (msg) => msg._id === reaction.chat._id,
    );
    const allReactions = chat.reactions;
    let reacton = allReactions.find(
      (p_reaction: any) => p_reaction.unified === reaction.unified,
    );
    const reactIndex = allReactions.findIndex(
      (p_reaction: any) => p_reaction.unified === reaction.unified,
    );
    if (!reacton) {
      chat.reactions.push(reaction);
    } else {
      if (reacton.owners) {
        reacton.owners = reaction.owners;
      } else {
        reacton = { ...reacton, owners: reaction.owners };
      }
      chat.reactions[reactIndex] = reacton;
    }
    allChatsCopy[chatIndex] = chat;
    setMessages(allChatsCopy);
    globals.chats = allChatsCopy;
  };

  const onChatReactionUpdate = (updatedreaction: any) => {
    const allChatsCopy = [...globals.chats];
    const selectedChat = allChatsCopy.find(
      (msg) => msg._id === updatedreaction.chat._id,
    );
    const selectedChatIndex = allChatsCopy.findIndex(
      (msg) => msg._id === updatedreaction.chat._id,
    );
    let reactions = selectedChat?.reactions ? selectedChat?.reactions : [];
    const reaction = reactions.find(
      (p_reaction: any) => p_reaction._id === updatedreaction._id,
    );
    const reactionIndex = reactions.findIndex(
      (p_reaction: any) => p_reaction._id === updatedreaction._id,
    );
    if (reaction.owners?.length === 1) {
      reactions = reactions.filter(
        (p_reaction: any) => p_reaction._id !== updatedreaction._id,
      );
    } else {
      reaction.owners = reaction.owners.filter(
        (owner: any) => owner._id !== updatedreaction.owner,
      );
      reactions[reactionIndex] = reaction;
    }
    allChatsCopy[selectedChatIndex].reactions = reactions;
    allChatsCopy[selectedChatIndex] = selectedChat;
    setMessages(allChatsCopy);
    globals.chats = allChatsCopy;
  };

  const saveMessage = async (
    chatId: string,
    currentRoomId: string,
    isWaveCall: boolean,
  ) => {
    setSavingMessageId(chatId);
    await saveMessageToRoom(chatId, currentRoomId, isWaveCall)
      .then((res) => {
        const allChatsCopy = [...globals.chats];
        const savedChat = res.data;
        const selectedChatIndex = allChatsCopy.findIndex(
          (msg) => msg._id === savedChat._id,
        );
        allChatsCopy[selectedChatIndex].persistedAt = savedChat.persistedAt;
        allChatsCopy[selectedChatIndex].room = savedChat.room;
        setMessages(allChatsCopy);
        globals.chats = allChatsCopy;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSavingMessageId(undefined);
      });
  };

  const onSaveMessageToRoom = (chat: any) => {
    const allChatsCopy = [...globals.chats];
    const selectedChatIndex = allChatsCopy.findIndex(
      (msg) => msg._id === chat._id,
    );
    allChatsCopy[selectedChatIndex].persistedAt = chat.persistedAt;
    allChatsCopy[selectedChatIndex].room = chat.room;
    setMessages(allChatsCopy);
    globals.chats = allChatsCopy;
  };

  return (
    <VibehutChatContext.Provider
      value={{
        isChatTabOpen,
        setIsChatTabOpen,
        hasUnreadMessages,
        messages,
        updateCallId,
        sendMessage,
        addReaction,
        removeUserOrAddReaction,
        addUserToReaction,
        onChatMessage,
        onDeletedMessage,
        unreadMessagesCount,
        isChatWindowOpen,
        isLiveStreamChatWindowOpen,
        setIsChatWindowOpen,
        setIsLiveStreamChatWindowOpen,
        onChatIdUpdate,
        onChatReactionAdded,
        onChatReactionUpdate,
        saveMessage,
        savingMessageId,
        onSaveMessageToRoom,
        updateRoomId,
        attachmentFile,
        setAttachmentFile,
        isMediaUploading,
        setIsMediaUploading,
      }}
    >
      {children}
    </VibehutChatContext.Provider>
  );
};
