import React from "react";
import { reportErrorService } from "../services/errorService";
import Button from "../VibehutUI/Button";

window.onerror = function (message, source, lineno, colno, error) {
  if (error) {
    console.error('Frontend Error : ', error);
    reportErrorService(error.stack);
    return true;
  }
};

class ErrorBoundary extends React.Component {
  errorSent = false;
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.errorSent) {
      return;
    }
    if (error.code === 22) {
      window.localStorage.clear();
      window.location.reload();
      return;
    }
    this.setState({ error, errorInfo });
    this.errorSent = true;
  }

  render() {
    if (this.state.error) {
      return (
        <div className="dark:bg-darkNight bg-white flex flex-col item-center justify-center h-screen">
          <h2 className="dark:text-frescoWhite text-black text-center mb-4">
            Opps! Something went wrong!
          </h2>
          <div className="flex item-center justify-center">
            <Button
              size="vibhut-sm"
              variant="vibhut-primary"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
