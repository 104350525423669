import React, { useContext, useEffect, useState } from "react";
import { getUserData } from "../../services/userService";
import UserCard from "../../VibehutUI/UserCard";
import { getChatReactions } from "../../services/chatService";
import { StoreContext } from "../../Context/StoreContext";
import Loader from "../../VibehutUI/Loader";

function AllReactions({ chat }) {
  const { state } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reactions, setReactions] = useState([]);
  const {
    auth: { user },
  } = state;
  useEffect(() => {
    setIsLoading(true);
    getChatReactions(user?.token, chat._id)
      .then((response) => {
        setReactions(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [chat]);

  return (
    <>
      <div className=" justify-center py-4  rounded-2xl">
        <div>
          <div className="flex flex-row items-center gap-4 w-full">
            <div className="w-full">
              {isLoading && <Loader />}
              {!isLoading &&
                reactions.map((reaction) => {
                  return (
                    <>
                      {reaction.owners.map((user, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <div className="" key={index}>
                            <UserCard user={getUserData(user)} size="sm" />
                          </div>
                          <div className="pl-3">
                            <p className="text-2xl font-medium dark:text-frescoWhite ">
                              {reaction.emoji}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllReactions;
