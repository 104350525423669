import React from 'react';

interface Props {
  color?: string;
}

export default function CastIcon({ color = '#707578' }: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 8V5H21V19H14M3 15C5.20914 15 7 16.7909 7 19M3 11C7.41828 11 11 14.5817 11 19M3 18.99V19"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
