import Warpcast from "../../VibehutUI/Icons/Warpcast";
import { IRoom, Role } from "../components/VibehutDataProvider";
import { frontEndURL, userRoleTypes } from "../constants";
import DeSo from "../VibehutUI/Icons/DeSo";
import Facebook from "../VibehutUI/Icons/Facebook";
import Instagram from "../VibehutUI/Icons/Instagram";
import LinkedIn from "../VibehutUI/Icons/LinkedIn";
import LinkIcon from "../VibehutUI/Icons/LinkIcon";
import Metamask from "../VibehutUI/Icons/Metamask";
import Telegram from "../VibehutUI/Icons/Telegram";
import TikTok from "../VibehutUI/Icons/Tiktok";
import TwitterV2 from "../VibehutUI/Icons/TwitterV2";
import { decryptMsg } from "./AES";

export const _renderSocialIcon = (
  provider: string,
  bottomPosition = "-bottom-1",
) => {
  switch (provider) {
    case "bitclout":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <DeSo />
        </span>
      );
    case "linkedin":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <LinkedIn />
        </span>
      );
    case "twitter":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <TwitterV2 />
        </span>
      );
    case "metamask":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Metamask />
        </span>
      );
    case "warpcast":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Warpcast />
        </span>
      );
    case "instagram":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Instagram />
        </span>
      );
    case "tiktok":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <TikTok />
        </span>
      );
    case "facebook":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <Facebook />
        </span>
      );
    case "link":
      return (
        <span
          className={`absolute ${bottomPosition} md:-right-1 right-0 bg-white dark:bg-darkNight p-1 rounded-full`}
        >
          <LinkIcon />
        </span>
      );
    default:
      return <span></span>;
  }
};

export const wait = (timeMs: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timeMs);
  });
};

export const trimText = (text: string, limit = 20) => {
  if (text.length > limit) {
    return text.slice(0, limit) + "...";
  } else {
    return text;
  }
};

export const LinkedInApi = {
  clientId: "86jgfok90ostcq",
  redirectUrl: `${frontEndURL}/linkedin`,
  oauthUrl:
    "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
  scope: "r_emailaddress r_liteprofile", //r_basicprofile
  state: "123456",
};

export const getRoomName = (roomData: IRoom, callId: string, authUser: any) => {
  if (roomData?.isWaveCall) {
    const callUsers =
      roomData.calls[callId]?.filter((u) => u._id !== authUser._id) || [];
    if (callUsers.length > 0) {
      const mainUser = callUsers[0].username;
      const otherUsersCount = callUsers.length - 1;
      const otherUsersText =
        otherUsersCount > 0
          ? ` and ${otherUsersCount} ${
              otherUsersCount > 1 ? "others" : "other"
            }`
          : "";
      return `Wave Call with ${mainUser}${otherUsersText}`;
    }
    return "Wave Call";
  }
  if (roomData.type === "Lobby") {
    return "Random Chat (1-on-1)";
  }
  return `${roomData.name} ${roomData.isGroupRoom ? "(group)" : "(1-on-1)"}`;
};

export function getPermissionOfUser(roles: Role[], serviceRole: string) {
  const found = roles.find((element) => element._id === serviceRole);
  if (found) {
    return found._id;
  } else {
    return null;
  }
}

export const userServiceRoleMap: {
  [key: string]: {
    planName: string;
    planRole: string;
    badgeColor: string | undefined;
  };
} = {
  "63b6ec661ecac8c97cfda29c": {
    planName: "Individual Plan role",
    planRole: userRoleTypes.INDIVIDUAL,
    badgeColor: undefined,
  },
  "63b6ece31ecac8c97cfda2da": {
    planName: "Pro Plan role",
    planRole: userRoleTypes.PRO,
    badgeColor: "#0095F6",
  },
  "63b6ed2c1ecac8c97cfda307": {
    planName: "Business Plan role",
    planRole: userRoleTypes.BUSINESS,
    badgeColor: "#FFCC00",
  },
};

export const getDecryptMessage = (
  roomId: string,
  isEncrypted: boolean,
  message: string,
) => {
  try {
    if (!isEncrypted) {
      return message;
    }
    const regex = /^U2F.*/;
    if (!regex.test(message)) {
      return message;
    }
    const secretKey = roomId + process.env.REACT_APP_AES_SECRET_KEY;
    const dcMessage = decryptMsg(secretKey, message);
    return dcMessage;
  } catch (error) {
    console.log("error", error);
  }
};

export const _renderTwitter = (url: string) => {
  const regex = /https:\/\/twitter\.com\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://twitter.com/${url}`;
  return (
    <span>
      <a
        href={regUrl}
        target="_blank"
        rel="noreferrer"
        className="text-darkGray bg-darkGray"
        key={new Date().toString()}
      >
        <TwitterV2 ratio={16} isHoverActive />
      </a>
    </span>
  );
};

export const _renderInstagram = (url: string) => {
  const regex = /https:\/\/www\.instagram\.com\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://www.instagram.com/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <Instagram ratio={17} isGreyScaleActive />
      </a>
    </span>
  );
};

export const detectTimezone = () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fullTimezoneInfo = new Intl.DateTimeFormat("en-US", {
      timeZoneName: "long",
    }).format(new Date());

    return {
      zoneName: timezone,
      dateAndTimeZone: fullTimezoneInfo,
    };
  } catch (error) {
    return {
      zoneName: "Unknown",
      dateAndTimeZone: "Unknown",
    };
  }
};

export const _renderFacebook = (url: string) => {
  const regex = /https:\/\/www\.facebook\.com\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://www.facebook.com/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <Facebook ratio={17} isGreyScaleActive />
      </a>
    </span>
  );
};

export const _renderTelegram = (url: string) => {
  const regex = /https:\/\/telegram\.me\/([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://telegram.me/${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <Telegram ratio={17} isGreyScaleActive />
      </a>
    </span>
  );
};
export const _renderTiktok = (url: string) => {
  const regex = /https:\/\/tiktok\.com\/@([a-zA-Z0-9_]+)/;
  const regUrl = regex.test(url) ? url : `https://tiktok.com/@${url}`;
  return (
    <span>
      <a href={regUrl} target="_blank" rel="noreferrer">
        <TikTok ratio="15" isGreyScaleActive />
      </a>
    </span>
  );
};

export const _renderLinkedIn = (url: string) => {
  const regex =
    /^(?:(?:https?:\/\/)?(?:www\.|w\.)?.*linkedin\.com\/(in|company)\/([a-zA-Z0-9_/-]+)\/?)$/;
  const match = url.match(regex);

  let validUrl;
  if (match) {
    const type = match[1];
    const identifier = match[2];
    validUrl = `https://www.linkedin.com/${type}/${identifier}`;
  } else {
    validUrl = `https://www.linkedin.com/in/${url}`;
  }

  return (
    <span>
      <a href={validUrl} target="_blank" rel="noreferrer">
        <LinkedIn ratio={17} isGreyScaleActive />
      </a>
    </span>
  );
};

export const playCallJoinSound = () => {
  const audio = new Audio(`${process.env.PUBLIC_URL}/calljoin.mp3`);
  audio.play();
};

export const isValidRtmpUrl = (url: string) => {
  const rtmpRegex = /^rtmps?:\/\/[^/\s]+(\/[^/\s]+)*\/?$/;
  return rtmpRegex.test(url);
};

export function getShortenedName(name: string, limit = 40) {
  if (name) {
    if (name.length > limit) {
      return name.slice(0, limit) + "...";
    } else {
      return name;
    }
  }
  return "";
}

export const publicDefaultImageLink =
  "https://images.unsplash.com/photo-1573164574572-cb89e39749b4?auto=format&fit=crop&q=80&w=3269&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export const getSliderWidth = (
  screenWidth: number,
  containerWidth: number = 0,
) => {
  let clientWidth = containerWidth || 0;
  if (screenWidth > 1279) {
    clientWidth = screenWidth - 648;
    if (screenWidth > 1920) {
      clientWidth = 1920 - 648;
    }
  }
  return clientWidth;
};
