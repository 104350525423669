import React from "react";

const NoSubsciptions = ({ width = "250", height = "172" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4909_106782)">
        <path
          d="M15.3737 30.815C13.3548 30.815 11.3556 30.4164 9.49042 29.6421C7.6252 28.8679 5.93042 27.733 4.50284 26.3023C3.07527 24.8716 1.94285 23.1731 1.17025 21.3038C0.397652 19.4345 0 17.431 0 15.4077C0 13.3844 0.397652 11.3809 1.17025 9.51163C1.94285 7.64234 3.07527 5.94386 4.50284 4.51316C5.93042 3.08247 7.6252 1.94757 9.49042 1.17329C11.3556 0.399003 13.3548 0.000488221 15.3737 0.000488281H155.297C157.316 0.000488221 159.315 0.399003 161.18 1.17329C163.045 1.94757 164.74 3.08247 166.168 4.51316C167.595 5.94386 168.728 7.64234 169.5 9.51163C170.273 11.3809 170.671 13.3844 170.671 15.4077C170.671 17.431 170.273 19.4345 169.5 21.3038C168.728 23.1731 167.595 24.8716 166.168 26.3023C164.74 27.733 163.045 28.8679 161.18 29.6421C159.315 30.4164 157.316 30.815 155.297 30.815H15.3737Z"
          fill="#E6E6E6"
        />
        <path
          d="M15.3738 2.01001C11.8282 2.01001 8.42795 3.42154 5.92089 5.93408C3.41382 8.44661 2.00537 11.8543 2.00537 15.4076C2.00537 18.9609 3.41382 22.3686 5.92089 24.8811C8.42795 27.3937 11.8282 28.8052 15.3738 28.8052H155.297C158.843 28.8052 162.243 27.3937 164.75 24.8811C167.257 22.3686 168.665 18.9609 168.665 15.4076C168.665 11.8543 167.257 8.44661 164.75 5.93408C162.243 3.42154 158.843 2.01001 155.297 2.01001H15.3738Z"
          fill="white"
        />
        <path
          d="M57.8196 2.00964H57.373V28.8048H57.8196V2.00964Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.299 2.00964H112.852V28.8048H113.299V2.00964Z"
          fill="#E6E6E6"
        />
        <path
          d="M112.852 2.00964H57.8188V28.8048H112.852V2.00964Z"
          fill="#6C63FF"
        />
        <path
          d="M85.361 7.23792C83.7438 7.23792 82.163 7.7185 80.8184 8.61889C79.4738 9.51929 78.4258 10.7991 77.807 12.2964C77.1881 13.7937 77.0262 15.4413 77.3417 17.0308C77.6572 18.6203 78.4359 20.0804 79.5794 21.2264C80.7229 22.3724 82.1798 23.1528 83.7658 23.469C85.3519 23.7851 86.9959 23.6229 88.4899 23.0027C89.984 22.3825 91.261 21.3322 92.1594 19.9846C93.0578 18.6371 93.5374 17.0528 93.5374 15.4322C93.5374 13.2589 92.6759 11.1747 91.1425 9.63795C89.6092 8.10123 87.5295 7.23792 85.361 7.23792V7.23792ZM85.361 9.69619C85.8461 9.69619 86.3204 9.84037 86.7237 10.1105C87.1271 10.3806 87.4415 10.7645 87.6272 11.2137C87.8128 11.6629 87.8614 12.1572 87.7667 12.634C87.6721 13.1109 87.4385 13.5489 87.0954 13.8927C86.7524 14.2365 86.3153 14.4707 85.8395 14.5655C85.3637 14.6604 84.8705 14.6117 84.4223 14.4256C83.9741 14.2395 83.591 13.9245 83.3214 13.5202C83.0519 13.1159 82.908 12.6407 82.908 12.1545C82.9081 11.5025 83.1666 10.8774 83.6266 10.4164C84.0866 9.95548 84.7105 9.69653 85.361 9.69653V9.69619ZM85.361 21.5307C84.392 21.5263 83.4387 21.285 82.5838 20.8277C81.7289 20.3705 80.9982 19.7112 80.4551 18.9069C80.4943 17.268 83.7257 16.366 85.361 16.366C86.9962 16.366 90.2276 17.268 90.2668 18.9069C89.7231 19.7105 88.9923 20.3694 88.1376 20.8266C87.2828 21.2838 86.3298 21.5254 85.361 21.5307V21.5307Z"
          fill="white"
        />
        <path
          d="M29.88 7.23792C28.2629 7.23792 26.6821 7.7185 25.3375 8.61889C23.9929 9.51929 22.9449 10.7991 22.326 12.2964C21.7072 13.7937 21.5452 15.4413 21.8607 17.0308C22.1762 18.6203 22.9549 20.0804 24.0984 21.2264C25.2419 22.3724 26.6988 23.1528 28.2849 23.469C29.8709 23.7851 31.5149 23.6229 33.009 23.0027C34.503 22.3825 35.78 21.3322 36.6784 19.9846C37.5769 18.6371 38.0564 17.0528 38.0564 15.4322C38.0564 13.2589 37.195 11.1747 35.6616 9.63795C34.1282 8.10123 32.0485 7.23792 29.88 7.23792ZM29.88 9.69619C30.3652 9.69619 30.8394 9.84037 31.2428 10.1105C31.6462 10.3806 31.9606 10.7645 32.1462 11.2137C32.3319 11.6629 32.3804 12.1572 32.2858 12.634C32.1912 13.1109 31.9575 13.5489 31.6145 13.8927C31.2715 14.2365 30.8344 14.4707 30.3586 14.5655C29.8827 14.6604 29.3895 14.6117 28.9413 14.4256C28.4931 14.2395 28.11 13.9245 27.8405 13.5202C27.571 13.1159 27.4271 12.6407 27.4271 12.1545C27.4271 11.5025 27.6855 10.8772 28.1455 10.4162C28.6055 9.95518 29.2295 9.69619 29.88 9.69619V9.69619ZM29.88 21.5307C28.911 21.5263 27.9577 21.285 27.1028 20.8277C26.2479 20.3705 25.5173 19.7112 24.9742 18.9069C25.0134 17.268 28.2447 16.366 29.88 16.366C31.5153 16.366 34.7467 17.268 34.7858 18.9069C34.2421 19.7105 33.5113 20.3694 32.6566 20.8266C31.8018 21.2838 30.8489 21.5254 29.88 21.5307V21.5307Z"
          fill="#E6E6E6"
        />
        <path
          d="M140.788 7.23792C139.171 7.23792 137.59 7.7185 136.246 8.61889C134.901 9.51929 133.853 10.7991 133.234 12.2964C132.615 13.7937 132.453 15.4413 132.769 17.0308C133.084 18.6203 133.863 20.0804 135.007 21.2264C136.15 22.3724 137.607 23.1528 139.193 23.469C140.779 23.7851 142.423 23.6229 143.917 23.0027C145.411 22.3825 146.688 21.3322 147.587 19.9846C148.485 18.6371 148.965 17.0528 148.965 15.4322C148.965 13.2589 148.103 11.1747 146.57 9.63795C145.036 8.10123 142.957 7.23792 140.788 7.23792ZM140.788 9.69619C141.273 9.69619 141.748 9.84037 142.151 10.1105C142.554 10.3806 142.869 10.7645 143.054 11.2137C143.24 11.6629 143.289 12.1572 143.194 12.634C143.099 13.1109 142.866 13.5489 142.523 13.8927C142.18 14.2365 141.743 14.4707 141.267 14.5655C140.791 14.6604 140.298 14.6117 139.85 14.4256C139.401 14.2395 139.018 13.9245 138.749 13.5202C138.479 13.1159 138.335 12.6407 138.335 12.1545C138.335 11.5025 138.594 10.8772 139.054 10.4162C139.514 9.95518 140.138 9.69619 140.788 9.69619V9.69619ZM140.788 21.5307C139.819 21.5263 138.866 21.285 138.011 20.8277C137.156 20.3705 136.425 19.7112 135.882 18.9069C135.922 17.268 139.153 16.366 140.788 16.366C142.423 16.366 145.655 17.268 145.694 18.9069C145.15 19.7105 144.42 20.3694 143.565 20.8266C142.71 21.2838 141.757 21.5254 140.788 21.5307V21.5307Z"
          fill="#E6E6E6"
        />
        <path
          d="M92.8554 42.0444H77.8654C76.7365 42.0444 75.8213 42.9615 75.8213 44.0929V59.1157C75.8213 60.2471 76.7365 61.1643 77.8654 61.1643H92.8554C93.9844 61.1643 94.8995 60.2471 94.8995 59.1157V44.0929C94.8995 42.9615 93.9844 42.0444 92.8554 42.0444Z"
          fill="#6C63FF"
        />
        <path
          d="M204.199 172C229.494 172 250 169.249 250 165.854C250 162.46 229.494 159.709 204.199 159.709C178.904 159.709 158.398 162.46 158.398 165.854C158.398 169.249 178.904 172 204.199 172Z"
          fill="#E6E6E6"
        />
        <path
          d="M148.285 41.9829H133.295C132.166 41.9829 131.25 42.9001 131.25 44.0315V59.0543C131.25 60.1857 132.166 61.1028 133.295 61.1028H148.285C149.414 61.1028 150.329 60.1857 150.329 59.0543V44.0315C150.329 42.9001 149.414 41.9829 148.285 41.9829Z"
          fill="#E6E6E6"
        />
        <path
          d="M147.875 44.6395H133.976V58.5691H147.875V44.6395Z"
          fill="white"
        />
        <path
          d="M84.466 54.573L82.0557 51.466L83.4572 50.3735L84.5985 51.844L88.4544 47.7653L89.7439 48.9893L84.466 54.573Z"
          fill="white"
        />
        <path
          d="M139.985 54.573L137.575 51.466L138.976 50.3735L140.118 51.844L143.973 47.7653L145.263 48.9893L139.985 54.573Z"
          fill="#E6E6E6"
        />
        <path
          d="M37.3764 41.9829H22.3864C21.2575 41.9829 20.3423 42.9001 20.3423 44.0315V59.0543C20.3423 60.1857 21.2575 61.1028 22.3864 61.1028H37.3764C38.5054 61.1028 39.4205 60.1857 39.4205 59.0543V44.0315C39.4205 42.9001 38.5054 41.9829 37.3764 41.9829Z"
          fill="#E6E6E6"
        />
        <path
          d="M36.9666 44.6395H23.0674V58.5691H36.9666V44.6395Z"
          fill="white"
        />
        <path
          d="M29.0768 54.573L26.6665 51.466L28.0681 50.3735L29.2094 51.844L33.0649 47.7653L34.3544 48.9893L29.0768 54.573Z"
          fill="#E6E6E6"
        />
        <path
          d="M235.074 160.995H230.584L228.449 143.638H235.076L235.074 160.995Z"
          fill="#FFB8B8"
        />
        <path
          d="M227.375 159.709H236.034V165.172H221.924C221.924 163.723 222.498 162.333 223.52 161.309C224.543 160.285 225.929 159.709 227.375 159.709Z"
          fill="#2F2E41"
        />
        <path
          d="M189.201 160.995H184.711L182.576 143.638H189.203L189.201 160.995Z"
          fill="#FFB8B8"
        />
        <path
          d="M181.504 159.709H190.164V165.172H176.052C176.052 163.723 176.626 162.333 177.648 161.309C178.671 160.285 180.057 159.709 181.503 159.709H181.504Z"
          fill="#2F2E41"
        />
        <path
          d="M204.199 32.2461V30.2778C204.199 26.911 205.533 23.6822 207.909 21.3016C210.284 18.9209 213.506 17.5835 216.865 17.5835V17.5835C220.225 17.5835 223.447 18.9209 225.822 21.3016C228.197 23.6822 229.532 26.911 229.532 30.2778V32.2457C229.532 34.6725 228.57 36.9999 226.858 38.7159C225.145 40.4319 222.823 41.396 220.402 41.396H213.33C210.909 41.396 208.587 40.4319 206.874 38.7159C205.162 36.9999 204.2 34.6725 204.2 32.2457L204.199 32.2461Z"
          fill="#2F2E41"
        />
        <path
          d="M216.866 42.0751C222.193 42.0751 226.512 37.7466 226.512 32.4072C226.512 27.0678 222.193 22.7394 216.866 22.7394C211.538 22.7394 207.219 27.0678 207.219 32.4072C207.219 37.7466 211.538 42.0751 216.866 42.0751Z"
          fill="#FFB8B8"
        />
        <path
          d="M164.394 65.6325C164.785 65.2621 165.253 64.9822 165.764 64.8126C166.275 64.6429 166.817 64.5876 167.352 64.6506C167.887 64.7135 168.401 64.8932 168.859 65.1769C169.317 65.4607 169.707 65.8416 170.003 66.2928L178.272 64.7254L180.448 69.5292L168.739 71.5778C167.95 72.0235 167.025 72.1638 166.139 71.9721C165.254 71.7804 164.469 71.27 163.934 70.5376C163.399 69.8051 163.15 68.9014 163.235 67.9975C163.319 67.0936 163.732 66.2522 164.394 65.6325V65.6325Z"
          fill="#FFB8B8"
        />
        <path
          d="M209.345 48.1631L209.453 48.3273L195.894 57.2799L173.012 64.6024C172.709 64.7001 172.448 64.9001 172.276 65.1684C172.103 65.4367 172.028 65.7568 172.065 66.074L172.564 70.3828C172.586 70.5735 172.648 70.7575 172.745 70.9229C172.842 71.0883 172.973 71.2316 173.128 71.3434C173.284 71.4553 173.461 71.5333 173.649 71.5725C173.836 71.6117 174.03 71.6112 174.217 71.571L195.753 66.945C196.795 66.7224 197.781 66.2875 198.648 65.6674L213.88 54.8049C214.716 54.2078 215.285 53.3056 215.465 52.2925C215.644 51.2794 215.419 50.2363 214.839 49.3876C214.258 48.5388 213.369 47.9523 212.361 47.7542C211.354 47.5562 210.309 47.7623 209.452 48.3283L209.345 48.1631Z"
          fill="#CCCCCC"
        />
        <path
          d="M230.664 156.07C230.319 156.07 229.982 155.969 229.694 155.78C229.405 155.592 229.177 155.323 229.039 155.006L208.454 104.031C208.406 103.921 208.326 103.827 208.223 103.764C208.121 103.701 208.001 103.671 207.881 103.677C207.761 103.684 207.646 103.728 207.551 103.802C207.456 103.876 207.387 103.978 207.351 104.093L190.557 153.72C190.486 153.951 190.369 154.165 190.213 154.348C190.056 154.532 189.864 154.682 189.647 154.789C189.431 154.895 189.195 154.956 188.954 154.968C188.714 154.98 188.473 154.942 188.247 154.858L182.616 152.741C182.31 152.627 182.042 152.43 181.841 152.173C181.64 151.916 181.514 151.607 181.478 151.283C178.944 129.164 201.037 73.3348 201.261 72.7728L201.323 72.6175L221.463 77.0823L221.505 77.1281C229.54 85.913 236.144 141.32 237.387 152.339C237.433 152.737 237.342 153.138 237.13 153.478C236.918 153.818 236.597 154.075 236.22 154.207L231.249 155.969C231.061 156.035 230.863 156.069 230.664 156.069V156.07Z"
          fill="#2F2E41"
        />
        <path
          d="M213.312 78.8809C208.432 78.8809 202.992 77.9058 201.318 73.8818L201.279 73.7892L201.331 73.7035C202.653 71.53 204.4 67.8228 203.461 67.1266C201.611 65.7561 200.714 63.5013 200.793 60.4251C200.967 53.7471 205.507 47.8179 212.092 45.6713C213.929 45.0818 215.8 44.5991 217.693 44.2257C219.074 43.9466 220.499 43.9774 221.866 44.3157C223.233 44.6541 224.509 45.2917 225.601 46.1828C226.706 47.0797 227.6 48.211 228.218 49.4952C228.836 50.7795 229.162 52.1849 229.173 53.6105C229.241 60.7464 228.146 70.687 222.53 77.5104C222.266 77.827 221.9 78.0404 221.495 78.1131C218.794 78.603 216.057 78.8599 213.312 78.8809V78.8809Z"
          fill="#CCCCCC"
        />
        <path
          d="M211.497 78.6122C211.762 77.6576 212.393 76.8465 213.252 76.3553C214.111 75.8641 215.129 75.7325 216.084 75.989C216.279 76.0436 216.469 76.1142 216.652 76.2L222.931 70.4733L227.298 73.4215L218.319 81.3849C217.913 82.1175 217.275 82.6931 216.505 83.0202C215.736 83.3474 214.879 83.4073 214.072 83.1904C213.601 83.0614 213.161 82.8402 212.777 82.5397C212.392 82.2392 212.071 81.8653 211.831 81.4395C211.592 81.0138 211.439 80.5447 211.382 80.0594C211.324 79.5741 211.363 79.0823 211.497 78.6122V78.6122Z"
          fill="#FFB8B8"
        />
        <path
          d="M221.937 78.6846C221.529 78.6847 221.134 78.543 220.818 78.2838L218.342 76.256C218.136 76.087 217.97 75.8733 217.858 75.631C217.746 75.3888 217.689 75.1242 217.694 74.857C217.698 74.5899 217.762 74.3272 217.882 74.0885C218.002 73.8499 218.174 73.6414 218.385 73.4789L228.771 65.4963C228.894 65.4012 228.975 65.2613 228.995 65.107C229.016 64.9526 228.976 64.7962 228.882 64.6717L222.472 56.1788C221.727 55.1953 221.354 53.9795 221.418 52.7465C221.481 51.5136 221.978 50.343 222.821 49.4421C223.708 48.482 224.922 47.8912 226.224 47.7869C227.525 47.6826 228.818 48.0724 229.846 48.879L229.887 48.9221L236.58 58.5274C237.304 59.0962 237.887 59.8249 238.283 60.6567C238.679 61.4885 238.879 62.4009 238.865 63.3226C238.851 64.2442 238.625 65.1503 238.204 65.9699C237.783 66.7895 237.179 67.5005 236.439 68.0474L222.988 78.336C222.684 78.5615 222.315 78.6831 221.936 78.6832L221.937 78.6846Z"
          fill="#CCCCCC"
        />
        <path
          d="M208.321 29.6875V24.8907L216.866 21.1501L225.014 24.8894V29.6861C225.014 29.7895 224.993 29.8919 224.954 29.9874C224.914 30.0829 224.856 30.1697 224.783 30.2428C224.711 30.3159 224.624 30.3739 224.529 30.4135C224.433 30.4531 224.331 30.4734 224.228 30.4734H209.106C209.003 30.4734 208.901 30.4531 208.806 30.4135C208.71 30.3739 208.624 30.3159 208.551 30.2428C208.478 30.1697 208.42 30.0829 208.381 29.9874C208.341 29.8919 208.321 29.7895 208.321 29.6861V29.6875Z"
          fill="#2F2E41"
        />
        <path
          d="M224.176 22.4437C227.134 22.4437 229.532 20.0404 229.532 17.0758C229.532 14.1112 227.134 11.7079 224.176 11.7079C221.217 11.7079 218.819 14.1112 218.819 17.0758C218.819 20.0404 221.217 22.4437 224.176 22.4437Z"
          fill="#2F2E41"
        />
        <path
          d="M219.232 14.1962C219.232 13.4021 219.407 12.6177 219.746 11.8998C220.085 11.1819 220.578 10.5482 221.191 10.0447C221.804 9.54109 222.52 9.18011 223.289 8.98777C224.058 8.79543 224.86 8.77653 225.637 8.93243C224.951 8.60859 224.205 8.4344 223.447 8.42138C222.689 8.40836 221.937 8.55681 221.24 8.8569C220.544 9.15699 219.919 9.60188 219.407 10.1621C218.895 10.7224 218.507 11.3852 218.27 12.1068C218.033 12.8284 217.951 13.5922 218.03 14.3477C218.11 15.1033 218.348 15.8334 218.73 16.4896C219.112 17.1459 219.629 17.7133 220.246 18.1545C220.863 18.5956 221.567 18.9004 222.31 19.0486C221.391 18.6158 220.613 17.9297 220.068 17.0705C219.523 16.2112 219.234 15.2143 219.233 14.1962H219.232Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_4909_106782">
          <rect width="250" height="172" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoSubsciptions;
