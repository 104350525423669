import { Dialog, Transition } from "@headlessui/react";
import Button from "../Button";
import React, { Fragment, useState } from "react";
import { frontEndURL } from "../../constants";
import { globals } from "../../globals";
import GuestModal from "../GuestModal";

interface loginModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  isDismissible?: boolean;
  isUserLoggedIn: boolean;
  roomUniqueId?: boolean | undefined;
  isGuestAllowed: boolean;
}

function LoginModal({
  isOpen,
  setIsOpen,
  isDismissible = true,
  isUserLoggedIn,
  roomUniqueId = undefined,
  isGuestAllowed,
}: loginModalProps) {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-navyGray">
                <div className="mt-2">
                  <div className="flex font-ubuntu dark:bg-navyGray">
                    <div className="w-full flex justify-center items-center relative">
                      <div className="my-auto flex space-y-8 flex-col">
                        <div className="flex justify-center">
                          <div className="rounded-xl">
                            <img
                              width={35}
                              src={`${process.env.PUBLIC_URL}/images/Hut.png`}
                              alt="Vibehut"
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <p className="text-navyGray dark:text-frescoWhite  font-medium text-2xl">
                            Welcome to Vibehut
                          </p>
                          <p className="text-darkGray  pt-2">
                            Hey, it's great to connect!
                          </p>
                        </div>
                        <div className="flex flex-col space-y-4">
                          <div className="flex justify-center">
                            <button
                              onClick={() => {
                                try {
                                  localStorage.setItem(
                                    "loggingCallbackUrl",
                                    window.location.href,
                                  );
                                  window.open(`${frontEndURL}/login`, "_self");
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              type="button"
                              className="bg-white authBtn border border-gray-400"
                            >
                              <span className="flex justify-center  items-center space-x-2">
                                <span className="text-bitClout">
                                  Login to Vibehut
                                </span>
                              </span>
                            </button>
                          </div>
                          {!isDismissible &&
                          (roomUniqueId || isGuestAllowed) ? (
                            <>
                              <p className="text-center text-navyGray dark:text-frescoWhite">
                                or
                              </p>
                              <div className="flex justify-center">
                                <button
                                  onClick={() => {
                                    setIsOpen(false);
                                    globals.showModal(
                                      "Join as Guest",
                                      <GuestModal
                                        onclose={globals.hideModal}
                                      />,
                                    );
                                  }}
                                  type="button"
                                  className="bg-white authBtn border border-gray-400"
                                >
                                  <span className="flex justify-center space-x-2">
                                    <span className="text-bitClout text-center">
                                      Join as Guest
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </>
                          ) : undefined}
                          <div className="flex justify-center">
                            <p className="text-darkGray  pt-2 w-60 text-center text-sm ">
                              By signing up, you agree to our &nbsp;
                              <a
                                href="/terms-condition"
                                target="_blank"
                                className="text-primary"
                              >
                                Terms of Service
                              </a>
                              &nbsp;and&nbsp;
                              <a
                                className="text-primary"
                                target="_blank"
                                href="/privacy-policy"
                              >
                                Privacy Policy.
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex flex-row justify-end">
                  <Button
                    size="vibhut-sm"
                    variant="vibhut-primary"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <SuccessModal
        isSuccessOpen={isSuccessOpen}
        setIsSuccessOpen={setIsSuccessOpen}
        isUserLoggedIn={isUserLoggedIn}
      />
    </>
  );
}
export default LoginModal;

interface successModalProps {
  isSuccessOpen: boolean;
  setIsSuccessOpen: (data: boolean) => void;
  isUserLoggedIn: boolean;
}

function SuccessModal({
  isSuccessOpen,
  setIsSuccessOpen,
  isUserLoggedIn,
}: successModalProps) {
  return (
    <>
      <Transition appear show={isSuccessOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => setIsSuccessOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Successfully LoggedIn!
                  </Dialog.Title>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => setIsSuccessOpen(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
                <hr className="mt-3" />
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    You have successfully logged in.
                  </p>
                  <p className="text-sm text-gray-500">- Vibehut Team🏠</p>
                </div>

                <div className="mt-4 flex flex-row justify-end">
                  <Button
                    onClick={() => {
                      setIsSuccessOpen(false);
                      if (!isUserLoggedIn) {
                        window.location.reload();
                      }
                    }}
                    size="vibhut-sm"
                    variant="vibhut-primary"
                  >
                    Okay
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
