import React, { useEffect } from "react";

interface props {
  dataAdSlot: string;
}

export const SideAd = ({ dataAdSlot }: props) => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    const interval = setInterval(() => {
      if (window.adsbygoogle) {
        pushAd();
        clearInterval(interval);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <ins
      className="adsbygoogle"
      style={{
        display: "flex",
        width: "100%",
        height: "250px",
        borderRadius: "10px",
      }}
      data-ad-client="ca-pub-9557256809070435"
      data-ad-slot={dataAdSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};
