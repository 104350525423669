import React, { useState } from 'react';
import Avatar from '../Avatar';
import DownArrow from '../Icons/DownArrow';
import UpArrow from '../Icons/UpArrow';
import Button from '../Button';

interface ShowActiveUserProps {
  users: any[];
  index: number;
}

const ShowActiveUser = ({ users, index }: ShowActiveUserProps) => {
  const [showActiveUsers, setShowActiveUsers] = useState(false);

  return (
    <div className="py-1" key={index}>
      {users.length === 1 ? (
        <>
          <div className="flex items-center w-full py-3 px-4">
            <div className="w-10">
              <div className="relative">
                <Avatar src={users[0]?.profile_picture} username={users[0]?.username} size="sm-2" />
              </div>
            </div>
            <div className="text-sm font-medium text-darkNight">{users[0]?.username}</div>
          </div>
        </>
      ) : users.length === 2 ? (
        <>
          <div className="flex items-center w-full py-3 px-4">
            <div className="w-16">
              <div className="relative">
                <Avatar src={users[0]?.profile_picture} username={users[0]?.username} size="sm-2" />

                <span className="absolute -top-1 left-6 border-4 border-white rounded-full">
                  <Avatar src={users[1]?.profile_picture} username={users[1]?.username} size="sm-2" />
                </span>
              </div>
            </div>
            <div className="text-sm font-medium text-darkNight">
              {users[0]?.username} / {users[1]?.username}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center  border-b w-full py-3 px-4">
            <div className="flex">
              <div className="w-16">
                <div className="relative">
                  <Avatar
                    src={users[users.length - 1]?.profile_picture}
                    username={users[users.length - 1]?.username}
                    size="sm-2"
                  />

                  <span className="absolute -top-1 left-6 border-4 border-white rounded-full">
                    <Avatar
                      src={users[users.length - 2]?.profile_picture}
                      username={users[users.length - 2]?.username}
                      size="sm-2"
                    />
                  </span>
                </div>
              </div>
              <div className="text-sm font-medium flex items-center text-darkNight pl-1">
                {users[users.length - 1]?.username} +{users.length - 1} others
              </div>
            </div>
            {!showActiveUsers ? (
              <span className="rounded-full cursor-pointer pr-1" onClick={() => setShowActiveUsers(true)}>
                <DownArrow width={16} height={8} />
              </span>
            ) : (
              <span className="rounded-full cursor-pointer pr-1" onClick={() => setShowActiveUsers(false)}>
                <UpArrow width={20} height={20} />
              </span>
            )}
          </div>
          {showActiveUsers ? (
            <>
              {users.map(p_user => (
                <div className="pl-8 pr-4 py-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="w-10">
                      <div className="relative">
                        <Avatar src={p_user?.profile_picture} username={p_user?.username} size="sm-2" />
                      </div>
                    </div>
                    <div className="text-sm font-medium text-darkNight pl-3">{p_user?.username}</div>
                  </div>

                  <Button size="vibhut-sm" variant="vibhut-primary" onClick={() => {}}>
                    Wave 👋
                  </Button>
                </div>
              ))}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ShowActiveUser;
