import React from 'react';

const UserFilled = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6599_67341)">
        <circle cx="8" cy="3.25" r="3.25" fill="#B5B5B5" />
        <path
          d="M2 13C2 10.7909 3.79086 9 6 9H9.49927C11.7084 9 13.4993 10.7909 13.4993 13V14.5C13.4993 15.0523 13.0515 15.5 12.4993 15.5H3C2.44771 15.5 2 15.0523 2 14.5V13Z"
          fill="#B5B5B5"
          stroke="#B5B5B5"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6599_67341">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserFilled;
