import React from "react";
import Stars from "../Icons/Stars";
import Button from "../Button";
import Close from "../Icons/Close";

function UpGradeCard({ setIsOpen }) {
  return (
    <div className=" relative cardWidth bg-frescoWhite flex flex-col justify-center items-center py-6 px-4 space-y-4 rounded-xl">
      <Stars />
      <span
        className="absolute -top-2 right-2  p-2 cursor-pointer"
        onClick={() => {
          try {
            const isLeftBarInfo = window.localStorage.getItem("isLeftBarInfo");
            if (isLeftBarInfo !== 1) {
              localStorage.setItem("isLeftBarInfo", 1);
            }
            setIsOpen(false);
          } catch (error) {}
        }}
      >
        <Close />
      </span>
      <p className="text-center text-darkGray text-sm">
        Create magic by interacting with like-minded people on video calls.
      </p>
      <Button
        size="vibhut-sm"
        variant="vibhut-primary"
        onClick={() => {
          window.location.href = "https://about.vibehut.io";
        }}
      >
        Learn About Vibehut
      </Button>
      <p className="text-center text-darkGray text-sm">
        If you have feedback or suggestions, join our
        <a
          href="https://discord.com/invite/bBCe4pcFen"
          className="text-primary"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;Discord.
        </a>
      </p>
    </div>
  );
}

export default UpGradeCard;
