import TextareaAutosize from 'react-textarea-autosize';
const LimitedTextarea = ({
  rows,
  cols,
  value,
  limit,
  onChange,
  placeHolder = 'Let others know what to expect...',
}: any) => {
  const setFormattedContent = (data: string) => {
    let text = data.slice(0, limit);
    onChange(text);
  };

  return (
    <div className="relative">
      <TextareaAutosize
        cacheMeasurements
        onChange={e => setFormattedContent(e.target.value)}
        className={`mt-1.5 border dark:border-0 text-matteGray   border-lightGray placeholder-notifyBg text-sm
                pr-8 pt-3 pl-4 custom-scrollbar rounded-xl w-full outline-none resize-none overflow-auto`}
        placeholder={placeHolder}
        rows={rows}
        maxRows={rows}
        value={value}
        minRows={rows}
      />
      <div className="absolute bottom-4 right-4">
        <p className="text-lightGray text-sm">
          {value.length}/{limit}
        </p>
      </div>
    </div>
  );
};

export default LimitedTextarea;
