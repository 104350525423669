import * as actionTypes from "../constants";
import { BookingType } from "../types";

interface InitialState {
  bookingTypeData: BookingType;
  bookingTypes: BookingType[];
  isFetchingBookingTypes: boolean;
  offset: string | null;
  isFetching: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isDeleting: boolean;
}

const initialState: InitialState = {
  bookingTypeData: {
    _id: "",
    title: "",
    slug: "",
    isAvailable: false,
    duration: 0,
    description: "",
    availability: [
      {
        start_at: new Date(),
        end_at: new Date(),
      },
    ],
  },
  bookingTypes: [],
  offset: "",
  isFetchingBookingTypes: false,
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  isDeleting: false,
};

function bookingTypeReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_BOOKING_TYPES_REQUEST:
      return {
        ...state,
        isFetchingBookingTypes: true,
      };
    case actionTypes.GET_BOOKING_TYPES_SUCCESS: {
      return {
        ...state,
        bookingTypes: action.payload,
        isFetchingBookingTypes: false,
      };
    }

    case actionTypes.GET_BOOKING_TYPES_FAILURE:
      return {
        ...state,
        isFetchingBookingTypes: false,
      };
    case actionTypes.CREATE_BOOKING_TYPE_REQUEST:
      return { ...state, isCreating: true };

    case actionTypes.CREATE_BOOKING_TYPE_SUCCESS: {
      return {
        ...state,
        bookingTypes: [action.payload, ...state.bookingTypes],
        isCreating: false,
      };
    }
    case actionTypes.CREATE_BOOKING_TYPE_FAILURE:
      return { ...state, isCreating: false };

    case actionTypes.UPDATE_BOOKING_TYPE_REQUEST:
      return { ...state, isUpdating: true };

    case actionTypes.UPDATE_BOOKING_TYPE_SUCCESS: {
      const updatedBookingTypes = state.bookingTypes.filter(
        (bookingType) => bookingType._id !== action.payload._id,
      );
      return {
        ...state,
        bookingTypes: [action.payload, ...updatedBookingTypes],
        isUpdating: false,
      };
    }
    case actionTypes.UPDATE_BOOKING_TYPE_FAILURE:
      return { ...state, isUpdating: false };
    case actionTypes.DELETE_BOOKING_TYPE_REQUEST:
      return { ...state, isDeleting: true };

    case actionTypes.DELETE_BOOKING_TYPE_SUCCESS: {
      const updatedBookingTypes = state.bookingTypes.filter(
        (bookingType) => bookingType._id !== action.payload,
      );
      return {
        ...state,
        bookingTypes: updatedBookingTypes,
        isDeleting: false,
      };
    }
    case actionTypes.DELETE_BOOKING_TYPE_FAILURE:
      return { ...state, isDeleting: false };
    default:
      return state;
  }
}

export default bookingTypeReducer;
