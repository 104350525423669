import axios from "../utils/axios";
import Axios from "axios";

export function createEvent(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/events", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function createEventByAI(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/events/generate-event-by-ami", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomEvents(token, roomId, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/rooms/${roomId}?limit=${3}&offset=${offset}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getRoomAllEvents(token, roomId, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/all-rooms/${roomId}?limit=${6}&offset=${offset}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateEvent(data, eventId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/events/${eventId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function deleteEvent(eventId, token, isGroupDelete = false) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`/events/${eventId}?isGroupDelete=${isGroupDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateRsvp(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/rsvp`, data, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateRsvpHighlighted(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/rsvp/highlight`, data, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getEventRsvps({ status, limit, offset, eventId }) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/rsvp/event/${eventId}?status=${status}&limit=${limit}&offset=${offset}`,
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAllEvents(token, offset, limit, eventType) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events?type=${eventType}&limit=${limit}&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyEvents(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/myevent`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCalenderEvents(startDate, endDate, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `events/calender-events`,
        { startDate, endDate },
        {
          headers: {
            ...(token
              ? {
                  Authorization: `Bearer ${token}`,
                }
              : {}),
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAllEventsByUserId(
  token,
  userId,
  source,
  limit = 20,
  offset = 0,
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/rsvpd/${userId}?limit=${limit}&offset=${offset}`, {
        cancelToken: source?.token,
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) return;
        reject(error);
      });
  });
}

export function getPopularEvents(offset, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/popular?limit=6&offset=${offset}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getLiveEvents(offset, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/live-events?limit=6&offset=${offset}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUpcomingEvents(offset, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/upcoming-events?limit=6&offset=${offset}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getHomePageEvents(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/homepage`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getNewlyAddedEvents(offset, token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/newly-added-events?limit=6&offset=${offset}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getGeneralRoomLatestEvent(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/get-general-room-latest-event`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function updateEventCacheData(slug, eventId) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/events/update-event-cache`,
        { slug: slug, eventId },
        {
          headers: {},
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getEventById(token, eventId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/event-by-id/${eventId}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteUserForEvent(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/events/invite-user", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteUsersForEvent(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/events/invite-users", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function inviteUserViaEmailForEvent(data, token) {
  const formData = new FormData();
  formData.append("csv", data.csv);
  formData.append("note", data.note);
  formData.append("eventId", data.eventId);
  formData.append("emails", data.emails);
  return new Promise(function (resolve, reject) {
    axios
      .post("/events/invite-user-via-email", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
export function getUserUpcomingRoomEvents(token, roomId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/get-rsvpd-events-room-user/${roomId}`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function joinEventWaitList(eventId, token) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/event-waitlist/${eventId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyInvitedEvents(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/get-invited-events`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getCurrentHappeningEvent(token, timezone) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/happening-events?timezone=${timezone}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAutoOrganizedEvents(token) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/events/auto-organized-events`, {
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
