import axios from "../utils/axios";

export function updateMyConnection(data, token) {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/connections/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMyConnections(token, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/connections?limit=30&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserFollowers(userId, token, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/connections/get-followers/${userId}?limit=30&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserFollowings(userId, token, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/connections/get-followings/${userId}?limit=30&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUserConnections(userId, token, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/connections/${userId}?limit=30&offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getMutualConnections(userId, token, offset = 0) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/connections/get-mutual-connections/${userId}?limit=30&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}

export function getConnection(token, userId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/connections/single/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => reject(err));
  });
}
