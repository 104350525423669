import React, { useContext } from "react";
import SelectBox from "../SelectBox";
import { themeOptions } from "../../constants";
import { ThemeContext } from "../../Context/ThemeContext";

type SelectOption = {
  label: any;
  value: any;
};

export default function Settings() {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = (SelectedOption: SelectOption) => {
    setTheme(SelectedOption.value);
  };

  const findTheme = (theme: string): SelectOption => {
    const themeOption = themeOptions.find(
      (item: SelectOption) => item.value === theme,
    );
    if (themeOption) {
      return themeOption as SelectOption;
    }
    return themeOptions[0]
  };

  return (
    <div>
      <p className="text-xl flex-grow font-bold text-black dark:text-frescoWhite hidden md:block">
        Settings
      </p>
      <div className="py-3 flex flex-row border-b dark:border-matteGray border-frescoWhite">
        <div className="w-4/5">
          <p className="text-sm font-medium text-navyGray dark:text-frescoWhite">
            Vibehut Theme
          </p>
          <p className="text-xs text-gray-400">
            Customize your Vibehut experience with different themes.
          </p>
        </div>
        <div className=" flex gap-2 items-center">
          <SelectBox
            onChange={handleThemeChange}
            selected={findTheme(theme)}
            options={themeOptions}
          />
        </div>
      </div>
    </div>
  );
}
