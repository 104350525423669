import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import EditChatModal from "./EditChatModal";

function EditChatModalWrapper({ isOpen, setIsOpen, chat, editMessage }) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-overlay bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white dark:bg-navyGray
                rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 font-ubuntu`}
            >
              <div
                className={`bg-white dark:bg-navyGray dark:border-matteGray
                p-3 border-b border-frescoWhite `}
              >
                <div className="flex justify-between items-center">
                  <p
                    className={`font-medium text-lg  text-black dark:text-frescoWhite dark:bg-navyGray
                      `}
                  >
                    Edit message
                  </p>
                  <span
                    className="bg-frescoWhite rounded-full p-1 cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <EditChatModal
                chat={chat}
                editMessage={editMessage}
                setIsOpen={setIsOpen}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EditChatModalWrapper;
