import CryptoJS from "crypto-js";

export const encryptMsg = (secretKey, msg) => {
  const ciphertext = CryptoJS.AES.encrypt(msg, secretKey).toString();
  return ciphertext;
};

export const decryptMsg = (secretKey, ciper) => {
  try {
    const originalText = CryptoJS.AES.decrypt(ciper, secretKey).toString(
      CryptoJS.enc.Utf8
    );

    if (originalText) {
      return originalText;
    }
    return ciper;
  } catch (error) {
    return ciper;
  }
};
